import React from 'react';
import JobHardwareCapability from '@codeDelivery/views/JobHardware/Components/JobHardwareCapability';
import { Button, Heading } from '@puppet/react-components';
import PageLayout from '@components/PageLayout';
import Breadcrumbs from '@components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { useNavigate } from 'react-router-dom';
import { LINKS } from '../../../routes';

const breadcrumbsRender = (t: Function, workspace: string) => {
  const breadcrumbs = [
    { displayName: `${workspace}` },
    {
      displayName: t('jobHardware.breadcrumbs.hardware'),
      linkDestination: LINKS.codeDelivery.jobHardware({
        path: { workspace },
      }),
    },
  ];
  return breadcrumbs;
};

const JobHardware = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const navigate = useNavigate();

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbsRender(t, workspaceName)} />
        </PageLayout.Breadcrumbs>
        <div className="job-hardware-capability__page-header">
          <Heading>{t('jobHardware.header')}</Heading>
          <Button
            className="job-hardware-capability__add-button"
            onClick={() => {
              navigate(
                LINKS.codeDelivery.newCapability({
                  path: { workspace: workspaceName },
                }),
              );
            }}
            data-testid="add-capability-button"
          >
            {t('jobHardware.button.add')}
          </Button>
        </div>
      </PageLayout.Header>
      <PageLayout.Content>
        <JobHardwareCapability />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default JobHardware;
