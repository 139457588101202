import React, { useEffect, useMemo } from 'react';
import {
  Location,
  Navigate,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  isCurrentWorkspacePending,
  selectUser,
  selectWorkspaces,
  selectWorkspaceName,
} from '@state/session/selectors';
import getDefaultRoute from '@utils/getDefaultRoute';
import session from '@state/session/session';
import { WorkspaceV1 } from '@utils/api/cd4pe';
import { UserV1 } from '@puppet/cd4pe-client-ts';
import { LINKS } from '../../routes';

interface CheckForRedirect {
  location: Location;
  workspaces: WorkspaceV1[];
  workspaceUrlParam?: string;
  user: UserV1;
}

const userRequiresWorkspace = (user: UserV1, workspaces: WorkspaceV1[]) =>
  !user.rootUser && workspaces.length <= 0;

const checkForRedirect = ({
  location,
  workspaces,
  workspaceUrlParam,
  user,
}: CheckForRedirect) => {
  if (workspaces?.length > 0) {
    let workspaceName;
    // Check if we have workspaces in state and the location is at the root.
    // We need to redirect to the default view of the first workspace.
    if (location.pathname === '/') {
      workspaceName = workspaces?.[0]?.name;
    }

    // Check if we have a workspace param that is available in state, and the location is at the root of the workspace.
    // We need to redirect to the default view of this workspace param.
    if (
      workspaceUrlParam &&
      workspaces.some(({ name }) => workspaceUrlParam === name) &&
      location.pathname === `/${workspaceUrlParam}`
    ) {
      workspaceName = workspaceUrlParam;
    }

    if (workspaceName) {
      return getDefaultRoute({
        workspaceName,
        userName: user.userName,
      });
    }
  }

  const chooseWorkspaceLink = LINKS.username.chooseWorkspace({
    path: { username: user.userName! },
  });

  if (
    userRequiresWorkspace(user, workspaces) &&
    location.pathname !== chooseWorkspaceLink
  ) {
    return chooseWorkspaceLink;
  }

  // root user has deleted last workspace
  if (
    user.rootUser &&
    workspaces.length <= 0 &&
    (location.pathname === '/' || location.pathname.endsWith('/workspace'))
  ) {
    return LINKS.cd4pe.root;
  }

  return null;
};

const WorkspaceRedirect = () => {
  const { workspace: workspaceUrlParam } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const workspaces = useSelector(selectWorkspaces);
  const currentWorkspace = useSelector(selectWorkspaceName);
  const workspaceStatePending = useSelector(isCurrentWorkspacePending);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (workspaceUrlParam && currentWorkspace !== workspaceUrlParam) {
      dispatch(session.setCurrentWorkspace(workspaceUrlParam));
    }
  }, [dispatch, workspaceUrlParam, currentWorkspace]);

  /*
    Here we want to prevent rendering views until we have first tried to set the current workspace in state. This will stop cases
    where views will render empty or incorrect data on screen before the workspace state is set.

    This solution is overly simplistic based on the number of cases where the workspace can be valid or not and we will need to revisit.
    There are multiple scenarios we may want to explicitly check in the future which result in no valid workspace name being set in state:

      - Non workspace path: e.g /login.
      - User path: e.g /userName/manage-workspaces
      - Root path: e.g /root/settings.
      - Incorrect workspace name: e.g /wrongWorkspace/nodes. CD4PE allows this case, but we may want to use a redirect to 404 for this scenario.
  */

  const redirect = useMemo(
    () =>
      checkForRedirect({
        location,
        workspaces,
        workspaceUrlParam,
        user,
      }),
    [location, workspaces, workspaceUrlParam, user],
  );

  if (workspaceUrlParam && workspaceStatePending) {
    return null;
  }

  if (redirect) {
    return <Navigate replace to={redirect} />;
  }

  return <Outlet />;
};

export default WorkspaceRedirect;
