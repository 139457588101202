import React from 'react';
import { Form } from '@puppet/react-components';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  stageNum: number | undefined;
  submitting: boolean;
  onSubmit: Function;
  onCancel: Function;
  submitKey?: string;
  cancelKey?: string;
}
const AddPRGateForm = ({
  stageNum,
  submitting,
  onSubmit,
  onCancel,
  submitKey = 'viewPipeline.form.pullRequestGate.fields.addGate.label',
  cancelKey = 'viewPipeline.form.pullRequestGate.fields.cancelGate.label',
}: Props) => {
  const { t } = useTranslation('codeDelivery');

  return (
    <div className="pr-gate-form">
      <Trans
        t={t}
        i18nKey="viewPipeline.form.pullRequestGate.field.addGate.description"
      >
        <span className="pr-gate-form__stageNumber">
          {{
            stageNumber: stageNum,
          }}
        </span>
      </Trans>
      <br />
      <br />
      <Trans
        t={t}
        i18nKey="viewPipeline.form.pullRequestGate.field.addGate.confirmation"
      >
        <span className="pr-gate-form__stageNumber">
          {{
            stageNumber: stageNum,
          }}
        </span>
      </Trans>
      <Form
        submittable
        cancellable
        className="pr-gate-form__buttons"
        submitLabel={t(submitKey)}
        cancelLabel={t(cancelKey)}
        submitting={submitting}
        onSubmit={() => onSubmit()}
        onCancel={() => onCancel()}
        data-testid="add-pr-gate"
      />
    </div>
  );
};

export default AddPRGateForm;
