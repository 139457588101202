import { Cd4peApiError, SecretDetailsV1 } from '@utils/api/cd4pe';
import * as sharedAt from '@codeDelivery/utils/actionTypes';
import { EditJobTemplateActions, JobTemplateModal } from './actions';
import * as at from './actionTypes';

export interface EditJobTemplateState {
  jobSecrets: SecretDetailsV1[];
  createJobSecretLoading: boolean;
  createJobSecretError: Cd4peApiError | null;
  createJobSecretSuccess: boolean;
  modal: JobTemplateModal;
  getJobSecretsLoading: boolean;
  getJobSecretsError: Cd4peApiError | null;
  selectedSecret: SecretDetailsV1 | null;
  editJobSecretLoading: boolean;
  editJobSecretSuccess: boolean;
  editJobSecretError: Cd4peApiError | null;
  deleteJobSecretLoading: boolean;
  deleteJobSecretError: Cd4peApiError | null;
}

export const defaultState: EditJobTemplateState = {
  createJobSecretLoading: false,
  createJobSecretError: null,
  createJobSecretSuccess: false,
  getJobSecretsLoading: false,
  getJobSecretsError: null,
  jobSecrets: [],
  modal: null,
  selectedSecret: null,
  editJobSecretLoading: false,
  editJobSecretSuccess: false,
  editJobSecretError: null,
  deleteJobSecretLoading: false,
  deleteJobSecretError: null,
};

export const reducer = (
  state: EditJobTemplateState,
  action: EditJobTemplateActions,
): EditJobTemplateState => {
  switch (action.type) {
    case sharedAt.CREATE_JOB_SECRET_LOADING:
      return {
        ...state,
        createJobSecretLoading: true,
      };
    case sharedAt.CREATE_JOB_SECRET_COMPLETE:
      return {
        ...state,
        createJobSecretLoading: false,
        createJobSecretError: null,
        createJobSecretSuccess: true,
        jobSecrets: [...state.jobSecrets, action.payload],
        selectedSecret: action.payload,
      };
    case sharedAt.CREATE_JOB_SECRET_ERROR:
      return {
        ...state,
        createJobSecretLoading: false,
        createJobSecretError: action.payload,
        createJobSecretSuccess: false,
      };
    case sharedAt.GET_JOB_SECRETS_LOADING:
      return {
        ...state,
        getJobSecretsLoading: true,
      };
    case sharedAt.GET_JOB_SECRETS_COMPLETE:
      return {
        ...state,
        jobSecrets: action.payload,
        getJobSecretsError: null,
      };
    case sharedAt.GET_JOB_SECRETS_ERROR:
      return {
        ...state,
        jobSecrets: [],
        getJobSecretsError: action.payload,
      };
    case sharedAt.EDIT_JOB_SECRET_LOADING:
      return {
        ...state,
        editJobSecretLoading: true,
      };
    case sharedAt.EDIT_JOB_SECRET_COMPLETE:
      return {
        ...state,
        editJobSecretLoading: false,
        editJobSecretError: null,
        editJobSecretSuccess: true,
        jobSecrets: state.jobSecrets.map((s) =>
          s.name === action.payload.name ? action.payload : s,
        ),
        selectedSecret: action.payload,
      };
    case sharedAt.EDIT_JOB_SECRET_ERROR:
      return {
        ...state,
        editJobSecretLoading: false,
        editJobSecretError: action.payload,
        editJobSecretSuccess: false,
      };
    case sharedAt.DELETE_JOB_SECRET_LOADING:
      return {
        ...state,
        deleteJobSecretLoading: true,
      };
    case sharedAt.DELETE_JOB_SECRET_COMPLETE:
      return {
        ...state,
        deleteJobSecretLoading: false,
        deleteJobSecretError: null,
        selectedSecret: null,
        modal: null,
        jobSecrets: state.jobSecrets.filter((s) => s.name !== action.payload),
      };
    case sharedAt.DELETE_JOB_SECRET_ERROR:
      return {
        ...state,
        deleteJobSecretLoading: false,
        deleteJobSecretError: action.payload,
        modal: null,
        selectedSecret: null,
      };
    case at.TOGGLE_MODAL:
      return action.payload === null
        ? {
            ...state,
            modal: null,
            selectedSecret: null,
            createJobSecretSuccess: false,
            createJobSecretError: null,
            editJobSecretSuccess: false,
            editJobSecretError: null,
          }
        : {
            ...state,
            modal: action.payload,
          };
    case at.TOGGLE_SELECTED_SECRET:
      return {
        ...state,
        selectedSecret: action.payload,
      };
    default:
      return state;
  }
};
