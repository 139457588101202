import React, { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Heading } from '@puppet/react-components';
import Breadcrumbs from '@components/Breadcrumbs';
import AddCodeProjectForm, {
  addCodeProjectFormActions,
  AddCodeProjectFormOnChange,
  ADD_CODE_PROJECT_FORM_ACTION_TYPES,
} from '@codeDelivery/components/AddCodeProjectForm';
import useWorkspaceName from '@hooks/useWorkspaceName';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import {
  getSources,
  getRepositories,
  getBranches,
} from '@utils/api/cd4pe/vcs/actions';
import debounce from '@utils/debounce';
import { CreateVcsBranchRequestV1, VcsProviderV1 } from '@utils/api/cd4pe';
import PageLayout from '@components/PageLayout';
import { LINKS } from 'src/routes';
import { CreateModulePayload, newModuleActions, saveModule } from './actions';
import { defaultNewModuleState, reducer } from './reducer';

interface StdItem<K, T> {
  value: K;
  actualValue: T;
}

const getActualValue = <
  ItemType extends StdItem<ValueType, ActualValueType>,
  ValueType = ItemType['value'],
  ActualValueType = ItemType['actualValue'],
>(
  items: ItemType[],
  selectedItem: ItemType['value'],
) => {
  const item = items.find((i) => {
    return i.value === selectedItem;
  });
  if (item) {
    return item.actualValue;
  }
  return null;
};

const debouncedGetRepos = debounce(getRepositories, 250);
const debouncedGetBranches = debounce(getBranches, 250);

const NewModule = () => {
  const [state, dispatch] = useReducer(reducer, defaultNewModuleState);
  const navigate = useNavigate();
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const sourceMatch = getActualValue(state.sources, state.selectedSource);
  const repoMatch = getActualValue(
    state.repositories,
    state.selectedRepository,
  );
  const branchMatch = state.branches.find(
    ({ label }) => label === state.selectedBranch,
  );

  useEffect(() => {
    if (workspaceId) getSources(workspaceId, dispatch);
  }, [workspaceId]);

  useEffect(() => {
    if (state.newModuleName) {
      navigate(
        LINKS.codeDelivery.viewModule({
          path: { workspace: workspaceName, name: state.newModuleName },
        }),
      );
    }
  }, [state.newModuleName, workspaceName, navigate]);

  useEffect(() => {
    if (!state.selectedBranch && state.mainBranch) {
      dispatch(
        addCodeProjectFormActions.selectedBranch(state.mainBranch.label),
      );
    }
  }, [state.mainBranch, state.selectedBranch]);

  useEffect(() => {
    if (state.selectedBranch && !branchMatch && repoMatch) {
      debouncedGetBranches(
        workspaceId,
        dispatch,
        repoMatch,
        state.selectedBranch,
      );
    }
  }, [state.selectedBranch, branchMatch, repoMatch, workspaceId, dispatch]);

  const breadcrumbs = [
    { displayName: `${workspaceName}` },
    {
      displayName: t('newModule.breadcrumb.modules'),
      linkDestination: LINKS.codeDelivery.listModules({
        path: { workspace: workspaceName },
      }),
    },
    { displayName: t('newModule.header.text') },
  ];

  const onFormChange: AddCodeProjectFormOnChange = (fieldName, values) => {
    dispatch({ type: fieldName, payload: values[fieldName] || '' });

    switch (fieldName) {
      case ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_SOURCE: {
        const selectedSource = getActualValue(
          state.sources,
          values.SELECTED_SOURCE || '',
        );
        if (selectedSource) {
          getRepositories(workspaceId, dispatch, selectedSource);
        }
        break;
      }
      case ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_REPOSITORY: {
        const selectedRepository = getActualValue(
          state.repositories,
          values.SELECTED_REPOSITORY || '',
        );

        if (selectedRepository) {
          dispatch(
            addCodeProjectFormActions.name(
              selectedRepository.displayName || '',
            ),
          );
          getBranches(workspaceId, dispatch, selectedRepository);
        }
        break;
      }
      default:
    }
  };

  const dispatchCreateMainBranch = (payload: boolean) => () => {
    dispatch(newModuleActions.createMainBanch(payload));
  };

  const adaptProviderForBackend = (provider: VcsProviderV1): VcsProviderV1 => {
    const p = provider.replace(/\s+/g, '').toUpperCase();
    if (p === 'BITBUCKETCLOUD') {
      return 'BITBUCKET';
    }
    return p as VcsProviderV1;
  };

  const onClickSaveModule = async () => {
    dispatch(newModuleActions.saveModuleStart());

    if (!repoMatch || !sourceMatch || !branchMatch) {
      dispatch(
        newModuleActions.saveModuleError(t('newModule.error.missingFields')),
      );
      return;
    }

    const provider = adaptProviderForBackend(repoMatch.provider);
    const srcRepoName = repoMatch.name;
    const srcRepoDisplayName = repoMatch.displayName || '';
    const srcRepoDisplayOwner = sourceMatch.displayName || '';

    const srcRepoOwner =
      repoMatch.organization || repoMatch.project || repoMatch.user || '';
    let srcRepoId = srcRepoOwner;
    if (provider === 'AZURE_DEVOPS' || provider === 'GITLAB') {
      srcRepoId = repoMatch.project || '';
    }

    let sanitizedCreateMainBranch = state.createMainBranch;
    if (state.mainBranch) {
      sanitizedCreateMainBranch = false;
    }

    const modulePayload: CreateModulePayload = {
      workspaceId,
      requestBody: {
        scanForPacFile: true,
        pacBranch: state.selectedBranch,
        name: state.name,
        srcRepoProvider: provider,
        srcRepoOwner,
        srcRepoDisplayOwner,
        srcRepoName,
        srcRepoDisplayName,
        srcRepoId,
      },
    };

    if (sanitizedCreateMainBranch) {
      const targetBranch = state.branches.find(
        (branch) => branch.actualValue.name === state.selectedBranch,
      );

      if (!targetBranch) {
        dispatch(
          newModuleActions.saveModuleError(t('newModule.error.missingFields')),
        );
        return;
      }

      const branchPayload: CreateVcsBranchRequestV1 = {
        provider,
        project: repoMatch.project || '',
        organization: repoMatch.organization || '',
        repositoryName: srcRepoName,
        sha: targetBranch.actualValue.headSha || '',
        user: repoMatch.user || '',
        name: 'main',
      };

      saveModule(dispatch, modulePayload, branchPayload);
      return;
    }
    saveModule(dispatch, modulePayload);
  };

  const onClickCancel = () => {
    navigate(
      LINKS.codeDelivery.listModules({ path: { workspace: workspaceName } }),
    );
  };

  const repoFilterCallback = (filterTerm: string) => {
    debouncedGetRepos(workspaceId, dispatch, sourceMatch, filterTerm);
  };

  const dispatchCloseModal = () => {
    dispatch(newModuleActions.closeModal());
  };

  return (
    <PageLayout data-testid="add-module-view" className="add-module-view">
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('newModule.header.text')}</Heading>
      </PageLayout.Header>

      <PageLayout.Content>
        <AddCodeProjectForm
          workspaceName={workspaceName}
          type="module"
          sources={state.sources}
          selectedSource={state.selectedSource}
          getSourcesLoading={state.getSourcesLoading}
          getSourcesError={state.getSourcesError}
          repositories={state.repositories}
          repoFilterCallback={repoFilterCallback}
          selectedRepository={state.selectedRepository}
          getReposLoading={state.getReposLoading}
          getReposError={state.getReposError}
          branches={state.branches}
          selectedBranch={state.selectedBranch}
          mainBranch={state.mainBranch}
          getBranchesLoading={state.getBranchesLoading}
          getBranchesError={state.getBranchesError}
          createMainBranch={state.createMainBranch}
          name={state.name}
          saveLoading={state.saveModuleLoading}
          saveError={state.saveModuleError}
          onFormChange={onFormChange}
          createMainBranchChange={dispatchCreateMainBranch}
          submitCallback={onClickSaveModule}
          cancelCallback={onClickCancel}
          webhookError={state.saveModuleWebhookError}
          closeModal={dispatchCloseModal}
          currentModalClosed={state.currentModalClosed}
        />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default NewModule;
