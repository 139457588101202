import { Component } from 'react';
import PropTypes from 'prop-types';

class RenderAfterTimeout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldDisplayNewContent: false,
    };
  }

  componentDidMount() {
    const { showNewContent, timeoutInMilliseconds } = this.props;
    if (showNewContent) {
      this.startTimer(timeoutInMilliseconds);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showNewContent) {
      const { timeoutInMilliseconds } = this.props;
      this.startTimer(timeoutInMilliseconds);
    } else {
      this.clearTimer();
      this.setState({
        shouldDisplayNewContent: false,
      });
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  startTimer(timeoutInMilliseconds) {
    this.clearTimer();

    if (timeoutInMilliseconds === 0) {
      this.setState({
        shouldDisplayNewContent: true,
      });
    } else {
      this.timeoutId = window.setTimeout(() => {
        this.setState({
          shouldDisplayNewContent: true,
        });
      }, timeoutInMilliseconds);
    }
  }

  clearTimer() {
    window.clearTimeout(this.timeoutId);
  }

  render() {
    const { defaultContent, newContent } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    return this.state.shouldDisplayNewContent ? newContent : defaultContent;
  }
}

export default RenderAfterTimeout;

RenderAfterTimeout.propTypes = {
  /** The initial Content being displayed */
  defaultContent: PropTypes.node,
  /** The secondary content to be displayed */
  newContent: PropTypes.node,
  /** Toggle content shown */
  showNewContent: PropTypes.bool,
  /** Time before newContent is displayed */
  timeoutInMilliseconds: PropTypes.number,
};

RenderAfterTimeout.defaultProps = {
  defaultContent: null,
  newContent: null,
  showNewContent: false,
  timeoutInMilliseconds: 500,
};
