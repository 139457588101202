import Breadcrumbs from '@components/Breadcrumbs';
import PageLayout from '@components/PageLayout';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import useWorkspaceName from '@hooks/useWorkspaceName';
import {
  useGetJobInstanceV1,
  useRerunJobInstanceV1,
} from '@services/cd4pe/jobs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LINKS } from 'src/routes';
import {
  Heading,
  Text,
  Badge,
  Loading,
  Button,
  Tabs,
  ConfirmationModal,
} from '@puppet/react-components';
import { JobInstanceResponseV1, TaskStateV1 } from '@puppet/cd4pe-client-ts';
import { FAILED_STATES, IN_PROGRESS_STATES } from '@utils/constants';
import JobDetailsTab from './components/JobDetailsTab';
import JobDetailsCommand from './components/JobDetailsCommand';
import JobDetailsStatusBar from './components/JobDetailsStatusBar';

type TabId = 'JOB' | 'COMMANDS';

const getCodeProjectUrl = (
  codeProjectType: JobInstanceResponseV1['codeProjectType'],
  workspaceName: string,
  codeProjectName: string,
) => {
  switch (codeProjectType) {
    case 'MODULE':
      return LINKS.codeDelivery.viewModule({
        path: { workspace: workspaceName, name: codeProjectName },
      });
    case 'CONTROL_REPO':
      return LINKS.codeDelivery.viewRepository({
        path: { workspace: workspaceName, name: codeProjectName },
      });
    default:
      return undefined;
  }
};

const JobDetails = () => {
  const { t } = useTranslation('codeDelivery');
  const navigate = useNavigate();
  const workspaceName = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const [rerunModalOpen, setRerunModalOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<TabId>('JOB');
  const { jobInstanceId } = useParams() as { jobInstanceId: string };
  const { data: jobInstance, isLoading } = useGetJobInstanceV1(
    {
      workspaceId,
      instanceId: +jobInstanceId,
    },
    {
      refetchInterval: (data) => (data?.state === 'RUNNING' ? 2000 : false),
    },
  );
  const rerunJobInstance = useRerunJobInstanceV1();

  const breadcrumbs = [
    {
      displayName: t('jobDetails.breadcrumbs.jobs'),
      linkDestination: LINKS.codeDelivery.listJobTemplates({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: jobInstance?.codeProjectName ?? '',
      linkDestination: getCodeProjectUrl(
        jobInstance?.codeProjectType,
        workspaceName,
        jobInstance?.codeProjectName || '',
      ),
      dataTestId: 'job-details-breadcrumb-code-project',
    },
    {
      displayName: t('jobDetails.breadcrumbs.jobDetails'),
    },
  ];

  const onRerunSuccess = (newId: number) => {
    setRerunModalOpen(false);
    navigate(
      LINKS.codeDelivery.jobDetails({
        path: { workspace: workspaceName, jobInstanceId: newId },
      }),
    );
  };

  const getStatusType = (status: TaskStateV1) => {
    if (FAILED_STATES.includes(status)) {
      return 'danger';
    }

    if (status === 'DONE') {
      return 'success';
    }

    if (IN_PROGRESS_STATES.includes(status)) {
      return 'info';
    }

    return 'neutral';
  };

  return (
    <PageLayout>
      {jobInstance && (
        <>
          <PageLayout.Header>
            <PageLayout.Breadcrumbs>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </PageLayout.Breadcrumbs>
            <div className="job-details-heading__container">
              <div className="job-details-heading__left">
                <Heading data-testid="job-details-header">
                  {jobInstance.name}
                </Heading>
                <Badge
                  weight="subtle"
                  type={getStatusType(jobInstance.state)}
                  aria-label={t('jobDetails.status.ariaLabel')}
                >
                  {t(`jobDetails.status.${jobInstance.state.toLowerCase()}`, {
                    defaultValue: jobInstance.state,
                  })}
                </Badge>
              </div>
              <div className="job-details-heading__right">
                {IN_PROGRESS_STATES.includes(jobInstance.state) && (
                  <Loading
                    className="job-details-heading-status__loading"
                    data-testid="job-details-loader-running"
                  />
                )}
                <Button
                  onClick={() => setRerunModalOpen(true)}
                  data-testid="job-details-rerun-job-btn"
                >
                  {t('jobDetails.rerunJob.label')}
                </Button>
              </div>
            </div>
            <Text aria-label={t('jobDetails.header.description')}>
              {jobInstance.description}
            </Text>
            <JobDetailsStatusBar jobInstance={jobInstance} />
          </PageLayout.Header>
          <PageLayout.Content className="job-details__content">
            <Tabs
              className="job-details__tabs"
              onChange={(tId: TabId) => setActiveTab(tId)}
            >
              <Tabs.Tab
                title={t('jobDetails.tabs.job.label')}
                id="JOB"
                active={activeTab === 'JOB'}
              >
                <JobDetailsTab
                  name={jobInstance.name}
                  jobDetailsError={jobInstance.error ?? ''}
                  taskState={jobInstance.state}
                />
              </Tabs.Tab>
              <Tabs.Tab
                title={t('jobDetails.tabs.commands.label')}
                id="COMMANDS"
                data-testid="commands-tab"
                active={activeTab === 'COMMANDS'}
              >
                <JobDetailsCommand
                  command={jobInstance.manifest?.build}
                  name="build"
                  label={t('jobDetails.tab.commands.build.label')}
                  active={activeTab === 'COMMANDS'}
                />
                <JobDetailsCommand
                  command={jobInstance.manifest?.onSuccess}
                  name="onSuccess"
                  label={t('jobDetails.tab.commands.onSuccess.label')}
                  active={activeTab === 'COMMANDS'}
                />
                <JobDetailsCommand
                  command={jobInstance.manifest?.onError}
                  name="onFailure"
                  label={t('jobDetails.tab.commands.onFailure.label')}
                  active={activeTab === 'COMMANDS'}
                />
              </Tabs.Tab>
            </Tabs>
          </PageLayout.Content>
          <ConfirmationModal
            isOpen={rerunModalOpen}
            title={t('jobDetails.rerun.modal.title')}
            description={t('jobDetails.rerun.modal.description', {
              jobName: jobInstance.name,
            })}
            confirmLabel={t('jobDetails.rerun.modal.confirm.label')}
            cancelLabel={t('jobDetails.rerun.modal.cancel.label')}
            onConfirm={() =>
              rerunJobInstance.mutate(
                {
                  workspaceId,
                  instanceId: jobInstance.id,
                },
                { onSuccess: ({ instanceId }) => onRerunSuccess(instanceId) },
              )
            }
            onCancel={() => setRerunModalOpen(false)}
          />
        </>
      )}
      {isLoading && <Loading data-testid="job-details-loader" />}
    </PageLayout>
  );
};

export default JobDetails;
