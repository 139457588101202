import React from 'react';
import { Card, Text, Icon } from '@puppet/react-components';
import ConditionalRender from 'inventory/components/ConditionalRender';
import PropTypes from 'prop-types';
import './CardBoardEmptyCard.scss';

const propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const CardBoardEmptyCard = ({ text, onClick, ...props }) => (
  <Card
    data-testid="empty-card"
    className="cb-empty-card-container"
    onClick={onClick}
    {...props}
  >
    <ConditionalRender enable={Boolean(text)}>
      <div className="cb-content-container">
        <Icon type="plus" className="cb-empty-card-icon" {...props} />
        <Text className="cb-empty-card-text" {...props}>
          {text}
        </Text>
      </div>
    </ConditionalRender>
  </Card>
);

export default CardBoardEmptyCard;

CardBoardEmptyCard.propTypes = propTypes;
