import React, { useEffect, useState } from 'react';
import { Icon, Button, Code, Loading, Text } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useParams } from 'react-router-dom';
import { useGetJobInstanceLogV1 } from '@services/cd4pe/jobs';
import { useTranslation } from 'react-i18next';
import download from 'downloadjs';
import Cd4peError from '@components/Cd4peError';
import { TaskStateV1 } from '@puppet/cd4pe-client-ts';
import BannerDropdown from '@components/BannerDropdown';
import { FAILED_STATES, IN_PROGRESS_STATES } from '@utils/constants';

type Props = {
  name: string;
  jobDetailsError?: string;
  taskState: TaskStateV1;
};

const JobDetailsTab = ({ name, jobDetailsError = '', taskState }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const { jobInstanceId } = useParams() as { jobInstanceId: string };
  const [logs, setLogs] = useState<Array<string>>([]);
  const { data, isLoading, error } = useGetJobInstanceLogV1({
    workspaceId,
    instanceId: +jobInstanceId,
  });

  useEffect(() => {
    if (data?.log) {
      setLogs(atob(data.log).split('\n'));
    }
  }, [data]);

  if (isLoading) {
    return <Loading data-testid="job-details-tab-spinner" size="small" />;
  }

  if (error) {
    return <Cd4peError error={error} />;
  }

  const getLogs = () => {
    if (!logs || logs.length === 0) {
      return (
        <div className="job-details-tab__empty-state">
          <Text color="subtle">
            {t('jobDetails.tab.emptyState.text', { name })}
          </Text>
        </div>
      );
    }

    return (
      <div className="job-details-tab-logs" data-testid="job-details-tab-logs">
        <div className="job-details-tab-logs__header">
          <Button
            type="text"
            trailingIcon="download"
            onClick={() =>
              download(
                logs.join('\n'),
                `${data?.logFileName}.log`,
                'text/plain',
              )
            }
          >
            {t('jobDetails.tab.logs.download.button')}
          </Button>
        </div>
        <div className="job-details-tab-logs__main">
          <Code size="small">
            {logs.map((line) => (
              <p>{line}</p>
            ))}
          </Code>
        </div>
      </div>
    );
  };

  const getBanner = () => {
    if (IN_PROGRESS_STATES.includes(taskState)) {
      return (
        <BannerDropdown
          icon={<Loading className="job-details-icon__loading" />}
          message={t('jobDetails.tab.alert.inProgress')}
          data-testid="job-details-tab-in-progress-banner"
        >
          {getLogs()}
        </BannerDropdown>
      );
    }

    if (taskState === 'DONE') {
      return (
        <BannerDropdown
          icon={<Icon type="check-circle" className="job-details-icon__done" />}
          message={t('jobDetails.tab.alert.done', {
            message:
              logs.length > 0 && logs[logs.length - 1] !== ''
                ? logs[logs.length - 1]
                : t('jobDetails.tab.alert.done.default'),
          })}
          data-testid="job-details-tab-done-banner"
        >
          {getLogs()}
        </BannerDropdown>
      );
    }

    if (jobDetailsError || FAILED_STATES.includes(taskState)) {
      return (
        <BannerDropdown
          icon={<Icon type="error" className="job-details-icon__error" />}
          message={t('jobDetails.tab.alert.error', { jobDetailsError })}
          defaultOpen
          data-testid="job-details-tab-failed-banner"
        >
          {getLogs()}
        </BannerDropdown>
      );
    }

    return (
      <BannerDropdown
        icon={<Icon type="minus-circle" />}
        message=""
        data-testid="job-details-tab-neutral-banner"
      >
        {getLogs()}
      </BannerDropdown>
    );
  };

  return (
    <div className="job-details-tab" data-testid="job-details-tab">
      {getBanner()}
    </div>
  );
};

export default JobDetailsTab;
