import { useState, useEffect } from 'react';

const useTableDisplayMessageGenerator = ({
  loading,
  searchTerm,
  tableDisplayMessages,
}) => {
  const [emptyStateHeader, setEmptyStateHeader] = useState('');
  const [emptyStateMessage, setEmptyStateMessage] = useState('');
  useEffect(() => {
    if (loading) {
      setEmptyStateHeader(tableDisplayMessages.loadingHeader);
      setEmptyStateMessage(tableDisplayMessages.loadingMessage);
    } else if (searchTerm) {
      setEmptyStateHeader(tableDisplayMessages.noDataHeader);
      setEmptyStateMessage(tableDisplayMessages.emptyFilterMessage);
    } else {
      setEmptyStateHeader(tableDisplayMessages.noDataHeader);
      setEmptyStateMessage('');
    }
  }, [
    loading,
    searchTerm,
    tableDisplayMessages.loadingHeader,
    tableDisplayMessages.loadingMessage,
    tableDisplayMessages.emptyFilterMessage,
    tableDisplayMessages.noDataHeader,
  ]);

  return {
    emptyStateHeader,
    emptyStateMessage,
  };
};

export default useTableDisplayMessageGenerator;
