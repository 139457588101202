import CommonForm from '@components/CommonForm';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, RadioButton } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useConnectGithubEnterpriseV1 } from '@services/cd4pe/vcs';
import { ConnectGithubEnterpriseVcsIntegrationRequestV1 } from '@utils/api/cd4pe';
import * as Provider from '@utils/vcs/providerConsts';
import getProviderDisplayName from '@utils/vcs/displayNameHelper';
import Cd4peError from '@components/Cd4peError';

const GitHubEnterpriseForm = ({
  onSuccess,
  onCancel,
}: {
  onSuccess: (provider: string) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const [showToken, setShowToken] = useState(false);
  const [certType, setCertType] = useState<'standard' | 'custom'>('custom');
  const [formValues, setFormValues] =
    useState<ConnectGithubEnterpriseVcsIntegrationRequestV1>({
      host: '',
      token: '',
      caCertificate: '',
    });

  const connectGithubEnterprise = useConnectGithubEnterpriseV1();

  const changeCertType = (type: 'standard' | 'custom') => {
    setFormValues({ ...formValues, caCertificate: '' });
    setCertType(type);
  };

  const onSubmit = async () => {
    // in the case of a 'standard' cert type we dont use the caCertificate
    // field so no need to send it down
    if (certType === 'standard') {
      delete formValues.caCertificate;
    }
    return connectGithubEnterprise.mutate(
      {
        workspaceId,
        requestBody: formValues,
      },
      {
        onSuccess: () => {
          onSuccess(getProviderDisplayName(Provider.GITHUB_ENTERPRISE));
        },
      },
    );
  };

  return (
    <CommonForm
      values={formValues}
      onChange={(_, values) =>
        setFormValues(values as ConnectGithubEnterpriseVcsIntegrationRequestV1)
      }
      submittable
      cancellable
      className="git-hub-enterprise-form"
      submitLabel={t('addSourceControl.form.submitButton')}
      submitting={connectGithubEnterprise.isLoading}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <CommonForm.Section className="add-source-control-credentials">
        <CommonForm.Section.Main>
          <h3 className="add-source-control-form-section__header">
            {t('addSourceControl.credentials.header')}
          </h3>
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              name="host"
              type="text"
              label={t('addSourceControl.form.host.label')}
              required
            />
          </div>
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              type={showToken ? 'text' : 'password'}
              trailingButtonIcon="eye"
              trailingButtonProps={{
                'aria-label': t('addSourceControl.form.token.ariaLabel'),
              }}
              onClickTrailingButton={() => setShowToken(!showToken)}
              name="token"
              label={t('addSourceControl.form.token.label')}
              required
            />
          </div>
          <div className="add-source-control-form-credentials-field__wrapper add-source-control-form-radio__wrapper">
            <RadioButton
              className="add-source-control-form-cert-type__radio"
              label={t('addSourceControl.form.cert.standard')}
              name="radio-standard"
              value={certType === 'standard'}
              onChange={() => changeCertType('standard')}
            />
            <RadioButton
              className="add-source-control-form-cert-type__radio"
              label={t('addSourceControl.form.cert.custom')}
              name="radio-custom"
              value={certType === 'custom'}
              onChange={() => changeCertType('custom')}
            />
          </div>
          {certType === 'custom' && (
            <div className="add-source-control-form-credentials-field__wrapper">
              <Form.Field
                name="caCertificate"
                type="multiline"
                label={t('addSourceControl.form.cert.label')}
                required={certType === 'custom'}
              />
            </div>
          )}
        </CommonForm.Section.Main>
      </CommonForm.Section>
      <div className="add-source-control-form-error">
        <Cd4peError error={connectGithubEnterprise.error} />
      </div>
    </CommonForm>
  );
};

export default GitHubEnterpriseForm;
