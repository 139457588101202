import { Trans, useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { Button, Form, Heading, Text } from '@puppet/react-components';
import CommonForm from '@components/CommonForm';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteWorkspaceV1,
  useUpdateWorkspaceV1,
} from '@services/cd4pe/workspaces';
import ConfirmDeleteModal from '@components/ConfirmDeleteModal';
import { formatError } from '@utils/api/cd4pe';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import { LINKS } from 'src/routes';
import session from '@state/session/session';

const WorkspaceSettings = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>('');
  const { t } = useTranslation('codeDelivery');
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const workspaceName = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();

  const navigateToLandingPage = () => {
    navigate('/', { replace: true });
  };

  const updateWorkspace = useUpdateWorkspaceV1();

  const onRenameWorkspace = () => {
    updateWorkspace.mutate(
      { workspaceId, requestBody: { name: newName } },
      {
        onSuccess: () => {
          appDispatch(
            session.actions.renameWorkspace({ id: workspaceId, name: newName }),
          );

          navigate(
            LINKS.settings.workspace({
              path: { workspace: newName },
            }),
          );

          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('workspaceSettings.rename.alert.success'),
            }),
          );
        },
      },
    );
  };

  const deleteWorkspace = useDeleteWorkspaceV1();

  const onDelete = () => {
    deleteWorkspace.mutate(
      { workspaceId },
      {
        onSuccess: () => {
          appDispatch(session.actions.removeWorkspace(workspaceId));
          navigateToLandingPage();

          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('workspaceSettings.delete.alert.success'),
            }),
          );
        },
      },
    );
  };

  return (
    <div className="workspace-settings-wrapper">
      <div className="workspace-settings-header">
        <Heading as="h2">{t('workspaceSettings.header.text')}</Heading>
      </div>
      <div className="workspace-settings-div">
        <div className="rename-workspace-div">
          <Heading as="h3">{t('workspaceSettings.rename.title')}</Heading>
          <Text>{t('workspaceSettings.rename.description')}</Text>
          <CommonForm
            data-testid="rename-workspace-form"
            className="workspace-settings-rename-form"
            submittable
            submitLabel={t('workspaceSettings.rename.submitButton')}
            submitDisabled={newName === workspaceName}
            values={{ newName }}
            onSubmit={onRenameWorkspace}
            onChange={(_f, formVals) => setNewName(formVals.newName ?? '')}
            error={updateWorkspace.error && formatError(updateWorkspace.error)}
          >
            <Form.Field
              data-testid="rename-workspace-input"
              type="text"
              name="newName"
              label={t('workspaceSettings.rename.name.label')}
              placeholder={t('workspaceSettings.rename.name.placeholder')}
              required
            />
          </CommonForm>
        </div>
        <div className="workspace-settings-div">
          <Heading as="h3">{t('workspaceSettings.delete.title')}</Heading>
          <Text>{t('workspaceSettings.delete.description')}</Text>
          <Button
            data-testid="delete-workspace-modal-open"
            className="workspace-settings-delete-button"
            type="danger"
            weight="subtle"
            onClick={() => setModalOpen(true)}
          >
            {t('workspaceSettings.delete.button')}
          </Button>
          <ConfirmDeleteModal
            isOpen={modalOpen}
            onCancel={() => setModalOpen(false)}
            onDelete={onDelete}
            confirmTextEntry={workspaceName}
            textEntryAriaLabel={t(
              'workspaceSettings.delete.modal.textEntryAriaLabel',
            )}
            title={t('workspaceSettings.delete.modal.title')}
            alert={t('workspaceSettings.delete.modal.warning')}
            description={
              <>
                <p>
                  <Trans
                    t={t}
                    i18nKey="workspaceSettings.delete.modal.description"
                    values={{ workspaceName }}
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>{t('workspaceSettings.delete.modal.instructions')}</p>
              </>
            }
            confirmLabel={t('workspaceSettings.delete.modal.confirmButton')}
            error={deleteWorkspace.error}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSettings;
