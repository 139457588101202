import { Cd4peApiError, SshKeyPairV1 } from '@utils/api/cd4pe';
import { Reducer } from 'react';
import { SshKeyActions } from './actions';
import * as at from './actionTypes';

export interface SshKeyState {
  sshKey?: SshKeyPairV1 | null;
  getSshKeyLoading: boolean;
  getSshKeyError: null | Cd4peApiError;
  createSshKeyLoading: boolean;
  createSshKeyError: null | Cd4peApiError;
  deleteSshKeyLoading: boolean;
  deleteSshKeyError: null | Cd4peApiError;
}

export const sshKeyDefaultState: SshKeyState = {
  sshKey: undefined,
  getSshKeyLoading: false,
  getSshKeyError: null,
  createSshKeyLoading: false,
  createSshKeyError: null,
  deleteSshKeyLoading: false,
  deleteSshKeyError: null,
};

export const reducer: Reducer<SshKeyState, SshKeyActions> = (state, action) => {
  switch (action.type) {
    case at.GET_SSH_KEY_LOADING:
      return {
        ...state,
        getSshKeyLoading: true,
      };
    case at.GET_SSH_KEY_COMPLETE:
      return {
        ...state,
        sshKey: action.payload,
        getSshKeyLoading: false,
        getSshKeyError: null,
      };
    case at.GET_SSH_KEY_ERROR:
      return {
        ...state,
        sshKey: null,
        getSshKeyLoading: false,
        getSshKeyError: action.payload,
      };
    case at.CREATE_SSH_KEY_LOADING:
      return {
        ...state,
        createSshKeyLoading: true,
      };
    case at.CREATE_SSH_KEY_COMPLETE:
      return {
        ...state,
        sshKey: action.payload,
        createSshKeyLoading: false,
        createSshKeyError: null,
      };
    case at.CREATE_SSH_KEY_ERROR:
      return {
        ...state,
        createSshKeyLoading: false,
        createSshKeyError: action.payload,
      };
    case at.DELETE_SSH_KEY_LOADING:
      return {
        ...state,
        deleteSshKeyLoading: true,
      };
    case at.DELETE_SSH_KEY_COMPLETE:
      return {
        ...state,
        sshKey: null,
        deleteSshKeyLoading: false,
        deleteSshKeyError: null,
        showActionsMenu: false,
      };
    case at.DELETE_SSH_KEY_ERROR:
      return {
        ...state,
        deleteSshKeyLoading: false,
        deleteSshKeyError: action.payload,
      };
    default:
      return state;
  }
};
