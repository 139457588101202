import Breadcrumbs from '@components/Breadcrumbs';
import { Heading } from '@puppet/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useWorkspaceName from '@hooks/useWorkspaceName';
import PageLayout from '@components/PageLayout';
import { LINKS } from 'src/routes';

const AddSourceControlHeader = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const breadcrumbs = [
    { displayName: workspaceName },
    {
      displayName: t('addSourceControl.breadcrumbs.settingsPage'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('addSourceControl.breadcrumbs.sourceControlPage'),
      linkDestination: LINKS.settings.listSourceControl({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('addSourceControl.breadcrumbs.addSourceControlPage'),
    },
  ];

  return (
    <PageLayout.Header>
      <PageLayout.Breadcrumbs>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </PageLayout.Breadcrumbs>
      <Heading>{t('addSourceControl.header.text')} </Heading>
    </PageLayout.Header>
  );
};

export default AddSourceControlHeader;
