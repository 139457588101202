import { PermittedV1 } from '@utils/api/cd4pe';
import * as ACTION_TYPES from './actionTypes';
import { NodeExplorerActions } from './actions';

export interface NodeExplorerState {
  evalPermissions: PermittedV1['results'];
  evalPermissionsError: string | null;
  evalPermissionsLoading: boolean;
}

export const nodeExplorerDefaultState: NodeExplorerState = {
  evalPermissions: [],
  evalPermissionsError: null,
  evalPermissionsLoading: true,
};

export const reducer = (
  state: NodeExplorerState,
  action: NodeExplorerActions,
): NodeExplorerState => {
  switch (action.type) {
    case ACTION_TYPES.EVAL_PERMISSIONS_START:
      return {
        ...state,
        evalPermissionsError: null,
        evalPermissionsLoading: true,
      };
    case ACTION_TYPES.EVAL_PERMISSIONS_COMPLETE:
      return {
        ...state,
        evalPermissions: action.payload.results,
        evalPermissionsError: null,
        evalPermissionsLoading: false,
      };
    case ACTION_TYPES.EVAL_PERMISSIONS_ERROR:
      return {
        ...state,
        evalPermissionsLoading: false,
        evalPermissionsError: action.payload,
      };
    default:
      throw new Error();
  }
};
