import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWorkspaceName from '@hooks/useWorkspaceName';
import Breadcrumbs from '@components/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import ManualIntegrationForm, {
  ManualIntegrationFormOnChange,
  ManualIntegrationFormValues,
} from '@codeDelivery/components/AddPeIntegrationForm/components/ManualIntegrationForm';
import { Loading, Heading } from '@puppet/react-components';
import {
  useGetPeIntegrationV2,
  useUpdatePeIntegrationV2,
} from '@services/cd4pe/peIntegrations';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import PageLayout from '@components/PageLayout';
import Cd4peError from '@components/Cd4peError';
import { LINKS } from 'src/routes';

const EditPeIntegration = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const { peId: peIntegrationId } = useParams<{ peId: string }>();
  const [formData, setFormData] = useState<ManualIntegrationFormValues>({
    name: '',
    puppetServer: '',
    apiToken: '',
    caCert: '',
    classifier: '',
    codeManager: '',
    compileBatchSize: '',
    ignoreSyncErrors: false,
    orchestrator: '',
    puppetDb: '',
  });

  const navigateToPeList = () =>
    navigate(
      LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceName },
      }),
    );

  const {
    isLoading,
    error,
    data: peIntegration,
  } = useGetPeIntegrationV2({ peIntegrationId: peIntegrationId! });

  useEffect(() => {
    if (peIntegration) {
      setFormData({
        caCert: peIntegration.caCertificate,
        classifier: peIntegration.nodeClassifierUrl,
        codeManager: peIntegration.codeManagerUrl,
        compileBatchSize: String(peIntegration.compileBatchSize),
        name: peIntegration.name,
        orchestrator: peIntegration.orchestratorUrl,
        puppetDb: peIntegration.puppetDbUrl,
        puppetServer: peIntegration.puppetServerUrl || '',
        ignoreSyncErrors: peIntegration.ignoreCodeManagerSyncErrors || false,
        apiToken: '',
      });
    }
  }, [peIntegration]);

  const updateIntegration = useUpdatePeIntegrationV2();

  const onSubmit = async () =>
    updateIntegration.mutate(
      {
        peIntegrationId: peIntegrationId!,
        requestBody: {
          puppetServerUrl: formData.puppetServer,
          puppetDbUrl: formData.puppetDb,
          codeManagerUrl: formData.codeManager,
          orchestratorUrl: formData.orchestrator,
          nodeClassifierUrl: formData.classifier,
          caCertificate: formData.caCert,
          compileBatchSize: Number(formData.compileBatchSize),
          ignoreCodeManagerSyncErrors: formData.ignoreSyncErrors,
          token: formData.apiToken || undefined,
        },
      },
      {
        onSuccess: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('editPeIntegration.alerts.success'),
            }),
          );
          navigateToPeList();
        },
      },
    );

  const onEditFormChange: ManualIntegrationFormOnChange = (
    fieldName,
    values,
  ) => {
    if (fieldName === 'ignoreSyncErrors') {
      setFormData({
        ...formData,
        ignoreSyncErrors: values.ignoreSyncErrors ?? false,
      });
      return;
    }
    setFormData({ ...formData, [fieldName]: values[fieldName] ?? '' });
  };

  const breadcrumbs = [
    { displayName: workspaceName },
    {
      displayName: t('editPeIntegration.breadcrumbs.settingsPage'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('editPeIntegration.breadcrumbs.peIntegrationPage'),
      linkDestination: LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('editPeIntegration.breadcrumbs.editPeIntegrationPage'),
    },
  ];

  return (
    <PageLayout
      className="edit-pe-integration-page"
      data-testid="edit-pe-integration-form"
    >
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('editPeIntegration.header.text')}</Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        {isLoading && (
          <Loading
            data-testid="loading-spinner"
            className="loading"
            size="small"
          />
        )}
        {peIntegration && (
          <ManualIntegrationForm
            name={formData.name}
            apiToken={formData.apiToken}
            puppetServer={formData.puppetServer}
            puppetDb={formData.puppetDb}
            codeManager={formData.codeManager}
            orchestrator={formData.orchestrator}
            classifier={formData.classifier}
            caCert={formData.caCert}
            compileBatchSize={formData.compileBatchSize}
            ignoreSyncErrors={formData.ignoreSyncErrors}
            onFormChange={onEditFormChange}
            submitCallback={onSubmit}
            cancelCallback={() => navigateToPeList()}
            saveLoading={updateIntegration.isLoading}
            saveError={updateIntegration.error}
            submitLabel={t(
              `editPeIntegration.manualIntegrationForm.submitButton`,
            )}
            updateIntegration
          />
        )}
        <Cd4peError error={error} />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default EditPeIntegration;
