export const PARAMETER_TYPES = {
  STRING: 'String',
  INTEGER: 'Integer',
  FLOAT: 'Float',
  NUMERIC: 'Numeric',
  BOOLEAN: 'Boolean',
  ARRAY: 'Array',
  HASH: 'Hash',
  REGEXP: 'Regexp',
  TIMESPAN: 'Timespan',
  TIMESTAMP: 'Timestamp',
  UNDEF: 'Undef',
  DEFAULT: 'Default',
};

export const OPTIONAL_PARAMETER_TYPES = {
  STRING: `Optional[${PARAMETER_TYPES.STRING}]`,
  INTEGER: `Optional[${PARAMETER_TYPES.INTEGER}]`,
  FLOAT: `Optional[${PARAMETER_TYPES.FLOAT}]`,
  NUMERIC: `Optional[${PARAMETER_TYPES.NUMERIC}]`,
  BOOLEAN: `Optional[${PARAMETER_TYPES.BOOLEAN}]`,
  ARRAY: `Optional[${PARAMETER_TYPES.ARRAY}]`,
  HASH: `Optional[${PARAMETER_TYPES.HASH}]`,
  REGEXP: `Optional[${PARAMETER_TYPES.REGEXP}]`,
  TIMESPAN: `Optional[${PARAMETER_TYPES.TIMESPAN}]`,
  TIMESTAMP: `Optional[${PARAMETER_TYPES.TIMESTAMP}]`,
  UNDEF: `Optional[${PARAMETER_TYPES.UNDEF}]`,
  DEFAULT: `Optional[${PARAMETER_TYPES.DEFAULT}]`,
};

export const DEPLOYMENT_POLICY_TYPES = {
  FEATURE_BRANCH_POLICY: 'cd4pe_deployments::feature_branch',
  EVENTUAL_CONSISTENCY_POLICY: 'cd4pe_deployments::eventual_consistency',
  DIRECT_DEPLOYMENT_POLICY: 'cd4pe_deployments::direct',
  TEMPORARY_BRANCH_POLICY: 'cd4pe_deployments::rolling',
};
