import React, { useState } from 'react';
import {
  useDeletePipelineStagesV1,
  useUpsertPipelineStagesV1,
} from '@services/cd4pe/pipelines';
import { Checkbox, Modal, Button, Icon, Text } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import { deleteStage } from '@codeDelivery/components/ViewPipeline/utils';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { ProjectPipelineStageV1 } from '@utils/api/cd4pe';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import Cd4peError from '@components/Cd4peError';

interface Props {
  stageNumber: number;
  name: string;
  pipelineId: string;
  projectName: string;
  stages: ProjectPipelineStageV1[];
  onClose: () => void;
}

const DeleteStage = ({
  stageNumber,
  name,
  pipelineId,
  projectName,
  stages,
  onClose,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const appDispatch = useAppDispatch();
  const [deleteCheck, setDeleteCheck] = useState(false);

  const upsertPipelineStages = useUpsertPipelineStagesV1();
  const deletePipelineStages = useDeletePipelineStagesV1();

  const onSuccessHandler = () => {
    appDispatch(
      alerts.actions.createAlert({
        type: 'Success',
        message: t('viewPipeline.dialog.delete.stage.alerts.delete.success', {
          name:
            stages.find((stage) => stage.stageNum === stageNumber)?.stageName ??
            '',
        }),
      }),
    );
    setDeleteCheck(false);
    onClose();
  };

  const onDeleteStageHandler = async () => {
    if (stageNumber === undefined) {
      return;
    }

    const updatedStages = deleteStage(stageNumber, stages);

    if (updatedStages.length === 0) {
      deletePipelineStages.mutate(
        {
          workspaceId,
          pipelineId,
          projectName,
        },
        {
          onSuccess: onSuccessHandler,
        },
      );
    } else {
      upsertPipelineStages.mutate(
        {
          workspaceId,
          pipelineId,
          requestBody: {
            projectName,
            stages: updatedStages,
          },
        },
        {
          onSuccess: onSuccessHandler,
        },
      );
    }
  };

  return (
    <Modal
      className="delete-stage-modal"
      onClose={() => {
        setDeleteCheck(false);
        onClose();
      }}
    >
      <Modal.Title>{t('viewPipeline.dialog.delete.stage')}</Modal.Title>
      <div className="delete-stage-modal__alert">
        <Text className="delete-stage-modal__alert-icon">
          <Icon type="alert" />
          {t('viewPipeline.dialog.delete.stage.warning')}
        </Text>
        <Checkbox
          name="delete-stage-checkbox"
          size="medium"
          label={
            <Text>
              {t('viewPipeline.dialog.delete.stage.confirmation.name', {
                name: name || stageNumber,
              })}
            </Text>
          }
          value={deleteCheck}
          onChange={() => setDeleteCheck(!deleteCheck)}
          data-testid="delete-stage-checkbox"
        />
      </div>
      <Cd4peError error={deletePipelineStages.error} />
      <Cd4peError error={upsertPipelineStages.error} />
      <Modal.Actions>
        <Button
          type="danger"
          onClick={onDeleteStageHandler}
          loading={
            deletePipelineStages.isLoading || upsertPipelineStages.isLoading
          }
          disabled={!deleteCheck}
          data-testid="delete-stage-delete-button"
        >
          {t('viewPipeline.dialog.delete.stage.confirmation.button.confirm')}
        </Button>
        <Button
          type="danger"
          weight="subtle"
          onClick={() => {
            setDeleteCheck(false);
            onClose();
          }}
          data-testid="delete-stage-cancel-button"
        >
          {t('viewPipeline.dialog.delete.stage.confirmation.button.cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteStage;
