import PropTypes from 'prop-types';

const propTypes = {
  enable: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const ConditionalRender = ({ enable, children }) => {
  if (enable) {
    return children;
  }

  return null;
};

ConditionalRender.propTypes = propTypes;

export default ConditionalRender;
