import queryClient from '@services/queryClient';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Cd4peApiError,
  GroupsV1Service,
  handleQueryRequest,
  MutationHook,
  QueryHook,
} from '@utils/api/cd4pe';

const GROUPS_LIST_QUERY_TAG = 'listGroups';
const GROUPS_GET_GROUP_QUERY_TAG = 'getGroup';
const GROUPS_GET_AVAILABLE_PRIVILEGES_QUERY_TAG = 'getAvailablePrivileges';
const GROUPS_GET_PRIVILEGES_QUERY_TAG = 'getPrivileges';
const GROUPS_LIST_GROUP_MEMBERS = 'listGroupMembers';
const GROUPS_LIST_GROUP_MEMBER_CANDIDATES = 'listGroupMemberCandidates';

/**
 * useListGroupsV1
 */

type UseListGroupsV1 = QueryHook<
  typeof GroupsV1Service,
  'listGroupsV1',
  Cd4peApiError
>;

export const useListGroupsV1: UseListGroupsV1 = (payload) =>
  useQuery([GROUPS_LIST_QUERY_TAG, payload.workspaceId], async () =>
    handleQueryRequest(GroupsV1Service.listGroupsV1(payload)),
  );

/**
 * useGetGroupsV1
 */

type UseGetGroupV1 = QueryHook<
  typeof GroupsV1Service,
  'getGroupV1',
  Cd4peApiError
>;

export const useGetGroupV1: UseGetGroupV1 = (payload, options) =>
  useQuery(
    [GROUPS_GET_GROUP_QUERY_TAG, payload.groupId],
    async () => handleQueryRequest(GroupsV1Service.getGroupV1(payload)),
    options,
  );

/**
 * useGetGroupPrivilegesV1
 */

type UseGetGroupPrivilegesV1 = QueryHook<
  typeof GroupsV1Service,
  'getGroupPrivilegesV1',
  Cd4peApiError
>;

export const useGetGroupPrivilegesV1: UseGetGroupPrivilegesV1 = (payload) =>
  useQuery([GROUPS_GET_PRIVILEGES_QUERY_TAG, payload.groupId], async () =>
    handleQueryRequest(GroupsV1Service.getGroupPrivilegesV1(payload)),
  );

/**
 * useGetAvailableGroupPrivilegesV1
 */

type UseGetAvailableGroupPrivilegesV1 = QueryHook<
  typeof GroupsV1Service,
  'getAvailableGroupPrivilegesV1',
  Cd4peApiError
>;

export const useGetAvailableGroupPrivilegesV1: UseGetAvailableGroupPrivilegesV1 =
  (payload) =>
    useQuery(
      [GROUPS_GET_AVAILABLE_PRIVILEGES_QUERY_TAG, payload.groupId],
      async () =>
        handleQueryRequest(
          GroupsV1Service.getAvailableGroupPrivilegesV1(payload),
        ),
    );

/**
 * useCreateGroupV1
 */

type UseCreateGroupV1 = MutationHook<
  typeof GroupsV1Service,
  'createGroupV1',
  Cd4peApiError
>;

export const useCreateGroupV1: UseCreateGroupV1 = () =>
  useMutation((payload) =>
    handleQueryRequest(GroupsV1Service.createGroupV1(payload)),
  );

/**
 * useSetGroupPrivilegesV1
 */

type UseSetGroupPrivilegesV1 = MutationHook<
  typeof GroupsV1Service,
  'setGroupPrivilegesV1',
  Cd4peApiError
>;

export const useSetGroupPrivilegesV1: UseSetGroupPrivilegesV1 = () => {
  return useMutation(
    (payload) =>
      handleQueryRequest(GroupsV1Service.setGroupPrivilegesV1(payload)),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          GROUPS_GET_PRIVILEGES_QUERY_TAG,
          payload.groupId,
        ]);
      },
    },
  );
};

/**
 * useListGroupMembersV1
 */

type UseListGroupsMembersV1 = QueryHook<
  typeof GroupsV1Service,
  'listGroupMembersV1',
  Cd4peApiError
>;

export const useListGroupsMembersV1: UseListGroupsMembersV1 = (payload) =>
  useQuery([GROUPS_LIST_GROUP_MEMBERS, payload.groupId], async () =>
    handleQueryRequest(GroupsV1Service.listGroupMembersV1(payload)),
  );

/**
 * useDeleteGroupMemberV1
 */

type UseDeleteGroupMemberV1 = MutationHook<
  typeof GroupsV1Service,
  'deleteGroupMemberV1',
  Cd4peApiError
>;

export const useDeleteGroupMemberV1: UseDeleteGroupMemberV1 = () => {
  return useMutation(
    (payload) =>
      handleQueryRequest(GroupsV1Service.deleteGroupMemberV1(payload)),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          GROUPS_LIST_GROUP_MEMBERS,
          payload.groupId,
        ]);
      },
    },
  );
};

/**
 * useListGroupMemberCandidatesV1
 */

type UseListGroupMemberCandidatesV1 = QueryHook<
  typeof GroupsV1Service,
  'listGroupMemberCandidatesV1',
  Cd4peApiError
>;

export const useListGroupMemberCandidatesV1: UseListGroupMemberCandidatesV1 = (
  payload,
) =>
  useQuery([GROUPS_LIST_GROUP_MEMBER_CANDIDATES, payload.groupId], async () =>
    handleQueryRequest(GroupsV1Service.listGroupMemberCandidatesV1(payload)),
  );

/**
 * addGroupMemberV1
 */

type UseAddGroupMembersV1 = MutationHook<
  typeof GroupsV1Service,
  'addGroupMembersV1',
  Cd4peApiError
>;

export const useAddGroupMembersV1: UseAddGroupMembersV1 = () => {
  return useMutation((payload) =>
    handleQueryRequest(GroupsV1Service.addGroupMembersV1(payload)),
  );
};
