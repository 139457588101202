import React, { useEffect, useState } from 'react';
import { Form, Modal } from '@puppet/react-components';
import { TFunction, useTranslation } from 'react-i18next';
import { Cd4peApiError, SecretDetailsV1, SecretTypeV1 } from '@utils/api/cd4pe';
import { SECRET_TYPES } from '@codeDelivery/utils/secrets';
import { CommonFormChangeFunction } from '@components/CommonForm/CommonForm';
import Cd4peError from '@components/Cd4peError';

interface BaseSecretsForm {
  secretName: string;
  secretDescription: string;
}

interface StringSecretsForm extends BaseSecretsForm {
  type: Exclude<SecretTypeV1, 'USERNAME_PASSWORD'>;
  secret: string;
}

interface UsernamePasswordSecretsForm extends BaseSecretsForm {
  type: Extract<SecretTypeV1, 'USERNAME_PASSWORD'>;
  username: string;
  password: string;
}

export type SecretsForm = StringSecretsForm | UsernamePasswordSecretsForm;

interface Props {
  secretDetails?: SecretDetailsV1 | null;
  onSubmit: (formValues: SecretsForm) => void;
  loading: boolean;
  error: Cd4peApiError | null;
  onCancel: () => void;
  onClose: () => void;
}

const defaultState: SecretsForm = {
  secretName: '',
  secretDescription: '',
  type: 'SSH_PRIVATE_KEY',
  secret: '',
};

const secretTypeToDisplayName = (secretType: SecretTypeV1) => {
  switch (secretType) {
    case 'PLAIN_TEXT':
      return 'jobTemplateSecretForm.secretTypes.plainText';
    case 'USERNAME_PASSWORD':
      return 'jobTemplateSecretForm.secretTypes.usernamePassword';
    case 'SSH_PRIVATE_KEY':
      return 'jobTemplateSecretForm.secretTypes.sshPrivateKey';
    case 'TLS_CERTIFICATE':
      return 'jobTemplateSecretForm.secretTypes.tlsCertificate';
    case 'TOKEN':
      return 'jobTemplateSecretForm.secretTypes.token';
    default:
      throw new Error(`Secret type "${secretType}" is not supported`);
  }
};

const resetSecretFields = (
  secretName: string,
  secretDescription: string,
  type: SecretTypeV1,
): SecretsForm => {
  if (type === 'USERNAME_PASSWORD') {
    return {
      secretName,
      secretDescription,
      type,
      username: '',
      password: '',
    };
  }

  return {
    secretName,
    secretDescription,
    type,
    secret: '',
  };
};

const secretOptions = (t: TFunction<'codeDelivery'>) =>
  SECRET_TYPES.map((o) => ({
    value: o.value,
    label: t(secretTypeToDisplayName(o.value)),
  }));

const generateSecretFields = (
  t: TFunction<'codeDelivery'>,
  secretType: SecretTypeV1,
  viewSecret: boolean,
  setViewSecret: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const trailingIcon = viewSecret ? 'private' : 'eye';

  if (secretType === 'USERNAME_PASSWORD') {
    return (
      <>
        <Form.Field
          type="text"
          name="username"
          label={t('jobTemplateSecretForm.fields.username.label')}
          placeholder={t('jobTemplateSecretForm.fields.username.placeholder')}
          required
        />
        <Form.Field
          type={viewSecret ? 'text' : 'password'}
          name="password"
          trailingButtonIcon={trailingIcon}
          trailingButtonProps={{
            'aria-label': t('jobTemplateSecretForm.buttons.view.password'),
          }}
          onClickTrailingButton={() => setViewSecret(!viewSecret)}
          label={t('jobTemplateSecretForm.fields.password.label')}
          placeholder={t('jobTemplateSecretForm.fields.password.placeholder')}
          required
        />
      </>
    );
  }

  const details = SECRET_TYPES.find((o) => o.value === secretType);

  if (!details) {
    return null;
  }

  if (details.type === 'multiline') {
    const label = (
      <span className="job-template-secret-form-form__label">
        {t(`jobTemplateSecretForm.fields.${details.name}.label`)}
      </span>
    );

    return (
      <Form.Field
        type="multiline"
        name="secret"
        label={label}
        placeholder={t(
          `jobTemplateSecretForm.fields.${details.name}.placeholder`,
        )}
        required
      />
    );
  }

  return (
    <Form.Field
      type={viewSecret ? 'text' : details.type}
      name="secret"
      trailingButtonIcon={trailingIcon}
      onClickTrailingButton={() => setViewSecret(!viewSecret)}
      trailingButtonProps={{
        'aria-label': t(`jobTemplateSecretForm.buttons.view.${details.name}`),
      }}
      label={t(`jobTemplateSecretForm.fields.${details.name}.label`)}
      placeholder={t(
        `jobTemplateSecretForm.fields.${details.name}.placeholder`,
      )}
      required
    />
  );
};

const JobTemplateSecretForm = ({
  secretDetails = null,
  loading,
  error,
  onCancel,
  onClose,
  onSubmit,
}: Props) => {
  const [state, setState] = useState<SecretsForm>(defaultState);
  const [viewSecret, setViewSecret] = useState(false);

  useEffect(() => {
    if (!secretDetails) {
      return;
    }

    setState(
      resetSecretFields(
        secretDetails.name,
        secretDetails.description || '',
        secretDetails.type,
      ),
    );
  }, [secretDetails]);

  const { t } = useTranslation('codeDelivery');

  const onFormChange: CommonFormChangeFunction<SecretsForm> = (
    field,
    values,
  ) => {
    if (field === 'secretName') {
      setState({
        ...state,
        secretName: (values[field] || '').replaceAll(/[-\s]/g, '_'),
      });
      return;
    }

    if (field !== 'type') {
      setState({
        ...state,
        [field]: values[field] || '',
      });
      return;
    }

    setViewSecret(false);
    setState(
      resetSecretFields(
        state.secretName,
        state.secretDescription,
        values.type || 'SSH_PRIVATE_KEY',
      ),
    );
  };

  return (
    <Modal className="job-template-secret-form" onClose={onClose}>
      <Modal.Title>{t('jobTemplateSecretForm.page.title')}</Modal.Title>
      <p>{t('jobTemplateSecretForm.page.subtitle')}</p>
      <Form
        className="job-template-secret-form-form"
        cancelLabel={t('jobTemplateSecretForm.buttons.cancel')}
        submitLabel={t('jobTemplateSecretForm.buttons.save')}
        values={state}
        onChange={onFormChange}
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitting={loading}
        cancellable
        submittable
      >
        <Form.Field
          type="text"
          name="secretName"
          label={t('jobTemplateSecretForm.fields.name.label')}
          placeholder={t('jobTemplateSecretForm.fields.name.placeholder')}
          disabled={secretDetails !== null}
          required
        />
        <Form.Field
          type="text"
          name="secretDescription"
          label={t('jobTemplateSecretForm.fields.description.label')}
          placeholder={t(
            'jobTemplateSecretForm.fields.description.placeholder',
          )}
        />
        <Form.Field
          type="select"
          name="type"
          label={t('jobTemplateSecretForm.fields.secretType.label')}
          placeholder={t('jobTemplateSecretForm.fields.secretType.placeholder')}
          options={secretOptions(t)}
          disabled={secretDetails !== null}
          required
        />
        {generateSecretFields(t, state.type, viewSecret, setViewSecret)}
        <Cd4peError error={error} />
      </Form>
    </Modal>
  );
};

export default JobTemplateSecretForm;
