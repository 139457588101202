/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Table as PDSTable } from '@puppet/data-grid';
import classnames from 'classnames';

type ColumnsItem = {
  label: string;
  dataKey: string;
  cellDataGetter?: (data: Data) => unknown;
  cellRenderer?: (data: { rowData: DataItem }) => unknown;
  columnData?: unknown;
  sortable?: boolean;
  className?: string;
  style?: React.CSSProperties;
};
export type Columns = ColumnsItem[];

export type DataItem = {
  [key: string]: unknown;
  selected?: boolean;
  disabled?: boolean;
};
export type Data = DataItem[] | [];

type Sort = {
  direction: 'asc' | 'desc';
  sortDataKey: string;
};

// These types will eventually be moved into the PDS data-grid package.
type Props = {
  data: Data;
  columns: Columns;
  rowKey?: string | (() => string);
  rowClassName?: () => string;
  onRowClick?: (rowKey: string, rowIndex: number, rowData: unknown) => void;
  fixed?: boolean;
  className?: string;
  sortedColumn?: Sort;
  loading?: boolean;
  loadingMessage?: string;
  onSort?: (
    direction: Sort['direction'],
    sortDataKey: Sort['sortDataKey'],
  ) => void;
  horizontalScroll?: boolean;
  fixedColumn?: boolean;
  fixedLastColumn?: boolean;
  emptyStateHeader?: string;
  emptyStateMessage?: string;
  selectable?: boolean;
  onRowChecked?: (checked: boolean, rowData: any) => void;
  onHeaderChecked?: (checked: boolean) => void;
  headerCheckState?: boolean;
  headerIndeterminateState?: boolean;
};

const Table = ({ className, ...rest }: Props) => {
  return (
    <div className="table-container">
      <PDSTable {...rest} className={classnames('table', className)} />
    </div>
  );
};

Table.TableHeader = PDSTable.TableHeader;

export default Table;
