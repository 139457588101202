import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessageStateContext = React.createContext();
const ErrorMessageDispatchContext = React.createContext();

export const ERROR_TYPES = {
  GRAPHQL_ERROR: 'GRAPHQL_ERROR',
};

function errorMessageReducer(state, action) {
  switch (action.type) {
    case ERROR_TYPES.GRAPHQL_ERROR:
      return {
        error: action.payload,
      };
    default:
      return state;
  }
}

const ErrorMessageProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(errorMessageReducer, {
    errorMessage: {},
  });
  return (
    <ErrorMessageStateContext.Provider value={state}>
      <ErrorMessageDispatchContext.Provider value={dispatch}>
        {children}
      </ErrorMessageDispatchContext.Provider>
    </ErrorMessageStateContext.Provider>
  );
};

function useErrorMessageState() {
  const context = React.useContext(ErrorMessageStateContext);
  if (context === undefined) {
    throw new Error(
      'useErrorMessageState must be used within a ErrorMessageProvider',
    );
  }
  return context;
}

function useErrorMessageDispatch() {
  const context = React.useContext(ErrorMessageDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useErrorMessageDispatch must be used within a ErrorMessageProvider',
    );
  }
  return context;
}

export { ErrorMessageProvider, useErrorMessageState, useErrorMessageDispatch };

ErrorMessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
