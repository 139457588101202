import React from 'react';
import { Button } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './CardBoardToolbar.scss';

const propTypes = {
  onAddChartHandler: PropTypes.func.isRequired,
};

const CardBoardToolbar = ({ onAddChartHandler }) => {
  const { t } = useTranslation('inventory');

  return (
    <div className="cb-header-container">
      <Button
        data-testid="add-chart-button"
        type="secondary"
        className="cb-secondary-button"
        onClick={onAddChartHandler}
      >
        {t('emptyGrid.card.button.text')}
      </Button>
    </div>
  );
};

export default CardBoardToolbar;

CardBoardToolbar.propTypes = propTypes;
