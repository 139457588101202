import {
  Cd4peApiError,
  handleQueryRequest,
  InfiniteQueryHook,
  MutationHook,
  QueryHook,
} from '@utils/api/cd4pe';
import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { JobHardwareCapabilitiesV1Service } from '@puppet/cd4pe-client-ts';
import queryClient from '@services/queryClient';

const CAPABILITIES_LIST_QUERY_TAG = 'listCapabilities';
const CAPABILITIES_NODE_LIST_QUERY_TAG = 'listCapabilitiesNodes';
const CAPABILITIES_GET_QUERY_TAG = 'getCapability';

/**
 * useListCapabilitiesV1
 */

type UseListCapabilitiesV1 = QueryHook<
  typeof JobHardwareCapabilitiesV1Service,
  'listCapabilitiesV1',
  Cd4peApiError
>;

export const useListCapabilitiesV1: UseListCapabilitiesV1 = (payload) =>
  useQuery([CAPABILITIES_LIST_QUERY_TAG, payload.workspaceId], async () =>
    handleQueryRequest(
      JobHardwareCapabilitiesV1Service.listCapabilitiesV1(payload),
    ),
  );

/**
 * useDeleteCapabilitiesV1
 */

type UseDeleteCapabilitiesV1 = MutationHook<
  typeof JobHardwareCapabilitiesV1Service,
  'deleteCapabilitiesV1',
  Cd4peApiError
>;

export const useDeleteCapabilitiesV1: UseDeleteCapabilitiesV1 = () => {
  return useMutation(
    (payload) =>
      handleQueryRequest(
        JobHardwareCapabilitiesV1Service.deleteCapabilitiesV1(payload),
      ),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          CAPABILITIES_LIST_QUERY_TAG,
          payload.workspaceId,
        ]);
      },
    },
  );
};

/**
 * useDeleteNodeFromCapabilityV1
 */

type UseDeleteNodeFromCapabilityV1 = MutationHook<
  typeof JobHardwareCapabilitiesV1Service,
  'deleteNodeFromCapabilityV1',
  Cd4peApiError
>;

export const useDeleteNodeFromCapabilityV1: UseDeleteNodeFromCapabilityV1 =
  () => {
    return useMutation(
      (payload) =>
        handleQueryRequest(
          JobHardwareCapabilitiesV1Service.deleteNodeFromCapabilityV1(payload),
        ),
      {
        onSuccess: (_, payload) => {
          queryClient.invalidateQueries([
            CAPABILITIES_LIST_QUERY_TAG,
            payload.workspaceId,
          ]);
        },
      },
    );
  };

/**
 * useGetJobHardwareCapabilityV1
 */

type UseGetJobHardwareCapabilityV1 = QueryHook<
  typeof JobHardwareCapabilitiesV1Service,
  'getJobHardwareCapabilityV1',
  Cd4peApiError
>;

export const useGetJobHardwareCapabilityV1: UseGetJobHardwareCapabilityV1 = (
  payload,
  options = {},
) =>
  useQuery(
    [CAPABILITIES_GET_QUERY_TAG, payload.workspaceId, payload.id],
    async () =>
      handleQueryRequest(
        JobHardwareCapabilitiesV1Service.getJobHardwareCapabilityV1(payload),
      ),
    options,
  );

/**
 * useListAvailableNodesV1
 */

type UseListAvailableNodesV1 = InfiniteQueryHook<
  typeof JobHardwareCapabilitiesV1Service,
  'listAvailableNodesV1',
  Cd4peApiError
>;

export type UseListAvailableNodesV1Result = ReturnType<UseListAvailableNodesV1>;

export const useListAvailableNodesV1: UseListAvailableNodesV1 = (
  payload,
  options = {},
) =>
  useInfiniteQuery(
    [
      CAPABILITIES_NODE_LIST_QUERY_TAG,
      payload.workspaceId,
      payload.peIntegrationName,
      payload.prefix ?? '',
    ],
    async ({ pageParam }) =>
      handleQueryRequest(
        JobHardwareCapabilitiesV1Service.listAvailableNodesV1({
          offset: pageParam,
          ...payload,
        }),
      ),
    {
      getNextPageParam: (lastPage) => lastPage.nextOffset,
      ...options,
    },
  );

/*
 * useCreateHardwareCapabilityV1
 */

type UseCreateHardwareCapabilityV1 = MutationHook<
  typeof JobHardwareCapabilitiesV1Service,
  'createCapabilitiesV1',
  Cd4peApiError
>;

export const useCreateHardwareCapabilityV1: UseCreateHardwareCapabilityV1 =
  () =>
    useMutation((payload) =>
      handleQueryRequest(
        JobHardwareCapabilitiesV1Service.createCapabilitiesV1(payload),
      ),
    );

/**
 * useUpdateHardwareCapabilityV1
 */

type UseUpdateHardwareCapabilityV1 = MutationHook<
  typeof JobHardwareCapabilitiesV1Service,
  'updateCapabilitiesV1',
  Cd4peApiError
>;

export const useUpdateHardwareCapabilityV1: UseUpdateHardwareCapabilityV1 =
  () =>
    useMutation(
      (payload) =>
        handleQueryRequest(
          JobHardwareCapabilitiesV1Service.updateCapabilitiesV1(payload),
        ),
      {
        onSuccess: (_, payload) => {
          queryClient.invalidateQueries([
            CAPABILITIES_GET_QUERY_TAG,
            payload.workspaceId,
            payload.id,
          ]);
        },
      },
    );
