import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  ButtonSelect,
  Button,
  Text,
  TooltipHoverArea,
} from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import ConditionalRender from 'inventory/components/ConditionalRender';
import FilterTag from 'inventory/components/FilterTag';
import { FILTER_UNION_OPTIONS } from 'utils/constants';

import './FilterGroup.scss';

const FilterGroup = ({
  isEditing,
  filter,
  updateFilters,
  onAppendFilter,
  savedFilter,
  groupIndex,
  isAltered,
}) => {
  const { t } = useTranslation('inventory');

  const { id, union, filters } = filter;
  const deleteFilter = (removedFilter) => {
    const updatedFilters = filters.filter((option) => {
      return option.filter !== removedFilter;
    });

    updateFilters({
      ...filter,
      filters: updatedFilters,
    });
  };

  // needs to compare against saved filters for presence and exact match
  const isSaved = (filterCriteria) => {
    const storedFilters = savedFilter?.filters ?? [];
    if (storedFilters.length === 0) {
      return false;
    }
    const filterSaved = savedFilter.filters.filter((option) => {
      return option === filterCriteria;
    });
    return filterSaved.length > 0;
  };
  const updateUnion = (newUnion) => {
    updateFilters({
      ...filter,
      union: newUnion,
    });
  };
  return (
    <div
      className={classnames({
        'filter-group-container': true,
        'filter-group-editing': isEditing,
        'filter-group-fixed': !isEditing,
        'filter-group-altered': isAltered && isEditing,
      })}
    >
      {filters.map((filterCriteria, index) => {
        const keyId = `${id}_${index}`;
        return (
          <div className="filter-group-filter-tab" key={keyId}>
            <FilterTag
              isSaved={isSaved(filterCriteria)}
              filter={filterCriteria}
              isEditing={isEditing}
              onDelete={deleteFilter}
            />
            <ConditionalRender enable={index < filters.length - 1}>
              <ConditionalRender enable={isEditing}>
                <ButtonSelect
                  type="text"
                  className="filter-group-filter-tab-union"
                  data-testid={`filter-group-filter-tab-union-${groupIndex}`}
                  options={FILTER_UNION_OPTIONS}
                  placeholder={union}
                  value={union}
                  onChange={(newUnion) => {
                    updateUnion(newUnion);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender enable={!isEditing}>
                <Text className="filter-group-filter-tab-union">{union}</Text>
              </ConditionalRender>
            </ConditionalRender>
          </div>
        );
      })}
      <ConditionalRender enable={isEditing}>
        <TooltipHoverArea
          tooltip={t('compoundFiltering.filterGroup.appendFilter')}
          anchor="top"
        >
          <Button
            data-testid={`filter-group-plus-circle-filled-${groupIndex}`}
            type="transparent"
            icon="plus-circle-filled"
            onClick={() => onAppendFilter(filter)}
          />
        </TooltipHoverArea>
      </ConditionalRender>
    </div>
  );
};

FilterGroup.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  filter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    union: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        fact: PropTypes.string,
        operator: PropTypes.string,
        value: PropTypes.string,
        filter: PropTypes.shape({}).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  savedFilter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    union: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        fact: PropTypes.string,
        operator: PropTypes.string,
        value: PropTypes.string,
        filter: PropTypes.shape({}).isRequired,
      }),
    ).isRequired,
  }),
  isAltered: PropTypes.bool,
  updateFilters: PropTypes.func.isRequired,
  onAppendFilter: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

FilterGroup.defaultProps = {
  savedFilter: {},
  isAltered: false,
};

export default FilterGroup;
