import actionCreator, { Actions } from '@utils/actionCreator';
import CommonForm from '@components/CommonForm';
import { CommonFormChangeFunction } from '@components/CommonForm/CommonForm';
import { useTranslation } from 'react-i18next';
import docsLinks from '@codeDelivery/utils/docsLinks';
import React from 'react';
import Definitions from '@components/Definitions';
import { Button, Form, Link, Text } from '@puppet/react-components';
import { Cd4peApiError } from '@utils/api/cd4pe';
import Cd4peError from '@components/Cd4peError';

interface IaConfigFormActionTypes {
  PUPPET_SERVER: 'PUPPET_SERVER';
  IA_CERT: 'IA_CERT';
  IA_PRIVATE_KEY: 'IA_PRIVATE_KEY';
}

export const IA_CONFIG_FORM_ACTION_TYPES: IaConfigFormActionTypes = {
  PUPPET_SERVER: 'PUPPET_SERVER',
  IA_CERT: 'IA_CERT',
  IA_PRIVATE_KEY: 'IA_PRIVATE_KEY',
};

export const iaConfigFormActions = {
  puppetServer: (puppetServer: string) =>
    actionCreator(IA_CONFIG_FORM_ACTION_TYPES.PUPPET_SERVER, puppetServer),
  iaCert: (iaCert: string) =>
    actionCreator(IA_CONFIG_FORM_ACTION_TYPES.IA_CERT, iaCert),
  iaPrivateKey: (iaPrivateKey: string) =>
    actionCreator(IA_CONFIG_FORM_ACTION_TYPES.IA_PRIVATE_KEY, iaPrivateKey),
};

export type IaConfigFormActions = Actions<typeof iaConfigFormActions>;

type FormFieldValues = {
  [K in keyof IaConfigFormActionTypes]: string | null | undefined;
};

export type IaConfigFormOnChange = CommonFormChangeFunction<FormFieldValues>;

interface Props {
  puppetServer?: string | null;
  iaCert?: string | null;
  iaPrivateKey?: string | null;
  saveLoading?: boolean;
  saveError?: Cd4peApiError | null;
  onFormChange: IaConfigFormOnChange;
  submitCallback: () => Promise<void>;
  cancelCallback: () => void;
}

const IaConfigForm = ({
  puppetServer,
  iaCert,
  iaPrivateKey,
  saveLoading,
  saveError,
  onFormChange,
  submitCallback,
  cancelCallback,
}: Props) => {
  const { t } = useTranslation('codeDelivery');

  const formVals = {
    PUPPET_SERVER: puppetServer,
    IA_CERT: iaCert,
    IA_PRIVATE_KEY: iaPrivateKey,
  };

  return (
    <CommonForm
      submittable
      className="ia-config-form"
      submitLabel={t(`addPeIntegration.iaConfigForm.submitButton`)}
      onSubmit={submitCallback}
      onCancel={cancelCallback}
      values={formVals}
      onChange={onFormChange}
      submitting={saveLoading}
    >
      <CommonForm.Section>
        <CommonForm.Section.Main>
          <h2 className="ia-config-form-main-section__header">
            {t('addPeIntegration.iaConfigForm.header')}
          </h2>
          <Text className="ia-config-form-main-section__subheader">
            {t('addPeIntegration.iaConfigForm.subHeader')}
          </Text>
          <Button
            type="secondary"
            onClick={cancelCallback}
            className="ia-config-form-main-section__skip-button"
          >
            {t('addPeIntegration.iaConfigForm.cancelButton')}
          </Button>
          <div className="ia-config-form-main-section__controls">
            <div className="manual-integration-form-field__wrapper">
              <Form.Field
                data-testid="ia-config-form-puppet-server-input"
                type="text"
                name={IA_CONFIG_FORM_ACTION_TYPES.PUPPET_SERVER}
                label={t('addPeIntegration.iaConfigForm.puppetServerLabel')}
                placeholder={t(
                  `addPeIntegration.iaConfigForm.puppetServerPlaceholder`,
                )}
                required
              />
            </div>
            <div className="manual-integration-form-field__wrapper">
              <Form.Field
                data-testid="ia-config-form-ia-cert-input"
                type="multiline"
                name={IA_CONFIG_FORM_ACTION_TYPES.IA_CERT}
                label={t('addPeIntegration.iaConfigForm.iaCertLabel')}
                placeholder={t(
                  `addPeIntegration.iaConfigForm.iaCertPlaceholder`,
                )}
                required
              />
            </div>
            <div className="manual-integration-form-field__wrapper">
              <Form.Field
                data-testid="ia-config-form-ia-private-key-input"
                type="multiline"
                name={IA_CONFIG_FORM_ACTION_TYPES.IA_PRIVATE_KEY}
                label={t('addPeIntegration.iaConfigForm.iaPrivateKeyLabel')}
                placeholder={t(
                  `addPeIntegration.iaConfigForm.iaPrivateKeyPlaceholder`,
                )}
                required
              />
            </div>
          </div>
        </CommonForm.Section.Main>
        <CommonForm.Section.Sidebar>
          <Definitions.Entry icon="info-circle">
            <Definitions.Entry.Content>
              <h3 className="ia-config-form-info-section__header">
                {t('addPeIntegration.iaConfigForm.infoHeader')}
              </h3>
              <Text className="ia-config-form-info-section__body">
                {t('addPeIntegration.iaConfigForm.infoBody')}
              </Text>
              <Link
                as="a"
                href={docsLinks().configureImpactAnalysis}
                target="_blank"
              >
                {t('addPeIntegration.iaConfigForm.infoLink')}
              </Link>
            </Definitions.Entry.Content>
          </Definitions.Entry>
        </CommonForm.Section.Sidebar>
      </CommonForm.Section>
      <Cd4peError error={saveError} />
    </CommonForm>
  );
};

export default IaConfigForm;

IaConfigForm.defaultProps = {
  puppetServer: null,
  iaCert: null,
  iaPrivateKey: null,
  saveLoading: false,
  saveError: null,
} as Partial<Props>;
