import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@state/store';
import { UserV1, WorkspaceV1 } from '@utils/api/cd4pe';
import { selectWorkspaces } from './selectors';

interface SessionState {
  user: UserV1 | null;
  workspaces: WorkspaceV1[];
  currentWorkspace: WorkspaceV1 | null;
  initialized: boolean;
  currentWorkspacePending: boolean;
}

const initialState: SessionState = {
  user: null,
  workspaces: [],
  initialized: false,
  currentWorkspacePending: true,
  currentWorkspace: null,
};

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetSession: () => initialState,
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    setUser: (state, action: PayloadAction<UserV1>) => {
      state.user = action.payload;
    },
    setWorkspaces: (state, action: PayloadAction<WorkspaceV1[]>) => {
      state.workspaces = action.payload;
    },
    setCurrentWorkspace: (state, action: PayloadAction<WorkspaceV1>) => {
      state.currentWorkspace = action.payload;
      state.currentWorkspacePending = false;
    },
    renameWorkspace: (
      state,
      actions: PayloadAction<{ id: string; name: string }>,
    ) => {
      state.workspaces = state.workspaces.map((w) => ({
        ...w,
        name: w.domain === actions.payload.id ? actions.payload.name : w.name,
      }));
    },
    removeWorkspace(state, action: PayloadAction<string>) {
      state.workspaces = state.workspaces.filter(
        (w) => w.domain !== action.payload,
      );
    },
    createWorkspace(state, action: PayloadAction<WorkspaceV1>) {
      state.workspaces = [...state.workspaces, action.payload];
      state.currentWorkspace = action.payload;
      state.currentWorkspacePending = false;
    },
  },
});

export const sessionReducer = slice.reducer;

const { actions } = slice;

export const initializeApp =
  (
    user: UserV1,
    workspaces: Array<WorkspaceV1> = [],
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(actions.setUser(user));
    dispatch(actions.setWorkspaces(workspaces));
    dispatch(actions.setInitialized(true));
  };

export const setCurrentWorkspace =
  (workspaceName: WorkspaceV1['name']): AppThunk =>
  (dispatch, getState) => {
    const workspaces = selectWorkspaces(getState());
    const workspace =
      workspaces.find(
        (w) =>
          w.name &&
          workspaceName &&
          w.name.toLowerCase() === workspaceName.toLowerCase(),
      ) || workspaces[0];

    dispatch(actions.setCurrentWorkspace(workspace || null));
  };

export default {
  actions,
  setCurrentWorkspace,
  initializeApp,
};
