import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Cd4peApiError,
  handleQueryRequest,
  MutationHook,
  WorkspacesV1Service,
} from '@utils/api/cd4pe';

/**
 * useUpdateWorkspaceV1
 */

type UseUpdateWorkspaceV1 = MutationHook<
  typeof WorkspacesV1Service,
  'updateWorkspaceV1',
  Cd4peApiError
>;

const useInvalidateUserQuery = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: ['getUserV1'] });
};

export const useUpdateWorkspaceV1: UseUpdateWorkspaceV1 = () =>
  useMutation(
    (payload) =>
      handleQueryRequest(WorkspacesV1Service.updateWorkspaceV1(payload)),
    {
      onSuccess: useInvalidateUserQuery(),
    },
  );

/**
 * useDeleteWorkspaceV1
 */

type UseDeleteWorkspaceV1 = MutationHook<
  typeof WorkspacesV1Service,
  'deleteWorkspaceV1',
  Cd4peApiError
>;

export const useDeleteWorkspaceV1: UseDeleteWorkspaceV1 = () =>
  useMutation(
    (payload) =>
      handleQueryRequest(WorkspacesV1Service.deleteWorkspaceV1(payload)),
    {
      onSuccess: useInvalidateUserQuery(),
    },
  );

/**
 * useCreateWorkspaceV1
 */

type UseCreateWorkspaceV1 = MutationHook<
  typeof WorkspacesV1Service,
  'createWorkspaceV1',
  Cd4peApiError
>;

export const useCreateWorkspaceV1: UseCreateWorkspaceV1 = () =>
  useMutation(
    (payload) =>
      handleQueryRequest(WorkspacesV1Service.createWorkspaceV1(payload)),
    {
      onSuccess: useInvalidateUserQuery(),
    },
  );
