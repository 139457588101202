import React, { useState } from 'react';
import {
  Card,
  Heading,
  Text,
  Button,
  TooltipHoverArea,
} from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import { UserV1 } from '@puppet/cd4pe-client-ts';
import ConditionalRender from '@components/ConditionalRender';
import ChangeEmailDialog from '../ChangeEmailDialog/ChangeEmailDialog';
import ChangePasswordDialog from '../ChangePasswordDialog/ChangePasswordDialog';

type Props = {
  user: UserV1;
};
const UserDetailsCard = ({ user }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [isChangeEmailOpen, setIsChangeEmailOpen] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);

  return (
    <Card className="user-details-card">
      <div className="user-details-card__details">
        {!user.rootUser && (
          <div className="user-details-card__item">
            <Heading color="medium" as="label">
              {t('profile.details.name')}
            </Heading>
            <Text data-testid="profile-details-name">{`${user?.firstName} ${user?.lastName}`}</Text>
          </div>
        )}
        <div className="user-details-card__item">
          <Heading color="medium" as="label">
            {t('profile.details.username')}
          </Heading>
          <Text data-testid="profile-details-username">{user?.userName}</Text>
        </div>
        <div className="user-details-card__item">
          <Heading color="medium" as="label">
            {t('profile.details.email')}
          </Heading>
          <Text data-testid="profile-details-email">{user?.email}</Text>
        </div>
        {!user.rootUser && (
          <div className="user-details-card__item">
            <Heading color="medium" as="label">
              {t('profile.details.accountId')}
            </Heading>
            <Text data-testid="profile-details-accountId">{user?.id}</Text>
          </div>
        )}
      </div>
      <div className="user-details-card__actions">
        {user.rootUser && (
          <div>
            <Button
              disabled={!user.superUser && !user.rootUser}
              onClick={() => {
                setIsChangePasswordOpen(!isChangePasswordOpen);
              }}
              data-testid="profile-change-password-button"
            >
              {t('profile.details.change.password.button')}
            </Button>
            <ConditionalRender enable={isChangePasswordOpen}>
              <ChangePasswordDialog
                isOpen={isChangePasswordOpen}
                onClose={setIsChangePasswordOpen}
                user={user}
              />
            </ConditionalRender>
          </div>
        )}
        <div>
          <TooltipHoverArea
            disabled={user.superUser}
            tooltip={t('profile.details.disabled.change.email.tooltip')}
            anchor="bottom"
          >
            <Button
              disabled={!user.superUser}
              onClick={() => {
                setIsChangeEmailOpen(!isChangeEmailOpen);
              }}
              data-testid="profile-change-email-button"
            >
              {t('profile.details.change.email.button')}
            </Button>
          </TooltipHoverArea>
          <ConditionalRender enable={isChangeEmailOpen}>
            <ChangeEmailDialog
              isOpen={isChangeEmailOpen}
              onClose={setIsChangeEmailOpen}
              user={user}
            />
          </ConditionalRender>
        </div>
      </div>
    </Card>
  );
};

export default UserDetailsCard;
