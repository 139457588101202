import { Dispatch } from 'react';
import getProviderDisplayName from '@utils/vcs/displayNameHelper';
import getIconType, { VcsProviderIconKey } from '@utils/vcs/iconHelper';
import * as ERRORS from '@utils/boltProjects/errorTypesConsts';
import actionCreator, { Actions } from '@utils/actionCreator';
import {
  ApiError,
  ErrorV1,
  formatError,
  isErrorResponse,
  isSuccessResponse,
  ListVcsOrganizationsResponseV1,
  unwrapApiResponse,
  VcsBranchV1,
  VcsOrganizationV1,
  VcsRepositoryV1,
  VcsV1Service,
} from '@utils/api/cd4pe';
import * as at from './actionTypes';

export interface StdVcsSource {
  label: string;
  value: string;
  actualValue: VcsOrganizationV1;
  icon: VcsProviderIconKey | 'alert';
}

export interface StdVcsRepo {
  label: string;
  value: string;
  actualValue: VcsRepositoryV1;
}

export interface StdVcsBranch {
  label: string;
  value: string;
  actualValue: VcsBranchV1;
}

const actions = {
  getSourcesStart: () => actionCreator(at.GET_SOURCES_START),
  getSourcesComplete: (sources: StdVcsSource[]) =>
    actionCreator(at.GET_SOURCES_COMPLETE, sources),
  getSourcesError: (error: string) =>
    actionCreator(at.GET_SOURCES_ERROR, error),
  getReposStart: () => actionCreator(at.GET_REPOS_START),
  getReposComplete: (repos: StdVcsRepo[]) =>
    actionCreator(at.GET_REPOS_COMPLETE, repos),
  getReposError: (error: string) => actionCreator(at.GET_REPOS_ERROR, error),
  getBranchesStart: () => actionCreator(at.GET_BRANCHES_START),
  getBranchesComplete: (branches: StdVcsBranch[]) =>
    actionCreator(at.GET_BRANCHES_COMPLETE, branches),
  getBranchesError: (error: string) =>
    actionCreator(at.GET_BRANCHES_ERROR, error),
};

export type VcsActions = Actions<typeof actions>;

export const getSources = async <T>(
  workspaceId: string,
  dispatch: Dispatch<T | VcsActions>,
) => {
  dispatch(actions.getSourcesStart());
  const providersRes = await unwrapApiResponse(
    VcsV1Service.listIntegrationsV1({ workspaceId }),
  );

  if (isErrorResponse(providersRes)) {
    dispatch(actions.getSourcesError(formatError(providersRes)));
    return;
  }

  const providers = providersRes.vcsIntegrations.filter((p) => p.connected);
  const orgPromises = providers.map((p) =>
    unwrapApiResponse(
      VcsV1Service.listOrganizationsV1({
        workspaceId,
        provider: p.provider,
      }),
    ),
  );
  const orgResponses = await Promise.all(orgPromises);
  const organizations = (
    orgResponses.filter((o) =>
      isSuccessResponse(o),
    ) as ListVcsOrganizationsResponseV1[]
  ).map((r) => r.vcsOrganizations);

  if (organizations.length !== orgResponses.length) {
    const error = orgResponses.find((o) => isErrorResponse(o)) as
      | ErrorV1
      | ApiError;
    dispatch(actions.getSourcesError(formatError(error)));
    return;
  }

  if (organizations.length === 0) {
    dispatch(actions.getSourcesError(ERRORS.NO_SOURCES_FOUND));
    return;
  }

  const sources = organizations.flat().map((source) => ({
    label: `${getProviderDisplayName(source.provider)} | ${source.displayName}`,
    // TODO: fix this in API
    value: source.id!,
    actualValue: source,
    icon: getIconType(source.provider),
  }));

  dispatch(actions.getSourcesComplete(sources));
};

export const getRepositories = async <T>(
  workspaceId: string,
  dispatch: Dispatch<T | VcsActions>,
  selectedSource: VcsOrganizationV1,
  searchTerm?: string,
) => {
  dispatch(actions.getReposStart());
  const response = await unwrapApiResponse(
    VcsV1Service.listRepositoriesV1({
      workspaceId,
      provider: selectedSource.provider,
      name: searchTerm || undefined,
      project: selectedSource.project || undefined,
      organization: selectedSource.organization || undefined,
      user: selectedSource.user || undefined,
    }),
  );

  if (isErrorResponse(response)) {
    dispatch(actions.getReposError(formatError(response)));
    return;
  }

  if (!searchTerm && response.vcsRepositories.length === 0) {
    dispatch(actions.getReposError(ERRORS.NO_REPOS_FOUND));
    return;
  }

  const repos = response.vcsRepositories.map((repo) => ({
    // TODO: fix this in API
    label: repo.displayName!,
    // TODO: fix this in API
    value: repo.displayName!,
    actualValue: repo,
  }));

  dispatch(actions.getReposComplete(repos));
};

export const getBranches = async <T>(
  workspaceId: string,
  dispatch: Dispatch<T | VcsActions>,
  selectedRepository: VcsRepositoryV1,
  searchTerm?: string,
) => {
  dispatch(actions.getBranchesStart());
  const response = await unwrapApiResponse(
    VcsV1Service.listBranchesV1({
      workspaceId,
      provider: selectedRepository.provider,
      project: selectedRepository.project || undefined,
      organization: selectedRepository.organization || undefined,
      user: selectedRepository.user || undefined,
      name: selectedRepository.name,
      prefix: searchTerm || undefined,
    }),
  );

  if (isErrorResponse(response)) {
    dispatch(actions.getBranchesError(formatError(response)));
    return;
  }

  if (response.vcsBranches.length === 0 && !searchTerm) {
    dispatch(actions.getBranchesError(ERRORS.NO_BRANCHES_FOUND));
    return;
  }

  const branches = response.vcsBranches.map((branch) => ({
    label: branch.name,
    value: branch.name,
    actualValue: branch,
  }));

  dispatch(actions.getBranchesComplete(branches));
};
