import {
  SetGroupPrivilegesRequestV1,
  RbacGroupPrivilegeActionV1,
  RbacGroupPrivilegeCategoryV1,
} from '@puppet/cd4pe-client-ts';
import {
  PRIVILEGE_CATEGORY_ORDER,
  PRIVILEGE_ACTION_ORDER,
  ACTION_STATE,
} from './constants';

export type PrivilegeActions = RbacGroupPrivilegeActionV1['type'];

export interface PrivilegeFormAction extends RbacGroupPrivilegeActionV1 {
  selected: boolean;
}

export interface PrivilegeFormCategory
  extends Omit<RbacGroupPrivilegeCategoryV1, 'actions'> {
  actions: Array<PrivilegeFormAction>;
}

export type PrivilegeFormState = PrivilegeFormCategory[];

export const sortPrivileges = (privileges: RbacGroupPrivilegeCategoryV1[]) => {
  const withSortedActions = privileges.map((privilege) => {
    return {
      ...privilege,
      actions: privilege.actions.sort(
        (a, b) =>
          PRIVILEGE_ACTION_ORDER.indexOf(a.type) -
          PRIVILEGE_ACTION_ORDER.indexOf(b.type),
      ),
    };
  });

  return withSortedActions.sort(
    (a, b) =>
      PRIVILEGE_CATEGORY_ORDER.indexOf(a.name) -
      PRIVILEGE_CATEGORY_ORDER.indexOf(b.name),
  );
};

export const getActionState = (
  categoryName: string,
  action: PrivilegeFormAction,
) => {
  if (action.selected) {
    if (categoryName === 'Users') {
      return ACTION_STATE.ALL;
    }
    if (action.usesSubsets) {
      return ACTION_STATE.SOME;
    }
    if (action.type === 'CREATE') {
      return ACTION_STATE.ANY;
    }
    return ACTION_STATE.ALL;
  }

  return ACTION_STATE.NONE;
};

export const setAllPrivileges = (
  prevState: PrivilegeFormState,
  selected: boolean,
) => {
  return prevState.map((category) => {
    return {
      ...category,
      actions: category.actions.map((action) => {
        return {
          ...action,
          selected,
        };
      }),
    };
  });
};

export const isAllPrivilegesSelected = (state: PrivilegeFormState) =>
  state.every((category) =>
    category.actions.every((action) => action.selected),
  );

export const isAnyPrivilegeSelected = (state: PrivilegeFormState) =>
  state.some((category) => category.actions.some((action) => action.selected));

export const buildFormState = (
  group: RbacGroupPrivilegeCategoryV1[],
  available: RbacGroupPrivilegeCategoryV1[],
): PrivilegeFormState => {
  const sortedAvailable = sortPrivileges(available);

  const privileges = sortedAvailable.map((category) => {
    const groupCategory = group.find((c) => c.name === category.name);

    const actions: PrivilegeFormAction[] = category.actions.map((action) => {
      const groupAction = groupCategory?.actions.find(
        (a) => a.type === action.type,
      );

      if (groupAction) {
        return {
          ...groupAction,
          selected: true,
        };
      }
      return {
        ...action,
        selected: false,
        usesSubsets: !!(action.usesSubsets && category.subsets?.length),
      };
    });

    return {
      ...(groupCategory ?? category),
      actions,
    };
  });

  return privileges;
};

export const buildEditRequest = (
  formState: PrivilegeFormState,
): SetGroupPrivilegesRequestV1 => {
  const apiCategories: RbacGroupPrivilegeCategoryV1[] = formState.map(
    (category) => {
      const actions = category.actions
        .filter(({ selected }) => selected)
        .map(({ type, usesSubsets }) => ({
          type,
          ...(usesSubsets ? { usesSubsets } : {}),
        }));

      return {
        name: category.name,
        type: category.type,
        ...(category.subsets ? { subsets: category.subsets } : {}),
        actions,
      };
    },
  );

  return { privileges: apiCategories.filter((c) => c.actions.length > 0) };
};

export const stripSubsetPrefix = (subset: string) => {
  return subset.replace(/d\d+:/, '');
};
