import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useWorkspaceName from '@hooks/useWorkspaceName';
import PageLayout from '@components/PageLayout';
import Breadcrumbs from '@components/Breadcrumbs';
import AddUserForm from '@codeDelivery/views/AddUserToGroup/components/AddUserForm';
import { Heading } from '@puppet/react-components';
import { RbacGroupV1 } from '@puppet/cd4pe-client-ts';
import { useGetGroupV1 } from '@services/cd4pe/groups';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useNavigate, useParams } from 'react-router-dom';
import alerts from '@state/ui/alerts';
import { useAppDispatch } from '@hooks/redux';
import { LINKS } from '../../../routes';

const breadcrumbsRender = (
  t: Function,
  workspace: string,
  group: RbacGroupV1 | undefined,
  groupId: string | undefined,
) => {
  const breadcrumbs = [
    { displayName: `${workspace}` },
    {
      displayName: t('addGroupUsers.breadcrumbs.settings'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace },
      }),
    },
    {
      displayName: t('addGroupUsers.breadcrumbs.groupsPage'),
      linkDestination: LINKS.settings.listGroups({
        path: { workspace },
      }),
    },
    {
      displayName: group?.name ?? t('addGroupUsers.groupName.empty'),
      linkDestination: LINKS.settings.viewGroup({
        path: { workspace, groupId },
      }),
    },
    {
      displayName: t('addGroupUsers.breadcrumb.addUsers'),
    },
  ];
  return breadcrumbs;
};

const AddUserToGroup = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { groupId } = useParams<{ groupId: string }>();
  const { error: groupError, data: group } = useGetGroupV1({
    workspaceId,
    groupId: Number(groupId),
  });

  useEffect(() => {
    if (groupError) {
      appDispatch(
        alerts.actions.createAlert({
          type: 'Error',
          message: t('addGroupUsers.error.groupErrorMessage', {
            provider: groupError,
          }),
        }),
      );
      navigate(
        LINKS.settings.listGroups({
          path: { workspace: workspaceName },
        }),
      );
    }
  }, [appDispatch, groupError, navigate, t, workspaceName]);

  return (
    <PageLayout className="add-group-users">
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs
            breadcrumbs={breadcrumbsRender(t, workspaceName, group, groupId)}
          />
        </PageLayout.Breadcrumbs>
        <Heading>
          {t('addGroupUsers.title', { group: group?.name ?? '' })}
        </Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        <Heading as="h4">{t('addGroupUsers.table.header')}</Heading>
        <AddUserForm groupId={groupId} workspace={workspaceName} />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default AddUserToGroup;
