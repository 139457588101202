import React, { useState, useEffect } from 'react';
import GET_REPORT_DATA from 'inventory/services/graphql/queries/queryReportData.gql';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Loading } from '@puppet/react-components';
import TablePager from 'components/TablePager';
import QueryError from 'inventory/components/QueryError';
import ReportsTable from 'inventory/components/ReportsTable';
import useTableDisplayMessageGenerator from 'inventory/utils/hooks';
import { TABLE_LIMIT } from 'utils/constants';
import { useErrorMessageState } from 'inventory/utils/context/error-context';

import './ReportsTab.scss';

export const filterOptions = (t) => [
  {
    value: 'all',
    label: t('reportsView.statusFilter.option.all'),
  },
  {
    value: 'unchanged',
    label: t('reportsView.statusFilter.option.unchanged'),
  },
  {
    value: 'iChange',
    label: t('reportsView.statusFilter.option.intentional'),
  },
  {
    value: 'cChange',
    label: t('reportsView.statusFilter.option.corrective'),
  },
  {
    value: 'failed',
    label: t('reportsView.statusFilter.option.failed'),
  },
];

const getQueryParams = (status) => {
  switch (status) {
    case 'unchanged': {
      return { status: { equalTo: 'unchanged' } };
    }
    case 'failed': {
      return { status: { equalTo: 'failed' } };
    }
    case 'iChange': {
      return {
        AND: [
          { correctiveChange: { equalTo: false } },
          { status: { equalTo: 'changed' } },
        ],
      };
    }
    case 'cChange': {
      return {
        AND: [
          { correctiveChange: { equalTo: true } },
          { status: { equalTo: 'changed' } },
        ],
      };
    }
    case 'all':
    default: {
      return null;
    }
  }
};

const ReportsTab = ({ source, nodeId, workspaceId, tableDisplayMessages }) => {
  const limit = TABLE_LIMIT;
  const [offset, setOffset] = useState(0);
  const [reportStatusOption, setReportStatusOption] = useState('');
  const [filter, setFilter] = useState(null);
  const [duplicateError, setDuplicateError] = useState(true);
  const { t } = useTranslation('inventory');

  const { data, loading, error, client } = useQuery(GET_REPORT_DATA, {
    variables: {
      id: workspaceId,
      certname: nodeId,
      source,
      filter,
      limit,
      offset,
    },
  });

  useEffect(() => {
    // reset the table position when we filter the total number of reports
    setOffset(0);
    setFilter(getQueryParams(reportStatusOption));
  }, [reportStatusOption]);

  const { emptyStateHeader, emptyStateMessage } =
    useTableDisplayMessageGenerator({
      loading,
      searchTerm: filter,
      tableDisplayMessages,
    });

  const { errorMessage } = useErrorMessageState();

  useEffect(() => {
    if (
      error &&
      errorMessage &&
      errorMessage?.payload?.message === error?.message
    ) {
      setDuplicateError(true);
    }
  }, [error, errorMessage]);

  const reportQueryData = data?.hosts?.nodes?.[0]?.reportsConnection ?? {};
  const totalCount = reportQueryData?.totalCount ?? 0;

  return (
    <div className="node-reports-tab">
      <div className="rv-search-container">
        <Select
          name="reportStatusOptionSelector"
          className="reports-selector"
          placeholder={t('reportsView.statusFilter.option.placeholder')}
          options={filterOptions(t)}
          value={reportStatusOption}
          onChange={(value) => {
            setReportStatusOption(value);
          }}
        />
        {loading && <Loading className="rv-search-loader" />}
      </div>
      {!duplicateError && <QueryError error={error} client={client} />}
      <div className="node-reports-table">
        <ReportsTable
          reportData={data}
          emptyStateHeader={emptyStateHeader}
          emptyStateMessage={emptyStateMessage}
          loading={loading}
        />
        {totalCount > 0 && (
          <TablePager
            pageData={reportQueryData}
            limit={limit}
            onPageSelect={setOffset}
            totalCount={totalCount}
            itemType={t('reportsView.tablepager.type', { count: totalCount })}
          />
        )}
      </div>
    </div>
  );
};

export default ReportsTab;

ReportsTab.propTypes = {
  nodeId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired,
  tableDisplayMessages: PropTypes.shape({
    loadingHeader: PropTypes.string,
    loadingMessage: PropTypes.string,
    noDataHeader: PropTypes.string,
    emptyFilterMessage: PropTypes.string,
    emptyFilterDataHeader: PropTypes.string,
  }).isRequired,
  source: PropTypes.string.isRequired,
};
