import React from 'react';
import { Table } from '@puppet/data-grid';
import { RbacGroupV1 } from '@utils/api/cd4pe';
import { useTranslation } from 'react-i18next';

export interface RowData extends RbacGroupV1 {
  selected?: boolean;
}

const AddProtectedEnvironmentsTable = ({
  data,
  onRowChecked,
  onHeaderChecked,
  headerCheckState,
  headerIndeterminateState,
  emptyStateHeader,
  loading,
}: {
  data: RowData[];
  onRowChecked: Function;
  onHeaderChecked: Function;
  headerCheckState: boolean;
  headerIndeterminateState: boolean;
  emptyStateHeader: string;
  loading: boolean;
}) => {
  const { t } = useTranslation('codeDelivery');
  const columns = [
    { label: t('addProtectedEnvironment.table.header.group'), dataKey: 'name' },
    {
      label: t('addProtectedEnvironment.table.header.description'),
      dataKey: 'description',
    },
  ];
  return (
    <div
      className="protected-environments__approvers-list-table"
      data-testid="add-protected-environments-table"
    >
      <Table
        data={data}
        loading={loading}
        columns={columns}
        selectable
        emptyStateHeader={emptyStateHeader}
        emptyStateMessage=""
        onRowChecked={(checked: boolean, rowData: RowData) =>
          onRowChecked(checked, rowData)
        }
        onHeaderChecked={(checked: boolean) => onHeaderChecked(checked)}
        headerCheckState={headerCheckState}
        headerIndeterminateState={headerIndeterminateState}
      />
    </div>
  );
};

export default AddProtectedEnvironmentsTable;
