import NodeGroupFilter from 'inventory/components/NodeTableFilter/NodeGroupFilter';
import peMasterServerQuery from 'inventory/services/graphql/queries/queryPeMasterServer.gql';
import queryNodeGroup from 'inventory/services/graphql/queries/queryNodeGroup.gql';

export default {
  component: NodeGroupFilter,
  placeholder: {
    peMasterServer: 'nodeTable.filter.peMasterServer.input.placeholder',
    nodeGroup: 'nodeTable.filter.nodeGroup.input.placeholder',
  },
  label: {
    peMasterServer: 'nodeTable.filter.peMasterServer.input.label',
    nodeGroup: 'nodeTable.filter.nodeGroup.input.label',
  },
  options: {
    peMasterServer: [],
    nodeGroup: [],
  },
  defaultQuery: 'peMasterServer',
  inputMapper: {
    peMasterServer: {
      queryVariables: (field, values) => {
        return {
          nextQuery: 'nodeGroup',
          variables: {
            source: values.peMasterServer,
          },
        };
      },
      query: peMasterServerQuery,
      after: (data, nodeGroupSchema) => {
        const results = data?.data?.puppetEnterprise?.puppetEnterprise;
        return {
          ...nodeGroupSchema,
          options: {
            ...nodeGroupSchema.options,
            peMasterServer: results.map((server) => {
              return {
                value: server.puppetServerEndpoint.substring(
                  0,
                  server.puppetServerEndpoint.indexOf(':'),
                ),
                label: server.name,
              };
            }),
          },
          filterValue: results.reduce(
            (result, { name, puppetServerEndpoint }) => ({
              ...result,
              [name]: {
                source: puppetServerEndpoint.substring(
                  0,
                  puppetServerEndpoint.indexOf(':'),
                ),
              },
            }),
            {},
          ),
        };
      },
    },
    nodeGroup: {
      query: queryNodeGroup,
      after: (data, nodeGroupSchema) => {
        const results = data?.data?.groups?.groups;
        return {
          ...nodeGroupSchema,
          options: {
            ...nodeGroupSchema.options,
            nodeGroup: results.map((nodeGroup) => {
              return {
                value: nodeGroup.name,
                label: nodeGroup.name,
                nodeGroupId: nodeGroup.id,
              };
            }),
          },
          filterValue: results.reduce(
            (result, { id }) => ({
              ...result,
              [id]: { group: { id: { equalTo: id } } },
            }),
            {},
          ),
        };
      },
    },
  },
};
