import * as at from './actionTypes';
import {
  AddUserActions,
  ListCandidatesWithSelectedAttr,
  ListRBACGroupsWithSelectedAttr,
} from './actions';

export interface AddUserState {
  getUsersLoading: boolean;
  getGroupsLoading: boolean;
  addUsersLoading: boolean;
  addUsersComplete: boolean;
  cachedUsers: ListCandidatesWithSelectedAttr;
  filteredUsers: ListCandidatesWithSelectedAttr;
  selectAllUsers: boolean;
  getUsersError: string | null;
  getGroupsError: string | null;
  addUsersError: string | null;
  sortUsers: {
    direction: string;
    sortDataKey: 'username';
  };
  sortGroups: {
    direction: string;
    sortDataKey: 'name' | 'description';
  };
  query: string;
  indeterminateUserState: boolean;
  indeterminateGroupState: boolean;
  groups: ListRBACGroupsWithSelectedAttr;
  selectAllGroups: boolean;
}

export const defaultAddUserState: AddUserState = {
  getUsersLoading: false,
  getGroupsLoading: false,
  addUsersLoading: false,
  addUsersComplete: false,
  cachedUsers: [],
  filteredUsers: [],
  selectAllUsers: false,
  getUsersError: null,
  getGroupsError: null,
  addUsersError: null,
  sortUsers: {
    direction: 'asc',
    sortDataKey: 'username',
  },
  sortGroups: {
    direction: 'asc',
    sortDataKey: 'name' || 'description',
  },
  query: '',
  indeterminateUserState: false,
  indeterminateGroupState: false,
  groups: [],
  selectAllGroups: false,
};

export const reducer = (
  state: AddUserState,
  action: AddUserActions,
): AddUserState => {
  switch (action.type) {
    case at.GET_USERS_LOADING:
      return {
        ...state,
        getUsersLoading: true,
      };
    case at.GET_GROUPS_LOADING:
      return {
        ...state,
        getGroupsLoading: true,
      };
    case at.ADD_USERS_LOADING:
      return {
        ...state,
        addUsersLoading: true,
      };
    case at.GET_USERS_COMPLETE:
      return {
        ...state,
        getUsersLoading: false,
        cachedUsers: action.payload.workspaceMemberCandidates,
        selectAllUsers: false,
        getUsersError: null,
      };
    case at.GET_GROUPS_COMPLETE:
      return {
        ...state,
        getGroupsLoading: false,
        groups: [...action.payload.groups],
        getGroupsError: null,
      };
    case at.ADD_USERS_COMPLETE:
      return {
        ...state,
        addUsersLoading: false,
        addUsersComplete: true,
        addUsersError: null,
      };
    case at.GET_USERS_ERROR:
      return {
        ...state,
        getUsersLoading: false,
        cachedUsers: [],
        getUsersError: action.payload,
        filteredUsers: [],
        selectAllUsers: false,
      };
    case at.GET_GROUPS_ERROR:
      return {
        ...state,
        getGroupsLoading: false,
        getUsersError: action.payload,
        groups: [],
      };
    case at.ADD_USERS_ERROR:
      return {
        ...state,
        addUsersComplete: false,
        addUsersLoading: false,
        addUsersError: action.payload,
      };
    case at.TOGGLE_ALL_USERS_SELECTION:
      return {
        ...state,
        indeterminateUserState: action.payload.indeterminateUserState,
        cachedUsers: [...state.cachedUsers].map((u) => ({
          ...u,
          selected: !state.selectAllUsers,
        })),
        filteredUsers: [...state.filteredUsers].map((u) => ({
          ...u,
          selected: !state.selectAllUsers,
        })),
        selectAllUsers: action.payload.selectAll,
      };
    case at.TOGGLE_ALL_GROUPS_SELECTION:
      return {
        ...state,
        indeterminateGroupState: action.payload.indeterminateGroupState,
        groups: [...state.groups].map((u) => ({
          ...u,
          selected: !state.selectAllGroups,
        })),
        selectAllGroups: action.payload.selectAll,
      };
    case at.TOGGLE_USER_ROW_SELECTION:
      return {
        ...state,
        filteredUsers: action.payload.users,
        indeterminateUserState:
          action.payload.usersSelectedCount !== 0 &&
          action.payload.usersSelectedCount !== action.payload.users.length,
        selectAllUsers:
          action.payload.users.length === action.payload.usersSelectedCount,
      };
    case at.TOGGLE_GROUP_ROW_SELECTION:
      return {
        ...state,
        groups: action.payload.groups,
        indeterminateGroupState:
          action.payload.groupsSelectedCount !== 0 &&
          action.payload.groupsSelectedCount !== action.payload.groups.length,
        selectAllGroups:
          action.payload.groups.length === action.payload.groupsSelectedCount,
      };
    case at.FILTER_USERS:
      return {
        ...state,
        query: action.payload.query ?? state.query,
        filteredUsers: action.payload.filteredUsers,
        indeterminateUserState:
          action.payload.usersSelectedCount !== 0 &&
          action.payload.usersSelectedCount !==
            action.payload.filteredUsers.length,
        selectAllUsers:
          action.payload.filteredUsers.length ===
          action.payload.usersSelectedCount,
      };
    case at.SORT_USERS:
      return {
        ...state,
        cachedUsers: action.payload.users,
        filteredUsers: action.payload.users,
        sortUsers: action.payload.sortUsers,
      };
    case at.SORT_GROUPS:
      return {
        ...state,
        groups: action.payload.groups,
        sortGroups: action.payload.sortGroups,
      };
    default:
      return state;
  }
};
