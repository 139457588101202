const getPathVersion = () => {
  const version = process.env.TEAMS_UI_VERSION || 'latest';

  const versionMatch4x = version.match(/^4.*/);
  if (versionMatch4x) {
    return '4.x';
  }
  const versionMatch5x = version.match(/^5.*/);
  if (versionMatch5x) {
    return '5.x';
  }
  return version;
};

const docsLinks = () => {
  const pathVersion = getPathVersion();
  return {
    sidebarDocumentation: `https://puppet.com/docs/continuous-delivery/${pathVersion}/cd_user_guide.html`,
    preBuiltJobsReference: `https://puppet.com/docs/continuous-delivery/${pathVersion}/example_jobs.html#pre_built_jobs_reference`,
    webhookTroubleshooting: `https://puppet.com/docs/continuous-delivery/${pathVersion}/cd_troubleshooting.html#webhook`,
    jobTemplateSecrets: `https://puppet.com/docs/continuous-delivery/${pathVersion}/example_jobs.html`,
    impactAnalysis: `https://www.puppet.com/docs/continuous-delivery/${pathVersion}/impact_analysis.html`,
    impactAnalysisProduct: `https://www.puppet.com/products/impact-analysis?utm_source=puppet&utm_medium=in-app&utm_campaign=pup-glb-2024-products-impact-analysis`,
    configureImpactAnalysis: `https://puppet.com/docs/continuous-delivery/${pathVersion}/configure_impact_analysis.html#configure_impact_analysis`,
    addIntegration: `https://puppet.com/docs/continuous-delivery/${pathVersion}/integrations.html#integrations`,
    learnAboutPrefix: `https://www.puppet.com/docs/continuous-delivery/${pathVersion}/integrations.html#integrations-status-notification-prefix`,
    integrateBitbucketCloud: `https://puppet.com/docs/continuous-delivery/${pathVersion}/integrations.html#integrate_bitbucket_cloud`,
    integrateGithub: `https://puppet.com/docs/continuous-delivery/${pathVersion}/integrations.html#integrate_github`,
    integrateAzureDevOps: `https://puppet.com/docs/continuous-delivery/${pathVersion}/integrations.html#integrate_azure_devops`,
    permissionsReference: `https://puppet.com/docs/continuous-delivery/${pathVersion}/permissions_reference.html`,
    addHardware: `https://www.puppet.com/docs/continuous-delivery/${pathVersion}/hardware_puppet_agent.html`,
    pipelinesAsCode: `https://www.puppet.com/docs/continuous-delivery/${pathVersion}/pipelines_code.html`,
    puppetLandingPage: `https://www.puppet.com`,
  };
};

export default docsLinks;
