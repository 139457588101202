// Function will change the none status values from pdb api to be more human readable
// If an unknown value will just return
function changeStatusText(status, correctiveChange) {
  let newStatus = '';
  switch (status) {
    case 'changed':
      if (correctiveChange) {
        newStatus = 'corrective change';
        break;
      }
      newStatus = 'intentional change';
      break;
    case 'failed':
      newStatus = 'failure';
      break;
    case 'unchanged':
      newStatus = 'no change';
      break;
    default:
      newStatus = status;
  }

  return newStatus;
}

export default changeStatusText;
