import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, ConfirmationModal, Loading } from '@puppet/react-components';
import ConditionalRender from 'inventory/components/ConditionalRender';
import { GraphQLObjectType } from 'graphql';
import { useQuery } from '@apollo/client';
import Chart from 'inventory/components/Chart';
import ChartCardAlternate from './ChartCardAlternate';
import './ChartCard.scss';

const ChartCard = ({
  id,
  label,
  queryName,
  onDelete,
  onEdit,
  workspaceId,
  after,
  responseKey,
  totalCountKey,
  hostCountKey,
  filter,
  chartType,
  chartOptions,
  order,
  areNodesExcluded,
  ...props
}) => {
  const { t } = useTranslation('inventory');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const actions = [
    {
      id: 'removeFactOption',
      icon: 'trash',
      label: t('chartCard.actionButton.remove'),
      onClick: () => setIsConfirmOpen(true),
      'data-TestId': `chart-card-remove`,
    },
    {
      id: 'editFactOption',
      icon: 'pencil',
      label: t('chartCard.actionButton.edit'),
      onClick: onEdit,
      'data-TestId': `chart-card-edit`,
    },
  ];

  const { data, loading, error } = useQuery(queryName, {
    variables: {
      id: workspaceId,
      filter,
    },
  });

  const chartData = useMemo(
    () =>
      after(
        data,
        responseKey,
        totalCountKey,
        order,
        hostCountKey,
        areNodesExcluded,
      ),
    [
      data,
      responseKey,
      totalCountKey,
      hostCountKey,
      areNodesExcluded,
      order,
      after,
    ],
  );

  const hasError = Boolean(error);
  const hasData = chartData?.categories?.length > 0;

  return (
    <>
      <Card
        className="chart-card"
        data-testid={`chart-card-${filter}`}
        {...props}
      >
        <div className="chart-card-container">
          <Card.Title as="h5" className="chart-card-title">
            {label}
          </Card.Title>
          <Card.ActionSelect
            type="transparent"
            aria-label="Chart actions"
            actions={actions}
            as="h5"
            data-testid={`chart-card-actions-${filter}`}
          >
            {label}
          </Card.ActionSelect>
          <ConditionalRender enable={loading}>
            <Loading
              data-testid={`chart-card-loading-${filter}`}
              size="small"
            />
          </ConditionalRender>
          <ConditionalRender enable={hasError}>
            <ChartCardAlternate
              title={t('chart.modal.error.title')}
              text={error?.message ?? ''}
              icon="alert"
            />
          </ConditionalRender>
          <ConditionalRender enable={!loading && !hasError && !hasData}>
            <ChartCardAlternate
              title={t('chart.modal.empty.title')}
              text={t('chart.modal.empty.text')}
              icon="reports"
              onDelete={() => setIsConfirmOpen(true)}
              onEdit={onEdit}
            />
          </ConditionalRender>
          <ConditionalRender enable={!loading && !hasError && hasData}>
            <Chart type={chartType} data={chartData} options={chartOptions} />
          </ConditionalRender>
        </div>
      </Card>
      <ConfirmationModal
        title={t('chart.modal.remove.title')}
        description={t('chart.modal.remove.description')}
        confirmLabel={t('chart.modal.remove.confirmLabel')}
        cancelLabel={t('chart.modal.remove.cancelLabel')}
        confirmButtonType="danger"
        onConfirm={() => {
          setIsConfirmOpen(false);
          onDelete(id);
        }}
        onCancel={() => setIsConfirmOpen(false)}
        isOpen={isConfirmOpen}
      />
    </>
  );
};

ChartCard.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  queryName: PropTypes.instanceOf(GraphQLObjectType).isRequired,
  chartType: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  workspaceId: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  after: PropTypes.func.isRequired,
  responseKey: PropTypes.string,
  totalCountKey: PropTypes.string,
  hostCountKey: PropTypes.string,
  filter: PropTypes.string,
  chartOptions: PropTypes.shape({
    grid: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }).isRequired,
  areNodesExcluded: PropTypes.bool.isRequired,
};

ChartCard.defaultProps = {
  filter: '',
  responseKey: '',
  totalCountKey: '',
  hostCountKey: '',
};

export default ChartCard;
