import { Dispatch } from 'react';
import actionCreator, { Actions } from '@utils/actionCreator';
import { JobTemplateSummaryV1 } from '@utils/api/cd4pe';
import { numberCompare, stringCompare } from '@utils/compare';
import * as at from './actionTypes';

type SortDirection = 'desc' | 'asc';
type SortKey = keyof Omit<JobTemplateSummaryV1, 'id' | 'workspaceId'>;
export interface SortData {
  sortDataKey: SortKey;
  direction: SortDirection;
}

export const listJobTemplateActions = {
  toggleConfirmJobTemplateDelete: (jobTemplateId: number | null) =>
    actionCreator(at.TOGGLE_CONFIRM_JOB_TEMPLATE_DELETE, jobTemplateId),
  setSort: (payload: SortData) => actionCreator(at.SORT_JOB_TEMPLATES, payload),
  setQuery: (payload: string) =>
    actionCreator(at.FILTER_JOB_TEMPLATES, payload),
};

export type JobTemplateListActions = Actions<typeof listJobTemplateActions>;

export const JOB_TEMPLATE_SORTS: {
  [K in SortKey]: (
    direction: SortDirection,
  ) => (a: JobTemplateSummaryV1, b: JobTemplateSummaryV1) => number;
} = {
  name:
    (direction: SortDirection) =>
    ({ name: a }: JobTemplateSummaryV1, { name: b }: JobTemplateSummaryV1) =>
      (direction === 'asc' ? 1 : -1) * stringCompare(a, b),
  description:
    (direction: SortDirection) =>
    (
      { description: a }: JobTemplateSummaryV1,
      { description: b }: JobTemplateSummaryV1,
    ) =>
      (direction === 'asc' ? 1 : -1) * stringCompare(a, b),
  lastRunTime:
    (direction: SortDirection) =>
    (
      { lastRunTime: a }: JobTemplateSummaryV1,
      { lastRunTime: b }: JobTemplateSummaryV1,
    ) =>
      (direction === 'asc' ? 1 : -1) * numberCompare(a || 0, b || 0),
};

export const toggleConfirmJobTemplateDelete = (
  dispatch: Dispatch<JobTemplateListActions>,
  jobTemplateId: number | null = null,
) => {
  dispatch(
    listJobTemplateActions.toggleConfirmJobTemplateDelete(jobTemplateId),
  );
};
