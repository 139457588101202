import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link as PdsLink, Text, Icon, Heading } from '@puppet/react-components';
import { CodeProjectDetailsV1, TriggerEventV1 } from '@puppet/cd4pe-client-ts';
import useVcsLinks from '@hooks/useVcsLinks';
import getIconType from '@utils/vcs/iconHelper';
import { PIPELINE_BRANCH_OPTIONS } from '../../../../utils';

interface Props {
  codeProject: CodeProjectDetailsV1;
  event: TriggerEventV1;
}

const EventDetails = ({ codeProject, event }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const { getBranchLink, getCommitLink } = useVcsLinks();

  const branchLink = getBranchLink(
    codeProject.srcRepoProvider,
    codeProject.srcRepoOwner,
    codeProject.srcRepoDisplayName,
    codeProject.srcRepoId,
    event.branch ?? '',
  );

  const commitLink = getCommitLink(
    codeProject.srcRepoProvider,
    codeProject.srcRepoOwner,
    codeProject.srcRepoDisplayName,
    codeProject.srcRepoId,
    event.commitId ?? '',
  );

  return (
    <div className="event-details">
      <Heading as="h5">{event.description}</Heading>
      <Trans
        parent={Text}
        className="event-details__links"
        t={t}
        i18nKey="viewPipeline.events.table.row.details"
      >
        <Icon size="small" type={getIconType(codeProject.srcRepoProvider)} />
        <PdsLink
          size="small"
          as="a"
          href={branchLink}
          target="_blank"
          data-testid="event-details-branch"
        >
          {{ branch: event.branch }}
        </PdsLink>
        <PdsLink size="small" as="a" href={commitLink} target="_blank">
          {{
            commitId: event.commitId,
          }}
        </PdsLink>
      </Trans>
      <div className="event-details__detail-value">
        <Trans t={t} i18nKey="viewPipeline.events.table.row.details.initiator">
          <Text size="small" />
          <Text size="small" color="medium">
            {{ initiator: event.initiator }}
          </Text>
        </Trans>
      </div>
      {event.pipelineName === PIPELINE_BRANCH_OPTIONS.MANUAL_ACTION ? (
        <Text size="small" color="medium">
          {t('viewPipeline.events.table.row.details.manualAction')}
        </Text>
      ) : (
        <div
          className="event-details__detail-value"
          data-testid="event-details-pipelineName"
        >
          <Trans
            t={t}
            i18nKey="viewPipeline.events.table.row.details.pipelineName"
          >
            <Text size="small" />
            <Text size="small" color="medium">
              {{
                pipelineName: event.pipelineName,
              }}
            </Text>
          </Trans>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
