import CommonForm from '@components/CommonForm';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, RadioButton } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { ConnectGitlabVcsIntegrationRequestV1 } from '@utils/api/cd4pe';
import { useConnectGitlabV1 } from '@services/cd4pe/vcs';
import getProviderDisplayName from '@utils/vcs/displayNameHelper';
import * as Provider from '@utils/vcs/providerConsts';
import Cd4peError from '@components/Cd4peError';

type GitlabFormValues = {
  host: string | undefined;
  token: string | undefined;
  caCertificate?: string;
  httpClone?: boolean;
  sshUser?: string;
  sshPort?: number | undefined;
};

type CloneMethod = 'ssh' | 'https';

const GitlabForm = ({
  onSuccess,
  onCancel,
}: {
  onSuccess: (provider: string) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const [showToken, setShowToken] = useState(false);
  const [cloneMethod, setCloneMethod] = useState<CloneMethod>('ssh');
  const [formValues, setFormValues] = useState<GitlabFormValues>({
    host: '',
    token: '',
    caCertificate: '',
    httpClone: false,
    sshUser: '',
  });

  const connectGitlab = useConnectGitlabV1();

  const changeCloneMethod = (method: CloneMethod) => {
    setCloneMethod(method);
    setFormValues({
      ...formValues,
      caCertificate: '',
      httpClone: method === 'https',
      sshUser: '',
      sshPort: undefined,
    });
  };

  const onSubmit = async () => {
    const requestBody = {
      ...formValues,
      sshPort: formValues.sshPort && Number(formValues.sshPort),
    } as ConnectGitlabVcsIntegrationRequestV1;

    if (cloneMethod === 'ssh' || requestBody.caCertificate === '') {
      delete requestBody.caCertificate;
    }

    return connectGitlab.mutate(
      {
        workspaceId,
        requestBody,
      },
      {
        onSuccess: () => {
          onSuccess(getProviderDisplayName(Provider.GITLAB));
        },
      },
    );
  };

  return (
    <CommonForm
      values={formValues}
      onChange={(_, values) => setFormValues(values)}
      submittable
      cancellable
      className="git-lab-form"
      submitLabel={t('addSourceControl.form.submitButton')}
      submitting={connectGitlab.isLoading}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <CommonForm.Section className="add-source-control-credentials">
        <CommonForm.Section.Main>
          <h3 className="add-source-control-form-section__header">
            {t('addSourceControl.credentials.header')}
          </h3>
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              name="host"
              type="text"
              label={t('addSourceControl.gitlab.form.host.label')}
              required
            />
          </div>
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              type={showToken ? 'text' : 'password'}
              trailingButtonIcon="eye"
              trailingButtonProps={{
                'aria-label': t('addSourceControl.gitlab.form.token.ariaLabel'),
              }}
              onClickTrailingButton={() => setShowToken(!showToken)}
              name="token"
              label={t('addSourceControl.gitlab.form.token.label')}
              required
            />
          </div>
          <div className="add-source-control-form-radio__wrapper">
            <RadioButton
              className="add-source-control-form-cert-type__radio"
              label={t('addSourceControl.gitlab.form.clone.ssh.label')}
              name="radio-ssh"
              value={cloneMethod === 'ssh'}
              onChange={() => changeCloneMethod('ssh')}
            />
            <RadioButton
              className="add-source-control-form-cert-type__radio"
              label={t('addSourceControl.gitlab.form.clone.https.label')}
              name="httpClone"
              value={cloneMethod === 'https'}
              onChange={() => changeCloneMethod('https')}
            />
          </div>
          {cloneMethod === 'https' ? (
            <div className="add-source-control-form-credentials-field__wrapper">
              <Form.Field
                name="caCertificate"
                type="multiline"
                label={t('addSourceControl.gitlab.form.cert.label')}
              />
            </div>
          ) : (
            <div className="add-source-control-form-credentials-field__wrapper">
              <Form.Field
                name="sshUser"
                type="text"
                label={t('addSourceControl.gitlab.form.sshUser.label')}
              />
              <Form.Field
                name="sshPort"
                type="number"
                label={t('addSourceControl.gitlab.form.sshPort.label')}
              />
            </div>
          )}
        </CommonForm.Section.Main>
      </CommonForm.Section>
      <div className="add-source-control-form-error">
        <Cd4peError error={connectGitlab.error} />
      </div>
    </CommonForm>
  );
};

export default GitlabForm;
