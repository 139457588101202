import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Card, Text } from '@puppet/react-components';
import { JobHardwareNodeV1 } from '@utils/api/cd4pe';

type FormSidebarProps = {
  nodes: JobHardwareNodeV1[];
  onDeleteAll: () => void;
  onDeleteNode: (index: number) => void;
};

const FormSidebar = ({
  nodes,
  onDeleteAll,
  onDeleteNode,
}: FormSidebarProps) => {
  const { t } = useTranslation('codeDelivery');
  return (
    <>
      <div className="job-hardware-capability-form__selected-nodes-heading">
        <Heading as="h4">
          {t('jobHardwareCapability.section.nodes.sidebar')}
        </Heading>
        <Button
          type="secondary"
          icon="trash"
          onClick={onDeleteAll}
          disabled={!nodes.length}
          aria-label={t(
            'jobHardwareCapability.section.nodes.sidebar.deleteAll.ariaLabel',
          )}
        />
      </div>
      <div
        role="list"
        className="job-hardware-capability-form__selected-nodes-list"
      >
        {nodes.map((node, index) => (
          <Card
            key={node.name}
            role="listitem"
            className="job-hardware-capability-form__selected-card"
          >
            <Card.Title>
              <Text
                className="job-hardware-capability-form__selected-card-heading"
                size="small"
              >
                <strong>{node.name}</strong>
              </Text>
              <Text size="small" color="medium">
                {node.peServerName}
              </Text>
            </Card.Title>
            <Card.Action
              icon="x"
              onClick={() => onDeleteNode(index)}
              aria-label={t(
                'jobHardwareCapability.section.nodes.sidebar.deleteNode.ariaLabel',
                { name: node.name },
              )}
            />
          </Card>
        ))}
      </div>
    </>
  );
};

export default FormSidebar;
