import ComplexOperatorFilter from 'inventory/components/NodeTableFilter/ComplexOperatorFilter';
import queryFactPaths from 'inventory/services/graphql/queries/queryFactPaths.gql';
import operator from './commonOptions/operator';

export default {
  component: ComplexOperatorFilter,
  query: queryFactPaths,
  name: {
    autoComplete: 'factName',
    operator: operator.name,
    textInput: 'textValue',
  },
  placeholder: {
    autoComplete: 'nodeTable.filter.factValue.input.placeholder',
    operator: operator.placeholder,
    textInput: 'nodeTable.filter.factValue.textInput.placeholder',
  },
  label: {
    autoComplete: 'nodeTable.filter.factValue.input.label',
    operator: operator.label,
    textInput: 'nodeTable.filter.factValue.textInput.label',
  },
  options: {
    operator: [],
    autoComplete: [],
  },
  defaultOptions: {
    operator: operator.default,
    autoComplete: { label: 'Operating System', value: 'operatingsystem' },
  },
  filterValue: {
    operator: operator.filterValue,
  },
  after: (data, queryFactPaths2) => {
    const rawFacts = data?.data?.factPaths ?? [];
    return {
      ...queryFactPaths2,
      options: {
        autoComplete: rawFacts
          .filter((fact, index) => rawFacts.indexOf(fact) === index)
          .map((fact) => {
            return {
              value: fact,
              label: fact,
            };
          }),
        operator: operator.options,
      },
    };
  },
};
