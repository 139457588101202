import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, ConfirmationModal } from '@puppet/react-components';
import { useMutation } from '@apollo/client';
import DELETE_SAVED_VIEWS from 'inventory/services/graphql/queries/mutationDeleteView.gql';
import client from 'inventory/services/graphql/index';

const DeleteViewModal = ({ name, workspace }) => {
  const { t } = useTranslation('inventory');
  const [open, setOpen] = useState(false);

  const [deleteView] = useMutation(DELETE_SAVED_VIEWS, { client });

  const onConfirm = (deleteName, deleteWorkspace) => {
    deleteView({
      variables: {
        name: deleteName,
        workspace: deleteWorkspace,
      },
      onComplete: setOpen(false),
      refetchQueries: ['GET_FULL_SAVED_VIEWS'],
    });
  };

  return (
    <div>
      <Button
        type="transparent"
        icon="trash"
        onClick={() => setOpen(true)}
        data-testid={`stored-views-delete-${name}`}
      />
      <ConfirmationModal
        title={t('storedView.DeleteViewModal.title')}
        description={t('storedView.DeleteViewModal.description')}
        confirmLabel={t('common.modal.button.delete')}
        cancelLabel={t('common.modal.button.cancel')}
        confirmButtonType="danger"
        onConfirm={() => onConfirm(name, workspace)}
        onCancel={() => setOpen(false)}
        isOpen={open}
      />
    </div>
  );
};
export default DeleteViewModal;

DeleteViewModal.propTypes = {
  name: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
};
