import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useAppDispatch } from '@hooks/redux';
import {
  CreatePEIntegrationRequestV2,
  DiscoverPEIntegrationRequestV2,
  PEIntegrationGenerateTokenRequestV2,
} from '@utils/api/cd4pe';
import { Button, Modal, Text } from '@puppet/react-components';
import alerts from '@state/ui/alerts';
import AutoIntegrationForm, {
  AutoIntegrationFormOnChange,
} from '../AutoIntegrationForm';
import ManualIntegrationForm, {
  manualIntegrationFormActions,
  ManualIntegrationFormOnChange,
} from './components/ManualIntegrationForm';
import {
  addPeIntegrationActions,
  createPeIntegration,
  discoverPeIntegration,
  configureIa,
  AuthType,
  FormType,
} from './actions';
import IaConfigForm, { IaConfigFormOnChange } from './components/IaConfigForm';
import { defaultAddPeIntegrationState, reducer } from './reducer';

const DAYS_IN_A_MONTH = Math.ceil(365 / 12);

type props = {
  onCancel: () => void;
  onSuccess: () => void;
};

const AddPEIntegrationForm = ({ onCancel, onSuccess }: props) => {
  const [state, dispatch] = useReducer(reducer, defaultAddPeIntegrationState);
  const { t } = useTranslation('codeDelivery');
  const appDispatch = useAppDispatch();
  const workspaceId = useWorkspaceDomain();

  useEffect(() => {
    if (state.newIntegration?.impactAnalysisConfigured) {
      onSuccess();
    }
  }, [onSuccess, state.newIntegration]);

  const integrationStatus = state.newIntegration != null;
  useEffect(() => {
    if (integrationStatus) {
      appDispatch(
        alerts.actions.createAlert({
          type: 'Success',
          message: t('addPeIntegration.alerts.success'),
        }),
      );
    }
  }, [integrationStatus, appDispatch, t]);

  const onAutoFormChange: AutoIntegrationFormOnChange = (fieldName, values) => {
    dispatch({ type: fieldName, payload: values[fieldName] || '' });
  };
  const onManualFormChange: ManualIntegrationFormOnChange = (
    fieldName,
    values,
  ) => {
    dispatch(manualIntegrationFormActions.patchForm(values));
  };
  const onIaFormChange: IaConfigFormOnChange = (fieldName, values) => {
    dispatch({ type: fieldName, payload: values[fieldName] || '' });
  };

  const dispatchAuthTypeChange = (payload: AuthType) => () => {
    dispatch(addPeIntegrationActions.authType(payload));
  };
  const changeFormType = (payload: FormType) => () => {
    dispatch(addPeIntegrationActions.formType(payload));
  };
  const dispatchIaModal = (payload: boolean) => () => {
    dispatch(addPeIntegrationActions.iaConfigModal(payload));
  };
  const autoDiscoverIntegration = async () => {
    dispatch(addPeIntegrationActions.discoverIntegrationStart());
    if (state.authType === 'basic') {
      const tokenLifetime = Math.ceil(
        DAYS_IN_A_MONTH * Number(state.tokenLifetime),
      );
      const generateTokenRequest: PEIntegrationGenerateTokenRequestV2 = {
        username: state.peUsername,
        password: state.password,
        tokenLifetime: `${tokenLifetime}d`,
      };
      const discoverPayload: DiscoverPEIntegrationRequestV2 = {
        workspaceId,
        name: state.name,
        nodeClassifierUrl: state.consoleAddress,
        generateTokenRequest,
      };
      await discoverPeIntegration(dispatch, discoverPayload);
      return;
    }
    const discoverPayload: DiscoverPEIntegrationRequestV2 = {
      workspaceId,
      name: state.name,
      nodeClassifierUrl: state.consoleAddress,
      token: state.apiToken,
    };
    await discoverPeIntegration(dispatch, discoverPayload);
  };
  const manuallySaveIntegration = async () => {
    dispatch(addPeIntegrationActions.createIntegrationStart());
    const createPayload: CreatePEIntegrationRequestV2 = {
      workspaceId,
      name: state.name,
      nodeClassifierUrl: state.classifier,
      puppetServerUrl: state.puppetServer,
      puppetDbUrl: state.puppetDb,
      codeManagerUrl: state.codeManager,
      orchestratorUrl: state.orchestrator,
      caCertificate: state.caCert,
      token: state.apiToken,
    };
    await createPeIntegration(dispatch, createPayload);
  };
  const configureImpactAnalysis = async () => {
    dispatch(addPeIntegrationActions.configureIaStart());
    if (state.newIntegration) {
      configureIa(
        dispatch,
        state.newIntegration.id,
        state.puppetServer,
        state.iaCert,
        state.iaPrivateKey,
      );
    }
  };

  const iaConfigModal = () => {
    if (state.iaConfigModal) {
      return (
        <Modal className="ia-config-modal" onClose={dispatchIaModal(false)}>
          <Modal.Title>{t('addPeIntegration.iaConfigModal.title')}</Modal.Title>
          <Text>{t('addPeIntegration.iaConfigModal.text1')}</Text>
          <Text>{t('addPeIntegration.iaConfigModal.text2')}</Text>
          <Modal.Actions>
            <Button onClick={changeFormType('ia')}>
              {t('addPeIntegration.iaConfigModal.confirmButton')}
            </Button>
            <Button type="tertiary" onClick={dispatchIaModal(false)}>
              {t('addPeIntegration.iaConfigModal.cancelButton')}
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  };

  const integrationForm = () => {
    if (state.formType === 'auto') {
      return (
        <AutoIntegrationForm
          data-testid="pe-integration-form"
          name={state.name}
          consoleAddress={state.consoleAddress}
          authType={state.authType}
          peUsername={state.peUsername}
          password={state.password}
          tokenLifetime={state.tokenLifetime}
          apiToken={state.apiToken}
          saveLoading={state.discoverIntegrationLoading}
          saveError={state.discoverIntegrationError}
          onFormChange={onAutoFormChange}
          authTypeChange={dispatchAuthTypeChange}
          changeFormType={changeFormType}
          submitCallback={autoDiscoverIntegration}
          cancelCallback={onCancel}
          submitLabel={t('addPeIntegration.autoIntegrationForm.submitButton')}
          infoHeader={t(
            'addPeIntegration.autoIntegrationForm.authSection.infoHeader',
          )}
          infoBody={t(
            'addPeIntegration.autoIntegrationForm.authSection.infoBody',
          )}
        />
      );
    }
    if (state.formType === 'manual') {
      return (
        <ManualIntegrationForm
          name={state.name}
          apiToken={state.apiToken}
          puppetServer={state.puppetServer}
          puppetDb={state.puppetDb}
          codeManager={state.codeManager}
          orchestrator={state.orchestrator}
          classifier={state.classifier}
          caCert={state.caCert}
          submitLabel={t(`addPeIntegration.manualIntegrationForm.submitButton`)}
          updateIntegration={false}
          saveLoading={state.createIntegrationLoading}
          saveError={state.createIntegrationError}
          onFormChange={onManualFormChange}
          changeFormType={changeFormType}
          submitCallback={manuallySaveIntegration}
          cancelCallback={onCancel}
        />
      );
    }
    return (
      <IaConfigForm
        puppetServer={state.puppetServer}
        iaCert={state.iaCert}
        iaPrivateKey={state.iaPrivateKey}
        onFormChange={onIaFormChange}
        saveLoading={state.configureIaLoading}
        saveError={state.configureIaError}
        submitCallback={configureImpactAnalysis}
        cancelCallback={onCancel}
      />
    );
  };

  return (
    <>
      {integrationForm()}
      {iaConfigModal()}
    </>
  );
};

export default AddPEIntegrationForm;
