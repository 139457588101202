import CommonForm from '@components/CommonForm';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, RadioButton } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useConnectAzureDevOpsServerV1 } from '@services/cd4pe/vcs';
import * as Provider from '@utils/vcs/providerConsts';
import getProviderDisplayName from '@utils/vcs/displayNameHelper';
import Cd4peError from '@components/Cd4peError';
import {
  ConnectAzureDevOpsServerRequestV1,
  VcsIntegrationV1,
} from '@puppet/cd4pe-client-ts';
import OAuthForm from '@codeDelivery/views/AddSourceControl/components/forms/OAuthForm';

const AzureDevOpsForm = ({
  integration,
  onSuccess,
  onCancel,
}: {
  integration: VcsIntegrationV1;
  onSuccess: (provider: string) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const [showToken, setShowToken] = useState(false);
  const [certType, setCertType] = useState<'standard' | 'custom'>('custom');
  const [onPrem, setOnPrem] = useState(false);
  const [formValues, setFormValues] =
    useState<ConnectAzureDevOpsServerRequestV1>({
      host: '',
      token: '',
      caCertificate: '',
    });

  const connectAzureDevOpsServer = useConnectAzureDevOpsServerV1();

  const changeCertType = (type: 'standard' | 'custom') => {
    setFormValues({ ...formValues, caCertificate: '' });
    setCertType(type);
  };

  const onSubmit = async () => {
    if (certType === 'standard') {
      delete formValues.caCertificate;
    }
    return connectAzureDevOpsServer.mutate(
      {
        workspaceId,
        requestBody: formValues,
      },
      {
        onSuccess: () => {
          onSuccess(getProviderDisplayName(Provider.AZURE_DEV_OPS));
        },
      },
    );
  };

  return (
    <CommonForm
      values={formValues}
      onChange={(_, values) =>
        setFormValues(values as ConnectAzureDevOpsServerRequestV1)
      }
      submittable={onPrem}
      cancellable
      className="azure-dev-ops-server-form"
      submitLabel={t('addSourceControl.form.submitButton')}
      submitting={connectAzureDevOpsServer.isLoading}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <CommonForm.Section
        className={
          onPrem
            ? 'add-source-control-credentials'
            : 'add-source-control-form__empty'
        }
      >
        <CommonForm.Section.Main>
          <h3 className="add-source-control-form-section__header">
            {t('addSourceControl.credentials.header')}
          </h3>
          <div className="add-source-control-form-credentials-field__wrapper add-source-control-form-radio__wrapper-inline">
            <RadioButton
              className="add-source-control-form-cert-type__radio"
              label={t('addSourceControl.azureDevOps.form.radio.cloud.label')}
              name="radio-cloud"
              value={!onPrem}
              onChange={() => setOnPrem(false)}
            />
            <RadioButton
              className="add-source-control-form-cert-type__radio"
              label={t('addSourceControl.azureDevOps.form.radio.onPrem.label')}
              name="radio-onPrem"
              value={onPrem}
              onChange={() => setOnPrem(true)}
            />
          </div>
          {onPrem ? (
            <>
              <div className="add-source-control-form-credentials-field__wrapper">
                <Form.Field
                  name="host"
                  type="text"
                  label={t('addSourceControl.form.host.label')}
                  required
                />
              </div>
              <div className="add-source-control-form-credentials-field__wrapper">
                <Form.Field
                  type={showToken ? 'text' : 'password'}
                  trailingButtonIcon="eye"
                  trailingButtonProps={{
                    'aria-label': t('addSourceControl.form.token.ariaLabel'),
                  }}
                  onClickTrailingButton={() => setShowToken(!showToken)}
                  name="token"
                  label={t('addSourceControl.form.token.label')}
                  required
                />
              </div>
              <div className="add-source-control-form-credentials-field__wrapper add-source-control-form-radio__wrapper">
                <RadioButton
                  className="add-source-control-form-cert-type__radio"
                  label={t('addSourceControl.form.cert.standard')}
                  name="radio-standard"
                  value={certType === 'standard'}
                  onChange={() => changeCertType('standard')}
                />
                <RadioButton
                  className="add-source-control-form-cert-type__radio"
                  label={t('addSourceControl.form.cert.custom')}
                  name="radio-custom"
                  value={certType === 'custom'}
                  onChange={() => changeCertType('custom')}
                />
              </div>
              {certType === 'custom' && (
                <div className="add-source-control-form-credentials-field__wrapper">
                  <Form.Field
                    name="caCertificate"
                    type="multiline"
                    label={t('addSourceControl.form.cert.label')}
                    required={certType === 'custom'}
                  />
                </div>
              )}
            </>
          ) : (
            <OAuthForm integration={integration} onCancel={onCancel} nested />
          )}
        </CommonForm.Section.Main>
      </CommonForm.Section>
      <div className="add-source-control-form-error">
        <Cd4peError error={connectAzureDevOpsServer.error} />
      </div>
    </CommonForm>
  );
};

export default AzureDevOpsForm;
