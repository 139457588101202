import React, { useState } from 'react';
import Table, { Columns } from '@components/Table';
import { Trans, useTranslation } from 'react-i18next';
import { JobHardwareNodeV1 } from '@puppet/cd4pe-client-ts';
import { Button, Link } from '@puppet/react-components';
import { useDeleteNodeFromCapabilityV1 } from '@services/cd4pe/hardware';
import Cd4peError from '@components/Cd4peError';
import { LINKS } from 'src/routes';
import { NavLink } from 'react-router-dom';
import useWorkspaceName from '@hooks/useWorkspaceName';

interface props {
  nodeData: JobHardwareNodeV1[];
  isLoading: boolean;
  workspaceId: string;
  id: number;
}

const JobHardwareCapabilityNodes = ({
  nodeData,
  isLoading,
  workspaceId,
  id,
}: props) => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const [deleteNodeName, setDeleteNodeName] = useState<string | null>(null);
  const deleteNodeFromCapability = useDeleteNodeFromCapabilityV1();

  const columns: Columns = [
    { label: t('jobHardware.table.column.name'), dataKey: 'name' },
    { label: t('jobHardware.table.column.server'), dataKey: 'peServerName' },
    { label: t('jobHardware.table.column.os'), dataKey: 'operatingSystem' },
    {
      label: t('jobHardware.table.column.actions'),
      dataKey: 'nodeName',
      className: 'job-hardware-capability__table-delete-column',
      style: { width: '200px', textAlign: 'center' },
      cellRenderer: (deleteProps) => {
        if (deleteNodeName === deleteProps.rowData.name) {
          return (
            <div>
              <Button
                aria-label={t(
                  'jobHardware.nodes.table.button.delete.ariaLabel',
                  {
                    nodeName: deleteProps.rowData.name,
                  },
                )}
                icon="trash"
                type="danger"
                onClick={() => {
                  deleteNodeFromCapability.mutate(
                    {
                      workspaceId,
                      id,
                      nodeName: deleteProps.rowData.name as string,
                    },
                    {
                      onSettled: () => setDeleteNodeName(null),
                    },
                  );
                }}
                data-testid={`delete-hardware-node-confirm-${deleteProps.rowData.name}`}
              >
                {t('jobHardware.nodes.table.button.delete')}
              </Button>
              <Button
                aria-label={t(
                  'jobHardware.nodes.table.button.cancel.ariaLabel',
                  {
                    nodeName: deleteProps.rowData.name,
                  },
                )}
                type="secondary"
                onClick={() => setDeleteNodeName(null)}
              >
                {t('jobHardware.nodes.table.button.cancel')}
              </Button>
            </div>
          );
        }
        return (
          <div>
            <Button
              aria-label={t('jobHardware.nodes.table.icon.delete', {
                nodeName: deleteProps.rowData.name,
              })}
              icon="trash"
              type="secondary"
              onClick={() =>
                setDeleteNodeName(deleteProps.rowData.name as string)
              }
              data-testid={`delete-hardware-node-${deleteProps.rowData.name}`}
            />
          </div>
        );
      },
    },
  ];

  if (nodeData.length === 0) {
    return (
      <div className="job-hardware-capability__empty-message">
        <Trans
          t={t}
          i18nKey="jobHardware.table.emptyState.body"
          components={[
            <Link
              as={NavLink}
              to={LINKS.codeDelivery.editCapability({
                path: { workspace, capabilityId: id },
              })}
            />,
          ]}
        />
      </div>
    );
  }

  return (
    <>
      <Cd4peError error={deleteNodeFromCapability.error} />
      <Table columns={columns} data={nodeData} loading={isLoading} />
    </>
  );
};

export default JobHardwareCapabilityNodes;
