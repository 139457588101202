import { ApiError, BaseErrorV1, ErrorV1 } from '@utils/api/cd4pe';
import {
  UseMutationResult,
  UseQueryResult,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import {
  Cd4peApiError,
  Cd4peError,
  formatError,
  getApiError,
  isApiError,
  isCd4peApiError,
  isGenericError,
  KnownCd4peError,
} from './errors';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ApiReturnValue<T, K extends keyof T> = T[K] extends (
  ...args: any[]
) => any
  ? Awaited<ReturnType<T[K]>>
  : never;

export type ApiPayload<T, K extends keyof T> = T[K] extends (
  ...args: any[]
) => any
  ? Parameters<T[K]>[0]
  : never;

// TODO revisit & consider making payload param optional for requests with no payload, ie useGetSharedDockerImageSettingsV1
export type QueryHook<
  T,
  K extends keyof T,
  E = ApiError | ErrorV1,
> = T[K] extends (...args: any[]) => any
  ? (
      payload: ApiPayload<T, K>,
      options?: UseQueryOptions<any, any, any, any>,
    ) => UseQueryResult<ApiReturnValue<T, K>, E>
  : never;

export type QueryHookNoPayload<
  T,
  K extends keyof T,
  E = ApiError | ErrorV1,
> = T[K] extends (...args: any[]) => any
  ? (
      options?: UseQueryOptions<any, any, any, any>,
    ) => UseQueryResult<ApiReturnValue<T, K>, E>
  : never;

export type InfiniteQueryHook<
  T,
  K extends keyof T,
  E = ApiError | ErrorV1,
> = T[K] extends (...args: any[]) => any
  ? (
      payload: ApiPayload<T, K>,
      options?: UseInfiniteQueryOptions<any, any, any, any, string[]>,
    ) => UseInfiniteQueryResult<ApiReturnValue<T, K>, E>
  : never;

export type MutationHook<
  T,
  K extends keyof T,
  E = ApiError | ErrorV1,
> = T[K] extends (...args: any[]) => any
  ? () => UseMutationResult<ApiReturnValue<T, K>, E, ApiPayload<T, K>>
  : never;
/* eslint-enable @typescript-eslint/no-explicit-any */

const isKnownCd4peErrorBody = <Response>(
  response: Response | KnownCd4peError | ApiError,
): response is KnownCd4peError => {
  if (!response || Object.keys(response).length <= 0) {
    return false;
  }

  const baseError = response as BaseErrorV1;

  return (
    typeof baseError.message === 'string' &&
    typeof baseError.traceId === 'string' &&
    typeof baseError.uriPath === 'string'
  );
};

const toError = <ErrorResponse>(error: ErrorResponse): Cd4peApiError => {
  if (!(error instanceof ApiError)) {
    return new ApiError(
      {
        body: null,
        ok: false,
        status: 1,
        statusText: 'Unknown error',
        url: 'unknown',
      },
      'An unknown error occurred.',
    );
  }

  if (isKnownCd4peErrorBody(error.body)) {
    return new Cd4peError(error.body, error.status, error.message);
  }

  return error;
};

export const isOkResponse = <Response>(
  response: Response | Cd4peError | ApiError,
): response is Exclude<Response, Cd4peError | ApiError> =>
  !isCd4peApiError(response);

export const handleQueryRequest = async <Response>(
  apiCall: Promise<Response>,
): Promise<Response> => {
  try {
    const response = await apiCall;
    return response as Response;
  } catch (e) {
    throw toError(e);
  }
};

export const handleApiRequest = async <Response>(
  apiCall: Promise<Response>,
): Promise<Response | Cd4peError | ApiError> => {
  try {
    const response = await apiCall;
    return response as Response;
  } catch (e) {
    return toError(e);
  }
};

/**
 * All methods below are depecrated and should not be used in new components
 */

export const isSuccessResponse = <T, K = Exclude<T, ApiError | ErrorV1>>(
  response: K | ApiError | ErrorV1,
): response is K => !isGenericError(response) && !isApiError(response);

// This should not be used anymore and should be replaced with
// handleQueryRequest
export const unwrapApiResponseWithThrow = async <T, K = Exclude<T, ErrorV1>>(
  apiCall: Promise<K | ErrorV1 | ApiError>,
) => {
  try {
    const response = await apiCall;
    return response as K;
  } catch (e) {
    throw formatError(e as ApiError);
  }
};

// This should not be used anymore and should be replaced with
// handleQueryRequest
export const toUseQueryResponse = async <T, K = Exclude<T, ErrorV1>>(
  apiCall: Promise<K | ErrorV1 | ApiError>,
) => {
  try {
    const response = await apiCall;
    return response as K;
  } catch (e) {
    const apiError = getApiError(e);

    if (apiError) {
      throw apiError;
    }

    // eslint-disable-next-line no-throw-literal
    throw e as ApiError;
  }
};

// This should not be used anymore and should be replaced with
// handleApiRequest
export const unwrapApiResponse = async <T, K = Exclude<T, ErrorV1>>(
  apiCall: Promise<K | ErrorV1 | ApiError>,
) => {
  try {
    const response = await apiCall;
    return response as K;
  } catch (e) {
    const apiError = getApiError(e);

    if (apiError) {
      return apiError;
    }
    return e as ApiError;
  }
};
