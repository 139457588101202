import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
} from 'recharts';
import { Card } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import CustomTooltip from './CustomTooltip';

export interface Props {
  data: { name: string; value: number }[];
}

const axisStyle = {
  fontFamily: 'Calibre',
  fontWeight: 600,
  color: '#5D6F7A',
};

const ValueChart = ({ data }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const yAxisLabel = t('activity.chart.yaxis.label');

  return (
    <Card
      data-testid="value-chart"
      className="activity-page-cards__value-chart"
      elevation={50}
    >
      <ResponsiveContainer width={1080} height="100%">
        <BarChart
          data={data}
          barSize={40}
          margin={{
            top: 40,
            right: 20,
            left: 5,
            bottom: 5,
          }}
        >
          <XAxis
            className="value-chart-axis"
            style={axisStyle}
            dataKey="name"
            tickLine={false}
            dy={5}
          />
          <YAxis
            style={axisStyle}
            tickLine={false}
            className="value-chart-axis"
          >
            <Label
              value={yAxisLabel}
              angle={-90}
              position="insideLeft"
              style={axisStyle}
            />
          </YAxis>
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar
            background={false}
            dataKey="value"
            fill="#34AF9E"
            radius={[2, 2, 0, 0]}
            activeBar={{
              stroke: '#0D443C',
              strokeWidth: 4,
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default ValueChart;
