import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import initializeLogging from '@utils/initializeLogging';
import client from '@inventory/services/graphql';
import queryClient from '@services/queryClient';
import ErrorBoundary from '@views/ErrorBoundary';
import store from '@state/store';
import App from './App';
import './i18n';
import './index.scss';

initializeLogging();

ReactDOM.render(
  <Suspense fallback="Loading...">
    <ErrorBoundary>
      <Router>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <QueryClientProvider contextSharing client={queryClient}>
              <App />
              <ReactQueryDevtools position="bottom-right" />
            </QueryClientProvider>
          </ApolloProvider>
        </Provider>
      </Router>
    </ErrorBoundary>
  </Suspense>,
  document.getElementById('root'),
);
