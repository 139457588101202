import React from 'react';

interface Props
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  fullWidth?: boolean;
}

const CommonFormSectionMain = ({
  children,
  className,
  fullWidth,
  ...props
}: Props) => {
  return (
    <div className={`common-form-section__column ${className}`} {...props}>
      {fullWidth ? (
        children
      ) : (
        <div className="common-form-section__column-inner">{children}</div>
      )}
    </div>
  );
};

CommonFormSectionMain.defaultProps = {
  className: '',
  fullWidth: false,
} as Partial<Props>;

export default CommonFormSectionMain;
