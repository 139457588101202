const requiredConfigurationOptionsValues = [
  'LOGGING',
  'TEAMS_UI_VERSION',
] as const;

type RequiredConfig = Record<
  typeof requiredConfigurationOptionsValues[number],
  string
>;

type AppConfig = RequiredConfig;

interface LoadingConfig {
  config: RequiredConfig;
  errors: string[];
}

const getConfig = (): AppConfig => {
  const { config, errors } = requiredConfigurationOptionsValues.reduce(
    (prev, current) => {
      const value = window.appConfig[current];
      if (!value) {
        return {
          ...prev,
          errors: [...prev.errors, current],
        };
      }

      return {
        ...prev,
        config: { ...prev.config, [current]: value },
      };
    },
    { config: {}, errors: [] as string[] } as LoadingConfig,
  );

  if (errors.length > 0) {
    throw Error(`Required ${errors.join(', ')} must be defined in appConfig`);
  }

  return config;
};

const config = getConfig();

export default config;
