import React from 'react';
import { Loading, Link } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useListIntegrationsV1 } from '@services/cd4pe/vcs';
import { Trans, useTranslation } from 'react-i18next';
import Cd4peError from '@components/Cd4peError';
import StatusBar from '@components/StatusBar/StatusBar';
import { getRepoHost, getCommitLink } from '@codeDelivery/utils/vcsLinkHelper';
import { format } from 'date-fns';
import { JobInstanceResponseV1 } from '@puppet/cd4pe-client-ts';
import { LINKS } from 'src/routes';

type Props = {
  jobInstance: JobInstanceResponseV1;
};

const JobDetailsStatusBar = ({ jobInstance }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();

  const {
    data: vcsIntegrations,
    isLoading: vcsIntegrationsLoading,
    error: vcsIntegrationsError,
  } = useListIntegrationsV1({ workspaceId });

  const getVCSHostURLs = () => {
    const hostUrls = new Map<string, string>();
    if (vcsIntegrations?.vcsIntegrations) {
      vcsIntegrations.vcsIntegrations.forEach(
        ({ provider, connected, host }) => {
          if (connected) {
            hostUrls.set(provider, host!);
          }
        },
      );
    }

    return hostUrls;
  };

  const getTriggerComponent = () => {
    let triggerComponent = <div />;

    if (jobInstance) {
      const initiator =
        jobInstance.eventInitiator ||
        t('jobDetails.status.trigger.unknown.label');
      const triggerType = jobInstance.eventTriggerType;
      if (triggerType && triggerType.toLowerCase().includes('manual')) {
        triggerComponent = (
          <div className="job-details__status-bar-link">
            {initiator !== t('jobDetails.status.trigger.unknown.label') ? (
              <Trans
                t={t}
                i18nKey="jobDetails.status.trigger.manual.label"
                components={[
                  <Link
                    as="a"
                    target="_blank"
                    rel="noreferrer"
                    href={LINKS.username.profile({
                      path: { username: initiator! },
                    })}
                  />,
                ]}
                values={{ initiator }}
              />
            ) : (
              initiator
            )}
          </div>
        );
      } else {
        triggerComponent = (
          <div>
            {t('jobDetails.status.trigger.pr.label', {
              username: initiator,
            })}
          </div>
        );
      }
    }

    return triggerComponent;
  };

  const getCommitUrl = () => {
    let url = null;

    const vcsHostUrls = getVCSHostURLs();
    if (jobInstance) {
      const repoHost = getRepoHost(jobInstance.repoProvider!, vcsHostUrls);
      if (repoHost) {
        url = getCommitLink(
          jobInstance.repoProvider!,
          jobInstance.repoOwner!,
          jobInstance.repoName!,
          jobInstance.repoId!,
          repoHost!,
          jobInstance.commitId,
        );
      }
    }
    return url;
  };

  const renderStatusBar = () => {
    if (vcsIntegrationsLoading || !vcsIntegrations || !jobInstance) {
      return <Loading />;
    }

    const commitURL = getCommitUrl();
    const triggerComponent = getTriggerComponent();

    return (
      <StatusBar>
        <StatusBar.Section>
          <StatusBar.Section.Header>
            {t('jobDetails.status.header.name.label')}
          </StatusBar.Section.Header>
          <StatusBar.Section.Detail>
            {jobInstance.name}
          </StatusBar.Section.Detail>
        </StatusBar.Section>
        <StatusBar.Section>
          <StatusBar.Section.Header>
            {t('jobDetails.status.header.time.label')}
          </StatusBar.Section.Header>
          <StatusBar.Section.Detail>
            {format(new Date(jobInstance.created), 'yyyy-MM-dd HH:mm:ss')}
          </StatusBar.Section.Detail>
        </StatusBar.Section>
        <StatusBar.Section>
          <StatusBar.Section.Header>
            {t('jobDetails.status.header.branch.label')}
          </StatusBar.Section.Header>
          <StatusBar.Section.Detail>
            <div className="job-details__status-bar-link">
              <Trans
                t={t}
                i18nKey="jobDetails.status.branch.link.label"
                components={[
                  <Link
                    as="a"
                    target="_blank"
                    rel="noreferrer"
                    href={commitURL}
                  />,
                ]}
                values={{
                  repoBranch: jobInstance.repoBranch,
                  commitId: jobInstance.commitId,
                }}
              />
            </div>
          </StatusBar.Section.Detail>
        </StatusBar.Section>
        <StatusBar.Section>
          <StatusBar.Section.Header>
            {t('jobDetails.status.header.image.label')}
          </StatusBar.Section.Header>
          <StatusBar.Section.Detail>
            {jobInstance.vmImage ? jobInstance.vmImage : '-'}
          </StatusBar.Section.Detail>
        </StatusBar.Section>
        <StatusBar.Section>
          <StatusBar.Section.Header>
            {t('jobDetails.status.header.trigger.label')}
          </StatusBar.Section.Header>
          <StatusBar.Section.Detail>
            {triggerComponent}
          </StatusBar.Section.Detail>
        </StatusBar.Section>
      </StatusBar>
    );
  };

  if (vcsIntegrationsError) {
    return <Cd4peError error={vcsIntegrationsError} />;
  }

  return renderStatusBar();
};

export default JobDetailsStatusBar;
