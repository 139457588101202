import ConditionalRender from '@components/ConditionalRender';
import {
  DeploymentEventDetailsV1,
  JobEventDetailsV1,
  TaskStateV1,
} from '@puppet/cd4pe-client-ts';
import { Heading, Text, Link as PdsLink, Icon } from '@puppet/react-components';
import { FAILED_STATES, IN_PROGRESS_STATES } from '@utils/constants';
import { formatDistance } from 'date-fns';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

type TEventDetails = {
  description: string;
  eventId: string;
  status: TaskStateV1 | null;
  branch: string;
  commitId: string;
  startTime: number;
  user: string;
};

interface Props {
  details: TEventDetails;
  title: string;
  eventLink: string;
  branchLink: string;
  commitLink: string;
  deployment?: { targetBranch: string; link: string };
  'data-testid'?: string;
  href?: boolean;
}

export const eventDetailsFromJob = (
  jobDetails: Exclude<JobEventDetailsV1, null>,
): TEventDetails => {
  return {
    description: jobDetails.jobDescription,
    eventId: jobDetails.vmJobInstanceId.toString(),
    status: jobDetails.jobStatus,
    branch: jobDetails.branch,
    commitId: jobDetails.commitId,
    startTime: jobDetails.jobStartTime,
    user: jobDetails.authorUsername,
  };
};

export const eventDetailsFromDeployment = (
  deployment: Exclude<DeploymentEventDetailsV1, null>,
): TEventDetails => {
  return {
    description: deployment.deploymentDescription,
    eventId: deployment.deploymentId.toString(),
    status: deployment.deploymentState,
    branch: deployment.sourceBranch,
    commitId: deployment.commitId,
    startTime: deployment.deploymentStartTime,
    user: deployment.authorUsername,
  };
};

const getStatusClass = (status: TaskStateV1 | null) => {
  if (status) {
    if (FAILED_STATES.includes(status)) {
      return 'listCodeProjects-latest__status--failed';
    }

    if (status === 'DONE') {
      return 'listCodeProjects-latest__status--success';
    }

    if (IN_PROGRESS_STATES.includes(status)) {
      return 'listCodeProjects-latest__status--running';
    }
  }

  return 'listCodeProjects-latest__status--neutral';
};

const EventDetails = ({
  details,
  title,
  eventLink,
  branchLink,
  commitLink,
  deployment,
  'data-testid': dataTestId,
  href = false,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const linkProps = href
    ? { as: 'a', href: eventLink }
    : { as: NavLink, to: eventLink };
  return (
    <div
      data-testid={dataTestId}
      className="listCodeProjects-eventDetails__container"
    >
      <Heading as="h5">{title}</Heading>
      <Text>{details.description}</Text>
      <Trans
        parent={Text}
        t={t}
        i18nKey="eventDetails.details"
        values={{
          id: details.eventId,
          status: t(
            `eventDetails.status.${details.status?.toLowerCase() ?? 'unknown'}`,
          ),
          branch: details.branch,
          commitId: `${details.commitId.slice(0, 10)}...`,
          time: formatDistance(new Date(details.startTime), new Date(), {
            addSuffix: true,
          }),
          user: details.user,
        }}
        components={[
          <PdsLink {...linkProps} />,
          <span className={getStatusClass(details.status)} />,
          <PdsLink as="a" href={branchLink} target="_blank" />,
          <PdsLink as="a" href={commitLink} target="_blank" />,
          <ConditionalRender enable={!!deployment}>
            <Icon
              type="double-right"
              className="listCodeProjects-eventDetails-deploy__chevron"
              size="medium"
            />
            <PdsLink as="a" href={deployment?.link}>
              {deployment?.targetBranch}
            </PdsLink>
          </ConditionalRender>,
        ]}
      />
    </div>
  );
};

export default EventDetails;
