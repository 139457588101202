import React from 'react';
import { Text, Checkbox, RadioButton } from '@puppet/react-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  SUBSETS_SUPPORTED_CATEGORIES,
  SUBSETS_SUPPORTED_ACTIONS,
} from '../utils/constants';
import { getActionState, PrivilegeFormAction } from '../utils/utils';

type PrivilegeActionProps = {
  editable: boolean;
  action: PrivilegeFormAction;
  categoryName: string;
  subsetsAvailable: boolean;
  setAction: (action: PrivilegeFormAction) => void;
};

const PrivilegeAction = ({
  editable,
  action,
  categoryName,
  subsetsAvailable,
  setAction,
}: PrivilegeActionProps) => {
  const { type: actionName, selected, usesSubsets } = action;
  const { t } = useTranslation('codeDelivery');
  const privilegeKey = `${categoryName.toLowerCase()}.${actionName.toLowerCase()}`;
  const label = t(`privilege.${privilegeKey}.label`);
  const actionStateLabel = getActionState(categoryName, action);

  if (!editable) {
    return (
      <div
        key={actionName}
        className="privilege-category-action__container"
        data-testid={privilegeKey}
        data-state={actionStateLabel}
      >
        <Text size="small">
          <Trans
            t={t}
            i18nKey="privilege.action.state.readonly"
            values={{
              label,
              state: t(`privilege.action.state.${actionStateLabel}`),
            }}
            components={[<span className="privilege-category-action__state" />]}
          />
        </Text>
      </div>
    );
  }

  const onCheckboxChange = () => {
    setAction({ ...action, selected: !selected });
  };
  const onRadioChange = (enabled: boolean) => {
    setAction({ ...action, usesSubsets: enabled });
  };

  const getSubsetRadioButtons = () => (
    <>
      <RadioButton
        className="privilege-category-action-subset__option--all"
        name={`${privilegeKey}-all`}
        data-testid={`${privilegeKey}-all`}
        value={!usesSubsets}
        label={t('privilege.subset.all.label')}
        onChange={() => onRadioChange(false)}
      />
      <RadioButton
        className="privilege-category-action-subset__option--subset"
        name={`${privilegeKey}-subset`}
        data-testid={`${privilegeKey}-subset`}
        value={usesSubsets}
        label={t('privilege.subset.subset.label')}
        onChange={() => onRadioChange(true)}
        disabled={subsetsAvailable}
      />
    </>
  );

  return (
    <div className="privilege-category-action__container">
      <div className="privilege-category-action__action">
        <Checkbox
          value={selected}
          onChange={onCheckboxChange}
          label={label}
          name={privilegeKey}
        />
      </div>
      {selected && (
        <div className="privilege-category-action-subset__container">
          {SUBSETS_SUPPORTED_CATEGORIES.includes(categoryName) &&
          SUBSETS_SUPPORTED_ACTIONS.includes(actionName) ? (
            getSubsetRadioButtons()
          ) : (
            <Text size="small" color="subtle">
              {t(`privilege.action.state.title.${actionStateLabel}`)}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

export default PrivilegeAction;
