import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonSelect,
  Heading,
  Input,
  Text,
} from '@puppet/react-components';
import {
  CodeProjectDetailsV1,
  ProjectTypeV1,
  TriggerEventV1,
} from '@puppet/cd4pe-client-ts';
import Table, { DataItem } from '@components/Table';
import Cd4peError from '@components/Cd4peError';
import CursorPagination from '@codeDelivery/components/CursorPagination';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import {
  LIST_TRIGGER_EVENTS_QUERY_TAG,
  useListTriggerEventsV1,
} from '@services/cd4pe/events';
import { useQueryClient } from '@tanstack/react-query';
import { PIPELINE_BRANCH_OPTIONS } from '../../utils';
import EventDetails from './components/EventDetails';
import PipelineEvent from './components/PipelineEvent';
import WebhookDialog from '../WebhookDialog';

type RowData = DataItem & TriggerEventV1;

interface Props {
  name: string;
  codeProject: CodeProjectDetailsV1;
  projectType: ProjectTypeV1;
}

const POLLING_INTERVAL = 10000;

const EventsTable = ({ name, codeProject, projectType }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const domain = useWorkspaceDomain();
  const [searchParams, setSearchParams] = useSearchParams();
  const pipelineId =
    searchParams.get('pipelineId') ?? PIPELINE_BRANCH_OPTIONS.ALL_FILTER;
  const eventId = searchParams.get('eventId');
  const [currentPage, setCurrentPage] = useState(0);
  const [webhookEvent, setWebhookEvent] = useState<TriggerEventV1 | null>(null);
  const [hasNewEvents, setHasNewEvents] = useState(false);
  const listTriggerEvents = useListTriggerEventsV1({
    workspaceId: domain,
    [projectType === 'CONTROL_REPO' ? 'controlRepoName' : 'moduleName']: name,
    pipelineId:
      pipelineId !== PIPELINE_BRANCH_OPTIONS.ALL_FILTER
        ? pipelineId
        : undefined,
    eventId: Number(eventId) || undefined,
  });

  const options = [
    {
      value: PIPELINE_BRANCH_OPTIONS.ALL_FILTER,
      label: t('viewPipeline.events.filter.all'),
    },
    ...(codeProject?.pipelines?.map((pipeline) => ({
      value: pipeline.pipelineId,
      label: pipeline.name,
    })) ?? []),
    {
      value: PIPELINE_BRANCH_OPTIONS.MANUAL_ACTION,
      label: 'Manual action',
    },
  ];

  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [LIST_TRIGGER_EVENTS_QUERY_TAG, domain, name, pipelineId, eventId],
    [domain, name, pipelineId, eventId],
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const lastEventId =
        listTriggerEvents.data?.pages[0].triggerEvents?.[0]?.id;

      listTriggerEvents
        .refetch({
          refetchPage: (_, index) => index === 0,
        })
        .then(({ data }) => {
          const newLastEventId = data?.pages[0].triggerEvents?.[0]?.id;
          if (newLastEventId !== lastEventId) {
            setHasNewEvents(true);
          }
        });
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentPage, listTriggerEvents, queryClient, queryKey]);

  const handleNewEvents = () => {
    listTriggerEvents.refetch().then(() => {
      setHasNewEvents(false);
      setCurrentPage(0);
    });
  };

  return (
    <>
      <Cd4peError error={listTriggerEvents.error} />
      <div className="view-pipeline__events-header">
        <Heading as="h4">{t('viewPipeline.events.table.header')}</Heading>
        <ButtonSelect
          type="secondary"
          options={options}
          value={pipelineId}
          onChange={(pipeline: string) => {
            setCurrentPage(0);
            setSearchParams({
              pipelineId: pipeline,
              eventId:
                pipelineId === PIPELINE_BRANCH_OPTIONS.ALL_FILTER
                  ? ''
                  : eventId ?? '',
            });
          }}
        />
        {pipelineId !== PIPELINE_BRANCH_OPTIONS.ALL_FILTER && (
          <>
            <Heading as="h6">{t('viewPipeline.events.table.eventId')}</Heading>
            <Input
              name="eventId"
              className="view-pipeline__event-number"
              type="number"
              value={eventId ? Number(eventId) : ''}
              onChange={(value: string) => {
                setCurrentPage(0);
                setSearchParams({
                  pipelineId: pipelineId || '',
                  eventId: value || '',
                });
              }}
              min="0"
            />
          </>
        )}
        {hasNewEvents && currentPage !== 0 && (
          <Button
            icon="refresh"
            onClick={handleNewEvents}
            className="view-pipeline__events-new-event-alert"
          >
            {t('viewPipeline.events.table.newEvent')}
          </Button>
        )}
      </div>
      <Table
        data={
          listTriggerEvents?.data?.pages?.[currentPage]?.triggerEvents ?? []
        }
        loading={listTriggerEvents.isLoading}
        rowKey="id"
        columns={[
          {
            label: t('viewPipeline.events.table.column.id'),
            dataKey: 'id',
            className: 'event-id-column',
            cellRenderer: (row: { rowData: RowData }) => (
              <Text size="small"># {row.rowData.id}</Text>
            ),
          },
          {
            label: t('viewPipeline.events.table.column.name'),
            dataKey: 'name',
            className: 'event-name-column',
            cellRenderer: (row: { rowData: RowData }) => (
              <EventDetails codeProject={codeProject} event={row.rowData} />
            ),
          },
          {
            label: t('viewPipeline.events.table.column.status'),
            dataKey: 'status',
            cellRenderer: (row: { rowData: RowData }) => (
              <PipelineEvent
                event={row.rowData}
                onClickWebhookEvent={(event) => setWebhookEvent(event)}
              />
            ),
          },
        ]}
        emptyStateHeader={
          listTriggerEvents.isLoading
            ? ''
            : t('viewPipeline.events.table.emptyState.header')
        }
        emptyStateMessage=""
      />
      <CursorPagination
        currentPage={currentPage}
        pageSelect={(newPage: number) => {
          if (newPage < 0) {
            setCurrentPage(0);
            return;
          }

          if (newPage >= (listTriggerEvents?.data?.pages?.length ?? 0)) {
            if (
              !listTriggerEvents.isFetchingNextPage &&
              listTriggerEvents.hasNextPage
            ) {
              listTriggerEvents.fetchNextPage();
            }
            setCurrentPage(listTriggerEvents?.data?.pages?.length ?? 0);
            return;
          }

          setCurrentPage(newPage);
        }}
        hasNextPage={
          listTriggerEvents.hasNextPage ||
          !!listTriggerEvents?.data?.pages?.[currentPage + 1]
        }
        hasPreviousPage={currentPage > 0}
      />
      {webhookEvent !== null && (
        <WebhookDialog
          event={webhookEvent}
          projectName={name}
          projectType={projectType}
          onClose={() => setWebhookEvent(null)}
        />
      )}
    </>
  );
};

export default EventsTable;
