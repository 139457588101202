import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Heading, Form, Text, Link } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import {
  useListEnvironmentsV2,
  useListPeIntegrationsV2,
} from '@services/cd4pe/peIntegrations';
import { useListBranchesV1, useListCommitsV1 } from '@services/cd4pe/vcs';
import {
  CodeProjectDetailsV1,
  PEIntegrationEnvironmentV2,
  PEIntegrationV2,
  ProjectPipelineStageV1,
  ProjectTypeV1,
} from '@puppet/cd4pe-client-ts';
import Cd4peError from '@components/Cd4peError';
import docsLinks from '@codeDelivery/utils/docsLinks';
import { checkDeploymentExists } from '@codeDelivery/components/ViewPipeline/utils';
import useListControlReposV1 from '@services/cd4pe/controlRepos';
import useDebounce from '@hooks/useDebounce';
import {
  formatBranchesForSelect,
  formatCommitsForSelect,
  formatControlReposForSelect,
  formatEnvironmentsForSelect,
  formatPEIntegrationsForSelect,
} from '../../formHelpers';

export type ImpactAnalysisFormProps = {
  branch: string;
  commit: string;
  puppetEnterpriseServer: string;
  environment: string;
  nodes: number;
  allStageEnvironments: boolean;
  stageEnvironments: Record<string, boolean>;
  stageEnvironmentsControlRepos: Record<string, string>;
  controlRepo?: string;
};

const defaultProps = {
  submittable: true,
  cancellable: true,
};

export type ImpactAnalysis = {
  branch?: string;
  commit?: string;
  puppetEnterpriseServer?: PEIntegrationV2 | undefined;
  environment?: PEIntegrationEnvironmentV2 | undefined;
  nodes: number;
  allStageEnvironments?: boolean;
  stageEnvironments?: string[];
  stageEnvironmentsControlRepos?: Record<string, string>;
  controlRepo?: string;
};

interface Props {
  type: 'manual' | 'stage';
  projectType: ProjectTypeV1;
  stages?: ProjectPipelineStageV1[];
  initialValues?: ImpactAnalysisFormProps;
  codeProject: CodeProjectDetailsV1;
  disabled?: boolean;
  submittable?: boolean;
  cancellable?: boolean;
  onSubmit: (values: ImpactAnalysis) => void;
  onCancel: () => void;
  submitting: boolean;
  submitKey?: string;
  cancelKey?: string;
}

const ImpactAnalysisForm = ({
  type,
  projectType,
  stages = [],
  initialValues,
  codeProject,
  disabled,
  submittable,
  cancellable,
  onSubmit,
  onCancel,
  submitting,
  submitKey,
  cancelKey,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const [NoEnvSelected, setNoEnvSelected] = useState(false);

  const [formValues, setFormValues] = useState<ImpactAnalysisFormProps>(
    initialValues || {
      branch: '',
      commit: '',
      puppetEnterpriseServer: '',
      environment: '',
      nodes: 10,
      allStageEnvironments: projectType === 'CONTROL_REPO',
      stageEnvironments: {},
      stageEnvironmentsControlRepos: {},
      controlRepo: '',
    },
  );

  const vcsBranches = useListBranchesV1(
    {
      workspaceId,
      provider: codeProject.srcRepoProvider,
      name: codeProject.srcRepoDisplayName,
      organization: codeProject.srcRepoOwner,
      project: codeProject.srcRepoId,
    },
    {
      enabled: type === 'manual',
    },
  );
  const branchOptions = formatBranchesForSelect(vcsBranches);

  const vcsCommits = useListCommitsV1(
    {
      workspaceId,
      provider: codeProject.srcRepoProvider,
      name: codeProject.srcRepoDisplayName,
      organization: codeProject.srcRepoOwner,
      project: codeProject.srcRepoId,
      branch: formValues.branch,
    },
    {
      enabled: type === 'manual' && formValues.branch !== '',
    },
  );
  const commitOptions = formatCommitsForSelect(
    vcsCommits,
    codeProject,
    formValues.branch,
  );

  const peIntegrations = useListPeIntegrationsV2(
    { workspaceId },
    {
      enabled: type === 'manual',
    },
  );
  const peIntegrationsOptions = formatPEIntegrationsForSelect(peIntegrations);

  const environments = useListEnvironmentsV2(
    {
      peIntegrationId: formValues.puppetEnterpriseServer,
    },
    {
      enabled: type === 'manual' && formValues.puppetEnterpriseServer !== '',
    },
  );
  const environmentOptions = formatEnvironmentsForSelect(
    environments,
    codeProject,
  );

  const controlRepos = useListControlReposV1(
    {
      workspaceId,
      prefix: useDebounce<string>(formValues.controlRepo ?? ''),
    },
    {
      enabled: projectType === 'MODULE',
    },
  );
  const controlRepoOptions = formatControlReposForSelect(controlRepos);

  const controlRepoMatch = (repoName: string) =>
    controlRepos.data?.pages[0]?.controlRepos?.find(
      (repo) => repo.name === repoName,
    );

  const onChangeHandler = (
    nameChanged: keyof ImpactAnalysisFormProps,
    values: ImpactAnalysisFormProps,
  ) => {
    let newValuesCopy: ImpactAnalysisFormProps;

    if (NoEnvSelected) {
      setNoEnvSelected(false);
    }

    switch (nameChanged) {
      case 'branch':
        newValuesCopy = {
          ...values,
          commit: '',
          puppetEnterpriseServer: '',
          environment: '',
        };
        break;
      case 'commit':
        newValuesCopy = {
          ...values,
          puppetEnterpriseServer: '',
          environment: '',
        };
        break;
      case 'puppetEnterpriseServer':
        newValuesCopy = {
          ...values,
          environment: '',
        };
        break;
      default:
        newValuesCopy = values;
        break;
    }

    setFormValues(newValuesCopy);
  };

  const selectedEnvironments = Object.keys(formValues.stageEnvironments).filter(
    (key) => formValues.stageEnvironments[key],
  );
  const onSubmitHandler = (submitValues: ImpactAnalysisFormProps) => {
    if (
      (type === 'stage' &&
        !formValues.allStageEnvironments &&
        selectedEnvironments.length === 0) ||
      (type === 'stage' && !checkDeploymentExists(stages))
    ) {
      setNoEnvSelected(true);
      return;
    }

    let submitPayload: ImpactAnalysis = {
      nodes: submitValues.nodes,
    };

    const puppetEnterpriseServer = peIntegrations.data?.peIntegrations?.find(
      (peIntegration) =>
        peIntegration.id === submitValues.puppetEnterpriseServer,
    );

    const environment = environments?.data?.environments?.find(
      (group) => group.id === submitValues.environment,
    );

    if (type === 'stage') {
      const stageEnvironments: ImpactAnalysis['stageEnvironments'] = [];
      const stageEnvironmentsControlRepos: ImpactAnalysis['stageEnvironmentsControlRepos'] =
        {};

      Object.entries(submitValues.stageEnvironments).forEach(
        ([environmentName, environmentEnabled]) => {
          if (environmentEnabled) {
            stageEnvironments.push(environmentName);

            const controlRepoName =
              submitValues.stageEnvironmentsControlRepos[environmentName];
            if (controlRepoName) {
              stageEnvironmentsControlRepos[environmentName] = controlRepoName;
            }
          }
        },
      );

      submitPayload = {
        ...submitPayload,
        allStageEnvironments: submitValues.allStageEnvironments,
        stageEnvironments,
      };

      if (projectType === 'MODULE') {
        submitPayload = {
          ...submitPayload,
          stageEnvironmentsControlRepos,
          controlRepo: submitValues.controlRepo,
        };
      }

      onSubmit(submitPayload);
    } else {
      submitPayload = {
        ...submitPayload,
        branch: submitValues.branch,
        commit: submitValues.commit,
        puppetEnterpriseServer,
        environment,
      };

      if (projectType === 'MODULE') {
        submitPayload = {
          ...submitPayload,
          controlRepo: submitValues.controlRepo,
        };
      }

      onSubmit(submitPayload);
    }
  };

  const getEnvironmentFields = () => {
    if (stages.length === 0) {
      return null;
    }

    return stages
      .flatMap((value) => value.destinations)
      .filter((destination) => !!destination.deploymentTemplate)
      .map((destination) => (
        <div className="impact-analysis-form__environment-field">
          <Form.Field
            key={destination.id}
            type="checkbox"
            name={`stageEnvironments.${destination.id}`}
            path={`stageEnvironments.${destination.id}`}
            data-testid={`stageEnvironments.${destination.id}`}
            label={
              <div className="environment-checkbox-label">
                <div>
                  <Trans
                    parent="div"
                    t={t}
                    i18nKey="viewPipeline.form.impactAnalysis.field.stageEnvironment.policy"
                  >
                    <span className="environment-checkbox-label__value">
                      {{
                        policy:
                          destination.deploymentTemplate?.deploymentPolicy
                            ?.displayName,
                      }}
                    </span>
                  </Trans>
                  <Trans
                    parent="div"
                    t={t}
                    i18nKey="viewPipeline.form.impactAnalysis.field.stageEnvironment.instance"
                  >
                    <span className="environment-checkbox-label__value">
                      {{
                        instance:
                          destination.deploymentTemplate
                            ?.puppetEnterpriseCredentialsId?.name,
                      }}
                    </span>
                  </Trans>
                </div>
                <div>
                  <Trans
                    parent="div"
                    t={t}
                    i18nKey="viewPipeline.form.impactAnalysis.field.stageEnvironment.environment"
                  >
                    <span className="environment-checkbox-label__value">
                      {{
                        environment:
                          destination.deploymentTemplate?.deploymentTarget
                            ?.nodeGroupName,
                      }}
                    </span>
                  </Trans>
                  <Trans
                    parent="div"
                    t={t}
                    i18nKey="viewPipeline.form.impactAnalysis.field.stageEnvironment.stageNum"
                  >
                    <span className="environment-checkbox-label__value">
                      {{
                        stageNum: destination.stageNum,
                      }}
                    </span>
                  </Trans>
                </div>
              </div>
            }
            value={
              destination.deploymentTemplate?.deploymentTarget?.nodeGroupName
            }
          />
          {projectType === 'MODULE' && (
            <div
              data-testid={`stageEnvironmentsControlRepos.${destination.id}`}
            >
              <Form.Field
                key={`${destination.id}-controlRepo`}
                className="impact-analysis-form__environment-field-control-repo"
                name={`stageEnvironmentsControlRepos.${destination.id}`}
                path={`stageEnvironmentsControlRepos.${destination.id}`}
                type="select"
                options={controlRepoOptions}
                placeholder={t(
                  'viewPipeline.form.impactAnalysis.field.environment.controlRepo.placeholder',
                )}
                required={formValues.stageEnvironments[destination.id!]}
              />
            </div>
          )}
        </div>
      ));
  };

  const controlRepoValidator = (value: string) => {
    if (!controlRepoMatch(value)) {
      return t(
        'viewPipeline.form.impactAnalysis.field.codeDelivery.error.noMatch',
      );
    }

    return false;
  };

  return (
    <>
      <Form
        className="impact-analysis-form"
        initialValues={formValues}
        disabled={disabled}
        onChange={onChangeHandler}
        cancellable={cancellable}
        submittable={submittable}
        onSubmit={onSubmitHandler}
        onCancel={onCancel}
        submitting={submitting}
        submitLabel={submitKey ? t(submitKey) : undefined}
        cancelLabel={cancelKey ? t(cancelKey) : undefined}
        error={
          NoEnvSelected
            ? t('viewPipeline.form.impactAnalysis.error.noDeploymentSelected')
            : null
        }
        data-testid="ia-form"
      >
        {type === 'manual' && (
          <Form.Field
            name="branch"
            type="select"
            placeholder={
              vcsBranches.isLoading
                ? t('viewPipeline.form.impactAnalysis.field.branch.loading')
                : t('viewPipeline.form.impactAnalysis.field.branch.placeholder')
            }
            label={t('viewPipeline.form.impactAnalysis.field.branch.label')}
            options={branchOptions}
            disabled={vcsBranches.isLoading}
          />
        )}
        {type === 'manual' && formValues.branch !== '' && (
          <Form.Field
            name="commit"
            type="select"
            options={commitOptions}
            placeholder={
              vcsCommits.isLoading
                ? t('viewPipeline.form.impactAnalysis.field.commit.loading')
                : t('viewPipeline.form.impactAnalysis.field.commit.placeholder')
            }
            label={t('viewPipeline.form.impactAnalysis.field.commit.label')}
            className="commit-select"
            disabled={vcsCommits.isLoading}
            required
          />
        )}
        {type === 'manual' && formValues.commit !== '' && (
          <Form.Field
            name="puppetEnterpriseServer"
            type="select"
            options={peIntegrationsOptions}
            placeholder={
              peIntegrations.isLoading
                ? t(
                    'viewPipeline.form.impactAnalysis.field.puppetEnterpriseServer.loading',
                  )
                : t(
                    'viewPipeline.form.impactAnalysis.field.puppetEnterpriseServer.placeholder',
                  )
            }
            label={t(
              'viewPipeline.form.impactAnalysis.field.puppetEnterpriseServer.label',
            )}
            disabled={peIntegrations.isLoading}
            required
          />
        )}
        {type === 'manual' && formValues.puppetEnterpriseServer !== '' && (
          <Form.Field
            name="environment"
            type="select"
            options={environmentOptions}
            placeholder={
              environments.isLoading
                ? t(
                    'viewPipeline.form.impactAnalysis.field.environment.loading',
                  )
                : t(
                    'viewPipeline.form.impactAnalysis.field.environment.placeholder',
                  )
            }
            label={t(
              'viewPipeline.form.impactAnalysis.field.environment.label',
            )}
            disabled={environments.isLoading}
            required
          />
        )}
        {type === 'stage' && (
          <div className="impact-analysis-form__stage-impact-analysis">
            <Link as="a" href={docsLinks().impactAnalysis} target="_blank">
              {t('viewPipeline.form.impactAnalysis.stage.whatIsIA.heading')}
            </Link>
            <Text size="small">
              {t('viewPipeline.form.impactAnalysis.stage.whatIsIA.description')}
            </Text>
          </div>
        )}
        {(type === 'stage' || formValues.environment !== '') && (
          <Text className="impact-analysis-form__nodes" size="small">
            <Trans t={t} i18nKey="viewPipeline.form.impactAnalysis.field.nodes">
              <Form.Field
                className="impact-analysis-form__nodes-field"
                name="nodes"
                label=""
                type="number"
                data-testid="nodes"
                required
              />
            </Trans>
          </Text>
        )}
        {type === 'manual' &&
          projectType === 'MODULE' &&
          formValues.environment !== '' && (
            <div
              data-testid="ia-control-repo-select"
              className="impact-analysis-form__field"
            >
              <Form.Field
                name="controlRepo"
                type="autocomplete"
                options={controlRepoOptions}
                placeholder={t(
                  'viewPipeline.form.impactAnalysis.field.codeDelivery.placeholder',
                )}
                label={t(
                  'viewPipeline.form.impactAnalysis.field.codeDelivery.label',
                )}
                validator={controlRepoValidator}
                footer={
                  controlRepos.hasNextPage
                    ? t(
                        'viewPipeline.form.impactAnalysis.field.codeDelivery.footer',
                      )
                    : null
                }
                required
              />
            </div>
          )}
        {type === 'stage' && (
          <>
            <Heading
              className="impact-analysis-form__analyze-environments-heading"
              as="h6"
            >
              {t(
                'viewPipeline.form.impactAnalysis.field.stageEnvironment.label',
              )}
            </Heading>
            {projectType === 'CONTROL_REPO' && (
              <Form.Field
                type="switch"
                name="allStageEnvironments"
                label={t(
                  'viewPipeline.form.impactAnalysis.field.allStageEnvironments.label',
                )}
                data-testid="allStageEnvironments"
              />
            )}
          </>
        )}
        {type === 'stage' &&
          !formValues.allStageEnvironments &&
          getEnvironmentFields()}
      </Form>
      <Cd4peError error={vcsBranches.error} />
      <Cd4peError error={vcsCommits.error} />
      <Cd4peError error={peIntegrations.error} />
      <Cd4peError error={environments.error} />
    </>
  );
};

export default ImpactAnalysisForm;

ImpactAnalysisForm.defaultProps = defaultProps;
