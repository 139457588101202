import { useQuery } from '@tanstack/react-query';
import {
  Cd4peApiError,
  handleQueryRequest,
  QueryHook,
  ValueReportingV1Service,
} from '@utils/api/cd4pe';

export const GENERATE_VALUE_REPORT = 'generateValueReportV1';

/**
 * UseGenerateValueReportV1
 */
type UseGenerateValueReportV1 = QueryHook<
  typeof ValueReportingV1Service,
  'generateValueReportV1',
  Cd4peApiError
>;

export const useGenerateValueReportV1: UseGenerateValueReportV1 = (
  payload,
  options,
) => {
  return useQuery(
    [GENERATE_VALUE_REPORT, payload.startDate, payload.endDate],
    () =>
      handleQueryRequest(
        ValueReportingV1Service.generateValueReportV1(payload),
      ),
    options,
  );
};
