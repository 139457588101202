import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  Cd4peApiError,
  ModulesV1Service,
  handleQueryRequest,
  InfiniteQueryHook,
  MutationHook,
  QueryHook,
} from '@utils/api/cd4pe';
import { GET_IS_BUILD_PR_ALLOWED, GET_PIPELINE_QUERY_TAG } from './pipelines';
import { PREVIEW_PIPELINE_AS_CODE_TAG } from './pipelinesAsCode';

export const MODULES_LIST_QUERY_TAG = 'listModules';
export const MODULES_GET_QUERY_TAG = 'getModules';

type UseGetModuleV1 = QueryHook<
  typeof ModulesV1Service,
  'getModuleV1',
  Cd4peApiError
>;

export const useGetModuleV1: UseGetModuleV1 = (payload) =>
  useQuery(
    [
      MODULES_GET_QUERY_TAG,
      payload.workspaceId,
      payload.moduleName.toLowerCase(),
    ],
    () => {
      return handleQueryRequest(
        ModulesV1Service.getModuleV1({
          ...payload,
        }),
      );
    },
  );

/**
 * useListModulesV1
 */

type UseListModulesV1 = InfiniteQueryHook<
  typeof ModulesV1Service,
  'listModulesV1',
  Cd4peApiError
>;

export const useListModulesV1: UseListModulesV1 = (payload) =>
  useInfiniteQuery(
    [MODULES_LIST_QUERY_TAG, payload.workspaceId, payload.prefix],
    ({ pageParam }) =>
      handleQueryRequest(
        ModulesV1Service.listModulesV1({ ...payload, nextMarker: pageParam }),
      ),
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageDetails.nextMarker ?? undefined,
    },
  );

/**
 * useDeleteModuleV1
 */

type UseDeleteModuleV1 = MutationHook<
  typeof ModulesV1Service,
  'deleteModuleV1',
  Cd4peApiError
>;

export const useDeleteModuleV1: UseDeleteModuleV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => handleQueryRequest(ModulesV1Service.deleteModuleV1(payload)),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          MODULES_LIST_QUERY_TAG,
          payload.workspaceId,
        ]);

        queryClient.removeQueries([
          MODULES_GET_QUERY_TAG,
          payload.workspaceId,
          payload.moduleName,
        ]);

        queryClient.removeQueries([
          GET_PIPELINE_QUERY_TAG,
          payload.workspaceId,
          payload.moduleName,
        ]);

        queryClient.removeQueries([
          PREVIEW_PIPELINE_AS_CODE_TAG,
          payload.workspaceId,
          payload.moduleName,
        ]);

        queryClient.removeQueries([
          GET_IS_BUILD_PR_ALLOWED,
          payload.workspaceId,
          payload.moduleName,
        ]);
      },
    },
  );
};
