/**
 * Helper function for making sure array contains all string from string union
 * Example:
 * type Test = 'hello' | 'world';
 * const some = arrayOfAll<Test>()(['hello']) // this throws error
 * const all = arrayOfAll<Test>()(['hello', 'world']) // this is good
 */
const arrayOfAll =
  <T>() =>
  <U extends T[]>(array: U & ([T] extends [U[number]] ? unknown : 'Invalid')) =>
    array;

export default arrayOfAll;
