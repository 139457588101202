import { nanoid } from 'nanoid';
import { COMPOUND_FILTER_AND } from 'utils/constants';

export const puppetFilter = [
  {
    id: nanoid(10),
    union: COMPOUND_FILTER_AND,
    isEditing: false,
    isSaved: true,
    filters: [],
  },
];

export default puppetFilter;
