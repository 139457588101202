import { Dispatch } from 'react';
import actionCreator, { Actions } from '@utils/actionCreator';
import { SecretDetailsV1 } from '@utils/api/cd4pe';
import { CodeDeliveryActions } from '@codeDelivery/utils/actions';
import * as at from './actionTypes';

export type JobTemplateModal =
  | 'SECRET_FORM_MODAL'
  | 'VIEW_SECRET_MODAL'
  | 'DELETE_SECRET_MODAL'
  | null;

export const editJobTemplateActions = {
  toggleModal: (modal: JobTemplateModal) =>
    actionCreator(at.TOGGLE_MODAL, modal),
  toggleSelectedSecret: (secretDetails: SecretDetailsV1 | null) =>
    actionCreator(at.TOGGLE_SELECTED_SECRET, secretDetails),
};

export type EditJobTemplateActions =
  | Actions<typeof editJobTemplateActions>
  | CodeDeliveryActions;

export const viewSecretDetails = (
  secrets: SecretDetailsV1[],
  secretName: string | null,
  dispatch: Dispatch<EditJobTemplateActions>,
) => {
  const targetSecret = secrets.find((s) => s.name === secretName);

  if (!targetSecret) {
    return;
  }

  dispatch(editJobTemplateActions.toggleSelectedSecret(targetSecret));
  dispatch(editJobTemplateActions.toggleModal('VIEW_SECRET_MODAL'));
};

export const editSecretDetails = (
  secrets: SecretDetailsV1[],
  secretName: string | null,
  dispatch: Dispatch<EditJobTemplateActions>,
) => {
  const targetSecret = secrets.find((s) => s.name === secretName);

  if (!targetSecret) {
    return;
  }

  dispatch(editJobTemplateActions.toggleSelectedSecret(targetSecret));
  dispatch(editJobTemplateActions.toggleModal('SECRET_FORM_MODAL'));
};

export const deleteSecretDetails = (
  secrets: SecretDetailsV1[],
  secretName: string | null,
  dispatch: Dispatch<EditJobTemplateActions>,
) => {
  const targetSecret = secrets.find((s) => s.name === secretName);

  if (!targetSecret) {
    return;
  }

  dispatch(editJobTemplateActions.toggleSelectedSecret(targetSecret));
  dispatch(editJobTemplateActions.toggleModal('DELETE_SECRET_MODAL'));
};
