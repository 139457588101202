import React from 'react';
import { Input, Text } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number;
  label: string | null;
  onChange: null;
}

const CompileBatch = ({ value, label, onChange }: Props) => {
  const { t } = useTranslation('codeDelivery');
  return (
    <div className="compile-batch-field-inline">
      <Text className="compile-batch-field-text">
        {t('addPeIntegration.manualIntegrationForm.compileBatchSizePreText')}
      </Text>
      <Input
        data-testid="manual-integration-compile-batch-input"
        type="number"
        name={label}
        value={value}
        className="compile-batch-field"
        onChange={onChange}
        style={{
          fill: '#8C6718',
          margin: '0px 0px 0px 0px',
          textAlign: 'center',
        }}
      />
      <Text className="compile-batch-field-text">
        {t('addPeIntegration.manualIntegrationForm.compileBatchSizePostText')}
      </Text>
    </div>
  );
};

export default CompileBatch;

CompileBatch.defaultProps = {
  value: 0,
  error: null,
  type: null,
} as Partial<Props>;
