import {
  Cd4peApiError,
  CreatePEIntegrationRequestV2,
  DiscoverPEIntegrationRequestV2,
  handleApiRequest,
  isCd4peApiError,
  PeIntegrationsV2Service,
  PEIntegrationV2,
} from '@utils/api/cd4pe';
import actionCreator, { Actions } from '@utils/actionCreator';
import { AutoIntegrationFormActions } from '@codeDelivery/components/AutoIntegrationForm';
import { Dispatch } from 'react';
import { ManualIntegrationFormActions } from './components/ManualIntegrationForm';
import {
  iaConfigFormActions,
  IaConfigFormActions,
} from './components/IaConfigForm';
import * as at from './actionTypes';

export type FormType = 'auto' | 'manual' | 'ia';
export type AuthType = 'basic' | 'token';
export type TokenUnits = 'months' | 'years';

export const addPeIntegrationActions = {
  formType: (formType: FormType) => actionCreator(at.FORM_TYPE, formType),
  authType: (authType: AuthType) => actionCreator(at.AUTH_TYPE, authType),
  editingTokenLifetime: (editingTokenLifetime: boolean) =>
    actionCreator(at.EDITING_TOKEN_LIFETIME, editingTokenLifetime),
  tokenUnits: (tokenUnits: TokenUnits) =>
    actionCreator(at.TOKEN_UNITS, tokenUnits),
  iaConfigModal: (showModal: boolean) =>
    actionCreator(at.IA_CONFIG_MODAL, showModal),
  discoverIntegrationStart: () => actionCreator(at.DISCOVER_INTEGRATION_START),
  discoverIntegrationComplete: (payload: PEIntegrationV2) =>
    actionCreator(at.DISCOVER_INTEGRATION_COMPLETE, payload),
  discoverIntegrationError: (error: Cd4peApiError) =>
    actionCreator(at.DISCOVER_INTEGRATION_ERROR, error),
  createIntegrationStart: () => actionCreator(at.CREATE_INTEGRATION_START),
  createIntegrationComplete: (payload: PEIntegrationV2) =>
    actionCreator(at.CREATE_INTEGRATION_COMPLETE, payload),
  createIntegrationError: (error: Cd4peApiError) =>
    actionCreator(at.CREATE_INTEGRATION_ERROR, error),
  configureIaStart: () => actionCreator(at.CONFIGURE_IA_START),
  configureIaError: (error: Cd4peApiError) =>
    actionCreator(at.CONFIGURE_IA_ERROR, error),
  configureIaComplete: (payload: PEIntegrationV2) =>
    actionCreator(at.CONFIGURE_IA_COMPLETE, payload),
};

export type AddPeIntegrationActions =
  | Actions<typeof addPeIntegrationActions>
  | AutoIntegrationFormActions
  | ManualIntegrationFormActions
  | IaConfigFormActions;

export const discoverPeIntegration = async (
  dispatch: Dispatch<AddPeIntegrationActions>,
  integrationPayload: DiscoverPEIntegrationRequestV2,
) => {
  const integrationResponse = await handleApiRequest(
    PeIntegrationsV2Service.discoverPeIntegrationV2({
      requestBody: integrationPayload,
    }),
  );

  if (isCd4peApiError(integrationResponse)) {
    return dispatch(
      addPeIntegrationActions.discoverIntegrationError(integrationResponse),
    );
  }

  if (!integrationResponse.impactAnalysisConfigured) {
    if (integrationResponse.puppetServerUrl) {
      dispatch(
        iaConfigFormActions.puppetServer(integrationResponse.puppetServerUrl),
      );
    }
    dispatch(addPeIntegrationActions.iaConfigModal(true));
  }

  return dispatch(
    addPeIntegrationActions.discoverIntegrationComplete(integrationResponse),
  );
};

export const createPeIntegration = async (
  dispatch: Dispatch<AddPeIntegrationActions>,
  addPayload: CreatePEIntegrationRequestV2,
) => {
  const integrationResponse = await handleApiRequest(
    PeIntegrationsV2Service.createPeIntegrationV2({ requestBody: addPayload }),
  );

  if (isCd4peApiError(integrationResponse)) {
    dispatch(
      addPeIntegrationActions.createIntegrationError(integrationResponse),
    );
    return;
  }

  if (!integrationResponse.impactAnalysisConfigured) {
    dispatch(addPeIntegrationActions.iaConfigModal(true));
  }

  dispatch(
    addPeIntegrationActions.createIntegrationComplete(integrationResponse),
  );
};

export const configureIa = async (
  dispatch: Dispatch<AddPeIntegrationActions>,
  integrationId: string,
  puppetServer: string,
  iaCert: string,
  iaKey: string,
) => {
  const iaConfigResponse = await handleApiRequest(
    PeIntegrationsV2Service.updatePeIntegrationV2({
      peIntegrationId: integrationId,
      requestBody: {
        puppetServerUrl: puppetServer,
        legacyPuppetServerCreds: { certificate: iaCert, privateKey: iaKey },
      },
    }),
  );

  if (isCd4peApiError(iaConfigResponse)) {
    dispatch(addPeIntegrationActions.configureIaError(iaConfigResponse));
    return;
  }

  dispatch(addPeIntegrationActions.configureIaComplete(iaConfigResponse));
};
