import React from 'react';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { CodeProjectDetailsV1 } from '@puppet/cd4pe-client-ts';
import { useTranslation } from 'react-i18next';
import { useListModulesV1 } from '@services/cd4pe/modules';
import Cd4peError from '@components/Cd4peError';
import useDebouncedSearch from '@hooks/useDebouncedSearch';
import PrivilegeSubsetModal, { ModalProps } from './PrivilegeSubsetModal';

const getTableTranslations = (
  loading: boolean,
  data: CodeProjectDetailsV1[][],
  searchTerm: string,
  t: Function,
) => {
  if (loading) {
    return {
      header: '',
      message: '',
    };
  }

  if (data?.length === 0) {
    if (searchTerm !== '') {
      return {
        header: t('privilege.subset.permissions.table.empty.search.header'),
        message: t(
          'privilege.subset.permissions.modules.table.empty.search.message',
        ),
      };
    }

    return {
      header: t('privilege.subset.permissions.table.empty.header'),
      message: t('privilege.subset.permissions.modules.table.empty.message'),
    };
  }

  return {
    header: '',
    message: '',
  };
};

const ModulePrivilegesSubsetModal = ({
  setOpen,
  open,
  subsets,
  setSubsets,
}: ModalProps) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const { searchTerm, appliedSearchTerm, handleSearch } = useDebouncedSearch();

  const { isLoading, isFetching, data, error, fetchNextPage, hasNextPage } =
    useListModulesV1({
      workspaceId,
      pageSize: 10,
      prefix: appliedSearchTerm,
    });

  const modules = [...(data?.pages ?? [])].map((page) => page.modules);

  const tableTranslations = getTableTranslations(
    isLoading,
    modules,
    appliedSearchTerm,
    t,
  );

  if (!open) {
    return null;
  }

  return (
    <>
      <PrivilegeSubsetModal
        onClose={() => setOpen(false)}
        onSearchChange={handleSearch}
        searchSubsetTerm={searchTerm}
        loading={isLoading || isFetching}
        data={modules}
        setSelectedSubsets={setSubsets}
        initialSubsets={subsets}
        title={t('privilege.modules.label')}
        searchPlaceHolder={t('privilege.subset.permissions.modules.search')}
        tableTranslations={tableTranslations}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
      <Cd4peError error={error} />
    </>
  );
};

export default ModulePrivilegesSubsetModal;
