import ShellEditor from '@codeDelivery/components/ShellEditor';
import { Button } from '@puppet/react-components';
import React from 'react';

type JobDetailsCommandProps = {
  command?: string | null;
  name: string;
  label: string;
  active: boolean;
};

const JobDetailsCommand = ({
  command = '',
  name,
  label,
  active,
}: JobDetailsCommandProps) => {
  const [visible, setVisible] = React.useState(!!command);

  return (
    <div className="job-details-command__container">
      <Button
        className="job-details-command__button"
        type="text"
        trailingIcon={visible ? 'chevron-up' : 'chevron-down'}
        onClick={() => setVisible((v) => !v)}
      >
        {label}
      </Button>
      {visible && (
        <ShellEditor
          value={command ?? ''}
          name={name}
          onChange={() => {}}
          readOnly
          active={active && visible}
        />
      )}
    </div>
  );
};

export default JobDetailsCommand;
