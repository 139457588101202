import React, { useState } from 'react';
import {
  Alert,
  Button,
  Form,
  Heading,
  Modal,
  Text,
} from '@puppet/react-components';
import {
  CodeProjectDetailsV1,
  JobTemplateSummaryV1,
  PipelineDestinationTypeV1,
  ProjectPipelineGateTypeV1,
  ProjectPipelineStageV1,
  ProjectPipelineV1,
  ProjectTypeV1,
} from '@puppet/cd4pe-client-ts';
import { TFunction, useTranslation } from 'react-i18next';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import ConditionalRender from '@components/ConditionalRender';
import Cd4peError from '@components/Cd4peError';
import { useUpsertPipelineStagesV1 } from '@services/cd4pe/pipelines';
import DeploymentForm, {
  Deployment,
} from '@codeDelivery/components/ViewPipeline/components/DeploymentForm';
import JobForm from '@codeDelivery/components/ViewPipeline/components/JobForm';
import {
  addNewStage,
  addNewStageAfter,
  addNewStageBefore,
  addNewStageItem,
  AddNewStageParams,
  addPRGate,
  checkDeploymentExists,
  checkPullRequestExists,
} from '@codeDelivery/components/ViewPipeline/utils';
import ImpactAnalysisForm, {
  ImpactAnalysis,
} from '@codeDelivery/components/ViewPipeline/components/ImpactAnalysisForm';
import AddPRGateForm from '../AddPRGateForm';

export type AddStageType =
  | 'AddStage'
  | 'AddItem'
  | 'BeforeStage'
  | 'AfterStage';

interface Props {
  type: AddStageType;
  projectType: ProjectTypeV1;
  stageNumber?: number;
  pipeline: ProjectPipelineV1;
  codeProject: CodeProjectDetailsV1;
  onClose: () => void;
  onClickAddToStage: (stageNumber: number) => void;
  onClickAddNextStage: (stageNumber: number) => void;
}

type itemType = PipelineDestinationTypeV1 | ProjectPipelineGateTypeV1;

type FormType = {
  stageName: string;
  itemType: itemType;
};

type DestinationOptions = { label: string; value: FormType['itemType'] }[];

const getAddItemOptions = (
  stages: ProjectPipelineStageV1[],
  stageNumber: number,
  codeProject: CodeProjectDetailsV1,
  pipelineId: string,
  t: TFunction<'codeDelivery'>,
): DestinationOptions => {
  const selectedStage = stages[stageNumber - 1];
  const options: DestinationOptions = [];
  const initialTypes = {
    DEPLOYMENT: false,
    JOB: false,
    IMPACT_ANALYSIS: false,
    PULL_REQUEST:
      selectedStage.pipelineGate?.projectPipelineGateType === 'PULL_REQUEST',
  };

  const destinationTypesPresent = selectedStage.destinations?.reduce(
    (acc, dest) => ({
      ...acc,
      [dest.type]: true,
    }),
    initialTypes,
  );

  const isRegexPipeline =
    codeProject.pipelines.find((pipeline) => pipeline.pipelineId === pipelineId)
      ?.name === 'regex';

  if (!destinationTypesPresent.IMPACT_ANALYSIS) {
    options.push({
      label: t('viewPipeline.dialog.addStage.stageItemType.deployment'),
      value: 'DEPLOYMENT',
    });
  }

  options.push({
    label: t('viewPipeline.dialog.addStage.stageItemType.job'),
    value: 'JOB',
  });

  if (
    !destinationTypesPresent.IMPACT_ANALYSIS &&
    checkDeploymentExists(stages) &&
    !isRegexPipeline
  ) {
    options.push({
      label: t('viewPipeline.dialog.addStage.stageItemType.impact_analysis'),
      value: 'IMPACT_ANALYSIS',
    });
  }

  if (!checkPullRequestExists(stages)) {
    options.push({
      label: t('viewPipeline.dialog.addStage.stageItemType.pullRequestGate'),
      value: 'PULL_REQUEST',
    });
  }

  return options;
};

const getStageOptions = (
  t: TFunction<'codeDelivery'>,
  stages: ProjectPipelineStageV1[],
): DestinationOptions => {
  const options: DestinationOptions = [
    {
      value: 'DEPLOYMENT',
      label: t('viewPipeline.dialog.addStage.stageItemType.deployment'),
    },
    {
      value: 'JOB',
      label: t('viewPipeline.dialog.addStage.stageItemType.job'),
    },
  ];

  if (checkDeploymentExists(stages)) {
    options.push({
      value: 'IMPACT_ANALYSIS',
      label: t('viewPipeline.dialog.addStage.stageItemType.impact_analysis'),
    });
  }
  return options;
};

const AddStageDialog = ({
  type,
  projectType,
  onClose,
  pipeline,
  codeProject,
  stageNumber,
  onClickAddToStage,
  onClickAddNextStage,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const [stageName, setStageName] = useState('');
  const [updatedStage, setUpdatedStage] =
    useState<ProjectPipelineStageV1 | null>(null);
  const [newDestinationsSummary, setNewDestinationsSummary] =
    useState<string[]>();
  const addStage = useUpsertPipelineStagesV1();

  const stageItemTypes =
    type === 'AddItem' && stageNumber
      ? getAddItemOptions(
          pipeline.stages,
          stageNumber,
          codeProject,
          pipeline.id,
          t,
        )
      : getStageOptions(t, pipeline.stages);

  const [itemType, setItemType] = useState<FormType['itemType']>(
    stageItemTypes[0].value,
  );

  const onSubmitHandler = (
    event: JobTemplateSummaryV1[] | Deployment | ImpactAnalysis,
  ) => {
    const addStagePayload: AddNewStageParams = {
      stageName,
      destinationType: itemType,
      stages: pipeline.stages ?? [],
      event,
      workspaceId,
      projectName: codeProject.name,
      projectType,
    };

    if (itemType === 'DEPLOYMENT') {
      const deployment = event as Deployment;
      addStagePayload.deploymentDisplayName = t(
        'viewPipeline.dialog.addStage.deployment.displayName',
        {
          nodeGroupName: deployment.nodeGroup?.name,
          peName: deployment.puppetEnterpriseServer?.name,
        },
      );
      setNewDestinationsSummary([
        t('viewPipeline.dialog.addStage.success.deploymentSummary', {
          policyName: deployment.deploymentPolicy?.displayName,
          nodeGroupName: deployment.nodeGroup?.name,
          peName: deployment.puppetEnterpriseServer?.name,
        }),
      ]);
    } else if (itemType === 'JOB') {
      const job = event as JobTemplateSummaryV1[];
      setNewDestinationsSummary(job.map((j) => j.name));
    } else if (itemType === 'IMPACT_ANALYSIS') {
      const ia = event as ImpactAnalysis;
      if (ia.allStageEnvironments) {
        setNewDestinationsSummary([
          t('viewPipeline.dialog.addStage.success.IASummary.all'),
        ]);
      } else {
        setNewDestinationsSummary([
          t('viewPipeline.dialog.addStage.success.IASummary.selected'),
        ]);
      }
    }

    let stages: ProjectPipelineStageV1[];
    if (type === 'AddItem' && stageNumber) {
      if (itemType === 'PULL_REQUEST') {
        stages = addPRGate(stageNumber, pipeline.stages ?? []);
      } else {
        stages = addNewStageItem({
          ...addStagePayload,
          stageNumber,
        });
      }
    } else if (type === 'AfterStage' && stageNumber) {
      stages = addNewStageAfter({
        ...addStagePayload,
        stageNumber,
      });
    } else if (type === 'BeforeStage' && stageNumber) {
      stages = addNewStageBefore({
        ...addStagePayload,
        stageNumber,
      });
    } else {
      stages = addNewStage(addStagePayload);
    }

    addStage.mutate(
      {
        workspaceId,
        pipelineId: pipeline.id ?? '',
        requestBody: {
          projectName: codeProject.name,
          stages,
        },
      },
      {
        onSuccess: (data) => {
          let stageIndex: number = 0;
          if (stageNumber) {
            if (
              type === 'AddItem' ||
              (type === 'BeforeStage' && stageNumber > 1)
            ) {
              stageIndex = stageNumber - 1;
            } else if (type === 'AfterStage') {
              stageIndex = stageNumber;
            }
          } else {
            stageIndex = data.stages.length - 1;
          }
          const stage = data.stages[stageIndex];
          setUpdatedStage(stage);
        },
      },
    );
  };

  const getHeader = () => {
    if (updatedStage) {
      return t('viewPipeline.dialog.addStage.success.header', {
        stageName: updatedStage.stageName || updatedStage.stageNum,
      });
    }

    if (stageNumber) {
      const stage = pipeline.stages?.[stageNumber - 1];
      let headerKey;
      switch (type) {
        case 'AddItem':
          headerKey = 'viewPipeline.dialog.addStage.addItem.header';
          break;
        case 'BeforeStage':
          headerKey = 'viewPipeline.dialog.addStage.header.before';
          break;
        case 'AfterStage':
          headerKey = 'viewPipeline.dialog.addStage.header.after';
          break;
        default:
          headerKey = 'viewPipeline.dialog.addStage.header';
          break;
      }

      return t(headerKey, {
        stageName: stage?.stageName || stage?.stageNum,
      });
    }

    return t('viewPipeline.dialog.addStage.header');
  };

  const resetFormState = () => {
    addStage.reset();
    setItemType(stageItemTypes[0].value);
    setStageName('');
    setUpdatedStage(null);
    setNewDestinationsSummary([]);
  };

  return (
    <Modal onClose={onClose} className="add-stage__dialog">
      <Modal.Title>{getHeader()}</Modal.Title>
      <ConditionalRender enable={!updatedStage}>
        <div>
          <div>
            <ConditionalRender enable={type !== 'AddItem'}>
              <Heading className="add-stage__subheader" as="h6">
                {t('viewPipeline.dialog.addStage.subheader')}
              </Heading>
            </ConditionalRender>
            <Form
              className="add-stage__form"
              values={{ stageName, itemType }}
              onChange={(_: string, values: FormType) => {
                setStageName(values.stageName);
                setItemType(values.itemType);
              }}
            >
              <ConditionalRender enable={type !== 'AddItem'}>
                <Form.Field
                  type="text"
                  data-testid="add-stage-name"
                  name="stageName"
                  label={t('viewPipeline.dialog.addStage.stageName.label')}
                  placeholder={t(
                    `viewPipeline.dialog.addStage.stageName.placeholder`,
                  )}
                />
              </ConditionalRender>
              <Heading as="h6" className="add-stage__subheader">
                {t('viewPipeline.dialog.addStage.addToStage.subheader')}
              </Heading>
              <div data-testid="add-destination-select">
                <Form.Field
                  type="select"
                  name="itemType"
                  label={t('viewPipeline.dialog.addStage.stageItemType.label')}
                  options={stageItemTypes}
                  data-testid="add-destination-select"
                />
              </div>
            </Form>
          </div>
          <ConditionalRender enable={itemType === 'DEPLOYMENT'}>
            <DeploymentForm
              type="stage"
              codeProject={codeProject}
              onSubmit={onSubmitHandler}
              submitting={addStage.isLoading}
              onCancel={onClose}
              initialBranch={pipeline.name ?? ''}
              submitKey={
                type === 'AddItem'
                  ? 'viewPipeline.dialog.addStage.button.submit.deployment'
                  : 'viewPipeline.dialog.addStage.button.addStage'
              }
              projectType={projectType}
            />
          </ConditionalRender>
          <ConditionalRender enable={itemType === 'JOB'}>
            <JobForm
              onSubmit={onSubmitHandler}
              onCancel={onClose}
              submitting={addStage.isLoading}
              submitKey={
                type === 'AddItem'
                  ? 'viewPipeline.dialog.addStage.button.submit.job'
                  : 'viewPipeline.dialog.addStage.button.addStage'
              }
            />
          </ConditionalRender>
          <ConditionalRender enable={itemType === 'IMPACT_ANALYSIS'}>
            <ImpactAnalysisForm
              type="stage"
              projectType={projectType}
              stages={pipeline.stages}
              codeProject={codeProject}
              onSubmit={onSubmitHandler}
              onCancel={onClose}
              submitting={addStage.isLoading}
              submitKey={
                type === 'AddItem'
                  ? 'viewPipeline.dialog.addStage.button.submit.impact_analysis'
                  : 'viewPipeline.dialog.addStage.button.addStage'
              }
            />
          </ConditionalRender>
          <ConditionalRender enable={itemType === 'PULL_REQUEST'}>
            <AddPRGateForm
              stageNum={stageNumber}
              submitting={addStage.isLoading}
              onSubmit={onSubmitHandler}
              onCancel={onClose}
              submitKey="viewPipeline.dialog.addStage.button.submit.pr_gate"
            />
          </ConditionalRender>
        </div>
        <Cd4peError error={addStage.error} />
      </ConditionalRender>
      <ConditionalRender enable={!!updatedStage}>
        <Alert className="add-stage__success-card" type="success">
          <Heading as="h5">
            {t('viewPipeline.dialog.addStage.success.subheader', {
              stageName:
                updatedStage?.stageName ||
                updatedStage?.stageNum ||
                stageNumber ||
                '',
            })}
          </Heading>
          <Alert.Message>
            <Text>
              <ul>
                {newDestinationsSummary?.map((name) => (
                  <li>{name}</li>
                ))}
              </ul>
            </Text>
          </Alert.Message>
        </Alert>
        <Button
          className="add-stage__success-button"
          onClick={() => onClose()}
          data-testid="add-stage-done"
        >
          {t('viewPipeline.dialog.addStage.success.button.done')}
        </Button>
        <hr />
        <Heading as="h4" className="add-stage__success-continue">
          {t('viewPipeline.dialog.addStage.success.continueHeading')}
        </Heading>
        <div className="add-stage__add-stage-button">
          <Button
            type="text"
            icon="plus"
            onClick={() => {
              resetFormState();
              const updatedStageNumber = updatedStage?.stageNum || stageNumber;
              if (updatedStageNumber) {
                onClickAddToStage(updatedStageNumber);
              }
            }}
          >
            {t('viewPipeline.dialog.addStage.success.addToStage')}
          </Button>
        </div>
        <div className="add-stage__add-stage-button">
          <Button
            type="text"
            icon="plus"
            onClick={() => {
              resetFormState();
              if (updatedStage) {
                onClickAddNextStage(updatedStage.stageNum);
              }
            }}
          >
            {t('viewPipeline.dialog.addStage.success.addAnotherStage', {
              stageName:
                updatedStage?.stageName || updatedStage?.stageNum || '',
            })}
          </Button>
        </div>
      </ConditionalRender>
    </Modal>
  );
};

export default AddStageDialog;
