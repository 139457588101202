import React, { useReducer, Dispatch, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Trans, TFunction, useTranslation } from 'react-i18next';
import {
  Button,
  Heading,
  Input,
  Link,
  Loading,
  Overlay,
  Text,
  TooltipHoverArea,
} from '@puppet/react-components';
import { Table } from '@puppet/data-grid';
import useRefList, { RefListSetter } from '@hooks/useRefList';
import Breadcrumbs from '@components/Breadcrumbs';
import useWorkspaceName from '@hooks/useWorkspaceName';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { JobTemplateSummaryV1 } from '@utils/api/cd4pe';
import { formatDistanceToNow } from '@utils/date';
import {
  useDeleteTemplateV1,
  useListTemplatesV1,
  UseListTemplatesV1Result,
} from '@services/cd4pe/jobs';
import Cd4peError from '@components/Cd4peError';
import { LINKS } from 'src/routes';
import {
  JobTemplateListActions,
  toggleConfirmJobTemplateDelete,
  listJobTemplateActions,
  SortData,
  JOB_TEMPLATE_SORTS,
} from './actions';
import {
  JobTemplateListDefaultState,
  JobTemplateListState,
  reducer,
} from './reducer';

const jobTemplateActions = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<JobTemplateListActions>,
  id: number,
  workspace: string,
  confirmDeleteId: null | number,
  setRef: RefListSetter,
) => (
  <>
    <TooltipHoverArea
      tooltip={t('jobTemplateList.iconButtons.edit.tooltip')}
      anchor="bottom"
    >
      <Button
        as={NavLink}
        type="transparent"
        icon="pencil"
        to={LINKS.codeDelivery.editJobTemplate({
          path: { workspace, templateId: id },
        })}
        aria-label={t('jobTemplateList.iconButtons.edit.ariaLabel', { id })}
        disabled={confirmDeleteId === id}
      />
    </TooltipHoverArea>
    <TooltipHoverArea
      tooltip={t('jobTemplateList.iconButtons.delete.tooltip')}
      anchor="bottom"
    >
      <Button
        ref={setRef(id)}
        type="transparent"
        icon="trash"
        aria-label={t('jobTemplateList.iconButtons.delete.ariaLabel', { id })}
        onClick={() => toggleConfirmJobTemplateDelete(dispatch, id)}
        disabled={confirmDeleteId === id}
      />
    </TooltipHoverArea>
  </>
);

const jobTemplateToRow = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<JobTemplateListActions>,
  workspace: string,
  jobTemplate: JobTemplateSummaryV1,
  confirmDeleteId: null | number,
  setRef: RefListSetter,
  locale: string,
) => ({
  id: jobTemplate.id,
  name: jobTemplate.name,
  description: jobTemplate.description,
  lastRunTime: jobTemplate.lastRunTime
    ? formatDistanceToNow(jobTemplate.lastRunTime, locale, {
        addSuffix: true,
      })
    : t('jobTemplateList.table.latestRun.noRuns'),
  actions: jobTemplateActions(
    t,
    dispatch,
    jobTemplate.id,
    workspace,
    confirmDeleteId,
    setRef,
  ),
});

const tableColumns = (t: TFunction<'codeDelivery'>) => {
  return [
    {
      label: t('jobTemplateList.table.header.name'),
      dataKey: 'name',
      sortable: true,
      className: 'job-template-list-table__cell--truncate',
    },
    {
      label: t('jobTemplateList.table.header.description'),
      dataKey: 'description',
      sortable: true,
      className: 'job-template-list-table__cell--truncate',
    },
    {
      label: t('jobTemplateList.table.header.latestRun'),
      dataKey: 'lastRunTime',
      sortable: true,
      className:
        'job-template-list-table__cell--truncate job-template-list-table__cell-latest-run',
    },
    {
      label: t('jobTemplateList.table.header.actions'),
      dataKey: 'actions',
      className: 'job-template-list-table__cell-actions',
      style: { textAlign: 'right' },
    },
  ];
};

const jobTemplatesTable = (
  t: TFunction<'codeDelivery'>,
  state: JobTemplateListState,
  dispatch: Dispatch<JobTemplateListActions>,
  workspace: string,
  setRef: RefListSetter,
  locale: string,
  templates: JobTemplateSummaryV1[],
) => {
  const rows = templates.map((template) =>
    jobTemplateToRow(
      t,
      dispatch,
      workspace,
      template,
      state.confirmDeleteId,
      setRef,
      locale,
    ),
  );
  const columns = tableColumns(t);
  const onSort = (
    direction: SortData['direction'],
    sortDataKey: SortData['sortDataKey'],
  ) => dispatch(listJobTemplateActions.setSort({ direction, sortDataKey }));

  const createJobCta = (
    <Trans
      components={[
        <Link
          as={NavLink}
          to={LINKS.codeDelivery.newJobTemplate({ path: { workspace } })}
        />,
      ]}
    >
      {t('jobTemplateList.table.empty.messageBody')}
    </Trans>
  );

  return (
    <Table
      data={rows}
      columns={columns}
      emptyStateHeader={t('jobTemplateList.table.empty.messageHeader')}
      emptyStateMessage={createJobCta}
      onSort={onSort}
      sortedColumn={state.sort}
    />
  );
};

const body = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<JobTemplateListActions>,
  state: JobTemplateListState,
  workspace: string,
  setRef: RefListSetter,
  locale: string,
  templateState: UseListTemplatesV1Result,
  templates: JobTemplateSummaryV1[],
) => {
  if (templateState.error) {
    return <Cd4peError error={templateState.error} />;
  }

  if (templateState.isLoading) {
    return <Loading data-testid="job-template-list-spinner" />;
  }

  return (
    <div className="job-template-list-table__wrapper">
      <Input
        className="job-template-list-table__search"
        placeholder={t('jobTemplateList.fields.search.placeholder')}
        value={state.query}
        onChange={(v: string) => dispatch(listJobTemplateActions.setQuery(v))}
        name="searchByJobName"
        icon="search"
      />
      {jobTemplatesTable(
        t,
        state,
        dispatch,
        workspace,
        setRef,
        locale,
        templates,
      )}
    </div>
  );
};

const JobTemplateList = () => {
  const { t, i18n } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const domain = useWorkspaceDomain();
  const [state, dispatch] = useReducer(reducer, JobTemplateListDefaultState);
  const [getRef, setRef] = useRefList();
  const templateState = useListTemplatesV1({ workspaceId: domain });
  const deleteTemplate = useDeleteTemplateV1();

  const templates = useMemo(() => {
    if (!templateState.data) {
      return [];
    }

    const allTemplates = [...templateState.data.jobTemplates].sort(
      JOB_TEMPLATE_SORTS[state.sort.sortDataKey](state.sort.direction),
    );

    if (!state.query) {
      return allTemplates;
    }

    return allTemplates.filter(({ name }) =>
      name.toLowerCase().includes(state.query),
    );
  }, [state.query, state.sort, templateState.data]);

  const breadcrumbs = [
    { displayName: workspace },
    { displayName: t('jobTemplateList.breadcrumbs.jobs') },
  ];

  const deleteError = deleteTemplate.error ? deleteTemplate.error.message : '';

  const showConfirmDelete =
    state.confirmDeleteId !== null
      ? `${templates.map(({ id }) => `${id}`).join()}${deleteError}`
      : false;

  const maybeDelete = () => {
    if (state.confirmDeleteId || state.confirmDeleteId === 0) {
      deleteTemplate.mutate({ id: state.confirmDeleteId, workspaceId: domain });
    }
  };

  return (
    <div className="job-template-list">
      <div className="job-template-list-header">
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className="job-template-list-header__cta">
          <Heading>{t('jobTemplateList.page.title')}</Heading>
          <Button
            as={NavLink}
            to={LINKS.codeDelivery.newJobTemplate({ path: { workspace } })}
          >
            {t('jobTemplateList.buttons.newJob.label')}
          </Button>
        </div>

        <Text>{t('jobTemplateList.page.subtitle')}</Text>
      </div>
      <div className="job-template-list-body">
        <Cd4peError error={deleteTemplate.error} />
        {body(
          t,
          dispatch,
          state,
          workspace,
          setRef,
          i18n.language,
          templateState,
          templates,
        )}
      </div>
      <Overlay
        show={showConfirmDelete}
        target={getRef(state.confirmDeleteId)}
        position="right"
        align="inner"
      >
        <div className="job-template-list__confirm-delete">
          <Button
            icon="trash"
            type="danger"
            aria-label={t('jobTemplateList.buttons.delete.ariaLabel', {
              id: state.confirmDeleteId,
            })}
            onClick={() => maybeDelete()}
            loading={deleteTemplate.isLoading}
          >
            {t('jobTemplateList.buttons.delete.label')}
          </Button>
          <Button
            type="tertiary"
            onClick={() => toggleConfirmJobTemplateDelete(dispatch)}
            aria-label={t('jobTemplateList.buttons.cancel.ariaLabel')}
          >
            {t('jobTemplateList.buttons.cancel.label')}
          </Button>
        </div>
      </Overlay>
    </div>
  );
};

export default JobTemplateList;
