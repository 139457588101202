import { generateBaseCorsConfig } from './helpers';

export default async function callApi(
  origin,
  endpoint,
  config,
  parsingFn = 'json',
) {
  // if the config is empty then default to the the base cors config
  let requestConfig = config;
  if (!requestConfig) {
    requestConfig = generateBaseCorsConfig();
  }
  const path = origin + endpoint;

  try {
    const response = await fetch(path, requestConfig);

    if (response.ok) {
      const data = await response[parsingFn]();
      return {
        data,
        errors: null,
      };
    }

    const errorResponse = await response.text();
    let errorData;
    try {
      // if error response can be parsed as json return the object
      errorData = JSON.parse(errorResponse);
    } catch (error) {
      // if json parsing fails then return the plain string/empty body
      errorData = errorResponse;
    }

    return {
      data: null,
      errors: [
        {
          defaultMessage: 'Network response was not ok',
          code: response.status,
          body: errorData,
        },
      ],
    };
  } catch (error) {
    log.error(
      'ERROR: There has been a problem with the fetch operation: ',
      error,
    );
    return {
      data: null,
      errors: [error],
    };
  }
}
