import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import JobTemplateList from '@codeDelivery/views/JobTemplateList';
import NewJobTemplate from '@codeDelivery/views/NewJobTemplate';
import EditJobTemplate from '@codeDelivery/views/EditJobTemplate';
import NewModule from '@codeDelivery/views/NewModule';
import NewControlRepo from '@codeDelivery/views/NewControlRepo';
import { ROUTE_TYPES } from '@utils/constants';
import GroupList from '@codeDelivery/views/GroupList';
import UserList from '@codeDelivery/views/UserList';
import AddUser from '@codeDelivery/views/AddUser';
import SshKey from '@codeDelivery/views/SshKey';
import PeIntegrationList from '@codeDelivery/views/PeIntegrationList';
import AddPEIntegration from '@codeDelivery/views/AddPeIntegration';
import EditPeIntegration from '@codeDelivery/views/EditPeIntegration';
import AddProtectedEnvironment from '@codeDelivery/views/AddProtectedEnvironment';
import EditProtectedEnvironment from '@codeDelivery/views/EditProtectedEnvironment';
import RegenerateAuthToken from '@codeDelivery/views/RegenerateAuthToken';
import AddSourceControl from '@codeDelivery/views/AddSourceControl';
import Layout from '@components/Layout';
import SourceControl from '@codeDelivery/views/SourceControl';
import AddGroup from '@codeDelivery/views/AddGroup';
import { getSegment } from 'src/routes';
import WorkspaceSettings from '@codeDelivery/views/WorkspaceSettings';
import SettingsPage from '@codeDelivery/components/SettingsPage';
import OAuthRedirect from '@codeDelivery/components/OAuthRedirect';
import * as Provider from '@utils/vcs/providerConsts';
import AddUserToGroup from '@codeDelivery/views/AddUserToGroup/AddUserToGroup';
import EditCapability from '@codeDelivery/views/EditCapability';
import ListModules from '@codeDelivery/views/ListModules';
import ViewGroup from './views/ViewGroup';
import JobHardware from './views/JobHardware';
import AddCapability from './views/AddCapability';
import JobDetails from './views/JobDetails';
import ListControlRepos from './views/ListControlRepos';
import ViewControlRepo from './views/ViewControlRepo';
import ViewModule from './views/ViewModule';

const codeDelivery = (
  <Route>
    <Route path={getSegment('oauth')}>
      <Route
        path={getSegment('oauth.github')}
        element={<OAuthRedirect provider={Provider.GITHUB} />}
      />
      <Route
        path={getSegment('oauth.bitbucket')}
        element={<OAuthRedirect provider={Provider.BITBUCKET} />}
      />
    </Route>
    <Route path={getSegment('oauth2')}>
      <Route
        path={getSegment('oauth2.azureDevOps')}
        element={<OAuthRedirect provider={Provider.AZURE_DEV_OPS} />}
      />
    </Route>
    <Route
      path={getSegment('workspace')}
      element={<Layout routeType={ROUTE_TYPES.codeDelivery} />}
    >
      <Route path={getSegment('workspace.codeDelivery')}>
        <Route path={getSegment('workspace.codeDelivery.jobs')}>
          <Route
            path={getSegment('workspace.codeDelivery.jobs.jobInstanceId')}
            element={<JobDetails />}
          />
          <Route path={getSegment('workspace.codeDelivery.jobs.templates')}>
            <Route index element={<JobTemplateList />} />
            <Route
              path={getSegment('workspace.codeDelivery.jobs.templates.new')}
              element={<NewJobTemplate />}
            />
            <Route
              path={getSegment(
                'workspace.codeDelivery.jobs.templates.templateId',
              )}
            >
              <Route
                path={getSegment(
                  'workspace.codeDelivery.jobs.templates.templateId.edit',
                )}
                element={<EditJobTemplate />}
              />
            </Route>
          </Route>
        </Route>
        <Route path={getSegment('workspace.codeDelivery.modules')}>
          <Route index element={<ListModules />} />
          <Route
            path={getSegment('workspace.codeDelivery.modules.new')}
            element={<NewModule />}
          />
          <Route
            path={getSegment('workspace.codeDelivery.modules.name')}
            element={<ViewModule />}
          />
        </Route>
        <Route path={getSegment('workspace.codeDelivery.repositories')}>
          <Route index element={<ListControlRepos />} />
          <Route
            path={getSegment('workspace.codeDelivery.repositories.new')}
            element={<NewControlRepo />}
          />
          <Route
            path={getSegment('workspace.codeDelivery.repositories.name')}
            element={<ViewControlRepo />}
          />
        </Route>
        <Route path={getSegment('workspace.codeDelivery.jobHardware')}>
          <Route index element={<JobHardware />} />
          <Route
            path={getSegment('workspace.codeDelivery.jobHardware.new')}
            element={<AddCapability />}
          />
          <Route
            path={getSegment('workspace.codeDelivery.jobHardware.capabilityId')}
            element={<EditCapability />}
          />
        </Route>
      </Route>
      <Route path={getSegment('workspace.settings')} element={<SettingsPage />}>
        <Route
          index
          element={<Navigate to={getSegment('workspace.settings.workspace')} />}
        />
        <Route path={getSegment('workspace.settings.workspace')}>
          <Route index element={<WorkspaceSettings />} />
        </Route>
        <Route path={getSegment('workspace.settings.groups')}>
          <Route index element={<GroupList />} />
          <Route
            path={getSegment('workspace.settings.groups.add')}
            element={<AddGroup />}
          />
          <Route path={getSegment('workspace.settings.groups.groupId')}>
            <Route index element={<ViewGroup />} />
            <Route
              path={getSegment('workspace.settings.groups.groupId.addUsers')}
              element={<AddUserToGroup />}
            />
          </Route>
        </Route>
        <Route path={getSegment('workspace.settings.users')}>
          <Route index element={<UserList />} />
          <Route
            path={getSegment('workspace.settings.users.add')}
            element={<AddUser />}
          />
        </Route>
        <Route
          path={getSegment('workspace.settings.ssh')}
          element={<SshKey />}
        />
        <Route path={getSegment('workspace.settings.puppetEnterprise')}>
          <Route index element={<PeIntegrationList />} />
          <Route
            path={getSegment('workspace.settings.puppetEnterprise.new')}
            element={<AddPEIntegration />}
          />
          <Route path={getSegment('workspace.settings.puppetEnterprise.peId')}>
            <Route index element={<EditPeIntegration />} />
            <Route
              path={getSegment(
                'workspace.settings.puppetEnterprise.peId.environments',
              )}
            >
              <Route index element={<AddProtectedEnvironment />} />
              <Route
                path={getSegment(
                  'workspace.settings.puppetEnterprise.peId.environments.envName',
                )}
                element={<EditProtectedEnvironment />}
              />
            </Route>
            <Route
              path={getSegment(
                'workspace.settings.puppetEnterprise.peId.regenerate',
              )}
              element={<RegenerateAuthToken />}
            />
          </Route>
        </Route>
        <Route path={getSegment('workspace.settings.sourceControl')}>
          <Route index element={<SourceControl />} />
          <Route
            path={getSegment('workspace.settings.sourceControl.new')}
            element={<AddSourceControl />}
          />
        </Route>
      </Route>
    </Route>
  </Route>
);

export default codeDelivery;
