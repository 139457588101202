import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddProtectedEnvironmentsSelect from '@codeDelivery/components/AddProtectedEnvironmentsSelect';
import AddProtectedEnvironmentsTable, {
  RowData,
} from '@codeDelivery/components/AddProtectedEnvironmentsTable';
import { Heading, Text } from '@puppet/react-components';
import { ListPEProtectedEnvironmentCandidatesResponseV2 } from '@puppet/cd4pe-client-ts';

interface Props {
  environments?: ListPEProtectedEnvironmentCandidatesResponseV2 | undefined;
  selectedEnvironment: string;
  onEnvironmentChange?: Function;
  envName?: string;
  groups: RowData[];
  loadingGroups: boolean;
  selectedGroups: string[];
  onSelectedData: Function;
}

const buildEnvironmentsSelect = (
  environments: ListPEProtectedEnvironmentCandidatesResponseV2,
) => {
  return environments.environments.map((environment) => ({
    label: environment.name,
    value: environment.name,
  }));
};

const defaultProps = {
  envName: '',
  environments: [],
  onEnvironmentChange: () => {},
};

const checkIfIndeterminateState = (
  groups: RowData[],
  selectedData: string[],
) => {
  let checkAllValue = false;
  let indeterminateStateValue = false;

  if (groups?.length === selectedData?.length) {
    indeterminateStateValue = false;
    checkAllValue = true;
  } else if (selectedData?.length > 0) {
    indeterminateStateValue = true;
    checkAllValue = false;
  }

  return { checkAllValue, indeterminateStateValue };
};

const ProtectedEnvironmentsForm = ({
  environments,
  selectedEnvironment,
  onEnvironmentChange,
  envName = '',
  groups,
  loadingGroups,
  selectedGroups,
  onSelectedData,
}: Props) => {
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminateState, setIndeterminateState] = useState(false);
  const { t } = useTranslation('codeDelivery');

  const onHeaderSelected = async (checked: boolean) => {
    if (!groups) {
      return;
    }

    if (checked) {
      onSelectedData(groups?.map((group) => group.name) ?? []);
    } else {
      onSelectedData([]);
    }
  };

  const onApproverRowSelect = (checked: boolean, rowData: RowData) => {
    if (!groups) {
      return;
    }

    const newSelectedData = [...selectedGroups];

    if (checked && !newSelectedData.includes(rowData.name)) {
      onSelectedData([...newSelectedData, rowData.name]);
    } else {
      const index = newSelectedData.findIndex((name) => rowData.name === name);
      if (index > -1) {
        newSelectedData.splice(index, 1);
      }
      onSelectedData(newSelectedData);
    }
  };

  useEffect(() => {
    if (!groups) {
      return;
    }

    const { checkAllValue, indeterminateStateValue } =
      checkIfIndeterminateState(groups, selectedGroups);

    setCheckAll(checkAllValue);
    setIndeterminateState(indeterminateStateValue);
  }, [groups, selectedGroups]);

  return (
    <>
      <div className="add-protected-environment-select-heading">
        {t('addProtectedEnvironment.select.header')}
      </div>
      <div
        className="add-protected-environment-select"
        data-testid="select-protected-environments"
      >
        <AddProtectedEnvironmentsSelect
          environments={
            environments?.environments
              ? buildEnvironmentsSelect(environments)
              : []
          }
          selectedEnvironment={selectedEnvironment}
          onChange={onEnvironmentChange!}
          presetEnvironment={envName}
        />
      </div>
      <Heading as="h4">{t('addProtectedEnvironment.table.header')}</Heading>
      <Text>{t('addProtectedEnvironment.table.description')}</Text>
      <AddProtectedEnvironmentsTable
        data={groups}
        loading={loadingGroups}
        onRowChecked={onApproverRowSelect}
        onHeaderChecked={onHeaderSelected}
        headerCheckState={checkAll}
        headerIndeterminateState={indeterminateState}
        emptyStateHeader={t(
          'addProtectedEnvironment.approvers.table.empty.messageHeader',
        )}
      />
    </>
  );
};

ProtectedEnvironmentsForm.defaultProps = defaultProps;

export default ProtectedEnvironmentsForm;
