import React from 'react';
import PropTypes from 'prop-types';
import { OptionsArray } from '@utils/en.propTypes';
import { TablePageSelector, TableFooter } from '@puppet/data-grid';
import { Select, Text } from '@puppet/react-components';

const TablePager = ({
  pageData,
  limit,
  onPageSelect,
  totalCount,
  itemType,
  changeLimits,
  onChangeLimit,
  selectedLimit,
}) => {
  let tableCurrentPage;
  let tableTotalPages;
  let tableFooterText;
  let currentNode;
  let lastItemNumber;

  if (pageData.pageInfo) {
    const { totalPages, currentPage, prevOffset, nextOffset } =
      pageData.pageInfo;

    tableCurrentPage = currentPage;
    tableTotalPages = totalPages;

    if (currentPage === 1) {
      currentNode = totalCount ? 1 : 0;
    } else {
      currentNode = prevOffset + limit + 1;
    }
    currentNode = currentNode > totalCount ? totalCount : currentNode;

    lastItemNumber = nextOffset > totalCount ? totalCount : nextOffset;
    tableFooterText = `${currentNode} - ${lastItemNumber} of ${totalCount} ${itemType}`;
  }

  const pageSelectFunc = (nextPage) => {
    // prevent paging if no data is available
    if (totalCount === 0) return;
    if (nextPage === 1) {
      onPageSelect(0);
    } else {
      onPageSelect(nextPage * limit - limit);
    }
  };

  return (
    <div className="table-pager">
      {changeLimits && (
        <div
          className="table-pager-limit-selector"
          data-testid={`table-pager-${itemType}`}
        >
          <Text>Rows per page: </Text>
          <Select
            type="select"
            name="limitSelector"
            value={`${selectedLimit}`}
            onChange={onChangeLimit}
            options={changeLimits}
          />
        </div>
      )}
      <TableFooter>
        <TablePageSelector
          paginationCountText={tableFooterText}
          currentPage={tableCurrentPage}
          pageCount={tableTotalPages}
          updatePage={(nextPage) => pageSelectFunc(nextPage)}
          delta={1}
        />
      </TableFooter>
    </div>
  );
};

export default TablePager;

TablePager.propTypes = {
  pageData: PropTypes.shape({
    pageInfo: PropTypes.shape({
      totalPages: PropTypes.number,
      currentPage: PropTypes.number,
      prevOffset: PropTypes.number,
      nextOffset: PropTypes.number,
    }),
  }).isRequired,
  limit: PropTypes.number.isRequired,
  onPageSelect: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  itemType: PropTypes.string.isRequired,
  changeLimits: OptionsArray,
  onChangeLimit: PropTypes.func,
  selectedLimit: PropTypes.number,
};

TablePager.defaultProps = {
  changeLimits: null,
  onChangeLimit: () => {},
  selectedLimit: 0,
};
