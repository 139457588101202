import React from 'react';
import {
  Button,
  Modal,
  Text,
  Heading,
  Link,
  Icon,
} from '@puppet/react-components';
import { Trans, useTranslation } from 'react-i18next';
import docsLinks from '@codeDelivery/utils/docsLinks';
import {
  useCreatePipelinesFromCodeV1,
  useDisablePipelinesAsCodeV1,
} from '@services/cd4pe/pipelinesAsCode';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import Cd4peError from '@components/Cd4peError';
import { ProjectTypeV1 } from '@puppet/cd4pe-client-ts';

interface Props {
  projectName: string;
  branch: string;
  pipelineId: string;
  onClose: () => void;
  projectType: ProjectTypeV1;
}

const PipelinesAsCodePrompt = ({
  projectName,
  branch,
  pipelineId,
  onClose,
  projectType,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const createPipelinesFromCode = useCreatePipelinesFromCodeV1();
  const disablePipelinesAsCode = useDisablePipelinesAsCodeV1();
  const [pipelinesCreated, setPipelinesCreated] = React.useState(false);
  const isLoading =
    createPipelinesFromCode.isLoading || disablePipelinesAsCode.isLoading;
  const handleAccept = () => {
    createPipelinesFromCode.mutate(
      {
        workspaceId,
        pipelineId,
        projectName,
        branch,
      },
      { onSuccess: () => setPipelinesCreated(true) },
    );
  };

  const handleReject = () => {
    disablePipelinesAsCode.mutate(
      { workspaceId, pipelineId, projectName },
      { onSuccess: onClose },
    );
  };

  return (
    <Modal className="pipelines-as-code-prompt" onClose={onClose}>
      <Modal.Title>
        {t('viewPipeline.dialog.pipelinesAsCode.title')}
      </Modal.Title>
      <Heading as="h5" className="pipelines-as-code-prompt__text">
        {t('viewPipeline.dialog.pipelinesAsCode.header')}
      </Heading>
      <Text color="medium" className="pipelines-as-code-prompt__text">
        {t('viewPipeline.dialog.pipelinesAsCode.description', {
          projectType,
        })}
      </Text>
      <Text color="medium">
        {t('viewPipeline.dialog.pipelinesAsCode.header.benefits')}
      </Text>
      <ul>
        <li>
          <Text color="medium">
            {t('viewPipeline.dialog.pipelinesAsCode.text.benefit1')}
          </Text>
        </li>
        <li>
          <Text color="medium">
            {t('viewPipeline.dialog.pipelinesAsCode.text.benefit2')}
          </Text>
        </li>
        <li>
          <Text color="medium">
            {t('viewPipeline.dialog.pipelinesAsCode.text.benefit3')}
          </Text>
        </li>
      </ul>
      <Text color="medium" className="pipelines-as-code-prompt__text">
        {t('viewPipeline.dialog.pipelinesAsCode.text.benefit4', {
          projectType,
        })}
      </Text>
      <Text color="medium" className="pipelines-as-code-prompt__text">
        {t('viewPipeline.dialog.pipelinesAsCode.text.prompt')}
      </Text>
      {pipelinesCreated ? (
        <div>
          <Text color="success" className="pipelines-as-code-prompt__text">
            {t('viewPipeline.dialog.pipelinesAsCode.text.created')}
          </Text>
          <div className="pipelines-as-code-prompt__buttons">
            <Button type="primary" onClick={onClose}>
              {t('viewPipeline.dialog.pipelinesAsCode.button.done')}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Cd4peError
            error={
              createPipelinesFromCode.error ?? disablePipelinesAsCode.error
            }
          />
          <div className="pipelines-as-code-prompt__buttons">
            <Button type="primary" onClick={handleAccept} loading={isLoading}>
              {t('viewPipeline.dialog.pipelinesAsCode.button.confirm')}
            </Button>
            <Button type="tertiary" onClick={handleReject} loading={isLoading}>
              {t('viewPipeline.dialog.pipelinesAsCode.button.cancel')}
            </Button>
          </div>
        </div>
      )}
      <Icon type="book" />
      <Trans t={t} i18nKey="viewPipeline.dialog.pipelinesAsCode.text.link">
        <Link href={docsLinks().pipelinesAsCode} />
      </Trans>
    </Modal>
  );
};

export default PipelinesAsCodePrompt;
