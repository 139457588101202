import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Cd4peApiError,
  handleQueryRequest,
  QueryHook,
  MutationHook,
} from '@utils/api/cd4pe';
import { ImpactAnalysisV1Service } from '@puppet/cd4pe-client-ts';
import queryClient from '@services/queryClient';
import { LIST_TRIGGER_EVENTS_QUERY_TAG } from './events';

export const GET_IMPACT_ANALYSIS_CSV_TAG = 'getImpactAnalysisCsv';
export const TRIGGER_IMPACT_ANALYSIS_QUERY_TAG = 'triggerImpactAnalysis';

/**
 * useGetImpactAnalysisCsvV1
 */

type UseGetImpactAnalysisCsvV1 = QueryHook<
  typeof ImpactAnalysisV1Service,
  'getImpactAnalysisCsvV1',
  Cd4peApiError
>;

export const useGetImpactAnalysisCsvV1: UseGetImpactAnalysisCsvV1 = (
  payload,
  options,
) =>
  useQuery(
    [GET_IMPACT_ANALYSIS_CSV_TAG, payload.workspaceId],
    () => {
      return handleQueryRequest(
        ImpactAnalysisV1Service.getImpactAnalysisCsvV1(payload),
      );
    },
    options,
  );

/**
 * useTriggerImpactAnalysisV1
 */
type UseTriggerImpactAnalysisV1 = MutationHook<
  typeof ImpactAnalysisV1Service,
  'triggerImpactAnalysisV1',
  Cd4peApiError
>;

export type UseTriggerImpactAnalysisV1Result =
  ReturnType<UseTriggerImpactAnalysisV1>;

export const useTriggerImpactAnalysisV1: UseTriggerImpactAnalysisV1 = () => {
  return useMutation(
    (payload) =>
      handleQueryRequest(
        ImpactAnalysisV1Service.triggerImpactAnalysisV1(payload),
      ),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          LIST_TRIGGER_EVENTS_QUERY_TAG,
          payload.requestBody.workspaceId,
        ]);
      },
    },
  );
};
