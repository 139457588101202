import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OptionsArray } from 'utils/en.propTypes';
import { Button, Text } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import FactList from './FactList';

import './ColumnPicker.scss';

const byActive = (fact) => {
  return fact.isActive === true;
};

const ColumnPicker = ({
  disabled,
  defaultFactList,
  facts,
  onClickApply,
  factLimitSize,
  closeColumnPicker,
  initialColumns,
}) => {
  const getInitialColumns = () => {
    if (initialColumns.length > 0) {
      return initialColumns.map((column) => {
        return {
          factname: column,
          isActive: true,
          defaultFact: defaultFactList.some(
            (defaultColumn) => defaultColumn.factname === column,
          ),
        };
      });
    }

    return defaultFactList;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [visibleFacts, setVisibleFacts] = useState(getInitialColumns);
  const [countActiveFacts, setCountActiveFacts] = useState(0);
  const [factsClean, setFactsClean] = useState(true);
  const [originalFacts, setOriginalFacts] = useState([]);
  const { t } = useTranslation('inventory');
  const onClickButton = () => {
    setIsOpen(!isOpen);
  };

  // TODO Eslint says this should be wrapped in a useCallback, but this breaks the unit tests for some reason.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cancelAction = () => {
    // if the facts have been altered
    // then set them to the cache on closing the column picker through a cancel
    if (originalFacts.length > 0 && !factsClean) {
      setVisibleFacts(originalFacts);
      // if the column picker was opened in a default state, return it to default
    } else if (factsClean) {
      setVisibleFacts(defaultFactList);
    }
    setIsOpen(false);
  };

  const onClickItem = (factValue) => {
    const newVisibleFacts = visibleFacts.map((fact) => {
      if (fact.factname === factValue) {
        return { ...fact, isActive: !fact.isActive };
      }
      return fact;
    });
    setVisibleFacts(newVisibleFacts);
  };

  const onRemoveItem = (factname) => {
    const remainingVisibleFacts = visibleFacts.filter((column) => {
      return column.factname !== factname;
    });
    setVisibleFacts(remainingVisibleFacts);
  };

  const addFact = (fact) => {
    // Do not add fact if it is already in the visible fact list
    const duplicateFact = visibleFacts.find(
      ({ factname }) => factname === fact,
    );
    if (duplicateFact) return false;

    // Do not add fact if it is not on the facts lists
    const validFact = facts.find(({ label }) => label === fact);
    if (!validFact) return false;

    const newFact = {
      factname: fact,
      isActive: true,
      defaultFact: false,
    };
    const newVisibleFacts = [...visibleFacts, newFact];
    // This list will display up to 8 facts before scrolling
    setVisibleFacts(newVisibleFacts);

    return true;
  };

  useEffect(() => {
    const activeFacts = visibleFacts.filter(({ isActive }) => isActive).length;
    setCountActiveFacts(activeFacts);
  }, [visibleFacts]);

  useEffect(() => {
    if (closeColumnPicker && isOpen) {
      cancelAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeColumnPicker, isOpen]);

  // TODO This useEffect logic is not correct and is being treated as a onOpen toggle handler. This should be reworked.
  useEffect(() => {
    if (!isOpen) {
      // on closing clear our facts cache
      setOriginalFacts([]);
    } else if (visibleFacts !== defaultFactList) {
      setOriginalFacts(visibleFacts);
    }
    // set a flag that the facts have changed
    setFactsClean(defaultFactList === visibleFacts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      data-testid="columnPickerContainer"
      className={classNames('cp-column-select rc-action-select', {
        'rc-action-select-open': isOpen,
        'rc-action-select-closed': !isOpen,
      })}
    >
      <Button
        type="transparent"
        onClick={onClickButton}
        disabled={disabled}
        data-testid="column-picker-button"
      >
        {t('columnPicker.button.open')}
      </Button>
      <div
        data-testid="menu-list"
        className={classNames('rc-menu-list', 'rc-action-menu-list')}
      >
        <div className="cp-column-header-container">
          <Text className="cp-column-header" size="tiny">
            {t('columnPicker.text.selected', {
              selected: countActiveFacts,
              total: visibleFacts.length,
            })}
          </Text>
        </div>
        <FactList
          factOptions={facts}
          addFact={addFact}
          onRemoveItem={onRemoveItem}
          onClickItem={onClickItem}
          visibleFacts={visibleFacts}
          onClickApply={() => {
            onClickApply(visibleFacts.filter(byActive));
            setIsOpen(false);
          }}
          onClickReset={() => {
            setVisibleFacts(defaultFactList);
          }}
          onClickCancel={() => {
            cancelAction();
          }}
          factLimitSize={factLimitSize}
        />
      </div>
    </div>
  );
};

export default ColumnPicker;

ColumnPicker.propTypes = {
  disabled: PropTypes.bool,
  defaultFactList: PropTypes.arrayOf(
    PropTypes.shape({
      factname: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      defaultFact: PropTypes.bool,
    }),
  ).isRequired,
  facts: OptionsArray.isRequired,
  onClickApply: PropTypes.func.isRequired,
  factLimitSize: PropTypes.number.isRequired,
  closeColumnPicker: PropTypes.bool.isRequired,
  initialColumns: PropTypes.arrayOf(PropTypes.string),
};

ColumnPicker.defaultProps = {
  initialColumns: [],
  disabled: false,
};
