import CommonForm from '@components/CommonForm';
import { useTranslation } from 'react-i18next';
import { Form, RadioButton } from '@puppet/react-components';
import React, { useState } from 'react';
import { CommonFormChangeFunction } from '@components/CommonForm/CommonForm';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useConnectBitbucketServerV1 } from '@services/cd4pe/vcs';
import * as Provider from '@utils/vcs/providerConsts';
import getProviderDisplayName from '@utils/vcs/displayNameHelper';
import Cd4peError from '@components/Cd4peError';

interface FormVals {
  host: string;
  username: string;
  password: string;
  caCertificate?: string;
  sshPort?: number;
  sshUrl?: string;
  sshUser?: string;
}
interface Props {
  startingValues?: FormVals;
  onSuccess: (provider: string) => void;
  onCancel: () => void;
}

const defaultProps = {
  startingValues: {
    host: '',
    username: '',
    password: '',
    caCertificate: '',
    sshPort: undefined,
    sshUrl: undefined,
    sshUser: undefined,
  } as FormVals,
};

const BitBucketServerForm = ({
  startingValues,
  onSuccess,
  onCancel,
}: Props) => {
  const [formVals, setFormVals] = useState<FormVals>(
    startingValues || defaultProps.startingValues,
  );
  const [customCaCert, setCustomCaCert] = useState<boolean>(
    startingValues?.caCertificate !== undefined &&
      startingValues?.caCertificate === '',
  );
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const bitBucketServerConnect = useConnectBitbucketServerV1();

  const onSubmit = async () => {
    const requestBody = { ...formVals };

    // in the case where we are not using a custom ca cert we dont use the
    // caCertificate field so no need to send it down
    if (!customCaCert) {
      delete requestBody.caCertificate;
    }

    return bitBucketServerConnect.mutate(
      {
        workspaceId,
        requestBody,
      },
      {
        onSuccess: () => {
          onSuccess(getProviderDisplayName(Provider.BITBUCKET_SERVER));
        },
      },
    );
  };

  const onChange: CommonFormChangeFunction<FormVals> = (e, values) => {
    let updatedVal = values[e];

    if (e === 'sshPort') {
      updatedVal = Number(updatedVal);
    }

    setFormVals((prev) => {
      return { ...prev, [e]: updatedVal };
    });
  };

  return (
    <CommonForm
      submittable
      cancellable
      className="add-source-control-form"
      submitLabel={t('addSourceControl.form.submitButton')}
      values={formVals}
      onChange={onChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
    >
      <CommonForm.Section>
        <CommonForm.Section.Main>
          <h3 className="add-source-control-form-section__header">
            {t('addSourceControl.credentials.header')}
          </h3>
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              type="url"
              name="host"
              label={t(
                'addSourceControl.credentials.form.bitBucketServer.serverHost',
              )}
              required
            />
          </div>
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              type="text"
              name="username"
              label={t(
                'addSourceControl.credentials.form.bitBucketServer.username',
              )}
              required
            />
          </div>
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              type="password"
              name="password"
              label={t(
                'addSourceControl.credentials.form.bitBucketServer.password',
              )}
              required
            />
          </div>
          <div className="add-source-control-form-radio__wrapper">
            <RadioButton
              name="custom-ca-cert-false"
              label={t(
                'addSourceControl.credentials.form.bitBucketServer.noCustomCaCert',
              )}
              value={!customCaCert}
              onChange={() => setCustomCaCert(false)}
            />
            <RadioButton
              name="custom-ca-cert-true"
              label={t(
                'addSourceControl.credentials.form.bitBucketServer.yesCustomCaCert',
              )}
              value={customCaCert}
              onChange={() => setCustomCaCert(true)}
            />
          </div>
          {customCaCert ? (
            <div className="add-source-control-form-credentials-field__wrapper">
              <Form.Field
                type="multiline"
                name="caCertificate"
                label={t(
                  'addSourceControl.credentials.form.bitBucketServer.customCaCert',
                )}
                required={customCaCert}
              />
            </div>
          ) : null}
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              type="number"
              name="sshPort"
              label={t(
                'addSourceControl.credentials.form.bitBucketServer.sshPort',
              )}
            />
          </div>
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              type="url"
              name="sshHost"
              label={t(
                'addSourceControl.credentials.form.bitBucketServer.sshHost',
              )}
            />
          </div>
          <div className="add-source-control-form-credentials-field__wrapper">
            <Form.Field
              type="text"
              name="sshUser"
              label={t(
                'addSourceControl.credentials.form.bitBucketServer.sshUser',
              )}
            />
          </div>
        </CommonForm.Section.Main>
      </CommonForm.Section>
      <div className="add-source-control-form-error">
        <Cd4peError error={bitBucketServerConnect.error} />
      </div>
    </CommonForm>
  );
};

export default BitBucketServerForm;

BitBucketServerForm.defaultProps = defaultProps;
