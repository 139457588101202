import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  path: string;
  traceId?: string;
}

export const DEFAULT_TRACE_ID = '00000000000000000000000000000000';

const CommonDetails = ({ path, traceId = undefined }: Props) => {
  const { t } = useTranslation('common');

  return (
    <div className="cd4pe-error__common">
      <p>
        <Trans
          t={t}
          i18nKey="cd4peError.commonDetails.path"
          values={{ path }}
          components={[<span />]}
        />
      </p>
      {traceId && traceId !== DEFAULT_TRACE_ID && (
        <p>
          <Trans
            t={t}
            i18nKey="cd4peError.commonDetails.traceId"
            values={{ traceId }}
            components={[<span />]}
          />
        </p>
      )}
    </div>
  );
};

export default CommonDetails;
