import { Table, TableHeader } from '@puppet/data-grid';
import { Button } from '@puppet/react-components';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AddGroupMembersRequestV1,
  GroupMemberCandidateV1,
} from '@puppet/cd4pe-client-ts';
import {
  useAddGroupMembersV1,
  useListGroupMemberCandidatesV1,
} from '@services/cd4pe/groups';
import { useTranslation } from 'react-i18next';
import Cd4peError from '@components/Cd4peError';
import { useNavigate } from 'react-router-dom';
import { LINKS } from 'src/routes';
import { stringCompare } from '@utils/compare';

interface props {
  groupId: string | undefined;
  workspace: string;
}

export interface RowData extends GroupMemberCandidateV1 {
  selected?: boolean;
}

type SortUsers = {
  direction: string;
  sortDataKey: string;
};

const columns = [{ label: 'User', dataKey: 'name' }];

const AddUserForm = ({ groupId, workspace }: props) => {
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminateState, setIndeterminateState] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortUsers, setSortUsers] = useState<SortUsers>({
    direction: 'asc',
    sortDataKey: 'name',
  });
  const { t } = useTranslation('codeDelivery');

  const navigate = useNavigate();

  const {
    isLoading: isLoadingUsers,
    error: userError,
    data: users,
  } = useListGroupMemberCandidatesV1({
    groupId: Number(groupId),
  });

  const derivedUsers = useMemo(
    () =>
      users?.groupMemberCandidates
        ?.filter(({ name }) =>
          name.toLowerCase().includes(searchValue.toLowerCase()),
        )
        ?.map((user) => ({
          ...user,
          selected: selectedData.includes(user.name),
        }))
        ?.sort(
          (a, b) =>
            (sortUsers.direction === 'asc' ? 1 : -1) *
            stringCompare(a.name, b.name),
        ) ?? [],
    [users, selectedData, sortUsers, searchValue],
  );

  const onUserRowSelected = (checked: boolean, rowData: RowData) => {
    if (!derivedUsers) {
      return;
    }

    const newSelectedData = [...selectedData];

    if (checked && !newSelectedData.includes(rowData.name)) {
      setSelectedData([...newSelectedData, rowData.name]);
    } else {
      const index = newSelectedData.findIndex((name) => rowData.name === name);
      if (index > -1) {
        newSelectedData.splice(index, 1);
      }
      setSelectedData(newSelectedData);
    }
  };

  const onHeaderSelected = async (checked: boolean) => {
    if (!derivedUsers) {
      return;
    }
    if (checked) {
      setSelectedData(derivedUsers?.map((user) => user.name) ?? []);
    } else {
      setSelectedData([]);
    }
  };

  const checkIfIndeterminateState = () => {
    let checkAllValue = false;
    let indeterminateStateValue = false;

    if (derivedUsers?.length === 0) {
      return { indeterminateStateValue: false, checkAllValue: false };
    }

    if (derivedUsers?.length === selectedData?.length) {
      indeterminateStateValue = false;
      checkAllValue = true;
    } else if (selectedData?.length > 0) {
      indeterminateStateValue = true;
      checkAllValue = false;
    }

    return { checkAllValue, indeterminateStateValue };
  };

  useEffect(() => {
    if (!derivedUsers) {
      return;
    }

    const { checkAllValue, indeterminateStateValue } =
      checkIfIndeterminateState();

    setCheckAll(checkAllValue);
    setIndeterminateState(indeterminateStateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedUsers, selectedData]);

  const navigateToGroup = () => {
    navigate(
      LINKS.settings.viewGroup({
        path: { workspace, groupId },
      }),
    );
  };

  const addUserToGroup = useAddGroupMembersV1();
  const onSubmit = async () => {
    const userIds = derivedUsers
      .filter((item) => selectedData.includes(item.name))
      .map((a) => a.userId);

    const requestBody: AddGroupMembersRequestV1 = { userIds };

    addUserToGroup.mutate(
      {
        groupId: Number(groupId),
        requestBody,
      },
      { onSuccess: () => navigateToGroup() },
    );
  };

  return (
    <div>
      <div>
        <TableHeader
          search
          searchPlaceholder={t('addGroupUsers.search.placeholder')}
          searchValue={searchValue}
          onSearchChange={(newSearchValue: string) =>
            setSearchValue(newSearchValue)
          }
        />
        <div className="add-group-users__table-container">
          <Table
            columns={columns}
            data={derivedUsers}
            loading={isLoadingUsers}
            onSort={(
              direction: SortUsers['direction'],
              sortDataKey: SortUsers['sortDataKey'],
            ) => setSortUsers({ direction, sortDataKey })}
            sortedColumn={sortUsers}
            selectable
            emptyStateHeader={t('addGroupUsers.table.emptyState.header')}
            emptyStateMessage=""
            onRowChecked={onUserRowSelected}
            onHeaderChecked={onHeaderSelected}
            headerCheckState={checkAll}
            headerIndeterminateState={indeterminateState}
            data-testid="add-group-users-table"
          />
        </div>
        <div className="add-group-users__button-container">
          <Button
            onClick={onSubmit}
            disabled={selectedData.length === 0}
            data-testid="add-group-users-button"
          >
            {t('addGroupUsers.button.addUsers')}
          </Button>
          <Button type="secondary" onClick={() => navigateToGroup()}>
            {t('addGroupUsers.button.cancel')}
          </Button>
        </div>
        <div>
          <Cd4peError error={userError} />
          <Cd4peError error={addUserToGroup.error} />
        </div>
      </div>
    </div>
  );
};

export default AddUserForm;
