import { useSelector } from 'react-redux';
import { selectWorkspaceName } from '@state/session/selectors';

const useWorkspaceName = () => {
  const workspaceName = useSelector(selectWorkspaceName);

  if (!workspaceName) {
    throw new Error('Expected workspace name to be set but was not defined.');
  }

  return workspaceName as string;
};

export default useWorkspaceName;
