// This custom hook will make an apollo client hook call to the provided query.
// It will return the data formatted to be used for a <Select/> tag
// It will also return the usual apollo client hook parameters
import { useQuery } from '@apollo/client';

function useListQuery(query, queryVariables, dataPath) {
  const { data, ...rest } = useQuery(query, {
    variables: queryVariables,
  });

  let formattedData;
  if (data) {
    const rawFacts = data?.[dataPath] ?? [];
    formattedData = rawFacts
      .filter((fact, index) => rawFacts.indexOf(fact) === index)
      .map((fact) => {
        return {
          value: fact,
          label: fact,
        };
      });
  }
  return { data: formattedData || data, ...rest };
}

export default useListQuery;
