export const stringCompare = (s1, s2) => {
  const a = (s1 || '').toLowerCase();
  const b = (s2 || '').toLowerCase();
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
};

export const numberCompare = (n1, n2) => n2 - n1;
