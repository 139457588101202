import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Text, Modal, Icon, Checkbox, Button } from '@puppet/react-components';
import { useAppDispatch } from '@hooks/redux';
import { AuthTokenV1 } from '@puppet/cd4pe-client-ts';
import { useRevokeToken } from '@services/cd4pe/auth';
import alerts from '@state/ui/alerts';
import Cd4peError from '@components/Cd4peError';

type RevokeTokenDialogProps = {
  token: AuthTokenV1;
  onClose: () => void;
};

const RevokeTokenDialog = ({ token, onClose }: RevokeTokenDialogProps) => {
  const { t } = useTranslation('codeDelivery');
  const [isConfirmedRevoke, setIsConfirmedRevoke] = useState(false);
  const { mutate: revokeToken, isLoading, error } = useRevokeToken();
  const appDispatch = useAppDispatch();

  const onCheckboxChange = (checked: boolean) => {
    setIsConfirmedRevoke(checked);
  };

  const CheckboxLabel = (
    <Trans
      t={t}
      i18nKey="profile.tokens.revoke.dialog.checkbox"
      values={{ tokenName: token.name }}
      components={[<b />]}
    />
  );

  const onConfirm = async () => {
    revokeToken(
      { tokenId: token.id! },
      {
        onSuccess: () => {
          appDispatch(
            alerts.actions.createAlert({
              message: t('profile.tokens.revoke.dialog.alert.success'),
              type: 'Success',
            }),
          );
          onClose();
        },
      },
    );
  };

  return (
    <Modal
      className="token-revoke-dialog"
      onClose={isLoading ? undefined : onClose}
    >
      <Modal.Title>{t('profile.tokens.revoke.dialog.title')}</Modal.Title>
      <Text size="medium" className="token-revoke-dialog-contents">
        <div className="token-revoke-dialog-contents-warning">
          <Icon
            className="token-revoke-dialog-contents-warning__icon"
            type="alert"
          />
          {t('profile.tokens.revoke.dialog.warning')}
        </div>
        <Checkbox
          name="revoke-token-confirmation-checkbox"
          className="token-revoke-dialog-contents__checkbox"
          size="medium"
          label={CheckboxLabel}
          value={isConfirmedRevoke}
          onChange={onCheckboxChange}
          data-testid="revoke-token-confirm-checkbox"
        />
        {error && <Cd4peError error={error} />}
        <Modal.Actions className="token-revoke-dialog-buttons">
          <Button
            type="danger"
            disabled={!isConfirmedRevoke}
            onClick={onConfirm}
            loading={isLoading}
            data-testid="revoke-token-confirm-button"
          >
            {t('profile.tokens.revoke.dialog.button.confirm')}
          </Button>
          <Button type="tertiary" onClick={onClose} disabled={isLoading}>
            {t('profile.tokens.revoke.dialog.button.cancel')}
          </Button>
        </Modal.Actions>
      </Text>
    </Modal>
  );
};

export default RevokeTokenDialog;
