import React, { useState } from 'react';
import { OptionsArray } from 'utils/en.propTypes';
import { Select, Button, Heading } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './FactPicker.scss';

const FactPicker = ({
  factOptions,
  onClick,
  onFilter,
  isOpen,
  displayFooter,
}) => {
  const [chosenFact, setChosenFact] = useState('');
  const { t } = useTranslation('inventory');
  const footer = displayFooter ? (
    <div className="fp-footer">{t('factPicker.footer.message')}</div>
  ) : (
    false
  );
  return (
    <div className="fp-container">
      <Heading className="fp-heading" label color="subtle">
        {t('factPicker.view.message')}
      </Heading>
      <div className="fp-select-container">
        <Select
          id="fact-selector"
          name="fact-selector"
          options={factOptions}
          placeholder={t('factPicker.select.placeholder')}
          value={chosenFact}
          onChange={(value) => setChosenFact(value)}
          type="autocomplete"
          footer={footer}
          onFilter={onFilter}
          open={isOpen}
        />
        <Button
          onClick={() => {
            onClick(chosenFact);
            setChosenFact('');
          }}
          type="tertiary"
          data-testid="add-fact-button"
        >
          {t('factPicker.button.add')}
        </Button>
      </div>
    </div>
  );
};

export default FactPicker;

FactPicker.propTypes = {
  factOptions: OptionsArray.isRequired,
  onClick: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  isOpen: PropTypes.string.isRequired,
  displayFooter: PropTypes.bool,
};

FactPicker.defaultProps = {
  displayFooter: false,
};
