import React, { PropsWithChildren } from 'react';

interface Props {
  status: number;
}

const ErrorTitle = ({ status, children }: PropsWithChildren<Props>) => (
  <h6 className="cd4pe-error__title">
    {status}: {children}
  </h6>
);

export default ErrorTitle;
