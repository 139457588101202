import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { Button, Card, Heading, Loading } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@hooks/redux';
import useWorkspaceName from '@hooks/useWorkspaceName';
import ProtectedEnvironmentsForm from '@codeDelivery/components/ProtectedEnvironmentsForm';
import alerts from '@state/ui/alerts';
import { useListGroupsV1 } from '@services/cd4pe/groups';
import {
  useBulkCreateApprovalGroupsV2,
  useBulkDeleteApprovalGroupsV2,
  useGetPeIntegrationV2,
  useListProtectedEnvironmentsV2,
} from '@services/cd4pe/peIntegrations';
import Breadcrumbs from '@components/Breadcrumbs';
import PageLayout from '@components/PageLayout';
import Cd4peError from '@components/Cd4peError';
import { Cd4peApiError } from '@utils/api/cd4pe';
import { LINKS } from 'src/routes';

const EditProtectedEnvironment = () => {
  const workspaceId = useWorkspaceDomain();
  const workspaceName = useWorkspaceName();
  const navigate = useNavigate();
  const { peId: peIntegrationId } = useParams<{ peId: string }>();
  const { envName: environName } = useParams<{ envName: string }>();
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [error, setError] = useState<Cd4peApiError | null>(null);
  const { t } = useTranslation('codeDelivery');
  const appDispatch = useAppDispatch();

  const navigateToPeList = () =>
    navigate(
      LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceName },
      }),
    );

  const {
    isLoading: isLoadingGroups,
    error: groupsError,
    data: groups,
  } = useListGroupsV1({ workspaceId });

  const {
    isLoading: peIntegrationLoading,
    error: peIntegrationError,
    data: peIntegration,
  } = useGetPeIntegrationV2({
    peIntegrationId: peIntegrationId!,
  });

  const { isLoading, data: selectedGroups } = useListProtectedEnvironmentsV2({
    peIntegrationId: peIntegrationId!,
  });

  const derivedGroups =
    groups?.groups?.map((d) =>
      selectedData.includes(d.name) ? { ...d, selected: true } : d,
    ) ?? [];

  const deletedGroups = derivedGroups
    .filter((g) => !selectedData.includes(g.name))
    .map(({ name }) => name);

  const createApprovalGroups = useBulkCreateApprovalGroupsV2();
  const deleteApprovalGroups = useBulkDeleteApprovalGroupsV2();

  const onSubmit = async () => {
    try {
      await createApprovalGroups.mutateAsync({
        peIntegrationId: peIntegrationId!,
        environmentName: environName!,
        requestBody: { approvalGroupNames: selectedData },
      });
      if (deletedGroups.length > 0) {
        await deleteApprovalGroups.mutateAsync({
          peIntegrationId: peIntegrationId!,
          environmentName: environName!,
          requestBody: { approvalGroupNames: deletedGroups },
        });
      }
      navigateToPeList();
      appDispatch(
        alerts.actions.createAlert({
          type: 'Success',
          message: t('editProtectedEnvironment.alert.success', {
            name: peIntegration?.name,
            environment: environName,
          }),
        }),
      );
    } catch (e) {
      setError(e as Cd4peApiError);
    }
  };

  useEffect(() => {
    if (selectedGroups && environName) {
      const environment =
        selectedGroups.protectedEnvironments?.filter(
          (pe) => pe.environmentName === environName,
        ) ?? [];

      environment.forEach((env) => {
        setSelectedData(env.approvalGroups.map(({ groupName }) => groupName));
      });
    }
  }, [selectedGroups, environName]);

  const breadcrumbs = [
    { displayName: workspaceName },
    {
      displayName: t('addProtectedEnvironment.breadcrumbs.settingsPage'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('addProtectedEnvironment.breadcrumbs.peIntegrationsPage'),
      linkDestination: LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t(
        'editProtectedEnvironment.breadcrumbs.editProtectedEnvironmentPage',
      ),
    },
  ];

  return (
    <PageLayout className="add-protected-environment-container">
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('editProtectedEnvironment.header.text')}</Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        <Card>
          <div className="add-protected-environment-body">
            {isLoading || peIntegrationLoading ? (
              <Loading
                data-testid="loading-spinner"
                className="loading"
                size="small"
              />
            ) : (
              <>
                <Heading as="h4">
                  {t('editProtectedEnvironment.page.title', {
                    name: peIntegration?.name,
                  })}
                </Heading>
                <ProtectedEnvironmentsForm
                  selectedEnvironment={environName ?? ''}
                  groups={derivedGroups}
                  loadingGroups={isLoadingGroups}
                  selectedGroups={selectedData}
                  onSelectedData={setSelectedData}
                  envName={environName}
                />
              </>
            )}
          </div>
        </Card>

        <div className="add-protected-environment-button-container">
          <Button
            data-testid="edit-protected-environments-btn"
            disabled={selectedData.length === 0 || environName === ''}
            onClick={onSubmit}
          >
            {t('editProtectedEnvironment.buttons.add.label')}
          </Button>
          <Button type="tertiary" onClick={() => navigateToPeList()}>
            {t('addProtectedEnvironment.buttons.cancel.label')}
          </Button>
        </div>
        <Cd4peError error={peIntegrationError} />
        <Cd4peError error={groupsError} />
        <Cd4peError error={error} />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default EditProtectedEnvironment;
