import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as PdsBreadcrumb } from '@puppet/react-components';
import { NavLink } from 'react-router-dom';

interface Breadcrumb {
  displayName: string;
  linkDestination?: string | null;
  dataTestId?: string;
}

export type BreadcrumbsType = Breadcrumb[];

const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: BreadcrumbsType }) => {
  const sections = breadcrumbs.map((breadcrumb) => {
    if (breadcrumb.linkDestination) {
      return (
        <PdsBreadcrumb.Section
          as={NavLink}
          to={breadcrumb.linkDestination}
          key={breadcrumb.displayName}
          data-testid={breadcrumb.dataTestId}
        >
          {breadcrumb.displayName}
        </PdsBreadcrumb.Section>
      );
    }
    return (
      <PdsBreadcrumb.Section
        key={breadcrumb.displayName}
        disabled
        data-testid={breadcrumb.dataTestId}
      >
        {breadcrumb.displayName}
      </PdsBreadcrumb.Section>
    );
  });

  return <PdsBreadcrumb>{sections}</PdsBreadcrumb>;
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      linkDestination: PropTypes.string,
      dataTestId: PropTypes.string,
    }),
  ).isRequired,
};

export default Breadcrumbs;
