import React, { Dispatch, useEffect, useReducer } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  ActionSelect,
  Button,
  Heading,
  Icon,
  Link,
  Loading,
  TooltipHoverArea,
} from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { SshKeyPairV1 } from '@utils/api/cd4pe';
import Cd4peError from '@components/Cd4peError';
import { sshKeyDefaultState, SshKeyState, reducer } from './reducer';
import {
  createSshKey,
  deleteSshKey,
  getSshKey,
  SshKeyActions,
} from './actions';

const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

const noSshKey = (
  t: TFunction<'codeDelivery'>,
  generateKey: () => Promise<void>,
) => (
  /* eslint-disable jsx-a11y/anchor-is-valid */
  <section className="ssh-key-message">
    <Icon type="key" className="ssh-key-message__icon" />
    <h4 className="ssh-key-message__title">
      {t('sshKey.section.empty.title')}
    </h4>
    <p className="ssh-key-message__body">
      <Link
        as={Button}
        onClick={generateKey}
        aria-label={t('sshKey.buttons.generateKey.label')}
      >
        {t('sshKey.buttons.generateKey.label')}
      </Link>
    </p>
  </section>
  /* eslint-enable jsx-a11y/anchor-is-valid */
);

const generatingKeyLoader = (t: TFunction<'codeDelivery'>) => (
  <section className="ssh-key-message">
    <div>
      <Loading size="tiny" />
    </div>
    <h4 className="ssh-key-message__title">
      {t('sshKey.section.loading.title')}
    </h4>
  </section>
);

const sshKeyList = (
  sshKey: SshKeyPairV1,
  deleteKey: () => Promise<void>,
  t: TFunction<'codeDelivery'>,
) => {
  const actions = [
    {
      id: 'delete',
      icon: 'trash',
      label: t('sshKey.buttons.delete.label'),
      onClick: deleteKey,
      type: 'transparent',
      'aria-label': t('sshKey.buttons.delete.label'),
      role: 'button',
    },
  ];

  return (
    <ul className="ssh-key-list">
      <li className="ssh-key-card">
        <div>
          <Icon type="key" className="ssh-key-card__icon" />
        </div>
        <div className="ssh-key-card__details">
          <div className="ssh-key-card__row">
            <h5 className="ssh-key-card__title">
              {t('sshKey.section.fingerprint.title')}
            </h5>

            <p className="ssh-key-card__value">{sshKey.fingerprint}</p>
          </div>

          <div className="ssh-key-card__row">
            <h5 className="ssh-key-card__title">
              {t('sshKey.section.publicKey.title')}
            </h5>

            <div className="ssh-key-card__value">
              <p className="ssh-key-card__value__wrapped">{sshKey.publicKey}</p>

              <Button
                type="secondary"
                icon="clipboard"
                onClick={() => copyToClipboard(sshKey.publicKey)}
              >
                {t('sshKey.buttons.copy.label')}
              </Button>
            </div>
          </div>
        </div>
        <div>
          <TooltipHoverArea
            tooltip={t('sshKey.iconButtons.actions.tooltip')}
            anchor="left"
          >
            <ActionSelect
              type="transparent"
              anchor="bottom right"
              icon="kebab"
              actions={actions}
            />
          </TooltipHoverArea>
        </div>
      </li>
    </ul>
  );
};

const body = (
  workspaceId: string,
  state: SshKeyState,
  dispatch: Dispatch<SshKeyActions>,
  t: TFunction<'codeDelivery'>,
) => {
  const generateNewKey = () => createSshKey(workspaceId, t, dispatch);
  const deleteKey = () => deleteSshKey(workspaceId, t, dispatch);

  if (state.getSshKeyError) {
    return null;
  }

  if (state.createSshKeyLoading) {
    return generatingKeyLoader(t);
  }

  if (!state.sshKey) {
    return noSshKey(t, generateNewKey);
  }

  return sshKeyList(state.sshKey, deleteKey, t);
};

const SshKey = () => {
  const { t } = useTranslation('codeDelivery');
  const [state, dispatch] = useReducer(reducer, sshKeyDefaultState);
  const workspaceId = useWorkspaceDomain();

  useEffect(() => {
    if (workspaceId && state.sshKey === undefined) {
      getSshKey(workspaceId, t, dispatch);
    }
  }, [workspaceId, dispatch, t, state.sshKey]);

  if (state.getSshKeyLoading) {
    return <Loading data-testid="ssh-key-spinner" />;
  }

  return (
    <div className="ssh-key">
      <Heading as="h3" className="ssh-key-title">
        {t('sshKey.page.title')}
      </Heading>

      <Cd4peError error={state.getSshKeyError} />
      <Cd4peError error={state.createSshKeyError} />
      <Cd4peError error={state.deleteSshKeyError} />

      {body(workspaceId, state, dispatch, t)}
    </div>
  );
};

export default SshKey;
