import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import GroupPrivilegesForm from '@codeDelivery/components/GroupPrivilegesForm';
import Breadcrumbs from '@components/Breadcrumbs';
import Cd4peError from '@components/Cd4peError';
import PageLayout from '@components/PageLayout';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import useWorkspaceName from '@hooks/useWorkspaceName';
import {
  Heading,
  Loading,
  Text,
  Button,
  Tabs,
  TooltipHoverArea,
} from '@puppet/react-components';
import { useGetGroupV1 } from '@services/cd4pe/groups';
import { LINKS } from 'src/routes';
import UserTab from './components/UserTab';

const ViewGroup = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const { groupId } = useParams() as { groupId: string };
  const [permEditMode, setPermEditMode] = useState(false);

  const {
    data: group,
    error,
    isLoading,
    isSuccess,
  } = useGetGroupV1({ workspaceId, groupId: +groupId });

  const breadcrumbs = [
    { displayName: `${workspaceName}` },
    {
      displayName: t('viewGroup.breadcrumbs.settings'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('viewGroup.breadcrumbs.groupsPage'),
      linkDestination: LINKS.settings.listGroups({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: group?.name ?? '',
    },
  ];

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{group?.name ?? ''}</Heading>
        <Text>{group?.description}</Text>
      </PageLayout.Header>
      <PageLayout.Content className="view-group__content">
        {isSuccess && group && (
          <Tabs className="view-group__tabs">
            <Tabs.Tab title={t('viewGroup.tabs.users')}>
              <UserTab groupId={+groupId} />
            </Tabs.Tab>
            <Tabs.Tab title={t('viewGroup.tabs.permissions')}>
              {!permEditMode && (
                <div className="view-group-tab__header">
                  <TooltipHoverArea
                    disabled={!group.builtIn}
                    tooltip={t('viewGroup.permissions.edit.builtIn.tooltip')}
                  >
                    <Button
                      type="secondary"
                      icon="pencil"
                      onClick={() => setPermEditMode(true)}
                      disabled={group.builtIn}
                    >
                      {t('viewGroup.permissions.edit.label')}
                    </Button>
                  </TooltipHoverArea>
                </div>
              )}
              <GroupPrivilegesForm
                editable={permEditMode}
                groupId={group.id}
                onCancel={() => setPermEditMode(false)}
                onSuccess={() => setPermEditMode(false)}
              />
            </Tabs.Tab>
          </Tabs>
        )}
        {isLoading && <Loading data-testid="view-group-loading" />}
        <Cd4peError error={error} />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default ViewGroup;
