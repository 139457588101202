import { Link } from '@puppet/react-components';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { LINKS } from 'src/routes';
import useWorkspaceName from '@hooks/useWorkspaceName';

const GroupLink = ({ id, name }: { id: number; name: string }) => {
  const workspace = useWorkspaceName();

  return (
    <Link
      className="group-list-table__cell-link"
      as={NavLink}
      to={LINKS.settings.viewGroup({
        path: { workspace, groupId: `${id}` },
      })}
    >
      {name}
    </Link>
  );
};

export default GroupLink;
