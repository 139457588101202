import React from 'react';
import Breadcrumbs from '@components/Breadcrumbs';
import { Button, Heading } from '@puppet/react-components';
import PageLayout from '@components/PageLayout';
import { BreadcrumbsType } from '@components/Breadcrumbs/Breadcrumbs';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LINKS } from 'src/routes';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useListModulesV1, useDeleteModuleV1 } from '@services/cd4pe/modules';
import ListCodeProjects from '@codeDelivery/components/ListCodeProjects';

const ListModules = () => {
  const { t } = useTranslation('codeDelivery');
  const navigate = useNavigate();
  const workspaceName = useWorkspaceName();
  const [prefix, setPrefix] = React.useState('');
  const workspaceId = useWorkspaceDomain();
  const modules = useListModulesV1({
    workspaceId,
    prefix,
  });
  const deleteModule = useDeleteModuleV1();

  const breadcrumbs: BreadcrumbsType = [
    {
      displayName: workspaceName,
    },
    {
      displayName: t('listModules.breadcrumb.modules'),
    },
  ];

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <div className="listModules__header">
          <Heading>{t('listModules.header.title')}</Heading>
          <Button
            data-testid="list-modules-add-new-button"
            onClick={() =>
              navigate(
                LINKS.codeDelivery.newModule({
                  path: { workspace: workspaceName },
                }),
              )
            }
          >
            {t('listModules.header.newModule')}
          </Button>
        </div>
      </PageLayout.Header>
      <PageLayout.Content>
        <ListCodeProjects
          translationSuffix="module"
          projectsByPage={
            modules.data?.pages?.map((page) => page.modules) ?? []
          }
          infiniteQueryDetails={modules}
          getProjectLink={(name) =>
            LINKS.codeDelivery.viewModule({
              path: { workspace: workspaceName, name },
            })
          }
          onDelete={(name, onSuccess: () => void) => {
            deleteModule.mutate(
              {
                workspaceId,
                moduleName: name,
              },
              {
                onSuccess,
              },
            );
          }}
          deleteError={deleteModule.error}
          prefix={prefix}
          setPrefix={setPrefix}
        />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default ListModules;
