import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Heading, Link as PdsLink, Text } from '@puppet/react-components';
import { LINKS } from 'src/routes';
import { useSignOut } from '@services/cd4pe/auth';
import session from '@state/session/session';
import { useDispatch } from 'react-redux';

interface Props {
  code: '404' | '403' | 'generic';
}

const AccessError = ({ code }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const signOut = useSignOut();
  const dispatch = useDispatch();

  const logout = async () => {
    const didSignOut = await signOut.mutateAsync();

    if (didSignOut) {
      dispatch(session.actions.resetSession());
      navigate(LINKS.login({}));
    }
  };

  return (
    <div className="access-error">
      <div className="access-error-icon">
        <img src="/public/images/error_robot_logo.svg" alt="Error Robot Logo" />
      </div>
      <Heading>
        {params.get('msg') ?? t(`accessError.messages.${code}`)}
      </Heading>
      {code === '404' && (
        <Text>{t(`accessError.messages.description.${code}`)}</Text>
      )}
      <div className="access-error-buttons">
        {code !== 'generic' && (
          <PdsLink as={Link} onClick={() => navigate(-1)}>
            {t('accessError.buttons.back')}
          </PdsLink>
        )}
        {code === '403' && (
          <PdsLink as={Link} onClick={logout}>
            {t('accessError.buttons.login')}
          </PdsLink>
        )}
        <PdsLink as={Link} to="/">
          {t('accessError.buttons.home')}
        </PdsLink>
      </div>
    </div>
  );
};

export default AccessError;
