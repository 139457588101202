import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text, Form, Link as PdsLink } from '@puppet/react-components';
import { Authentication } from '@puppet/react-layouts';
import CopyrightFooter from '@components/CopyrightFooter';
import { useForgotPasswordV1 } from '@services/cd4pe/users';
import ConditionalRender from '@inventory/components/ConditionalRender';
import { LINKS } from 'src/routes';
import { Link } from 'react-router-dom';
import FormError from '@components/FormError';

const ForgotPassword = () => {
  const { t } = useTranslation('codeDelivery');
  const submitForgotPassword = useForgotPasswordV1();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit = async (formData: { email: string }) => {
    submitForgotPassword.mutate(
      {
        requestBody: { email: formData.email },
      },
      {
        onSuccess: () => {
          setFormSubmitted(true);
        },
      },
    );
  };

  return (
    <Authentication
      className="forgotPassword"
      title={
        formSubmitted
          ? t('forgotPassword.submitted.header')
          : t('forgotPassword.header')
      }
      product="continuous-delivery"
      footer={<CopyrightFooter />}
    >
      <ConditionalRender enable={formSubmitted}>
        <Text className="forgotPassword__description" color="medium">
          {t('forgotPassword.submitted.description')}
        </Text>
      </ConditionalRender>
      <ConditionalRender enable={!formSubmitted}>
        <Text className="forgotPassword__description" color="medium">
          {t('forgotPassword.description')}
        </Text>
        <Form
          className="forgotPassword__form"
          data-testid="forgot-password-form"
          submittable
          actionsPosition="block"
          submitLabel={t(`forgotPassword.button`)}
          onSubmit={(values: { email: string }) => {
            onSubmit(values);
          }}
          submitting={submitForgotPassword.isLoading}
        >
          <Form.Field
            type="email"
            name="email"
            label={t(`forgotPassword.form.email.label`)}
            required
            requiredFieldMessage={t(`forgotPassword.form.email.required`)}
            placeholder={t(`forgotPassword.form.email.placeholder`)}
            data-testid="forgot-password-email-field"
          />
        </Form>
        {submitForgotPassword.error && (
          <FormError message={submitForgotPassword.error.body.message} />
        )}
      </ConditionalRender>
      <div className="links__return-to-signin">
        <Trans
          t={t}
          i18nKey="forgotPassword.form.links.returnToSignin"
          components={[
            <Text color="medium" />,
            <PdsLink as={Link} to={LINKS.login({})} />,
          ]}
        />
      </div>
    </Authentication>
  );
};

export default ForgotPassword;
