import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OptionsArray } from 'utils/en.propTypes';
import './NodeTableFilter.scss';
import { useTranslation } from 'react-i18next';
import { Form } from '@puppet/react-components';

const ComplexOperatorFilter = ({
  onCancel,
  onSubmit,
  name,
  placeholder,
  label,
  defaultOptions,
  filterValue,
  options,
}) => {
  const { t } = useTranslation('inventory');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isSubmittable, setIsSubmittable] = useState(false);

  useEffect(() => {
    if (options.autoComplete) {
      setFilteredOptions(options.autoComplete);
    }
  }, [options]);

  const handleFactValueFilter = (searchTerm) => {
    if (options.autoComplete) {
      const newFilteredFacts = options.autoComplete.filter((listOptions) => {
        return listOptions.label
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setFilteredOptions(newFilteredFacts);
    }
  };

  const canSubmit = (selectedFilter, change) => {
    const { autoComplete, textInput } = name;
    if (autoComplete) {
      setIsSubmittable(
        change[autoComplete]?.length > 0 && change[textInput]?.length > 0,
      );
    } else {
      setIsSubmittable(change[textInput]?.length > 0);
    }
  };
  const getOptionValues = (listOptions, value) => {
    const selectedOption = listOptions.find((option) => option.value === value);

    return {
      label: selectedOption ? selectedOption.label : '',
      value: selectedOption ? selectedOption.value : '',
    };
  };

  const presenceCheck = (submittedValues) => {
    const autoCompleteCheck = name.autoComplete
      ? submittedValues[name.autoComplete]
      : false;
    const textCheck = submittedValues[name.textInput];
    return (
      (name.autoComplete && Boolean(autoCompleteCheck) === false) ||
      Boolean(textCheck) === false
    );
  };

  const getFilterObj = (submittedValues, operatorFilter, factNameFilter) => {
    const operatorFilterValue = filterValue?.operator[operatorFilter?.value];
    const filterObject = {
      name: factNameFilter.value,
      [operatorFilterValue.value]: submittedValues[name.textInput],
    };
    if (operatorFilterValue.not) {
      return {
        NOT: [{ facts: filterObject }],
      };
    }

    return { facts: filterObject };
  };

  const assembleFilterValue = (submittedValues) => {
    const errors = presenceCheck(submittedValues);
    if (!errors) {
      const factNameFilter = name.autoComplete
        ? getOptionValues(
            options.autoComplete,
            submittedValues[name.autoComplete],
          )
        : defaultOptions.autoComplete;
      const operatorFilter = submittedValues[name.operator]
        ? getOptionValues(options.operator, submittedValues[name.operator])
        : defaultOptions.operator;
      onSubmit({
        fact: name.autoComplete ? factNameFilter.label : null,
        operator: operatorFilter.label,
        value: submittedValues[name.textInput],
        filter: getFilterObj(submittedValues, operatorFilter, factNameFilter),
      });
    }
  };

  return (
    <Form
      submittable
      cancellable
      actionsPosition="left"
      submitType="secondary"
      cancelType="text"
      onSubmit={assembleFilterValue}
      submitDisabled={!isSubmittable}
      onCancel={onCancel}
      onChange={canSubmit}
      submitLabel={t('nodeTable.filter.add.button')}
    >
      {name.autoComplete && (
        <Form.Field
          data-testid="ComplexOperatorFilter-autocomplete"
          type="autocomplete"
          name={name.autoComplete}
          label={t(label.autoComplete)}
          placeholder={t(placeholder.autoComplete)}
          options={filteredOptions}
          onFilter={handleFactValueFilter}
        />
      )}
      <Form.Field
        data-testid="ComplexOperatorFilter-select"
        type="select"
        name={name.operator}
        label={t(label.operator)}
        placeholder={t(placeholder.operator)}
        options={options.operator || []}
      />
      <Form.Field
        data-testid="ComplexOperatorFilter-textInput"
        type="text"
        name={name.textInput}
        label={t(label.textInput)}
        placeholder={t(placeholder.textInput)}
        description={t('nodeTable.filter.validation.caseSensitive')}
      />
    </Form>
  );
};

export default ComplexOperatorFilter;

ComplexOperatorFilter.propTypes = {
  name: PropTypes.shape({
    autoComplete: PropTypes.string,
    operator: PropTypes.string,
    textInput: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.shape({
    autoComplete: PropTypes.string,
    operator: PropTypes.string,
    textInput: PropTypes.string,
  }).isRequired,
  label: PropTypes.shape({
    autoComplete: PropTypes.string,
    operator: PropTypes.string,
    textInput: PropTypes.string,
  }).isRequired,
  defaultOptions: PropTypes.shape({
    autoComplete: OptionsArray,
    operator: OptionsArray,
  }).isRequired,
  filterValue: PropTypes.shape({
    operator: OptionsArray,
  }).isRequired,
  options: PropTypes.shape({
    autoComplete: OptionsArray,
    operator: OptionsArray,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
