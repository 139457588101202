import React from 'react';
import { Button, Modal, Text } from '@puppet/react-components';
import {
  CodeProjectDetailsV1,
  TriggerImpactAnalysisRequestV1,
} from '@utils/api/cd4pe';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useTranslation } from 'react-i18next';
import Cd4peError from '@components/Cd4peError';
import { useTriggerImpactAnalysisV1 } from '@services/cd4pe/impactAnalysis';
import {
  PEImpactAnalysisEnvironmentV1,
  ProjectTypeV1,
} from '@puppet/cd4pe-client-ts';
import { LINKS } from 'src/routes';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import ImpactAnalysisForm, {
  ImpactAnalysis,
} from '../ImpactAnalysisForm/ImpactAnalysisForm';

interface Props {
  codeProject: CodeProjectDetailsV1;
  projectType: ProjectTypeV1;
  onClose: () => void;
}

const ImpactAnalysisDialog = ({ codeProject, projectType, onClose }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const workspaceName = useWorkspaceName();
  const appDispatch = useAppDispatch();

  const triggerImpactAnalysis = useTriggerImpactAnalysisV1();

  const buildImpactAnalysisRequest = (
    impactAnalysis: ImpactAnalysis,
  ): TriggerImpactAnalysisRequestV1 => {
    const peCredentialsId = {
      name: impactAnalysis.puppetEnterpriseServer?.name ?? '',
      domain: workspaceId,
    };

    const environment: PEImpactAnalysisEnvironmentV1 = {
      nodeGroupId: impactAnalysis.environment?.id,
      peCredentialsId,
    };

    if (projectType === 'MODULE') {
      environment.moduleImpactAnalysisControlRepoId = {
        name: impactAnalysis.controlRepo ?? '',
        domain: workspaceId,
      };
    }

    const settings = {
      environments: [environment],
      sourceBranch: impactAnalysis.branch,
      sourceSha: impactAnalysis.commit,
      analyzeAllDeployments: false,
      compileBatchSize: 10,
      [projectType === 'CONTROL_REPO' ? 'controlRepoId' : 'moduleId']: {
        name: codeProject.name,
        domain: workspaceId,
      },
    };

    return {
      workspaceId,
      projectName: codeProject.name,
      projectType,
      settings,
    };
  };

  const onImpactAnalysis = (impactAnalysis: ImpactAnalysis) => {
    if (!triggerImpactAnalysis.isSuccess) {
      triggerImpactAnalysis.mutate(
        {
          requestBody: buildImpactAnalysisRequest(impactAnalysis),
        },
        {
          onSuccess: () => {
            appDispatch(
              alerts.actions.createAlert({
                type: 'Success',
                message: t(
                  'viewPipeline.dialog.manualImpactAnalysis.alert.success',
                ),
              }),
            );
          },
        },
      );
    } else {
      window.location.href = LINKS.cd4pe.viewImpactAnalysis({
        path: {
          workspace: workspaceName,
          id: triggerImpactAnalysis.data?.id!,
        },
      });
    }
  };

  return (
    <Modal className="manual-impactAnalysis-dialog" onClose={() => onClose()}>
      <Modal.Title>
        {t('viewPipeline.dialog.manualImpactAnalysis.title')}
      </Modal.Title>
      <ImpactAnalysisForm
        type="manual"
        projectType={projectType}
        codeProject={codeProject}
        onSubmit={onImpactAnalysis}
        onCancel={() => onClose()}
        disabled={triggerImpactAnalysis.isSuccess}
        submittable={!triggerImpactAnalysis.isSuccess}
        cancellable={!triggerImpactAnalysis.isSuccess}
        submitting={triggerImpactAnalysis.isLoading}
        submitKey={t('viewPipeline.dialog.manualImpactAnalysis.analyse')}
        cancelKey={t('viewPipeline.dialog.manualImpactAnalysis.cancel')}
      />
      {triggerImpactAnalysis.isSuccess && (
        <Modal.Actions>
          <Text className="analysis-in-progress-message">
            {t('viewPipeline.form.impactAnalysis.inProgress')}
          </Text>
          <Button type="primary" onClick={onImpactAnalysis}>
            {t('viewPipeline.dialog.manualImpactAnalysis.viewImpactAnalysis')}
          </Button>
          <Button type="tertiary" onClick={onClose}>
            {t('viewPipeline.dialog.manualImpactAnalysis.done')}
          </Button>
        </Modal.Actions>
      )}
      <Cd4peError error={triggerImpactAnalysis.error} />
    </Modal>
  );
};

export default ImpactAnalysisDialog;
