import actionCreator, { Actions } from '@utils/actionCreator';
import {
  Cd4peApiError,
  CreateSecretRequestV1,
  EditSecretRequestV1,
  handleApiRequest,
  isCd4peApiError,
  JobsV1Service,
  JobTemplateV1,
  SecretDetailsV1,
  SecretNameV1,
  SecretsV1Service,
} from '@utils/api/cd4pe';
import { Dispatch } from 'react';
import * as at from './actionTypes';

const codeDeliveryActions = {
  createJobSecretLoading: () => actionCreator(at.CREATE_JOB_SECRET_LOADING),
  createJobSecretComplete: (secretDetails: SecretDetailsV1) =>
    actionCreator(at.CREATE_JOB_SECRET_COMPLETE, secretDetails),
  createJobSecretError: (error: Cd4peApiError) =>
    actionCreator(at.CREATE_JOB_SECRET_ERROR, error),
  getJobSecretsLoading: () => actionCreator(at.GET_JOB_SECRETS_LOADING),
  getJobSecretsComplete: (secrets: SecretDetailsV1[]) =>
    actionCreator(at.GET_JOB_SECRETS_COMPLETE, secrets),
  getJobSecretsError: (error: Cd4peApiError) =>
    actionCreator(at.GET_JOB_SECRETS_ERROR, error),
  editJobSecretLoading: () => actionCreator(at.EDIT_JOB_SECRET_LOADING),
  editJobSecretComplete: (secretDetails: SecretDetailsV1) =>
    actionCreator(at.EDIT_JOB_SECRET_COMPLETE, secretDetails),
  editJobSecretError: (error: Cd4peApiError) =>
    actionCreator(at.EDIT_JOB_SECRET_ERROR, error),
  deletJobSecretLoading: () => actionCreator(at.DELETE_JOB_SECRET_LOADING),
  deleteJobSecretComplete: (secretName: string) =>
    actionCreator(at.DELETE_JOB_SECRET_COMPLETE, secretName),
  deleteJobSecretError: (error: Cd4peApiError) =>
    actionCreator(at.DELETE_JOB_SECRET_ERROR, error),
};

export type CodeDeliveryActions = Actions<typeof codeDeliveryActions>;

export const createJobSecret = async <T>(
  { workspaceId, id }: JobTemplateV1,
  formValues: CreateSecretRequestV1,
  dispatch: Dispatch<T | CodeDeliveryActions>,
) => {
  dispatch(codeDeliveryActions.createJobSecretLoading());

  const createResponse = await handleApiRequest(
    SecretsV1Service.createSecretV1({
      workspaceId,
      requestBody: formValues,
    }),
  );

  if (isCd4peApiError(createResponse)) {
    dispatch(codeDeliveryActions.createJobSecretError(createResponse));
    return;
  }

  const accessResponse = await handleApiRequest(
    JobsV1Service.addJobTemplateSecretAccessV1({
      workspaceId,
      id,
      requestBody: { secretName: formValues.name },
    }),
  );

  if (isCd4peApiError(accessResponse)) {
    dispatch(codeDeliveryActions.createJobSecretError(accessResponse));
    return;
  }

  dispatch(codeDeliveryActions.createJobSecretComplete(createResponse));
};

export const listJobSecrets = async <T>(
  { workspaceId, id }: JobTemplateV1,
  dispatch: Dispatch<T | CodeDeliveryActions>,
) => {
  dispatch(codeDeliveryActions.getJobSecretsLoading());

  const response = await handleApiRequest(
    JobsV1Service.listJobTemplateSecretsV1({
      workspaceId,
      id,
    }),
  );

  if (isCd4peApiError(response)) {
    dispatch(codeDeliveryActions.getJobSecretsError(response));
    return;
  }

  dispatch(
    codeDeliveryActions.getJobSecretsComplete(response.jobTemplateSecrets),
  );
};

export const editJobSecret = async <T>(
  workspaceId: string,
  secretName: SecretNameV1,
  formValues: EditSecretRequestV1,
  dispatch: Dispatch<T | CodeDeliveryActions>,
) => {
  dispatch(codeDeliveryActions.editJobSecretLoading());

  const editResponse = await handleApiRequest(
    SecretsV1Service.replaceSecretV1({
      workspaceId,
      secretName,
      requestBody: formValues,
    }),
  );

  if (isCd4peApiError(editResponse)) {
    dispatch(codeDeliveryActions.editJobSecretError(editResponse));
    return;
  }

  dispatch(codeDeliveryActions.editJobSecretComplete(editResponse));
};

export const deleteJobSecret = async <T>(
  { workspaceId }: JobTemplateV1,
  secretName: SecretNameV1,
  dispatch: Dispatch<T | CodeDeliveryActions>,
) => {
  dispatch(codeDeliveryActions.deletJobSecretLoading());

  const deleteResponse = await handleApiRequest(
    SecretsV1Service.deleteSecretV1({ workspaceId, secretName }),
  );

  if (isCd4peApiError(deleteResponse)) {
    dispatch(codeDeliveryActions.deleteJobSecretError(deleteResponse));
    return;
  }

  dispatch(codeDeliveryActions.deleteJobSecretComplete(secretName));
};
