import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@puppet/react-components';

import './NodeStatus.scss';

const NodeStatus = ({ status }) => {
  const { t } = useTranslation('inventory');

  switch (status) {
    case 'no change': {
      return (
        <span className="node-status">
          <Icon role="img" type="check-circle" size="medium" />
          {t('nodeStatus.unchanged')}
        </span>
      );
    }
    case 'corrective change': {
      return (
        <span className="node-status">
          <Icon
            role="img"
            svg={
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C12.411 0 16 3.589 16 8C16 12.411 12.411 16 8 16C3.589 16 0 12.411 0 8C0 3.589 3.589 0 8 0ZM8 14C11.309 14 14 11.309 14 8C14 4.691 11.309 2 8 2C4.691 2 2 4.691 2 8C2 11.309 4.691 14 8 14ZM8 12V4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12Z"
                fill="#DB9F1F"
              />
            }
            viewBox="0 0 16 16"
          />
          {t('nodeStatus.corrective')}
        </span>
      );
    }
    case 'intentional change': {
      return (
        <span className="node-status">
          <Icon
            role="img"
            svg={
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C12.411 0 16 3.589 16 8C16 12.411 12.411 16 8 16C3.589 16 0 12.411 0 8C0 3.589 3.589 0 8 0ZM8 14C11.309 14 14 11.309 14 8C14 4.691 11.309 2 8 2C4.691 2 2 4.691 2 8C2 11.309 4.691 14 8 14ZM8 12V4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12Z"
                fill="#318CF5"
              />
            }
            viewBox="0 0 16 16"
          />
          {t('nodeStatus.intentional')}
        </span>
      );
    }
    case 'failure': {
      return (
        <span className="node-status">
          <Icon role="img" type="alert" size="medium" />
          {t('nodeStatus.failed')}
        </span>
      );
    }
    default:
      return (
        <span className="node-status">
          <Icon role="img" type="question-circle" size="medium" />
        </span>
      );
  }
};

export default NodeStatus;

NodeStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
