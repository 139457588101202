/**
 * Returns function that will only be called after the debounce time has elapsed. If
 * the function is repeatedly called before the time has elapsed, the timer will
 * reset.
 * @param {function} func         Function to debounce
 * @param {number}   debounceTime Time to wait, in milliseconds, before calling func
 */
const debounce = (func, debounceTime) => {
  let timeout = null;
  return (...args) => {
    const later = () => {
      timeout = null;
      func.apply({}, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, debounceTime);
  };
};

export default debounce;
