export const PRIVILEGE_CATEGORY_ORDER = [
  'ControlRepos',
  'Modules',
  'Jobs',
  'Users',
  'Groups',
  'Integrations',
  'Inventory',
];

export const PRIVILEGE_ACTION_ORDER = [
  'CREATE',
  'READ',
  'WRITE',
  'DELETE',
  'PROMOTE',
  'RUN',
];

export const SUBSETS_SUPPORTED_CATEGORIES = ['ControlRepos', 'Modules'];

export const SUBSETS_SUPPORTED_ACTIONS = ['READ', 'WRITE', 'DELETE'];

// eslint-disable-next-line no-shadow
export enum ACTION_STATE {
  ALL = 'all',
  NONE = 'none',
  SOME = 'some',
  ANY = 'any',
}
