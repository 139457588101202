// Function takes the puppet server version value of a source and uses it with the
// source value and hash value to generate the appropriate link to the pe console
import compareVersions from 'compare-versions';

function generateReportURL(version, source, hash) {
  const useNewURL = Boolean(
    version !== '' && compareVersions.compare(version, '2019.7', '>='),
  );

  return `https://${source}/#/${
    useNewURL ? 'enforcement' : 'inspect'
  }/report/${hash}`;
}

export default generateReportURL;
