import React from 'react';
import { Loading } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useListPeIntegrationsV2 } from '@services/cd4pe/peIntegrations';
import Cd4peError from '@components/Cd4peError';
import NoPeIntegrations from './components/NoPeIntegrations';
import PeIntegrations from './components/PeIntegrations';
import PeHeading from './components/PeHeading';

const PeIntegrationList = () => {
  const workspaceId = useWorkspaceDomain();

  const { isLoading, error, data } = useListPeIntegrationsV2({ workspaceId });

  return (
    <div className="pe-list">
      <PeHeading />
      <div className="pe-list-body">
        <Cd4peError error={error} />
        {isLoading && <Loading data-testid="pe-list-spinner" size="small" />}
        {data?.peIntegrations?.length === 0 && <NoPeIntegrations />}
        {data && data?.peIntegrations?.length > 0 && (
          <PeIntegrations data={data.peIntegrations} />
        )}
      </div>
    </div>
  );
};

export default PeIntegrationList;
