import actionCreator, { Actions } from '@utils/actionCreator';
import { stringCompare } from '@utils/compare';
import {
  formatError,
  isErrorResponse,
  unwrapApiResponse,
  ListWorkspaceMembersResponseV1,
  WorkspacesV1Service,
  WorkspaceMemberV1,
} from '@utils/api/cd4pe';
import { Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import * as at from './actionTypes';

const actions = {
  getUsersLoading: () => actionCreator(at.GET_USERS_LOADING),
  getUsersComplete: (users: ListWorkspaceMembersResponseV1) =>
    actionCreator(at.GET_USERS_COMPLETE, users),
  getUsersError: (error: string) => actionCreator(at.GET_USERS_ERROR, error),
  deleteUserLoading: () => actionCreator(at.DELETE_USER_LOADING),
  deleteUserComplete: (
    newUsers: ListWorkspaceMembersResponseV1['workspaceMembers'],
  ) => actionCreator(at.DELETE_USER_COMPLETE, newUsers),
  deleteUserError: (error: string) =>
    actionCreator(at.DELETE_USER_ERROR, error),
  toggleConfirmDelete: (toggle: number | null) =>
    actionCreator(at.TOGGLE_CONFIRM_DELETE, toggle),
  filterUsers: (filteredPayload: {
    query: string;
    filteredUsers: ListWorkspaceMembersResponseV1['workspaceMembers'];
  }) => actionCreator(at.FILTER_USERS, filteredPayload),
  sortUsers: (sortedPayload: {
    sort: { sortDataKey: 'user'; direction: string };
    users: ListWorkspaceMembersResponseV1['workspaceMembers'];
  }) => actionCreator(at.SORT_USERS, sortedPayload),
};

export type UserListActions = Actions<typeof actions>;

export const filterUsers = (
  query: string,
  users: ListWorkspaceMembersResponseV1['workspaceMembers'],
  dispatch: Dispatch<UserListActions>,
) => {
  let userList;
  if (query === '') {
    userList = users;
  } else {
    userList = users.filter((user) => {
      return user.username.toLowerCase().includes(query.toLowerCase());
    });
  }

  dispatch(
    actions.filterUsers({
      query,
      filteredUsers: userList,
    }),
  );
};

export const toggleConfirmDelete = (
  dispatch: Dispatch<UserListActions>,
  userId: number | null = null,
) => {
  dispatch(actions.toggleConfirmDelete(userId));
};

export const USER_SORTS = {
  user: (
    { username: a }: WorkspaceMemberV1,
    { username: b }: WorkspaceMemberV1,
  ) => stringCompare(a, b),
};

export const sortUsers = (
  direction: string,
  sortDataKey: 'user',
  users: ListWorkspaceMembersResponseV1['workspaceMembers'],
  query: string,
  dispatch: Dispatch<UserListActions>,
) => {
  const multiplier = direction === 'asc' ? 1 : -1;
  const sortedUsers = [...users].sort(
    (a, b) => multiplier * USER_SORTS[sortDataKey](a, b),
  );

  dispatch(
    actions.sortUsers({
      users: sortedUsers,
      sort: { direction, sortDataKey },
    }),
  );

  return filterUsers(query, sortedUsers, dispatch);
};

export const getUsers = async (
  workspaceId: string,
  query: string,
  sort: { direction: string; sortDataKey: 'user' },
  dispatch: Dispatch<UserListActions>,
  t: TFunction<'codeDelivery'>,
) => {
  dispatch(actions.getUsersLoading());

  const response = await unwrapApiResponse(
    WorkspacesV1Service.listMembersV1({ workspaceId, pageSize: 1000 }),
  );

  if (isErrorResponse(response)) {
    dispatch(
      actions.getUsersError(formatError(response, t('userList.error.fetch'))),
    );
    return;
  }

  dispatch(actions.getUsersComplete(response));
  sortUsers(
    sort.direction,
    sort.sortDataKey,
    response.workspaceMembers,
    query,
    dispatch,
  );
};

export const deleteUser = async (
  workspaceId: string,
  confirmDeleteId: number,
  users: ListWorkspaceMembersResponseV1['workspaceMembers'],
  query: string,
  sort: { direction: string; sortDataKey: 'user' },
  dispatch: Dispatch<UserListActions>,
  t: TFunction<'codeDelivery'>,
) => {
  dispatch(actions.deleteUserLoading());

  const response = await unwrapApiResponse(
    WorkspacesV1Service.deleteMemberV1({
      workspaceId,
      userId: confirmDeleteId,
    }),
  );

  if (isErrorResponse(response)) {
    dispatch(
      actions.deleteUserError(
        formatError(
          response,
          t('userList.error.delete', { id: confirmDeleteId }),
        ),
      ),
    );
    return;
  }

  const newUsers = users.filter((user) => user.userId !== confirmDeleteId);
  dispatch(actions.deleteUserComplete(newUsers));
  sortUsers(sort.direction, sort.sortDataKey, newUsers, query, dispatch);
};
