import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button, Card, Input, Text } from '@puppet/react-components';
import { ProjectPipelineStageV1 } from '@puppet/cd4pe-client-ts';
import ConditionalRender from '@components/ConditionalRender';
import { PIPELINE_STAGE_OPTIONS } from '../../../../utils';
import StageCardItem from '../StageCardItem';
import { EditHandlerType } from '../../Stages';

interface Props {
  stage: ProjectPipelineStageV1;
  manageAsCode?: boolean;
  onAddStageItem?: (stageNumber: number) => void;
  onAddStageBefore?: (stageNumber: number) => void;
  onAddStageAfter?: (stageNumber: number) => void;
  onRename?: (name: string) => void;
  onDeleteJob?: (id: string) => void;
  onDeleteStage?: (stageNum: number) => void;
  onReorderPipeline?: () => void;
  onDeletePlaceholder?: () => void;
  requiresAction?: 'DEPLOYMENT_WARNING';
  reordering?: boolean;
  reordered?: boolean;
  getOnEditItemHandler?: EditHandlerType;
  jobsNotToDelete?: (id: string) => string[];
  isRegex?: boolean;
}

const StageCard = ({
  stage,
  manageAsCode = false,
  onAddStageItem = () => {},
  onAddStageBefore = () => {},
  onAddStageAfter = () => {},
  onRename = () => {},
  onDeleteJob = () => {},
  onDeleteStage = () => {},
  onReorderPipeline = () => {},
  onDeletePlaceholder = () => {},
  requiresAction,
  reordering = false,
  reordered = false,
  getOnEditItemHandler = () => undefined,
  jobsNotToDelete = () => [],
  isRegex = false,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [name, setName] = useState(stage.stageName);
  const [renameCheck, setRenameCheck] = useState(false);

  useEffect(() => {
    if (name !== stage.stageName) {
      setName(stage.stageName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage.stageName]);

  const displayRenameForm = () => (
    <div className="stage-card-title__rename">
      <Input
        data-testid="rename-stage"
        name="rename-stage"
        type="text"
        value={name}
        onChange={(value: string) => setName(value)}
      />
      <Button
        className="stage-card-title__rename-button"
        type="text"
        onClick={() => {
          if (name !== '' && name !== undefined) {
            onRename(name);
            setName(name);
            setRenameCheck(false);
          }
        }}
        data-testid="rename-stage-save-button"
      >
        {t('viewPipeline.stage.card.rename.stage.button.save')}
      </Button>
      <Button
        className="stage-card-title__rename-button"
        type="text"
        onClick={() => {
          setRenameCheck(false);
          setName(stage.stageName);
        }}
      >
        {t('viewPipeline.stage.card.rename.stage.button.cancel')}
      </Button>
    </div>
  );

  const options = [
    {
      id: PIPELINE_STAGE_OPTIONS.ADD_ITEM_TO_STAGE,
      label: t('viewPipeline.stage.dropdown.add.item.to.stage'),
      onClick: () => {
        onAddStageItem(stage.stageNum);
      },
    },
    {
      id: PIPELINE_STAGE_OPTIONS.ADD_STAGE_BEFORE,
      label: t('viewPipeline.stage.dropdown.add.stage.before'),
      onClick: () => onAddStageBefore(stage.stageNum),
    },
    {
      id: PIPELINE_STAGE_OPTIONS.ADD_STAGE_AFTER,
      label: t('viewPipeline.stage.dropdown.add.stage.after'),
      onClick: () => onAddStageAfter(stage.stageNum),
    },
    {
      id: PIPELINE_STAGE_OPTIONS.REORDER_PIPELINE,
      label: t('viewPipeline.stage.dropdown.reorder.pipeline'),
      onClick: () => {
        onReorderPipeline();
      },
    },
    {
      id: PIPELINE_STAGE_OPTIONS.DELETE_STAGE,
      label: t('viewPipeline.stage.dropdown.delete.stage'),
      onClick: () => {
        onDeleteStage(stage.stageNum!);
      },
    },
    {
      id: PIPELINE_STAGE_OPTIONS.RENAME_STAGE,
      label: t('viewPipeline.stage.dropdown.rename.stage'),
      onClick: () => {
        setRenameCheck(true);
      },
    },
  ];

  const isPlaceholder = stage.destinations.some(
    (destination) => 'placeholder' in destination,
  );

  return (
    <Card
      className={classnames('stage-card', {
        'stage-card--placeholder': isPlaceholder,
      })}
      elevation={50}
      data-testid={`stage-card-${stage.stageNum}`}
    >
      <Card.Title className="stage-card-title" data-testid="stage-card-title">
        <ConditionalRender enable={!renameCheck}>
          <div className="stage-card-title__text">
            <span>
              {name ||
                t('viewPipeline.stage.card.name.stageNumber', {
                  stageNumber: stage.stageNum,
                })}
            </span>
            {reordered && (
              <Text
                color="subtle"
                size="tiny"
                className="stage-card-title__text-previous-stage"
                data-testid="stage-card-previous-title"
              >
                <strong>
                  {t('viewPipeline.stage.card.name.reorderedStageNumber', {
                    stageNumber: stage.stageNum,
                  })}
                </strong>
              </Text>
            )}
          </div>
        </ConditionalRender>
        <ConditionalRender enable={renameCheck}>
          {displayRenameForm()}
        </ConditionalRender>
      </Card.Title>
      {!manageAsCode && !reordering && (
        <Card.ActionSelect actions={options} data-testid="stage-card-actions" />
      )}
      {stage.destinations.map((destination, idx) => {
        const selectedDestination = {
          stageIndex: (stage.stageNum ?? 1) - 1, // -1 to get index
          destinationIndex: idx,
        };
        return (
          <StageCardItem
            key={`${destination.stageNum}-${destination.id}`}
            manageAsCode={manageAsCode}
            destination={destination}
            requiresAction={requiresAction}
            onDelete={() => onDeleteJob(destination.id!)}
            reordering={reordering}
            onEditItem={getOnEditItemHandler(
              destination.type,
              selectedDestination,
            )}
            onEditPlaceholder={() => {
              onAddStageItem(stage.stageNum);
            }}
            preventDeleteStage={jobsNotToDelete(destination.id!).length > 0}
            isRegex={isRegex}
          />
        );
      })}
      <ConditionalRender enable={!!stage.pipelineGate}>
        <StageCardItem
          key={`${stage.stageNum}-${stage.stageName}-pipeline-gate`}
          manageAsCode={manageAsCode}
          pipelineGate={stage.pipelineGate}
          onDelete={onDeletePlaceholder}
          reordering={reordering}
        />
      </ConditionalRender>
    </Card>
  );
};

export default StageCard;
