import React from 'react';
import DefinitionsEntry from './DefinitionsEntry';

interface Props
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {}

const hasIcons = (children: React.ReactNode) => {
  let result = false;
  React.Children.forEach(children, (c) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result = result || (c as any).props.icon !== null;
  });
  return result;
};

const Definitions = ({ children, className, ...props }: Props) => {
  const modifierClass = hasIcons(children) ? '' : 'definitions--no-icons';

  return (
    <div className={`definitions ${modifierClass} ${className}`} {...props}>
      {children}
    </div>
  );
};

Definitions.Entry = DefinitionsEntry;

Definitions.defaultProps = {
  className: '',
} as Partial<Props>;

export default Definitions;
