import ComplexOperatorFilter from 'inventory/components/NodeTableFilter/ComplexOperatorFilter';
import operator from './commonOptions/operator';

export default {
  component: ComplexOperatorFilter,
  operator,

  name: {
    operator: operator.name,
    textInput: 'textValue',
  },
  placeholder: {
    operator: operator.placeholder,
    textInput: 'nodeTable.filter.operatingSystem.textInput.placeholder',
  },
  label: {
    operator: operator.label,
    textInput: 'nodeTable.filter.operatingSystem.textInput.label',
  },
  options: {
    operator: operator.options,
  },
  defaultOptions: {
    operator: operator.default,
    autoComplete: { label: 'Operating System', value: 'operatingsystem' },
  },
  filterValue: {
    operator: operator.filterValue,
  },
};
