import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, ConfirmationModal } from '@puppet/react-components';

const PinViewModal = ({ onConfirm, name }) => {
  const { t } = useTranslation('inventory');

  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        type="transparent"
        icon="star-filled"
        onClick={() => setOpen(true)}
        data-testid={`stored-views-favorite-${name}`}
      />
      <ConfirmationModal
        title={t('storedView.pinViewModal.title')}
        description={t('storedView.pinViewModal.description')}
        confirmLabel={t('common.modal.button.remove')}
        cancelLabel={t('common.modal.button.cancel')}
        onConfirm={() => {
          onConfirm();
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
        isOpen={open}
      />
    </div>
  );
};
export default PinViewModal;

PinViewModal.propTypes = {
  onConfirm: func.isRequired,
  name: string.isRequired,
};
