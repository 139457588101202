import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Text,
  Loading,
  Heading,
} from '@puppet/react-components';
import { Table } from '@puppet/data-grid';
import { ProtectedEnvironmentApprovalGroupV2 } from '@utils/api/cd4pe';
import useWorkspaceName from '@hooks/useWorkspaceName';
import ConfirmDeleteModal from '@components/ConfirmDeleteModal';
import {
  useListProtectedEnvironmentsV2,
  useDeleteProtectedEnvironmentV2,
} from '@services/cd4pe/peIntegrations';
import Cd4peError from '@components/Cd4peError';
import { LINKS } from 'src/routes';

const FORBIDDEN_STATUS = 403;

interface EnvironmentsTableProps {
  peIntegrationId: string;
}

const ProtectedEnvironmentsTable = ({
  peIntegrationId,
}: EnvironmentsTableProps) => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const [selectedEnvironment, setSelectedEnvironment] = useState('');

  const { isLoading, error, data } = useListProtectedEnvironmentsV2({
    peIntegrationId,
  });

  const deleteProtectedEnvironment = useDeleteProtectedEnvironmentV2();

  const columns = [
    {
      label: t('peList.env.table.column.environment'),
      dataKey: 'environmentName',
    },
    {
      label: t('peList.env.table.column.groups'),
      dataKey: 'approvalGroups',
      cellRenderer: (args: {
        cellData: ProtectedEnvironmentApprovalGroupV2[];
      }) => {
        return args.cellData.map(({ groupName }) => groupName).join(', ');
      },
    },
    {
      label: t('peList.env.table.column.actions'),
      cellRenderer: (args: { rowData: { environmentName: string } }) => {
        return (
          <div className="protected-environments-table-actions">
            <Button
              as={Link}
              to={LINKS.settings.editProtectedEnvironment({
                path: {
                  workspace,
                  peId: peIntegrationId,
                  envName: args.rowData.environmentName,
                },
              })}
              icon="pencil"
              type="transparent"
              data-testid={`edit-protected-environment-${args.rowData.environmentName}`}
              aria-label={t('peList.protectedEnvironments.edit.ariaLabel')}
            />
            <Button
              icon="trash"
              type="transparent"
              onClick={() =>
                setSelectedEnvironment(args.rowData.environmentName)
              }
              data-testid={`remove-protected-environment-${args.rowData.environmentName}`}
              aria-label={t('peList.protectedEnvironments.delete.ariaLabel')}
            />
          </div>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <Loading
        data-testid="pe-protected-environments-spinner"
        className="pe-list-body-card-protected-environments-loading"
        size="tiny"
      />
    );
  }

  const renderHeader = () => {
    return (
      <>
        <div className="pe-list-body-card-protected-environments-header">
          <Heading as="h5"> {t('peList.env.table.title')}</Heading>
          <Button
            as={Link}
            disabled={error && error.status === FORBIDDEN_STATUS}
            to={LINKS.settings.addProtectedEnvironment({
              path: { workspace, peId: peIntegrationId },
            })}
            type="transparent"
            icon="plus-circle"
            className="icon"
            data-testid="add-protected-environment-btn"
          >
            {t('peList.env.table.add.button')}
          </Button>
        </div>
        <hr />
      </>
    );
  };

  const renderBody = () => {
    if (error?.status === FORBIDDEN_STATUS) {
      return (
        <Alert type="danger" className="forbidden-error">
          {t('peList.env.permission.forbidden')}
        </Alert>
      );
    }

    if (error) {
      return <Cd4peError error={error} />;
    }

    if (data?.protectedEnvironments?.length === 0) {
      return (
        <Text data-testid="protected-environments-none">
          {t('peList.env.none')}
        </Text>
      );
    }

    return (
      <Table
        data-testid="protected-environments-table"
        data={
          Array.isArray(data?.protectedEnvironments)
            ? data?.protectedEnvironments
            : []
        }
        columns={columns}
      />
    );
  };

  return (
    <div className="pe-list-body-card-protected-environments">
      {renderHeader()}
      {renderBody()}
      <ConfirmDeleteModal
        dataTestId="delete-protected-environments-modal-form"
        isOpen={selectedEnvironment !== ''}
        onCancel={() => setSelectedEnvironment('')}
        title={t('peList.deleteProtectedEnvironment.modal.title')}
        alert={t('peList.deleteProtectedEnvironment.modal.description', {
          name: selectedEnvironment,
        })}
        confirmLabel={t(
          'peList.deleteProtectedEnvironment.modal.button.confirm',
        )}
        cancelLabel={t('peList.deleteProtectedEnvironment.modal.button.cancel')}
        onDelete={() =>
          deleteProtectedEnvironment.mutate(
            {
              peIntegrationId,
              environmentName: selectedEnvironment,
            },
            {
              onSettled: () => setSelectedEnvironment(''),
            },
          )
        }
      />
    </div>
  );
};

export default ProtectedEnvironmentsTable;
