import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@puppet/react-components';
import { AppEventV1 } from '@puppet/cd4pe-client-ts';
import {
  getFirstActiveEventStatus,
  buildStatusMap,
  isAllCancelled,
  isAllSucceeded,
  isAllTerminal,
  isAnyDeclined,
  isAnyFailed,
} from '../../../../utils';

interface Props {
  events: AppEventV1[];
}

const StageBadge = ({ events }: Props) => {
  const { t } = useTranslation('codeDelivery');
  let finalStatus;
  let badgeType = 'warning';
  const statusMap = buildStatusMap(events);

  if (isAllTerminal(statusMap)) {
    if (isAllCancelled(statusMap)) {
      finalStatus = t('viewPipeline.events.status.canceled');
      badgeType = 'neutral';
    } else if (isAnyDeclined(statusMap)) {
      finalStatus = t('viewPipeline.events.status.declined');
      badgeType = 'danger';
    } else if (isAnyFailed(statusMap)) {
      finalStatus = t('viewPipeline.events.status.failed');
      badgeType = 'danger';
    } else if (isAllSucceeded(statusMap)) {
      finalStatus = t('viewPipeline.events.status.success');
      badgeType = 'success';
    }
  } else {
    const firstActiveStatus = getFirstActiveEventStatus(events);
    if (!firstActiveStatus) {
      return null;
    }

    if (firstActiveStatus === 'QUEUED') {
      badgeType = 'info';
    }
    finalStatus = t(
      `viewPipeline.events.status.${firstActiveStatus.toLowerCase()}`,
    );
  }

  if (!finalStatus) {
    return null;
  }

  return (
    <Badge weight="subtle" type={badgeType}>
      {finalStatus}
    </Badge>
  );
};

export default StageBadge;
