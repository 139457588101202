import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import {
  Cd4peApiError,
  CodeProjectDetailsV1,
  ListPEIntegrationsResponseV2,
  ListVcsBranchesResponseV1,
  ListVcsCommitsResponseV1,
  ListPEIntegrationEnvironmentsResponseV2,
  ListControlReposResponseV1,
  VcsCommitV1,
  PEIntegrationV2,
  PEIntegrationEnvironmentV2,
} from '@utils/api/cd4pe';
import {
  Text,
  Link as PdsLink,
  TooltipHoverArea,
} from '@puppet/react-components';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import useVcsLinks from '@hooks/useVcsLinks';

interface CommitOptionLabelProps {
  commit: VcsCommitV1;
  codeProject: CodeProjectDetailsV1;
  selectedBranch?: string;
}

export const CommitOptionLabel = ({
  commit,
  codeProject,
  selectedBranch,
}: CommitOptionLabelProps) => {
  const { t } = useTranslation('codeDelivery');
  const { getBranchLink, getCommitLink } = useVcsLinks();

  return (
    <div className="view-pipeline-commit-option-label">
      <PdsLink
        className="branchLink"
        size="small"
        as="a"
        href={getBranchLink(
          codeProject.srcRepoProvider,
          codeProject.srcRepoOwner,
          codeProject.srcRepoName,
          codeProject.srcRepoId,
          selectedBranch || commit.branch,
        )}
        target="_blank"
      >
        {commit.branch}
      </PdsLink>
      <Text size="small" className="item">
        <Trans t={t} i18nKey="viewPipeline.form.field.commit.sha">
          <PdsLink
            size="small"
            as="a"
            href={getCommitLink(
              codeProject.srcRepoProvider,
              codeProject.srcRepoOwner,
              codeProject.srcRepoName,
              codeProject.srcRepoId,
              commit.sha,
            )}
            target="_blank"
          >
            {{
              commit: commit.sha,
            }}
          </PdsLink>
        </Trans>
      </Text>
      {'committer' in commit && (
        <Text size="small" className="item">
          {t('viewPipeline.form.field.commit.committer', {
            committer: commit.committer,
          })}
        </Text>
      )}
      {commit.timestamp !== undefined && (
        <Text size="small" className="item">
          {t('viewPipeline.form.field.commit.timestamp', {
            timestamp: formatDistanceToNow(commit.timestamp),
          })}
        </Text>
      )}
      <div className="message">
        <TooltipHoverArea tooltip={commit.commitMessage} anchor="bottom">
          <Text className="item" size="small">
            {commit.commitMessage}
          </Text>
        </TooltipHoverArea>
      </div>
    </div>
  );
};

export const formatCommitsForSelect = (
  commits: UseQueryResult<ListVcsCommitsResponseV1, Cd4peApiError>,
  codeProject: CodeProjectDetailsV1,
  selectedBranch: string,
) => {
  return commits.data?.vcsCommits?.map((commit) => ({
    value: commit.sha,
    label: (
      <CommitOptionLabel
        commit={commit}
        codeProject={codeProject}
        selectedBranch={selectedBranch}
      />
    ),
  }));
};

export const formatBranchesForSelect = (
  vcsBranches: UseQueryResult<ListVcsBranchesResponseV1, Cd4peApiError>,
) =>
  vcsBranches.data?.vcsBranches.map((branch) => {
    return { value: branch.name, label: branch.name };
  });

interface PEIntegrationProps {
  peIntegration: PEIntegrationV2;
}

export const PeIntegrationsOptionsLabel = ({
  peIntegration,
}: PEIntegrationProps) => {
  const { t } = useTranslation('codeDelivery');

  return (
    <div className="view-pipeline-select-option-label">
      <span>
        <TooltipHoverArea tooltip={peIntegration.name} anchor="bottom">
          {peIntegration.name}
        </TooltipHoverArea>
      </span>
      <span>
        <TooltipHoverArea
          tooltip={peIntegration.nodeClassifierUrl}
          anchor="bottom"
        >
          {t('viewPipeline.form.field.puppetEnterpriseServer', {
            puppetEnterpriseServer: peIntegration.nodeClassifierUrl,
          })}
        </TooltipHoverArea>
      </span>
    </div>
  );
};

export const formatPEIntegrationsForSelect = (
  peIntegrations: UseQueryResult<ListPEIntegrationsResponseV2, Cd4peApiError>,
) => {
  return peIntegrations?.data?.peIntegrations?.map((peIntegration) => ({
    value: peIntegration.id,
    label: <PeIntegrationsOptionsLabel peIntegration={peIntegration} />,
  }));
};

interface EnvironmentProps {
  environment: PEIntegrationEnvironmentV2;
  codeProject: CodeProjectDetailsV1;
}

export const EnvironmentOptionsLabel = ({
  environment,
  codeProject,
}: EnvironmentProps) => {
  const { t } = useTranslation('codeDelivery');
  const { getBranchLink } = useVcsLinks();

  return (
    <div className="view-pipeline-select-option-label">
      <span>
        <TooltipHoverArea tooltip={environment.name} anchor="bottom">
          {environment.name}
        </TooltipHoverArea>
      </span>
      <span>
        <TooltipHoverArea tooltip={environment.environment} anchor="bottom">
          <Trans t={t} i18nKey="viewPipeline.form.field.environment">
            <PdsLink
              as="a"
              size="small"
              href={getBranchLink(
                codeProject.srcRepoProvider,
                codeProject.srcRepoOwner,
                codeProject.name,
                codeProject.srcRepoId,
                environment.environment,
              )}
              target="_blank"
            >
              {{
                environment: environment.environment,
              }}
            </PdsLink>
          </Trans>
        </TooltipHoverArea>
      </span>
    </div>
  );
};

export const formatEnvironmentsForSelect = (
  environments: UseQueryResult<
    ListPEIntegrationEnvironmentsResponseV2,
    Cd4peApiError
  >,
  codeProject: CodeProjectDetailsV1,
) => {
  return environments?.data?.environments?.map((env) => ({
    value: env.id,
    label: (
      <EnvironmentOptionsLabel environment={env} codeProject={codeProject} />
    ),
  }));
};

export const formatControlReposForSelect = (
  controlRepos: UseInfiniteQueryResult<
    ListControlReposResponseV1,
    Cd4peApiError
  >,
) => {
  return controlRepos?.data?.pages[0]?.controlRepos?.map((controlRepo) => ({
    value: controlRepo.name,
    label: controlRepo.name,
  }));
};
