import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import generateReportURL from 'inventory/utils/generateReportURL';
import GET_LATEST_REPORT from 'inventory/services/graphql/queries/queryLatestReport.gql';
import { useQuery } from '@apollo/client';
import { Alert, Text, Link } from '@puppet/react-components';
import NodeStatus from 'inventory/components/NodeStatus';
import ConditionalRender from 'inventory/components/ConditionalRender';

import QueryError from 'inventory/components/QueryError';
import { useTranslation } from 'react-i18next';
import changeStatusText from 'inventory/utils/changeStatusText';
import {
  useErrorMessageDispatch,
  ERROR_TYPES,
} from 'inventory/utils/context/error-context';
import './NodeDetailsHeader.scss';

function processReport(tableData) {
  const node = tableData?.hosts?.nodes?.[0];
  if (!node) {
    return {};
  }

  return {
    ...node,
    latestReportStatus: changeStatusText(
      node.latestReportStatus,
      node.latestReportCorrectiveChange,
    ),
  };
}

const NodeDetailsHeader = ({ source, nodeId, workspaceId }) => {
  const dispatch = useErrorMessageDispatch();

  const { data, loading, error, client } = useQuery(GET_LATEST_REPORT, {
    variables: { id: workspaceId, certname: nodeId, source },
  });

  const { t } = useTranslation('inventory');

  const processedReport = useMemo(() => processReport(data), [data]);

  if (loading) return <p>{t('nodeDetailsHeader.loading')}</p>;

  if (error)
    dispatch({
      type: ERROR_TYPES.GRAPHQL_ERROR,
      payload: error,
    });

  const {
    reportTimestamp,
    latestReportHash,
    latestReportCorrectiveChange,
    latestReportStatus,
  } = processedReport;

  const version = data?.hosts?.nodes?.[0]?.peServerVersion ?? '';

  const peConsoleLink = generateReportURL(version, source, latestReportHash);

  const gqlError = error?.graphQLErrors?.[0]?.message ?? '';

  return (
    <div className="node-details-header">
      <ConditionalRender enable={gqlError.indexOf('null') === -1}>
        <QueryError error={error} client={client} />
      </ConditionalRender>
      <ConditionalRender enable={gqlError.indexOf('null') > -1}>
        <Alert type="warning">
          {t('nodeDetailsHeader.alert.title')}
          <Alert.Message>{t('nodeDetailsHeader.alert.text')}</Alert.Message>
        </Alert>
      </ConditionalRender>
      <div className="current-node-status">
        <Text>
          <strong>{t('nodeDetailsHeader.lastReportTime')}</strong>{' '}
          {typeof reportTimestamp === 'string' &&
            format(parseISO(reportTimestamp), 'MMM dd, yyyy p')}
        </Text>
        <Text>
          <strong>{t('nodeDetailsHeader.status')}</strong>{' '}
          <NodeStatus
            status={latestReportStatus}
            correctiveChange={latestReportCorrectiveChange}
          />
        </Text>
        <Text>
          <strong>{t('nodeDetailsHeader.source')}</strong> {source}
        </Text>
        <Link as="a" href={peConsoleLink} target="_blank">
          {t('nodeDetailsHeader.peLink')}
        </Link>
      </div>
    </div>
  );
};

export default NodeDetailsHeader;

NodeDetailsHeader.propTypes = {
  nodeId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};
