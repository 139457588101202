import React from 'react';
import { Select } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';

export interface SelectData {
  label: string;
  value: string;
}

const AddProtectedEnvironmentsSelect = ({
  environments,
  selectedEnvironment,
  onChange,
  presetEnvironment,
}: {
  environments: SelectData[];
  selectedEnvironment: string;
  onChange: Function;
  presetEnvironment?: string;
}) => {
  const { t } = useTranslation('codeDelivery');

  return (
    <Select
      name="select-protected-environments"
      placeholder={
        presetEnvironment !== ''
          ? presetEnvironment
          : t('addProtectedEnvironment.select.placeholder')
      }
      options={environments}
      disabled={presetEnvironment !== '' || environments.length === 0}
      value={presetEnvironment || selectedEnvironment}
      onChange={(value: string) => onChange(value)}
    />
  );
};

AddProtectedEnvironmentsSelect.defaultProps = {
  presetEnvironment: '',
};

export default AddProtectedEnvironmentsSelect;
