import React, { useState } from 'react';
import CommonForm from '@components/CommonForm';
import { Button, Form, Heading, Icon, Text } from '@puppet/react-components';
import actionCreator, { Actions } from '@utils/actionCreator';
import { CommonFormChangeFunction } from '@components/CommonForm/CommonForm';
import { useTranslation } from 'react-i18next';
import CompileBatch from '@codeDelivery/components/AddPeIntegrationForm/components/CompileBatch';
import { Cd4peApiError } from '@utils/api/cd4pe';
import Cd4peError from '@components/Cd4peError';

export interface ManualIntegrationFormValues {
  name: string;
  apiToken: string;
  puppetServer: string;
  puppetDb: string;
  codeManager: string;
  orchestrator: string;
  classifier: string;
  caCert: string;
  compileBatchSize: string;
  ignoreSyncErrors: boolean;
}

export const MANUAL_INTEGRATION_FORM_FIELD_NAMES: {
  [K in keyof ManualIntegrationFormValues]: K;
} = {
  name: 'name',
  apiToken: 'apiToken',
  caCert: 'caCert',
  classifier: 'classifier',
  codeManager: 'codeManager',
  compileBatchSize: 'compileBatchSize',
  ignoreSyncErrors: 'ignoreSyncErrors',
  orchestrator: 'orchestrator',
  puppetDb: 'puppetDb',
  puppetServer: 'puppetServer',
};

export const MANUAL_INTEGRATION_FORM_ACTION_TYPES: {
  PATCH_FORM: 'MANUAL_INTEGRATION_FORM_PATCH';
} = {
  PATCH_FORM: 'MANUAL_INTEGRATION_FORM_PATCH',
};

export const manualIntegrationFormActions = {
  patchForm: (partialForm: Partial<ManualIntegrationFormValues>) =>
    actionCreator(MANUAL_INTEGRATION_FORM_ACTION_TYPES.PATCH_FORM, partialForm),
};

export type ManualIntegrationFormActions = Actions<
  typeof manualIntegrationFormActions
>;

export type ManualIntegrationFormOnChange =
  CommonFormChangeFunction<ManualIntegrationFormValues>;

interface Props {
  name?: string | null;
  apiToken?: string | null;
  puppetServer?: string | null;
  puppetDb?: string | null;
  codeManager?: string | null;
  orchestrator?: string | null;
  classifier?: string | null;
  caCert?: string | null;
  compileBatchSize?: string | null;
  ignoreSyncErrors?: boolean | false;
  saveLoading?: boolean;
  saveError?: Cd4peApiError | null;
  onFormChange: ManualIntegrationFormOnChange;
  changeFormType?: (payload: 'auto') => void;
  submitCallback: () => Promise<void>;
  cancelCallback: () => void;
  submitLabel: string;
  updateIntegration?: boolean;
}

const ManualIntegrationForm = ({
  name,
  apiToken,
  puppetServer,
  puppetDb,
  codeManager,
  orchestrator,
  classifier,
  caCert,
  compileBatchSize,
  ignoreSyncErrors,
  saveLoading,
  saveError,
  onFormChange,
  changeFormType,
  submitCallback,
  cancelCallback,
  submitLabel,
  updateIntegration,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [showIaSettings, setShowIaSettings] = useState(false);

  const formVals: ManualIntegrationFormValues = {
    name: name ?? '',
    apiToken: apiToken ?? '',
    puppetServer: puppetServer ?? '',
    puppetDb: puppetDb ?? '',
    codeManager: codeManager ?? '',
    orchestrator: orchestrator ?? '',
    classifier: classifier ?? '',
    caCert: caCert ?? '',
    compileBatchSize: compileBatchSize ?? '',
    ignoreSyncErrors: ignoreSyncErrors ?? false,
  };

  return (
    <CommonForm
      submittable
      cancellable
      className="manual-integration-form"
      submitLabel={submitLabel}
      onSubmit={submitCallback}
      onCancel={cancelCallback}
      values={formVals}
      onChange={onFormChange}
      submitting={saveLoading}
    >
      <CommonForm.Section>
        <CommonForm.Section.Main>
          {!updateIntegration && (
            <span className="manual-integration-form-section__subheader">
              <Text>
                {t('addPeIntegration.manualIntegrationForm.subheader.text')}
              </Text>

              <Button type="text" onClick={changeFormType!('auto')}>
                {t('addPeIntegration.manualIntegrationForm.subheader.button')}
              </Button>
            </span>
          )}

          <div className="manual-integration-form-field__wrapper">
            <Form.Field
              data-testid="manual-integration-form-name-input"
              type="text"
              name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.name}
              label={t('addPeIntegration.manualIntegrationForm.nameLabel')}
              placeholder={t(
                `addPeIntegration.manualIntegrationForm.namePlaceholder`,
              )}
              required
              disabled={updateIntegration}
            />
          </div>
          <div className="manual-integration-form-field__wrapper">
            <Form.Field
              data-testid="manual-integration-form-api-token-input"
              type="password"
              name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.apiToken}
              label={t('addPeIntegration.manualIntegrationForm.apiTokenLabel')}
              placeholder={t(
                `addPeIntegration.manualIntegrationForm.apiTokenPlaceholder`,
              )}
            />
          </div>
          <div className="manual-integration-form-field__wrapper">
            <Form.Field
              data-testid="manual-integration-form-puppet-server-input"
              type="text"
              name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.puppetServer}
              label={t(
                'addPeIntegration.manualIntegrationForm.puppetServerLabel',
              )}
              placeholder={t(
                `addPeIntegration.manualIntegrationForm.puppetServerPlaceholder`,
              )}
              required
            />
          </div>
          <div className="manual-integration-form-field__wrapper">
            <Form.Field
              data-testid="manual-integration-form-puppetdb-input"
              type="text"
              name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.puppetDb}
              label={t('addPeIntegration.manualIntegrationForm.puppetDbLabel')}
              placeholder={t(
                `addPeIntegration.manualIntegrationForm.puppetDbPlaceholder`,
              )}
              required
            />
          </div>
          <div className="manual-integration-form-field__wrapper">
            <Form.Field
              data-testid="manual-integration-form-code-manager-input"
              type="text"
              name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.codeManager}
              label={t(
                'addPeIntegration.manualIntegrationForm.codeManagerLabel',
              )}
              placeholder={t(
                `addPeIntegration.manualIntegrationForm.codeManagerPlaceholder`,
              )}
              required
            />
          </div>
          <div className="manual-integration-form-field__wrapper">
            <Form.Field
              data-testid="manual-integration-form-orchestrator-input"
              type="text"
              name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.orchestrator}
              label={t(
                'addPeIntegration.manualIntegrationForm.orchestratorLabel',
              )}
              placeholder={t(
                `addPeIntegration.manualIntegrationForm.orchestratorPlaceholder`,
              )}
              required
            />
          </div>
          <div className="manual-integration-form-field__wrapper">
            <Form.Field
              data-testid="manual-integration-form-classifier-input"
              type="text"
              name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.classifier}
              label={t(
                'addPeIntegration.manualIntegrationForm.classifierLabel',
              )}
              placeholder={t(
                `addPeIntegration.manualIntegrationForm.classifierPlaceholder`,
              )}
              required
            />
          </div>
          <div className="manual-integration-form-field__wrapper">
            <Form.Field
              className="manual-integration-form-ca-cert"
              data-testid="manual-integration-form-caCert-input"
              type="multiline"
              name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.caCert}
              label={t('addPeIntegration.manualIntegrationForm.caCertLabel')}
              placeholder={t(
                `addPeIntegration.manualIntegrationForm.caCertPlaceholder`,
              )}
              required
            />
          </div>
          {updateIntegration && (
            <div className="manual-integration-form-maint-edit-info">
              <Button
                data-testid="manual-integration-form-ia-button"
                innerFocus
                type="transparent"
                className="manual-integration-ia-button"
                icon={
                  showIaSettings ? 'chevron-double-up' : 'chevron-double-down'
                }
                onClick={() => {
                  setShowIaSettings((current) => !current);
                }}
              >
                {t(`addPeIntegration.manualIntegrationForm.iaSettings`)}
              </Button>
              {showIaSettings && (
                <div>
                  <Form.Field
                    type={CompileBatch}
                    name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.compileBatchSize}
                    label={t(
                      'addPeIntegration.manualIntegrationForm.compileBatchSizeLabel',
                    )}
                    value={MANUAL_INTEGRATION_FORM_FIELD_NAMES.compileBatchSize}
                  />
                </div>
              )}
              <div className="manual-integration-divider" />
              <Heading
                as="h6"
                color="subtle"
                className="manual-integration-compiler-heading"
              >
                {t(
                  'addPeIntegration.manualIntegrationForm.compilerMaintenanceLabel',
                )}
              </Heading>
              <Form.Field
                data-testid="manual-integration-form-ignore-sync-error-radio"
                type="switch"
                name={MANUAL_INTEGRATION_FORM_FIELD_NAMES.ignoreSyncErrors}
                label={t(
                  'addPeIntegration.manualIntegrationForm.compilerMaintenanceSlider',
                )}
                placeholder={t(
                  `addPeIntegration.manualIntegrationForm.compileBatchSizePlaceHolder`,
                )}
                className="manual-integration-form-maint-info"
              />
              <Icon
                role="img"
                type="alert"
                size="medium"
                className="manual-integration-alert"
              />
              <span>
                {t(
                  'addPeIntegration.manualIntegrationForm.compilerMaintenanceInfo',
                )}
              </span>
            </div>
          )}
        </CommonForm.Section.Main>
      </CommonForm.Section>
      <Cd4peError error={saveError} />
    </CommonForm>
  );
};

export default ManualIntegrationForm;

ManualIntegrationForm.defaultProps = {
  name: null,
  apiToken: null,
  puppetServer: null,
  puppetDb: null,
  codeManager: null,
  orchestrator: null,
  classifier: null,
  caCert: null,
  compileBatchSize: null,
  ignoreSyncErrors: false,
  saveLoading: false,
  saveError: null,
  changeFormType: () => {},
} as Partial<Props>;
