import { TaskStateV1 } from '@puppet/cd4pe-client-ts';

const TABLE_LIMIT = 30;
const INITIAL_TABLE_LIMIT = 10;
const TABLE_PAGE_OPTIONS = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '20',
    label: '20',
  },
  {
    value: '30',
    label: '30',
  },
];

const API_POLLING_FREQUENCY = 10000;

const DOCS_URL =
  'https://puppet.com/docs/continuous-delivery/latest/node_inventory.html';

const ROUTE_TYPES = {
  cd4pe: 'cd4pe',
  codeDelivery: 'codeDelivery',
  inventory: 'inventory',
  root: 'root',
};

const PUPPET_DEPLOY = 'deploy';
const OUTPUT_MESSAGE = 'out_message';

const RESULT_STATUS = {
  NEW: 'new',
  READY: 'ready',
  RUNNING: 'running',
  STOPPING: 'stopping',
  STOPPED: 'stopped',
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
  DONE: 'done',
  ERROR: 'error',
  UNKNOWN: 'unknown',
  QUEUED: 'queued',
};

const FILTER_OPERATORS = {
  equals: { symbol: '=', label: 'Equals', value: 'equals' },
  notEqual: {
    symbol: '!=',
    label: 'Not equal to',
    value: 'notEqual',
  },
  contains: { symbol: '=~', label: 'Contains', value: 'contains' },
  notContain: {
    symbol: '!~',
    label: 'Does not contain',
    value: 'notContain',
  },
};

const FORM_FIELD_TYPES = {
  STRING: 'String',
  VARIANT: 'Variant',
  HASH: 'Hash',
  ARRAY: 'Array',
  PATTERN: 'Pattern',
  BOOLEAN: 'Boolean',
  ENUM: 'Enum',
  INTEGER: 'Integer',
  TARGETSPEC: 'TargetSpec',
};

const ACTION_DETAILS_TABS = {
  FAILED: 'failed',
  SUCCEEDED: 'finished',
  SKIPPED: 'stopped',
  IN_PROGRESS: 'running',
  QUEUED: 'ready',
};

// This is used to differentiate requests that should reach
// the cd4pe service, via the teams node service proxy.
const PROXY_HEADER = {
  key: 'X-Node-Proxy',
  value: 'enable',
};

const KEYBOARD_KEYS = {
  enter: 13,
  space: 32,
};

const CARDTYPES = {
  chart: {
    donut: 'donut',
  },
  data: {
    fact: 'fact',
  },
  empty: {
    donut: {
      categories: [],
      series: [{ data: [] }],
    },
  },
};

const VALUE_LIMIT = 6;

const PUPPET_FACT = 'puppetFact';

const CHART_LEGEND_LABEL_MAX_LENGTH = 11;
const CHART_LEGEND_LABEL_ELLIPSE_POSITION = 9;

const PUPPETN400 = '#CCD4D9';

const COMPOUND_FILTER_AND = 'AND';
const COMPOUND_FILTER_OR = 'OR';

const ESTATE_REPORTING_FILTER_OPERATORS = {
  equals: { symbol: '=', label: 'Equals', value: 'equals' },
  'not equal to': {
    symbol: '!=',
    label: 'Not equal to',
    value: 'notEqual',
  },
  contains: { symbol: '=~', label: 'Contains', value: 'contains' },
  'does not contain': {
    symbol: '!~',
    label: 'Does not contain',
    value: 'notContain',
  },
};

const TARGET_TYPE_OPTIONS = {
  selectByName: 'Select nodes by name',
  filterByFact: 'Filter nodes by fact value',
};

const FILTER_UNION_OPTIONS = [
  { value: COMPOUND_FILTER_AND, label: COMPOUND_FILTER_AND },
  { value: COMPOUND_FILTER_OR, label: COMPOUND_FILTER_OR },
];

const PERMISSION_OBJECTS = {
  GROUP: 'GROUP',
  SERVER: 'SERVER',
  PROJECT: 'PROJECT',
  SETTING: 'SETTING',
  ENVIRONMENT: 'ENVIRONMENT',
  WORKFLOW: 'WORKFLOW',
  DASHBOARD: 'DASHBOARD',
  VM_JOB_TEMPLATE: 'VM_JOB_TEMPLATE',
  MODULE: 'MODULE',
  CONTROL_REPO: 'CONTROL_REPO',
};
const PERMISSION_ACTIONS = {
  CREATE: 'CREATE',
  READ: 'READ',
  WRITE: 'WRITE',
  DELETE: 'DELETE',
  ADMIN: 'ADMIN',
  BUILD: 'BUILD',
  RUN: 'RUN',
  DEPLOY: 'DEPLOY',
  PROMOTE: 'PROMOTE',
  MANAGE: 'MANAGE',
  EDIT: 'EDIT',
};
const PERMISSION_INSTANCES = { INVENTORY: 'INVENTORY', ALL: '*' };

const ALERT_TYPES = {
  SUCCESS: 'success',
  DANGER: 'danger',
  INFO: 'info',
  WARNING: 'warning',
};

const QUERY_NAMES = {
  PE_INTEGRATION_LIST: 'peIntegrationList',
  PE_PROTECTED_ENVIRONMENTS: 'peIntegrationProtectedEnvironments',
  PE_INTEGRATION: 'peIntegration',
};

export {
  CHART_LEGEND_LABEL_MAX_LENGTH,
  CHART_LEGEND_LABEL_ELLIPSE_POSITION,
  TABLE_LIMIT,
  INITIAL_TABLE_LIMIT,
  TABLE_PAGE_OPTIONS,
  API_POLLING_FREQUENCY,
  DOCS_URL,
  RESULT_STATUS,
  PUPPET_DEPLOY,
  OUTPUT_MESSAGE,
  CARDTYPES,
  FILTER_OPERATORS,
  FORM_FIELD_TYPES,
  ACTION_DETAILS_TABS,
  ROUTE_TYPES,
  PROXY_HEADER,
  PUPPET_FACT,
  PUPPETN400,
  KEYBOARD_KEYS,
  VALUE_LIMIT,
  COMPOUND_FILTER_AND,
  COMPOUND_FILTER_OR,
  ESTATE_REPORTING_FILTER_OPERATORS,
  TARGET_TYPE_OPTIONS,
  FILTER_UNION_OPTIONS,
  ALERT_TYPES,
  QUERY_NAMES,
  PERMISSION_OBJECTS,
  PERMISSION_ACTIONS,
  PERMISSION_INSTANCES,
};

export const IN_PROGRESS_STATES: TaskStateV1[] = [
  'WAITING',
  'QUEUED',
  'RUNNING',
];

export const FAILED_STATES: TaskStateV1[] = [
  'FAILED',
  'ABORTED',
  'DECLINED',
  'TIMED_OUT',
];

export const NEUTRAL_STATES: TaskStateV1[] = [
  'PENDING_APPROVAL',
  'APPROVED',
  'CANCELLED',
];

export const WEB_API_TOKEN_DEFAULT_AGE_IN_DAYS = 90;
