import React, { useState } from 'react';
import JobHardwareCapabilityNodes from '@codeDelivery/views/JobHardware/Components/JobHardwareCapabilityNodes';
import { Button, Text, Heading } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import {
  useDeleteCapabilitiesV1,
  useListCapabilitiesV1,
} from '@services/cd4pe/hardware';
import Cd4peError from '@components/Cd4peError';
import { Trans, useTranslation } from 'react-i18next';
import alerts from '@state/ui/alerts';
import ConfirmDeleteModal from '@components/ConfirmDeleteModal';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '@hooks/redux';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { LINKS } from 'src/routes';

const JobHardwareCapability = () => {
  const workspaceId = useWorkspaceDomain();
  const workspace = useWorkspaceName();
  const { t } = useTranslation('codeDelivery');
  const appDispatch = useAppDispatch();
  const [selectedCapabilityForDelete, setselectedCapabilityForDelete] =
    useState<number>(-1);
  const [selectedCapabilityForDeleteName, setSelectedCapabilityForDeleteName] =
    useState<string>('');

  const {
    isLoading: isLoadingCapabilities,
    error: capabilitiesError,
    data: capabilities,
  } = useListCapabilitiesV1({
    workspaceId,
  });

  const deleteCapability = useDeleteCapabilitiesV1();

  const setSelectedCapability = (id: number, name: string) => {
    setselectedCapabilityForDelete(id);
    setSelectedCapabilityForDeleteName(name);
  };

  const clearSelectedCapability = () => {
    setselectedCapabilityForDelete(-1);
    setSelectedCapabilityForDeleteName('');
  };

  const deleteSubmit = (id: number) => {
    deleteCapability.mutate(
      {
        workspaceId,
        id,
      },
      {
        onSuccess: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('jobHardware.alerts.delete.success'),
            }),
          );
        },
      },
    );
    clearSelectedCapability();
  };

  return (
    <>
      {capabilities?.jobHardwareCapabilities.map((capability) => (
        <div
          key={capability?.id}
          className="job-hardware-capability__capability-container"
        >
          <div className="job-hardware-capability__header">
            <div className="job-hardware-capability__header-left">
              <div className="job-hardware-capability__header-left-name">
                <Heading
                  as="h4"
                  className="job-hardware-capability__header-left-name-text"
                  data-testid={`${capability?.displayName}-header`}
                >
                  {capability?.displayName}
                </Heading>
              </div>
              <div className="job-hardware-capability__header-left-by">
                <Text className="job-hardware-capability__header-left-by-text">
                  {t('jobHardware.capability.table.by')}
                </Text>
              </div>
            </div>
            <div className="job-hardware-capability__header-right">
              <Button
                as={NavLink}
                icon="pencil"
                type="secondary"
                to={LINKS.codeDelivery.editCapability({
                  path: { workspace, capabilityId: capability.id },
                })}
                name={`${capability.displayName}-edit-button`}
                data-testid={`${capability.displayName}-edit-button`}
                aria-label={t('jobHardware.capability.edit.ariaLabel', {
                  name: capability.displayName,
                })}
              />
              <Button
                icon="trash"
                type="secondary"
                onClick={() =>
                  setSelectedCapability(capability?.id, capability?.displayName)
                }
                name={`${capability.displayName}-delete-button`}
                data-testid={`${capability.displayName}-delete-button`}
                aria-label={t('jobHardware.capability.delete.ariaLabel', {
                  name: capability.displayName,
                })}
              />
            </div>
          </div>
          <div
            data-testid={`job-hardware-capability-table-${capability.displayName}`}
          >
            <JobHardwareCapabilityNodes
              nodeData={capability.nodes}
              isLoading={isLoadingCapabilities}
              workspaceId={workspaceId}
              id={capability.id}
            />
          </div>
        </div>
      ))}
      <Cd4peError error={capabilitiesError} />
      <ConfirmDeleteModal
        dataTestId="jobHardware-delete-capability-modal"
        isOpen={selectedCapabilityForDelete !== -1}
        onCancel={() => clearSelectedCapability()}
        onDelete={() => deleteSubmit(selectedCapabilityForDelete)}
        title={t('jobHardware.deleteModal.capability.header', {
          capabilityName: selectedCapabilityForDeleteName,
        })}
        alert={t('jobHardware.deleteModal.capability.description')}
        checkbox
        checkboxLabel={
          <Trans
            t={t}
            i18nKey="jobHardware.deleteModal.capability.checkboxDescription"
          >
            <strong>{{ name: selectedCapabilityForDeleteName }}</strong>
          </Trans>
        }
        confirmLabel={t('jobHardware.deleteModal.capability.remove.button')}
        cancelLabel={t('jobHardware.deleteModal.capability.cancel.button')}
      />
    </>
  );
};

export default JobHardwareCapability;
