import { Card, Heading, Icon, Text } from '@puppet/react-components';
import getIconType from '@utils/vcs/iconHelper';
import getProviderDisplayName from '@utils/vcs/displayNameHelper';
import React from 'react';
import { VcsProviderV1 } from '@utils/api/cd4pe';
import { useTranslation } from 'react-i18next';
import { useDeleteIntegrationV1 } from '@services/cd4pe/vcs';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import alerts from '@state/ui/alerts';
import { useAppDispatch } from '@hooks/redux';
import { VcsIntegrationV1 } from '@puppet/cd4pe-client-ts';
import * as Provider from '@utils/vcs/providerConsts';

const isOAuthIntegration = (
  vcsProvider: VcsProviderV1,
  host: string | undefined,
) =>
  (!host && vcsProvider === Provider.AZURE_DEV_OPS) ||
  vcsProvider === Provider.BITBUCKET ||
  vcsProvider === Provider.GITHUB;

const SourceControlIntegration = ({
  integration,
}: {
  integration: VcsIntegrationV1;
}) => {
  const { t } = useTranslation('codeDelivery');
  const appDispatch = useAppDispatch();
  const workspaceId = useWorkspaceDomain();
  const deleteIntegration = useDeleteIntegrationV1();
  const {
    provider,
    host,
    connectedAccount,
    cloneOverHttp,
    sshPort,
    sshUser,
    connected,
  } = integration;

  const onDelete = () =>
    deleteIntegration.mutate(
      { workspaceId, provider },
      {
        onSuccess: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('sourceControl.integrations.remove.success.alert', {
                name: getProviderDisplayName(provider),
              }),
            }),
          );
        },
        onError: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Error',
              message: t('sourceControl.integrations.remove.error.alert', {
                name: getProviderDisplayName(provider),
              }),
            }),
          );
        },
      },
    );

  return (
    <Card
      elevation={50}
      className="source-control-card"
      data-testid={`${provider}-integration-card`}
    >
      <Card.Title className="source-control-card-heading">
        <Icon type={getIconType(provider)} size="large" />
        <div>{getProviderDisplayName(provider)}</div>
      </Card.Title>
      <Card.ActionSelect
        actions={[
          {
            id: 0,
            icon: 'trash',
            label: t('sourceControl.integrations.remove.label'),
            'aria-label': t('sourceControl.integrations.remove.ariaLabel', {
              provider,
            }),
            onClick: onDelete,
          },
        ]}
      />
      {host && (
        <div className="source-control-card-info">
          <Heading as="h5" className="source-control-card-info__heading">
            {t('sourceControl.integrations.host')}
          </Heading>
          <Text>{host}</Text>
        </div>
      )}
      {connected && (
        <div className="source-control-card-info">
          <Heading as="h5" className="source-control-card-info__heading">
            {t('sourceControl.integrations.cloneMethod')}
          </Heading>
          <Text>{cloneOverHttp ? 'HTTPS' : 'SSH'}</Text>
        </div>
      )}
      {connectedAccount && (
        <div className="source-control-card-info">
          <Heading as="h5" className="source-control-card-info__heading">
            {t('sourceControl.integrations.connectedAccount')}
          </Heading>
          <Text>{connectedAccount}</Text>
        </div>
      )}
      {cloneOverHttp ? (
        <div>
          <Text className="source-control-card-oauth" color="subtle">
            {isOAuthIntegration(provider, host)
              ? t('sourceControl.integrations.oAuth')
              : t('sourceControl.integrations.pAT')}
          </Text>
        </div>
      ) : (
        <>
          <div className="source-control-card-info">
            <Heading as="h5" className="source-control-card-info__heading">
              {t('sourceControl.integrations.sshUser')}
            </Heading>
            <Text color={sshUser ? 'base' : 'medium'}>
              {sshUser || <em>{t('sourceControl.integrations.default')}</em>}
            </Text>
          </div>
          <div className="source-control-card-info">
            <Heading as="h5" className="source-control-card-info__heading">
              {t('sourceControl.integrations.sshPort')}
            </Heading>
            <Text color={sshPort ? 'base' : 'medium'}>
              {sshPort || <em>{t('sourceControl.integrations.default')}</em>}
            </Text>
          </div>
        </>
      )}
    </Card>
  );
};

export default SourceControlIntegration;
