import React, { useState } from 'react';
import CommonForm from '@components/CommonForm';
import {
  Alert,
  Button,
  Form,
  RadioButton,
  Text,
} from '@puppet/react-components';
import actionCreator, { Actions } from '@utils/actionCreator';
import { CommonFormChangeFunction } from '@components/CommonForm/CommonForm';
import { useTranslation } from 'react-i18next';
import Definitions from '@components/Definitions';
import { Cd4peApiError } from '@utils/api/cd4pe';
import Cd4peError from '@components/Cd4peError';

interface AutoIntegrationFormActionTypes {
  NAME: 'NAME';
  CONSOLE_ADDRESS: 'CONSOLE_ADDRESS';
  PE_USERNAME: 'PE_USERNAME';
  PASSWORD: 'PASSWORD';
  TOKEN_LIFETIME: 'TOKEN_LIFETIME';
  API_TOKEN: 'API_TOKEN';
}

export const AUTO_INTEGRATION_FORM_ACTION_TYPES: AutoIntegrationFormActionTypes =
  {
    NAME: 'NAME',
    CONSOLE_ADDRESS: 'CONSOLE_ADDRESS',
    PE_USERNAME: 'PE_USERNAME',
    PASSWORD: 'PASSWORD',
    TOKEN_LIFETIME: 'TOKEN_LIFETIME',
    API_TOKEN: 'API_TOKEN',
  };

export const autoIntegrationFormActions = {
  name: (name: string) =>
    actionCreator(AUTO_INTEGRATION_FORM_ACTION_TYPES.NAME, name),
  consoleAddress: (consoleAddress: string) =>
    actionCreator(
      AUTO_INTEGRATION_FORM_ACTION_TYPES.CONSOLE_ADDRESS,
      consoleAddress,
    ),
  peUsername: (peUsername: string) =>
    actionCreator(AUTO_INTEGRATION_FORM_ACTION_TYPES.PE_USERNAME, peUsername),
  password: (password: string) =>
    actionCreator(AUTO_INTEGRATION_FORM_ACTION_TYPES.PASSWORD, password),
  tokenLifetime: (tokenLifetime: string) =>
    actionCreator(
      AUTO_INTEGRATION_FORM_ACTION_TYPES.TOKEN_LIFETIME,
      tokenLifetime,
    ),
  apiToken: (apiToken: string) =>
    actionCreator(AUTO_INTEGRATION_FORM_ACTION_TYPES.API_TOKEN, apiToken),
};

export type AutoIntegrationFormActions = Actions<
  typeof autoIntegrationFormActions
>;

type FormFieldValues = {
  [K in keyof AutoIntegrationFormActionTypes]: string | null | undefined;
};

export type AutoIntegrationFormOnChange =
  CommonFormChangeFunction<FormFieldValues>;

interface Props {
  name?: string | null;
  consoleAddress?: string | null;
  authType?: 'basic' | 'token';
  peUsername?: string | null;
  password?: string | null;
  tokenLifetime?: string | null;
  apiToken?: string | null;
  saveLoading?: boolean;
  saveError?: Cd4peApiError | null;
  onFormChange: AutoIntegrationFormOnChange;
  authTypeChange?: (payload: 'basic' | 'token') => void;
  changeFormType?: (payload: 'manual') => void;
  submitCallback: () => Promise<void>;
  cancelCallback: () => void;
  submitLabel: string;
  regenerateToken?: boolean;
  infoHeader: string;
  infoBody: string | React.ReactFragment;
  infoLink?: React.ReactFragment | null;
}

const AutoIntegrationForm = ({
  name,
  consoleAddress,
  authType,
  peUsername,
  password,
  tokenLifetime,
  apiToken,
  saveLoading,
  saveError,
  onFormChange,
  authTypeChange,
  changeFormType,
  submitCallback,
  cancelCallback,
  submitLabel,
  regenerateToken,
  infoHeader,
  infoBody,
  infoLink,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [showChangeButton, setShowChangeButton] = useState(true);

  const formVals = {
    NAME: name,
    CONSOLE_ADDRESS: consoleAddress,
    PE_USERNAME: peUsername,
    PASSWORD: password,
    TOKEN_LIFETIME: tokenLifetime,
    API_TOKEN: apiToken,
  };

  const populatedField = (label: string, value: string) => {
    return (
      <div className="auto-integration-form-populated-field">
        <p>{label}</p>
        <Text>{value}</Text>
      </div>
    );
  };

  const authControls = () => {
    if (authType === 'basic') {
      return (
        <div className="auto-integration-form-auth-controls">
          <div className="auto-integration-form-field__wrapper">
            <Form.Field
              data-testid="auto-integration-form-pe-username-input"
              type="text"
              name={AUTO_INTEGRATION_FORM_ACTION_TYPES.PE_USERNAME}
              label={t(
                'addPeIntegration.autoIntegrationForm.authSection.peUsernameLabel',
              )}
              placeholder={t(
                `addPeIntegration.autoIntegrationForm.authSection.peUsernamePlaceholder`,
              )}
              required
            />
          </div>
          <div className="auto-integration-form-field__wrapper">
            <Form.Field
              data-testid="auto-integration-form-pe-password-input"
              type="password"
              name={AUTO_INTEGRATION_FORM_ACTION_TYPES.PASSWORD}
              label={t(
                'addPeIntegration.autoIntegrationForm.authSection.pePasswordLabel',
              )}
              placeholder={t(
                `addPeIntegration.autoIntegrationForm.authSection.pePasswordPlaceholder`,
              )}
              required
            />
          </div>
          <div className="auto-integration-form-field__wrapper token-lifetime-field-wrapper">
            {regenerateToken && showChangeButton ? (
              <div className="token-lifetime-disabled-field-wrapper">
                {populatedField(
                  t(
                    'addPeIntegration.autoIntegrationForm.authSection.tokenLifetimeLabel',
                  ),
                  `${tokenLifetime} ${t(
                    'addPeIntegration.autoIntegrationForm.authSection.tokenLifetimeSecondaryLabel',
                  )}`,
                )}
                <div className="token-lifetime-change-button">
                  <Button
                    type="text"
                    icon="pencil"
                    onClick={() => setShowChangeButton(!showChangeButton)}
                  >
                    {t(
                      'addPeIntegration.autoIntegrationForm.authSection.changeButton',
                    )}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <Form.Field
                  data-testid="auto-integration-form-token-lifetime-input"
                  type="number"
                  name={AUTO_INTEGRATION_FORM_ACTION_TYPES.TOKEN_LIFETIME}
                  label={t(
                    'addPeIntegration.autoIntegrationForm.authSection.tokenLifetimeLabel',
                  )}
                  required
                />
                <label
                  htmlFor="addPeIntegration.autoIntegrationForm.authSection.tokenLifetimeSecondaryLabel"
                  className="auto-integration-form-field__token-lifetime__secondary-label"
                >
                  {t(
                    'addPeIntegration.autoIntegrationForm.authSection.tokenLifetimeSecondaryLabel',
                  )}
                </label>
              </>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="auto-integration-form-field__wrapper">
        <Form.Field
          data-testid="add-pe-integration-api-token-input"
          type="password"
          name={AUTO_INTEGRATION_FORM_ACTION_TYPES.API_TOKEN}
          label={t(
            'addPeIntegration.autoIntegrationForm.authSection.apiTokenLabel',
          )}
          placeholder={t(
            `addPeIntegration.autoIntegrationForm.authSection.apiTokenPlaceholder`,
          )}
          required
        />
      </div>
    );
  };

  const errorDisplay = () => {
    if (!saveError) {
      return null;
    }

    if (regenerateToken) {
      return <Cd4peError error={saveError} />;
    }

    return (
      <CommonForm.Section className="auto-integration-form-error-section">
        <CommonForm.Section.Main>
          <Alert
            type="danger"
            className="auto-integration-form-error-alert"
            data-testid="auto-integration-form-error"
          >
            {t('addPeIntegration.autoIntegrationForm.error.title')}
            <Alert.Message className="auto-integration-form-error-message">
              {t('addPeIntegration.autoIntegrationForm.error.message1')}
              <Button
                type="text"
                onClick={changeFormType!('manual')}
                data-testid="manual-pe-integration"
              >
                {t('addPeIntegration.autoIntegrationForm.error.button')}
              </Button>
              {t('addPeIntegration.autoIntegrationForm.error.message2')}
            </Alert.Message>
          </Alert>
          {saveError.status === 400 && <Cd4peError error={saveError} />}
        </CommonForm.Section.Main>
      </CommonForm.Section>
    );
  };

  return (
    <CommonForm
      submittable
      cancellable
      className="auto-integration-form"
      submitLabel={submitLabel}
      onSubmit={submitCallback}
      onCancel={cancelCallback}
      values={formVals}
      onChange={onFormChange}
      submitting={saveLoading}
    >
      <CommonForm.Section>
        <CommonForm.Section.Main>
          <h2 className="auto-integration-form-section__header">
            {t('addPeIntegration.autoIntegrationForm.instanceSection.header')}
          </h2>
          <div className="auto-integration-form-field__wrapper">
            {regenerateToken && name ? (
              populatedField(
                t(
                  'addPeIntegration.autoIntegrationForm.instanceSection.nameLabel',
                ),
                name,
              )
            ) : (
              <Form.Field
                data-testid="auto-integration-form-name-input"
                type="text"
                name={AUTO_INTEGRATION_FORM_ACTION_TYPES.NAME}
                label={t(
                  'addPeIntegration.autoIntegrationForm.instanceSection.nameLabel',
                )}
                placeholder={t(
                  `addPeIntegration.autoIntegrationForm.instanceSection.namePlaceholder`,
                )}
                required
              />
            )}
          </div>
          <div className="auto-integration-form-field__wrapper">
            {regenerateToken && consoleAddress ? (
              populatedField(
                t(
                  'addPeIntegration.autoIntegrationForm.instanceSection.consoleAddressLabel',
                ),
                consoleAddress,
              )
            ) : (
              <Form.Field
                data-testid="auto-integration-form-console-address-input"
                type="text"
                name={AUTO_INTEGRATION_FORM_ACTION_TYPES.CONSOLE_ADDRESS}
                label={t(
                  'addPeIntegration.autoIntegrationForm.instanceSection.consoleAddressLabel',
                )}
                placeholder={t(
                  `addPeIntegration.autoIntegrationForm.instanceSection.consoleAddressPlaceholder`,
                )}
                required
              />
            )}
          </div>
        </CommonForm.Section.Main>
      </CommonForm.Section>
      <CommonForm.Section>
        <CommonForm.Section.Main>
          <h2 className="auto-integration-form-section__header">
            {t('addPeIntegration.autoIntegrationForm.authSection.header')}
          </h2>
          {!regenerateToken && (
            <div className="auto-integration-form-auth-type">
              <RadioButton
                className="auto-integration-form-auth-type__radio"
                name="authTypeBasic"
                label={t(
                  'addPeIntegration.autoIntegrationForm.authSection.basicAuthRadio',
                )}
                value={authType === 'basic'}
                onChange={authTypeChange!('basic')}
              />
              <RadioButton
                className="auto-integration-form-auth-type__radio"
                name="authTypeToken"
                label={t(
                  'addPeIntegration.autoIntegrationForm.authSection.tokenAuthRadio',
                )}
                value={authType === 'token'}
                onChange={authTypeChange!('token')}
              />
            </div>
          )}
          {authControls()}
        </CommonForm.Section.Main>
        <CommonForm.Section.Sidebar>
          <Definitions.Entry icon="info-circle">
            <Definitions.Entry.Content>
              <h3 className="auto-integration-form-info-section__header">
                {infoHeader}
              </h3>
              <Text className="auto-integration-form-info-section__body">
                {infoBody}
              </Text>
              {infoLink}
            </Definitions.Entry.Content>
          </Definitions.Entry>
        </CommonForm.Section.Sidebar>
      </CommonForm.Section>
      {errorDisplay()}
    </CommonForm>
  );
};

export default AutoIntegrationForm;

AutoIntegrationForm.defaultProps = {
  name: null,
  consoleAddress: null,
  peUsername: null,
  password: null,
  tokenLifetime: null,
  apiToken: null,
  saveLoading: false,
  saveError: null,
  authType: 'basic',
  authTypeChange: () => {},
  regenerateToken: false,
  changeFormType: () => {},
  infoLink: null,
} as Partial<Props>;
