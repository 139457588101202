import React from 'react';
import {
  Cd4peApiError,
  isBadRequestError,
  isInternalError,
  isUnknownCd4peError,
} from '@utils/api/cd4pe';
import { Trans, useTranslation } from 'react-i18next';
import CommonDetails from './CommonDetails';
import ErrorWrapper from './ErrorWrapper';
import ErrorTitle from './ErrorTitle';

interface Props {
  error?: Cd4peApiError | null;
}

const Cd4peError = ({ error = null }: Props) => {
  const { t } = useTranslation('common');

  if (error === undefined || error === null) {
    return null;
  }

  if (isUnknownCd4peError(error)) {
    return (
      <ErrorWrapper icon="error">
        <ErrorTitle status={error.status}>{error.message}</ErrorTitle>
        <CommonDetails path={error.url} />
      </ErrorWrapper>
    );
  }

  if (isBadRequestError(error)) {
    return (
      <ErrorWrapper icon="error">
        <ErrorTitle status={error.status}>{error.body.message}</ErrorTitle>
        <ul>
          {error.body.errors.map((e) => (
            <li key={e.message}>{e.message}</li>
          ))}
        </ul>
        <CommonDetails traceId={error.body.traceId} path={error.body.uriPath} />
      </ErrorWrapper>
    );
  }

  if (isInternalError(error)) {
    return (
      <ErrorWrapper icon="error">
        <ErrorTitle status={error.status}>{error.body.message}</ErrorTitle>
        <ul>
          <li>
            <Trans
              t={t}
              i18nKey="cd4peError.internalError"
              values={{ errorCode: error.body.code }}
              components={[<span />]}
            />
          </li>
        </ul>
        <CommonDetails traceId={error.body.traceId} path={error.body.uriPath} />
      </ErrorWrapper>
    );
  }

  return (
    <ErrorWrapper icon="error">
      <ErrorTitle status={error.status}>{error.body.message}</ErrorTitle>
      <CommonDetails traceId={error.body.traceId} path={error.body.uriPath} />
    </ErrorWrapper>
  );
};

export default Cd4peError;
