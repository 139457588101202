import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  VcsV1Service,
  MutationHook,
  toUseQueryResponse,
  QueryHook,
  handleQueryRequest,
  Cd4peError,
  Cd4peApiError,
} from '@utils/api/cd4pe';

const STATUS_PREFIX_QUERY_TAG = 'statusPrefix';
const LIST_INTEGRATIONS_QUERY_TAG = 'listIntegrations';
const LIST_BRANCHES_QUERY_TAG = 'listBranches';
const LIST_COMMITS_QUERY_TAG = 'listCommits';

/**
 * useConnectBitbucketServerV1
 */

type UseConnectBitbucketServerV1 = MutationHook<
  typeof VcsV1Service,
  'connectBitbucketServerV1',
  Cd4peApiError
>;

export type UseConnectBitbucketServerV1Result =
  ReturnType<UseConnectBitbucketServerV1>;

export const useConnectBitbucketServerV1: UseConnectBitbucketServerV1 = () =>
  useMutation((payload) =>
    handleQueryRequest(VcsV1Service.connectBitbucketServerV1(payload)),
  );

/**
 * useConnectGithubEnterpriseV1
 */

type UseConnectGithubEnterpriseV1 = MutationHook<
  typeof VcsV1Service,
  'connectGithubEnterpriseV1',
  Cd4peApiError
>;

export type UseConnectGithubEnterpriseV1Result =
  ReturnType<UseConnectGithubEnterpriseV1>;

export const useConnectGithubEnterpriseV1: UseConnectGithubEnterpriseV1 = () =>
  useMutation((payload) =>
    handleQueryRequest(VcsV1Service.connectGithubEnterpriseV1(payload)),
  );

/**
 * useConnectGitlabV1
 */

type UseConnectGitlabV1 = MutationHook<
  typeof VcsV1Service,
  'connectGitlabV1',
  Cd4peApiError
>;

export type UseConnectGitlabV1Result = ReturnType<UseConnectGitlabV1>;

export const useConnectGitlabV1: UseConnectGitlabV1 = () =>
  useMutation((payload) =>
    handleQueryRequest(VcsV1Service.connectGitlabV1(payload)),
  );

/**
 * useCreateBuildStatusPrefixV1
 */

type UseCreateBuildStatusPrefixV1 = MutationHook<
  typeof VcsV1Service,
  'createBuildStatusPrefixV1',
  Cd4peApiError
>;

export type UseCreateBuildStatusPrefixV1Result =
  ReturnType<UseCreateBuildStatusPrefixV1>;

export const useCreateBuildStatusPrefixV1: UseCreateBuildStatusPrefixV1 =
  () => {
    const queryClient = useQueryClient();

    return useMutation(
      (payload) =>
        handleQueryRequest(VcsV1Service.createBuildStatusPrefixV1(payload)),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([STATUS_PREFIX_QUERY_TAG]);
        },
      },
    );
  };

/**
 * useGetBuildStatusPrefixV1
 */

type UseGetBuildStatusPrefixV1 = QueryHook<
  typeof VcsV1Service,
  'getBuildStatusPrefixV1',
  Cd4peApiError
>;

export type UseGetBuildStatusPrefixV1Result =
  ReturnType<UseGetBuildStatusPrefixV1>;

export const useGetBuildStatusPrefixV1: UseGetBuildStatusPrefixV1 = (payload) =>
  useQuery([STATUS_PREFIX_QUERY_TAG, payload.workspaceId], async () =>
    handleQueryRequest(VcsV1Service.getBuildStatusPrefixV1(payload)),
  );

/**
 * useDeleteBuildStatusPrefixV1
 */

type UseDeleteBuildStatusPrefixV1 = MutationHook<
  typeof VcsV1Service,
  'deleteBuildStatusPrefixV1',
  Cd4peApiError
>;

export type UseDeleteBuildStatusPrefixV1Result =
  ReturnType<UseDeleteBuildStatusPrefixV1>;

export const useDeleteBuildStatusPrefixV1: UseDeleteBuildStatusPrefixV1 =
  () => {
    const queryClient = useQueryClient();
    return useMutation(
      (payload) =>
        handleQueryRequest(VcsV1Service.deleteBuildStatusPrefixV1(payload)),
      {
        onSuccess: (_, payload) => {
          /*
          In this case we cant simply invalidate the query because the next get request will
          return a 404 error and the cache will not be updated correctly. 
          */
          queryClient.setQueryData(
            [STATUS_PREFIX_QUERY_TAG, payload.workspaceId],
            null,
          );
          queryClient.invalidateQueries([
            STATUS_PREFIX_QUERY_TAG,
            payload.workspaceId,
          ]);
        },
      },
    );
  };

/*
 * useListIntegrationsV1
 */

type UseListIntegrationsV1 = QueryHook<
  typeof VcsV1Service,
  'listIntegrationsV1',
  Cd4peError
>;

export const useListIntegrationsV1: UseListIntegrationsV1 = (payload) =>
  useQuery([LIST_INTEGRATIONS_QUERY_TAG, payload.workspaceId], async () =>
    handleQueryRequest(VcsV1Service.listIntegrationsV1(payload)),
  );

/**
 * useDeleteIntegrationV1
 */

type UseDeleteIntegrationV1 = MutationHook<
  typeof VcsV1Service,
  'deleteIntegrationV1'
>;

export type UseDeleteIntegrationResult = ReturnType<UseDeleteIntegrationV1>;

export const useDeleteIntegrationV1: UseDeleteIntegrationV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => toUseQueryResponse(VcsV1Service.deleteIntegrationV1(payload)),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          LIST_INTEGRATIONS_QUERY_TAG,
          payload.workspaceId,
        ]);
      },
    },
  );
};

/**
 * useConnectGitHubV1
 */
type UseConnectGitHubV1 = MutationHook<
  typeof VcsV1Service,
  'connectGitHubV1',
  Cd4peError
>;

export type UseConnectGitHubV1Result = ReturnType<UseConnectGitHubV1>;

export const useConnectGitHubV1: UseConnectGitHubV1 = () =>
  useMutation((payload) =>
    handleQueryRequest(VcsV1Service.connectGitHubV1(payload)),
  );

/**
 * useConnectAzureDevOpsV1
 */
type UseConnectAzureDevOpsV1 = MutationHook<
  typeof VcsV1Service,
  'connectAzureDevopsV1',
  Cd4peError
>;

export type UseConnectAzureDevOpsV1Result = ReturnType<UseConnectAzureDevOpsV1>;

export const useConnectAzureDevOpsV1: UseConnectAzureDevOpsV1 = () =>
  useMutation((payload) =>
    handleQueryRequest(VcsV1Service.connectAzureDevopsV1(payload)),
  );

/**
 * useConnectBitbucketCloudV1
 */
type UseConnectBitbucketCloudV1 = MutationHook<
  typeof VcsV1Service,
  'connectBitbucketCloudV1',
  Cd4peError
>;

export type UseConnectBitbucketCloudV1Result =
  ReturnType<UseConnectBitbucketCloudV1>;

export const useConnectBitbucketCloudV1: UseConnectBitbucketCloudV1 = () =>
  useMutation((payload) =>
    handleQueryRequest(VcsV1Service.connectBitbucketCloudV1(payload)),
  );

/**
 * useConnectAzureDevOpsServerV1
 */

type UseConnectAzureDevOpsServerV1 = MutationHook<
  typeof VcsV1Service,
  'connectAzureDevOpsServerV1',
  Cd4peApiError
>;

export const useConnectAzureDevOpsServerV1: UseConnectAzureDevOpsServerV1 =
  () =>
    useMutation((payload) =>
      handleQueryRequest(VcsV1Service.connectAzureDevOpsServerV1(payload)),
    );

/**
 * useListBranchesV1
 */

type UseListBranchesV1 = QueryHook<
  typeof VcsV1Service,
  'listBranchesV1',
  Cd4peApiError
>;

export type UseListBranchesV1Result = ReturnType<UseListBranchesV1>;

export const useListBranchesV1: UseListBranchesV1 = (payload, options) =>
  useQuery(
    [
      LIST_BRANCHES_QUERY_TAG,
      payload.workspaceId,
      payload.provider,
      payload.name,
      payload.project,
      payload.organization,
    ],
    async () => handleQueryRequest(VcsV1Service.listBranchesV1(payload)),
    options,
  );

/**
 * useListBranchesV1
 */

type UseListCommitsV1 = QueryHook<
  typeof VcsV1Service,
  'listCommitsV1',
  Cd4peApiError
>;

export type UseListCommitsV1Result = ReturnType<UseListCommitsV1>;

export const useListCommitsV1: UseListCommitsV1 = (payload, options) =>
  useQuery(
    [
      LIST_COMMITS_QUERY_TAG,
      payload.workspaceId,
      payload.provider,
      payload.branch,
      payload.name,
      payload.project,
      payload.organization,
    ],
    async () => handleQueryRequest(VcsV1Service.listCommitsV1(payload)),
    options,
  );
