import PageLoader from '@components/PageLoader';
import React from 'react';
import { useGetUserV1 } from '@services/cd4pe/users';
import { Navigate, Outlet } from 'react-router-dom';

const PublicRoute = () => {
  const { data: user, isLoading } = useGetUserV1();

  if (isLoading) {
    return <PageLoader />;
  }

  return user ? <Navigate replace to="/" /> : <Outlet />;
};

export default PublicRoute;
