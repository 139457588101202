import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@puppet/react-components';
import { selectUser } from '@state/session/selectors';
import useWorkspaceName from '@hooks/useWorkspaceName';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useAppSelector } from '@hooks/redux';
import { UserV1 } from '@utils/api/cd4pe';
import Breadcrumbs from '@components/Breadcrumbs';
import SettingsSideNav from '@codeDelivery/components/SettingsSideNav';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import PageLayout from '@components/PageLayout';
import { LINKS } from 'src/routes';

const defaultProps = {
  heading: '',
  selectedItem: undefined,
};

const SettingsPage = () => {
  const { t } = useTranslation('codeDelivery');
  const user = useAppSelector(selectUser);
  const workspaceInfo = {
    name: useWorkspaceName(),
    domain: useWorkspaceDomain(),
    username: (user as UserV1).userName!,
  };

  const SETTINGS_PATHS: {
    breadcrumb: string;
    path: string;
  }[] = [
    {
      breadcrumb: 'workspace',
      path: LINKS.settings.workspace({
        path: { workspace: workspaceInfo.name },
      }),
    },
    {
      breadcrumb: 'groups',
      path: LINKS.settings.listGroups({
        path: { workspace: workspaceInfo.name },
      }),
    },
    {
      breadcrumb: 'users',
      path: LINKS.settings.listUsers({
        path: { workspace: workspaceInfo.name },
      }),
    },
    {
      breadcrumb: 'ssh',
      path: LINKS.settings.ssh({ path: { workspace: workspaceInfo.name } }),
    },
    {
      breadcrumb: 'puppetEnterprise',
      path: LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceInfo.name },
      }),
    },
    {
      breadcrumb: 'sourceControl',
      path: LINKS.settings.listSourceControl({
        path: { workspace: workspaceInfo.name },
      }),
    },
  ];

  const location = useLocation();
  const matchedPath = SETTINGS_PATHS.find(
    (p) => matchPath(p.path, location.pathname) !== null,
  );

  const breadcrumbs = [
    { displayName: workspaceInfo.name },
    {
      displayName: t('settingsPage.breadcrumbs.settings'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace: workspaceInfo.name },
      }),
    },
  ];

  if (!matchedPath) {
    return <Outlet />;
  }

  return (
    <PageLayout className="settings-page">
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs
            breadcrumbs={[
              ...breadcrumbs,
              {
                displayName: t(
                  `settingsPage.breadcrumbs.${matchedPath.breadcrumb}`,
                ),
              },
            ]}
          />
        </PageLayout.Breadcrumbs>

        <Heading>{t('settingsPage.page.title')}</Heading>
      </PageLayout.Header>

      <PageLayout.Content className="settings-page-body">
        <SettingsSideNav workspace={workspaceInfo} />

        <div className="settings-page-body__content">
          <Outlet />
        </div>
      </PageLayout.Content>
    </PageLayout>
  );
};

SettingsPage.defaultProps = defaultProps;

export default SettingsPage;
