import log from 'loglevel';
import config from 'config';

export default function initializeLogging() {
  if (config.LOGGING) {
    log.setLevel(config.LOGGING);
  } else if (process.env.NODE_ENV === 'production') {
    log.disableAll();
  } else {
    log.setLevel(log.levels.INFO);
  }

  window.log = log;
}
