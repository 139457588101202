import React from 'react';
import { Modal } from '@puppet/react-components';
import { useAppDispatch } from '@hooks/redux';
import {
  CodeProjectDetailsV1,
  ProjectPipelineStageV1,
  ProjectTypeV1,
  UpsertPipelineStagesRequestV1,
} from '@puppet/cd4pe-client-ts';
import alerts from '@state/ui/alerts';
import { useTranslation } from 'react-i18next';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import Cd4peError from '@components/Cd4peError';
import { useUpsertPipelineStagesV1 } from '@services/cd4pe/pipelines';
import DeploymentForm, {
  Deployment,
  DeploymentFormValues,
} from '../../../DeploymentForm';
import { DestinationSelector } from '../../Stages';

interface EditDeploymentDialogProps {
  codeProject: CodeProjectDetailsV1;
  onClose: () => void;
  selectedDestination: DestinationSelector;
  stages: Array<ProjectPipelineStageV1>;
  pipelineId: string;
  branch: string;
  projectType: ProjectTypeV1;
}

const DEFAULT_TIMEOUT_MINUTES = 60;
const toMilliseconds = (minutes: number) => minutes * 60000;
const toMinutes = (milliseconds: number) => milliseconds / 60000;

const EditDeploymentDialog = ({
  codeProject,
  onClose,
  selectedDestination,
  stages,
  pipelineId,
  branch,
  projectType,
}: EditDeploymentDialogProps) => {
  const upsertDeployment = useUpsertPipelineStagesV1();
  const appDispatch = useAppDispatch();
  const workspaceId = useWorkspaceDomain();
  const { t } = useTranslation('codeDelivery');

  const stageData = stages[selectedDestination.stageIndex];
  const destinationData =
    stageData.destinations?.[selectedDestination.destinationIndex];

  if (destinationData === undefined) {
    throw new Error('destinationData is undefined');
  }

  const buildUpdateDeploymentRequest = (
    deployment: Deployment,
  ): UpsertPipelineStagesRequestV1 => {
    const updatedStages = [...stages];

    const targetStage = stages[selectedDestination.stageIndex];

    targetStage.destinations[selectedDestination.destinationIndex] = {
      ...destinationData,
      deploymentTemplate: {
        ...destinationData.deploymentTemplate,
        deploymentPolicy: {
          ...deployment.deploymentPolicy,
        },
        deploymentTarget: {
          ...(destinationData.deploymentTemplate?.deploymentTarget ?? {
            type: 'NODE_GROUP',
          }),
          nodeGroupId: deployment.nodeGroup?.id ?? '',
          environmentPrefix: deployment.prefix,
        },
        maxRuntime: deployment.timeout
          ? toMilliseconds(deployment.timeout)
          : toMilliseconds(DEFAULT_TIMEOUT_MINUTES),
        puppetEnterpriseCredentialsId: {
          name: deployment.puppetEnterpriseServer?.name ?? '',
          domain: workspaceId,
        },
        displayName: t('viewPipeline.dialog.addStage.deployment.displayName', {
          nodeGroupName: deployment.nodeGroup?.name,
          peName: deployment.puppetEnterpriseServer?.name,
        }),
      },
    };
    updatedStages[selectedDestination.stageIndex] = targetStage;

    return { stages: updatedStages, projectName: codeProject.name };
  };

  const updateDeploymentStage = (deployment: Deployment) => {
    upsertDeployment.mutate(
      {
        requestBody: buildUpdateDeploymentRequest(deployment),
        workspaceId,
        pipelineId,
      },
      {
        onSuccess: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('viewPipeline.dialog.manualDeployment.alert.updated'),
            }),
          );
          onClose();
        },
      },
    );
  };

  const parametersMap: { [key: string]: unknown } = {};
  destinationData?.deploymentTemplate?.deploymentPolicy?.parameters?.forEach(
    (param) => {
      parametersMap[param?.name ?? 'default'] = param.value;
    },
  );
  const policyName =
    destinationData?.deploymentTemplate?.deploymentPolicy?.name ?? 'default';
  const nodeGroupId =
    destinationData?.deploymentTemplate?.deploymentTarget?.nodeGroupId || '';
  const peServerCredentialsId =
    destinationData?.deploymentTemplate?.puppetEnterpriseCredentialsId?.name ||
    '';
  const timeout = toMinutes(
    destinationData?.deploymentTemplate?.maxRuntime ?? 3600000,
  );
  const prefix =
    destinationData.deploymentTemplate?.deploymentTarget?.environmentPrefix ||
    '';
  const controlRepo = destinationData.deploymentTemplate?.controlRepoName || '';
  const controlRepoBranch =
    destinationData.deploymentTemplate?.controlRepoBaseFeatureBranch || '';

  const initialFormValues: DeploymentFormValues = {
    branch,
    commit: '',
    puppetEnterpriseServer: peServerCredentialsId,
    nodeGroup: nodeGroupId,
    deploymentPolicy: policyName || '',
    deploymentPolicyParameters: {
      [policyName]: parametersMap,
    },
    timeout,
    description: '',
    prefix,
    controlRepo,
    controlRepoBranch,
  };

  return (
    <Modal className="manual-deployment-dialog" onClose={onClose}>
      <Modal.Title>{t('viewPipeline.form.deployment.edit.title')}</Modal.Title>
      <DeploymentForm
        type="edit"
        codeProject={codeProject}
        onSubmit={updateDeploymentStage}
        submitting={upsertDeployment.isLoading}
        onCancel={onClose}
        initialState={initialFormValues}
        submitKey="viewPipeline.form.deployment.buttons.submit.edit"
        projectType={projectType}
      />
      <Cd4peError error={upsertDeployment.error} />
    </Modal>
  );
};

export default EditDeploymentDialog;
