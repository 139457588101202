import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Alert } from '@puppet/react-components';
import {
  CodeProjectDetailsV1,
  TriggerPipelineRequestV1,
  VcsCommitV1,
} from '@utils/api/cd4pe';
import {
  UseListCommitsV1Result,
  useListBranchesV1,
  useListCommitsV1,
} from '@services/cd4pe/vcs';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import Cd4peError from '@components/Cd4peError';
import {
  useGetPipelineV1,
  useTriggerPipelineV1,
} from '@services/cd4pe/pipelines';
import {
  CommitOptionLabel,
  formatBranchesForSelect,
  formatCommitsForSelect,
} from '../../formHelpers';

export interface PromoteStageCommits {
  sha: string;
  commitMessage: string | undefined;
  branch: string;
  pullRequestId: string | undefined;
}

interface Props {
  codeProject: CodeProjectDetailsV1;
  pipeline?: string;
  stageCommits?: PromoteStageCommits[];
  stageNumber?: number;
  stageName?: string;
  onClose: () => void;
}

type FormValues = {
  pipeline: string;
  branch: string;
  sha: string;
  pullRequest: boolean;
};

const buildRequest = ({
  values,
  workspaceId,
  projectName,
  stageNumber,
  stageCommits,
  commits,
}: {
  values: FormValues;
  workspaceId: string;
  projectName: string;
  stageNumber: number;
  stageCommits: PromoteStageCommits[];
  commits: UseListCommitsV1Result;
}) => {
  const findCommit = (commit: PromoteStageCommits | VcsCommitV1) =>
    commit.sha === values.sha;

  const requestBody: TriggerPipelineRequestV1 = {
    workspaceId,
    projectName,
    sha: values.sha,
    // The branch is only required for regex pipelines, for non-regex pipelines, the backend
    // will infer the branch from the pipeline name. For non-regex pipelines, `values.branch`
    // will be undefined, which results in the field not being passed to the request.
    branch: values.branch,
    stageNumber,
  };

  const stageCommit = stageCommits.find(findCommit);
  const pullRequestId = values.pullRequest
    ? `cdpe_pr_flag_${new Date().getTime()}`
    : stageCommit?.pullRequestId;
  const commitMessage =
    stageCommit?.commitMessage ||
    commits.data?.vcsCommits?.find(findCommit)?.commitMessage;

  if (pullRequestId !== undefined) {
    requestBody.pullRequestId = pullRequestId;
  }

  if (commitMessage !== undefined) {
    requestBody.commitMessage = commitMessage;
  }

  return requestBody;
};

const TriggerPipelineDialog = ({
  codeProject,
  pipeline = '',
  stageCommits = [],
  stageNumber = 1,
  stageName,
  onClose,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const isPromoteStage = stageNumber > 1;
  const [values, setValues] = React.useState<FormValues>({
    pipeline,
    branch: '',
    sha: isPromoteStage ? stageCommits[0]?.sha ?? '' : '',
    pullRequest: false,
  });

  const pipelineOptions = codeProject?.pipelines?.map((b) => ({
    value: b.name,
    label: b.name,
  }));

  const pipelineId =
    codeProject.pipelines?.find((p) => p.name === values.pipeline)
      ?.pipelineId ?? '';

  const pipelineResponse = useGetPipelineV1(
    {
      workspaceId,
      pipelineId,
      projectName: codeProject.name,
    },
    {
      enabled: pipelineId !== '',
    },
  );

  const triggerPipelineV1 = useTriggerPipelineV1();

  const branches = useListBranchesV1(
    {
      workspaceId,
      provider: codeProject.srcRepoProvider,
      name: codeProject.srcRepoName,
      project: codeProject.srcRepoId,
      organization: codeProject.srcRepoOwner,
    },
    {
      enabled: values.pipeline === 'regex',
    },
  );

  const branchOptions = formatBranchesForSelect(branches);

  const enableCommit =
    !isPromoteStage &&
    (values.pipeline === 'regex'
      ? values.branch !== ''
      : values.pipeline !== '');

  const commits = useListCommitsV1(
    {
      workspaceId,
      provider: codeProject.srcRepoProvider,
      branch: values.pipeline === 'regex' ? values.branch : values.pipeline,
      name: codeProject.srcRepoName,
      project: codeProject.srcRepoId,
      organization: codeProject.srcRepoOwner,
    },
    {
      enabled: enableCommit,
    },
  );

  let commitOptions;
  if (stageCommits.length > 0) {
    commitOptions = stageCommits.map((commit) => ({
      value: commit.sha,
      label: (
        <CommitOptionLabel
          commit={commit}
          codeProject={codeProject}
          selectedBranch={values.branch}
        />
      ),
    }));
  } else {
    commitOptions = formatCommitsForSelect(commits, codeProject, values.branch);
  }

  const onChange = (_: keyof FormValues, newValues: FormValues) => {
    setValues(newValues);
  };

  const onSubmit = (newValues: FormValues) => {
    if (!pipelineId || pipelineResponse.isError) {
      return;
    }

    const requestBody: TriggerPipelineRequestV1 = buildRequest({
      values: newValues,
      workspaceId,
      projectName: codeProject.name,
      stageNumber,
      stageCommits,
      commits,
    });

    triggerPipelineV1.mutate({
      pipelineId,
      requestBody,
    });
  };

  return (
    <Modal onClose={onClose} className="trigger-pipeline-dialog">
      <Modal.Title>
        {isPromoteStage
          ? t('viewPipeline.dialog.triggerPipeline.promote.title', {
              stageNumber: stageName || stageNumber,
            })
          : t('viewPipeline.dialog.triggerPipeline.title')}
      </Modal.Title>
      <Form
        submittable={!triggerPipelineV1.isSuccess}
        submitting={triggerPipelineV1.isLoading}
        cancellable
        submitLabel={
          isPromoteStage
            ? t('viewPipeline.dialog.triggerPipeline.promote.button.submit')
            : t('viewPipeline.dialog.triggerPipeline.button.submit')
        }
        cancelLabel={
          triggerPipelineV1.isSuccess
            ? t('viewPipeline.dialog.triggerPipeline.button.done')
            : t('viewPipeline.dialog.triggerPipeline.button.cancel')
        }
        values={values}
        onChange={onChange}
        onSubmit={onSubmit}
        onCancel={onClose}
      >
        <Form.Field
          type="select"
          name="pipeline"
          label={t('viewPipeline.dialog.triggerPipeline.field.pipeline.label')}
          placeholder={t(
            'viewPipeline.dialog.triggerPipeline.field.pipeline.placeholder',
          )}
          options={pipelineOptions}
          required
          disabled={!!pipeline || triggerPipelineV1.isSuccess}
        />
        {values.pipeline === 'regex' && (
          <Form.Field
            type="select"
            name="branch"
            label={t('viewPipeline.dialog.triggerPipeline.field.branch.label')}
            placeholder={
              branches.isLoading
                ? t('viewPipeline.dialog.triggerPipeline.field.branch.loading')
                : t(
                    'viewPipeline.dialog.triggerPipeline.field.branch.placeholder',
                  )
            }
            options={branchOptions}
            required
            disabled={branches.isLoading || triggerPipelineV1.isSuccess}
          />
        )}
        {(values.pipeline === 'regex'
          ? values.branch !== ''
          : values.pipeline !== '') && (
          <Form.Field
            type="select"
            name="sha"
            label={t('viewPipeline.dialog.triggerPipeline.field.commit.label')}
            placeholder={
              (!isPromoteStage && commits.isLoading && !commits.isFetching) ||
              commits.isFetching
                ? t('viewPipeline.dialog.triggerPipeline.field.commit.loading')
                : t(
                    'viewPipeline.dialog.triggerPipeline.field.commit.placeholder',
                  )
            }
            options={commitOptions}
            required
            disabled={
              triggerPipelineV1.isSuccess ||
              isPromoteStage ||
              commits.isLoading ||
              commits.isFetching ||
              !!commits.error
            }
            data-testid="commit-select"
          />
        )}
        {!isPromoteStage && (
          <Form.Field
            type="switch"
            name="pullRequest"
            label={t(
              'viewPipeline.dialog.triggerPipeline.field.pullRequest.label',
            )}
            description={t(
              'viewPipeline.dialog.triggerPipeline.field.pullRequest.description',
            )}
            disabled={triggerPipelineV1.isSuccess}
          />
        )}
      </Form>
      {triggerPipelineV1.isSuccess && (
        <Alert
          type="success"
          className="trigger-pipeline-dialog__success"
          data-testid="alert-pipeline-trigger-success"
        >
          {isPromoteStage
            ? t('viewPipeline.dialog.triggerPipeline.promote.alert.successful')
            : t('viewPipeline.dialog.triggerPipeline.alert.successful')}
        </Alert>
      )}
      <Cd4peError error={branches.error} />
      <Cd4peError error={commits.error} />
      <Cd4peError error={pipelineResponse.error} />
      <Cd4peError error={triggerPipelineV1.error} />
    </Modal>
  );
};

export default TriggerPipelineDialog;
