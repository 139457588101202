import { LINKS } from 'src/routes';

export default ({ workspaceName, userName }) => {
  if (workspaceName) {
    return LINKS.codeDelivery.listRepositories({
      path: { workspace: workspaceName },
    });
  }

  if (userName) {
    return LINKS.username.profile({ path: { username: userName } });
  }

  return '/';
};
