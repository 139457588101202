import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { Button, Card, Heading, Loading } from '@puppet/react-components';
import { useAppDispatch } from '@hooks/redux';
import useWorkspaceName from '@hooks/useWorkspaceName';
import ProtectedEnvironmentsForm from '@codeDelivery/components/ProtectedEnvironmentsForm';
import alerts from '@state/ui/alerts';
import { useListGroupsV1 } from '@services/cd4pe/groups';
import {
  useCreateProtectedEnvironmentV2,
  useGetPeIntegrationV2,
  useListProtectedEnvironmentCandidatesV2,
} from '@services/cd4pe/peIntegrations';
import Breadcrumbs from '@components/Breadcrumbs';
import PageLayout from '@components/PageLayout';
import Cd4peError from '@components/Cd4peError';
import { LINKS } from 'src/routes';

const AddProtectedEnvironment = () => {
  const workspaceId = useWorkspaceDomain();
  const workspaceName = useWorkspaceName();
  const { peId: peIntegrationId } = useParams<{ peId: string }>();
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const { t } = useTranslation('codeDelivery');
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const navigateToPeList = () =>
    navigate(
      LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceName },
      }),
    );

  const {
    isLoading: peIntegrationLoading,
    error: peIntegrationError,
    data: peIntegration,
  } = useGetPeIntegrationV2({
    peIntegrationId: peIntegrationId!,
  });

  const {
    isLoading: environmentsLoading,
    error: environmentsError,
    data: environments,
  } = useListProtectedEnvironmentCandidatesV2({
    peIntegrationId: peIntegrationId!,
  });

  const {
    isLoading: groupsLoading,
    error: groupsError,
    data: groups,
  } = useListGroupsV1({ workspaceId });

  const isLoading = () =>
    peIntegrationLoading || environmentsLoading || groupsLoading;

  const derivedGroups =
    groups?.groups?.map((d) =>
      selectedData.includes(d.name) ? { ...d, selected: true } : d,
    ) ?? [];

  const createProtectedEnvironment = useCreateProtectedEnvironmentV2();

  const addProtectedEnvironment = async () =>
    createProtectedEnvironment.mutate(
      {
        peIntegrationId: peIntegrationId!,
        requestBody: {
          environmentName: selectedEnvironment,
          approvalGroupNames: selectedData,
        },
      },
      {
        onSuccess: () => {
          navigateToPeList();
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('addProtectedEnvironment.alert.success', {
                name: peIntegration?.name,
              }),
            }),
          );
        },
      },
    );

  const breadcrumbs = [
    { displayName: workspaceName },
    {
      displayName: t('addProtectedEnvironment.breadcrumbs.settingsPage'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('addProtectedEnvironment.breadcrumbs.peIntegrationsPage'),
      linkDestination: LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t(
        'addProtectedEnvironment.breadcrumbs.addProtectedEnvironmentPage',
      ),
    },
  ];

  return (
    <PageLayout className="add-protected-environment-container">
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('addProtectedEnvironment.header.text')}</Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        {isLoading() ? (
          <Loading
            data-testid="loading-spinner"
            className="loading"
            size="small"
          />
        ) : (
          <>
            <Card>
              <div className="add-protected-environment-body">
                <div className="add-protected-environment-header">
                  <Heading as="h4">
                    {t('addProtectedEnvironment.page.title', {
                      name: peIntegration?.name,
                    })}
                  </Heading>
                </div>
                <ProtectedEnvironmentsForm
                  environments={environments}
                  selectedEnvironment={selectedEnvironment}
                  onEnvironmentChange={setSelectedEnvironment}
                  groups={derivedGroups}
                  loadingGroups={groupsLoading}
                  selectedGroups={selectedData}
                  onSelectedData={setSelectedData}
                />
              </div>
            </Card>
            <div className="add-protected-environment-button-container">
              <Button
                data-testid="add-protected-environments-btn"
                disabled={
                  selectedEnvironment === '' || selectedData.length === 0
                }
                onClick={() => addProtectedEnvironment()}
              >
                {t('addProtectedEnvironment.buttons.add.label')}
              </Button>
              <Button type="tertiary" onClick={() => navigateToPeList()}>
                {t('addProtectedEnvironment.buttons.cancel.label')}
              </Button>
            </div>
            <Cd4peError error={peIntegrationError} />
            <Cd4peError error={environmentsError} />
            <Cd4peError error={groupsError} />
            <Cd4peError error={createProtectedEnvironment.error} />
          </>
        )}
      </PageLayout.Content>
    </PageLayout>
  );
};

export default AddProtectedEnvironment;
