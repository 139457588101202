import { useSelector } from 'react-redux';
import { selectWorkspaceDomain } from '@state/session/selectors';

const useWorkspaceDomain = () => {
  const workspaceDomain = useSelector(selectWorkspaceDomain);

  if (!workspaceDomain) {
    throw new Error('Expected workspace domain to be set but was not defined.');
  }

  return workspaceDomain as string;
};

export default useWorkspaceDomain;
