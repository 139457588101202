import { Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import actionCreator, { Actions } from '@utils/actionCreator';
import { stringCompare } from '@utils/compare';
import {
  formatError,
  GroupsV1Service,
  isErrorResponse,
  ListRbacGroupsV1,
  RbacGroupV1,
  unwrapApiResponse,
} from '@utils/api/cd4pe';
import * as at from './actionTypes';

const actions = {
  getGroupsLoading: () => actionCreator(at.GET_GROUPS_LOADING),
  getGroupsComplete: (groupDataPayload: ListRbacGroupsV1) =>
    actionCreator(at.GET_GROUPS_COMPLETE, groupDataPayload),
  getGroupsError: (error: string) => actionCreator(at.GET_GROUPS_ERROR, error),
  deleteGroupLoading: () => actionCreator(at.DELETE_GROUPS_LOADING),
  deleteGroupComplete: () => actionCreator(at.DELETE_GROUPS_COMPLETE),
  deleteGroupError: (error: string) =>
    actionCreator(at.DELETE_GROUPS_ERROR, error),
  toggleConfirmDelete: (groupId: number | null) =>
    actionCreator(at.TOGGLE_CONFIRM_DELETE, groupId),
  filterGroups: (filterPayload: {
    query: string;
    filteredGroups: RbacGroupV1[];
  }) => actionCreator(at.FILTER_GROUPS, filterPayload),
  sortGroups: (sortPayload: {
    groups: RbacGroupV1[];
    sort: { direction: string; sortDataKey: 'name' | 'description' };
  }) => actionCreator(at.SORT_GROUPS, sortPayload),
};

export type GroupListActions = Actions<typeof actions>;

export const GROUP_SORTS = {
  name: ({ name: a }: RbacGroupV1, { name: b }: RbacGroupV1) =>
    stringCompare(a, b),
  description: (
    { description: a }: RbacGroupV1,
    { description: b }: RbacGroupV1,
  ) => stringCompare(a, b),
};

export const filterGroups = (
  { query, groups }: { query: string; groups: RbacGroupV1[] },
  dispatch: Dispatch<GroupListActions>,
) => {
  const groupNameMatches = ({ name }: { name: string }) => {
    return name.toLowerCase().includes(query.toLowerCase());
  };
  const filteredGroups = (groups || []).filter(groupNameMatches);

  return dispatch(
    actions.filterGroups({
      query,
      filteredGroups,
    }),
  );
};

export const toggleConfirmDelete = (
  dispatch: Dispatch<GroupListActions>,
  groupId: number | null = null,
) => {
  dispatch(actions.toggleConfirmDelete(groupId));
};

export const sortGroups = (
  {
    direction,
    sortDataKey,
    groups,
    query,
  }: {
    direction: string;
    sortDataKey: 'name' | 'description';
    groups: RbacGroupV1[];
    query: string;
  },
  dispatch: Dispatch<GroupListActions>,
) => {
  const multiplier = direction === 'asc' ? 1 : -1;
  const sortedGroups = [...groups].sort(
    (a, b) => multiplier * GROUP_SORTS[sortDataKey](a, b),
  );

  dispatch(
    actions.sortGroups({
      groups: sortedGroups,
      sort: { direction, sortDataKey },
    }),
  );

  return filterGroups({ query, groups: sortedGroups }, dispatch);
};

export const getGroups = async (
  {
    workspaceId,
    query,
    sort,
  }: {
    workspaceId: string;
    query: string;
    sort: { direction: string; sortDataKey: 'name' | 'description' };
  },
  dispatch: Dispatch<GroupListActions>,
  t: TFunction<'codeDelivery'>,
) => {
  dispatch(actions.getGroupsLoading());

  const response = await unwrapApiResponse(
    GroupsV1Service.listGroupsV1({ workspaceId }),
  );

  if (isErrorResponse(response)) {
    return dispatch(
      actions.getGroupsError(formatError(response, t('groupList.error.fetch'))),
    );
  }

  dispatch(actions.getGroupsComplete(response));

  return sortGroups(
    {
      direction: sort.direction,
      sortDataKey: sort.sortDataKey,
      groups: response.groups,
      query,
    },
    dispatch,
  );
};

export const deleteGroup = async (
  {
    workspaceId,
    groupId,
    groups,
    query,
    sort,
  }: {
    workspaceId: string;
    groupId: number;
    groups: RbacGroupV1[];
    query: string;
    sort: { direction: string; sortDataKey: 'name' | 'description' };
  },
  dispatch: Dispatch<GroupListActions>,
  t: TFunction<'codeDelivery'>,
) => {
  dispatch(actions.deleteGroupLoading());
  const response = await unwrapApiResponse(
    GroupsV1Service.deleteGroupV1({ workspaceId, groupId }),
  );
  if (isErrorResponse(response)) {
    return dispatch(
      actions.deleteGroupError(
        formatError(response, t('groupList.error.delete', { id: groupId })),
      ),
    );
  }

  const newGroups = groups.filter(({ id }) => id !== groupId);
  dispatch(actions.deleteGroupComplete());
  return sortGroups(
    {
      direction: sort.direction,
      sortDataKey: sort.sortDataKey,
      groups: newGroups,
      query,
    },
    dispatch,
  );
};
