import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@puppet/react-components';
import {
  ChartModalProptypes,
  CardPropTypes,
} from 'inventory/utils/en.propTypes';
import GET_FACT_PATHS from 'inventory/services/graphql/queries/queryFactPaths.gql';
import useListQuery from 'inventory/utils/hooks/useListQuery';
import FactModalView from './FactModalView';
import './ChartModal.scss';

const ChartModal = ({
  chartModalMessages,
  workspaceId,
  onClose,
  onSubmit,
  isEditing,
  card,
  ...props
}) => {
  const { data } = useListQuery(
    GET_FACT_PATHS,
    {
      id: workspaceId,
    },
    'factPaths',
  );

  return (
    <div className="chart-modal-container">
      <Modal
        className="chart-modal-container ntf-modal"
        onClose={onClose}
        {...props}
      >
        <div data-testid={`chart-modal-container-${card?.factname ?? 'new'}`}>
          <FactModalView
            onCancel={onClose}
            formMessages={chartModalMessages}
            options={data || []}
            onSubmit={onSubmit}
            isEditing={isEditing}
            card={card}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ChartModal;

ChartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  workspaceId: PropTypes.string.isRequired,
  chartModalMessages: ChartModalProptypes.isRequired,
  isEditing: PropTypes.bool.isRequired,
  card: CardPropTypes.isRequired,
};
