import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Text, Form, Link as PdsLink } from '@puppet/react-components';
import { Authentication } from '@puppet/react-layouts';
import { useResetPasswordV1 } from '@services/cd4pe/users';
import ConditionalRender from '@inventory/components/ConditionalRender';
import CopyrightFooter from '@components/CopyrightFooter';
import FormError from '@components/FormError';
import { LINKS } from 'src/routes';

const ResetPassword = () => {
  const { t } = useTranslation('codeDelivery');
  const submitResetPassword = useResetPasswordV1();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorValue, setErrorValue] = useState<string>('');
  const [urlParams] = useSearchParams();
  const token = urlParams.get('token');
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (formData: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    if (formData.newPassword === formData.confirmPassword) {
      setErrorValue('');
      submitResetPassword.mutate(
        {
          requestBody: {
            newPassword: formData.newPassword,
            confirmPassword: formData.confirmPassword,
            token: String(token),
          },
        },
        {
          onSuccess: () => {
            setFormSubmitted(true);
          },
        },
      );
    } else {
      setErrorValue(t('resetPassword.form.error.password.mismatch'));
    }
  };

  return (
    <Authentication
      className="resetPassword"
      product="continuous-delivery"
      title={
        formSubmitted
          ? t('resetPassword.submitted.header')
          : t('resetPassword.header')
      }
      footer={<CopyrightFooter />}
    >
      <ConditionalRender enable={formSubmitted}>
        <Text className="resetPassword__description" color="medium">
          {t('resetPassword.submitted.description')}
        </Text>
        <div className="links__proceed-to-signin">
          <Trans
            t={t}
            i18nKey="resetPassword.form.links.proceedToSignin"
            components={[
              <Text color="medium" />,
              <PdsLink as={Link} to={LINKS.login({})} />,
            ]}
          />
        </div>
      </ConditionalRender>
      <ConditionalRender enable={!formSubmitted}>
        <Text className="resetPassword__description" color="medium">
          {t('resetPassword.description')}
        </Text>
        <Form
          className="resetPassword__form"
          data-testid="reset-password-form"
          submittable
          actionsPosition="block"
          submitLabel={t(`resetPassword.button`)}
          submitting={submitResetPassword.isLoading}
          onSubmit={(values: {
            newPassword: string;
            confirmPassword: string;
          }) => {
            onSubmit(values);
          }}
        >
          <Form.Field
            type={showPassword ? 'text' : 'password'}
            name="newPassword"
            label={t(`resetPassword.form.password.label`)}
            required
            requiredFieldMessage={t(`resetPassword.form.password.required`)}
            trailingButtonIcon={showPassword ? 'private' : 'eye'}
            trailingButtonProps={{
              'aria-label': t(`login.form.field.password.toggle.ariaLabel`),
            }}
            onClickTrailingButton={() => setShowPassword(!showPassword)}
            data-testid="reset-password-password-field"
          />
          <Form.Field
            type={showPassword ? 'text' : 'password'}
            name="confirmPassword"
            label={t(`resetPassword.form.confirmPassword.label`)}
            required
            requiredFieldMessage={t(
              `resetPassword.form.confirmPassword.required`,
            )}
            trailingButtonIcon={showPassword ? 'private' : 'eye'}
            trailingButtonProps={{
              'aria-label': t(`login.form.field.password.toggle.ariaLabel`),
            }}
            onClickTrailingButton={() => setShowPassword(!showPassword)}
            data-testid="reset-password-confirm-password-field"
          />
        </Form>
        {submitResetPassword.error && !errorValue && (
          <FormError message={submitResetPassword.error.body.message} />
        )}
        {errorValue && <FormError message={errorValue} />}
        <div className="links__return-to-signin">
          <Trans
            t={t}
            i18nKey="resetPassword.form.links.returnToSignin"
            components={[
              <Text color="medium" />,
              <PdsLink as={Link} to={LINKS.login({})} />,
            ]}
          />
        </div>
      </ConditionalRender>
    </Authentication>
  );
};

export default ResetPassword;
