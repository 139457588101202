import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, Button } from '@puppet/react-components';
import './ActiveFilterList.scss';

const ActiveFilterList = ({
  currentFilterList,
  onApplyAll,
  onClearAll,
  onRemove,
  filterDisplayMessages,
  isEditing,
  onCancel,
}) => {
  const getActiveFilters = (rawActiveFilters) => {
    return rawActiveFilters.map(({ label, fact, operator, value }, index) => {
      const keyId = `${index}_${label}`;
      return (
        <li key={keyId}>
          <div className="li_text">
            <Text className="li-text-item">
              <strong>{label}</strong>
            </Text>
            {fact && <Text className="li-text-item">{fact} </Text>}
            {operator && (
              <Text className="li-text-item" color="subtle">
                {operator}{' '}
              </Text>
            )}
            <Text>{value}</Text>
          </div>
          <Icon
            role="img"
            type="x"
            size="medium"
            onClick={() => onRemove(index)}
          />
        </li>
      );
    });
  };

  return (
    <div className="nodeTableFilter-active-filter-list">
      {currentFilterList.length > 0 ? (
        <ul>{getActiveFilters(currentFilterList)}</ul>
      ) : (
        <Text className="active-filter-empty-text">
          {filterDisplayMessages.emptyList}
        </Text>
      )}
      <div className="listAction">
        <Button
          data-testid="activeFilter-addFilter"
          type="text"
          icon="plus"
          onClick={() => {
            isEditing(true);
          }}
        >
          {filterDisplayMessages.addFilterButton}
        </Button>
        <Button
          data-testid="activeFilter-clearAll"
          type="text"
          onClick={() => {
            onClearAll();
          }}
        >
          {filterDisplayMessages.clearAllButton}
        </Button>
      </div>
      <div className="formButtons">
        <Button
          data-testid="activeFilter-cancel"
          type="tertiary"
          onClick={() => {
            onCancel();
          }}
        >
          {filterDisplayMessages.cancelButton}
        </Button>
        <Button
          data-testid="activeFilter-applyAll"
          onClick={() => {
            onApplyAll();
          }}
        >
          {filterDisplayMessages.applyAllButton}
        </Button>
      </div>
    </div>
  );
};

export default ActiveFilterList;

ActiveFilterList.propTypes = {
  currentFilterList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      fact: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.string.isRequired,
      filter: PropTypes.shape({}).isRequired,
    }),
  ).isRequired,
  // passed from the NodeTable but driven from the NodeTableFilter as it controls the hide/show modal
  onApplyAll: PropTypes.func.isRequired,
  // driven from the NodeTable
  onClearAll: PropTypes.func.isRequired,
  // driven from the NodeTable
  onRemove: PropTypes.func.isRequired,
  filterDisplayMessages: PropTypes.shape({
    buttonLabel: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
    selectLabel: PropTypes.string.isRequired,
    selectPlaceholderText: PropTypes.string.isRequired,
    addFilterButton: PropTypes.string.isRequired,
    clearAllButton: PropTypes.string.isRequired,
    applyAllButton: PropTypes.string.isRequired,
    clearFiltersButton: PropTypes.string.isRequired,
    activeFiltersButton: PropTypes.string.isRequired,
    cancelButton: PropTypes.string.isRequired,
    emptyList: PropTypes.string.isRequired,
  }).isRequired,
  // Flag to show the filter selector
  isEditing: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
