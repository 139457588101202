import { useRef, useState } from 'react';
import debounce from 'lodash.debounce';

const useDebouncedSearch = (timeout = 500) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [appliedSearchTerm, setAppliedSearchTerm] = useState('');
  const debouncedSearchHandler = useRef(
    debounce((value: string) => setAppliedSearchTerm(value), timeout),
  ).current;

  const handleSearch = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    debouncedSearchHandler(newSearchTerm);
  };

  return { searchTerm, appliedSearchTerm, handleSearch };
};

export default useDebouncedSearch;
