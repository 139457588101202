import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Modal,
  Code,
  Heading,
  Text,
  Tabs,
  Button,
  Loading,
} from '@puppet/react-components';
import classnames from 'classnames';
import {
  useGetWebhookEventV1,
  useRedeliverWebhookDataV1,
} from '@services/cd4pe/webhooks';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import Cd4peError from '@components/Cd4peError';
import ConditionalRender from '@components/ConditionalRender';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import { ProjectTypeV1, TriggerEventV1 } from '@puppet/cd4pe-client-ts';

interface Props {
  event: TriggerEventV1;
  projectName: string;
  projectType: ProjectTypeV1;
  onClose: () => void;
}

const getReponseCodeClass = (httpCode: number) => ({
  'webhook-dialog__response-code--success': httpCode >= 200 && httpCode < 300,
  'webhook-dialog__response-code--error': httpCode >= 400 && httpCode < 600,
});

const WebhookDialog = ({ event, projectName, projectType, onClose }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const appDispatch = useAppDispatch();

  const [redeliverWebookConfirm, setRedeliverWebhookConfirm] =
    React.useState(false);

  const webhookEvent = useGetWebhookEventV1({
    workspaceId,
    webhookEventId: event.webhookEvent?.webhookEventId ?? '',
    projectName,
    projectType,
    appEventId: event.webhookEvent?.eventId,
  });

  const redeliverWebhook = useRedeliverWebhookDataV1();

  const onRedeliverWebhook = () => {
    redeliverWebhook.mutate(
      {
        workspaceId,
        requestBody: {
          webhookEventId: event.webhookEvent?.webhookEventId ?? '',
          projectName,
        },
      },
      {
        onSuccess: () => {
          onClose();
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t(
                'viewPipeline.dialog.webhook.alerts.redeliver.success',
              ),
            }),
          );
        },
      },
    );
  };

  const onCancelRedeliverWebhook = () => {
    setRedeliverWebhookConfirm(false);
    redeliverWebhook.reset();
  };

  return (
    <Modal onClose={onClose} className="webhook-dialog">
      <div className="webhook-dialog__heading">
        <Modal.Title>{t('viewPipeline.dialog.webhook.title')}</Modal.Title>
        <ConditionalRender enable={!!webhookEvent.data}>
          <Trans
            parent={Text}
            t={t}
            i18nKey="viewPipeline.dialog.webhook.responseCode"
            className="webhook-dialog__response-code"
            values={{ responseCode: webhookEvent?.data?.responseCode }}
            components={[
              <span
                className={classnames(
                  getReponseCodeClass(webhookEvent?.data?.responseCode ?? 0),
                )}
              />,
            ]}
          />
        </ConditionalRender>
      </div>
      <Cd4peError error={webhookEvent.error} />
      <ConditionalRender enable={webhookEvent.isLoading}>
        <Loading size="small" data-testid="webhook-loading" />
      </ConditionalRender>
      <ConditionalRender enable={!!webhookEvent.data}>
        <Tabs>
          <Tabs.Tab title={t('viewPipeline.dialog.webhook.tab.request')}>
            <div className="webhook-dialog__tab">
              <Heading as="h6">
                {t('viewPipeline.dialog.webhook.requestHeaders')}
              </Heading>
              <Code type="block" size="small" className="webhook-dialog__code">
                {JSON.stringify(webhookEvent?.data?.requestHeaders, null, 2)}
              </Code>
              <Heading as="h6">
                {t('viewPipeline.dialog.webhook.requestBody')}
              </Heading>
              {webhookEvent?.data?.requestBody && (
                <Code
                  type="block"
                  size="small"
                  className="webhook-dialog__code"
                >
                  {JSON.stringify(
                    JSON.parse(webhookEvent?.data?.requestBody),
                    null,
                    2,
                  )}
                </Code>
              )}
            </div>
          </Tabs.Tab>
          <Tabs.Tab title={t('viewPipeline.dialog.webhook.tab.response')}>
            <div className="webhook-dialog__tab">
              <Heading as="h6">
                {t('viewPipeline.dialog.webhook.responseBody')}
              </Heading>
              {webhookEvent?.data?.responseBody && (
                <Code
                  type="block"
                  size="small"
                  className="webhook-dialog__code"
                >
                  {JSON.stringify(
                    JSON.parse(webhookEvent?.data?.responseBody),
                    null,
                    2,
                  )}
                </Code>
              )}
            </div>
          </Tabs.Tab>
        </Tabs>
      </ConditionalRender>
      {!webhookEvent?.isLoading && !webhookEvent.isError && (
        <Modal.Actions>
          <ConditionalRender enable={!redeliverWebookConfirm}>
            <Button onClick={() => setRedeliverWebhookConfirm(true)}>
              {t('viewPipeline.dialog.webhook.buttons.redeliver')}
            </Button>
          </ConditionalRender>
          <ConditionalRender enable={redeliverWebookConfirm}>
            <div className="webhook-dialog__redeliver-warning">
              <Text size="small">
                {t('viewPipeline.dialog.webhook.redeliver.warning')}
              </Text>
              <Text size="small">
                {t('viewPipeline.dialog.webhook.redeliver.warning.trigger')}
              </Text>
            </div>
            <Button
              loading={redeliverWebhook.isLoading}
              onClick={onRedeliverWebhook}
            >
              {t('viewPipeline.dialog.webhook.buttons.redeliver.confirm')}
            </Button>
            <Button type="secondary" onClick={onCancelRedeliverWebhook}>
              {t('viewPipeline.dialog.webhook.buttons.redeliver.cancel')}
            </Button>
            <Cd4peError error={redeliverWebhook.error} />
          </ConditionalRender>
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default WebhookDialog;
