import { Form } from '@puppet/react-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SignupRequestV1 } from '@puppet/cd4pe-client-ts';

export type SignUpFormValues = {
  confirmPassword: string;
} & SignupRequestV1;

const SignUpForm = ({
  isLoading,
  onSubmit,
  error,
}: {
  isLoading: boolean;
  onSubmit: (values: SignUpFormValues) => void;
  error: string;
}) => {
  const { t } = useTranslation('codeDelivery');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <Form
      className="signup__form"
      data-testid="signup__form"
      submittable
      submitting={isLoading}
      onSubmit={onSubmit}
      actionsPosition="block"
      submitLabel={t('signup.form.button.submit')}
    >
      <Form.Field
        type="text"
        name="firstName"
        label={t('signup.form.fields.firstName.label')}
        required
        placeholder={t('signup.form.fields.firstName.placeholder')}
        requiredFieldMessage={t('signup.form.fields.firstName.required')}
        data-testid="signup-form-firstname-field"
      />
      <Form.Field
        type="text"
        name="lastName"
        label={t('signup.form.fields.surname.label')}
        required
        placeholder={t('signup.form.fields.surname.placeholder')}
        requiredFieldMessage={t('signup.form.fields.surname.required')}
        data-testid="signup-form-lastname-field"
      />
      <Form.Field
        type="text"
        name="companyName"
        label={t('signup.form.fields.company.label')}
        placeholder={t('signup.form.fields.company.placeholder')}
        data-testid="signup-form-company-name-field"
      />
      <Form.Field
        type="email"
        name="email"
        label={t('signup.form.fields.email.label')}
        required
        placeholder={t('signup.form.fields.email.placeholder')}
        requiredFieldMessage={t('signup.form.fields.email.required')}
        data-testid="signup-form-email-field"
      />
      <div className="signup-divider" />
      <Form.Field
        type="text"
        name="username"
        label={t('signup.form.fields.username.label')}
        required
        placeholder={t('signup.form.fields.username.placeholder')}
        requiredFieldMessage={t('signup.form.fields.username.required')}
        data-testid="signup-form-username-field"
      />
      <Form.Field
        type={showPassword ? 'text' : 'password'}
        name="password"
        label={t('signup.form.fields.password.label')}
        required
        placeholder={t('signup.form.fields.password.placeholder')}
        trailingButtonIcon={showPassword ? 'private' : 'eye'}
        trailingButtonProps={{
          'aria-label': t(`signup.form.field.password.toggle.ariaLabel`),
        }}
        onClickTrailingButton={() => setShowPassword(!showPassword)}
        error={error}
        requiredFieldMessage={t('signup.form.fields.password.required')}
        data-testid="signup-form-password-field"
      />
      <Form.Field
        type={showConfirmPassword ? 'text' : 'password'}
        name="confirmPassword"
        label={t('signup.form.fields.confirmPassword.label')}
        required
        placeholder={t('signup.form.fields.confirmPassword.placeholder')}
        trailingButtonIcon={showConfirmPassword ? 'private' : 'eye'}
        trailingButtonProps={{
          'aria-label': t(`signup.form.field.confirmPassword.toggle.ariaLabel`),
        }}
        onClickTrailingButton={() =>
          setShowConfirmPassword(!showConfirmPassword)
        }
        error={error}
        requiredFieldMessage={t('signup.form.fields.confirmPassword.required')}
        data-testid="signup-form-confirm-password-field"
      />
    </Form>
  );
};

export default SignUpForm;
