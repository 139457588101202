export default {
  placeholder: 'nodeTable.filter.factValue.operator.placeholder',
  name: 'operator',
  label: 'nodeTable.filter.factValue.operator.label',
  filterValue: {
    contains: {
      not: false,
      value: 'like',
    },
    notContain: {
      not: true,
      value: 'like',
    },
    equals: {
      not: false,
      value: 'equalTo',
    },
    notEqual: {
      not: true,
      value: 'equalTo',
    },
  },
  default: {
    value: 'contains',
    label: 'Contains',
  },
  options: [
    { value: 'contains', label: 'Contains' },
    { value: 'notContain', label: 'Does not contain' },
    { value: 'equals', label: 'Equals' },
    { value: 'notEqual', label: 'Not equal to' },
  ],
};
