import { Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import {
  Cd4peApiError,
  handleApiRequest,
  isCd4peApiError,
  isKnownCd4peError,
  SshKeyPairV1,
  WorkspacesV1Service,
} from '@utils/api/cd4pe';
import actionCreator, { Actions } from '@utils/actionCreator';
import * as at from './actionTypes';

const actions = {
  getSshKeyLoading: () => actionCreator(at.GET_SSH_KEY_LOADING),
  getSshKeyComplete: (sshKey: SshKeyPairV1 | null) =>
    actionCreator(at.GET_SSH_KEY_COMPLETE, sshKey),
  getSshKeyError: (error: Cd4peApiError) =>
    actionCreator(at.GET_SSH_KEY_ERROR, error),
  createSshKeyLoading: () => actionCreator(at.CREATE_SSH_KEY_LOADING),
  createSshKeyComplete: (sshKey: SshKeyPairV1) =>
    actionCreator(at.CREATE_SSH_KEY_COMPLETE, sshKey),
  createSshKeyError: (error: Cd4peApiError) =>
    actionCreator(at.CREATE_SSH_KEY_ERROR, error),
  deleteSshKeyLoading: () => actionCreator(at.DELETE_SSH_KEY_LOADING),
  deleteSshKeyComplete: () => actionCreator(at.DELETE_SSH_KEY_COMPLETE),
  deleteSshKeyError: (error: Cd4peApiError) =>
    actionCreator(at.DELETE_SSH_KEY_ERROR, error),
};

export type SshKeyActions = Actions<typeof actions>;

export const getSshKey = async (
  workspaceId: string,
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<SshKeyActions>,
) => {
  dispatch(actions.getSshKeyLoading());

  const response = await handleApiRequest(
    WorkspacesV1Service.getSshCredentialV1({ workspaceId }),
  );

  if (isKnownCd4peError(response) && response.status === 404) {
    dispatch(actions.getSshKeyComplete(null));
    return;
  }

  if (isCd4peApiError(response)) {
    dispatch(actions.getSshKeyError(response));
    return;
  }

  dispatch(actions.getSshKeyComplete(response));
};

export const createSshKey = async (
  workspaceId: string,
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<SshKeyActions>,
) => {
  dispatch(actions.createSshKeyLoading());
  const response = await handleApiRequest(
    WorkspacesV1Service.createSshCredentialV1({ workspaceId }),
  );

  if (isCd4peApiError(response)) {
    dispatch(actions.createSshKeyError(response));
    return;
  }

  dispatch(actions.createSshKeyComplete(response));
};

export const deleteSshKey = async (
  workspaceId: string,
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<SshKeyActions>,
) => {
  dispatch(actions.deleteSshKeyLoading());

  const response = await handleApiRequest(
    WorkspacesV1Service.deleteSshCredentialV1({ workspaceId }),
  );

  if (isCd4peApiError(response)) {
    dispatch(actions.deleteSshKeyError(response));
    return;
  }

  dispatch(actions.deleteSshKeyComplete());
};
