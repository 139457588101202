import { ADD_CODE_PROJECT_FORM_ACTION_TYPES } from '@codeDelivery/components/AddCodeProjectForm';
import { Cd4peApiError } from '@utils/api/cd4pe';
import {
  StdVcsBranch,
  StdVcsRepo,
  StdVcsSource,
} from '@utils/api/cd4pe/vcs/actions';
import * as vcsAt from '@utils/api/cd4pe/vcs/actionTypes';
import { NewControlRepoActions } from './actions';
import * as at from './actionTypes';

export interface NewControlRepoState {
  sources: StdVcsSource[];
  selectedSource: string;
  repositories: StdVcsRepo[];
  selectedRepository: string;
  branches: StdVcsBranch[];
  mainBranch: StdVcsBranch | null;
  createMainBranch: boolean;
  selectedBranch: string;
  name: string;
  saveControlRepoSuccess: boolean;
  currentModalClosed: boolean;
  getSourcesLoading: boolean;
  getSourcesError: string | null;
  getReposLoading: boolean;
  getReposError: string | null;
  getBranchesLoading: boolean;
  getBranchesError: string | null;
  saveControlRepoLoading: boolean;
  newControlRepoName?: string | null;
  saveControlRepoError: Cd4peApiError | string | null;
  saveControlRepoWebhookError: boolean;
}

export const defaultNewControlRepoState: NewControlRepoState = {
  sources: [],
  selectedSource: '',
  repositories: [],
  selectedRepository: '',
  branches: [],
  mainBranch: null,
  createMainBranch: true,
  selectedBranch: '',
  name: '',
  saveControlRepoSuccess: false,
  currentModalClosed: false,
  getSourcesLoading: false,
  getSourcesError: null,
  getReposLoading: false,
  getReposError: null,
  getBranchesLoading: false,
  getBranchesError: null,
  saveControlRepoLoading: false,
  newControlRepoName: null,
  saveControlRepoError: null,
  saveControlRepoWebhookError: false,
};

export const reducer = (
  state: NewControlRepoState,
  action: NewControlRepoActions,
): NewControlRepoState => {
  switch (action.type) {
    case vcsAt.GET_SOURCES_START:
      return {
        ...state,
        getSourcesLoading: true,
      };
    case vcsAt.GET_SOURCES_COMPLETE:
      return {
        ...state,
        getSourcesLoading: false,
        sources: action.payload,
      };
    case vcsAt.GET_SOURCES_ERROR:
      return {
        ...state,
        getSourcesLoading: false,
        getSourcesError: action.payload,
      };
    case vcsAt.GET_REPOS_START:
      return {
        ...state,
        getReposLoading: true,
      };
    case vcsAt.GET_REPOS_COMPLETE:
      return {
        ...state,
        getReposLoading: false,
        repositories: action.payload,
      };
    case vcsAt.GET_REPOS_ERROR:
      return {
        ...state,
        getReposLoading: false,
        getReposError: action.payload,
      };
    case vcsAt.GET_BRANCHES_START:
      return {
        ...state,
        getBranchesLoading: true,
      };
    case vcsAt.GET_BRANCHES_COMPLETE:
      return {
        ...state,
        getBranchesLoading: false,
        branches: action.payload,
        mainBranch:
          state.mainBranch ||
          action.payload.find((branch) => branch.actualValue.name === 'main') ||
          null,
      };
    case vcsAt.GET_BRANCHES_ERROR:
      return {
        ...state,
        getBranchesLoading: false,
        getBranchesError: action.payload,
      };
    case at.SAVE_CONTROL_REPO_START:
      return {
        ...state,
        saveControlRepoLoading: true,
      };
    case at.SAVE_CONTROL_REPO_COMPLETE:
      return {
        ...state,
        saveControlRepoLoading: false,
        saveControlRepoSuccess: true,
        newControlRepoName: action.payload.name,
      };
    case at.SAVE_CONTROL_REPO_ERROR:
      return {
        ...state,
        saveControlRepoLoading: false,
        saveControlRepoSuccess: false,
        saveControlRepoError: action.payload,
      };
    case at.SAVE_CONTROL_REPO_WEBHOOK_ERROR:
      return {
        ...state,
        saveControlRepoLoading: false,
        saveControlRepoSuccess: false,
        saveControlRepoWebhookError: true,
        currentModalClosed: false,
      };
    case ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_SOURCE:
      return {
        ...state,
        selectedSource: action.payload,
        repositories: [],
        selectedRepository: '',
        branches: [],
        mainBranch: null,
        selectedBranch: '',
        name: '',
        saveControlRepoError: null,
        getReposError: null,
      };
    case ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_REPOSITORY:
      return {
        ...state,
        selectedRepository: action.payload,
        branches: [],
        mainBranch: null,
        selectedBranch: '',
        name: '',
        saveControlRepoError: null,
        getBranchesError: null,
      };
    case at.CREATE_MAIN_BRANCH:
      return {
        ...state,
        createMainBranch: action.payload,
      };
    case ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_BRANCH:
      return {
        ...state,
        selectedBranch: action.payload,
      };
    case ADD_CODE_PROJECT_FORM_ACTION_TYPES.NAME:
      return {
        ...state,
        name: action.payload,
      };
    case at.CLOSE_MODAL:
      return {
        ...state,
        currentModalClosed: true,
      };
    default:
      throw new Error();
  }
};
