import { JobTemplateListActions, SortData } from './actions';
import * as at from './actionTypes';

export interface JobTemplateListState {
  query: string;
  confirmDeleteId: null | number;
  buttonRefs: object;
  sort: SortData;
}

export const JobTemplateListDefaultState: JobTemplateListState = {
  query: '',
  confirmDeleteId: null,
  buttonRefs: {},
  sort: {
    direction: 'asc',
    sortDataKey: 'name',
  },
};

export const reducer = (
  state: JobTemplateListState,
  action: JobTemplateListActions,
): JobTemplateListState => {
  switch (action.type) {
    case at.TOGGLE_CONFIRM_JOB_TEMPLATE_DELETE:
      return {
        ...state,
        confirmDeleteId: action.payload ?? null,
      };
    case at.FILTER_JOB_TEMPLATES:
      return {
        ...state,
        query: action.payload,
      };
    case at.SORT_JOB_TEMPLATES:
      return {
        ...state,
        sort: action.payload,
      };
    default:
      return state;
  }
};
