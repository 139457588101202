import {
  AppEventV1,
  CodeProjectDetailsV1,
  DeploymentAppEventV1,
  JobTemplateSummaryV1,
  PEImpactAnalysisEnvironmentV1,
  PEImpactAnalysisEventV1,
  PipelineDestinationTypeV1,
  ProjectPipelineDestinationV1,
  ProjectPipelineGateTypeV1,
  ProjectPipelineGateV1,
  ProjectPipelineStageV1,
  ProjectTypeV1,
  TaskStateV1,
  TriggerConditionV1,
  TriggerDeploymentRequestV1,
  UpsertPipelineStagesRequestV1,
  VmJobInstanceEventV1,
} from '@puppet/cd4pe-client-ts';
import { DestinationSelector } from '@codeDelivery/components/ViewPipeline/components/Stages/Stages';
import { Deployment } from './components/DeploymentForm';
import { ImpactAnalysis } from './components/ImpactAnalysisForm';

export enum PIPELINE_STAGE_OPTIONS {
  ADD_ITEM_TO_STAGE,
  ADD_STAGE_BEFORE,
  ADD_STAGE_AFTER,
  REORDER_PIPELINE,
  DELETE_STAGE,
  RENAME_STAGE,
}

type TYPE_PIPELINE_STAGE_PROMOTE_OPTIONS = {
  [key: string]: TriggerConditionV1;
};

export const PIPELINE_STAGE_PROMOTE_OPTIONS: TYPE_PIPELINE_STAGE_PROMOTE_OPTIONS =
  {
    ALL_SUCCESS: 'ALL_SUCCESS',
    ALL_COMPLETE: 'ALL_COMPLETE',
    ANY_SUCCESS: 'ANY_SUCCESS',
    ANY_COMPLETE: 'ANY_COMPLETE',
  };

export const PIPELINE_BRANCH_OPTIONS = {
  ALL_FILTER: 'ALL',
  MANUAL_ACTION: '~manual~',
};

export const EVENT_TYPE_JOB = 'VM_JOB';
export const EVENT_TYPE_DEPLOYMENT = 'DEPLOYMENT';
export const EVENT_TYPE_IMPACT_ANALYSIS = 'PE_IMPACT_ANALYSIS';
export const LEGACY_EVENT_TYPE_ROLLING_DEPLOYMENT = 'ROLLING_DEPLOY';
export const LEGACY_EVENT_TYPE_MODULE_DEPLOYMENT = 'PE_MODULE_DEPLOYMENT';

// eslint-disable-next-line no-shadow
export enum EventStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING',
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  ABORTED = 'ABORTED',
  TIMEDOUT = 'TIMEDOUT',
  TIMED_OUT = 'TIMED_OUT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export function returnStatusColor(
  status: EventStatus | TaskStateV1 | undefined,
) {
  switch (status) {
    case EventStatus.DONE:
    case EventStatus.SUCCESS:
    case EventStatus.ACTIVE:
    case EventStatus.APPROVED:
      return 'success';
    case EventStatus.FAILED:
    case EventStatus.TIMEDOUT:
    case EventStatus.INACTIVE:
    case EventStatus.DECLINED:
      return 'danger';
    case EventStatus.PENDING:
    case EventStatus.PENDING_APPROVAL:
    case EventStatus.IN_PROGRESS:
    case EventStatus.RUNNING:
    case EventStatus.WAITING:
      return 'warning';
    case EventStatus.CANCELED:
    case EventStatus.CANCELLED:
    case EventStatus.ABORTED:
      return 'subtle';
    default:
      return 'subtle';
  }
}

export function isRunningStatus(status: EventStatus) {
  switch (status) {
    case EventStatus.PENDING:
    case EventStatus.IN_PROGRESS:
    case EventStatus.RUNNING:
    case EventStatus.QUEUED:
    case EventStatus.WAITING:
    case EventStatus.PENDING_APPROVAL:
    case EventStatus.APPROVED:
      return true;
    default:
      return false;
  }
}

export function getEventStatus(
  event: VmJobInstanceEventV1 | DeploymentAppEventV1 | PEImpactAnalysisEventV1,
): EventStatus | TaskStateV1 | keyof StatusMap | undefined {
  switch (event.eventType) {
    case EVENT_TYPE_JOB: {
      const job = event as VmJobInstanceEventV1;
      return job.jobStatus;
    }
    case EVENT_TYPE_DEPLOYMENT:
    case LEGACY_EVENT_TYPE_MODULE_DEPLOYMENT:
    case LEGACY_EVENT_TYPE_ROLLING_DEPLOYMENT: {
      const deployment = event as DeploymentAppEventV1;
      return deployment.deploymentState;
    }
    case EVENT_TYPE_IMPACT_ANALYSIS: {
      const impactAnalysis = event as PEImpactAnalysisEventV1;
      return impactAnalysis.state;
    }
    default:
      return undefined;
  }
}

export function getFirstActiveEventStatus(stageEvents: AppEventV1[]) {
  if (!stageEvents || !Array.isArray(stageEvents)) {
    return null;
  }

  const firstActiveEvent = stageEvents.find((e) => {
    const status = getEventStatus(e);
    if (!status) return false;
    return isRunningStatus(status as EventStatus);
  });

  if (!firstActiveEvent) {
    return null;
  }

  return getEventStatus(firstActiveEvent);
}

export function statusBadgeType(status: EventStatus | TaskStateV1) {
  switch (status) {
    case EventStatus.DONE:
    case EventStatus.SUCCESS:
    case EventStatus.ACTIVE:
    case EventStatus.APPROVED:
      return 'success';
    case EventStatus.FAILED:
    case EventStatus.TIMEDOUT:
    case EventStatus.INACTIVE:
    case EventStatus.DECLINED:
      return 'danger';
    case EventStatus.PENDING:
    case EventStatus.PENDING_APPROVAL:
    case EventStatus.IN_PROGRESS:
    case EventStatus.RUNNING:
    case EventStatus.WAITING:
      return 'warning';
    case EventStatus.CANCELED:
    case EventStatus.CANCELLED:
    case EventStatus.ABORTED:
      return 'neutral';
    default:
      return 'neutral';
  }
}

export type StatusMap = {
  DONE?: number;
  SUCCESS: number;
  ACTIVE: number;
  FAILED: number;
  CANCELED: number;
  CANCELLED?: number;
  ABORTED: number;
  TIMEDOUT: number;
  INACTIVE: number;
  DECLINED: number;
  PENDING: number;
  IN_PROGRESS?: number;
  RUNNING: number;
  WAITING?: number;
  APPROVED: number;
  PENDING_APPROVAL: number;
  QUEUED: number;
};

export const initStatusMap = (): StatusMap => {
  return {
    DONE: 0,
    SUCCESS: 0,
    ACTIVE: 0,
    FAILED: 0,
    CANCELED: 0,
    CANCELLED: 0,
    ABORTED: 0,
    TIMEDOUT: 0,
    INACTIVE: 0,
    DECLINED: 0,
    PENDING: 0,
    IN_PROGRESS: 0,
    RUNNING: 0,
    WAITING: 0,
    APPROVED: 0,
    PENDING_APPROVAL: 0,
    QUEUED: 0,
  };
};

export const buildStatusMap = (events: AppEventV1[]): StatusMap => {
  const statusMap = initStatusMap();
  events.forEach((e) => {
    const status = getEventStatus(e);
    if (status) {
      statusMap[status as keyof StatusMap] += 1;
    }
  });
  return statusMap;
};

export function isAllTerminal(statusMap: StatusMap) {
  if (!statusMap) return false;
  return (
    statusMap.ACTIVE === 0 &&
    statusMap.PENDING === 0 &&
    statusMap.IN_PROGRESS === 0 &&
    statusMap.RUNNING === 0 &&
    statusMap.WAITING === 0 &&
    statusMap.APPROVED === 0 &&
    statusMap.PENDING_APPROVAL === 0 &&
    statusMap.QUEUED === 0
  );
}

export function isAnyFailed(statusMap: StatusMap) {
  if (!statusMap) return false;
  return (
    statusMap.FAILED > 0 || statusMap.TIMEDOUT > 0 || statusMap.INACTIVE > 0
  );
}

export function isAnyDeclined(statusMap: StatusMap) {
  if (!statusMap) return false;
  return statusMap.DECLINED > 0;
}

export function isAnySucceeded(statusMap: StatusMap) {
  if (!statusMap) return false;
  return statusMap.SUCCESS > 0 || statusMap.DONE! > 0;
}

export function isAnyCancelled(statusMap: StatusMap) {
  if (!statusMap) return false;
  return (
    statusMap.CANCELED > 0 || statusMap.CANCELLED! > 0 || statusMap.ABORTED > 0
  );
}

export function isAllCancelled(statusMap: StatusMap) {
  if (!statusMap) return false;
  return (
    isAllTerminal(statusMap) &&
    !isAnyFailed(statusMap) &&
    !isAnySucceeded(statusMap) &&
    isAnyCancelled(statusMap)
  );
}

export function isAllSucceeded(statusMap: StatusMap) {
  return (
    isAllTerminal(statusMap) &&
    !isAnyFailed(statusMap) &&
    !isAnyCancelled(statusMap)
  );
}

export function consolidateStatusMap(statusMap: StatusMap) {
  // we have some duplicate statuses, so we want to give a consolidated summary
  const consolidatedStatusMap = { ...statusMap };

  if (statusMap) {
    consolidatedStatusMap.SUCCESS = statusMap.SUCCESS + statusMap.DONE!;
    delete consolidatedStatusMap.DONE;

    consolidatedStatusMap.CANCELED = statusMap.CANCELED + statusMap.CANCELLED!;
    delete consolidatedStatusMap.CANCELLED;

    consolidatedStatusMap.PENDING = statusMap.PENDING + statusMap.WAITING!;
    delete consolidatedStatusMap.WAITING;

    consolidatedStatusMap.RUNNING = statusMap.RUNNING + statusMap.IN_PROGRESS!;
    delete consolidatedStatusMap.IN_PROGRESS;
  }

  return statusMap;
}

export function renameStage(
  stageName: string,
  stageNumber: number, // 1-indexed
  stages: ProjectPipelineStageV1[],
) {
  const stagesCopy = window.structuredClone(stages ?? []);

  stagesCopy[stageNumber - 1] = {
    ...stagesCopy[stageNumber - 1],
    stageName,
  };

  return stagesCopy;
}

export function autoPromoteStage(
  triggerOn: boolean,
  stageNumber: number, // 1-indexed
  stages: ProjectPipelineStageV1[],
) {
  const stagesCopy = window.structuredClone(stages ?? []);

  stagesCopy[stageNumber - 1] = {
    ...stagesCopy[stageNumber - 1],
    triggerOn,
  };

  return stagesCopy;
}

export function autoPromoteConditionStage(
  triggerCondition: TriggerConditionV1,
  stageNumber: number, // 1-indexed
  stages: ProjectPipelineStageV1[],
) {
  const stagesCopy = window.structuredClone(stages ?? []);

  stagesCopy[stageNumber - 1] = {
    ...stagesCopy[stageNumber - 1],
    triggerCondition,
  };

  return stagesCopy;
}

const toMilliseconds = (min: number) => min * 60000;

const DEFAULT_TIMEOUT_MINUTES = 60;

function generateDeploymentDestination(
  deployment: Deployment,
  stageNum: number,
  workspaceId: string,
  displayName: string,
): ProjectPipelineDestinationV1 {
  return {
    stageNum,
    type: 'DEPLOYMENT',
    deploymentTemplate: {
      deploymentPolicy: deployment.deploymentPolicy,
      domain: workspaceId,
      displayName,
      maxRuntime: deployment.timeout
        ? toMilliseconds(deployment.timeout)
        : toMilliseconds(DEFAULT_TIMEOUT_MINUTES),
      puppetEnterpriseCredentialsId: {
        name: deployment.puppetEnterpriseServer?.name!,
        domain: deployment.puppetEnterpriseServer?.workspaceId!,
      },
      deploymentTarget: {
        type: 'NODE_GROUP',
        nodeGroupId: deployment.nodeGroup?.id!,
        environmentPrefix: deployment.prefix,
      },
      controlRepoName: deployment.controlRepoName,
      controlRepoBaseFeatureBranch: deployment.controlRepoBaseFeatureBranch,
    },
  };
}

function generateIADestination(
  iaData: ImpactAnalysis,
  stageNum: number,
  workspaceId: string,
  projectName: string,
  projectType: ProjectTypeV1,
): ProjectPipelineDestinationV1 {
  return {
    stageNum,
    type: 'IMPACT_ANALYSIS',
    peImpactAnalysisTemplate: {
      domain: workspaceId,
      settings: {
        analyzeAllDeployments: iaData.allStageEnvironments,
        compileBatchSize: iaData.nodes,
        [projectType === 'CONTROL_REPO' ? 'controlRepoId' : 'moduleId']: {
          domain: workspaceId,
          name: projectName,
        },
        environments: iaData.stageEnvironments
          ? iaData.stageEnvironments?.map((str) => ({
              pipelineDestinationId: str,
            }))
          : [],
        moduleImpactAnalysisControlRepos:
          projectType === 'MODULE'
            ? iaData.stageEnvironments?.map((env) => ({
                controlRepoId: {
                  domain: workspaceId,
                  name: iaData?.stageEnvironmentsControlRepos?.[env] ?? '',
                },
                peModuleDeploymentId: env,
              }))
            : undefined,
      },
    },
  };
}

export function getNextPipelineStageNumber(stages: ProjectPipelineStageV1[]) {
  if (!stages || stages.length < 1) {
    return 1;
  }

  let maxStage = stages[0].stageNum;

  stages.forEach((s) => {
    if (s.stageNum > maxStage) {
      maxStage = s.stageNum;
    }
  });

  return maxStage + 1;
}

export function generateJobDestinations(
  jobTemplateSummaries: JobTemplateSummaryV1[],
  stageNum: number,
): ProjectPipelineDestinationV1[] {
  const destinations: ProjectPipelineDestinationV1[] = [];
  jobTemplateSummaries.forEach((jobTemplateSummary) => {
    const destination: ProjectPipelineDestinationV1 = {
      type: 'JOB',
      stageNum,
      vmJobTemplateId: jobTemplateSummary.id,
      vmJobTemplateName: jobTemplateSummary.name,
    };
    destinations.push(destination);
  });
  return destinations;
}

type DestinationType = PipelineDestinationTypeV1 | ProjectPipelineGateTypeV1;

export interface AddNewStageParams {
  stageName: string;
  destinationType: DestinationType;
  stages: ProjectPipelineStageV1[];
  event: Deployment | JobTemplateSummaryV1[] | ImpactAnalysis;
  deploymentDisplayName?: string;
  workspaceId: string;
  projectName: string;
  projectType: ProjectTypeV1;
}

export interface AddNewNumberedStageParams extends AddNewStageParams {
  stageNumber: number;
}

function createStage({
  stageNumber, // 1-indexed
  stageName,
  destinationType,
  event,
  deploymentDisplayName,
  workspaceId,
  projectName,
  projectType,
}: AddNewNumberedStageParams): ProjectPipelineStageV1 {
  const newStage: ProjectPipelineStageV1 = {
    stageNum: stageNumber,
    stageName,
    triggerOn: false,
    destinations: [],
  };

  if (destinationType === 'JOB') {
    newStage.destinations = generateJobDestinations(
      event as JobTemplateSummaryV1[],
      stageNumber,
    );
  } else if (destinationType === 'DEPLOYMENT' && deploymentDisplayName) {
    const destination = generateDeploymentDestination(
      event as Deployment,
      stageNumber,
      workspaceId,
      deploymentDisplayName,
    );
    newStage.destinations.push(destination);
  } else if (destinationType === 'IMPACT_ANALYSIS') {
    const destination = generateIADestination(
      event as ImpactAnalysis,
      stageNumber,
      workspaceId,
      projectName,
      projectType,
    );
    newStage.destinations.push(destination);
  }

  return newStage;
}

export function addNewStageBefore({
  stageNumber, // 1-indexed
  stageName,
  destinationType,
  stages,
  event,
  deploymentDisplayName,
  workspaceId,
  projectName,
  projectType,
}: AddNewNumberedStageParams): ProjectPipelineStageV1[] {
  const newStage = createStage({
    stageNumber,
    stageName,
    destinationType,
    stages,
    event,
    deploymentDisplayName,
    workspaceId,
    projectName,
    projectType,
  });
  const stagesCopy = window.structuredClone(stages ?? []);
  stagesCopy.splice(stageNumber - 1, 0, newStage);
  return stagesCopy;
}

export function addNewStageAfter({
  stageNumber, // 1-indexed
  stageName,
  destinationType,
  stages,
  event,
  deploymentDisplayName,
  workspaceId,
  projectName,
  projectType,
}: AddNewNumberedStageParams): ProjectPipelineStageV1[] {
  const newStage = createStage({
    stageNumber: stageNumber + 1,
    stageName,
    destinationType,
    stages,
    event,
    deploymentDisplayName,
    workspaceId,
    projectName,
    projectType,
  });
  const stagesCopy = window.structuredClone(stages ?? []);
  if (stageNumber === stagesCopy.length) {
    stagesCopy.push(newStage);
  } else {
    stagesCopy.splice(stageNumber, 0, newStage);
  }
  return stagesCopy;
}

export function addNewStage({
  stageName,
  destinationType,
  stages,
  event,
  deploymentDisplayName,
  workspaceId,
  projectName,
  projectType,
}: AddNewStageParams): ProjectPipelineStageV1[] {
  const newStage = createStage({
    stageNumber: getNextPipelineStageNumber(stages),
    stageName,
    destinationType,
    stages,
    event,
    deploymentDisplayName,
    workspaceId,
    projectName,
    projectType,
  });
  const stagesCopy = window.structuredClone(stages ?? []);
  stagesCopy.push(newStage);
  return stagesCopy;
}

export function addNewStageItem({
  stageNumber, // 1-indexed
  destinationType,
  stages,
  event,
  deploymentDisplayName,
  workspaceId,
  projectName,
  projectType,
}: AddNewNumberedStageParams): ProjectPipelineStageV1[] {
  const stagesCopy = window.structuredClone(stages ?? []);

  if (destinationType === 'JOB') {
    stagesCopy[stageNumber - 1].destinations = stagesCopy[
      stageNumber - 1
    ].destinations
      .concat(
        generateJobDestinations(event as JobTemplateSummaryV1[], stageNumber),
      )
      .filter(
        (d: ProjectPipelineDestinationV1) =>
          d.placeholder !== 'JOB_PLACEHOLDER',
      );
  } else if (destinationType === 'DEPLOYMENT' && deploymentDisplayName) {
    stagesCopy[stageNumber - 1].destinations = stagesCopy[
      stageNumber - 1
    ].destinations
      .concat([
        generateDeploymentDestination(
          event as Deployment,
          stageNumber,
          workspaceId,
          deploymentDisplayName,
        ),
      ])
      .filter(
        (d: ProjectPipelineDestinationV1) =>
          d.placeholder !== 'DEPLOYMENT_PLACEHOLDER',
      );
  } else if (destinationType === 'IMPACT_ANALYSIS') {
    stagesCopy[stageNumber - 1].destinations = stagesCopy[
      stageNumber - 1
    ].destinations
      .concat([
        generateIADestination(
          event as ImpactAnalysis,
          stageNumber,
          workspaceId,
          projectName,
          projectType,
        ),
      ])
      .filter(
        (d: ProjectPipelineDestinationV1) =>
          d.placeholder !== 'IMPACT_ANALYSIS_PLACEHOLDER',
      );
  }

  return stagesCopy;
}

export function deleteStage(
  stageNumber: number, // 1-indexed
  stages: ProjectPipelineStageV1[],
) {
  return stages.filter((stage) => stage.stageNum !== stageNumber);
}

export function deleteStageJob(
  id: string,
  stageNumber: number, // 1-indexed
  stages: ProjectPipelineStageV1[],
) {
  const stagesCopy = window.structuredClone(stages ?? []);

  const stage = stagesCopy[stageNumber - 1];

  if (stage?.destinations.length === 1) {
    return deleteStage(stageNumber, stagesCopy);
  }

  stagesCopy[stageNumber - 1] = {
    ...stage,
    destinations:
      stage?.destinations.filter(
        (d: ProjectPipelineDestinationV1) => d.id !== id,
      ) ?? [],
  };

  return stagesCopy;
}

export function deleteStagePipelineGate(
  stageNumber: number, // 1-indexed
  stages: ProjectPipelineStageV1[],
) {
  const stagesCopy = window.structuredClone(stages ?? []);

  delete stagesCopy[stageNumber - 1].pipelineGate;

  return stagesCopy;
}

export function addPRGate(
  stageNum: number, // 1-indexed
  stages: ProjectPipelineStageV1[],
) {
  const newPipelineGate: ProjectPipelineGateV1 = {
    projectPipelineGateType: 'PULL_REQUEST',
  };
  const stagesCopy = window.structuredClone(stages ?? []);

  stagesCopy[stageNum - 1].pipelineGate = newPipelineGate;

  return stagesCopy;
}

export function buildManualDeploymentRequest(
  deployment: Deployment,
  codeProject: CodeProjectDetailsV1,
  displayName: string,
  workspaceId: string,
  projectType: ProjectTypeV1,
): TriggerDeploymentRequestV1 {
  return {
    workspaceId,
    projectName: codeProject.name,
    projectType,
    displayName,
    description: deployment.description ?? '',
    maxRuntime: deployment.timeout
      ? toMilliseconds(deployment.timeout)
      : toMilliseconds(DEFAULT_TIMEOUT_MINUTES),
    deploymentPolicy: {
      ...deployment.deploymentPolicy,
      controlRepoId: {
        domain: workspaceId,
        name: codeProject.name,
      },
    },
    deploymentTarget: {
      type: 'NODE_GROUP',
      nodeGroupId: deployment.nodeGroup?.id,
      environmentPrefix: deployment.prefix,
    },
    puppetEnterpriseServer: deployment.puppetEnterpriseServer?.name ?? '',
    repoTargetBranch: deployment.nodeGroup?.environment ?? '',
    repoSourceBranch: deployment.branch,
    repoSourceSha: deployment.commit,
  };
}

export const buildUpdateImpactAnalysisRequest = (
  values: ImpactAnalysis,
  codeProject: CodeProjectDetailsV1,
  selectedDestination: DestinationSelector,
  destinationData: ProjectPipelineDestinationV1,
  stages: ProjectPipelineStageV1[],
  projectType: ProjectTypeV1,
  workspaceId: string,
): UpsertPipelineStagesRequestV1 => {
  const updatedStages = [...stages];

  const targetStage = stages[selectedDestination.stageIndex];

  targetStage.destinations[selectedDestination.destinationIndex] = {
    ...destinationData,
    peImpactAnalysisTemplate: {
      ...destinationData.peImpactAnalysisTemplate,
      settings: {
        ...destinationData.peImpactAnalysisTemplate?.settings,
        compileBatchSize: Number(values.nodes), // ensure number
        analyzeAllDeployments: values.allStageEnvironments,
        environments: values.stageEnvironments
          ? values.stageEnvironments?.map((str) => ({
              pipelineDestinationId: str,
            }))
          : [],
        moduleImpactAnalysisControlRepos:
          projectType === 'MODULE'
            ? values.stageEnvironments?.map((env) => ({
                controlRepoId: {
                  domain: workspaceId,
                  name: values?.stageEnvironmentsControlRepos?.[env] ?? '',
                },
                peModuleDeploymentId: env,
              }))
            : undefined,
      },
    },
  };
  updatedStages[selectedDestination.stageIndex] = targetStage;

  return { stages: updatedStages, projectName: codeProject.name };
};

export const checkDeploymentExists = (
  stages: ProjectPipelineStageV1[],
): boolean => {
  return stages.some((stage) => {
    return stage.destinations?.some((dest) => dest.type === 'DEPLOYMENT');
  });
};

export function getIATasksFromPipelineStages(stages: ProjectPipelineStageV1[]) {
  const environments: PEImpactAnalysisEnvironmentV1[] = [];

  stages.forEach((s) => {
    s.destinations.forEach((d) => {
      if (d.type === 'IMPACT_ANALYSIS') {
        d.peImpactAnalysisTemplate?.settings?.environments?.forEach((e) => {
          environments.push({
            ...e,
          });
        });
      }
    });
  });

  return environments;
}

export function impactedJobs(
  stages: ProjectPipelineStageV1[],
  pipelineDestinationId: string,
) {
  const impactedDestinationIds: string[] = [];

  const environments: PEImpactAnalysisEnvironmentV1[] =
    getIATasksFromPipelineStages(stages);
  environments.forEach((environment) => {
    if (environment.pipelineDestinationId === pipelineDestinationId)
      impactedDestinationIds.push(environment.pipelineDestinationId);
  });

  return impactedDestinationIds;
}

export function getProjectTypeString(projectType: ProjectTypeV1): string {
  if (projectType === 'CONTROL_REPO') {
    return 'control repo';
  }
  return 'module';
}

export const checkPullRequestExists = (
  stages: ProjectPipelineStageV1[],
): boolean => {
  return stages.some((stage) => {
    return stage?.pipelineGate?.projectPipelineGateType === 'PULL_REQUEST';
  });
};
