import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from '@puppet/react-components';
import alerts from '@state/ui/alerts';
import { useAppDispatch } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import { LINKS } from 'src/routes';
import {
  useConnectAzureDevOpsV1,
  UseConnectAzureDevOpsV1Result,
  useConnectBitbucketCloudV1,
  UseConnectBitbucketCloudV1Result,
  useConnectGitHubV1,
  UseConnectGitHubV1Result,
} from '@services/cd4pe/vcs';
import getProviderDisplayName from '@utils/vcs/displayNameHelper';
import * as Provider from '@utils/vcs/providerConsts';

type OAuthProvider = 'GITHUB' | 'BITBUCKET' | 'AZURE_DEVOPS';

const oauthProviderMap: {
  [provider: string]: () =>
    | UseConnectGitHubV1Result
    | UseConnectBitbucketCloudV1Result
    | UseConnectAzureDevOpsV1Result;
} = {
  [Provider.GITHUB]: useConnectGitHubV1,
  [Provider.BITBUCKET]: useConnectBitbucketCloudV1,
  [Provider.AZURE_DEV_OPS]: useConnectAzureDevOpsV1,
};

const OAuthRedirect = ({ provider }: { provider: OAuthProvider }) => {
  const { t } = useTranslation('codeDelivery');
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const providerDisplayName = getProviderDisplayName(provider);
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const code = searchParams.get('code');
  const error =
    searchParams.get('error_description') ?? searchParams.get('error');

  const navigateOnError = useCallback(
    (msg: string) => {
      // this recreates the behaviour of the old oauth flow which navigates to a 403 screen on error,
      // it should be changed when we complete the screen migration
      navigate(
        LINKS.forbidden({
          query: {
            msg,
          },
        }),
      );
    },
    [navigate],
  );

  const { mutate: connectOAuthProvider } = oauthProviderMap[provider]();

  useEffect(() => {
    if (error) {
      navigateOnError(error);
    } else if (!state || !code) {
      navigateOnError(
        t('oauth.alerts.connect.missing.values', {
          provider: providerDisplayName,
        }),
      );
    }

    if (code && state) {
      connectOAuthProvider(
        { code, state },
        {
          onSuccess: ({ workspaceName }) => {
            appDispatch(
              alerts.actions.createAlert({
                type: 'Success',
                message: t('addSourceControl.alerts.connect.success', {
                  provider: providerDisplayName,
                }),
              }),
            );
            navigate(
              LINKS.settings.listSourceControl({
                path: { workspace: workspaceName },
              }),
            );
          },
          onError: () => {
            navigateOnError(
              t('addSourceControl.oauth.error', {
                provider: providerDisplayName,
              }),
            );
          },
        },
      );
    }
  }, [
    appDispatch,
    code,
    connectOAuthProvider,
    error,
    navigate,
    navigateOnError,
    providerDisplayName,
    state,
    t,
  ]);

  return <Loading data-testid="loading-spinner" size="large" />;
};

export default OAuthRedirect;
