import React, { useState, useEffect, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { Table } from '@puppet/data-grid';
import { useTranslation } from 'react-i18next';
import { Link } from '@puppet/react-components';
import NodeStatus from 'inventory/components/NodeStatus';
import changeStatusText from 'inventory/utils/changeStatusText';

import './ReportsTable.scss';
import generateReportURL from '../../utils/generateReportURL';

export const columns = (t) => [
  { label: t('reportsTable.columns.date'), dataKey: 'reportTimeStamp' },
  { label: t('reportsTable.columns.status'), dataKey: 'reportStatus' },
  { label: t('reportsTable.columns.view'), dataKey: 'reportView' },
];

const ReportsTable = ({
  reportData,
  emptyStateMessage,
  emptyStateHeader,
  loading,
}) => {
  const source = reportData?.hosts?.nodes?.[0]?.source ?? '';
  const version = reportData?.hosts?.nodes[0]?.peServerVersion ?? '';
  const { t } = useTranslation('inventory');

  const constructReportTableData = useCallback(
    (data) => {
      const reports = data?.hosts?.nodes?.[0]?.reportsConnection?.reports ?? [];
      return reports.map(
        ({ endTime, status, correctiveChange, hash }, index) => {
          const reportLink = generateReportURL(version, source, hash);

          const timeStamp = endTime
            ? format(parseISO(endTime), 'MMM dd, yyyy p')
            : '';
          return {
            id: index,
            reportTimeStamp: timeStamp,
            reportStatus: (
              <NodeStatus status={changeStatusText(status, correctiveChange)} />
            ),
            reportView: (
              <Link as="a" href={reportLink} target="_blank">
                {t('reportsTable.row.reportLink')}
              </Link>
            ),
          };
        },
      );
    },
    [source, t, version],
  );

  const [reportTableData, setReportTableData] = useState(
    constructReportTableData(reportData),
  );

  useEffect(() => {
    setReportTableData(constructReportTableData(reportData));
  }, [constructReportTableData, reportData]);

  return (
    <div className="nd-report-view">
      <Table
        data={reportTableData}
        columns={columns(t)}
        emptyStateHeader={emptyStateHeader}
        emptyStateMessage={emptyStateMessage}
        loading={loading}
        loadingMessage={t('nodeTable.loading.message')}
      />
    </div>
  );
};
export default ReportsTable;

ReportsTable.propTypes = {
  reportData: PropTypes.instanceOf(Object).isRequired,
  emptyStateMessage: PropTypes.string.isRequired,
  emptyStateHeader: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
