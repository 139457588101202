/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, useEffect, useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Heading,
  Input,
  Loading,
  Overlay,
  TooltipHoverArea,
} from '@puppet/react-components';
import { Table } from '@puppet/data-grid';
import useRefList, { RefListSetter } from '@hooks/useRefList';
import { WorkspaceMemberV1 } from '@utils/api/cd4pe';
import useWorkspaceName from '@hooks/useWorkspaceName';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { LINKS } from 'src/routes';
import { defaultUserListState, reducer, UserListState } from './reducer';
import {
  deleteUser,
  filterUsers,
  getUsers,
  sortUsers,
  toggleConfirmDelete,
  UserListActions,
} from './actions';

const deleteErrorAlert = (state: UserListState) => {
  if (!state.deleteUserError) {
    return null;
  }
  return (
    <Alert type="danger" className="user-list-error">
      {state.deleteUserError}
    </Alert>
  );
};

const userActions = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<UserListActions>,
  id: number,
  confirmDeleteId: number | null,
  setRef: RefListSetter,
) => (
  <TooltipHoverArea
    tooltip={t('userList.iconButtons.delete.tooltip')}
    anchor="bottom"
  >
    <Button
      ref={setRef(id)}
      type="transparent"
      icon="trash"
      aria-label={t('userList.iconButtons.delete.ariaLabel', { id })}
      onClick={() => toggleConfirmDelete(dispatch, id)}
      disabled={confirmDeleteId === id}
    />
  </TooltipHoverArea>
);

const userToRow = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<UserListActions>,
  workspace: string | null,
  user: WorkspaceMemberV1,
  confirmDeleteId: number | null,
  setRef: RefListSetter,
) => ({
  id: user.userId,
  user: user.username,
  actions: userActions(t, dispatch, user.userId, confirmDeleteId, setRef),
});

const tableColumns = (t: TFunction<'codeDelivery'>) => [
  {
    label: t('userList.table.header.user'),
    dataKey: 'user',
    sortable: true,
    className: 'user-list-table__cell--truncate',
  },
  {
    label: t('userList.table.header.actions'),
    dataKey: 'actions',
    className: 'user-list-table__cell-actions',
    style: { textAlign: 'right' },
  },
];

const usersTable = (
  t: TFunction<'codeDelivery'>,
  state: UserListState,
  dispatch: Dispatch<UserListActions>,
  workspace: string | null,
  setRef: RefListSetter,
) => {
  const rows = state.filteredUsers.map((user: WorkspaceMemberV1) =>
    userToRow(t, dispatch, workspace, user, state.confirmDeleteId, setRef),
  );
  const columns = tableColumns(t);
  const onSort = (direction: string, sortDataKey: 'user') =>
    sortUsers(direction, sortDataKey, state.users, state.query, dispatch);

  const emptyMessage =
    state.query === ''
      ? t('userList.table.empty.messageBody')
      : t('userList.table.empty.messageBodyNoResults');

  return (
    <div className="user-list-table">
      <Table
        data={rows}
        columns={columns}
        emptyStateHeader={t('userList.table.empty.messageHeader')}
        emptyStateMessage={emptyMessage}
        onSort={onSort}
        sortedColumn={state.sort}
      />
    </div>
  );
};

const body = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<UserListActions>,
  state: UserListState,
  workspace: string | null,
  setRef: RefListSetter,
) => {
  if (state.getUsersError) {
    return <Alert type="danger">{state.getUsersError}</Alert>;
  }

  if (state.getUsersLoading && state.users.length === 0) {
    return <Loading data-testid="user-list-spinner" />;
  }

  return (
    <div className="user-list-table__wrapper">
      <Input
        className="user-list-table__search"
        placeholder={t('userList.fields.search.placeholder')}
        value={state.query}
        onChange={(query: string) => filterUsers(query, state.users, dispatch)}
        name="searchByUserName"
        icon="search"
      />
      {usersTable(t, state, dispatch, workspace, setRef)}
    </div>
  );
};

const UserList = () => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const [state, dispatch] = useReducer(reducer, defaultUserListState);
  const [getRef, setRef] = useRefList();

  const fetchUsers = () => {
    if (workspaceId) {
      getUsers(workspaceId, state.query, state.sort, dispatch, t);
    }
  };

  useEffect(fetchUsers, []);

  const showConfirmDelete =
    state.confirmDeleteId !== null
      ? `${state.filteredUsers.map((x) => `${x.userId}`).join()}${
          state.deleteUserError
        }`
      : false;

  const maybeDelete = () => {
    if (state.confirmDeleteId || state.confirmDeleteId === 0) {
      deleteUser(
        workspaceId,
        state.confirmDeleteId,
        state.users,
        state.query,
        state.sort,
        dispatch,
        t,
      );
    }
  };

  return (
    <div className="user-list">
      <div className="user-list-header__cta">
        <Heading as="h3">{t('userList.page.title')}</Heading>
        <Button
          as={NavLink}
          to={LINKS.settings.addUser({ path: { workspace } })}
        >
          {t('userList.buttons.newUser.label')}
        </Button>
      </div>

      <div className="user-list-body">
        {deleteErrorAlert(state)}
        {body(t, dispatch, state, workspace, setRef)}
      </div>
      <Overlay
        show={showConfirmDelete}
        target={getRef(state.confirmDeleteId)}
        position="right"
        align="inner"
      >
        <div className="user-list__confirm-delete">
          <Button
            icon="trash"
            type="danger"
            aria-label={t('userList.buttons.delete.ariaLabel', {
              id: state.confirmDeleteId,
            })}
            onClick={() => maybeDelete()}
          >
            {t('userList.buttons.delete.label')}
          </Button>
          <Button
            type="tertiary"
            onClick={() => toggleConfirmDelete(dispatch)}
            aria-label={t('userList.buttons.cancel.ariaLabel')}
          >
            {t('userList.buttons.cancel.label')}
          </Button>
        </div>
      </Overlay>
    </div>
  );
};

export default UserList;
