import React from 'react';
import classnames from 'classnames';
import { Heading } from '@puppet/react-components';

interface Props
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {}

const StatusBar = ({ children, className }: Props) => (
  <div className={classnames('status-bar', className)}>{children}</div>
);

const StatusBarSection = ({ children, className }: Props) => (
  <div className={classnames('status-bar-section', className)}>{children}</div>
);

StatusBarSection.Header = ({ children, className }: Props) => (
  <Heading className={classnames('status-bar-section-header', className)}>
    {children}
  </Heading>
);

StatusBarSection.Detail = ({ children, className }: Props) => (
  <div className={classnames('status-bar-section-detail', className)}>
    {children}
  </div>
);

StatusBar.Section = StatusBarSection;

export default StatusBar;
