/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, useEffect, useReducer } from 'react';
import { Trans, TFunction, useTranslation } from 'react-i18next';
import { NavLink, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Heading,
  Input,
  Link,
  Loading,
  Overlay,
  TooltipHoverArea,
} from '@puppet/react-components';
import { Table } from '@puppet/data-grid';
import useRefList, { RefListSetter } from '@hooks/useRefList';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { RbacGroupV1 } from '@utils/api/cd4pe';
import { LINKS } from 'src/routes';
import GroupLink from './components/GroupLink';
import {
  deleteGroup,
  filterGroups,
  getGroups,
  GroupListActions,
  sortGroups,
  toggleConfirmDelete,
} from './actions';
import { groupListDefaultState, GroupListState, reducer } from './reducer';

const deleteErrorAlert = (state: GroupListState) => {
  if (!state.deleteGroupError) {
    return null;
  }
  return (
    <Alert type="danger" className="group-list-error">
      {state.deleteGroupError}
    </Alert>
  );
};

const groupActions = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<GroupListActions>,
  id: number,
  isBuiltIn: boolean,
  name: string,
  confirmDeleteId: number | null,
  setRef: RefListSetter,
) => (
  <TooltipHoverArea
    tooltip={t(
      isBuiltIn
        ? 'groupList.iconButtons.delete.builtInTooltip'
        : 'groupList.iconButtons.delete.tooltip',
    )}
    anchor="right"
  >
    <Button
      ref={setRef(id)}
      type="transparent"
      icon="trash"
      aria-label={t('groupList.iconButtons.delete.ariaLabel', { id })}
      data-testid={`delete-group-${name}`}
      onClick={() => toggleConfirmDelete(dispatch, id)}
      disabled={confirmDeleteId === id || isBuiltIn}
    />
  </TooltipHoverArea>
);

const groupToRow = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<GroupListActions>,
  group: RbacGroupV1,
  confirmDeleteId: number | null,
  setRef: RefListSetter,
) => ({
  id: group.id,
  name: group.name,
  description: group.description,
  actions: groupActions(
    t,
    dispatch,
    group.id,
    group.builtIn,
    group.name,
    confirmDeleteId,
    setRef,
  ),
});

const tableColumns = (t: TFunction<'codeDelivery'>) => [
  {
    label: t('groupList.table.header.name'),
    dataKey: 'name',
    sortable: true,
    className: 'group-list-table__cell--truncate',
    cellRenderer: (data: { rowData: RbacGroupV1 }) => (
      <GroupLink
        key={data.rowData.id}
        id={data.rowData.id}
        name={data.rowData.name}
      />
    ),
  },
  {
    label: t('groupList.table.header.description'),
    dataKey: 'description',
    sortable: true,
    className: 'group-list-table__cell--wrap',
  },
  {
    label: t('groupList.table.header.actions'),
    dataKey: 'actions',
    className: 'group-list-table__cell-actions',
    style: { textAlign: 'right' },
  },
];

const groupsTable = (
  t: TFunction<'codeDelivery'>,
  state: GroupListState,
  dispatch: Dispatch<GroupListActions>,
  workspace: string,
  setRef: RefListSetter,
  newGroupName: string | null,
) => {
  const rows = state.filteredGroups.map((group: RbacGroupV1) =>
    groupToRow(t, dispatch, group, state.confirmDeleteId, setRef),
  );
  const columns = tableColumns(t);
  const onSort = (direction: string, sortDataKey: 'name' | 'description') =>
    sortGroups(
      {
        direction,
        sortDataKey,
        query: state.query,
        groups: state.groups,
      },
      dispatch,
    );

  const noGropupsCta = (
    <Trans
      t={t}
      i18nKey="groupList.table.empty.messageBody"
      components={[
        <Link
          as={NavLink}
          to={LINKS.settings.addGroup({
            path: { workspace },
          })}
        />,
      ]}
    />
  );

  return (
    <div className="group-list-table">
      <Table
        rowKey="id"
        data-testid="group-list-table"
        data={rows}
        columns={columns}
        emptyStateHeader={t('groupList.table.empty.messageHeader')}
        emptyStateMessage={noGropupsCta}
        onSort={onSort}
        sortedColumn={state.sort}
        rowClassName={(data: RbacGroupV1) =>
          newGroupName &&
          data.name.toLowerCase() === newGroupName?.toLowerCase()
            ? 'group-list-table__row--new'
            : ''
        }
      />
    </div>
  );
};

const body = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<GroupListActions>,
  state: GroupListState,
  workspace: string,
  setRef: RefListSetter,
  newGroupName: string | null,
) => {
  if (state.getGroupsError) {
    return <Alert type="danger">{state.getGroupsError}</Alert>;
  }

  if (state.getGroupsLoading && state.groups.length === 0) {
    return <Loading data-testid="group-list-spinner" />;
  }

  return (
    <div className="group-list-table__wrapper">
      <Input
        className="group-list-table__search"
        placeholder={t('groupList.fields.search.placeholder')}
        value={state.query}
        onChange={(v: string) =>
          filterGroups({ query: v, groups: state.groups }, dispatch)
        }
        name="searchByGroupName"
        icon="search"
        data-testid="group-list-search-input"
      />
      {groupsTable(t, state, dispatch, workspace, setRef, newGroupName)}
    </div>
  );
};

const GroupList = () => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const [state, dispatch] = useReducer(reducer, groupListDefaultState);
  const [getRef, setRef] = useRefList();
  const [searchParams] = useSearchParams();
  const newGroupName = searchParams.get('newGroupName');

  const fetchGroups = () => {
    if (workspaceId) {
      getGroups(
        {
          workspaceId,
          query: state.query,
          sort: state.sort,
        },
        dispatch,
        t,
      );
    }
  };

  useEffect(fetchGroups, []);

  const showConfirmDelete =
    state.confirmDeleteId !== null
      ? `${state.filteredGroups
          .map(({ id }: { id: number }) => `${id}`)
          .join()}${state.deleteGroupError}`
      : false;

  const maybeDelete = () => {
    if (state.confirmDeleteId || state.confirmDeleteId === 0) {
      deleteGroup(
        {
          workspaceId,
          groupId: state.confirmDeleteId,
          groups: state.groups,
          query: state.query,
          sort: state.sort,
        },
        dispatch,
        t,
      );
    }
  };

  return (
    <div className="group-list">
      <div className="group-list-header__cta">
        <Heading as="h3">{t('groupList.page.title')}</Heading>
        <Button
          as={NavLink}
          to={LINKS.settings.addGroup({ path: { workspace } })}
          data-testid="group-list-add-group-button"
        >
          {t('groupList.buttons.newGroup.label')}
        </Button>
      </div>

      <div className="group-list-body">
        {deleteErrorAlert(state)}
        {body(t, dispatch, state, workspace, setRef, newGroupName)}
      </div>
      <Overlay
        show={showConfirmDelete}
        target={getRef(state.confirmDeleteId)}
        position="right"
        align="inner"
      >
        <div className="group-list__confirm-delete">
          <Button
            icon="trash"
            type="danger"
            aria-label={t('groupList.buttons.delete.ariaLabel', {
              id: state.confirmDeleteId,
            })}
            onClick={() => maybeDelete()}
            data-testid="group-list-confirm-delete-button"
          >
            {t('groupList.buttons.delete.label')}
          </Button>
          <Button
            type="tertiary"
            onClick={() => toggleConfirmDelete(dispatch)}
            aria-label={t('groupList.buttons.cancel.ariaLabel')}
          >
            {t('groupList.buttons.cancel.label')}
          </Button>
        </div>
      </Overlay>
    </div>
  );
};

export default GroupList;
