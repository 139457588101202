import { ListWorkspaceMembersResponseV1 } from '@utils/api/cd4pe';
import { UserListActions } from './actions';
import * as at from './actionTypes';

export interface UserListState {
  getUsersLoading: boolean;
  users: ListWorkspaceMembersResponseV1['workspaceMembers'];
  getUsersError: string | null;
  confirmDeleteId: null | number;
  deleteUserLoading: boolean;
  deleteUserError: string | null;
  filteredUsers: ListWorkspaceMembersResponseV1['workspaceMembers'];
  query: string;
  sort: { direction: string; sortDataKey: 'user' };
}

export const defaultUserListState: UserListState = {
  getUsersLoading: false,
  users: [],
  getUsersError: null,
  confirmDeleteId: null,
  deleteUserLoading: false,
  deleteUserError: null,
  filteredUsers: [],
  query: '',
  sort: {
    direction: 'asc',
    sortDataKey: 'user',
  },
};

export const reducer = (
  state: UserListState,
  action: UserListActions,
): UserListState => {
  switch (action.type) {
    case at.GET_USERS_LOADING:
      return {
        ...state,
        getUsersLoading: true,
      };
    case at.GET_USERS_COMPLETE:
      return {
        ...state,
        getUsersLoading: false,
        users: action.payload.workspaceMembers,
        getUsersError: null,
      };
    case at.GET_USERS_ERROR:
      return {
        ...state,
        getUsersLoading: false,
        users: [],
        getUsersError: action.payload,
        filteredUsers: [],
        confirmDeleteId: null,
      };
    case at.DELETE_USER_LOADING:
      return {
        ...state,
        deleteUserLoading: true,
      };
    case at.DELETE_USER_COMPLETE:
      return {
        ...state,
        users: action.payload,
        deleteUserLoading: false,
        deleteUserError: null,
      };
    case at.DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserError: action.payload,
      };
    case at.FILTER_USERS: {
      return {
        ...state,
        query: action.payload.query ?? state.query,
        filteredUsers: action.payload.filteredUsers,
        confirmDeleteId:
          state.confirmDeleteId !== null &&
          action.payload.filteredUsers.find(
            (t) => t.userId === state.confirmDeleteId,
          )
            ? state.confirmDeleteId
            : null,
      };
    }
    case at.TOGGLE_CONFIRM_DELETE:
      return {
        ...state,
        confirmDeleteId: action.payload ?? null,
      };
    case at.SORT_USERS:
      return {
        ...state,
        users: action.payload.users,
        sort: action.payload.sort,
      };
    default:
      return state;
  }
};
