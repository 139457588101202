import React from 'react';
import { Heading, Text } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';

const ChooseWorkspaceInfo = () => {
  const { t } = useTranslation('codeDelivery');

  return (
    <>
      <Text className="chooseWorkspace__description">
        {t('chooseWorkspace.choose.description')}
      </Text>
      <hr className="chooseWorkspace__hr" />
      <div className="chooseWorkspace-content__item">
        <Heading className="chooseWorkspace-content__subtitle" as="h5">
          {t('chooseWorkspace.choose.join.title')}
        </Heading>
        <Text>{t('chooseWorkspace.choose.join.description')}</Text>
      </div>
      <div className="chooseWorkspace-content__item">
        <Heading className="chooseWorkspace-content__subtitle" as="h5">
          {t('chooseWorkspace.choose.create.title')}
        </Heading>
        <Text>{t('chooseWorkspace.choose.create.description')}</Text>
      </div>
    </>
  );
};

export default ChooseWorkspaceInfo;
