import CommonForm from '@components/CommonForm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Loading, Text } from '@puppet/react-components';
import Definitions from '@components/Definitions';
import { useListIntegrationsV1 } from '@services/cd4pe/vcs';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import getProviderDisplayName from '@utils/vcs/displayNameHelper';
import { VcsProviderV1 } from '@puppet/cd4pe-client-ts';
import PageLayout from '@components/PageLayout';
import Cd4peError from '@components/Cd4peError';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { useNavigate } from 'react-router-dom';
import { LINKS } from 'src/routes';
import AddSourceControlForm from './components/AddSourceControlForm';
import AddSourceControlHeader from './components/AddSourceControlHeader';

type SelectOptionArray = Array<{
  value: string;
  label: string;
  disabled: boolean;
}>;

const AddSourceControl = () => {
  const { t } = useTranslation('codeDelivery');
  const [sourceControl, setSourceControl] = useState<VcsProviderV1 | undefined>(
    undefined,
  );
  const [sourceControlOptions, setSourceControlOptions] =
    useState<SelectOptionArray>([]);
  const workspaceId = useWorkspaceDomain();
  const { data, isLoading, error } = useListIntegrationsV1({ workspaceId });
  const appDispatch = useAppDispatch();
  const workspaceName = useWorkspaceName();
  const navigate = useNavigate();
  const navigateToSettings = () =>
    navigate(
      LINKS.settings.listSourceControl({ path: { workspace: workspaceName } }),
    );

  const onSuccess = (provider: string) => {
    appDispatch(
      alerts.actions.createAlert({
        type: 'Success',
        message: t('addSourceControl.alerts.connect.success', {
          provider,
        }),
      }),
    );
    navigateToSettings();
  };

  useEffect(() => {
    if (data?.vcsIntegrations) {
      const options: SelectOptionArray = [];
      data.vcsIntegrations.forEach(({ provider, connected }) =>
        options.push({
          value: provider,
          label: getProviderDisplayName(provider),
          disabled: connected as boolean,
        }),
      );
      setSourceControlOptions(options);
    }
  }, [data]);

  if (isLoading) {
    return <Loading data-testid="loading-spinner" size="large" />;
  }

  return (
    <PageLayout>
      <AddSourceControlHeader />
      <PageLayout.Content>
        <CommonForm
          className="add-source-control-form"
          submitLabel={t('addSourceControl.form.submitButton')}
          values={{ sourceControl }}
          onChange={(e, values) => setSourceControl(values[e])}
        >
          <CommonForm.Section>
            <CommonForm.Section.Main>
              <h3 className="add-source-control-form-section__header">
                {t('addSourceControl.form.header')}
              </h3>
              <div
                className="add-source-control-form-select-field__wrapper"
                data-testid="select-source-wrapper"
              >
                <Form.Field
                  type="select"
                  name="sourceControl"
                  label={t('addSourceControl.form.select.label')}
                  placeholder={t('addSourceControl.form.select.placeholder')}
                  options={sourceControlOptions}
                  disabled={isLoading || error}
                  required
                />
                <Cd4peError error={error} />
              </div>
            </CommonForm.Section.Main>
            <CommonForm.Section.Sidebar>
              <Definitions.Entry icon="info-circle">
                <Definitions.Entry.Content>
                  <h3 className="add-source-control-form-info-section__header">
                    {t('addSourceControl.form.info.header')}
                  </h3>
                  <Text className="add-source-control-form-info-section__body">
                    {t('addSourceControl.form.info.body')}
                  </Text>
                </Definitions.Entry.Content>
              </Definitions.Entry>
            </CommonForm.Section.Sidebar>
          </CommonForm.Section>
        </CommonForm>
        <div
          className="add-source-control-credentials"
          data-testid="source-control-credentials-form"
        >
          <AddSourceControlForm
            integration={data?.vcsIntegrations.find(
              (v) => v.provider === sourceControl,
            )}
            onSuccess={onSuccess}
            onCancel={navigateToSettings}
          />
        </div>
      </PageLayout.Content>
    </PageLayout>
  );
};

export default AddSourceControl;
