import CommonForm from '@components/CommonForm';
import React from 'react';
import { useSetGroupPrivilegesV1 } from '@services/cd4pe/groups';
import { Text, Loading, Link, Checkbox } from '@puppet/react-components';
import { Trans, useTranslation } from 'react-i18next';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import Cd4peError from '@components/Cd4peError';
import docsLinks from '@codeDelivery/utils/docsLinks';
import {
  buildEditRequest,
  isAllPrivilegesSelected,
  isAnyPrivilegeSelected,
  PrivilegeFormAction,
  setAllPrivileges,
} from './utils/utils';
import PrivilegeCategory from './components/PrivilegeCategory';
import PrivilegeAction from './components/PrivilegeAction';
import useGroupPrivilegesState from './useGroupPrivilegesState';

interface Props {
  editable: boolean;
  groupId: number;
  onSuccess?: () => void;
  onCancel?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

const defaultProps = {
  onSuccess: () => {},
  onCancel: () => {},
  confirmLabel: null,
  cancelLabel: null,
};

const GroupPrivilegesForm = ({
  editable,
  groupId,
  onSuccess,
  onCancel,
  confirmLabel,
  cancelLabel,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const { state, setState, isLoading, errors } = useGroupPrivilegesState(
    groupId,
    editable,
  );
  const editGroupPrivilegesV1 = useSetGroupPrivilegesV1();
  const allSelected = isAllPrivilegesSelected(state);
  const someSelected = isAnyPrivilegeSelected(state) && !allSelected;

  const onSubmit = () => {
    const requestBody = buildEditRequest(state);

    editGroupPrivilegesV1.mutate(
      {
        workspaceId,
        groupId,
        requestBody,
      },
      { onSuccess },
    );
  };

  const setAction = (categoryName: string, nAction: PrivilegeFormAction) => {
    setState(
      state.map((category) => {
        if (category.name !== categoryName) {
          return category;
        }

        const newActions = category.actions.map((action) =>
          action.type !== nAction.type ? action : nAction,
        );
        return { ...category, actions: newActions };
      }),
    );
  };

  const setSubsets = (categoryName: string, newSubsets: string[]) => {
    setState(
      state.map((category) => {
        if (category.name !== categoryName) {
          return category;
        }

        if (newSubsets.length === 0) {
          return {
            ...category,
            subsets: newSubsets,
            actions: category.actions.map((action) => ({
              ...action,
              usesSubsets: false,
            })),
          };
        }

        return { ...category, subsets: newSubsets };
      }),
    );
  };

  if (isLoading) {
    return <Loading data-testid="privileges-form-loading" />;
  }

  if (errors.aError || errors.cError) {
    return (
      <>
        <Cd4peError error={errors.aError} />
        <Cd4peError error={errors.cError} />
      </>
    );
  }

  return (
    <CommonForm
      onSubmit={onSubmit}
      submittable={editable}
      submitLabel={confirmLabel ?? t('privilege.form.submit')}
      cancellable={editable}
      cancelLabel={cancelLabel ?? t('privilege.form.cancel')}
      submitting={editGroupPrivilegesV1.isLoading}
      onCancel={onCancel}
      data-testid="privileges-form"
    >
      <CommonForm.Section className="privilege__section">
        <div className="privilege__wrapper">
          <Text>
            <Trans
              t={t}
              i18nKey="viewPipeline.dialog.managePipelines.as.code.link"
            >
              <Link href={docsLinks().permissionsReference} />
            </Trans>
          </Text>
          {editable && (
            <div className="privilege-toggle-all__container">
              <Checkbox
                name={t('privilege.form.toggleAll')}
                label={t('privilege.form.toggleAll')}
                value={allSelected}
                indeterminate={someSelected}
                data-indeterminate={someSelected}
                onChange={() => {
                  setState(setAllPrivileges(state, !allSelected));
                }}
                data-testid="privileges-form-toggle-all"
              />
            </div>
          )}
          <div className="privilege-category__wrapper">
            {state.map((category) => (
              <div key={category.name}>
                <div className="privilege-category__br" />
                <PrivilegeCategory
                  editable={editable}
                  category={category}
                  setSubsets={(newSubsets) =>
                    setSubsets(category.name, newSubsets)
                  }
                >
                  {category.actions.map((action) => {
                    return (
                      <PrivilegeAction
                        key={action.type}
                        editable={editable}
                        action={action}
                        setAction={(nAction) =>
                          setAction(category.name, nAction)
                        }
                        categoryName={category.name}
                        subsetsAvailable={!category.subsets?.length}
                      />
                    );
                  })}
                </PrivilegeCategory>
              </div>
            ))}
          </div>
        </div>
      </CommonForm.Section>
      <Cd4peError error={editGroupPrivilegesV1.error} />
    </CommonForm>
  );
};

export default GroupPrivilegesForm;

GroupPrivilegesForm.defaultProps = defaultProps;
