import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '@components/Layout';
import Cd4pePage from '@views/Cd4pe';
import { ROUTE_TYPES } from '@utils/constants';
import { getSegment } from 'src/routes';

const getElement = (pageTitle: string, isPublic = false) =>
  isPublic ? (
    <main className="app-main-content">
      <Cd4pePage pageTitle={pageTitle} />
    </main>
  ) : (
    <Layout routeType={ROUTE_TYPES.cd4pe}>
      <Cd4pePage pageTitle={pageTitle} />
    </Layout>
  );

const Cd4pe = (
  <Route path={getSegment('cd4pe')}>
    <Route index element={getElement('')} />
    <Route path={getSegment('cd4pe.root')}>
      <Route index element={getElement('RootAccounts')} />
      <Route path={getSegment('cd4pe.root.settings')}>
        <Route index element={getElement('Root')} />
        <Route
          path={getSegment('cd4pe.root.settings.puppetEnterprise')}
          element={getElement('PuppetEnterprise')}
        />
      </Route>
      <Route
        path={getSegment('cd4pe.root.jobHardware')}
        element={getElement('Servers')}
      />
      <Route
        path={getSegment('cd4pe.root.workspaces')}
        element={getElement('RootWorkspaces')}
      />
      <Route
        path={getSegment('cd4pe.root.jobHardwareCapability')}
        element={getElement('JobHardwareCapability')}
      />
    </Route>
    <Route
      path={getSegment('cd4pe.ssoLogout')}
      element={getElement('SsoLogout')}
    />
    <Route
      path={getSegment('cd4pe.samlStatus')}
      element={getElement('SamlStatus')}
    />
    <Route path={getSegment('cd4pe.workspace')}>
      <Route index element={getElement('')} />
      <Route path={getSegment('cd4pe.workspace.repositories')}>
        <Route index element={getElement('Repos')} />
        <Route
          path={getSegment('cd4pe.workspace.repositories.name')}
          element={getElement('RepoDetails')}
        />
      </Route>
      <Route path={getSegment('cd4pe.workspace.modules')}>
        <Route index element={getElement('Modules')} />
        <Route
          path={getSegment('cd4pe.workspace.modules.name')}
          element={getElement('ModuleDetails')}
        />
      </Route>
      <Route path={getSegment('cd4pe.workspace.deployments')}>
        <Route path=":id" element={getElement('DeploymentDetailsV2')} />
      </Route>
      <Route path={getSegment('cd4pe.workspace.moduleDeployments')}>
        <Route
          path={getSegment('cd4pe.workspace.moduleDeployments.id')}
          element={getElement('ModuleDeploymentDetails')}
        />
      </Route>
      <Route path={getSegment('cd4pe.workspace.analysis')}>
        <Route path={getSegment('cd4pe.workspace.analysis.id')}>
          <Route index element={getElement('AnalysisDetails')} />
          <Route
            path={getSegment('cd4pe.workspace.analysis.id.environmentResultId')}
            element={getElement('AnalysisEnvironmentDetails')}
          />
        </Route>
      </Route>
    </Route>
    <Route path={getSegment('cd4pe.username')}>
      <Route
        path={getSegment('cd4pe.username.profile')}
        element={getElement('ProfilePage')}
      />
      <Route
        path={getSegment('cd4pe.username.messages')}
        element={getElement('Messages')}
      />
      <Route
        path={getSegment('cd4pe.username.manageWorkspaces')}
        element={getElement('ManageWorkspaces')}
      />
    </Route>
  </Route>
);

export default Cd4pe;
