import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AuthTokensV1Service,
  Cd4peApiError,
  handleQueryRequest,
  MutationHook,
  QueryHook,
} from '@utils/api/cd4pe';
import {
  AjaxError,
  LoginPayload,
  LoginResponse,
  performLogin,
  performRootLogin,
  signOut,
} from './ajax';

const AUTH_GET_METHOD_TAG = 'getAuthMethod';
const AUTH_LIST_TOKENS_TAG = 'listAuthTokens';

/**
 * useRevokeToken
 */

type UseRevokeToken = MutationHook<
  typeof AuthTokensV1Service,
  'revokeTokenV1',
  Cd4peApiError
>;

export type UseRevokeTokenResult = ReturnType<UseRevokeToken>;

export const useRevokeToken: UseRevokeToken = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => handleQueryRequest(AuthTokensV1Service.revokeTokenV1(payload)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AUTH_LIST_TOKENS_TAG]);
      },
    },
  );
};

/**
 * useGetAuthMethod
 */

type UseGetAuthMethod = QueryHook<
  typeof AuthTokensV1Service,
  'getAuthMethodV1',
  Cd4peApiError
>;

// eslint-disable-next-line import/prefer-default-export
export const useGetAuthMethod: UseGetAuthMethod = () =>
  useQuery([AUTH_GET_METHOD_TAG], async () =>
    handleQueryRequest(AuthTokensV1Service.getAuthMethodV1()),
  );

/**
 *
 * Non-OpenAPI endpoints below
 *
 */

/**
 * useLogin
 */

export const useLogin = () =>
  useMutation<LoginResponse, AjaxError, LoginPayload>(performLogin);

/**
 * useRootLogin
 */

export const useRootLogin = () =>
  useMutation<LoginResponse, AjaxError, LoginPayload>(performRootLogin);

/**
 * useSignOut
 */

export const useSignOut = () => {
  const queryClient = useQueryClient();
  return useMutation(signOut, {
    onSuccess: () => queryClient.removeQueries(),
  });
};

/**
 * useListTokensV1
 */

type UseListTokensV1 = QueryHook<
  typeof AuthTokensV1Service,
  'listTokensV1',
  Cd4peApiError
>;

export const useListTokensV1: UseListTokensV1 = (payload, options) =>
  useQuery(
    [AUTH_LIST_TOKENS_TAG, payload.userId],
    () => handleQueryRequest(AuthTokensV1Service.listTokensV1(payload)),
    options,
  );

/**
 * useCreateTokenV1
 */
type UseCreateTokenV1 = MutationHook<
  typeof AuthTokensV1Service,
  'createTokenV1',
  Cd4peApiError
>;

export type UseCreateTokenV1Result = ReturnType<UseCreateTokenV1>;

export const useCreateTokenV1: UseCreateTokenV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => handleQueryRequest(AuthTokensV1Service.createTokenV1(payload)),
    {
      onSuccess() {
        queryClient.invalidateQueries([AUTH_LIST_TOKENS_TAG]);
      },
    },
  );
};
