import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input, Button, Link } from '@puppet/react-components';
import { Table, TablePageSelector } from '@puppet/data-grid';
import { useQuery, useMutation } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { KEYBOARD_KEYS } from 'utils/constants';
import GET_FULL_SAVED_VIEWS from 'inventory/services/graphql/queries/queryFullSavedViews.gql';
import SET_FAVOURITE_VIEW from 'inventory/services/graphql/queries/mutationSetFavourite.gql';
import PageHeader from 'components/PageHeader';
import Breadcrumbs from 'components/Breadcrumbs';

import {
  selectUser,
  selectWorkspaceName,
  selectWorkspaceDomain,
} from 'state/session/selectors';
import QueryError from 'inventory/components/QueryError';
import { LINKS } from 'src/routes';
import DeleteViewModal from './DeleteViewModal';
import PinViewModal from './PinViewModal';
import client from '../../services/graphql';
import './StoredValue.scss';

const StoredViews = () => {
  const { t } = useTranslation('inventory');
  const workspaceName = useSelector((state) => selectWorkspaceName(state));
  const { userName } = useSelector((state) => selectUser(state));
  const domain = useSelector((state) => selectWorkspaceDomain(state));
  const [savedViews, setSavedViews] = useState([]);
  const [pagination, setPaginationInfo] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [currentOffSet, setCurrentOffset] = useState(0);
  const [filter, setFilter] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [sortedColumn, setSortedColumn] = useState([
    {
      sortDataKey: 'createdTime',
      direction: 'asc',
    },
  ]);

  const limit = 30;

  const changeDirFormat = (originalDir) => {
    if (originalDir === 'desc') {
      return 'DESC';
    }
    return 'ASC';
  };

  const { data, loading, error } = useQuery(GET_FULL_SAVED_VIEWS, {
    client,
    variables: {
      workspace: domain,
      user: userName,
      name: filter,
      limit,
      offset: currentOffSet,
      orderBy: [
        {
          field: sortedColumn[0].sortDataKey,
          order: changeDirFormat(sortedColumn[0].direction),
        },
      ],
    },
  });

  const [setFavouriteView] = useMutation(SET_FAVOURITE_VIEW, {
    client,
    variables: {
      workspace: domain,
      user: userName,
    },
  });

  useEffect(() => {
    const onPinClick = (rowName, isFavourite) => {
      setFavouriteView({
        variables: {
          name: rowName,
          value: !isFavourite,
        },
        refetchQueries: ['GET_FULL_SAVED_VIEWS'],
      });
    };

    if (data !== undefined) {
      if (!loading && data.views !== null) {
        const tableData = data?.views?.views.map((row) => {
          const {
            name,
            favourite,
            workspace,
            createdTime,
            createdBy,
            description,
          } = row;
          const viewURL = `${encodeURI(name)}`;
          return {
            description,
            favourite,
            workspace,
            createdBy,
            actions: (
              <div className="stored-views-actions-container">
                {row.favourite === true ? (
                  <PinViewModal
                    onConfirm={() => onPinClick(name, favourite)}
                    name={name}
                  />
                ) : (
                  <Button
                    icon="star-empty"
                    type="transparent"
                    onClick={() => onPinClick(name, favourite)}
                    data-testid={`stored-views-favourite-${name}`}
                  />
                )}
                {userName !== createdBy ? (
                  <div />
                ) : (
                  <DeleteViewModal name={name} workspace={workspace} />
                )}
              </div>
            ),

            name: (
              <Link
                className="stored-views-table-links"
                as="a"
                href={viewURL}
                data-testid={`view-name-link-${name}`}
              >
                {name}
              </Link>
            ),
            createdTime: format(parseISO(createdTime), 'dd/MM/yyyy'),
          };
        });

        setSavedViews(tableData);
        setPaginationInfo(data?.views?.pageInfo);
        setTotalCount(data?.views?.totalCount);
      } else {
        setSavedViews([]);
      }
    }
  }, [data, loading, setFavouriteView, userName]);

  const columns = [
    {
      label: t('storedViews.table.column.name'),
      dataKey: 'name',
      sortable: true,
    },
    {
      label: t('storedViews.table.column.description'),
      dataKey: 'description',
    },

    {
      label: t('storedViews.table.column.savedBy'),
      dataKey: 'createdBy',
      sortable: true,
    },
    {
      label: t('storedViews.table.column.savedOn'),
      dataKey: 'createdTime',
      sortable: true,
    },
    { label: t('storedViews.table.column.actions'), dataKey: 'actions' },
  ];

  const handleEnterKeyPress = (e) => {
    if (e.keyCode === KEYBOARD_KEYS.enter) {
      setFilter(e.target.value);
    }
  };

  const handleOnSort = (newDirection, newDataKey) => {
    setSortedColumn(() => {
      return [
        {
          direction: newDirection,
          sortDataKey: newDataKey,
        },
      ];
    });
  };

  const pageSelectFunc = (newPage) => {
    // prevent paging if no data is available
    if (totalCount === 0) return;
    if (newPage === 1) {
      setCurrentOffset(0);
    } else {
      const x = newPage * limit - limit;
      setCurrentOffset(x);
    }
  };

  const { currentPage = 0, nextOffset = 0, totalPages = 0 } = pagination;

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            displayName: workspaceName,
            linkDestination: LINKS.inventory.listNodes({
              path: { workspace: workspaceName },
            }),
          },
          {
            displayName: t('nodes.breadcrumb'),
            linkDestination: LINKS.inventory.listNodes({
              path: { workspace: workspaceName },
            }),
          },
          {
            displayName: t('storedViews.header.text'),
          },
        ]}
      />
      <PageHeader
        pageTitle={t('storedViews.header.text')}
        headerDescription={t('storedViews.header.description')}
      />
      <div>
        <QueryError error={error} client={client} />
        <div className="view-search">
          <Input
            data-testid="SearchView-inputSearch"
            name="view-search"
            placeholder={t('common.search.placeholder')}
            icon="search"
            simple
            value={inputValue}
            onChange={(value) => setInputValue(value)}
            onKeyDown={(e) => {
              handleEnterKeyPress(e);
            }}
            disabled={loading}
          />
        </div>
        <Table
          data={savedViews}
          columns={columns}
          loading={loading}
          data-testid="stored-views-table-of-views"
          emptyStateHeader={t('storedViews.table.empty.header')}
          emptyStateMessage={
            filter !== ''
              ? t('storedViews.table.empty.message.with.search')
              : t('storedViews.table.empty.message')
          }
          onSort={handleOnSort}
          sortedColumn={sortedColumn[0]}
        />
        <Table.TableFooter>
          <TablePageSelector
            paginationCountText={
              totalCount === 0
                ? t('storedView.table.pagination.no.rows')
                : t('storedView.table.pagination.counts', {
                    count: limit * currentPage - limit + 1,
                    offset: nextOffset > totalCount ? totalCount : nextOffset,
                    totalCount,
                  })
            }
            currentPage={currentPage}
            pageCount={totalPages}
            updatePage={pageSelectFunc}
          />
        </Table.TableFooter>
      </div>
    </>
  );
};

export default StoredViews;
