import React from 'react';
import { Button } from '@puppet/react-components';

interface CursorPaginationProps {
  pageSelect: Function;
  currentPage: number;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
}

const CursorPagination = ({
  pageSelect,
  currentPage,
  hasNextPage = false,
  hasPreviousPage = false,
}: CursorPaginationProps) => {
  return (
    <div className="cursor-pagination">
      <Button
        disabled={!hasPreviousPage}
        type="transparent"
        icon="chevron-left"
        onClick={() => {
          pageSelect(currentPage - 1);
        }}
        data-testid="cursor-pagination-previous-page"
      />
      <Button
        disabled={!hasNextPage}
        type="transparent"
        icon="chevron-right"
        onClick={() => pageSelect(currentPage + 1)}
        data-testid="cursor-pagination-next-page"
      />
    </div>
  );
};

export default CursorPagination;
