import * as Provider from './providerConsts';

export default function getProviderDisplayName(providerName) {
  switch (providerName) {
    case Provider.BITBUCKET:
      return 'Bitbucket';
    case Provider.BITBUCKET_SERVER:
      return 'Bitbucket Server';
    case Provider.GITHUB:
      return 'GitHub';
    case Provider.GITHUB_ENTERPRISE:
      return 'GitHub Enterprise';
    case Provider.AZURE_DEV_OPS:
      return 'Azure DevOps';
    case Provider.GITLAB:
      return 'GitLab';
    default:
      return 'unknown';
  }
}
