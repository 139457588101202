import React, { useEffect, useState } from 'react';
import { Form, Heading, Loading } from '@puppet/react-components';
import CommonForm from '@components/CommonForm';
import { useTranslation } from 'react-i18next';
import { useCreateGroupV1, useGetGroupV1 } from '@services/cd4pe/groups';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import Cd4peError from '@components/Cd4peError';
import alerts from '@state/ui/alerts';
import { useAppDispatch } from '@hooks/redux';
import { CommonFormChangeFunction } from '@components/CommonForm/CommonForm';

interface props {
  onCreateGroup: Function;
  groupId?: string;
  cancelCallback: Function;
}

interface AddGroupNameFormValues {
  name: string;
  description: string;
}

const defaultProps = {
  groupId: '',
};

const AddGroupForm = ({ onCreateGroup, groupId, cancelCallback }: props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const createGroup = useCreateGroupV1();
  const {
    data: savedGroup,
    error: getGroupError,
    isLoading: groupLoading,
  } = useGetGroupV1(
    {
      workspaceId,
      groupId: Number(groupId),
    },
    {
      enabled: groupId !== '',
    },
  );
  const [formValues, setFormValues] = useState<AddGroupNameFormValues>({
    name: '',
    description: '',
  });

  const [disableFields, setDisableFields] = useState<boolean>(false);
  const appDispatch = useAppDispatch();

  const onChange: CommonFormChangeFunction<AddGroupNameFormValues> = (
    _,
    values,
  ) => {
    setFormValues({
      ...values,
      name: values.name?.replaceAll(' ', '-'),
    } as AddGroupNameFormValues);
  };

  const onSubmit = () => {
    createGroup.mutate(
      {
        requestBody: {
          workspaceId,
          name: formValues.name,
          description: formValues.description,
        },
      },
      {
        onSuccess: (group) => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('addGroup.alerts.create.success', {
                name: group.name,
              }),
            }),
          );
          onCreateGroup(group.id, group.name);
        },
      },
    );
  };

  useEffect(() => {
    if (groupId) {
      setDisableFields(true);
    }
  }, [groupId]);

  useEffect(() => {
    if (savedGroup) {
      setFormValues({
        name: savedGroup.name,
        description: savedGroup.description!,
      });
    }
  }, [savedGroup]);

  if (groupId !== '' && groupLoading) {
    return <Loading />;
  }

  return (
    <CommonForm
      className="add-group-form"
      submitLabel={t('addGroup.button.saveGroup')}
      values={formValues}
      onSubmit={onSubmit}
      submittable={!disableFields}
      cancellable={!disableFields}
      onChange={onChange}
      submitting={createGroup.isLoading}
      onCancel={() => cancelCallback()}
      data-testid="add-group-form"
    >
      <CommonForm.Section>
        <CommonForm.Section.Main>
          <div className="add-group-permissions-subset-title">
            <Heading as="h4">{t('addGroup.form.header')}</Heading>
          </div>
          <div className="add-group-form-field__wrapper">
            <Form.Field
              type="text"
              name="name"
              label={t('addGroup.form.name.label')}
              placeholder={t('addGroup.form.name.placeholder')}
              disabled={disableFields}
              required
              data-testid="add-group-form-name"
            />
            <Form.Field
              type="multiline"
              name="description"
              label={t('addGroup.form.description.label')}
              placeholder={t('addGroup.form.description.placeholder')}
              disabled={disableFields}
              data-testid="add-group-form-description"
            />
          </div>
        </CommonForm.Section.Main>
      </CommonForm.Section>
      <div className="add-group-form-error">
        <Cd4peError error={createGroup.error} />
        <Cd4peError error={getGroupError} />
      </div>
    </CommonForm>
  );
};

AddGroupForm.defaultProps = defaultProps;

export default AddGroupForm;
