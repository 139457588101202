import React from 'react';
import { Heading } from '@puppet/react-components';
import PageLayout from '@components/PageLayout';
import Breadcrumbs from '@components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { useNavigate } from 'react-router-dom';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useAppDispatch } from '@hooks/redux';
import { useCreateHardwareCapabilityV1 } from '@services/cd4pe/hardware';
import alerts from '@state/ui/alerts';
import Cd4peError from '@components/Cd4peError';
import JobHardwareCapabilityForm from '@codeDelivery/components/JobHardwareCapabilityForm';
import {
  CreateJobHardwareCapabilitiesRequestV1,
  UpdateJobHardwareCapabilitiesRequestV1,
} from '@puppet/cd4pe-client-ts';
import { LINKS } from 'src/routes';

const breadcrumbsRender = (t: Function, workspace: string) => {
  const breadcrumbs = [
    { displayName: `${workspace}` },
    {
      displayName: t('jobHardware.breadcrumbs.hardware'),
      linkDestination: LINKS.codeDelivery.jobHardware({
        path: { workspace },
      }),
    },
    { displayName: `${t('addCapability.breadcrumb')}` },
  ];
  return breadcrumbs;
};

const AddCapability = () => {
  const { t } = useTranslation('codeDelivery');
  const navigate = useNavigate();
  const workspaceName = useWorkspaceName();

  const workspaceId = useWorkspaceDomain();
  const appDispatch = useAppDispatch();
  const createCapability = useCreateHardwareCapabilityV1();

  const navigateToHardwareList = () => {
    navigate(
      LINKS.codeDelivery.jobHardware({ path: { workspace: workspaceName } }),
    );
  };

  const onSubmit = (
    payload:
      | CreateJobHardwareCapabilitiesRequestV1
      | UpdateJobHardwareCapabilitiesRequestV1,
  ) => {
    createCapability.mutate(
      {
        workspaceId,
        requestBody: {
          displayName: payload.displayName!,
          nodes: payload.nodes!,
        },
      },
      {
        onSuccess: (capability) => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('jobHardware.alerts.create.success', {
                capabilityName: capability.displayName,
              }),
            }),
          );
          navigateToHardwareList();
        },
      },
    );
  };

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbsRender(t, workspaceName)} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('addCapability.header')}</Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        <JobHardwareCapabilityForm
          onSubmit={onSubmit}
          onCancel={navigateToHardwareList}
        />
        <Cd4peError error={createCapability.error} />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default AddCapability;
