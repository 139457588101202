import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Cd4peApiError,
  handleQueryRequest,
  QueryHook,
  MutationHook,
} from '@utils/api/cd4pe';
import { PipelinesAsCodeV1Service } from '@puppet/cd4pe-client-ts';
import { GET_PIPELINE_QUERY_TAG } from '@services/cd4pe/pipelines';
import { CONTROL_REPOS_GET_QUERY_TAG } from '@services/cd4pe/controlRepos';
import { MODULES_GET_QUERY_TAG } from '@services/cd4pe/modules';

export const PREVIEW_PIPELINE_AS_CODE_TAG = 'previewPipelinesAsCode';
export const PIPELINE_AS_CODE_ERROR_TAG = 'pipelinesAsCodeError';

/**
 * useCreatePipelinesFromCodeV1
 */
type UseCreatePipelinesFromCodeV1 = MutationHook<
  typeof PipelinesAsCodeV1Service,
  'createPipelinesFromCodeV1',
  Cd4peApiError
>;

export const useCreatePipelinesFromCodeV1: UseCreatePipelinesFromCodeV1 =
  () => {
    const queryClient = useQueryClient();
    return useMutation(
      (payload) =>
        handleQueryRequest(
          PipelinesAsCodeV1Service.createPipelinesFromCodeV1(payload),
        ),
      {
        onSettled: (_, error, payload) => {
          queryClient.invalidateQueries([
            GET_PIPELINE_QUERY_TAG,
            payload.workspaceId,
            payload.projectName,
            payload.pipelineId,
          ]);
          queryClient.invalidateQueries([
            CONTROL_REPOS_GET_QUERY_TAG,
            payload.workspaceId,
            payload.projectName.toLowerCase(),
          ]);
          queryClient.invalidateQueries([
            MODULES_GET_QUERY_TAG,
            payload.workspaceId,
            payload.projectName.toLowerCase(),
          ]);
          queryClient.invalidateQueries([
            PIPELINE_AS_CODE_ERROR_TAG,
            payload.workspaceId,
            payload.pipelineId,
            payload.projectName,
          ]);
        },
      },
    );
  };

/**
 * useDisablePipelinesAsCodeV1
 */
type UseDisablePipelinesAsCodeV1 = MutationHook<
  typeof PipelinesAsCodeV1Service,
  'disablePipelinesAsCodeV1',
  Cd4peApiError
>;

export const useDisablePipelinesAsCodeV1: UseDisablePipelinesAsCodeV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(
        PipelinesAsCodeV1Service.disablePipelinesAsCodeV1(payload),
      ),
    {
      onSettled: (_, error, payload) => {
        queryClient.invalidateQueries([
          CONTROL_REPOS_GET_QUERY_TAG,
          payload.workspaceId,
          payload.projectName.toLowerCase(),
        ]);
        queryClient.invalidateQueries([
          MODULES_GET_QUERY_TAG,
          payload.workspaceId,
          payload.projectName.toLowerCase(),
        ]);
        queryClient.invalidateQueries([
          PIPELINE_AS_CODE_ERROR_TAG,
          payload.workspaceId,
          payload.pipelineId,
          payload.projectName,
        ]);
      },
    },
  );
};

/**
 * usePreviewPipelineAsCodeV1
 */

type UsePreviewPipelineAsCodeV1 = QueryHook<
  typeof PipelinesAsCodeV1Service,
  'previewPipelineAsCodeV1',
  Cd4peApiError
>;

export const usePreviewPipelineAsCodeV1: UsePreviewPipelineAsCodeV1 = (
  payload,
  options,
) => {
  return useQuery(
    [
      PREVIEW_PIPELINE_AS_CODE_TAG,
      payload.workspaceId,
      payload.projectName,
      payload.pipelineId,
    ],
    async () =>
      handleQueryRequest(
        PipelinesAsCodeV1Service.previewPipelineAsCodeV1(payload),
      ),
    options,
  );
};

/**
 * useValidatePipelinesAsCodeV1
 */
type UseValidatePipelinesAsCodeV1 = MutationHook<
  typeof PipelinesAsCodeV1Service,
  'validatePipelinesAsCodeV1',
  Cd4peApiError
>;
export type UseValidatePipelinesAsCodeV1Result =
  ReturnType<UseValidatePipelinesAsCodeV1>;

export const useValidatePipelinesAsCodeV1: UseValidatePipelinesAsCodeV1 =
  () => {
    return useMutation((payload) =>
      handleQueryRequest(
        PipelinesAsCodeV1Service.validatePipelinesAsCodeV1(payload),
      ),
    );
  };

/**
 * useGetPipelineAsCodeErrorV1
 */
type UseGetPipelineAsCodeErrorV1 = QueryHook<
  typeof PipelinesAsCodeV1Service,
  'getPipelinesAsCodeErrorV1',
  Cd4peApiError
>;

export type UseGetPipelineAsCodeErrorV1Result =
  ReturnType<UseGetPipelineAsCodeErrorV1>;

export const useGetPipelineAsCodeErrorV1: UseGetPipelineAsCodeErrorV1 = (
  payload,
  options,
) =>
  useQuery(
    [
      PIPELINE_AS_CODE_ERROR_TAG,
      payload.workspaceId,
      payload.pipelineId,
      payload.projectName,
    ],
    () =>
      handleQueryRequest(
        PipelinesAsCodeV1Service.getPipelinesAsCodeErrorV1(payload),
      ),
    options,
  );
