import SingleSelectFilter from 'inventory/components/NodeTableFilter/SingleSelectFilter';

export default {
  component: SingleSelectFilter,
  placeholder: 'nodeTable.filter.status.input.placeholder',
  label: 'nodeTable.filter.status.input.label',
  filterValue: {
    unchanged: { latestReportStatus: { equalTo: 'unchanged' } },
    failed: { latestReportStatus: { equalTo: 'failed' } },
    iChange: {
      AND: [
        { latestReportCorrectiveChange: { equalTo: false } },
        { latestReportStatus: { equalTo: 'changed' } },
      ],
    },
    cChange: {
      AND: [
        { latestReportCorrectiveChange: { equalTo: true } },
        { latestReportStatus: { equalTo: 'changed' } },
      ],
    },
  },
  options: [
    { value: 'unchanged', label: 'No change' },
    { value: 'iChange', label: 'Intentional Change' },
    { value: 'cChange', label: 'Corrective Change' },
    { value: 'failed', label: 'Failure' },
  ],
};
