// TODO remove and replace with design system components after next release
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Button } from '@puppet/react-components';
import PropTypes from 'prop-types';

const propTypes = {
  error: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  trailingIcon: PropTypes.string,
};

const defaultProps = {
  error: '',
  value: '',
  type: '',
  placeholder: '',
  trailingIcon: '',
};

const renderText = (type, value, placeholder) => {
  if (type === 'multiselect' || !value) {
    return placeholder;
  }

  return value;
};

const SelectTarget = forwardRef(
  ({ error, value, type, placeholder, trailingIcon, ...rest }, ref) => (
    <div className={classNames('rc-input-container', 'rc-select-target')}>
      <Button
        type={type === 'textselect' ? 'text' : null}
        className={classNames('rc-input', {
          'rc-input-error': error,
          'rc-input-empty': !value,
          'rc-input-text-select': type === 'textselect',
        })}
        ref={ref}
        trailingIcon={trailingIcon}
        icon={trailingIcon ? null : 'chevron-down'}
        {...rest}
      >
        {renderText(type, value, placeholder)}
      </Button>
    </div>
  ),
);

SelectTarget.propTypes = propTypes;
SelectTarget.defaultProps = defaultProps;

export default SelectTarget;
