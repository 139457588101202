import PropTypes from 'prop-types';
import { GraphQLObjectType } from 'graphql';

const ChartModalProptypes = PropTypes.shape({
  modalTitle: PropTypes.string.isRequired,
  chooseFactInfo: PropTypes.string.isRequired,
  chooseFactPlaceholder: PropTypes.string.isRequired,
  save: PropTypes.string.isRequired,
  chartTitle: PropTypes.string.isRequired,
  chartOptions: PropTypes.string.isRequired,
  excludeCheckbox: PropTypes.string.isRequired,
});

const CardPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  query: PropTypes.instanceOf(GraphQLObjectType).isRequired,
  chartType: PropTypes.string.isRequired,
  areNodesExcluded: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired,
  after: PropTypes.func.isRequired,
  responseKey: PropTypes.string,
  totalCountKey: PropTypes.string,
  filter: PropTypes.string,
  chartOptions: PropTypes.shape({
    grid: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }).isRequired,
});

const FilterPropTypes = PropTypes.shape({
  value: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  fact: PropTypes.string.isRequired,
});

export { ChartModalProptypes, CardPropTypes, FilterPropTypes };
