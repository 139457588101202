import React from 'react';
import { Modal, Form } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import { useUpdateUserV1 } from '@services/cd4pe/users';
import { UserV1 } from '@puppet/cd4pe-client-ts';
import Cd4peError from '@components/Cd4peError';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import session from '@state/session/session';

type Props = {
  isOpen: boolean;
  onClose: (open: boolean) => void;
  user: UserV1;
};

type FormValues = {
  email: string;
};

const ChangeEmailDialog = ({ isOpen, onClose, user }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const appDispatch = useAppDispatch();
  const updateUserEmail = useUpdateUserV1();

  const onSubmitHandler = ({ email }: FormValues) => {
    if (user.superUser) {
      updateUserEmail.mutate(
        {
          userId: user.id!,
          requestBody: {
            email,
          },
        },
        {
          onSuccess: () => {
            appDispatch(session.actions.setUser({ ...user, email }));

            onClose(!isOpen);

            appDispatch(
              alerts.actions.createAlert({
                type: 'Success',
                message: t('profile.change.email.dialog.success'),
              }),
            );
          },
        },
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(!isOpen)}>
      <Modal.Title>{t('profile.change.email.dialog.title')}</Modal.Title>
      <Form
        submittable
        cancellable
        onSubmit={(newEmail: FormValues) => {
          onSubmitHandler(newEmail);
        }}
        submitting={updateUserEmail.isLoading}
        onCancel={() => onClose(!isOpen)}
        submitLabel={t('profile.change.email.dialog.save.changes.button')}
        cancelLabel={t('profile.change.email.dialog.cancel.button')}
        data-testid="profile-change-email-form"
      >
        <Form.Field
          name="email"
          type="email"
          label={t('profile.change.email.dialog.new.email.label')}
          placeholder={t('profile.change.email.dialog.new.email.placeholder')}
          required
          data-testid="profile-change-email-input"
        />
      </Form>
      <Cd4peError error={updateUserEmail.error} />
    </Modal>
  );
};

export default ChangeEmailDialog;
