import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useState, useEffect } from 'react';
import {
  useGetGroupPrivilegesV1,
  useGetAvailableGroupPrivilegesV1,
} from '@services/cd4pe/groups';
import { buildFormState, PrivilegeFormState } from './utils/utils';

const useGroupPrivilegesState = (groupId: number, editable: boolean) => {
  const workspaceId = useWorkspaceDomain();
  const [state, setState] = useState<PrivilegeFormState>([]);

  const {
    data: groupPrivileges,
    isLoading: cLoading,
    error: cError,
    refetch: cRefetch,
  } = useGetGroupPrivilegesV1({
    workspaceId,
    groupId,
  });
  const {
    data: availablePrivileges,
    isLoading: aLoading,
    error: aError,
  } = useGetAvailableGroupPrivilegesV1({
    workspaceId,
    groupId,
  });

  const isLoading = aLoading || cLoading;

  const errors = {
    cError,
    aError,
  };

  useEffect(() => {
    if (groupPrivileges && availablePrivileges) {
      const initState = buildFormState(
        groupPrivileges.privileges,
        availablePrivileges.privileges,
      );
      setState(initState);
    }
  }, [availablePrivileges, groupPrivileges, isLoading]);

  useEffect(() => {
    cRefetch();
  }, [editable, cRefetch]);

  return { state, setState, isLoading, errors } as const;
};

export default useGroupPrivilegesState;
