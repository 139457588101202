import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Cd4peApiError,
  handleQueryRequest,
  JobsV1Service,
  MutationHook,
  QueryHook,
} from '@utils/api/cd4pe';

const JOB_TEMPLATES_QUERY_TAG = 'jobTemplates';
const JOB_INSTANCES_QUERY_TAG = 'jobInstances';
const JOB_INSTANCES_LOG_QUERY_TAG = 'jobInstancesLog';

type UseListTemplatesV1 = QueryHook<
  typeof JobsV1Service,
  'listTemplatesV1',
  Cd4peApiError
>;

export type UseListTemplatesV1Result = ReturnType<UseListTemplatesV1>;

export const useListTemplatesV1: UseListTemplatesV1 = (payload) =>
  useQuery(
    [JOB_TEMPLATES_QUERY_TAG],
    () => handleQueryRequest(JobsV1Service.listTemplatesV1(payload)),
    { staleTime: 300000 },
  );

type UseDeleteTemplateV1 = MutationHook<
  typeof JobsV1Service,
  'deleteTemplateV1',
  Cd4peApiError
>;

export type UseDeleteTemplateV1Result = ReturnType<UseDeleteTemplateV1>;

export const useDeleteTemplateV1: UseDeleteTemplateV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (p) => handleQueryRequest(JobsV1Service.deleteTemplateV1(p)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([JOB_TEMPLATES_QUERY_TAG]);
      },
    },
  );
};

type UseCreateTemplateV1 = MutationHook<
  typeof JobsV1Service,
  'createTemplateV1',
  Cd4peApiError
>;

export type UseCreateTemplateV1Result = ReturnType<UseCreateTemplateV1>;

export const useCreateTemplateV1: UseCreateTemplateV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (p) => handleQueryRequest(JobsV1Service.createTemplateV1(p)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([JOB_TEMPLATES_QUERY_TAG]);
      },
    },
  );
};

type UseReplaceTemplateV1 = MutationHook<
  typeof JobsV1Service,
  'replaceTemplateV1',
  Cd4peApiError
>;

export type UseReplaceTemplateV1Result = ReturnType<UseReplaceTemplateV1>;

export const useReplaceTemplateV1: UseReplaceTemplateV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (p) => handleQueryRequest(JobsV1Service.replaceTemplateV1(p)),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([JOB_TEMPLATES_QUERY_TAG, payload.id]);
        queryClient.invalidateQueries([JOB_TEMPLATES_QUERY_TAG]);
      },
    },
  );
};

type UseGetTemplateV1 = QueryHook<
  typeof JobsV1Service,
  'getTemplateV1',
  Cd4peApiError
>;

export type UseGetTemplateV1Result = ReturnType<UseGetTemplateV1>;

export const useGetTemplateV1: UseGetTemplateV1 = (payload) =>
  useQuery(
    [JOB_TEMPLATES_QUERY_TAG, payload.id],
    () => handleQueryRequest(JobsV1Service.getTemplateV1(payload)),
    { staleTime: 300000 },
  );

type UseGetJobInstanceV1 = QueryHook<
  typeof JobsV1Service,
  'getJobInstanceV1',
  Cd4peApiError
>;

export const useGetJobInstanceV1: UseGetJobInstanceV1 = (payload, options) => {
  const queryClient = useQueryClient();
  return useQuery(
    [JOB_INSTANCES_QUERY_TAG, payload.instanceId],
    () => handleQueryRequest(JobsV1Service.getJobInstanceV1(payload)),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          JOB_INSTANCES_LOG_QUERY_TAG,
          payload.instanceId,
        ]),
      ...options,
    },
  );
};

type UseRerunJobInstanceV1 = MutationHook<
  typeof JobsV1Service,
  'rerunJobInstanceV1',
  Cd4peApiError
>;

export const useRerunJobInstanceV1: UseRerunJobInstanceV1 = () => {
  return useMutation((p) =>
    handleQueryRequest(JobsV1Service.rerunJobInstanceV1(p)),
  );
};

type UseGetJobInstanceLogV1 = QueryHook<
  typeof JobsV1Service,
  'getJobInstanceLogV1',
  Cd4peApiError
>;

export const useGetJobInstanceLogV1: UseGetJobInstanceLogV1 = (payload) =>
  useQuery([JOB_INSTANCES_LOG_QUERY_TAG, payload.instanceId], () =>
    handleQueryRequest(JobsV1Service.getJobInstanceLogV1(payload)),
  );
