import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PROXY_HEADER } from 'utils/constants';
import {
  loadScript,
  getPageStyles,
  unloadScript,
  restorePageStyles,
} from 'utils/cd4pePageLoader';

const Cd4pe = ({ pageTitle }) => {
  const scriptId = `cd4pe-script-${pageTitle}`;
  const [error, setError] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { workspace: urlWorkspace } = useParams();
  const dispatch = useDispatch();

  const [preLoadStyles, setPreLoadStyles] = useState([]);
  const preLoadStylesRef = useRef();

  useEffect(() => {
    preLoadStylesRef.current = preLoadStyles;
  }, [preLoadStyles]);

  useEffect(() => {
    setPreLoadStyles(getPageStyles());
    return () => {
      if (pageTitle) {
        // A handful of routes in CD4PE are just pass-through routes and don't actually render a page. Do not attempt to unload page styles in this case.
        unloadScript(scriptId);
      }
      restorePageStyles(preLoadStylesRef.current);
    };
  }, [scriptId, pageTitle]);

  useEffect(() => {
    const parseCd4peHtml = async (response) => {
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const pagePropsScript = doc.scripts.namedItem('cd4pe-page-props');
      const bundleScript = doc.scripts.namedItem('cd4pe-js-bundle');
      let pageProps = {};

      // Apply cd4pe PAGE_PROPS hydrating state object
      if (pagePropsScript?.text) {
        pageProps = JSON.parse(
          pagePropsScript.text
            .replace('window.PAGE_PROPS = {', '{')
            .replace(/};([^};]*)$/, '}$1'), // replace last instance of '};' with '}'
        );

        // Allows CD4PE's frontend to continue as normal as it relies on this object being present in the window scope.
        window.PAGE_PROPS = pageProps;
      }

      return bundleScript;
    };

    const fetchCd4e = async () => {
      try {
        setError(false);
        const response = await fetch(`${location.pathname}${location.search}`, {
          method: 'GET',
          headers: new Headers({ [PROXY_HEADER.key]: PROXY_HEADER.value }),
          credentials: 'include',
          mode: 'cors',
        });

        if (response.redirected) {
          log.info('CD4PE has performed a redirect');
          const url = new URL(response.url);
          navigate(`${url.pathname}${url.search}`, { replace: true });
          return;
        }

        const bundleScript = await parseCd4peHtml(response);

        loadScript({
          src: bundleScript?.src,
          scriptId,
          onErrorHandler: (err) => {
            setError(err.message);
          },
          onLoadHandler: () => {
            setScriptLoaded(true);
          },
        });
      } catch (e) {
        log.error('Error fetching cd4pe route', e);
      }
    };

    fetchCd4e();
  }, [pageTitle, location, navigate, dispatch, urlWorkspace, scriptId]);

  const WebComponent = useMemo(
    () => React.createElement(`cd4pe-${pageTitle}`),
    [pageTitle],
  );

  return (
    <div className="cd4pe-micro-frontend">
      {error && <div>{error}</div>}
      {scriptLoaded && WebComponent}
    </div>
  );
};

Cd4pe.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default Cd4pe;
