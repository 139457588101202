import React, { useState, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  Card,
  Icon,
  Heading,
  Text,
  Button,
  ActionSelect,
} from '@puppet/react-components';
import { format } from 'date-fns';
import { PEIntegrationV2 } from '@utils/api/cd4pe';
import ProtectedEnvironmentsTable from '@codeDelivery/views/PeIntegrationList/components/ProtectedEnvironmentsTable';
import useWorkspaceName from '@hooks/useWorkspaceName';
import ConfirmDeleteModal from '@components/ConfirmDeleteModal';
import { useDeletePeIntegrationV2 } from '@services/cd4pe/peIntegrations';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import Cd4peError from '@components/Cd4peError';
import { LINKS } from 'src/routes';

interface DataItemProps {
  name: String;
  content?: String;
}

const DataItem = ({
  name,
  content = '',
  children,
  ...rest
}: PropsWithChildren<DataItemProps>) => (
  <div className="data-item" {...rest}>
    <Heading as="h5" className="data-item-name">
      {name}
    </Heading>
    {content && <Text className="data-item-content">{content}</Text>}
    {children}
  </div>
);

interface CardActionProps {
  peId: string;
  onRemove: Function;
}

const CardActions = ({ peId, onRemove }: CardActionProps) => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const settingsEditPePath = LINKS.settings.editPuppetEnterprise({
    path: { workspace, peId },
  });

  const actions = [
    {
      id: 'edit',
      icon: 'pencil',
      label: t('peList.card.actions.edit'),
      as: Link,
      to: settingsEditPePath,
      'data-testid': 'edit-pe-integration',
      'aria-label': t('peList.cardActions.edit.ariaLabel'),
    },
    {
      id: 'remove',
      icon: 'trash',
      label: t('peList.card.actions.remove'),
      onClick: () => onRemove(peId),
      'data-testid': 'delete-pe-integration',
      'aria-label': t('peList.cardActions.delete.ariaLabel'),
    },
  ];

  return (
    <div data-testid="pe-delete-edit-select">
      <ActionSelect
        anchor="bottom right"
        type="transparent"
        actions={actions}
        icon="kebab"
        label={t('peList.cardActions.actions.ariaLabel')}
      />
    </div>
  );
};

const PeIntegrations = ({ data = [] }: { data: PEIntegrationV2[] }) => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const appDispatch = useAppDispatch();
  const [selectedPeId, setSelectedPeId] = useState('');
  const [selectedPeName, setSelectedPeName] = useState('');

  const clearSelectedPE = () => {
    setSelectedPeId('');
    setSelectedPeName('');
  };

  const deletePe = useDeletePeIntegrationV2();

  const handleDelete = () => {
    deletePe.mutate(
      { peIntegrationId: selectedPeId },
      {
        onSuccess: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('peList.alerts.deletePe.success', {
                name: selectedPeName,
              }),
            }),
          );
        },
        onError: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Error',
              message: t('peList.alerts.deletePe.error', {
                name: selectedPeName,
              }),
            }),
          );
        },
      },
    );
    clearSelectedPE();
  };

  return (
    <>
      <Cd4peError error={deletePe.error} />
      {data.map(
        ({ id, name, puppetServerUrl, tokenExpiration, compileBatchSize }) => {
          return (
            <Card key={`pe-card-${id}`} className="pe-list-body-card">
              <div className="pe-list-body-card-icon">
                <Icon type="dag" />
              </div>
              <div
                className="pe-list-body-card-data"
                data-testid="pe-list-body-card"
              >
                <DataItem
                  data-testid="pe-data-item-name"
                  name={t('peList.card.content.name')}
                  content={name}
                />
                <DataItem
                  data-testid="pe-data-item-address"
                  name={t('peList.card.content.address')}
                  content={
                    typeof puppetServerUrl === 'string'
                      ? new URL(`http://${puppetServerUrl}`).hostname
                      : ''
                  }
                />
                <DataItem
                  data-testid="pe-data-item-tokenExpires"
                  name={t('peList.card.content.tokenExpires')}
                  content={
                    typeof tokenExpiration !== 'undefined' &&
                    tokenExpiration !== null
                      ? format(new Date(tokenExpiration), 'MMMM d, yyyy')
                      : ''
                  }
                />
                <DataItem
                  data-testid="pe-data-item-ia"
                  name={t('peList.card.content.impactAnalysis')}
                >
                  <div>
                    <Text>
                      {t('peList.card.content.impactAnalysis.endpoint', {
                        url: puppetServerUrl,
                      })}
                    </Text>
                    <Text data-testid="data-item-ia-maxconcurrentcatalogs">
                      {t(
                        'peList.card.content.impactAnalysis.maxConcurrentCatalogs',
                        {
                          size: compileBatchSize ?? 0,
                        },
                      )}
                    </Text>
                  </div>
                </DataItem>
              </div>
              <div className="pe-list-body-card-actions">
                <CardActions
                  peId={id}
                  onRemove={() => {
                    setSelectedPeId(id);
                    setSelectedPeName(name);
                  }}
                />
                <Button
                  as={Link}
                  to={LINKS.settings.regeneratePuppetEnterpriseToken({
                    path: { workspace, peId: id },
                  })}
                  type="transparent"
                >
                  {t('peList.card.regenerate.link')}
                </Button>
              </div>
              <ProtectedEnvironmentsTable peIntegrationId={id} />
            </Card>
          );
        },
      )}
      <ConfirmDeleteModal
        dataTestId="delete-pe-modal-form"
        isOpen={selectedPeId !== ''}
        onCancel={() => clearSelectedPE()}
        onDelete={() => handleDelete()}
        title={t('peList.deletePeIntegration.modal.title')}
        alert={t('peList.deletePeIntegration.modal.description')}
        checkbox
        checkboxLabel={
          <Trans
            t={t}
            i18nKey="peList.deletePeIntegration.modal.checkboxDescription"
          >
            <strong>{{ name: selectedPeName }}</strong>
          </Trans>
        }
        confirmLabel={t('peList.deletePeIntegration.modal.button.confirm')}
        cancelLabel={t('peList.deletePeIntegration.modal.button.cancel')}
      />
    </>
  );
};

export default PeIntegrations;
