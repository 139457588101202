import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Outlet } from 'react-router-dom';
import LeftNav from '@views/LeftNav';
import { ROUTE_TYPES } from '@utils/constants';
import config from '../../config';

interface Props {
  routeType: string;
}

const renderContent = (routeType: string, content: React.ReactNode) => {
  if (routeType === ROUTE_TYPES.codeDelivery) {
    return (
      <div className="app-main-content-overflow">
        <Outlet />
      </div>
    );
  }
  if (routeType === ROUTE_TYPES.inventory) {
    return <Outlet />;
  }

  return content;
};

const Layout = ({ routeType, children }: PropsWithChildren<Props>) => {
  return (
    <>
      <LeftNav visible version={config.TEAMS_UI_VERSION} />
      <main className="app-main-content">
        <div
          className={classnames({
            'app-main-content-inner': routeType === ROUTE_TYPES.inventory,
            'code-delivery-route': routeType === ROUTE_TYPES.codeDelivery,
          })}
        >
          {renderContent(routeType, children)}
        </div>
      </main>
    </>
  );
};

export default Layout;
