export const GET_JOB_SECRETS_LOADING = 'GET_JOB_SECRETS_LOADING';
export const GET_JOB_SECRETS_COMPLETE = 'GET_JOB_SECRETS_COMPLETE';
export const GET_JOB_SECRETS_ERROR = 'GET_JOB_SECRETS_ERROR';

export const CREATE_JOB_SECRET_LOADING = 'CREATE_JOB_SECRET_LOADING';
export const CREATE_JOB_SECRET_COMPLETE = 'CREATE_JOB_SECRET_COMPLETE';
export const CREATE_JOB_SECRET_ERROR = 'CREATE_JOB_SECRET_ERROR';

export const EDIT_JOB_SECRET_LOADING = 'EDIT_JOB_SECRET_LOADING';
export const EDIT_JOB_SECRET_COMPLETE = 'EDIT_JOB_SECRET_COMPLETE';
export const EDIT_JOB_SECRET_ERROR = 'EDIT_JOB_SECRET_ERROR';

export const DELETE_JOB_SECRET_LOADING = 'DELETE_JOB_SECRET_LOADING';
export const DELETE_JOB_SECRET_COMPLETE = 'DELETE_JOB_SECRET_COMPLETE';
export const DELETE_JOB_SECRET_ERROR = 'DELETE_JOB_SECRET_ERROR';
