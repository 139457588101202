import React from 'react';
import { Loading } from '@puppet/react-components';

export const PAGE_LOADER_TEST_ID = 'page-loader-testid';

const PageLoader = () => (
  <div className="page-loader-container">
    <Loading data-testid={PAGE_LOADER_TEST_ID} size="large" />
  </div>
);

export default PageLoader;
