import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonForm from '@components/CommonForm';
import { Heading, Form, Link } from '@puppet/react-components';
import useDebouncedSearch from '@hooks/useDebouncedSearch';
import { useListPeIntegrationsV2 } from '@services/cd4pe/peIntegrations';
import { useListAvailableNodesV1 } from '@services/cd4pe/hardware';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import {
  CreateJobHardwareCapabilitiesRequestV1,
  UpdateJobHardwareCapabilitiesRequestV1,
} from '@utils/api/cd4pe';
import Cd4peError from '@components/Cd4peError';
import docsLinks from '@codeDelivery/utils/docsLinks';
import FormSidebar from './FormSidebar';
import PeIntegrationSelect from './PeServerSelect';
import NodeTable from './NodeTable';
import { NodeList } from './utils';

type Props = {
  initialName?: string;
  initialNodes?: NodeList;
  onSubmit: (
    payload:
      | CreateJobHardwareCapabilitiesRequestV1
      | UpdateJobHardwareCapabilitiesRequestV1,
  ) => void;
  onCancel: () => void;
};

type FormValues = {
  capabilityName: string;
  peServer: string;
};

const JobHardwareCapabilityForm = ({
  initialName = '',
  initialNodes = [],
  onSubmit,
  onCancel,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const { searchTerm, appliedSearchTerm, handleSearch } = useDebouncedSearch();
  const [currentPage, setCurrentPage] = useState(0);

  const [selectedNodes, setSelectedNodes] = useState<NodeList>(initialNodes);
  const [formValues, setFormValues] = useState<FormValues>({
    capabilityName: initialName,
    peServer: '',
  });

  const peServers = useListPeIntegrationsV2({ workspaceId });
  const nodes = useListAvailableNodesV1(
    {
      workspaceId,
      peIntegrationName: formValues.peServer,
      prefix: appliedSearchTerm,
    },
    {
      enabled: !!formValues.peServer,
    },
  );

  return (
    <CommonForm
      className="job-hardware-capability-form"
      submittable
      submitLabel={
        initialName
          ? t('jobHardwareCapability.form.submit.edit')
          : t('jobHardwareCapability.form.submit.create')
      }
      submitDisabled={!formValues.capabilityName}
      cancellable
      values={formValues}
      onSubmit={() => {
        onSubmit({
          displayName: formValues.capabilityName,
          nodes: selectedNodes.map(
            ({ name, peServerName, operatingSystem }) => ({
              name,
              peServerName,
              operatingSystem,
            }),
          ),
        });
      }}
      onChange={(_, values) => {
        setFormValues((oldValues) => {
          if (oldValues.peServer !== values.peServer) setCurrentPage(0);
          return {
            ...values,
            capabilityName: values.capabilityName?.replaceAll(' ', '-'),
          } as FormValues;
        });
      }}
      onCancel={onCancel}
      data-testid="job-hardware-capability-form"
    >
      <CommonForm.Section>
        <CommonForm.Section.Main>
          <Heading
            as="h4"
            className="job-hardware-capability-form__section-heading"
          >
            {t('jobHardwareCapability.section.name.heading')}
          </Heading>
          <div className="job-hardware-capability-form__field">
            <Form.Field
              type="text"
              name="capabilityName"
              label={t('jobHardwareCapability.section.name.fields.name.label')}
              data-testid="capability-name"
            />
          </div>
        </CommonForm.Section.Main>
      </CommonForm.Section>
      <CommonForm.Section>
        <CommonForm.Section.Main fullWidth>
          <Heading
            as="h4"
            className="job-hardware-capability-form__section-heading"
          >
            {t('jobHardwareCapability.section.nodes.heading')}
          </Heading>
          <div className="job-hardware-capability-form__field">
            <Link
              as="a"
              target="_blank"
              rel="noreferrer"
              href={docsLinks().addHardware}
            >
              {t('jobHardwareCapability.section.nodes.link')}
            </Link>
          </div>
          <div className="job-hardware-capability-form__field">
            <Heading label color="medium">
              {t('jobHardwareCapability.section.nodes.fields.certname.label')}
            </Heading>
            <div
              aria-label={t(
                'jobHardwareCapability.section.nodes.fields.certname.ariaLabel',
              )}
            >
              {t('jobHardwareCapability.section.nodes.fields.certname.value')}
            </div>
          </div>
          <div
            className="job-hardware-capability-form__field"
            data-testid="capability-pe-server"
          >
            <PeIntegrationSelect peServers={peServers}>
              <Form.Field
                type="select"
                name="peServer"
                label={t('jobHardwareCapability.section.nodes.fields.pe.label')}
                options={
                  peServers.data?.peIntegrations?.map((pe) => ({
                    value: pe.name,
                    label: pe.name,
                  })) ?? []
                }
                placeholder={t(
                  'jobHardwareCapability.section.nodes.fields.pe.placeholder',
                )}
              />
            </PeIntegrationSelect>
          </div>
          {formValues.peServer && (
            <NodeTable
              searchTerm={searchTerm}
              appliedSearchTerm={appliedSearchTerm}
              handleSearch={handleSearch}
              nodesQuery={nodes}
              selectedNodes={selectedNodes}
              onSetSelectedNodes={(newSelectedNodes: NodeList) =>
                setSelectedNodes(newSelectedNodes)
              }
              currentPage={currentPage}
              onChangeCurrentPage={(newPage: number) => setCurrentPage(newPage)}
            />
          )}
        </CommonForm.Section.Main>
        <CommonForm.Section.Sidebar className="" size="medium">
          <FormSidebar
            nodes={selectedNodes}
            onDeleteAll={() => setSelectedNodes([])}
            onDeleteNode={(index: number) => {
              const clone = window.structuredClone(selectedNodes);
              clone.splice(index, 1);
              setSelectedNodes(clone);
            }}
          />
        </CommonForm.Section.Sidebar>
      </CommonForm.Section>
      <Cd4peError error={peServers.error} />
      <Cd4peError error={nodes.error} />
    </CommonForm>
  );
};

export default JobHardwareCapabilityForm;
