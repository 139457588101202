export const FORM_TYPE = 'FORM_TYPE';
export const AUTH_TYPE = 'AUTH_TYPE';
export const EDITING_TOKEN_LIFETIME = 'EDITING_TOKEN_LIFETIME';
export const TOKEN_UNITS = 'TOKEN_UNITS';
export const IA_CONFIG_MODAL = 'IA_CONFIG_MODAL';
export const DISCOVER_INTEGRATION_START = 'DISCOVER_INTEGRATION_START';
export const DISCOVER_INTEGRATION_COMPLETE = 'DISCOVER_INTEGRATION_COMPLETE';
export const DISCOVER_INTEGRATION_ERROR = 'DISCOVER_INTEGRATION_ERROR';
export const CREATE_INTEGRATION_START = 'CREATE_INTEGRATION_START';
export const CREATE_INTEGRATION_COMPLETE = 'CREATE_INTEGRATION_COMPLETE';
export const CREATE_INTEGRATION_ERROR = 'CREATE_INTEGRATION_ERROR';
export const CONFIGURE_IA_START = 'CONFIGURE_IA_START';
export const CONFIGURE_IA_COMPLETE = 'CONFIGURE_IA_COMPLETE';
export const CONFIGURE_IA_ERROR = 'CONFIGURE_IA_ERROR';
