import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Text,
  Button,
  Card,
  Heading,
  Icon,
  TooltipHoverArea,
} from '@puppet/react-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  ProjectPipelineDestinationV1,
  ProjectPipelineGateV1,
} from '@puppet/cd4pe-client-ts';
import ConditionalRender from '@components/ConditionalRender';

interface Props {
  destination?: ProjectPipelineDestinationV1;
  manageAsCode?: boolean;
  pipelineGate?: ProjectPipelineGateV1;
  onDelete: () => void;
  requiresAction?: 'DEPLOYMENT_WARNING';
  reordering?: boolean;
  onEditItem?: (() => void) | undefined;
  onEditPlaceholder?: () => void;
  preventDeleteStage?: boolean;
  isRegex?: boolean;
}

const StageCardItem = ({
  destination,
  manageAsCode = false,
  pipelineGate,
  requiresAction,
  onDelete,
  reordering,
  onEditItem,
  onEditPlaceholder = () => {},
  preventDeleteStage,
  isRegex,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [deleteOpen, setDeleteOpen] = useState(false);

  let description = null;
  let typeTranslationKey = 'viewPipeline.stage.card.delete.item.title.type.job';
  if (destination?.placeholder && !reordering) {
    switch (destination?.placeholder) {
      case 'DEPLOYMENT_PLACEHOLDER':
        description = (
          <div
            className={
              requiresAction === 'DEPLOYMENT_WARNING'
                ? 'stage-card-item__add-deployment-warning-placeholder'
                : 'stage-card-item__placeholder'
            }
          >
            <ConditionalRender enable={requiresAction === 'DEPLOYMENT_WARNING'}>
              <Heading className="stage-card-item__placeholder-heading" as="h6">
                <Icon type="alert" />
                {t('viewPipeline.stage.card.add.deployment.item.heading')}
              </Heading>
              <Text size="small" color="subtle">
                {t(
                  'viewPipeline.stage.card.add.deploymentWarning.item.to.stage',
                )}
              </Text>
            </ConditionalRender>
            <Button
              type="text"
              onClick={onEditPlaceholder}
              data-testid="add-deployment-button"
            >
              {t('viewPipeline.stage.card.add.deployment.item.to.stage')}
            </Button>
          </div>
        );

        break;
      case 'IMPACT_ANALYSIS_PLACEHOLDER':
        description = (
          <div className="stage-card-item__placeholder">
            <Button type="text" onClick={onEditPlaceholder}>
              {t('viewPipeline.stage.card.add.impact.analysis.item.to.stage')}
            </Button>
          </div>
        );
        break;
      case 'JOB_PLACEHOLDER':
        description = (
          <div className="stage-card-item__placeholder">
            <Button type="text" onClick={onEditPlaceholder}>
              {t('viewPipeline.stage.card.add.job.item.to.stage')}
            </Button>
          </div>
        );
        break;
      default:
    }
  } else if (destination?.type === 'JOB') {
    description = (
      <div className="stage-card__detail-value" data-testid="job-stage-item">
        <Trans
          t={t}
          i18nKey="viewPipeline.stages.card.item.type.job"
          values={{
            name: destination?.vmJobTemplateName,
          }}
          components={[
            <Text size="small" />,
            <Text size="small" color="medium" />,
          ]}
        />
      </div>
    );
  } else if (destination?.type === 'DEPLOYMENT') {
    typeTranslationKey =
      'viewPipeline.stage.card.delete.item.title.type.deployment';
    description = (
      <>
        <div
          className="stage-card__detail-value"
          data-testid="deployment-stage-item"
        >
          <Trans
            t={t}
            i18nKey="viewPipeline.stages.card.item.type.deployment.policy"
            values={{
              name:
                destination?.deploymentTemplate?.deploymentPolicy
                  ?.displayName ?? 'Policy missing',
            }}
            components={[
              <Text size="small" />,
              <Text size="small" color="medium" />,
            ]}
          />
        </div>
        <div className="stage-card__detail-value">
          <Trans
            t={t}
            i18nKey="viewPipeline.stages.card.item.type.deployment.instance"
            values={{
              name:
                destination.deploymentTemplate?.puppetEnterpriseCredentialsId
                  ?.name ?? 'Instance missing',
            }}
            components={[
              <Text size="small" />,
              <Text size="small" color="medium" />,
            ]}
          />
        </div>
        {!isRegex && (
          <div className="stage-card__detail-value">
            <Trans
              t={t}
              i18nKey="viewPipeline.stages.card.item.type.deployment.environment"
              values={{
                name:
                  destination?.deploymentTemplate?.deploymentTarget
                    ?.nodeGroupName ?? 'Environment missing',
              }}
              components={[
                <Text size="small" />,
                <Text size="small" color="medium" />,
              ]}
            />
          </div>
        )}
      </>
    );
  } else if (destination?.type === 'IMPACT_ANALYSIS') {
    typeTranslationKey = 'viewPipeline.stage.card.delete.item.title.type.ia';
    const numberOfEnvironments = destination?.peImpactAnalysisTemplate?.settings
      ?.analyzeAllDeployments
      ? t('viewPipeline.stages.card.item.type.impactAnalysis.allEnvironments')
      : t('viewPipeline.stages.card.item.type.impactAnalysis.environments', {
          numberOfEnvironments:
            destination?.peImpactAnalysisTemplate?.settings?.environments
              ?.length ?? 0,
        });

    description = (
      <div className="stage-card__detail-value" data-testid="ia-stage-item">
        <Trans
          t={t}
          i18nKey="viewPipeline.stages.card.item.type.impactAnalysis"
          values={{
            name: numberOfEnvironments,
          }}
          components={[
            <Text size="small" />,
            <Text size="small" color="medium" />,
          ]}
        />
      </div>
    );
  } else if (pipelineGate) {
    typeTranslationKey =
      'viewPipeline.stage.card.delete.item.title.type.prgate';
    description = (
      <Text size="small">
        {t('viewPipeline.stages.card.item.type.pipelineGate')}
      </Text>
    );
  }

  if (!description) {
    return null;
  }

  return (
    <div
      className="stage-card-item"
      data-testid={`stage-card-item-${
        destination?.id || pipelineGate?.projectPipelineGateType
      }`}
    >
      <Card
        className={classnames('stage-card-item__description', {
          deleteOpen,
        })}
      >
        {description}
        <ConditionalRender enable={!manageAsCode && deleteOpen}>
          <div className="stage-card-item__delete">
            <Text size="small" color="danger">
              {t('viewPipeline.stage.card.delete.item.title', {
                type: t(typeTranslationKey),
              })}
            </Text>
            <div>
              <Button
                type="danger"
                onClick={() => {
                  setDeleteOpen(false);
                  onDelete();
                }}
                data-testid="stage-card-delete-item-confirm"
              >
                {t('viewPipeline.stage.card.delete.item.confirm')}
              </Button>
              <Button
                type="danger"
                weight="subtle"
                onClick={() => setDeleteOpen(false)}
                data-testid="stage-card-delete-item-cancel"
              >
                {t('viewPipeline.stage.card.delete.item.cancel')}
              </Button>
            </div>
          </div>
        </ConditionalRender>
      </Card>
      <ConditionalRender
        enable={
          !manageAsCode &&
          !destination?.placeholder &&
          !reordering &&
          !!onEditItem
        }
      >
        <Button
          type="secondary"
          icon="pencil"
          onClick={onEditItem}
          data-testid={`card-item-edit-${destination?.id}`}
          aria-label={t('viewPipeline.stages.card.item.type.edit.ariaLabel', {
            name: destination?.type ?? '',
          })}
        />
      </ConditionalRender>
      <ConditionalRender
        enable={!manageAsCode && !destination?.placeholder && !reordering}
      >
        <TooltipHoverArea
          disabled={!preventDeleteStage}
          tooltip={t('viewPipeline.stage.card.delete.item.hover')}
          anchor="top"
        >
          <Button
            className="stage-card-item__delete-button"
            type="danger"
            weight="subtle"
            disabled={preventDeleteStage}
            icon="close"
            data-testid={`card-item-delete-${destination?.id}`}
            onClick={() => {
              setDeleteOpen(true);
            }}
            aria-label={t(
              'viewPipeline.stages.card.item.type.delete.ariaLabel',
              {
                name: destination?.type ?? '',
              },
            )}
          />
        </TooltipHoverArea>
      </ConditionalRender>
    </div>
  );
};

export default StageCardItem;

StageCardItem.defaultProps = {
  destination: {},
  manageAsCode: false,
  pipelineGate: null,
  requiresAction: null,
  reordering: false,
  onEditItem: undefined,
  preventDeleteStage: false,
  isRegex: false,
};
