import PageLayout from '@components/PageLayout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@puppet/react-components';
import Breadcrumbs, {
  BreadcrumbsType,
} from '@components/Breadcrumbs/Breadcrumbs';
import { useSelector } from 'react-redux';
import { selectUser } from '@state/session/selectors';
import { UserV1 } from '@puppet/cd4pe-client-ts';
import UserDetailsCard from '@codeDelivery/components/UserDetailsCard';

const RootAccounts = () => {
  const { t } = useTranslation('codeDelivery');
  const user: UserV1 = useSelector(selectUser);

  const breadcrumbs: BreadcrumbsType = [
    {
      displayName: t('root.accounts.breadcrumbs.root.console'),
    },
    {
      displayName: t('root.accounts.breadcrumbs.root.accounts'),
    },
  ];

  return (
    <PageLayout className="root-accounts-page">
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('root.accounts.header')}</Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        {user.rootUser ? <UserDetailsCard user={user} /> : ''}
      </PageLayout.Content>
    </PageLayout>
  );
};

export default RootAccounts;
