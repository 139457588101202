import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Heading } from '@puppet/react-components';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { LINKS } from 'src/routes';

const PeHeading = () => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const settingsAddPePath = LINKS.settings.newPuppetEnterprise({
    path: { workspace },
  });

  return (
    <div className="pe-list-header__cta">
      <Heading as="h3">{t('peList.page.title')}</Heading>
      <Button as={NavLink} to={settingsAddPePath}>
        {t('peList.buttons.addPe.label')}
      </Button>
    </div>
  );
};

export default PeHeading;
