import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from '@puppet/data-grid';
import { FactColumnCell } from 'inventory/components/NodeTable';
import './FactsTable.scss';

const columns = (t) => [
  { label: t('factsTable.columns.key'), dataKey: 'key' },
  {
    label: t('factsTable.columns.value'),
    dataKey: 'FactValue',
    cellRenderer: FactColumnCell,
  },
];

const FactsTable = ({
  factData,
  emptyStateMessage,
  emptyStateHeader,
  loading,
}) => {
  const constructFactTableData = (data) =>
    data.map((fact) => {
      return {
        key: fact.name,
        FactValue: fact.value,
      };
    });
  const [factTableData, setFactTableData] = useState(
    constructFactTableData(factData),
  );

  const { t } = useTranslation('inventory');

  useEffect(() => {
    setFactTableData(constructFactTableData(factData));
  }, [factData]);

  return (
    <div className="nd-fact-view">
      <Table
        data={factTableData}
        columns={columns(t)}
        emptyStateHeader={emptyStateHeader}
        emptyStateMessage={emptyStateMessage}
        loading={loading}
        loadingMessage={t('nodeTable.loading.message')}
      />
    </div>
  );
};
export default FactsTable;

FactsTable.propTypes = {
  factData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  emptyStateMessage: PropTypes.string.isRequired,
  emptyStateHeader: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
