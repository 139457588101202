import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tabs } from '@puppet/react-components';
import PageHeader from 'components/PageHeader';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  selectWorkspaceName,
  selectWorkspaceDomain,
} from 'state/session/selectors';
import { ErrorMessageProvider } from 'inventory/utils/context/error-context';
import { LINKS } from 'src/routes';
import NodeDetailsHeader from './NodeDetailsHeader';
import FactTab from './FactTab';
import ReportsTab from './ReportsTab';

const tableDisplayMessages = (t) => ({
  loadingHeader: t('common.table.loading.header'),
  loadingMessage: t('common.table.loading.message'),
  noDataHeader: t('common.table.noData.header'),
  emptyFilterMessage: t('common.table.noData.filter.message'),
});

const NodeDetailsView = () => {
  const [activeTab, setTab] = useState('facts-tab');
  const { t } = useTranslation('inventory');
  const workspaceName = useSelector((state) => selectWorkspaceName(state));
  const domain = useSelector((state) => selectWorkspaceDomain(state));

  const { id, source } = useParams();

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            displayName: workspaceName,
            linkDestination: LINKS.inventory.listNodes({
              path: { workspace: workspaceName },
            }),
          },
          {
            displayName: t('nodes.breadcrumb'),
            linkDestination: LINKS.inventory.listNodes({
              path: { workspace: workspaceName },
            }),
          },
          {
            displayName: id,
          },
        ]}
      />
      <PageHeader pageTitle={id} />
      <ErrorMessageProvider>
        <NodeDetailsHeader nodeId={id} workspaceId={domain} source={source} />
        <div className="tableTabs">
          <Tabs id="controlled-tabs" onChange={(tab) => setTab(tab)}>
            <Tabs.Tab
              title={t('nodeDetails.tabs.facts')}
              id="facts-tab"
              active={activeTab === 'facts-tab'}
            >
              <FactTab
                nodeId={id}
                workspaceId={domain}
                tableDisplayMessages={tableDisplayMessages(t)}
                source={source}
              />
            </Tabs.Tab>
            <Tabs.Tab
              title={t('nodeDetails.tabs.reports')}
              id="reports-tab"
              active={activeTab === 'reports-tab'}
            >
              <ReportsTab
                nodeId={id}
                workspaceId={domain}
                tableDisplayMessages={tableDisplayMessages(t)}
                source={source}
              />
            </Tabs.Tab>
          </Tabs>
        </div>
      </ErrorMessageProvider>
    </>
  );
};

export default NodeDetailsView;
