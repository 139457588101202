import React from 'react';
import { Modal } from '@puppet/react-components';
import { useAppDispatch } from '@hooks/redux';
import {
  CodeProjectDetailsV1,
  ProjectPipelineStageV1,
  ProjectTypeV1,
} from '@puppet/cd4pe-client-ts';
import alerts from '@state/ui/alerts';
import { useTranslation } from 'react-i18next';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import Cd4peError from '@components/Cd4peError';
import { useUpsertPipelineStagesV1 } from '@services/cd4pe/pipelines';
import ImpactAnalysisForm, {
  ImpactAnalysis,
  ImpactAnalysisFormProps,
} from '@codeDelivery/components/ViewPipeline/components/ImpactAnalysisForm';
import { buildUpdateImpactAnalysisRequest } from '@codeDelivery/components/ViewPipeline/utils';
import { DestinationSelector } from '../../Stages';

interface EditDeploymentDialogProps {
  codeProject: CodeProjectDetailsV1;
  projectType: ProjectTypeV1;
  onClose: () => void;
  selectedDestination: DestinationSelector;
  stages: Array<ProjectPipelineStageV1>;
  pipelineId: string;
  branch: string;
}

const EditImpactAnalysisDialog = ({
  codeProject,
  projectType,
  onClose,
  selectedDestination,
  stages,
  pipelineId,
  branch,
}: EditDeploymentDialogProps) => {
  const upsertDeployment = useUpsertPipelineStagesV1();
  const appDispatch = useAppDispatch();
  const workspaceId = useWorkspaceDomain();
  const { t } = useTranslation('codeDelivery');

  const showErrorBanner = () => {
    appDispatch(
      alerts.actions.createAlert({
        type: 'Error',
        message: t('viewPipeline.form.impactAnalysis.edit.error'),
      }),
    );
  };

  const stageData = stages[selectedDestination.stageIndex];
  if (stageData === undefined) {
    showErrorBanner();
    return null;
  }

  const destinationData =
    stageData.destinations?.[selectedDestination.destinationIndex];
  if (destinationData === undefined) {
    showErrorBanner();
    return null;
  }

  const onSubmit = (values: ImpactAnalysis) => {
    upsertDeployment.mutate(
      {
        requestBody: buildUpdateImpactAnalysisRequest(
          values,
          codeProject,
          selectedDestination,
          destinationData,
          stages,
          projectType,
          workspaceId,
        ),
        workspaceId,
        pipelineId,
      },
      {
        onSuccess: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('viewPipeline.dialog.manualDeployment.alert.updated'),
            }),
          );
          onClose();
        },
      },
    );
  };

  const peServerCredentialsId =
    destinationData?.deploymentTemplate?.puppetEnterpriseCredentialsId?.name ||
    '';
  const compiledBatchSize =
    destinationData?.peImpactAnalysisTemplate?.settings?.compileBatchSize;
  const allStageEnvironments =
    destinationData?.peImpactAnalysisTemplate?.settings?.analyzeAllDeployments;

  const stageEnvironments: { [p: string]: boolean } | undefined =
    destinationData?.peImpactAnalysisTemplate?.settings?.environments?.reduce(
      (acc, val) => {
        if (val.pipelineDestinationId) {
          return {
            ...acc,
            [val.pipelineDestinationId]: true,
          };
        }
        return acc;
      },
      {} as { [p: string]: boolean },
    );

  const stageEnvironmentsControlRepos =
    destinationData?.peImpactAnalysisTemplate?.settings?.moduleImpactAnalysisControlRepos?.reduce<
      ImpactAnalysisFormProps['stageEnvironmentsControlRepos']
    >((acc, val) => {
      if (val.peModuleDeploymentId && val.controlRepoId?.name) {
        return {
          ...acc,
          [val.peModuleDeploymentId]: val.controlRepoId.name,
        };
      }
      return acc;
    }, {}) || {};

  const initialFormValues: ImpactAnalysisFormProps = {
    branch,
    commit: '',
    allStageEnvironments: allStageEnvironments || false,
    environment: '',
    nodes: compiledBatchSize || 10,
    stageEnvironments: stageEnvironments || {},
    stageEnvironmentsControlRepos,
    puppetEnterpriseServer: peServerCredentialsId,
    controlRepo: '',
  };

  return (
    <Modal className="manual-impactAnalysis-dialog" onClose={onClose}>
      <Modal.Title>
        {t('viewPipeline.form.impactAnalysis.edit.title')}
      </Modal.Title>
      <ImpactAnalysisForm
        type="stage"
        projectType={projectType}
        onSubmit={onSubmit}
        submitting={upsertDeployment.isLoading}
        onCancel={onClose}
        initialValues={initialFormValues}
        submitKey="viewPipeline.form.impactAnalysis.edit.buttons.submit"
        cancelKey="viewPipeline.form.impactAnalysis.edit.buttons.cancel"
        codeProject={codeProject}
        stages={stages}
      />
      <Cd4peError error={upsertDeployment.error} />
    </Modal>
  );
};

export default EditImpactAnalysisDialog;
