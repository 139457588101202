import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@state/store';

export type AlertType = 'Error' | 'Success' | 'Info' | 'Warning';

export interface AlertOptions {
  dismissAfter?: number | null;
  message: string;
  type: AlertType;
  closeable?: boolean;
}

export interface ManagedAlertOptions extends AlertOptions {
  dismissAfter: number | null;
  closeable: boolean;
  id: string;
}

export interface AlertsState {
  data: ManagedAlertOptions[];
}

const initialState: AlertsState = {
  data: [],
};

const slice = createSlice({
  name: 'ui/alerts',
  initialState,
  reducers: {
    createAlert: (state, action: PayloadAction<AlertOptions>) => {
      state.data = [
        ...state.data,
        {
          ...action.payload,
          closeable: action.payload.closeable ?? true,
          dismissAfter:
            action.payload.dismissAfter === undefined
              ? 5000
              : action.payload.dismissAfter,
          id: nanoid(),
        },
      ];
    },
    dismissAlert: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((a) => a.id !== action.payload);
    },
  },
});

export const uiAlertsReducer = slice.reducer;

const { actions } = slice;

const selector = (state: RootState) => state.ui.alerts.data;

export default {
  selector,
  actions,
};
