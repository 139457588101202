import {
  Cd4peApiError,
  handleQueryRequest,
  QueryHook,
  SharedDockerImageSettingsV1Service,
} from '@utils/api/cd4pe';
import { useQuery } from '@tanstack/react-query';

const GET_SHARED_DOCKER_IMAGE_SETTINGS_QUERY_TAG =
  'getSharedDockerImageSettings';

/**
 * useGetSharedDockerImageSettingsV1
 */
type UseGetSharedDockerImageSettingsV1 = QueryHook<
  typeof SharedDockerImageSettingsV1Service,
  'getSharedDockerImageSettingsV1',
  Cd4peApiError
>;

const useGetSharedDockerImageSettingsV1: UseGetSharedDockerImageSettingsV1 =
  () =>
    useQuery([GET_SHARED_DOCKER_IMAGE_SETTINGS_QUERY_TAG], async () =>
      handleQueryRequest(
        SharedDockerImageSettingsV1Service.getSharedDockerImageSettingsV1(),
      ),
    );

export default useGetSharedDockerImageSettingsV1;
