import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Link as PdsLink } from '@puppet/react-components';

type Props = {
  ssoUrl: string;
  onChangeMethod: Function;
};

const SamlForm = ({ ssoUrl, onChangeMethod }: Props) => {
  const { t } = useTranslation('codeDelivery');

  return (
    <>
      <Button
        data-testid="login-form-sso-button"
        className="saml-form__button"
        as="a"
        href={ssoUrl}
      >
        {t('login.form.saml.button')}
      </Button>
      <div className="ldap-form__links">
        <PdsLink
          data-testid="login-form-root-login-link"
          as={Link}
          onClick={onChangeMethod}
        >
          {t('login.form.saml.links.root')}
        </PdsLink>
      </div>
    </>
  );
};

export default SamlForm;
