import React, { PropsWithChildren } from 'react';

const PageLayoutBreadcrumbs = ({
  children,
  // eslint-disable-next-line react/prop-types
  className = '',
  ...props
}: PropsWithChildren<React.ComponentPropsWithRef<'div'>>) => (
  <div className={`page-layout-header__breadcrumbs ${className}`} {...props}>
    {children}
  </div>
);

export default PageLayoutBreadcrumbs;
