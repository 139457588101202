import { AddCodeProjectFormActions } from '@codeDelivery/components/AddCodeProjectForm';
import actionCreator, { Actions } from '@utils/actionCreator';
import {
  Cd4peApiError,
  CreateModuleRequestV1,
  CreateModuleResponseV1,
  CreateVcsBranchRequestV1,
  handleApiRequest,
  isCd4peApiError,
  isInternalError,
  ModulesV1Service,
  VcsV1Service,
  WorkspaceIdV1,
} from '@utils/api/cd4pe';
import { VcsActions } from '@utils/api/cd4pe/vcs/actions';
import { Dispatch } from 'react';
import * as at from './actionTypes';

export const newModuleActions = {
  saveModuleStart: () => actionCreator(at.SAVE_MODULE_START),
  saveModuleComplete: (payload: CreateModuleResponseV1) =>
    actionCreator(at.SAVE_MODULE_COMPLETE, payload),
  saveModuleError: (error: Cd4peApiError) =>
    actionCreator(at.SAVE_MODULE_ERROR, error),
  saveModuleWebhookError: () => actionCreator(at.SAVE_MODULE_WEBHOOK_ERROR),
  createMainBanch: (payload: boolean) =>
    actionCreator(at.CREATE_MAIN_BRANCH, payload),
  closeModal: () => actionCreator(at.CLOSE_MODAL),
};

export type NewModuleActions =
  | Actions<typeof newModuleActions>
  | VcsActions
  | AddCodeProjectFormActions;

export interface CreateModulePayload {
  workspaceId: WorkspaceIdV1;
  requestBody: CreateModuleRequestV1;
}

export const saveModule = async (
  dispatch: Dispatch<NewModuleActions>,
  modulePayload: CreateModulePayload,
  branchPayload?: CreateVcsBranchRequestV1,
) => {
  const saveModuleCall = async (payload: CreateModulePayload) => {
    const moduleResponse = await handleApiRequest(
      ModulesV1Service.createModuleV1(payload),
    );

    if (isInternalError(moduleResponse) && moduleResponse.body.code === 'VCS') {
      dispatch(newModuleActions.saveModuleWebhookError());
      return;
    }

    if (isCd4peApiError(moduleResponse)) {
      dispatch(newModuleActions.saveModuleError(moduleResponse));
      return;
    }

    dispatch(newModuleActions.saveModuleComplete(moduleResponse));
  };

  if (branchPayload) {
    const branchResponse = await handleApiRequest(
      VcsV1Service.createBranchV1({
        workspaceId: modulePayload.workspaceId,
        requestBody: branchPayload,
      }),
    );

    if (isCd4peApiError(branchResponse)) {
      dispatch(newModuleActions.saveModuleError(branchResponse));
      return;
    }

    const updatedModulePayload: CreateModulePayload = {
      ...modulePayload,
      requestBody: {
        ...modulePayload.requestBody,
        pacBranch: branchPayload.name,
      },
    };

    await saveModuleCall(updatedModulePayload);
    return;
  }

  await saveModuleCall(modulePayload);
};
