import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  QueryHook,
  handleQueryRequest,
  Cd4peApiError,
  WebhooksV1Service,
  MutationHook,
} from '@utils/api/cd4pe';
import { LIST_TRIGGER_EVENTS_QUERY_TAG } from './events';

const WEBHOOK_EVENT_QUERY_TAG = 'webhookEvent';

/**
 * useGetWebhookEventV1
 */

type UseGetWebhookEventV1 = QueryHook<
  typeof WebhooksV1Service,
  'getWebhookEventV1',
  Cd4peApiError
>;

export type UseGetWebhookEventV1Result = ReturnType<UseGetWebhookEventV1>;

export const useGetWebhookEventV1: UseGetWebhookEventV1 = (payload) =>
  useQuery(
    [
      WEBHOOK_EVENT_QUERY_TAG,
      payload.workspaceId,
      payload.projectName,
      payload.webhookEventId,
      payload.projectType,
    ],
    async () =>
      handleQueryRequest(WebhooksV1Service.getWebhookEventV1(payload)),
  );

/**
 * useRedeliverWebhookDataV1
 */

type UseRedeliverWebhookDataV1 = MutationHook<
  typeof WebhooksV1Service,
  'redeliverWebhookData',
  Cd4peApiError
>;

export type UseRedeliverWebhookDataV1Result =
  ReturnType<UseRedeliverWebhookDataV1>;

export const useRedeliverWebhookDataV1: UseRedeliverWebhookDataV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(WebhooksV1Service.redeliverWebhookData(payload)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([LIST_TRIGGER_EVENTS_QUERY_TAG]);
      },
    },
  );
};
