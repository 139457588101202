import {
  formatDistanceToNow as fnsFormatDistanceToNow,
  format,
  isValid,
  intervalToDuration,
  toDate,
} from 'date-fns';
import { enUS, enGB } from 'date-fns/locale';

const SECONDS_IN_A_DAY = 86400;
const DEFAULT_LABELS = {
  years: 'y',
  months: 'M',
  days: 'd',
  hours: 'h',
  minutes: 'm',
  seconds: 's',
};
const fnsLocales = { enUS, enGB };

export const formatDistanceToNow = (date, locale, options = {}) => {
  return fnsFormatDistanceToNow(date, {
    locale: fnsLocales[locale],
    ...options,
  });
};

export const getFormattedDate = (rawDate) => {
  const date = new Date(rawDate);
  return isValid(date) ? format(date, 'yyyy-MM-dd HH:mm:ss') : null;
};

// returns seconds formatted as hh:mm:ss
// if seconds amount to greater than a day return 23:59:59
export const getFormattedSeconds = (seconds) => {
  const secondsToFormat = seconds ?? 0;

  let date;
  if (secondsToFormat >= SECONDS_IN_A_DAY) {
    date = new Date((SECONDS_IN_A_DAY - 1) * 1000);
  } else {
    date = new Date(secondsToFormat * 1000);
  }

  return isValid(date) ? date.toISOString().substr(11, 8) : '00:00:00';
};

export const monthDayYearTime = (millis) => {
  const date = new Date(millis);

  return new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);
};

const isTooLong = (duration, errorAt) => {
  if (!duration || !errorAt) {
    return true;
  }
  return duration[errorAt] !== 0;
};

const durationLabels = (labelOverrides) =>
  !labelOverrides
    ? { ...DEFAULT_LABELS }
    : { ...DEFAULT_LABELS, ...labelOverrides };

const removeZeroValues = (duration) =>
  Object.keys(duration).filter((k) => duration[k] !== 0);

const buildDurationString = (duration, labels) =>
  removeZeroValues(duration).reduce(
    (durationStr, k) => `${durationStr} ${duration[k]}${labels[k]}`,
    '',
  ) || `0${labels.seconds}`;

/**
 *
 * @typedef {Object} DurationLabels Structure of a object for overriding duration labels
 * @property {string} [years] Label to be used for years
 * @property {string} [months] Label to be used for months
 * @property {string} [days] Label to be used for days
 * @property {string} [hours] Label to be used for hours
 * @property {string} [minutes] Label to be used for minutes
 * @property {string} [seconds] Label to be used for seconds
 */

/**
 * Generates a string representing the time between two dates.
 * @param {number} start Interval start time in milliseconds
 * @param {?number} end Interval end time in milliseconds. If null then current time is used.
 * @param {Object} [options] Additional options for formatting.
 * @param {string} [options.errorAt=months] ditional options for formatting.
 * @param {DurationLabels} [options.labels] Object containing labels that should override the default labels.
 *
 * @return {string} String representation of time between dates.
 */
export const getFormattedDuration = (
  start,
  end,
  options = { errorAt: 'months' },
) => {
  if (!isValid(start) || (end !== null && !isValid(end))) {
    return 'ERROR';
  }

  const duration = intervalToDuration({
    start: toDate(start),
    end: end === null ? new Date() : toDate(end),
  });

  if (isTooLong(duration, options.errorAt)) {
    return 'TOO_LONG';
  }

  return buildDurationString(duration, durationLabels(options.labels));
};
