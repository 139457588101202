import React from 'react';
import { Button, Modal, Text } from '@puppet/react-components';
import { CodeProjectDetailsV1, ProjectTypeV1 } from '@utils/api/cd4pe';
import { useTriggerDeploymentV1 } from '@services/cd4pe/deployments';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useAppDispatch } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import alerts from '@state/ui/alerts';
import Cd4peError from '@components/Cd4peError';
import useWorkspaceName from '@hooks/useWorkspaceName';
import DeploymentForm, { Deployment } from '../DeploymentForm';
import { buildManualDeploymentRequest } from '../../utils';
import { LINKS } from '../../../../../routes';

interface Props {
  codeProject: CodeProjectDetailsV1;
  onClose: () => void;
  projectType: ProjectTypeV1;
}

export const getDisplayName = (deployment: Deployment, t: Function) => {
  let displayName = t('viewPipeline.dialog.manualDeployment.form.displayName', {
    environment: deployment.nodeGroup?.environment,
    peIntegrationName: deployment.puppetEnterpriseServer?.name,
  });

  if (
    deployment.deploymentPolicy?.name === 'cd4pe_deployments::feature_branch'
  ) {
    // Feature branch is special cased since that policy will never have a node group / target env associated with.
    displayName = t(
      'viewPipeline.dialog.manualDeployment.form.displayName.featureBranch',
      {
        peIntegrationName: deployment.puppetEnterpriseServer?.name,
      },
    );
  }

  return displayName;
};

const DeploymentDialog = ({ codeProject, onClose, projectType }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const workspaceName = useWorkspaceName();
  const appDispatch = useAppDispatch();

  const triggerDeployment = useTriggerDeploymentV1();

  const onDeployment = (deployment: Deployment) => {
    if (!triggerDeployment.isSuccess) {
      triggerDeployment.mutate(
        {
          requestBody: buildManualDeploymentRequest(
            deployment,
            codeProject,
            getDisplayName(deployment, t),
            workspaceId,
            projectType,
          ),
        },
        {
          onSuccess: () => {
            appDispatch(
              alerts.actions.createAlert({
                type: 'Success',
                message: t(
                  'viewPipeline.dialog.manualDeployment.alert.success',
                ),
              }),
            );
          },
        },
      );
    } else {
      window.location.href = LINKS.cd4pe.viewDeployment({
        path: {
          workspace: workspaceName,
          id: triggerDeployment.data?.id!,
        },
      });
    }
  };

  return (
    <Modal className="manual-deployment-dialog" onClose={() => onClose()}>
      <Modal.Title>{t('viewPipeline.form.deployment.new.title')}</Modal.Title>
      <DeploymentForm
        type="manual"
        codeProject={codeProject}
        onSubmit={onDeployment}
        submitting={triggerDeployment.isLoading}
        submittable={!triggerDeployment.isSuccess}
        cancellable={!triggerDeployment.isSuccess}
        onCancel={() => onClose()}
        disabled={triggerDeployment.isSuccess}
        projectType={projectType}
      />
      {triggerDeployment.isSuccess && (
        <Modal.Actions>
          <Text className="deployment-in-progress-message">
            {t('viewPipeline.form.deployment.inProgress')}
          </Text>
          <Button type="primary" onClick={onDeployment}>
            {t('viewPipeline.dialog.deployment.viewDeployment')}
          </Button>
          <Button type="tertiary" onClick={onClose}>
            {t('viewPipeline.dialog.deployment.done')}
          </Button>
        </Modal.Actions>
      )}
      <Cd4peError error={triggerDeployment.error} />
    </Modal>
  );
};

export default DeploymentDialog;
