import React, { useState } from 'react';
import { Button } from '@puppet/react-components';
import ConfirmDeleteModal from '@components/ConfirmDeleteModal';
import { Trans, useTranslation } from 'react-i18next';
import { Cd4peApiError } from '@utils/api/cd4pe';

type Props = {
  projectName: string;
  typeName: string;
  onDelete: (onSuccess: () => void) => void;
  error?: Cd4peApiError | null;
};

const DeleteProjectButton = ({
  projectName,
  typeName,
  onDelete,
  error = null,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [displayModal, setDisplayModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setDisplayModal(true);
        }}
        icon="trash"
        type="transparent"
        data-testid={`delete-project-button-${projectName}`}
      />
      <ConfirmDeleteModal
        isOpen={displayModal}
        title={t('listProjects.delete.title', { typeName })}
        alert={t('listProjects.delete.alert')}
        checkbox
        checkboxLabel={
          <Trans
            t={t}
            i18nKey="listProjects.delete.checkboxLabel"
            values={{ typeName, projectName }}
            components={[<b />]}
          />
        }
        onCancel={() => {
          setDisplayModal(false);
        }}
        onDelete={() => {
          onDelete(() => {
            setDisplayModal(false);
          });
        }}
        error={error}
      />
    </>
  );
};

export default DeleteProjectButton;
