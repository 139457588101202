import React, { useState } from 'react';
import { Modal, Button, Code, Copy, Alert } from '@puppet/react-components';
import { useTranslation, Trans } from 'react-i18next';

type TokenCreatedDialogProps = {
  onClose: () => void;
  token: string;
};

const TokenCreatedDialog = ({ onClose, token }: TokenCreatedDialogProps) => {
  const { t } = useTranslation('codeDelivery');
  const [openCopiedAlert, setOpenCopiedAlert] = useState(false);

  const onCloseAlert = () => {
    setOpenCopiedAlert(false);
  };

  const onTokenCopy = () => {
    setOpenCopiedAlert(true);
  };

  return (
    <Modal
      onClose={onClose}
      closeOnEscapeAndOverlay={false}
      className="token-created-dialog"
    >
      <Modal.Title>{t('profile.tokens.created.dialog.title')}</Modal.Title>
      <div
        className="token-created-dialog__content"
        data-testid="create-token-confirm-dialog"
      >
        <div>
          <Trans
            className="token-created-dialog__description"
            t={t}
            i18nKey="profile.tokens.created.dialog.description"
            components={[<strong />]}
          />
        </div>
        <Code type="block">
          <Copy onCopy={onTokenCopy} data-testid="create-token-copy-box">
            {token}
          </Copy>
        </Code>
        {openCopiedAlert && (
          <Alert
            className="token-created-dialog__alert"
            type="success"
            closeable
            data-testid="create-token-copied-alert"
            onClose={onCloseAlert}
          >
            {t('profile.tokens.created.dialog.copied')}
          </Alert>
        )}
        <Modal.Actions>
          <Button type="tertiary" onClick={onClose}>
            {t('profile.tokens.created.dialog.close')}
          </Button>
        </Modal.Actions>
      </div>
    </Modal>
  );
};

export default TokenCreatedDialog;
