import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Alerts as DsAlerts } from '@puppet/react-components';
import uiAlerts, { ManagedAlertOptions } from '@state/ui/alerts';
import { useAppSelector } from '@hooks/redux';
import Alert from './Alert';

const ALERTS_ROOT_ID = 'alerts-root';

const generateAlerts = (alert: ManagedAlertOptions) => (
  <Alert
    key={alert.id}
    type={alert.type}
    dismissAfter={alert.dismissAfter}
    uuid={alert.id}
    closeable={alert.closeable}
  >
    {alert.message}
  </Alert>
);

const Alerts = () => {
  const alerts = useAppSelector(uiAlerts.selector);
  const element = useRef(document.createElement('div'));

  element.current.setAttribute('id', ALERTS_ROOT_ID);

  useEffect(() => {
    document.body.appendChild(element.current);

    const mountedElement = element.current;

    return () => {
      if (mountedElement) {
        mountedElement.remove();
      }
    };
  }, []);

  const renderAlerts = () => <DsAlerts>{alerts.map(generateAlerts)}</DsAlerts>;

  return ReactDOM.createPortal(renderAlerts(), element.current);
};

export default Alerts;
