import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Cd4peApiError,
  handleQueryRequest,
  QueryHook,
  MutationHook,
} from '@utils/api/cd4pe';
import { DeploymentV1Service } from '@puppet/cd4pe-client-ts';
import queryClient from '@services/queryClient';
import { LIST_TRIGGER_EVENTS_QUERY_TAG } from './events';

export const GET_DEPLOYMENT_POLICIES_TAG = 'getDeploymentPolicies';
export const TRIGGER_DEPLOYMENT_QUERY_TAG = 'triggerDeployment';

/**
 * useGetDeploymentPoliciesV1
 */

type UseGetDeploymentPoliciesV1 = QueryHook<
  typeof DeploymentV1Service,
  'getDeploymentPoliciesV1',
  Cd4peApiError
>;

export const useGetDeploymentPoliciesV1: UseGetDeploymentPoliciesV1 = (
  payload,
  options,
) =>
  useQuery(
    [GET_DEPLOYMENT_POLICIES_TAG, payload.workspaceId],
    () => {
      return handleQueryRequest(
        DeploymentV1Service.getDeploymentPoliciesV1(payload),
      );
    },
    options,
  );

/**
 * useTriggerDeploymentV1
 */
type UseTriggerDeploymentV1 = MutationHook<
  typeof DeploymentV1Service,
  'triggerDeploymentV1',
  Cd4peApiError
>;

export type UseTriggerDeploymentV1Result = ReturnType<UseTriggerDeploymentV1>;

export const useTriggerDeploymentV1: UseTriggerDeploymentV1 = () => {
  return useMutation(
    (payload) =>
      handleQueryRequest(DeploymentV1Service.triggerDeploymentV1(payload)),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          LIST_TRIGGER_EVENTS_QUERY_TAG,
          payload.requestBody.workspaceId,
        ]);
      },
    },
  );
};
