import { VcsProviderV1 } from '@utils/api/cd4pe';

export type VcsProviderIconKey = 'bitbucket' | 'github' | 'azure' | 'gitlab';

const getIconType = (
  providerName: VcsProviderV1,
): VcsProviderIconKey | 'alert' => {
  switch (providerName) {
    case 'BITBUCKET':
    case 'BITBUCKET_SERVER':
      return 'bitbucket';
    case 'GITHUB':
    case 'GITHUB_ENTERPRISE':
      return 'github';
    case 'AZURE_DEVOPS':
      return 'azure';
    case 'GITLAB':
      return 'gitlab';
    default:
      return 'alert';
  }
};

export default getIconType;
