import React from 'react';
import { Logo, Text, Link as PdsLink } from '@puppet/react-components';
import { Trans, useTranslation } from 'react-i18next';
import docsLinks from '@codeDelivery/utils/docsLinks';

const CopyrightFooter = () => {
  const { t } = useTranslation('codeDelivery');
  return (
    <div className="copyright-footer">
      <Logo type="full" product="puppet-standalone" />
      <Text className="copyright-footer__text">
        <Trans
          t={t}
          i18nKey="copyright.footer"
          components={[
            <PdsLink
              as="a"
              href={docsLinks().puppetLandingPage}
              target="_blank"
            />,
          ]}
        />
      </Text>
    </div>
  );
};

export default CopyrightFooter;
