import React, { useState } from 'react';
import { Icon, Button } from '@puppet/react-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { KEYBOARD_KEYS } from 'utils/constants';
import './ColumnPicker.scss';

const FactListItem = ({
  children,
  isActive,
  onClick,
  id,
  onRemove,
  defaultFact,
  isLocked,
}) => {
  const [showOnHover, setShowOnHover] = useState(false);

  return (
    <div className="fl-item-container">
      <li
        id={id.factname}
        role="option"
        className={classNames('fl-menu-list-item', {
          'fl-menu-list-item-focused': showOnHover,
          'fl-menu-list-item-disable': isLocked,
        })}
        aria-selected={isActive}
        onClick={() => {
          if (!isLocked) {
            onClick();
          }
        }}
        onKeyDown={(e) => {
          if (
            (e.keyCode === KEYBOARD_KEYS.enter ||
              e.keyCode === KEYBOARD_KEYS.space) &&
            !isLocked
          ) {
            onClick();
          }
        }}
        onFocus={() => {
          if (!isLocked) {
            setShowOnHover(true);
          }
        }}
        onBlur={() => {
          if (!isLocked) {
            setShowOnHover(false);
          }
        }}
        onMouseEnter={() => {
          if (!isLocked) {
            setShowOnHover(true);
          }
        }}
        onMouseLeave={() => {
          if (!isLocked) {
            setShowOnHover(false);
          }
        }}
        tabIndex="0"
      >
        <span className="fl-menu-list-item-content">
          {children}
          {!defaultFact && (
            <div className="fl-item-icon-container">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  onRemove();
                }}
                className="fl-item-remove-button"
                icon="x"
              />
            </div>
          )}
        </span>
        {isActive && (
          <Icon
            role="img"
            alt="check"
            className="fl-menu-list-item-checkmark"
            type="check"
            size="small"
          />
        )}
      </li>
    </div>
  );
};

export default FactListItem;

FactListItem.propTypes = {
  children: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.shape({
    factname: PropTypes.string,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  defaultFact: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
};
