import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@puppet/react-components';
import Table from '@components/Table';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useListTemplatesV1 } from '@services/cd4pe/jobs';
import { JobTemplateSummaryV1 } from '@utils/api/cd4pe';
import Cd4peError from '@components/Cd4peError';

interface Props {
  onSubmit: (values: JobTemplateSummaryV1[]) => void;
  onCancel: () => void;
  submitting: boolean;
  submitKey?: string;
  cancelKey?: string;
}

const checkIfIndeterminateState = (
  data: Array<JobTemplateSummaryV1>,
  selectedData: Set<String>,
) => {
  let checkAllValue = false;
  let indeterminateStateValue = false;

  if (data?.length === selectedData?.size) {
    indeterminateStateValue = false;
    checkAllValue = true;
  } else if (selectedData?.size > 0) {
    indeterminateStateValue = true;
    checkAllValue = false;
  }

  return { checkAllValue, indeterminateStateValue };
};

const JobForm = ({
  onSubmit,
  onCancel,
  submitting,
  submitKey = 'viewPipeline.form.job.button.submit',
  cancelKey = 'viewPipeline.form.job.button.cancel',
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const [searchValue, setSearchValue] = useState('');
  const [selectedData, setSelectedData] = useState<Set<string>>(new Set());

  const { data, isLoading, error } = useListTemplatesV1({
    workspaceId,
  });

  const { checkAllValue, indeterminateStateValue } = checkIfIndeterminateState(
    data?.jobTemplates ?? [],
    selectedData,
  );

  const tableData =
    data?.jobTemplates
      ?.map((job) => ({
        ...job,
        selected: selectedData.has(job.name),
      }))
      .filter((job) => job.name.includes(searchValue)) ?? [];

  return (
    <div className="stage-job-form">
      <Table.TableHeader
        search
        searchPlaceholder={t('viewPipeline.form.job.add.search.placeholder')}
        searchValue={searchValue}
        onSearchChange={(value: string) => setSearchValue(value)}
      />
      <Table
        data={tableData}
        columns={[
          {
            label: t('viewPipeline.form.job.add.table.column.name'),
            dataKey: 'name',
          },
        ]}
        loading={isLoading}
        selectable
        onRowChecked={(checked: boolean, row: JobTemplateSummaryV1) => {
          const newSelectedData = new Set(selectedData);
          if (checked) {
            setSelectedData(newSelectedData.add(row.name));
          } else {
            newSelectedData.delete(row.name);
            setSelectedData(newSelectedData);
          }
        }}
        onHeaderChecked={(checked: boolean) => {
          if (checked) {
            setSelectedData(
              new Set(data?.jobTemplates?.map((job) => job.name)),
            );
          } else {
            setSelectedData(new Set());
          }
        }}
        headerCheckState={checkAllValue}
        headerIndeterminateState={indeterminateStateValue}
        emptyStateHeader={t('viewPipeline.form.job.add.table.empty.header')}
        emptyStateMessage=""
        data-testid="job-table"
      />
      <div className="stage-job-form__buttons" data-testid="job-form-buttons">
        <Button
          disabled={selectedData.size === 0}
          loading={submitting}
          onClick={() => {
            onSubmit(
              data?.jobTemplates?.filter((job) => selectedData.has(job.name))!!,
            );
          }}
          data-testid="stage-job-form-submit"
        >
          {t(submitKey)}
        </Button>
        <Button type="secondary" onClick={onCancel}>
          {t(cancelKey)}
        </Button>
      </div>
      <Cd4peError error={error} />
    </div>
  );
};

export default JobForm;
