import { AddCodeProjectFormActions } from '@codeDelivery/components/AddCodeProjectForm';
import actionCreator, { Actions } from '@utils/actionCreator';
import {
  CreateControlRepoResponseV1,
  CreateControlRepoRequestV1,
  ControlReposV1Service,
  VcsV1Service,
  WorkspaceIdV1,
  CreateVcsBranchRequestV1,
  Cd4peApiError,
  isInternalError,
  isCd4peApiError,
  handleApiRequest,
} from '@utils/api/cd4pe';
import { VcsActions } from '@utils/api/cd4pe/vcs/actions';
import { Dispatch } from 'react';
import * as at from './actionTypes';

export const newControlRepoActions = {
  saveControlRepoStart: () => actionCreator(at.SAVE_CONTROL_REPO_START),
  saveControlRepoComplete: (payload: CreateControlRepoResponseV1) =>
    actionCreator(at.SAVE_CONTROL_REPO_COMPLETE, payload),
  saveControlRepoError: (error: Cd4peApiError) =>
    actionCreator(at.SAVE_CONTROL_REPO_ERROR, error),
  saveControlRepoWebhookError: () =>
    actionCreator(at.SAVE_CONTROL_REPO_WEBHOOK_ERROR),
  createMainBanch: (payload: boolean) =>
    actionCreator(at.CREATE_MAIN_BRANCH, payload),
  closeModal: () => actionCreator(at.CLOSE_MODAL),
};

export type NewControlRepoActions =
  | Actions<typeof newControlRepoActions>
  | VcsActions
  | AddCodeProjectFormActions;

export interface CreateControlRepoPayload {
  workspaceId: WorkspaceIdV1;
  requestBody: CreateControlRepoRequestV1;
}

export const saveControlRepo = async (
  dispatch: Dispatch<NewControlRepoActions>,
  repoPayload: CreateControlRepoPayload,
  branchPayload?: CreateVcsBranchRequestV1,
) => {
  const saveControlRepoCall = async (payload: CreateControlRepoPayload) => {
    const repoResponse = await handleApiRequest(
      ControlReposV1Service.createControlRepoV1(payload),
    );

    if (isInternalError(repoResponse) && repoResponse.body.code === 'VCS') {
      dispatch(newControlRepoActions.saveControlRepoWebhookError());
      return;
    }

    if (isCd4peApiError(repoResponse)) {
      dispatch(newControlRepoActions.saveControlRepoError(repoResponse));
      return;
    }

    dispatch(newControlRepoActions.saveControlRepoComplete(repoResponse));
  };

  if (branchPayload) {
    const branchResponse = await handleApiRequest(
      VcsV1Service.createBranchV1({
        workspaceId: repoPayload.workspaceId,
        requestBody: branchPayload,
      }),
    );

    if (isCd4peApiError(branchResponse)) {
      dispatch(newControlRepoActions.saveControlRepoError(branchResponse));
      return;
    }

    const updatedControlRepoPayload: CreateControlRepoPayload = {
      ...repoPayload,
      requestBody: {
        ...repoPayload.requestBody,
        pacBranch: branchPayload.name,
      },
    };

    await saveControlRepoCall(updatedControlRepoPayload);
    return;
  }

  await saveControlRepoCall(repoPayload);
};
