import React, { PropsWithChildren } from 'react';
import PageLayoutBreadcrumbs from './PageLayoutBreadcrumbs';
import PageLayoutContent from './PageLayoutContent';
import PageLayoutHeader from './PageLayoutHeader';

const PageLayout = ({
  children,
  // eslint-disable-next-line react/prop-types
  className = '',
  ...props
}: PropsWithChildren<React.ComponentPropsWithRef<'div'>>) => (
  <div className={`page-layout ${className}`} {...props}>
    {children}
  </div>
);

PageLayout.Header = PageLayoutHeader;
PageLayout.Content = PageLayoutContent;
PageLayout.Breadcrumbs = PageLayoutBreadcrumbs;

export default PageLayout;
