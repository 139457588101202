import React from 'react';
import { TooltipProps } from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { Card } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation('codeDelivery');

  if (!active) {
    return null;
  }

  return (
    <Card className="activity-page-cards__value-chart-tooltip" elevation={200}>
      <strong>{label}</strong>
      {t('activity.chart.tooltip.timeSaved', { value: payload?.[0]?.value })}
    </Card>
  );
};

export default CustomTooltip;
