import React from 'react';
import RenderAfterTimeout from '@components/RenderAfterTimeout';

const LoadingBar = () => {
  return (
    <RenderAfterTimeout
      newContent={
        <div className="loading-bar">
          <div className="loading-bar-value" />
        </div>
      }
      showNewContent
    />
  );
};

export default LoadingBar;
