import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@puppet/react-components';
import Table, { DataItem } from '@components/Table';
import Cd4peError from '@components/Cd4peError';
import { AuthTokenV1, UserV1 } from '@utils/api/cd4pe';
import { useListTokensV1 } from '@services/cd4pe/auth';
import { stringCompare } from '@utils/compare';
import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

type RowData = DataItem & AuthTokenV1;

type SortUsers = {
  direction: 'asc' | 'desc';
  sortDataKey: string;
};

type Props = {
  user: UserV1;
  onRevokeToken: (token: AuthTokenV1) => void;
};

const TABLE_DATE_FORMAT = 'MMM dd, yyyy';

const AccessTokenTable = ({ user, onRevokeToken }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [sort, setSort] = useState<SortUsers>({
    direction: 'asc',
    sortDataKey: 'name',
  });

  const sortName = (a: AuthTokenV1, b: AuthTokenV1) =>
    (sort.direction === 'asc' ? 1 : -1) * stringCompare(a.name, b.name);

  const sortDate = (
    a: AuthTokenV1,
    b: AuthTokenV1,
    key: keyof Pick<AuthTokenV1, 'createdOn' | 'expiresOn'>,
  ) => {
    const aDate = parseISO(a[key]!);
    const bDate = parseISO(b[key]!);
    return sort.direction === 'asc'
      ? compareAsc(aDate, bDate)
      : compareDesc(aDate, bDate);
  };

  const { data, isLoading, error } = useListTokensV1(
    { userId: user.id! },
    {
      select(d) {
        switch (sort.sortDataKey) {
          case 'name':
            d.authTokens?.sort(sortName);
            break;
          case 'createdOn':
            d.authTokens?.sort((a: AuthTokenV1, b: AuthTokenV1) =>
              sortDate(a, b, 'createdOn'),
            );
            break;
          case 'expiresOn':
            d.authTokens?.sort((a: AuthTokenV1, b: AuthTokenV1) =>
              sortDate(a, b, 'expiresOn'),
            );
            break;
          default:
            break;
        }

        return d;
      },
    },
  );

  const columns = [
    {
      label: t('profile.tokens.table.column.name'),
      dataKey: 'name',

      sortable: true,
    },
    {
      label: t('profile.tokens.table.column.created'),
      dataKey: 'createdOn',
      cellRenderer: (row: { rowData: RowData }) =>
        format(parseISO(row.rowData.createdOn!), TABLE_DATE_FORMAT),
      sortable: true,
    },
    {
      label: t('profile.tokens.table.column.expires'),
      dataKey: 'expiresOn',
      cellRenderer: (row: { rowData: RowData }) =>
        format(parseISO(row.rowData.expiresOn!), TABLE_DATE_FORMAT),
      sortable: true,
    },
    {
      label: t('profile.tokens.table.column.actions'),
      dataKey: 'actions',
      cellRenderer: (row: { rowData: RowData }) => (
        <Button
          type="danger"
          weight="subtle"
          onClick={() => onRevokeToken(row.rowData)}
          data-testid={`revoke-token-${row.rowData.name}`}
        >
          {t('profile.tokens.table.buttons.revoke')}
        </Button>
      ),
    },
  ];

  const onSortHandler = (
    direction: SortUsers['direction'],
    sortDataKey: SortUsers['sortDataKey'],
  ) => {
    setSort({ direction, sortDataKey });
  };

  return (
    <>
      <Table
        data={data?.authTokens ?? []}
        rowKey="id"
        columns={columns}
        loading={isLoading}
        emptyStateHeader={
          isLoading ? '' : t('profile.tokens.table.emptyState.header')
        }
        emptyStateMessage=""
        sortedColumn={sort}
        onSort={onSortHandler}
        data-testid="token-table"
      />
      <Cd4peError error={error} />
    </>
  );
};

export default AccessTokenTable;
