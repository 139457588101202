import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Sidebar,
  ActionSelect,
  TooltipHoverArea,
} from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import docsLinks from '@codeDelivery/utils/docsLinks';
import { LINKS } from 'src/routes';
import getDefaultRoute from '@utils/getDefaultRoute';

const AppSidebar = ({
  workspaceName,
  userName,
  version,
  workspaceActions,
  handleSignOut,
  rootConsolePage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const getWorkspaceSelectionSection = () => {
    if (workspaceActions.length === 0) {
      return (
        <Sidebar.Section key="user profile">
          <Sidebar.Item
            icon="profile"
            className="sb-user-profile"
            title={userName}
          />
        </Sidebar.Section>
      );
    }

    const profilePaths = [
      userName
        ? LINKS.username.profile({ path: { username: userName } })
        : null,
      userName ? LINKS.cd4pe.messages({ path: { username: userName } }) : null,
      LINKS.cd4pe.root,
      LINKS.cd4pe.rootSettings,
      LINKS.cd4pe.rootWorkspaces,
    ].filter((l) => l !== null);

    const label =
      rootConsolePage || profilePaths.includes(location.pathname)
        ? userName
        : workspaceName;

    return (
      <Sidebar.Section key="workspace selection">
        <ActionSelect
          className="sb-select-workspace"
          type="tertiary"
          actions={workspaceActions}
          placeholder={t('sidebar.workspace.action.placeholder')}
          label={label}
          width="100%"
          style={{ background: '#33393d' }}
        />
      </Sidebar.Section>
    );
  };

  const getInventorySection = () => {
    if (!workspaceName || rootConsolePage) return null;

    return (
      <Sidebar.Section
        key="inventory"
        label={t('sidebar.section.inventory.header')}
      >
        <Sidebar.Item
          className="sb-estate-reporting"
          title={t('sidebar.section.inventory.item.nodes')}
          icon="inventory"
          as={NavLink}
          to={LINKS.inventory.listNodes({ path: { workspace: workspaceName } })}
          data-testid="LeftNav_nodeExplorerPageLink_TestId"
        />
        <Sidebar.Item
          className="sb-activity-reporting"
          title={t('sidebar.section.inventory.item.activity')}
          icon="bar-chart"
          as={NavLink}
          to={LINKS.activity.root({ path: { workspace: workspaceName } })}
        />
      </Sidebar.Section>
    );
  };

  const getRootConsoleSection = () => {
    if (!rootConsolePage) return null;

    return (
      <Sidebar.Section
        key="root console"
        label={t('sidebar.section.rootConsole.header')}
      >
        <Sidebar.Item
          className="sb-accounts"
          title={t('sidebar.section.rootConsole.item.accounts')}
          icon="users"
          as={NavLink}
          exact
          end
          to={LINKS.cd4pe.root}
        />
        <Sidebar.Item
          className="sb-settings"
          title={t('sidebar.section.rootConsole.item.settings')}
          icon="gear"
          as={NavLink}
          exact
          end
          to={LINKS.cd4pe.rootSettings}
        />
        <Sidebar.Item
          className="sb-puppet-enterprise"
          title={t('sidebar.section.rootConsole.item.pe')}
          icon="dag"
          as={NavLink}
          to={LINKS.cd4pe.rootPuppetEnterprise}
        />
        <Sidebar.Item
          className="sb-hardware"
          title={t('sidebar.section.rootConsole.item.hardware')}
          icon="hardware"
          as={NavLink}
          to={LINKS.cd4pe.rootJobHardware}
        />
        <Sidebar.Item
          className="sb-workspaces"
          title={t('sidebar.section.rootConsole.item.workspaces')}
          icon="workspaces"
          as={NavLink}
          to={LINKS.cd4pe.rootWorkspaces}
        />
      </Sidebar.Section>
    );
  };

  const getCodeDeliverySection = () => {
    if (!workspaceName) return null;
    if (rootConsolePage) return null;

    return (
      <Sidebar.Section
        key="code delivery"
        label={t('sidebar.section.codeDelivery.header')}
      >
        <Sidebar.Item
          className="sb-control-repos"
          title={t('sidebar.section.codeDelivery.item.controlRepos')}
          icon="control-repo"
          as={NavLink}
          to={LINKS.codeDelivery.listRepositories({
            path: { workspace: workspaceName },
          })}
        />
        <Sidebar.Item
          className="sb-modules"
          title={t('sidebar.section.codeDelivery.item.modules')}
          icon="module"
          as={NavLink}
          to={LINKS.codeDelivery.listModules({
            path: { workspace: workspaceName },
          })}
        />
        <Sidebar.Item
          className="sb-jobs"
          title={t('sidebar.section.codeDelivery.item.jobs')}
          icon="clipboard"
          as={NavLink}
          to={LINKS.codeDelivery.listJobTemplates({
            path: { workspace: workspaceName },
          })}
        />
        <Sidebar.Item
          className="sb-hardware"
          title={t('sidebar.section.codeDelivery.item.hardware')}
          icon="hardware"
          as={NavLink}
          to={LINKS.codeDelivery.jobHardware({
            path: { workspace: workspaceName },
          })}
        />
      </Sidebar.Section>
    );
  };

  const getWorkspaceSection = () => {
    const items = [];
    if (workspaceName && !rootConsolePage) {
      items.push(
        <Sidebar.Item
          className="sb-settings"
          key="settings"
          title={t('sidebar.section.workspace.item.settings')}
          icon="gear"
          as={NavLink}
          to={LINKS.settings.root({ path: { workspace: workspaceName } })}
        />,
      );
    }

    if (userName || workspaceName) {
      items.push(
        <Sidebar.Item
          className="sb-messages"
          key="messages"
          title={t('sidebar.section.workspace.item.messages')}
          icon="email"
          as={NavLink}
          to={LINKS.cd4pe.messages({
            path: { username: userName || workspaceName },
          })} // This can either be the workspace name or the username.  strange behaviour with CD4PE backend routing for messages route.
        />,
      );
    }

    items.push(
      <Sidebar.Item
        className="sb-documentation"
        key="documentation"
        title={t('sidebar.section.workspace.item.docs')}
        icon="book"
        href={docsLinks().sidebarDocumentation}
        target="_blank"
        rel="noopener noreferrer"
      />,
    );

    return (
      <Sidebar.Section
        key="workspace"
        label={t('sidebar.section.workspace.header')}
      >
        {items}
      </Sidebar.Section>
    );
  };

  const sections = [
    getWorkspaceSelectionSection(),
    getRootConsoleSection(),
    getInventorySection(),
    getCodeDeliverySection(),
    getWorkspaceSection(),
  ].filter(Boolean);

  const renderFooter = () => {
    const sidebarFooter = (
      <Sidebar.Footer
        data-testid="sidebar-footer"
        onClick={() => {
          navigate(LINKS.username.profile({ path: { username: userName } }));
        }}
        username={userName}
        version={version}
        enableSignout
        onSignout={handleSignOut}
        tooltip={t('sidebar.section.user.item.signOut')}
      />
    );

    const usernameTooLong = userName && userName.length >= 18;
    const versionTooLong = version && version.length >= 11;
    const displayTooltip = usernameTooLong || versionTooLong;
    if (displayTooltip) {
      return (
        <TooltipHoverArea
          tooltip={
            <>
              <div>
                {t('sidebar.section.footer.item.username', {
                  userName,
                })}
              </div>
              <div>
                {t('sidebar.section.footer.item.version', {
                  version,
                })}
              </div>
            </>
          }
          anchor="top"
          style={{ textAlign: 'left' }}
        >
          {sidebarFooter}
        </TooltipHoverArea>
      );
    }
    return sidebarFooter;
  };

  return (
    <Sidebar className="sidebar">
      <Sidebar.Header
        logo="continuous-delivery"
        ariaLabel={t('sidebar.header.ariaLabel')}
        as="NavLink"
        to={getDefaultRoute({
          workspaceName,
          userName,
        })}
      />
      <Sidebar.Navigation>{sections}</Sidebar.Navigation>
      {renderFooter()}
    </Sidebar>
  );
};

AppSidebar.propTypes = {
  workspaceName: PropTypes.string,
  userName: PropTypes.string,
  version: PropTypes.string.isRequired,
  workspaceActions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  handleSignOut: PropTypes.func.isRequired,
  rootConsolePage: PropTypes.bool.isRequired,
};

AppSidebar.defaultProps = {
  workspaceName: '',
  userName: '',
};

export default AppSidebar;
