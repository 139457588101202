import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  ButtonSelect,
  Heading,
  Text,
  Loading,
} from '@puppet/react-components';
import {
  CodeProjectDetailsV1,
  PipelineDestinationTypeV1,
  ProjectPipelineStageV1,
  ProjectTypeV1,
  TriggerConditionV1,
} from '@puppet/cd4pe-client-ts';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import {
  useCreateDefaultPipelineV1,
  useDeletePipelineStagesV1,
  useGetPipelineV1,
  useUpsertPipelineStagesV1Optimistic,
} from '@services/cd4pe/pipelines';
import ConditionalRender from '@components/ConditionalRender';
import Cd4peError from '@components/Cd4peError';
import AddStageDialog from '@codeDelivery/components/ViewPipeline/components/AddStageDialog';
import EditImpactAnalysisDialog from './components/EditImpactAnalysisDialog';
import PipelinesAsCodePrompt from './components/PipelinesAsCodePrompt';
import ReorderStagesDialog from './components/ReorderStagesDialog';
import DefaultPipelineCard from './components/DefaultPipelineCard';
import StageCard from './components/StageCard';
import PromoteStage from './components/PromoteStage';
import DeletePipeline from './components/DeletePipeline';
import AddPipelineDialog from '../AddPipelineDialog/AddPipelineDialog';
import {
  autoPromoteConditionStage,
  autoPromoteStage,
  deleteStageJob,
  deleteStagePipelineGate,
  impactedJobs,
  renameStage,
} from '../../utils';
import DeleteStage from './components/DeleteStage';
import TriggerPipelineDialog, {
  PromoteStageCommits,
} from '../TriggerPipelineDialog';
import EditDeploymentDialog from './components/EditDeploymentDialog';
import ManagePipelinesDialog from '../ManagePipelinesDialog';
import { AddStageType } from '../AddStageDialog/AddStageDialog';

interface Props {
  codeProject: CodeProjectDetailsV1;
  projectType: ProjectTypeV1;
}

interface PromoteStageState {
  pipeline: string | undefined;
  stageCommits: PromoteStageCommits[] | undefined;
  stageNumber: number | undefined;
  stageName: string | undefined;
}

export type DestinationSelector = {
  stageIndex: number;
  destinationIndex: number;
};

export type EditHandlerType = (
  editType: PipelineDestinationTypeV1,
  destination: DestinationSelector,
) => (() => void) | undefined;

const Stages = ({ codeProject, projectType }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const [isDeletePipelineOpen, setDeletePipelineOpen] = useState(false);
  const [isReorderPipelineOpen, setIsReorderPipelineOpen] = useState(false);
  const [isPipelinesAsCodePromptOpen, setIsPipelinesAsCodePromptOpen] =
    useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState(
    codeProject.pipelines[0].pipelineId ?? '',
  );
  const [openAddPipelineDialog, setOpenAddPipelineDialog] = useState(false);
  const [addStageDialogType, setAddStageDialogType] = useState<AddStageType>();
  const [addStageByNumber, setAddStageByNumber] = useState<number>();
  const [promoteStageProps, setPromoteStageProps] =
    useState<PromoteStageState | null>(null);
  const [openManagePipelineDialog, setOpenManagePipelineDialog] =
    useState(false);
  const [deleteStageNum, setDeleteStageNum] = useState<null | number>(null);
  const [openEditDeploymentDialog, setOpenEditDeploymentDialog] = useState<
    DestinationSelector | undefined
  >(undefined);
  const [openEditImpactAnalysisDialog, setOpenEditImpactAnalysisDialog] =
    useState<DestinationSelector | undefined>(undefined);

  useEffect(() => {
    if (selectedPipeline === '' && codeProject.pipelines[0].pipelineId) {
      setSelectedPipeline(codeProject.pipelines[0].pipelineId);
    }
  }, [codeProject, selectedPipeline]);

  const getOnEditItemHandler: EditHandlerType = (editType, destination) => {
    switch (editType) {
      case 'DEPLOYMENT':
        return () => setOpenEditDeploymentDialog(destination);
      case 'IMPACT_ANALYSIS': {
        return () => setOpenEditImpactAnalysisDialog(destination);
      }
      default: {
        return undefined;
      }
    }
  };

  const pipelineOptions = codeProject.pipelines.map((pipeline) => ({
    value: pipeline.pipelineId,
    label: pipeline.name,
  }));

  const { data, isLoading, error } = useGetPipelineV1(
    {
      workspaceId,
      pipelineId: selectedPipeline,
      projectName: codeProject.name,
    },
    {
      enabled: selectedPipeline !== '',
    },
  );

  const upsertPipelineStages = useUpsertPipelineStagesV1Optimistic();
  const deletePipelineStages = useDeletePipelineStagesV1();
  const useCreateDefaultPipeline = useCreateDefaultPipelineV1();

  const triggers = data?.sources[0]?.autoBuildTriggers
    ?.join(', ')
    .toLowerCase();

  const hasPlaceholderDeploymentStage =
    data?.stages.some((stage) =>
      stage.destinations.some(
        (destination) => destination?.placeholder === 'DEPLOYMENT_PLACEHOLDER',
      ),
    ) ?? false;

  const hasIAConfigured =
    data?.stages.some((stage) =>
      stage.destinations.some(
        (destination) => destination.type === 'IMPACT_ANALYSIS',
      ),
    ) ?? false;

  const onRenameHandler = (stageName: string, stageNumber: number) => {
    const updatedStages = renameStage(stageName, stageNumber, data?.stages!);

    upsertPipelineStages.mutate({
      workspaceId,
      pipelineId: selectedPipeline,
      requestBody: {
        projectName: codeProject.name,
        stages: updatedStages,
      },
    });
  };

  const onAutoPromoteHandler = (autoPromote: boolean, stageNumber: number) => {
    const updatedStages = autoPromoteStage(
      autoPromote,
      stageNumber,
      data?.stages!,
    );

    upsertPipelineStages.mutate({
      workspaceId,
      pipelineId: selectedPipeline,
      requestBody: {
        projectName: codeProject.name,
        stages: updatedStages,
      },
    });
  };

  const onAutoPromoteConditionHandler = (
    autoPromoteCondition: TriggerConditionV1,
    stageNumber: number,
  ) => {
    const updatedStages = autoPromoteConditionStage(
      autoPromoteCondition,
      stageNumber,
      data?.stages!,
    );

    upsertPipelineStages.mutate({
      workspaceId,
      pipelineId: selectedPipeline,
      requestBody: {
        projectName: codeProject.name,
        stages: updatedStages,
      },
    });
  };

  const jobsNotToDelete = (id: string) => {
    return impactedJobs(data?.stages!, id);
  };

  const isRegex =
    codeProject?.pipelines?.find(
      (pipeline) => pipeline.pipelineId === selectedPipeline,
    )?.name === 'regex';

  const onDeleteJobHandler = (id: string, stageNumber: number) => {
    const updatedStages = deleteStageJob(id, stageNumber, data?.stages!);

    const { stages } = data!;
    if (stages.length === 1 && stages[0].destinations.length === 1) {
      deletePipelineStages.mutate({
        workspaceId,
        pipelineId: selectedPipeline,
        projectName: codeProject.name,
      });
      return;
    }
    upsertPipelineStages.mutate({
      workspaceId,
      pipelineId: selectedPipeline,
      requestBody: {
        projectName: codeProject.name,
        stages: updatedStages,
      },
    });
  };

  const onDeletePipelineGateHandler = (stageNumber: number) => {
    const updatedStages = deleteStagePipelineGate(stageNumber, data?.stages!);

    upsertPipelineStages.mutate({
      workspaceId,
      pipelineId: selectedPipeline,
      requestBody: {
        projectName: codeProject.name,
        stages: updatedStages,
      },
    });
  };

  const onCreateDefaultPipelineHandler = () => {
    useCreateDefaultPipeline.mutate({
      workspaceId,
      pipelineId: selectedPipeline,
      requestBody: {
        projectName: codeProject.name,
        isRegexPipeline: isRegex,
      },
    });
  };

  const onDeletePipelineHandler = () =>
    setSelectedPipeline(
      codeProject.pipelines?.find(
        ({ pipelineId }) => pipelineId !== selectedPipeline,
      )?.pipelineId ?? '',
    );

  const onPromoteHandler = (
    stage: ProjectPipelineStageV1,
    nextStage: ProjectPipelineStageV1 | undefined,
  ) => {
    const commits = new Map();
    stage?.destinations.forEach((d) => {
      const {
        type: eventType,
        vmJobEvent,
        deploymentAppEvent,
        peImpactAnalysisEvent,
      } = d;
      let commit;

      switch (eventType) {
        case 'JOB':
          commit = {
            sha: vmJobEvent?.commitId ?? '',
            commitMessage: vmJobEvent?.commitMsg ?? '',
            branch: vmJobEvent?.branch ?? '',
            pullRequestId: vmJobEvent?.pullRequestId ?? '',
          };
          break;
        case 'DEPLOYMENT':
          commit = {
            sha: deploymentAppEvent?.commitId ?? '',
            commitMessage: deploymentAppEvent?.commitMsg ?? '',
            branch: deploymentAppEvent?.branch ?? '',
            pullRequestId: deploymentAppEvent?.pullRequestId ?? '',
          };
          break;
        case 'IMPACT_ANALYSIS':
          commit = {
            sha: peImpactAnalysisEvent?.commitId ?? '',
            commitMessage: peImpactAnalysisEvent?.commitMsg ?? '',
            branch: peImpactAnalysisEvent?.branch ?? '',
            pullRequestId: peImpactAnalysisEvent?.pullRequestId ?? '',
          };
          break;
        default:
          commit = {
            sha: '',
            commitMessage: '',
            branch: '',
            pullRequestId: '',
          };
          break;
      }

      if (!commits.has(commit.sha)) {
        commits.set(commit.sha, commit);
      }
    });

    const promoteStage = Array.from(commits.values())?.filter(
      (d) => d.sha !== '',
    );

    setPromoteStageProps({
      pipeline: promoteStage?.[0]?.branch ?? '',
      stageCommits: promoteStage,
      stageNumber: nextStage?.stageNum,
      stageName: nextStage?.stageName,
    });
  };

  const selectedPipelineMatch = pipelineOptions.some(
    ({ value }) => value === selectedPipeline,
  );

  const stageToDelete =
    data?.stages.find(({ stageNum }) => stageNum === deleteStageNum) ?? null;

  useEffect(() => {
    if (data?.isPipelinesAsCodeCandidate) {
      setIsPipelinesAsCodePromptOpen(true);
    }
  }, [data?.isPipelinesAsCodeCandidate]);

  const onCloseEditDeploymentDialog = () => {
    setOpenEditDeploymentDialog(undefined);
  };

  const onCloseEditImpactAnalysisDialog = () => {
    setOpenEditImpactAnalysisDialog(undefined);
  };

  const checkDestinationExists = (selectedDestination: DestinationSelector) => {
    const stageData = data?.stages[selectedDestination.stageIndex];
    if (stageData === undefined) {
      return false;
    }

    const destinationData =
      stageData.destinations?.[selectedDestination.destinationIndex];
    return destinationData !== undefined;
  };

  const onAddStageHandler = () => {
    setAddStageDialogType('AddStage');
  };

  return (
    <div className="pipeline-stages" data-testid="pipeline-stages">
      <div className="pipeline-stages__header-container">
        <div className="pipeline-stages__header">
          <Heading as="h4">{t('viewPipeline.stages.header')}</Heading>
          <Button
            type="text"
            size="small"
            onClick={() => {
              setOpenManagePipelineDialog(!openManagePipelineDialog);
            }}
            data-testid="stages-manage-pipelines-button"
          >
            {t('viewPipeline.stages.header.button.managePipelines')}
          </Button>
        </div>
        <div className="pipeline-stages__header">
          <div data-testid="pipeline-stages-name-select">
            <ButtonSelect
              loading={!selectedPipelineMatch}
              type="text"
              options={pipelineOptions}
              value={selectedPipelineMatch ? selectedPipeline : ''}
              onChange={(newPipeline: string) =>
                setSelectedPipeline(newPipeline)
              }
            />
          </div>
          <ConditionalRender
            enable={!isLoading && !data?.isPipelinesAsCodeEnabled}
          >
            <div>
              <Button
                type="secondary"
                size="small"
                data-testid="stages-add-pipeline-btn"
                onClick={() => {
                  setOpenAddPipelineDialog(!openAddPipelineDialog);
                }}
              >
                {t('viewPipeline.stages.header.button.addPipeline')}
              </Button>
              <Button
                type="secondary"
                icon="trash"
                onClick={() => {
                  setDeletePipelineOpen(!isDeletePipelineOpen);
                }}
                data-testid="stages-delete-pipeline-button"
              />
              <DeletePipeline
                isOpen={isDeletePipelineOpen}
                onToggleOpen={setDeletePipelineOpen}
                pipelineId={selectedPipeline}
                codeProjectName={codeProject.name}
                availablePipelines={codeProject.pipelines}
                stages={data?.stages}
                onDeletePipeline={onDeletePipelineHandler}
              />
              {data?.stages && isReorderPipelineOpen && (
                <ReorderStagesDialog
                  stages={data?.stages}
                  pipelineId={selectedPipeline}
                  projectName={codeProject.name}
                  onClose={() => setIsReorderPipelineOpen(false)}
                />
              )}
            </div>
          </ConditionalRender>
        </div>
      </div>
      <div className="pipeline-stages__content-container">
        <Cd4peError error={error} />
        <Cd4peError error={upsertPipelineStages.error} />
        <ConditionalRender enable={isLoading}>
          <Loading size="tiny" data-testid="pipeline-stages-loading" />
        </ConditionalRender>
        <ConditionalRender enable={!isLoading}>
          <ConditionalRender enable={!!triggers}>
            <div className="pipeline-stages__triggers stage-card__detail-value">
              <Trans t={t} i18nKey="viewPipeline.stages.pipelineTrigger">
                <Text size="small" />
                <Text size="small" color="medium" />
                {{
                  triggers,
                }}
              </Trans>
            </div>
          </ConditionalRender>
          <ConditionalRender enable={!data?.stages.length}>
            <DefaultPipelineCard
              onCreateDefaultPipeline={onCreateDefaultPipelineHandler}
              loadingDefaultPipeline={useCreateDefaultPipeline.isLoading}
              onAddStage={onAddStageHandler}
            />
          </ConditionalRender>
          <ConditionalRender enable={!!data?.stages.length}>
            {data?.stages?.map((stage, index, stages) => (
              <React.Fragment key={`${stage.stageNum}-${stage.stageName}`}>
                <StageCard
                  stage={stage}
                  manageAsCode={data.isPipelinesAsCodeEnabled}
                  onAddStageItem={(stageNumber) => {
                    setAddStageByNumber(stageNumber);
                    setAddStageDialogType('AddItem');
                  }}
                  onAddStageBefore={(stageNumber) => {
                    setAddStageByNumber(stageNumber);
                    setAddStageDialogType('BeforeStage');
                  }}
                  onAddStageAfter={(stageNumber) => {
                    setAddStageByNumber(stageNumber);
                    setAddStageDialogType('AfterStage');
                  }}
                  onRename={(stageName) =>
                    onRenameHandler(stageName, stage.stageNum)
                  }
                  onDeleteJob={(id) => onDeleteJobHandler(id, stage.stageNum)}
                  onDeleteStage={(stageNum: number) =>
                    setDeleteStageNum(stageNum)
                  }
                  onReorderPipeline={() => setIsReorderPipelineOpen(true)}
                  onDeletePlaceholder={() =>
                    onDeletePipelineGateHandler(stage.stageNum)
                  }
                  requiresAction={
                    hasIAConfigured && hasPlaceholderDeploymentStage
                      ? 'DEPLOYMENT_WARNING'
                      : undefined
                  }
                  getOnEditItemHandler={getOnEditItemHandler}
                  jobsNotToDelete={(id) => jobsNotToDelete(id)}
                  isRegex={isRegex}
                />
                <ConditionalRender enable={index < (stages.length ?? 0) - 1}>
                  <PromoteStage
                    stage={stage}
                    manageAsCode={data.isPipelinesAsCodeEnabled}
                    onAutoPromote={(triggerOn: boolean) =>
                      onAutoPromoteHandler(triggerOn, stage.stageNum)
                    }
                    onTriggerCondition={(triggerCondition) =>
                      onAutoPromoteConditionHandler(
                        triggerCondition,
                        stage.stageNum,
                      )
                    }
                    enablePromote={
                      stage.destinations.some((d) => {
                        switch (d.type) {
                          case 'JOB':
                            return !!d.vmJobEvent;
                          case 'DEPLOYMENT':
                            return !!d.deploymentAppEvent;
                          case 'IMPACT_ANALYSIS':
                            return !!d.peImpactAnalysisEvent;
                          default:
                            return false;
                        }
                      }) ?? false
                    }
                    onPromote={() => onPromoteHandler(stage, stages[index + 1])}
                  />
                </ConditionalRender>
              </React.Fragment>
            )) ?? []}
            <ConditionalRender enable={!data?.isPipelinesAsCodeEnabled}>
              <div className="pipeline-stages__add-stage-button">
                <Button
                  type="text"
                  icon="plus"
                  onClick={onAddStageHandler}
                  data-testid="add-stage-btn"
                >
                  {t('viewPipelines.add.stage.button')}
                </Button>
              </div>
            </ConditionalRender>
          </ConditionalRender>
          <ConditionalRender enable={openManagePipelineDialog}>
            <ManagePipelinesDialog
              onClose={setOpenManagePipelineDialog}
              pipelineId={selectedPipeline}
              setPipelineId={setSelectedPipeline}
              codeProject={codeProject}
              projectType={projectType}
            />
          </ConditionalRender>
          <ConditionalRender enable={addStageDialogType !== undefined}>
            <AddStageDialog
              type={addStageDialogType!}
              projectType={projectType}
              pipeline={data!}
              stageNumber={addStageByNumber}
              codeProject={codeProject}
              onClickAddToStage={(stageNumber) => {
                setAddStageByNumber(stageNumber);
                setAddStageDialogType('AddItem');
              }}
              onClickAddNextStage={(stageNumber) => {
                setAddStageByNumber(stageNumber);
                setAddStageDialogType('AfterStage');
              }}
              onClose={() => {
                setAddStageDialogType(undefined);
                setAddStageByNumber(undefined);
              }}
            />
          </ConditionalRender>
          <ConditionalRender enable={openAddPipelineDialog}>
            <AddPipelineDialog
              codeProject={codeProject}
              isOpen={openAddPipelineDialog}
              onClose={setOpenAddPipelineDialog}
              onAddPipeline={(newPipeline) => setSelectedPipeline(newPipeline)}
              projectType={projectType}
            />
          </ConditionalRender>
          <ConditionalRender enable={!!stageToDelete}>
            <DeleteStage
              stageNumber={stageToDelete?.stageNum!}
              name={stageToDelete?.stageName!}
              pipelineId={selectedPipeline}
              projectName={codeProject.name}
              stages={data?.stages!}
              onClose={() => setDeleteStageNum(null)}
            />
          </ConditionalRender>
          <ConditionalRender enable={!!promoteStageProps}>
            <TriggerPipelineDialog
              onClose={() => setPromoteStageProps(null)}
              codeProject={codeProject}
              {...promoteStageProps}
            />
          </ConditionalRender>
        </ConditionalRender>
      </div>
      {isPipelinesAsCodePromptOpen && data?.name && (
        <PipelinesAsCodePrompt
          projectName={codeProject.name}
          branch={data.name}
          pipelineId={selectedPipeline}
          onClose={() => setIsPipelinesAsCodePromptOpen(false)}
          projectType={projectType}
        />
      )}
      {openEditDeploymentDialog !== undefined &&
        checkDestinationExists(openEditDeploymentDialog) && (
          <EditDeploymentDialog
            codeProject={codeProject}
            onClose={onCloseEditDeploymentDialog}
            selectedDestination={openEditDeploymentDialog}
            stages={data?.stages ?? []}
            pipelineId={selectedPipeline}
            branch={data?.name ?? ''}
            projectType={projectType}
          />
        )}
      {openEditImpactAnalysisDialog !== undefined &&
        checkDestinationExists(openEditImpactAnalysisDialog) && (
          <EditImpactAnalysisDialog
            codeProject={codeProject}
            projectType={projectType}
            onClose={onCloseEditImpactAnalysisDialog}
            selectedDestination={openEditImpactAnalysisDialog}
            stages={data?.stages ?? []}
            pipelineId={selectedPipeline}
            branch={data?.name ?? ''}
          />
        )}
    </div>
  );
};

export default Stages;
