import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OptionsArray } from 'utils/en.propTypes';
import { Form } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import './NodeTableFilter.scss';

const NodeGroupFilter = ({
  onCancel,
  label,
  placeholder,
  options,
  onFormChange,
  onSubmit,
}) => {
  const { t } = useTranslation('inventory');

  const [filteredNodeGroupOptions, setFilteredNodeGroupOptions] = useState(
    options?.nodeGroup,
  );
  const [isSubmittable, setIsSubmittable] = useState(false);

  useEffect(() => {
    setFilteredNodeGroupOptions(options.nodeGroup);
  }, [options.nodeGroup]);

  const handleNodeGroupFilter = (searchTerm) => {
    const newFilteredNodeGroups = options.nodeGroup.filter(
      ({ label: nestedLabel }) => {
        return nestedLabel.toLowerCase().includes(searchTerm.toLowerCase());
      },
    );
    setFilteredNodeGroupOptions(newFilteredNodeGroups);
  };

  const canSubmit = (field, values) => {
    onFormChange(field, values);
    setIsSubmittable(!Object.values(values).some((x) => x === undefined));
  };

  return (
    <Form
      submittable
      cancellable
      actionsPosition="left"
      submitType="secondary"
      cancelType="text"
      submitDisabled={!isSubmittable}
      onChange={canSubmit}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitLabel={t('nodeTable.filter.add.button')}
    >
      <Form.Field
        type="select"
        name="peMasterServer"
        label={t(label.peMasterServer)}
        placeholder={t(placeholder.peMasterServer)}
        options={options.peMasterServer}
      />
      <Form.Field
        type="autocomplete"
        name="nodeGroup"
        label={t(label.nodeGroup)}
        placeholder={t(placeholder.nodeGroup)}
        options={filteredNodeGroupOptions}
        onFilter={handleNodeGroupFilter}
      />
    </Form>
  );
};

export default NodeGroupFilter;

NodeGroupFilter.propTypes = {
  label: PropTypes.shape({
    peMasterServer: PropTypes.string,
    nodeGroup: PropTypes.string,
  }).isRequired,

  placeholder: PropTypes.shape({
    peMasterServer: PropTypes.string,
    nodeGroup: PropTypes.string,
  }).isRequired,
  options: PropTypes.shape({
    peMasterServer: OptionsArray,
    nodeGroup: OptionsArray,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
};
