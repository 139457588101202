import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ApiError,
  Cd4peApiError,
  handleQueryRequest,
  MutationHook,
  QueryHookNoPayload,
  UserV1Service,
} from '@utils/api/cd4pe';
import {
  deleteUser,
  DeleteUserPayload,
  listUsersByPrefix,
  ListUsersByPrefixPayload,
  setSuperUser,
  SetSuperUserPayload,
  performSetUserPassword,
  SetUserPasswordPayload,
  SetUserPasswordResponse,
  signUp,
  SignUpPayload,
  SignUpResponse,
  AjaxError,
} from '@services/cd4pe/ajax';
import queryClient from '@services/queryClient';
import { User } from './ajaxTypes';

export const GET_USER_QUERY_TAG = 'getUserV1';
export const LIST_USERS_QUERY_TAG = 'listUsersByPrefix';

/**
 * useForgotPasswordV1
 */
type UseForgotPasswordV1 = MutationHook<
  typeof UserV1Service,
  'forgotPasswordV1',
  Cd4peApiError
>;

export const useForgotPasswordV1: UseForgotPasswordV1 = () => {
  return useMutation((payload) =>
    handleQueryRequest(UserV1Service.forgotPasswordV1(payload)),
  );
};

/**
 * useResetPasswordV1
 */
type UseResetPasswordV1 = MutationHook<
  typeof UserV1Service,
  'resetPasswordV1',
  Cd4peApiError
>;

export const useResetPasswordV1: UseResetPasswordV1 = () => {
  return useMutation((payload) =>
    handleQueryRequest(UserV1Service.resetPasswordV1(payload)),
  );
};

/**
 * useGetUserV1
 */
type UseGetUserV1 = QueryHookNoPayload<
  typeof UserV1Service,
  'getUserV1',
  Cd4peApiError
>;

export const useGetUserV1: UseGetUserV1 = () => {
  return useQuery([GET_USER_QUERY_TAG], () =>
    handleQueryRequest(UserV1Service.getUserV1()),
  );
};

export const useSignUpV1 = () =>
  useMutation<SignUpResponse, AjaxError, SignUpPayload>(signUp);

/**
 * useUpdateUserV1
 */
type UseUpdateUserV1 = MutationHook<
  typeof UserV1Service,
  'updateUserV1',
  Cd4peApiError
>;

export const useUpdateUserV1: UseUpdateUserV1 = () => {
  return useMutation(
    (payload) => handleQueryRequest(UserV1Service.updateUserV1(payload)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USER_QUERY_TAG]);
      },
    },
  );
};

/**
 * useSetUserPassword
 */

export const useSetUserPassword = () =>
  useMutation<SetUserPasswordResponse, AjaxError, SetUserPasswordPayload>(
    performSetUserPassword,
  );

export const useListUsersByPrefix = (
  loggedInUsername: string,
  payload: ListUsersByPrefixPayload,
) => {
  return useQuery<User[], ApiError>(
    [LIST_USERS_QUERY_TAG, payload.prefix, payload.scope],
    {
      queryFn: () =>
        handleQueryRequest(listUsersByPrefix(payload, loggedInUsername)),
    },
  );
};

export const useDeleteUser = (loggedInUsername: string) => {
  return useMutation<undefined, ApiError, DeleteUserPayload>(
    (payload) => handleQueryRequest(deleteUser(payload, loggedInUsername)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([LIST_USERS_QUERY_TAG]);
      },
    },
  );
};

export const useSetSuperUser = (loggedInUsername: string) => {
  return useMutation<undefined, ApiError, SetSuperUserPayload>(
    (payload) => handleQueryRequest(setSuperUser(loggedInUsername, payload)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([LIST_USERS_QUERY_TAG]);
      },
    },
  );
};
