import { generatePath } from 'react-router-dom';

export const ROUTES = {
  cd4pe: {
    base: 'cd4pe',
    ssoLogout: 'sso-logout',
    logout: 'logout',
    samlStatus: 'saml-status',
    root: {
      base: 'root',
      settings: {
        base: 'settings',
        puppetEnterprise: 'puppet-enterprise',
      },
      workspaces: 'workspaces',
      jobHardware: 'job-hardware',
      jobHardwareCapability: 'job-hardware-capability',
    },
    workspace: {
      base: ':workspace',
      repositories: {
        base: 'repositories',
        name: ':name',
      },
      modules: {
        base: 'modules',
        name: ':name',
      },
      deployments: {
        base: 'deployments',
        id: ':id',
      },
      moduleDeployments: {
        base: 'module-deployments',
        id: ':id',
      },
      analysis: {
        base: 'analysis',
        id: {
          base: ':id',
          environmentResultId: ':environmentResultId',
        },
      },
    },
    username: {
      base: ':username',
      profile: 'profile',
      messages: 'messages',
      manageWorkspaces: 'manage-workspaces',
    },
  },
  workspace: {
    base: ':workspace',
    codeDelivery: {
      base: 'code-delivery',
      jobs: {
        base: 'jobs',
        jobInstanceId: {
          base: ':jobInstanceId',
        },
        templates: {
          base: 'templates',
          new: 'new',
          templateId: {
            base: ':templateId',
            edit: 'edit',
          },
        },
      },
      modules: {
        base: 'modules',
        new: 'new',
        name: ':name',
      },
      repositories: {
        base: 'repositories',
        new: 'new',
        name: ':name',
      },
      jobHardware: {
        base: 'job-hardware',
        new: 'new',
        capabilityId: {
          base: ':capabilityId',
        },
      },
    },
    settings: {
      base: 'settings',
      workspaces: 'workspaces',
      workspace: 'workspace',
      groups: {
        base: 'groups',
        add: 'add',
        groupId: {
          base: ':groupId',
          addUsers: 'add-users',
        },
      },
      users: {
        base: 'users',
        add: 'add',
      },
      ssh: 'ssh',
      puppetEnterprise: {
        base: 'puppet-enterprise',
        new: 'new',
        peId: {
          base: ':peId',
          environments: {
            base: 'environments',
            envName: ':envName',
          },
          regenerate: 'regenerate',
        },
      },
      sourceControl: {
        base: 'source-control',
        new: 'new',
      },
    },
    inventory: {
      base: 'inventory',
      nodes: {
        base: 'nodes',
        savedViews: 'saved-views',
        view: ':view',
        source: {
          base: ':source',
          id: ':id',
        },
      },
    },
    activity: {
      base: 'activity',
    },
  },
  oauth: {
    base: 'oauth',
    github: 'github',
    bitbucket: 'bitbucket',
  },
  oauth2: {
    base: 'oauth2',
    azureDevOps: 'azureDevOps',
  },
  root: {
    base: 'root',
    login: 'login',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
  },
  username: {
    base: ':username',
    chooseWorkspace: 'choose-workspace',
    profile: 'profile',
  },
  login: 'login',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  signup: 'signup',
  forbidden: '403',
  notFound: '404',
};

type ConcatStrings<A, B> = `${string & A}.${string & B}`;

type OrElseConcat<Value, IgnoreKeys, NextValue> = NextValue extends IgnoreKeys
  ? Value
  : ConcatStrings<Value, NextValue>;

type ObjectPath<
  T extends Record<string, {}>,
  ObjectValues = string | boolean | number | null,
  IgnoreKeys extends string | never = never,
> = keyof {
  [Property in keyof T as T[Property] extends ObjectValues
    ? Property
    : OrElseConcat<
        Property,
        IgnoreKeys,
        ObjectPath<T[Property], ObjectValues, IgnoreKeys>
      >]: never;
};

type RoutePath = ObjectPath<typeof ROUTES, string, 'base'>;

interface RoutesShape {
  base: string;
  [key: string]: string | RoutesShape;
}

type Routes = Record<string, RoutesShape>;

export const getSegment = (routePath: RoutePath) => {
  const segments = routePath.split('.');

  const getValue = (path: string[]) =>
    path.reduce((value, key) => {
      if (typeof value === 'string') {
        return value;
      }

      return value[key];
    }, ROUTES as unknown as Routes[string] | Routes[string][string]);

  const value = getValue(segments);

  if (typeof value === 'string') {
    return value;
  }

  return value.base;
};

const getRoute = (routePath: RoutePath) => {
  const segments = routePath.split('.');

  return segments.reduce(
    ({ objPath, path }, key) => {
      const newPath = (objPath ? `${objPath}.${key}` : key) as RoutePath;

      return {
        objPath: newPath,
        path: `${path}/${getSegment(newPath)}`,
      };
    },
    { objPath: '', path: '' },
  ).path;
};

type ParamType =
  | string
  | Record<string, string | undefined | number | boolean>
  | null;

type AsRecord<K extends NonNullable<ParamType>> = K extends string
  ? Record<K, string>
  : K;

type RequiredOrOptional<
  Key extends string,
  Values extends ParamType,
> = Values extends null
  ? Partial<Record<Key, AsRecord<NonNullable<Values>>>>
  : Record<Key, AsRecord<NonNullable<Values>>>;

type LinkGeneratorArgs<
  PathParams extends ParamType,
  QueryParams extends ParamType,
> = RequiredOrOptional<'path', PathParams> &
  RequiredOrOptional<'query', QueryParams>;

type ParamOptions = undefined | string | number | boolean;

type QueryParamMap<T = ParamOptions> =
  | Record<string, T>
  | Record<string, Exclude<ParamOptions, undefined | null>>;

const getQueryParamsString = (queryParams: QueryParamMap) => {
  const encodedParams: string[] = Object.entries(queryParams)
    .filter(([value]) => value !== null && value !== undefined)
    .map(
      ([param, value]) =>
        `${encodeURIComponent(param)}=${encodeURIComponent(`${value}`)}`,
    );

  if (encodedParams.length <= 0) {
    return '';
  }

  return `?${encodedParams.join('&')}`;
};

const getLinkFn = <T extends ParamType, K extends ParamType = null>(
  routePath: RoutePath,
): ((linkArgs: LinkGeneratorArgs<T, K>) => string) => {
  const path = getRoute(routePath);
  return ({ path: pathParams, query }) => {
    return !query
      ? generatePath(path, pathParams || {})
      : `${generatePath(path, pathParams)}${getQueryParamsString(query)}`;
  };
};

export const LINKS = {
  cd4pe: {
    ssoLogout: getRoute('cd4pe.ssoLogout'),
    logout: getRoute('cd4pe.logout'),
    samlStatus: getRoute('cd4pe.samlStatus'),
    root: getRoute('cd4pe.root'),
    rootSettings: getRoute('cd4pe.root.settings'),
    rootPuppetEnterprise: getRoute('cd4pe.root.settings.puppetEnterprise'),
    rootWorkspaces: getRoute('cd4pe.root.workspaces'),
    rootJobHardware: getRoute('cd4pe.root.jobHardware'),
    workspace: getLinkFn<'workspace'>('cd4pe.workspace'),
    manageWorkspaces: getLinkFn<'username'>('cd4pe.username.manageWorkspaces'),
    profile: getLinkFn<'username'>('cd4pe.username.profile'),
    messages: getLinkFn<'username'>('cd4pe.username.messages'),
    viewDeployment: getLinkFn<{ workspace: string; id: number }>(
      'cd4pe.workspace.deployments.id',
    ),
    viewImpactAnalysis: getLinkFn<{ workspace: string; id: number }>(
      'cd4pe.workspace.analysis.id',
    ),
    viewImpactAnalysisEnvironmentResult: getLinkFn<{
      workspace: string;
      id: number;
      environmentResultId: number;
    }>('cd4pe.workspace.analysis.id.environmentResultId'),
  },
  workspace: getLinkFn<'workspace'>('workspace'),
  login: getLinkFn<null, 'url' | null>('login'),
  forgotPassword: getRoute('forgotPassword'),
  resetPassword: getRoute('resetPassword'),
  signup: getRoute('signup'),
  forbidden: getLinkFn<null, { msg: string } | null>('forbidden'),
  notFound: getRoute('notFound'),
  username: {
    chooseWorkspace: getLinkFn<'username'>('username.chooseWorkspace'),
    profile: getLinkFn<'username'>('username.profile'),
  },
  root: {
    root: getRoute('root'),
    login: getLinkFn<null, 'url' | null>('root.login'),
  },
  codeDelivery: {
    root: getLinkFn<'workspace'>('workspace.codeDelivery'),
    jobs: getLinkFn<'workspace'>('workspace.codeDelivery.jobs'),
    jobDetails: getLinkFn<{ workspace: string; jobInstanceId: number }>(
      'workspace.codeDelivery.jobs.jobInstanceId',
    ),
    listJobTemplates: getLinkFn<'workspace'>(
      'workspace.codeDelivery.jobs.templates',
    ),
    newJobTemplate: getLinkFn<'workspace'>(
      'workspace.codeDelivery.jobs.templates.new',
    ),
    editJobTemplate: getLinkFn<{ workspace: string; templateId: number }>(
      'workspace.codeDelivery.jobs.templates.templateId.edit',
    ),
    listModules: getLinkFn<'workspace'>('workspace.codeDelivery.modules'),
    newModule: getLinkFn<'workspace'>('workspace.codeDelivery.modules.new'),
    listRepositories: getLinkFn<'workspace'>(
      'workspace.codeDelivery.repositories',
    ),
    newRepository: getLinkFn<'workspace'>(
      'workspace.codeDelivery.repositories.new',
    ),
    jobHardware: getLinkFn<'workspace'>('workspace.codeDelivery.jobHardware'),
    newCapability: getLinkFn<'workspace'>(
      'workspace.codeDelivery.jobHardware.new',
    ),
    editCapability: getLinkFn<{
      workspace: string;
      capabilityId: number;
    }>('workspace.codeDelivery.jobHardware.capabilityId'),
    viewRepository: getLinkFn<
      'workspace' | 'name',
      { pipelineId: string; eventId: string } | null
    >('workspace.codeDelivery.repositories.name'),
    viewModule: getLinkFn<
      'workspace' | 'name',
      { pipelineId: string; eventId: string } | null
    >('workspace.codeDelivery.modules.name'),
  },
  settings: {
    root: getLinkFn<'workspace'>('workspace.settings'),
    workspace: getLinkFn<'workspace'>('workspace.settings.workspace'),
    listGroups: getLinkFn<'workspace', { newGroupName: string } | null>(
      'workspace.settings.groups',
    ),
    addGroup: getLinkFn<
      'workspace',
      {
        groupId: string;
        groupName: string;
        stepperIndex: number;
      } | null
    >('workspace.settings.groups.add'),
    viewGroup: getLinkFn<'workspace' | 'groupId'>(
      'workspace.settings.groups.groupId',
    ),
    addGroupUsers: getLinkFn<'workspace' | 'groupId'>(
      'workspace.settings.groups.groupId.addUsers',
    ),
    ssh: getLinkFn<'workspace'>('workspace.settings.ssh'),
    listUsers: getLinkFn<'workspace'>('workspace.settings.users'),
    addUser: getLinkFn<'workspace'>('workspace.settings.users.add'),
    listPuppetEnterprise: getLinkFn<'workspace'>(
      'workspace.settings.puppetEnterprise',
    ),
    newPuppetEnterprise: getLinkFn<'workspace'>(
      'workspace.settings.puppetEnterprise.new',
    ),
    editPuppetEnterprise: getLinkFn<'workspace' | 'peId'>(
      'workspace.settings.puppetEnterprise.peId',
    ),
    addProtectedEnvironment: getLinkFn<'workspace' | 'peId'>(
      'workspace.settings.puppetEnterprise.peId.environments',
    ),
    editProtectedEnvironment: getLinkFn<'workspace' | 'peId' | 'envName'>(
      'workspace.settings.puppetEnterprise.peId.environments.envName',
    ),
    regeneratePuppetEnterpriseToken: getLinkFn<'workspace' | 'peId'>(
      'workspace.settings.puppetEnterprise.peId.regenerate',
    ),
    listSourceControl: getLinkFn<'workspace'>(
      'workspace.settings.sourceControl',
    ),
    newSourceControl: getLinkFn<'workspace'>(
      'workspace.settings.sourceControl.new',
    ),
  },
  inventory: {
    root: getLinkFn<'workspace'>('workspace.inventory'),
    view: getLinkFn<'workspace' | 'view'>('workspace.inventory'),
    listNodes: getLinkFn<'workspace'>('workspace.inventory.nodes'),
    savedViews: getLinkFn<'workspace'>('workspace.inventory.nodes.savedViews'),
    source: getLinkFn<'workspace'>('workspace.inventory.nodes.source'),
    viewSource: getLinkFn<'workspace' | 'source' | 'id'>(
      'workspace.inventory.nodes.source.id',
    ),
  },
  activity: {
    root: getLinkFn<'workspace'>('workspace.activity'),
  },
};
