import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  Heading,
  ActionSelect,
  Link as PdsLink,
} from '@puppet/react-components';
import { Columns } from '@puppet/react-layouts';
import { CodeProjectDetailsV1, ProjectTypeV1 } from '@puppet/cd4pe-client-ts';
import Breadcrumbs, {
  BreadcrumbsType,
} from '@components/Breadcrumbs/Breadcrumbs';
import useVcsLinks from '@hooks/useVcsLinks';
import PageLayout from '@components/PageLayout';
import ImpactAnalysisDialog from '@codeDelivery/components/ViewPipeline/components/ImpactAnalysisDialog/ImpactAnalysisDialog';
import { useGetPipelineAsCodeErrorV1 } from '@services/cd4pe/pipelinesAsCode';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import ConditionalRender from '@components/ConditionalRender';
import EventsTable from './components/EventsTable';
import Stages from './components/Stages';
import TriggerPipelineDialog from './components/TriggerPipelineDialog';
import DeploymentDialog from './components/DeploymentDialog/DeploymentDialog';

export type ProjectType = 'module' | 'controlRepo';

type ViewPipelineProps = {
  breadcrumbs: BreadcrumbsType;
  name: string;
  codeProject: CodeProjectDetailsV1;
  projectType: ProjectTypeV1;
};

const ViewPipeline = ({
  breadcrumbs,
  name,
  codeProject,
  projectType,
}: ViewPipelineProps) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const { getRepoLink } = useVcsLinks();
  const [isOpenTriggerPipelineDialog, setOpenTriggerPipelineDialog] =
    useState(false);
  const [isOpenDeploymentDialog, setOpenDeploymentDialog] = useState(false);
  const [isOpenImpactAnalysisDialog, setOpenImpactAnalysisDialog] =
    useState(false);

  const repoLink = getRepoLink(
    codeProject.srcRepoProvider,
    codeProject.srcRepoOwner,
    codeProject.srcRepoDisplayName,
    codeProject.srcRepoId,
  );

  const pipelinesAsCodeError = useGetPipelineAsCodeErrorV1(
    {
      workspaceId,
      pipelineId: codeProject?.pipelines[0]?.pipelineId!!,
      projectName: codeProject.name,
    },
    {
      enabled: codeProject.pipelines[0].pipelineId !== '',
    },
  );

  const actions = [
    {
      id: 'newImpactAnalysis',
      label: t('viewPipeline.header.actions.newImpactAnalysis'),
      onClick: () => {
        setOpenImpactAnalysisDialog(true);
      },
      'data-testid': 'new-impact-analysis',
    },
    {
      id: 'newDeployment',
      label: t('viewPipeline.header.actions.newDeployment'),
      onClick: () => {
        setOpenDeploymentDialog(true);
      },
      'data-testid': 'new-deployment',
    },
    {
      id: 'triggerPipeline',
      label: t('viewPipeline.header.actions.triggerPipeline'),
      onClick: () => {
        setOpenTriggerPipelineDialog(true);
      },
      'data-testid': 'trigger-pipeline',
    },
  ];

  return (
    <PageLayout className="view-pipeline">
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <div
          className="view-pipeline__header"
          data-testid="view-pipeline-header"
        >
          <div>
            <Heading>{codeProject.name}</Heading>
            <Trans
              t={t}
              i18nKey="viewPipeline.header.connection"
              values={{
                providerName: t(
                  `repoProvider.${codeProject.srcRepoProvider.toLowerCase()}`,
                ),
                repoName: `${codeProject.srcRepoOwnerDisplayName}/${codeProject.srcRepoDisplayName}`,
              }}
              components={[<PdsLink as="a" href={repoLink} target="_blank" />]}
            />
          </div>
          <div data-testid="view-pipeline-actions">
            <ActionSelect
              label={t('viewPipeline.header.actions')}
              actions={actions}
              anchor="bottom right"
            >
              {t('listControlRepos.header.newControlRepo')}
            </ActionSelect>
          </div>
        </div>
        <ConditionalRender
          enable={
            pipelinesAsCodeError?.data?.pipelineAsCodeError !== '' &&
            codeProject.pacEnabled!!
          }
        >
          <div className="view-pipeline__pac-error">
            <Alert type="danger" data-testid="pacError">
              {pipelinesAsCodeError?.data?.pipelineAsCodeError}
            </Alert>
          </div>
        </ConditionalRender>
      </PageLayout.Header>
      <PageLayout.Content className="view-pipeline__content">
        <Columns>
          <Columns.Column className="view-pipeline__events">
            <EventsTable
              name={name}
              codeProject={codeProject}
              projectType={projectType}
            />
          </Columns.Column>
          <Columns.Column fixed className="view-pipeline__stages">
            <Stages
              key={codeProject.pipelines[0].pipelineId ?? 0}
              codeProject={codeProject}
              projectType={projectType}
            />
          </Columns.Column>
        </Columns>
        {isOpenTriggerPipelineDialog && (
          <TriggerPipelineDialog
            onClose={() => setOpenTriggerPipelineDialog(false)}
            codeProject={codeProject}
          />
        )}
        {isOpenDeploymentDialog && (
          <DeploymentDialog
            codeProject={codeProject}
            onClose={() => setOpenDeploymentDialog(false)}
            projectType={projectType}
          />
        )}
        {isOpenImpactAnalysisDialog && (
          <ImpactAnalysisDialog
            codeProject={codeProject}
            projectType={projectType}
            onClose={() => setOpenImpactAnalysisDialog(false)}
          />
        )}
      </PageLayout.Content>
    </PageLayout>
  );
};

export default ViewPipeline;
