import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert, Link, Loading } from '@puppet/react-components';
import { useTranslation, Trans } from 'react-i18next';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { UseListPeIntegrationsV2Result } from '@services/cd4pe/peIntegrations';
import { LINKS } from 'src/routes';

const PeIntegrationSelect = ({
  peServers,
  children,
}: PropsWithChildren<{
  peServers: UseListPeIntegrationsV2Result;
}>) => {
  const workspaceName = useWorkspaceName();
  const { t } = useTranslation('codeDelivery');

  if (peServers.isLoading) {
    return (
      <div>
        <Loading size="tiny" data-testid="pe-servers-loading-spinner" />
      </div>
    );
  }

  if (peServers?.data?.peIntegrations?.length === 0) {
    return (
      <Alert type="info">
        <Trans
          t={t}
          i18nKey="jobHardwareCapability.section.nodes.fields.pe.noPE"
          components={[
            <Link
              as={NavLink}
              to={LINKS.settings.listPuppetEnterprise({
                path: { workspace: workspaceName },
              })}
            />,
          ]}
        />
      </Alert>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default PeIntegrationSelect;
