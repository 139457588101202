import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConditionalRender from '@components/ConditionalRender';
import LandingPage from '@components/LandingPage';
import { Heading, Link as PdsLink, Button } from '@puppet/react-components';
import session from '@state/session/session';
import { useAppDispatch } from '@hooks/redux';
import { useSignOut } from '@services/cd4pe/auth';
import { LINKS } from 'src/routes';
import ChooseWorkspaceForm from './components/ChooseWorkspaceForm';
import ChooseWorkspaceInfo from './components/ChooseWorkspaceInfo';

const ChooseWorkspace = () => {
  const { t } = useTranslation('codeDelivery');
  const [view, setView] = useState<'choose' | 'new'>('choose');
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const handleSignOut = async () => {
    const signedOut = await signOut.mutateAsync();

    if (signedOut) {
      appDispatch(session.actions.resetSession());
      navigate(LINKS.login({}));
    }
  };

  const header = () => {
    return (
      <div className="chooseWorkspace-header__title">
        <ConditionalRender enable={view === 'choose'}>
          <Heading as="h3">{t('chooseWorkspace.choose.header')}</Heading>
        </ConditionalRender>
        <ConditionalRender enable={view === 'new'}>
          <Button
            type="text"
            icon="chevron-left"
            onClick={() => setView('choose')}
          >
            {t('chooseWorkspace.new.backToChoose')}
          </Button>
        </ConditionalRender>
      </div>
    );
  };

  const footer = () => (
    <div className="chooseWorkspace__footer">
      <ConditionalRender enable={view === 'choose'}>
        <Button
          type="secondary"
          icon="plus"
          onClick={() => setView('new')}
          data-testid="add-new-workspace-button"
        >
          {t('chooseWorkspace.choose.addNewWorkspace')}
        </Button>
      </ConditionalRender>
      <PdsLink
        aria-label={t('chooseWorkspace.signout.aria')}
        as={Button}
        onClick={handleSignOut}
      >
        {t('chooseWorkspace.signout')}
      </PdsLink>
    </div>
  );

  return (
    <LandingPage className="chooseWorkspace__container">
      {header()}
      <ConditionalRender enable={view === 'choose'}>
        <ChooseWorkspaceInfo />
      </ConditionalRender>
      <ConditionalRender enable={view === 'new'}>
        <ChooseWorkspaceForm />
      </ConditionalRender>
      {footer()}
    </LandingPage>
  );
};

export default ChooseWorkspace;
