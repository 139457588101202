import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Icon, Text, Heading } from '@puppet/react-components';

const NoPeIntegrations = () => {
  const { t } = useTranslation('codeDelivery');
  return (
    <div className="pe-list-body-no-pe">
      <Icon type="dag" size="large" />
      <Heading as="h4" color="medium">
        {t('peList.emptyList.addNewPe.icon.label')}
      </Heading>
      <Text weight="bold" color="medium">
        <a
          href="https://puppet.com/docs/continuous-delivery/latest/integrate_with_puppet_enterprise.html"
          aria-label="peList.emptyList.addNewPe.link"
          target="_blank"
          rel="noreferrer"
        >
          <Trans t={t} i18nKey="peList.emptyList.addNewPe.link" />
        </a>
      </Text>
    </div>
  );
};

export default NoPeIntegrations;
