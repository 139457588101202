import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Sidebar from '@components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  isInitialized,
  selectUser,
  selectWorkspaceName,
  selectWorkspaces,
} from '@state/session/selectors';
import { useTranslation } from 'react-i18next';
import getDefaultRoute from '@utils/getDefaultRoute';
import session from '@state/session/session';
import { LINKS } from 'src/routes';
import { useSignOut } from '@services/cd4pe/auth';

const ROOT_USERNAME = 'root';

const LeftNav = ({ visible, version }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceName = useSelector((state) => selectWorkspaceName(state));
  const initialized = useSelector((state) => isInitialized(state));
  const workspaces = useSelector((state) => selectWorkspaces(state));
  const { userName, superUser } = useSelector((state) => selectUser(state));
  const { t } = useTranslation();
  const signOut = useSignOut();

  if (!initialized || !visible) {
    return null;
  }
  const handleSelectWorkspace = (workspace, rootConsolePage) => {
    try {
      if (rootConsolePage) {
        // TODO: use `history.push` once root and workspace pages are natively in teams UI
        window.location.href = LINKS.codeDelivery.listRepositories({
          path: { workspace },
        });
      } else {
        const newUrl = getDefaultRoute({
          workspaceName: workspace,
          userName,
        });
        window.location.href = newUrl; // This is a temp work around to reload the same cd4pe web component with a new workspace.
      }
    } catch (e) {
      log.error(e);
      navigate('/');
    }
  };

  const handleManageWorkspaces = () => {
    try {
      navigate(LINKS.cd4pe.manageWorkspaces({ path: { username: userName } }));
    } catch (e) {
      log.error(e);
    }
  };

  const handleRootConsole = () => {
    try {
      // TODO: use `history.push` once root and workspace pages are natively in teams UI
      window.location.href = LINKS.cd4pe.root;
    } catch (e) {
      log.error(e);
    }
  };

  const handleSignOut = async () => {
    const signedOut = await signOut.mutateAsync();
    if (signedOut) {
      dispatch(session.actions.resetSession());
      navigate(LINKS.login({}));
    }
  };

  // Note: the exact check CD4PE use is window.location.pathname.contains('root')
  // we're avoiding this because any other apps including that word in a url would break this

  const rootConsolePage =
    window.location.pathname.startsWith(LINKS.cd4pe.root) ||
    window.location.pathname.startsWith(LINKS.root.root);

  const workspaceActions = [];
  if (userName !== ROOT_USERNAME) {
    workspaceActions.push({
      id: 'manageWorkspaces',
      label: t('leftnav.workspaces.options.manage'),
      onClick: () => handleManageWorkspaces(),
    });
  }
  if (superUser && !(rootConsolePage && userName === ROOT_USERNAME)) {
    workspaceActions.push({
      id: 'rootConsole',
      label: t('leftnav.workspaces.options.root'),
      onClick: () => handleRootConsole(),
    });
  }
  if (userName) {
    workspaceActions.push({
      id: userName,
      label: userName,
      onClick: () =>
        navigate(LINKS.username.profile({ path: { username: userName } })),
    });
  }

  workspaceActions.push(
    ...workspaces.map((w) => ({
      id: w.name,
      label: w.name,
      onClick: () => handleSelectWorkspace(w.name, rootConsolePage),
    })),
  );

  return (
    <Sidebar
      workspaceName={workspaceName}
      userName={userName}
      version={version}
      workspaceActions={workspaceActions}
      handleSignOut={handleSignOut}
      rootConsolePage={rootConsolePage}
    />
  );
};

LeftNav.propTypes = {
  version: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

LeftNav.defaultProps = {
  visible: true,
};

export default LeftNav;
