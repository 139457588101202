import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSelect, Text } from '@puppet/react-components';
import ConditionalRender from 'inventory/components/ConditionalRender';
import FilterGroup from 'inventory/components/FilterGroup';
import { FILTER_UNION_OPTIONS, COMPOUND_FILTER_AND } from 'utils/constants';
import LoadingBar from '../../../components/LoadingBar';
import './FilterBar.scss';

const FilterBar = ({
  onAdd,
  updateFilters,
  filters,
  savedFilters,
  onApplyAll,
  loading,
  appendFilter,
  globalFilterUnion,
  setGlobalFilterUnion,
  cancelFilters,
  submittedCompoundFilters,
  parentRef,
  setInfoVisible,
  isModalOpen,
}) => {
  const [isEditing, setIsEditing] = useState();
  const [showEmpty, setShowIsEmpty] = useState(false);
  const [localUnion, setLocalUnion] = useState(globalFilterUnion);

  useEffect(() => {
    if (!isModalOpen && isEditing && filters.length === 0 && !showEmpty) {
      setIsEditing(false);
    }
  }, [filters.length, isEditing, isModalOpen, showEmpty]);

  const updateGroupFilters = (filter) => {
    updateFilters(filter, filters);
    setShowIsEmpty(true);
  };
  const updateOnAppend = (filter) => {
    appendFilter(filter, filters);
    setShowIsEmpty(true);
  };

  const isAltered = (filterGroup) => {
    const currentFilterGroup = submittedCompoundFilters.filter((option) => {
      return option.id === filterGroup.id;
    });

    return !(filterGroup === currentFilterGroup[0]);
  };

  // setting  is editing to true to hide createFilter button
  const onAddFirstFilter = () => {
    setIsEditing(true);
    onAdd();
  };
  const getSavedFilter = (id) => {
    // This is to handle the old node filter saved views
    if (!Array.isArray(savedFilters)) {
      return {};
    }
    const filter = savedFilters.filter((savedFilter) => {
      return savedFilter.id === id;
    });
    return filter[0] || {};
  };

  const { t } = useTranslation('inventory');
  return (
    <>
      <div className="filter-bar-container-ui">
        <ConditionalRender enable={filters.length === 0 && !showEmpty}>
          <div className="filter-bar-empty-message">
            <Text>{t('compoundFiltering.filterBar.message.noFilters')}</Text>
          </div>
        </ConditionalRender>
        <ConditionalRender enable={filters.length > 0}>
          <div className="filter-bar-filter-groups">
            {filters.map((filter, index) => {
              const keyId = `${filter.id}_${index}`;
              return (
                <div className="filter-bar-filter-criteria" key={keyId}>
                  <ConditionalRender enable={index !== 0}>
                    <ConditionalRender enable={isEditing}>
                      <ButtonSelect
                        // Figure out how to identify this
                        data-testid={`filter-bar-filter-groups-union-${localUnion}-${index}`}
                        type="text"
                        className={classnames({
                          'filter-bar-filter-groups-union': true,
                          'filter-bar-filter-groups-union-editing': isEditing,
                        })}
                        options={FILTER_UNION_OPTIONS}
                        placeholder={localUnion}
                        value={localUnion}
                        onChange={(newUnion) => {
                          setLocalUnion(newUnion);
                        }}
                      />
                    </ConditionalRender>
                    <ConditionalRender enable={!isEditing}>
                      <Text className="filter-bar-filter-groups-union">
                        {globalFilterUnion}
                      </Text>
                    </ConditionalRender>
                  </ConditionalRender>
                  <FilterGroup
                    filter={filter}
                    savedFilter={getSavedFilter(filter.id)}
                    id={filter.id}
                    isAltered={isAltered(filter)}
                    union={filter.union}
                    isEditing={isEditing}
                    filters={filter.filters}
                    updateFilters={updateGroupFilters}
                    onAppendFilter={updateOnAppend}
                    groupIndex={index}
                  />
                </div>
              );
            })}

            <ConditionalRender enable={isEditing && filters.length > 0}>
              <div className="filter-bar-add-filter-group">
                <Button
                  type="text"
                  iconSize="large"
                  icon="plus-circle"
                  onClick={onAdd}
                >
                  {t('compoundFiltering.filterBar.button.newFilter')}
                </Button>
              </div>
            </ConditionalRender>
            <ConditionalRender enable={!isEditing}>
              <div className="filter-bar-filter-action-button-edit">
                <Button
                  type="secondary"
                  onClick={() => {
                    setIsEditing(!isEditing);
                  }}
                >
                  {t('compoundFiltering.filterBar.button.edit')}
                </Button>
              </div>
            </ConditionalRender>
          </div>
        </ConditionalRender>
        <ConditionalRender
          enable={isEditing && filters.length === 0 && showEmpty}
        >
          <div className="filter-bar-empty">
            <div className="filter-bar-add-filter-group">
              <Button
                type="text"
                icon="plus-circle"
                iconSize="large"
                onClick={onAdd}
              >
                {t('compoundFiltering.filterBar.button.newFilter')}
              </Button>
            </div>
            <Text>{t('compoundFiltering.filterBar.message.empty')}</Text>
          </div>
        </ConditionalRender>
        <div className="filter-bar-filter-actions-right">
          <div className="filter-bar-filter-actions-right-create-actions">
            <ConditionalRender enable={!isEditing}>
              <Button
                size="small"
                ref={parentRef}
                icon="info-circle"
                type="transparent"
                onClick={() => setInfoVisible(true)}
              />
            </ConditionalRender>
            <ConditionalRender enable={!isEditing && filters.length === 0}>
              <Button
                type="secondary"
                className="filter-bar-create-button"
                onClick={onAddFirstFilter}
                data-testid="filter-bar-create-button"
              >
                {t('compoundFiltering.filterBar.button.create')}
              </Button>
            </ConditionalRender>
          </div>
          <div className="filter-bar-filter-actions-right-apply-actions">
            <ConditionalRender
              enable={
                isEditing &&
                (filters.length > 0 || (showEmpty && filters.length === 0))
              }
            >
              <div className="filter-bar-filter-action-buttons">
                <Button
                  type="primary"
                  onClick={() => {
                    setIsEditing(!isEditing);
                    setShowIsEmpty(false);
                    if (filters.length === 0) {
                      setLocalUnion(COMPOUND_FILTER_AND);
                      setGlobalFilterUnion(COMPOUND_FILTER_AND);
                    } else {
                      setGlobalFilterUnion(localUnion);
                    }
                    onApplyAll();
                  }}
                  data-testid="filter-bar-apply-button"
                >
                  {t('compoundFiltering.filterBar.button.apply')}
                </Button>
                <Button
                  type="secondary"
                  onClick={() => {
                    setIsEditing(!isEditing);
                    setShowIsEmpty(false);
                    setLocalUnion(globalFilterUnion);
                    cancelFilters();
                  }}
                >
                  {t('compoundFiltering.filterBar.button.cancel')}
                </Button>
              </div>
            </ConditionalRender>
          </div>
        </div>
      </div>
      <div className="filter-bar-container-loading-bar">
        <ConditionalRender enable={loading}>
          <LoadingBar />
        </ConditionalRender>
      </div>
    </>
  );
};
FilterBar.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      fact: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.string,
      filter: PropTypes.shape({}),
    }),
  ),
  savedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      union: PropTypes.string,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          fact: PropTypes.string,
          operator: PropTypes.string,
          value: PropTypes.string,
          filter: PropTypes.shape({}),
        }),
      ),
    }),
  ),
  onAdd: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  onApplyAll: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  appendFilter: PropTypes.func.isRequired,
  globalFilterUnion: PropTypes.string.isRequired,
  setGlobalFilterUnion: PropTypes.func.isRequired,
  cancelFilters: PropTypes.func.isRequired,
  setInfoVisible: PropTypes.func.isRequired,
  parentRef: PropTypes.func.isRequired,
  submittedCompoundFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      union: PropTypes.string,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          fact: PropTypes.string,
          operator: PropTypes.string,
          value: PropTypes.string,
          filter: PropTypes.shape({}),
        }),
      ),
    }),
  ),
};

FilterBar.defaultProps = {
  filters: [],
  savedFilters: [],
  submittedCompoundFilters: [],
};

export default FilterBar;
