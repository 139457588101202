import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Link, Text } from '@puppet/react-components';

const PageHeader = ({
  pageTitle,
  renderIcon,
  ctaContent,
  headerDescription,
  subheaderLinks,
}) => {
  const renderHeaderDescription = () => {
    if (headerDescription?.length > 0) {
      return (
        <Text className="page-subheader-description">{headerDescription}</Text>
      );
    }
    return null;
  };

  const iterateOverLinks = () => {
    return subheaderLinks.map((link) => {
      return (
        <dl className="page-subheader-link-container" key={link.labelText}>
          <dt>{link.labelText}</dt>
          {link.staticText && (
            <Text className="page-subheader-link-text"> {link.staticText}</Text>
          )}
          <dd>
            <Link
              className="page-subheader-link"
              as="a"
              href={link.linkDestination}
              target={link.linkTarget}
            >
              {link.linkText}
            </Link>
          </dd>
        </dl>
      );
    });
  };

  const renderSubheaderLinks = () => {
    if (subheaderLinks.length > 0) {
      return (
        <div className="page-subheader-links-row">{iterateOverLinks()}</div>
      );
    }
    return null;
  };

  const renderCtaContent = () => {
    if (ctaContent) {
      return ctaContent();
    }
    return null;
  };
  const renderIconContent = () => {
    if (renderIcon) {
      return renderIcon();
    }
    return null;
  };

  return (
    <div className="page-header-container">
      <div className="page-header">
        <div className="page-header-subheader">
          <div className="page-header-title-container">
            <Heading as="h1" data-testid="page-heading-title">
              {pageTitle}
            </Heading>
            <div className="page-header-icon">{renderIconContent()}</div>
          </div>
          <div className="page-subheader">
            {renderHeaderDescription()}
            {renderSubheaderLinks()}
          </div>
        </div>
        <div className="page-header-cta">{renderCtaContent()}</div>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  pageTitle: PropTypes.string,
  ctaContent: PropTypes.func,
  renderIcon: PropTypes.func,
  headerDescription: PropTypes.string,
  subheaderLinks: PropTypes.arrayOf(
    PropTypes.exact({
      labelText: PropTypes.string.isRequired,
      linkText: PropTypes.string,
      linkDestination: PropTypes.string,
      staticText: PropTypes.string,
      linkTarget: PropTypes.string,
    }),
  ),
};

PageHeader.defaultProps = {
  pageTitle: '',
  renderIcon: null,
  ctaContent: null,
  headerDescription: '',
  subheaderLinks: [],
};

export default PageHeader;
