import React from 'react';
import { Heading, Card, Text, Button } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';

interface Props {
  onCreateDefaultPipeline: () => void;
  loadingDefaultPipeline: boolean;
  onAddStage: () => void;
}

const DefaultPipelineCard = ({
  onCreateDefaultPipeline,
  loadingDefaultPipeline,
  onAddStage,
}: Props) => {
  const { t } = useTranslation('codeDelivery');

  return (
    <Card className="default-pipeline-card">
      <Heading as="h4">
        {t('viewPipeline.stages.default.card.header.default')}
      </Heading>
      <Text size="small">
        {t('viewPipeline.stages.default.card.description')}
      </Text>
      <Button
        type="primary"
        onClick={() => onCreateDefaultPipeline()}
        data-testid="add-default-pipeline-btn"
        loading={loadingDefaultPipeline}
      >
        {t('viewPipeline.stages.default.card.button.add.pipeline')}
      </Button>

      <Heading as="h4">
        {t('viewPipeline.stages.add.pipline.card.header')}
      </Heading>
      <Text size="small">
        {t('viewPipeline.stages.add.pipeline.card.description')}
      </Text>
      <Button type="secondary" onClick={onAddStage} data-testid="add-stage-btn">
        {t('viewPipeline.stages.add.pipeline.card.button.add.stage')}
      </Button>
    </Card>
  );
};

export default DefaultPipelineCard;
