// These files are only here temporarily and will be moved when more designs are available
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Text } from '@puppet/react-components';
import ActionSelect from 'inventory/components/action-select';
import { useMutation } from '@apollo/client';
import { ADD_SAVED_VIEW } from 'inventory/services/graphql/queries/mutationAddView.gql';
import { EDIT_SAVED_VIEW } from 'inventory/services/graphql/queries/mutationEditView.gql';
import './SaveViewModal.scss';

const SaveViewModal = ({
  username,
  workspaceId,
  submittedFilters,
  selectedCards,
  selectedColumns,
  isEdited,
  activeView,
  name,
  description,
  favourite,
}) => {
  const { t } = useTranslation('inventory');
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [inEditMode, setInEditMode] = useState(false);

  const [addView] = useMutation(ADD_SAVED_VIEW);
  const [editView] = useMutation(EDIT_SAVED_VIEW);

  const savedViewURL = (viewName) => {
    const urlPathArray = window.location.pathname.split('/');
    const lastPathName = urlPathArray[urlPathArray.length - 1];

    if (lastPathName === 'nodes') {
      return `${window.location}/saved-views`;
    }
    if (viewName) {
      return `${urlPathArray.slice(0, -1).join('/')}/${viewName}`;
    }

    return `${urlPathArray.slice(0, -1).join('/')}/saved-views`;
  };

  const onEdit = () => {
    editView({
      variables: {
        name: activeView,
        workspace: workspaceId,
        user: username,
        NewViewDetails: {
          filter: JSON.stringify(submittedFilters),
          facts: JSON.stringify(selectedColumns),
          cards: JSON.stringify(selectedCards),
          favourite,
        },
      },
      onComplete: (window.location.href = savedViewURL()),
    });
  };

  const onUpdate = (values) => {
    editView({
      variables: {
        name: activeView,
        workspace: workspaceId,
        user: username,
        NewViewDetails: {
          name: values ? values.name : activeView,
          description: values ? values.description : description,
          favourite: values ? values.pin : favourite,
        },
      },
      onComplete: (window.location.href = savedViewURL(values.name)),
    });
  };

  const actions = [
    {
      id: 'save',
      label: t('save.view.options.save'),
      onClick() {
        onEdit();
      },
      disabled: !isEdited,
    },
    {
      id: 'saveAsNew',
      label: t('save.view.options.saveNew'),
      onClick() {
        setChecked(true);
        setOpen(true);
      },
    },
  ];

  const existingViewActions = [
    {
      id: 'save',
      label: t('save.view.options.save'),
      onClick() {
        onEdit();
      },
      disabled: !isEdited,
    },
    {
      id: 'saveAsNew',
      label: t('save.view.options.saveNew'),
      onClick() {
        setChecked(true);
        setOpen(true);
      },
    },
    {
      id: 'edit',
      label: t('save.view.options.edit'),
      onClick() {
        setInEditMode(true);
        setChecked(favourite);
        setOpen(true);
      },
    },
  ];

  const onSubmit = (values, user, workspace, filters, columns, cards) => {
    addView({
      variables: {
        name: values.name,
        createdBy: user,
        workspace,
        description: values.description,
        filter: JSON.stringify(filters),
        facts: JSON.stringify(columns),
        cards: JSON.stringify(cards),
        favourite: values.pin,
      },
      onComplete: (window.location.href = savedViewURL()),
    });
  };

  const initialValues = {
    name: inEditMode ? name : '',
    description: inEditMode ? description : '',
    pin: inEditMode ? favourite : true,
  };

  return (
    <div className="save-view-modal-container">
      <div
        className="node-explorer-header-right-side-ui"
        data-testid="save-action-select"
      >
        <ActionSelect
          className="save-view-modal-action-select"
          type="secondary"
          onClick={() => setOpen(true)}
          label={t('save.view.button.titleSave')}
          actions={name ? existingViewActions : actions}
          data-testid="save-view-modal-action-select"
        />
      </div>
      {open && (
        <Modal
          className="save-view-modal"
          onClose={() => {
            setInEditMode(false);
            setOpen(false);
          }}
        >
          <div data-testid="save-view-modal">
            <Modal.Title>
              {inEditMode
                ? t('save.view.modal.titleEdit')
                : t('save.view.modal.titleSave')}
            </Modal.Title>
            <Text className="save-view-modal-description">
              {t('save.view.modal.description')}
            </Text>
            <Form
              submittable
              cancellable
              submitLabel={t('save.view.modal.button.save')}
              initialValues={initialValues}
              onSubmit={(values) => {
                if (inEditMode) {
                  onUpdate(values);
                } else {
                  onSubmit(
                    values,
                    username,
                    workspaceId,
                    submittedFilters,
                    selectedColumns,
                    selectedCards,
                  );
                }
              }}
              onCancel={() => setOpen(false)}
            >
              <Form.Field
                data-testid="SaveViewForm-inputName"
                type="text"
                name="name"
                label={t('save.view.input.name.label')}
                placeholder={t('save.view.input.name.placeholder')}
                required
                autocomplete="off"
              />
              <Form.Field
                data-testid="SaveViewForm-inputDescription"
                type="text"
                name="description"
                label={t('save.view.input.description.label')}
                placeholder={t('save.view.input.description.placeholder')}
              />
              <Form.Field
                data-testid="SaveViewForm-CheckboxPin"
                type="checkbox"
                name="pin"
                label={
                  inEditMode
                    ? t('save.view.input.checkbox.EditLabel')
                    : t('save.view.input.checkbox.label')
                }
                checked={checked}
                value={checked}
                onClick={() => setChecked(!checked)}
              />
            </Form>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default SaveViewModal;

SaveViewModal.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  submittedFilters: PropTypes.instanceOf(Array),
  selectedColumns: PropTypes.instanceOf(Array),
  selectedCards: PropTypes.instanceOf(Array),
  isEdited: PropTypes.bool,
  activeView: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  favourite: PropTypes.bool,
};

SaveViewModal.defaultProps = {
  submittedFilters: [],
  selectedColumns: [],
  selectedCards: [],
  isEdited: false,
  activeView: '',
  name: '',
  description: '',
  favourite: true,
};
