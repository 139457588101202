import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loading } from '@puppet/react-components';
import ViewPipeline from '@codeDelivery/components/ViewPipeline';
import { BreadcrumbsType } from '@components/Breadcrumbs/Breadcrumbs';
import Cd4peError from '@components/Cd4peError';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { useGetModuleV1 } from '@services/cd4pe/modules';
import { LINKS } from 'src/routes';

const ViewModule = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const { name = '' } = useParams();

  const { data, isLoading, error } = useGetModuleV1({
    workspaceId,
    moduleName: name,
  });

  const breadcrumbs: BreadcrumbsType = [
    {
      displayName: workspaceName,
    },
    {
      displayName: t('viewModule.breadcrumb.modules'),
      linkDestination: LINKS.codeDelivery.listModules({
        path: { workspace: workspaceName },
      }),
    },
    { displayName: data?.name ?? '' },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    <Cd4peError error={error} />;
  }

  return (
    <ViewPipeline
      breadcrumbs={breadcrumbs}
      name={name}
      codeProject={data!}
      projectType="MODULE"
    />
  );
};

export default ViewModule;
