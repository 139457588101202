import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRender from 'inventory/components/ConditionalRender';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Icon, Text } from '@puppet/react-components';
import './ChartCardAlternate.scss';

const ChartCardAlternate = ({ icon, title, text, onEdit, onDelete }) => {
  const { t } = useTranslation('inventory');
  return (
    <div className="chart-card-alternate">
      <div className="chart-card-alternate-info">
        <Icon type={icon} role="img" />
        <Heading as="h5">{title}</Heading>
        <Text>{text}</Text>
      </div>
      <div className="chart-card-alternate-action-buttons">
        <ConditionalRender enable={typeof onEdit === 'function'}>
          <Button onClick={onEdit} type="secondary">
            {t('chart.modal.alternate.edit')}
          </Button>
        </ConditionalRender>
        <ConditionalRender enable={typeof onDelete === 'function'}>
          <Button onClick={onDelete} type="text">
            {t('chart.modal.alternate.remove')}
          </Button>
        </ConditionalRender>
      </div>
    </div>
  );
};

export default ChartCardAlternate;

ChartCardAlternate.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

ChartCardAlternate.defaultProps = {
  onEdit: null,
  onDelete: null,
};
