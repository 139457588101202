/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, useEffect, useReducer } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Table, TableHeader } from '@puppet/data-grid';
import { Alert, Button, Heading, Loading } from '@puppet/react-components';
import useWorkspaceName from '@hooks/useWorkspaceName';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@components/Breadcrumbs';
import { useAppDispatch } from '@hooks/redux';
import { useStore } from 'react-redux';
import alerts from '@state/ui/alerts';
import { LINKS } from 'src/routes';
import {
  AddUserActions,
  addUsersToWorkspace,
  BreadCrumbType,
  filterUsers,
  getGroups,
  getUsers,
  ListCandidatesWithSelectedAttr,
  ListRBACGroupsWithSelectedAttr,
  RbacGroupWithSelectedAttr,
  RowData,
  sortGroups,
  sortUsers,
  toggleAllSelectionOfType,
  toggleGroupRowSelection,
  toggleUserRowSelection,
  WorkspaceCandidateWithSelectedAttr,
} from './actions';
import { AddUserState, defaultAddUserState, reducer } from './reducer';

const transformBreadcrumbs = (
  breadcrumbs: BreadCrumbType[],
  workspace: string,
  t: TFunction<'codeDelivery'>,
) => {
  const crumbs = breadcrumbs.map((b) => {
    const { translationKey, ...rest } = b;
    return {
      displayName: t(translationKey),
      ...rest,
    };
  });
  return [{ displayName: workspace }, ...crumbs];
};

const renderError = (state: AddUserState) => {
  if (state.addUsersError) {
    return <Alert type="danger">{state.addUsersError}</Alert>;
  }
  return null;
};

const onHeaderSelected = (
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
  isChecked: boolean,
  type: 'users' | 'groups',
) => {
  toggleAllSelectionOfType(type, state, dispatch, isChecked);
};

const onGroupRowSelect = (
  isChecked: boolean,
  rowData: RowData,
  groups: ListRBACGroupsWithSelectedAttr,
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
) => {
  toggleGroupRowSelection(isChecked, rowData, groups, state, dispatch);
};

const onUserRowSelect = (
  isChecked: boolean,
  rowData: RowData,
  users: ListCandidatesWithSelectedAttr,
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
) => {
  toggleUserRowSelection(isChecked, rowData, users, state, dispatch);
};

const userToRow = (user: WorkspaceCandidateWithSelectedAttr) => ({
  userId: user.userId,
  username: user.username,
  selected: user.selected,
});

const userTableColumns = (t: TFunction<'codeDelivery'>) => [
  {
    label: t('addUsers.users.table.header.user'),
    dataKey: 'username',
    sortable: true,
  },
];

const usersTable = (
  t: TFunction<'codeDelivery'>,
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
) => {
  const rows = state.filteredUsers.map((user) => userToRow(user));
  const columns = userTableColumns(t);
  const onSort = (direction: string, sortDataKey: 'username') =>
    sortUsers(
      direction,
      sortDataKey,
      state.filteredUsers,
      state.query,
      dispatch,
    );
  return (
    <>
      <TableHeader
        search
        searchPlaceholder={t('addUsers.fields.search.placeholder')}
        searchValue={state.query}
        onSearchChange={(query: string) =>
          filterUsers(query, state.cachedUsers, dispatch)
        }
      />
      <div className="add-users__user-list-table">
        <Table
          data={rows}
          columns={columns}
          selectable
          emptyStateHeader={t('addUsers.table.empty.messageHeader')}
          emptyStateMessage=""
          onHeaderChecked={(checked: boolean) =>
            onHeaderSelected(state, dispatch, checked, 'users')
          }
          onRowChecked={(checked: boolean, rowData: RowData) =>
            onUserRowSelect(
              checked,
              rowData,
              state.filteredUsers,
              state,
              dispatch,
            )
          }
          headerCheckState={state.selectAllUsers}
          headerIndeterminateState={state.indeterminateUserState}
          onSort={onSort}
          sortedColumn={state.sortUsers}
        />
      </div>
    </>
  );
};

const groupToRow = (group: RbacGroupWithSelectedAttr) => ({
  userId: group.id,
  name: group.name,
  description: group.description,
  selected: group.selected,
});

const groupsTableColumns = (t: TFunction<'codeDelivery'>) => [
  {
    label: t('addUsers.groups.table.header.name'),
    dataKey: 'name',
    sortable: true,
  },
  {
    label: t('addUsers.groups.table.header.description'),
    dataKey: 'description',
    sortable: true,
  },
];

const groupsTable = (
  t: TFunction<'codeDelivery'>,
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
) => {
  const rows = state.groups.map((group) => groupToRow(group));
  const columns = groupsTableColumns(t);
  const onSort = (direction: string, sortDataKey: 'name' | 'description') =>
    sortGroups(direction, sortDataKey, state.groups, state.query, dispatch);

  return (
    <div className="add-users__group-list__table">
      <Table
        data={rows}
        columns={columns}
        selectable
        emptyStateHeader={t('groupList.table.empty.messageHeader')}
        emptyStateMessage={t('groupList.table.empty.messageBody')}
        onHeaderChecked={(checked: boolean) =>
          onHeaderSelected(state, dispatch, checked, 'groups')
        }
        onRowChecked={(checked: boolean, rowData: RowData) =>
          onGroupRowSelect(checked, rowData, state.groups, state, dispatch)
        }
        headerCheckState={state.selectAllGroups}
        headerIndeterminateState={state.indeterminateGroupState}
        onSort={onSort}
        sortedColumn={state.sortGroups}
      />
    </div>
  );
};

const body = (
  t: TFunction<'codeDelivery'>,
  dispatch: Dispatch<AddUserActions>,
  state: AddUserState,
) => {
  if (state.getUsersError) {
    return <Alert type="danger">{state.getUsersError}</Alert>;
  }

  if (state.getGroupsError) {
    return <Alert type="danger">{state.getGroupsError}</Alert>;
  }

  if (
    state.getUsersLoading &&
    state.cachedUsers.length === 0 &&
    state.getGroupsLoading &&
    state.groups.length === 0
  ) {
    return (
      <>
        <Loading data-testid="user-list-spinner" />
        <Loading data-testid="group-list-spinner" />
      </>
    );
  }

  return (
    <>
      <div className="add-users__user-list">
        <div className="add-users__user-list-header__cta">
          <Heading as="h3">{t('addUsers.users.header')}</Heading>
        </div>
        <div className="add-users__user-list-body">
          <div className="add-users__user-list-table__wrapper">
            {usersTable(t, state, dispatch)}
          </div>
        </div>
      </div>
      <div className="add-users__group-list">
        <div className="add-users__group-list__header-add-users-page">
          <Heading as="h5">{t('addUsers.users.groupsHeader')}</Heading>
        </div>
        <div className="add-users__group-list-body">
          {groupsTable(t, state, dispatch)}
        </div>
      </div>
    </>
  );
};

const AddUser = () => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const [state, dispatch] = useReducer(reducer, defaultAddUserState);
  const navigate = useNavigate();

  const appDispatch = useAppDispatch();
  const appStore = useStore();

  const usersSettingsPageUrl = LINKS.settings.listUsers({
    path: { workspace },
  });

  const breadcrumbs: BreadCrumbType[] = [
    {
      translationKey: 'addUsers.breadcrumbs.settingsPage',
    },
    {
      translationKey: 'addUsers.breadcrumbs.usersPage',
      linkDestination: usersSettingsPageUrl,
    },
    { translationKey: 'addUsers.breadcrumbs.addUsers' },
  ];

  const fetchUsers = () => {
    if (workspaceId) {
      getUsers(workspaceId, state.query, dispatch, t);
    }
  };
  useEffect(fetchUsers, [workspaceId, workspace]);

  const fetchGroups = () => {
    if (workspaceId) {
      getGroups(workspaceId, state.query, dispatch, t, state.sortGroups);
    }
  };
  useEffect(fetchGroups, [workspaceId, workspace]);

  useEffect(() => {
    const numSelected = state.cachedUsers.reduce(
      (acc, u) => (u.selected ? acc + 1 : acc),
      0,
    );
    if (state.addUsersComplete && !state.addUsersError) {
      navigate(usersSettingsPageUrl);
      appDispatch(
        alerts.actions.createAlert({
          type: 'Success',
          message: `${numSelected} user${
            numSelected > 1 ? 's' : ''
          } added to the workspace`,
        }),
      );
    }
  }, [
    state.addUsersComplete,
    usersSettingsPageUrl,
    state.addUsersError,
    appDispatch,
    appStore,
    state.cachedUsers,
    navigate,
  ]);
  return (
    <div className="add-users">
      <div className="add-users-page-header">
        <div className="add-users-page-header__breadcrumbs">
          <Breadcrumbs
            breadcrumbs={transformBreadcrumbs(breadcrumbs, workspace, t)}
          />
        </div>
        <Heading>{t('addUsers.page.title')}</Heading>
      </div>
      <div className="add-users-body__content">
        {body(t, dispatch, state)}
        <div className="add-users__post-error">{renderError(state)}</div>
        <div className="add-users-button-container">
          <Button
            onClick={() => addUsersToWorkspace(workspaceId, t, state, dispatch)}
          >
            {t('addUsers.button.addUsersToWorkspace.label')}
          </Button>
          <Button onClick={() => navigate(usersSettingsPageUrl)}>
            {t('addUsers.button.addUsersToWorkspace.cancel.label')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
