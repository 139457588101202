import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@puppet/react-components';
import { LINKS } from 'src/routes';

export type SettingsSideNavKeys =
  | 'workspaces'
  | 'users'
  | 'groups'
  | 'ssh'
  | 'puppetEnterprise'
  | 'integrations';

type SettingsSectionNames = 'workspace' | 'rbac' | 'settings';

interface Props {
  workspace: {
    domain: string;
    name: string;
    username: string;
  };
}

interface SidenavItem {
  text: string;
  href: string;
  name: SettingsSideNavKeys;
  testId?: string;
}

const SettingsSideNav = ({ workspace }: Props) => {
  const workspaceLinks: SidenavItem[] = [];
  const rbacLinks: SidenavItem[] = [];
  const settingsLinks: SidenavItem[] = [];
  const isCurrentContextWorkspace = !!workspace.name;
  // TODO: why does this fallback to username?
  const workspaceName = isCurrentContextWorkspace
    ? workspace.name
    : workspace.username;

  const { t } = useTranslation('codeDelivery');

  if (isCurrentContextWorkspace) {
    workspaceLinks.push({
      text: t('settingsSideNav.workspaces'),
      href: LINKS.settings.workspace({
        path: { workspace: workspaceName },
      }),
      name: 'workspaces',
    });
  }

  rbacLinks.push({
    text: t('settingsSideNav.users'),
    href: LINKS.settings.listUsers({ path: { workspace: workspaceName! } }),
    name: 'users',
  });

  const groupLink = LINKS.settings.listGroups({
    path: { workspace: workspaceName! },
  });

  rbacLinks.push({
    text: t('settingsSideNav.groups'),
    href: groupLink,
    name: 'groups',
  });

  settingsLinks.push({
    text: t('settingsSideNav.ssh'),
    href: LINKS.settings.ssh({ path: { workspace: workspaceName! } }),
    name: 'ssh',
  });

  settingsLinks.push({
    text: t('settingsSideNav.puppetEnterprise'),
    href: LINKS.settings.listPuppetEnterprise({
      path: { workspace: workspaceName! },
    }),
    name: 'puppetEnterprise',
  });

  settingsLinks.push({
    text: t('settingsSideNav.integrations'),
    href: LINKS.settings.listSourceControl({
      path: { workspace: workspaceName },
    }),
    name: 'integrations',
    testId: 'SettingsContainer_Source controlSideNavItem_TestId',
  });

  const navItemButton = (navItem: SidenavItem) => {
    return (
      <Button
        as={NavLink}
        type="transparent"
        className="side-nav-button"
        to={navItem.href}
        key={navItem.name}
        data-testid={navItem.testId}
      >
        {navItem.text}
      </Button>
    );
  };

  const divider = (sectionName: SettingsSectionNames) => {
    return (
      <div
        className="side-nav-divider"
        key={`${sectionName}-divider`}
        data-testid="side-nav-divider"
      />
    );
  };

  const renderSideNavSection = (
    items: SidenavItem[],
    sectionName: SettingsSectionNames,
    endingDivider = true,
  ) => {
    const sectionElements = items.map(navItemButton);
    if (sectionElements.length > 0 && endingDivider) {
      return [...sectionElements, divider(sectionName)];
    }
    return sectionElements;
  };

  return (
    <div className="settings-side-nav">
      {renderSideNavSection(workspaceLinks, 'workspace')}
      {renderSideNavSection(rbacLinks, 'rbac')}
      {renderSideNavSection(settingsLinks, 'settings', false)}
    </div>
  );
};

export default SettingsSideNav;
