export const loadScript = ({
  src,
  scriptId,
  onLoadHandler,
  onErrorHandler,
}) => {
  if (!src) return;

  const bundleUrl = new URL(src);
  const bundlePath = `${bundleUrl.pathname}${bundleUrl.search}`;
  const script = document.createElement('script');
  script.id = scriptId;
  script.src = bundlePath;
  script.onload = onLoadHandler;
  script.onerror = onErrorHandler;

  try {
    document.head.appendChild(script);
  } catch (e) {
    log.error(e);
  }
};

export const unloadScript = (scriptId) => {
  const jsScript = document.getElementById(scriptId);
  if (jsScript === null) return;

  try {
    document.head.removeChild(jsScript);
  } catch (e) {
    log.error(e);
  }
};

export const getPageStyles = () => {
  const htmlCollection = document.head.getElementsByTagName('style');
  return [...htmlCollection];
};

export const restorePageStyles = (oldStyles) => {
  const currentStyles = getPageStyles();
  currentStyles.forEach((style) => {
    if (style !== null && !oldStyles.includes(style)) {
      try {
        document.head.removeChild(style);
      } catch (e) {
        log.error(e);
      }
    }
  });
};
