import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Input, Loading } from '@puppet/react-components';
import QueryError from 'inventory/components/QueryError';
import QUERY_FACT_DATA from 'inventory/services/graphql/queries/queryFactData.gql';
import useTableDisplayMessageGenerator from 'inventory/utils/hooks';
import { useErrorMessageState } from 'inventory/utils/context/error-context';
import FactsTable from 'inventory/components/FactsTable';
import './FactTab.scss';

const FactTab = ({ source, nodeId, workspaceId, tableDisplayMessages }) => {
  const [filter, setFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [duplicateError, setDuplicateError] = useState(true);
  const { data, loading, error, client } = useQuery(QUERY_FACT_DATA, {
    variables: {
      source,
      id: workspaceId,
      certname: nodeId,
      filter,
    },
  });

  const handleEnterKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearchTerm(e.target.value);
      setIsSubmitted(true);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      setFilter({ AND: [{ name: { like: searchTerm } }] });
    } else {
      setFilter(null);
    }
    setIsSubmitted(false);
  }, [searchTerm, isSubmitted]);

  const { errorMessage } = useErrorMessageState();

  const { t } = useTranslation('inventory');

  useEffect(() => {
    if (
      error &&
      errorMessage &&
      errorMessage.payload.message === error.message
    ) {
      setDuplicateError(true);
    }
  }, [error, errorMessage]);

  const { emptyStateHeader, emptyStateMessage } =
    useTableDisplayMessageGenerator({
      loading,
      searchTerm,
      tableDisplayMessages,
    });

  const factData = data?.hosts?.nodes[0]?.factsConnection?.facts ?? [];

  return (
    <div className="fv-container">
      <div className="fv-search-container">
        <div className="fv-search">
          <Input
            name="fact-search"
            value={inputValue}
            placeholder={t('factView.search.placeholder')}
            icon="search"
            onChange={(value) => setInputValue(value)}
            onKeyDown={(e) => {
              handleEnterKeyPress(e);
            }}
            disabled={loading}
          />
        </div>
        {loading && <Loading className="fv-search-loader" />}
      </div>
      {!duplicateError && <QueryError error={error} client={client} />}
      <FactsTable
        factData={factData}
        emptyStateHeader={emptyStateHeader}
        emptyStateMessage={emptyStateMessage}
        loading={loading}
        source={source}
      />
    </div>
  );
};

export default FactTab;

FactTab.propTypes = {
  nodeId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired,
  tableDisplayMessages: PropTypes.shape({
    loadingHeader: PropTypes.string,
    loadingMessage: PropTypes.string,
    noDataHeader: PropTypes.string,
    emptyFilterMessage: PropTypes.string,
    emptyFilterDataHeader: PropTypes.string,
  }).isRequired,
  source: PropTypes.string.isRequired,
};
