/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, {
  useCallback,
  useEffect,
  useState,
  useReducer,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Breadcrumbs from 'components/Breadcrumbs';
import classnames from 'classnames';
import {
  selectUser,
  selectWorkspaceDomain,
  selectWorkspaceName,
} from 'state/session/selectors';
import client from 'inventory/services/graphql/index';
import ConditionalRender from 'inventory/components/ConditionalRender';
import NodeTable from 'inventory/components/NodeTable';
import {
  PUPPET_FACT,
  COMPOUND_FILTER_AND,
  COMPOUND_FILTER_OR,
} from 'utils/constants';
import { useQuery } from '@apollo/client';
import GET_SAVED_VIEWS from 'inventory/services/graphql/queries/querySavedViews.gql';
import GET_SAVED_VIEW from 'inventory/services/graphql/queries/querySavedView.gql';
import QueryError from 'inventory/components/QueryError';
import SaveViewModal from 'inventory/views/StoredViews/SaveViewModal';
import {
  Button,
  Heading,
  Loading,
  Text,
  Icon,
  Link,
  Modal,
  Overlay,
  Popover,
  Content,
  Tabs,
} from '@puppet/react-components';
import Select from 'inventory/components/Select';
import CardBoard from 'inventory/components/CardBoard';
import FilterBar from 'inventory/components/FilterBar';
import FilterDetails from 'inventory/components/FilterDetails';
import { cardService, initialCards } from 'inventory/utils/cards/index';
import filterService from 'inventory/utils/filters/index';
import './NodeExplorer.scss';
import { LINKS } from 'src/routes';
import { nodeExplorerDefaultState, reducer } from './reducer';
import { evaluatePermissions } from './actions';

const tableDisplayMessages = (t) => ({
  loadingHeader: t('common.table.loading.header'),
  loadingMessage: t('common.table.loading.message'),
  noDataHeader: t('common.table.noData.header'),
  emptyFilterMessage: t('common.table.noData.filter.message'),
  emptyFilterDataHeader: t('common.table.noData.filter.header'),
});

const savedViewsUrl = 'saved-views';

const NodeExplorerView = () => {
  const { t } = useTranslation('inventory');
  const { userName, superUser } = useSelector((state) => selectUser(state));
  const workspaceName = useSelector((state) => selectWorkspaceName(state));
  const domain = useSelector((state) => selectWorkspaceDomain(state));

  const features = {
    enableFactVisualization: true,
  };

  const defaultFilters = [
    {
      value: 'peMasterServer',
      label: t('nodeTable.filter.option.peMasterServer'),
    },
    {
      value: 'changeStatus',
      label: t('nodeTable.filter.option.changeStatus'),
    },
    { value: 'noopStatus', label: t('nodeTable.filter.option.noopStatus') },
    {
      value: 'operatingSystem',
      label: t('nodeTable.filter.option.operatingSystem'),
    },
    { value: 'nodeGroup', label: t('nodeTable.filter.option.nodeGroup') },
    { value: 'factValue', label: t('nodeTable.filter.option.factFilter') },
  ];

  const urlPathArray = window.location.pathname.split('/');
  const lastPathName = urlPathArray[urlPathArray.length - 1];

  const [activeView] = useState(
    lastPathName !== 'nodes' ? decodeURI(lastPathName) : '',
  );
  const [submittedFilters, setSubmittedFilters] = useState([]);
  const [compoundFilters, setCompoundFilters] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showDrawerDetails, setShowDrawerDetails] = useState(false);
  const [applyCompoundFilters, setApplyCompoundFilters] = useState(false);
  const [error, setError] = useState(null);
  const [submittedCards, setSubmittedCards] = useState(
    cardService.getCards(initialCards),
  );
  const [cardObject, setCardObject] = useState([]);
  const [savedFilters, setSavedFilters] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isAppending, setIsAppending] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [showIsEdited, setShowIsEdited] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const [submittedCompoundFilters, setSubmittedCompoundFilters] = useState([]);
  const [activeTab, setTab] = useState('fact-chart-tab');

  const [filterModalTitle, setFilterModalTitle] = useState(
    'nodeTable.filter.modal.title',
  );
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [peServerFilterInfo, setPeServerFilterInfo] = useState(false);
  const [peServerToAdd, setPeServerToAdd] = useState('');

  const [globalFilterUnion, setGlobalFilterUnion] =
    useState(COMPOUND_FILTER_AND);

  const [state, dispatch] = useReducer(reducer, nodeExplorerDefaultState);

  const infoRef = useRef();
  const [infoVisible, setInfoVisible] = useState(false);

  const { id: userId } = useSelector((appState) => selectUser(appState));
  useEffect(() => {
    if (domain) {
      // check permission exists for Inventory
      evaluatePermissions(domain, userId, dispatch);
    }
  }, [domain, userId]);

  const loadingPriviliges = state?.evalPermissionsLoading;
  const hasViewInventoryPriviliges = superUser || state?.evalPermissions[0];

  useEffect(() => {
    setCardObject(cardService.getSavedViewsFormat(submittedCards));
  }, [submittedCards]);

  const getSubmittedFilters = (filters) => {
    setSubmittedFilters(filters);
  };

  const getSelectedColumns = (columns) => {
    setSelectedColumns(columns);
  };

  const {
    data: viewsData,
    loading: viewsLoading,
    error: viewsError,
  } = useQuery(GET_SAVED_VIEWS, {
    variables: {
      workspace: domain,
      user: userName,
    },
    client,
  });

  const viewsMap = {
    [t('nodeExplore.select.favorite.group.title')]: [
      {
        value: '',
        label: t('nodeExplorer.select.default.placeholder.view'),
        description: t('nodeExplorer.select.default.value.description'),
      },
    ],
    [t('nodeExplore.select.not.favorite.group.title')]: [],
  };

  if (viewsData !== undefined) {
    viewsData?.views?.views.map((view) => {
      if (view.favourite) {
        viewsMap[t('nodeExplore.select.favorite.group.title')].push({
          value: view.name,
          label: view.name,
          description: view.description,
        });
      } else {
        viewsMap[t('nodeExplore.select.not.favorite.group.title')].push({
          value: view.name,
          label: view.name,
          description: view.description,
        });
      }
    });
  }

  const UiReviewsMap = {
    [t('nodeExplore.select.favorite.group.title')]: [
      {
        value: '',
        label: t('nodeExplorer.select.default.placeholder'),
        description: t('nodeExplorer.select.default.value.description'),
      },
    ],
    [t('nodeExplore.select.other.views.group.title')]: [],
  };

  if (viewsData !== undefined) {
    viewsData?.views?.views.map((view) => {
      if (view.favourite) {
        UiReviewsMap[t('nodeExplore.select.favorite.group.title')].push({
          value: view.name,
          label: view.name,
          description: view.description,
        });
      } else {
        UiReviewsMap[t('nodeExplore.select.other.views.group.title')].push({
          value: view.name,
          label: view.name,
          description: view.description,
        });
      }
    });
  }

  const {
    data: saveView,
    loading: saveViewLoading,
    error: viewError,
  } = useQuery(GET_SAVED_VIEW, {
    variables: {
      workspace: domain,
      name: activeView,
      user: userName,
    },
    client,
  });

  const {
    name = '',
    description = '',
    filter,
    facts,
    cards,
    favourite,
  } = saveView?.views?.views[0] ?? '';

  if ((viewError || viewsError) && !error) {
    if (
      viewError !== undefined &&
      !viewError.message === "GraphQL error: View '' not found"
    ) {
      setError({
        graphQLErrors: [
          {
            message: t('nodeExplorer.error.failed.saved.view'),
          },
        ],
        message: t('nodeExplorer.error.failed.saved.view'),
      });
    }
  }

  if (filter !== undefined) {
    const filterObject = JSON.parse(filter);
    if (
      ((Array.isArray(filterObject) && filterObject.length > 0) ||
        (Object.values(filterObject).length === 1 &&
          Object.keys(filterObject).length > 0)) &&
      savedFilters.length === 0
    ) {
      // This check is used to convert old saved node filters to the new compound filter.
      // After a while the if check and else can be removed
      if (
        Object.keys(filterObject)[0] === COMPOUND_FILTER_AND ||
        Object.keys(filterObject)[0] === COMPOUND_FILTER_OR
      ) {
        setGlobalFilterUnion(Object.keys(filterObject)[0]);
        setCompoundFilters(Object.values(filterObject)[0][0]);
        setSubmittedCompoundFilters(Object.values(filterObject)[0][0]);
        setSavedFilters(Object.values(filterObject)[0]);
      } else {
        setCompoundFilters(filterService.getFilters(filterObject));
        setSubmittedCompoundFilters(filterService.getFilters(filterObject));
        setSavedFilters(filterObject);
      }
    }
  }

  if (cards !== undefined && cards.length > 0) {
    const cardObjectSting = JSON.stringify(cardObject);
    if (
      cardObjectSting !== cards &&
      cardObjectSting === JSON.stringify(initialCards)
    ) {
      setCardObject(JSON.parse(cards));
      setSubmittedCards(cardService.getCards(JSON.parse(cards)));
    }
  }

  /**
   *  Return's the needed url for navigating around the node explorer
   *
   * @param {bool} toSavedViews: if navigating to saved views screen pass true
   * @param {string} view: If your navigating to a saved node view pass it here
   */
  const url = (toSavedViews, view) => {
    if (toSavedViews) {
      if (activeView === '' || undefined) {
        return `${window.location}/${savedViewsUrl}`; // /nodes => nodes/saved-views
      }
      return `${urlPathArray.slice(0, -1).join('/')}/${savedViewsUrl}`; // /nodes/{view} => nodes/saved-view
    }
    if (activeView === '') {
      if (view === '') {
        return urlPathArray.join('/'); // /nodes => /nodes
      }
      return `${urlPathArray.join('/')}/${view}`; // /nodes => /nodes/view
    }
    if (view === '') {
      return urlPathArray.slice(0, -1).join('/'); // /nodes/view => nodes
    }
    return `${urlPathArray.slice(0, -1).join('/')}/${view}`; // /nodes/view => nodes/newView
  };

  /**
   *  Return's whether there has been a change to saved content in a view or not
   * @param {Object[]} inputtedFilters is the current filters state
   * @param {Object[]} savedfilter is the saved db content
   * @param {string[]} selectedColumns is the current fact state
   * @param {string[]} savedfacts is the saved db content
   * @param {string[]} inputtedCards is the current cards state
   * @param {string[]} savedCards is the saved db content
   */
  const checkForChanges = (
    inputtedFilters,
    savedfilter,
    submittedColumns,
    savedfacts,
    inputtedCards,
    savedCards,
  ) => {
    if (
      savedfilter === undefined &&
      savedfacts === undefined &&
      savedCards === undefined
    ) {
      return false;
    }

    if (JSON.stringify(submittedColumns) !== savedfacts) {
      return true;
    }

    if (JSON.stringify(inputtedFilters) !== savedfilter) {
      return true;
    }
    if (savedCards !== JSON.stringify(inputtedCards)) {
      return true;
    }
  };

  const onEditCard = (values) => {
    setSubmittedCards(cardService.editCard(values, submittedCards));
  };

  const onAddCard = (values) => {
    setSubmittedCards(
      cardService.updateCards(values, submittedCards, PUPPET_FACT),
    );
  };
  const onDeleteCard = (id) => {
    setSubmittedCards(cardService.removeCard(id, submittedCards));
  };

  // This wll remove a filter on the active filter array
  const onRemove = (filterIndex) => {
    setActiveFilters([
      ...activeFilters.slice(0, filterIndex),
      ...activeFilters.slice(filterIndex + 1),
    ]);
  };

  // This will set the active filter array to an empty array
  const onClearAll = () => {
    setActiveFilters([]);
  };

  const onApplyAllFilterBar = () => {
    setApplyCompoundFilters(true);
    // add function to filters class to flip applied status? possible solution to this???
    setSubmittedCompoundFilters(compoundFilters);
  };

  const onUpdateFilters = (updatedFilter, existingFilters) => {
    setCompoundFilters(
      filterService.updateFilterGroup(updatedFilter, existingFilters),
    );
  };

  const appendFilter = (appendingFilter) => {
    setOpenFilterModal(true);
    setIsAppending(appendingFilter);
    setFilterModalTitle('nodeTable.filter.modal.titleAppendFilter');
    setIsModalOpen(true);
  };

  const cancelFilters = () => {
    setCompoundFilters(submittedCompoundFilters);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedFilter(null);
    setIsAppending(null);
    setFilterModalTitle('nodeTable.filter.modal.title');
  };

  const onAddFilter = () => {
    setOpenFilterModal(true);
    if (compoundFilters.length > 0) {
      setFilterModalTitle('nodeTable.filter.modal.titleAddFilter');
    }
    setIsModalOpen(true);
  };

  const onAddCompoundFilter = (newFilter) => {
    if (newFilter.filter.source || newFilter.filter.groupSource) {
      if (filterService.hasPeServerFilter(compoundFilters)) {
        setPeServerToAdd(
          newFilter.filter.source
            ? newFilter.filter.source
            : newFilter.filter.groupSource,
        );
        setPeServerFilterInfo(true);
        return;
      }
    }

    if (isAppending) {
      setCompoundFilters(
        filterService.updateFilterGroup(
          filterService.appendFilter(isAppending, newFilter),
          compoundFilters,
        ),
      );
      setIsAppending(null);
    } else {
      setCompoundFilters(
        filterService.addFilterGroup(newFilter, compoundFilters),
      );
    }
  };

  const getPageTitle = () => {
    if (name) return name;
    return t('nodeExplorer.header.defaultTitle');
  };

  const onApply = useCallback(() => {
    setIsModalOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  useEffect(() => {
    if (!isModalOpen && activeFilters !== submittedFilters) {
      onApply();
    }
  }, [activeFilters, onApply, isModalOpen, submittedFilters]);

  useEffect(() => {
    const changes = checkForChanges(
      { [globalFilterUnion]: [submittedCompoundFilters] },
      filter,
      selectedColumns,
      facts,
      cardObject,
      cards,
    );
    setIsEdited(changes);
    setShowIsEdited(changes);
  }, [
    cardObject,
    cards,
    submittedCompoundFilters,
    facts,
    filter,
    globalFilterUnion,
    selectedColumns,
    submittedCards,
    submittedFilters,
  ]);
  const breadcrumbs = (
    <Breadcrumbs
      breadcrumbs={[
        {
          displayName: workspaceName,
          linkDestination: LINKS.inventory.listNodes({
            path: { workspace: workspaceName },
          }),
        },
        {
          displayName: t('nodes.breadcrumb'),
        },
      ]}
    />
  );

  if (loadingPriviliges) {
    return (
      <>
        {breadcrumbs}
        <div>
          <div>
            <div className="node-explorer-header">
              <Heading as="h2">{getPageTitle()}</Heading>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!hasViewInventoryPriviliges) {
    return (
      <>
        {breadcrumbs}
        <div>
          <div>
            <QueryError error={error} client={client} />
            <div className="node-explorer-header">
              <Heading as="h2">{getPageTitle()}</Heading>
            </div>
            <div className="node-explorer-no-privileges">
              <Icon role="img" type="alert" size="large" />
              <Heading
                as="h4"
                color="medium"
                className="node-explorer-no-privilige-header"
              >
                {t('nodeExplore.no.privilige.header')}
              </Heading>
              <Text color="medium">{t('nodeExplore.no.privilige.text')}</Text>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <ConditionalRender enable={showIsEdited}>
        <div className="node-explorer-header-view-changed">
          <Text>{t('nodeExplorer.changed.saved.view')}</Text>
          <Button
            className="node-explore-drawer-button"
            type="text"
            onClick={() => setShowIsEdited(false)}
          >
            {t('nodeExplorer.title.dismiss.button')}
          </Button>
        </div>
      </ConditionalRender>
      {breadcrumbs}
      {activeView && saveViewLoading ? (
        <Loading size="large" />
      ) : (
        <div className="node-explorer-main-page">
          <div>
            <QueryError error={error} client={client} />
            <div className="node-explorer-header">
              <div className="node-explorer-header-title">
                <Heading as="h2">{getPageTitle()}</Heading>
                <ConditionalRender enable={!showDrawerDetails}>
                  <Button
                    className="node-explore-drawer-button"
                    type="text"
                    onClick={() => setShowDrawerDetails(true)}
                    data-testid="node-explorer-view-details-button"
                  >
                    <u>{t('nodeExplorer.title.details.button')}</u>
                  </Button>
                </ConditionalRender>
              </div>
              <ConditionalRender
                enable={
                  submittedCompoundFilters.length > 0 && showNoResults === true
                }
              >
                <Popover onClose={() => setShowNoResults(false)} side="none">
                  <Content>
                    <Text size="small">
                      <Icon role="img" type="error" />
                      <strong>
                        {t('nodeExplorer.header.noFilterResults.title')}
                      </strong>
                    </Text>
                    <Text size="small">
                      {t('nodeExplorer.header.noFilterResults.text')}
                    </Text>
                  </Content>
                </Popover>
              </ConditionalRender>
              <div
                className="node-explore-save-view-controls"
                data-testid="view-select"
              >
                <Select
                  className="node-explorer-view-select"
                  name="select-example"
                  options={UiReviewsMap}
                  disabled={UiReviewsMap.length === 0 || viewsLoading}
                  placeholder={t('nodeExplorer.select.view.placeholder')}
                  value={name}
                  onChange={(view) => {
                    window.location.href = url(false, view);
                  }}
                  trailingIcon="chevron-down"
                  style={{}}
                  navigationButton
                  // eslint-disable-next-line no-return-assign
                  onNavigate={() => (window.location.href = url(true))}
                  navigationLabel={t(
                    'nodeExplore.select.other.views.nav.label',
                  )}
                />
                <SaveViewModal
                  username={userName}
                  workspaceId={domain}
                  submittedFilters={{
                    [globalFilterUnion]: [submittedCompoundFilters],
                  }}
                  selectedCards={cardObject}
                  selectedColumns={selectedColumns}
                  isEdited={isEdited}
                  activeView={activeView}
                  name={name}
                  description={description}
                  favourite={favourite}
                />
              </div>
            </div>
            <div
              className={classnames({
                'node-explorer-header-drawer-edit': isEdited,
              })}
            >
              <ConditionalRender enable={showDrawerDetails}>
                <div className="save-view-details">
                  <Button
                    type="transparent"
                    icon="chevron-double-up"
                    onClick={() => setShowDrawerDetails(false)}
                  />

                  <table data-testid="NodeExplorer-ViewDescriptorTable">
                    <tbody>
                      <tr>
                        <td>
                          <Heading
                            className="node-explorer-drawer-fields-labels"
                            label
                            color="medium"
                          >
                            {t('nodeExplorer.drawer.description.field.title')}
                          </Heading>
                        </td>
                        <td>
                          <Text size="small" color="medium">
                            {description !== ''
                              ? description
                              : t(
                                  'nodeExplorer.drawer.description.field.empty',
                                )}
                          </Text>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Heading
                            className="node-explorer-drawer-fields-labels"
                            label
                            color="medium"
                          >
                            {t('nodeExplorer.drawer.filter.field.titleApplied')}
                          </Heading>
                        </td>
                        <td>
                          <div className="node-explore-drawer-filter-list">
                            <FilterDetails
                              savedFilters={savedFilters[0]}
                              globalUnion={globalFilterUnion}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Heading
                            className="node-explorer-drawer-fields-labels"
                            label
                            color="medium"
                          >
                            {t(
                              'nodeExplorer.drawer.table.columns.field.titleApplied',
                            )}
                          </Heading>
                        </td>
                        <td>
                          <Text size="small" color="medium">
                            {facts !== '' && facts !== undefined
                              ? facts
                                  .replaceAll('"', '')
                                  .replace('[', '')
                                  .replace(']', '')
                              : t(
                                  'nodeExplorer.drawer.table.columns.field.empty',
                                )}
                          </Text>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Heading
                            className="node-explorer-drawer-fields-labels"
                            label
                            color="medium"
                          >
                            {t(
                              'nodeExplorer.drawer.table.cards.field.titleApplied',
                            )}
                          </Heading>
                        </td>
                        <td>
                          <Text size="small" color="medium">
                            {/* Swap 'cachedCards' for 'cards' once query is ready */}
                            {cardObject && cardObject.length > 0
                              ? cardObject.map((card, index) => {
                                  return cardObject.length > index
                                    ? `${card.label}, `
                                    : `${card.label}`;
                                })
                              : t(
                                  'nodeExplorer.drawer.table.cards.field.empty',
                                )}
                          </Text>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ConditionalRender>
            </div>
          </div>
          <div>
            <div className="filter-bar-info">
              <Text size="tiny">
                <strong>{t('compoundFiltering.filterBar.info')}</strong>
              </Text>
            </div>
            <FilterBar
              onAdd={onAddFilter}
              filters={compoundFilters}
              updateFilters={onUpdateFilters}
              onApplyAll={onApplyAllFilterBar}
              loading={isTableLoading}
              appendFilter={appendFilter}
              globalFilterUnion={globalFilterUnion}
              setGlobalFilterUnion={setGlobalFilterUnion}
              cancelFilters={cancelFilters}
              savedFilters={savedFilters ? savedFilters[0] : []}
              submittedCompoundFilters={submittedCompoundFilters}
              parentRef={infoRef}
              setInfoVisible={setInfoVisible}
              isModalOpen={isModalOpen}
            />
          </div>
          <Tabs
            id="controlled-tabs"
            onChange={(tab) => setTab(tab)}
            transparent
            type="secondary"
          >
            <Tabs.Tab
              title={t('nodeExplorer.tabs.factCharts')}
              id="fact-chart-tab"
              active={activeTab === 'fact-chart-tab'}
              data-testid="fact-chart-tab-button"
            >
              <CardBoard
                workspaceId={domain}
                submittedCards={submittedCards}
                onEditCard={onEditCard}
                onAdd={onAddCard}
                onDelete={onDeleteCard}
                loading={isTableLoading}
              />
            </Tabs.Tab>
            <Tabs.Tab
              title={t('nodeExplorer.tabs.nodeTable')}
              id="node-table-table"
              active={activeTab === 'node-table-table'}
              data-testid="node-table-table-tab-button"
            >
              <NodeTable
                linkToNodeDetails={LINKS.inventory.listNodes({
                  path: { workspace: workspaceName },
                })}
                workspaceId={domain}
                tableDisplayMessages={tableDisplayMessages(t)}
                getSubmittedFilters={getSubmittedFilters}
                getSelectedColumns={getSelectedColumns}
                initialFilter={filter}
                initialColumns={facts}
                features={features}
                openAddFilterModal={openFilterModal}
                setOpenFilterModal={setOpenFilterModal}
                setIsModalOpen={setIsModalOpen}
                onApply={onApply}
                onModalClose={onModalClose}
                isModalOpen={isModalOpen}
                onRemove={onRemove}
                onClearAll={onClearAll}
                setIsTableLoading={setIsTableLoading}
                filterModalTitle={filterModalTitle}
                availableFilters={defaultFilters}
                setSelectedFilter={setSelectedFilter}
                selectedFilter={selectedFilter}
                onAddCompoundFilter={onAddCompoundFilter}
                compoundFilters={submittedCompoundFilters}
                applyCompoundFilters={applyCompoundFilters}
                setApplyCompoundFilters={setApplyCompoundFilters}
                globalFilterUnion={globalFilterUnion}
                setShowNoResults={setShowNoResults}
              />
            </Tabs.Tab>
          </Tabs>
        </div>
      )}
      <Overlay
        target={infoRef}
        show={infoVisible}
        position="right"
        align="inner"
      >
        <div className="filter-bar-actions-popover">
          <Popover
            className="info-popover"
            side="right"
            onClose={() => setInfoVisible(false)}
          >
            <Text size="small">{t('nodeExplorer.filter.info.text1')}</Text>
            <br />
            <Text size="small">{t('nodeExplorer.filter.info.text2')}</Text>
            <br />
            <Link
              as="a"
              href="https://puppet.com/docs/continuous-delivery/latest/node_inventory.html#compound-filters"
              target="_blank"
              size="small"
            >
              {t('nodeExplorer.filter.info.link')}
            </Link>
          </Popover>
        </div>
      </Overlay>
      <Modal
        className="filter-modal-pe-server-info"
        onClose={() => {
          setPeServerFilterInfo(false);
          setIsModalOpen(true);
        }}
        isOpen={peServerFilterInfo}
      >
        <Modal.Title>
          {t('nodeExplorer.filter.peServer.modal.title')}
        </Modal.Title>
        <Text size="small" className="filter-modal-pe-server-info-name">
          {t('nodeExplorer.filter.peServer.modal.name')}
          {peServerToAdd}
        </Text>
        <div className="filter-modal-pe-server-info-text">
          <Text size="small">
            {t('nodeExplorer.filter.peServer.modal.text1')}
          </Text>
          <Text size="small">
            {t('nodeExplorer.filter.peServer.modal.text2')}
          </Text>
          <Link
            as="a"
            href="https://puppet.com/docs/continuous-delivery/latest/node_inventory.html#compound-filters"
            target="_blank"
            size="small"
          >
            {t('nodeExplorer.filter.peServer.modal.link')}
          </Link>
        </div>
        <Modal.Actions>
          <Button
            onClick={() => {
              setPeServerFilterInfo(false);
              setIsModalOpen(true);
            }}
          >
            {t('nodeExplorer.filter.peServer.modal.button')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default NodeExplorerView;
