import React, { useMemo, useState } from 'react';
import {
  ProjectPipelineStageV1,
  TriggerConditionV1,
} from '@puppet/cd4pe-client-ts';
import ConditionalRender from '@components/ConditionalRender';
import StageCard from '@codeDelivery/components/ViewPipeline/components/Stages/components/StageCard';
import PromoteStage from '@codeDelivery/components/ViewPipeline/components/Stages/components/PromoteStage';
import { Button, Modal, Text } from '@puppet/react-components';
import { useUpsertPipelineStagesV1Optimistic } from '@services/cd4pe/pipelines';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useTranslation } from 'react-i18next';
import alerts from '@state/ui/alerts';
import { useAppDispatch } from '@hooks/redux';

interface Props {
  pipelineId: string;
  projectName: string;
  stages: Array<ProjectPipelineStageV1>;
  onClose: () => void;
}

const compareStages = (a: ProjectPipelineStageV1, b: ProjectPipelineStageV1) =>
  a.stageName === b.stageName &&
  a.triggerOn === b.triggerOn &&
  a.triggerCondition === b.triggerCondition;

const compareStageArrays = (
  a: Array<ProjectPipelineStageV1>,
  b: Array<ProjectPipelineStageV1>,
) => a.some((stage, i) => !compareStages(stage, b[i]));

const ReorderStagesDialog = ({
  pipelineId,
  projectName,
  stages,
  onClose,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const upsertPipelineStages = useUpsertPipelineStagesV1Optimistic();
  const appDispatch = useAppDispatch();
  const [updatedStages, setUpdatedStages] = useState(stages);

  const stagesAreEqual = useMemo(
    () => compareStageArrays(stages, updatedStages),
    [stages, updatedStages],
  );

  const updateAutoPromote = (triggerOn: boolean, index: number) => {
    const newStages = structuredClone(updatedStages);
    newStages[index].triggerOn = triggerOn;
    setUpdatedStages(newStages);
  };

  const updateTriggerCondition = (
    triggerCondition: TriggerConditionV1,
    index: number,
  ) => {
    const newStages = structuredClone(updatedStages);
    newStages[index].triggerCondition = triggerCondition;
    setUpdatedStages(newStages);
  };

  const reorderStages = (id: number, destination: number) => {
    const newStages = structuredClone(updatedStages);
    const stage = newStages[destination];
    newStages[destination] = newStages[id];
    newStages[id] = stage;
    newStages[destination].triggerOn = false;
    newStages[id].triggerOn = false;

    setUpdatedStages(newStages);
  };

  const saveStages = () => {
    upsertPipelineStages.mutate(
      {
        workspaceId,
        pipelineId,
        requestBody: {
          projectName,
          stages: updatedStages.map((stage, index) => ({
            ...stage,
            stageNum: index + 1,
          })),
        },
      },
      {
        onSuccess: () => {
          onClose();
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('viewPipeline.pipeline.alerts.reorder.stages.success'),
            }),
          );
        },
        onError: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Error',
              message: t('viewPipeline.pipeline.alerts.reorder.stages.error'),
            }),
          );
        },
      },
    );
  };

  return (
    <Modal className="reorder-pipeline-dialog" onClose={onClose}>
      <div data-testid="reorder-pipeline-modal">
        <Modal.Title>
          {t('viewPipeline.dialog.reorderStages.title')}
        </Modal.Title>
        <Text className="reorder-pipeline-dialog__description">
          {t('viewPipeline.dialog.reorderStages.description')}
        </Text>
        {updatedStages.map((stage, index) => (
          <div key={stage.stageNum}>
            <div className="reorder-pipeline-dialog__stage">
              <StageCard
                stage={stage}
                reordering
                reordered={stage.stageNum !== index + 1}
              />
              <div className="reorder-pipeline-dialog__stage-buttons">
                <Button
                  type="primary"
                  icon="chevron-up"
                  onClick={() => {
                    reorderStages(index, index - 1);
                  }}
                  disabled={index === 0}
                  data-testid={`reorder-stage-up-${index}`}
                />
                <Button
                  type="primary"
                  icon="chevron-down"
                  onClick={() => {
                    reorderStages(index, index + 1);
                  }}
                  disabled={index === stages.length - 1}
                  data-testid={`reorder-stage-down-${index}`}
                />
              </div>
            </div>
            <ConditionalRender enable={index < stages.length - 1}>
              <PromoteStage
                stage={stage}
                onAutoPromote={(triggerOn: boolean) => {
                  updateAutoPromote(triggerOn, index);
                }}
                onTriggerCondition={(triggerCondition) => {
                  updateTriggerCondition(triggerCondition, index);
                }}
                reordering
                enablePromote={false}
              />
            </ConditionalRender>
          </div>
        ))}
        <Modal.Actions>
          <Button
            type="primary"
            onClick={saveStages}
            loading={upsertPipelineStages.isLoading}
            disabled={!stagesAreEqual}
            data-testid="reorder-pipeline-save-button"
          >
            {t('viewPipeline.dialog.reorderStages.button.save')}
          </Button>
          <Button
            type="tertiary"
            onClick={onClose}
            data-testid="reorder-pipeline-cancel-button"
          >
            {t('viewPipeline.dialog.reorderStages.button.cancel')}
          </Button>
        </Modal.Actions>
      </div>
    </Modal>
  );
};

export default ReorderStagesDialog;
