import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link as PdsLink } from '@puppet/react-components';
import Form from './Form';

type Props = {
  submitting: boolean;
  onSubmit: Function;
  onChangeMethod: Function;
  isError: boolean;
};

const LdapForm = ({ onSubmit, submitting, isError, onChangeMethod }: Props) => {
  const { t } = useTranslation('codeDelivery');
  return (
    <>
      <Form
        type="ldap"
        onSubmit={onSubmit}
        submitting={submitting}
        isError={isError}
      />
      <div className="ldap-form__links">
        <PdsLink
          data-testid="login-form-root-login-link"
          as={Link}
          onClick={onChangeMethod}
        >
          {t('login.form.ldap.links.root')}
        </PdsLink>
      </div>
    </>
  );
};

export default LdapForm;
