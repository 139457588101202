import changeStatus from './changeStatus';
import noopStatus from './noopStatus';
import nodeGroup from './nodeGroup';
import operatingSystem from './operatingSystem';
import peMasterServer from './peMasterServer';
import factValue from './factValue';

export default {
  changeStatus,
  noopStatus,
  nodeGroup,
  operatingSystem,
  peMasterServer,
  factValue,
};
