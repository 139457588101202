import { RbacGroupV1 } from '@utils/api/cd4pe';
import { GroupListActions } from './actions';
import * as at from './actionTypes';

export interface GroupListState {
  getGroupsLoading: boolean;
  groups: RbacGroupV1[];
  getGroupsError: null | string;
  confirmDeleteId: null | number;
  deleteGroupLoading: boolean;
  deleteGroupError: null | string;
  filteredGroups: RbacGroupV1[];
  query: string;
  sort: { direction: string; sortDataKey: 'name' | 'description' };
}

export const groupListDefaultState: GroupListState = {
  getGroupsLoading: false,
  groups: [],
  getGroupsError: null,
  confirmDeleteId: null,
  deleteGroupLoading: false,
  deleteGroupError: null,
  filteredGroups: [],
  query: '',
  sort: {
    direction: 'asc',
    sortDataKey: 'name',
  },
};

export const reducer = (
  state: GroupListState,
  action: GroupListActions,
): GroupListState => {
  switch (action.type) {
    case at.GET_GROUPS_LOADING:
      return {
        ...state,
        getGroupsLoading: true,
      };
    case at.GET_GROUPS_COMPLETE:
      return {
        ...state,
        getGroupsLoading: false,
        groups: action.payload.groups,
        getGroupsError: null,
      };
    case at.GET_GROUPS_ERROR:
      return {
        ...state,
        getGroupsLoading: false,
        groups: [],
        getGroupsError: action.payload,
        filteredGroups: [],
        confirmDeleteId: null,
      };
    case at.DELETE_GROUPS_LOADING:
      return {
        ...state,
        deleteGroupLoading: true,
      };
    case at.DELETE_GROUPS_COMPLETE:
      return {
        ...state,
        deleteGroupLoading: false,
        deleteGroupError: null,
      };
    case at.DELETE_GROUPS_ERROR:
      return {
        ...state,
        deleteGroupLoading: false,
        deleteGroupError: action.payload,
      };
    case at.FILTER_GROUPS: {
      return {
        ...state,
        query: action.payload.query ?? state.query,
        filteredGroups: action.payload.filteredGroups,
        confirmDeleteId:
          state.confirmDeleteId !== null &&
          action.payload.filteredGroups.find(
            (t) => t.id === state.confirmDeleteId,
          )
            ? state.confirmDeleteId
            : null,
      };
    }
    case at.TOGGLE_CONFIRM_DELETE:
      return {
        ...state,
        confirmDeleteId: action.payload ?? null,
      };
    case at.SORT_GROUPS:
      return {
        ...state,
        groups: action.payload.groups,
        sort: action.payload.sort,
      };
    default:
      return state;
  }
};
