/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Alert as DsAlert } from '@puppet/react-components';
import alerts, { AlertType } from '@state/ui/alerts';
import { useAppDispatch } from '@hooks/redux';

interface Props {
  dismissAfter: number | null;
  type: AlertType;
  uuid: string;
  closeable: boolean;
}

const getAlertType = (alertType: AlertType) => {
  switch (alertType) {
    case 'Success':
      return 'success';
    case 'Error':
      return 'danger';
    case 'Info':
      return 'info';
    case 'Warning':
      return 'warning';
    default:
      throw new Error(`${alertType} is not a valid alert type.`);
  }
};

const Alert = ({
  dismissAfter,
  type,
  uuid,
  closeable,
  children,
}: PropsWithChildren<Props>) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    if (dismissAfter !== null) {
      setTimer(
        setTimeout(
          () => dispatch(alerts.actions.dismissAlert(uuid)),
          dismissAfter,
        ),
      );
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [uuid, dismissAfter]);

  return (
    <DsAlert
      type={getAlertType(type)}
      closeable={closeable}
      onClose={() => dispatch(alerts.actions.dismissAlert(uuid))}
    >
      {children}
    </DsAlert>
  );
};

export default Alert;
