import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  PeIntegrationsV2Service,
  MutationHook,
  QueryHook,
  handleQueryRequest,
  Cd4peApiError,
} from '@utils/api/cd4pe';

const PE_INTEGRATION_LIST_QUERY_TAG = 'peIntegrationList';
const PE_INTEGRATION_QUERY_TAG = 'peIntegration';
const PE_ENVIRONMENTS_QUERY_TAG = 'peIntegrationEnvironments';
const PE_PROTECTED_ENVIRONMENTS_QUERY_TAG =
  'peIntegrationProtectedEnvironments';
const PE_PROTECTED_ENVIRONMENT_CANDIDATES_QUERY_TAG =
  'peIntegrationProtectedEnvironmentCandidates';
const PE_INTEGRATION_LIST_ENVIRONMENT_PREFIXES_QUERY_TAG =
  'peIntegrationEnvironmentPrefixesList';

/**
 * useListPeIntegrationsV2
 */

type UseListPeIntegrationsV2 = QueryHook<
  typeof PeIntegrationsV2Service,
  'listPeIntegrationsV2',
  Cd4peApiError
>;

export type UseListPeIntegrationsV2Result = ReturnType<UseListPeIntegrationsV2>;

export const useListPeIntegrationsV2: UseListPeIntegrationsV2 = (payload) =>
  useQuery([PE_INTEGRATION_LIST_QUERY_TAG, payload.workspaceId], async () =>
    handleQueryRequest(PeIntegrationsV2Service.listPeIntegrationsV2(payload)),
  );

/**
 * useGetPeIntegrationV2
 */

type UseGetPeIntegrationV2 = QueryHook<
  typeof PeIntegrationsV2Service,
  'getPeIntegrationV2',
  Cd4peApiError
>;

export type UseGetPeIntegrationV2Result = ReturnType<UseGetPeIntegrationV2>;

export const useGetPeIntegrationV2: UseGetPeIntegrationV2 = (payload) =>
  useQuery([PE_INTEGRATION_QUERY_TAG, payload.peIntegrationId], async () =>
    handleQueryRequest(PeIntegrationsV2Service.getPeIntegrationV2(payload)),
  );

/**
 * useUpdatePeIntegrationV2
 */

type UseUpdatePeIntegrationV2 = MutationHook<
  typeof PeIntegrationsV2Service,
  'updatePeIntegrationV2',
  Cd4peApiError
>;

export type UseUpdatePeIntegrationV2Result =
  ReturnType<UseUpdatePeIntegrationV2>;

export const useUpdatePeIntegrationV2: UseUpdatePeIntegrationV2 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(
        PeIntegrationsV2Service.updatePeIntegrationV2(payload),
      ),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          PE_INTEGRATION_QUERY_TAG,
          payload.peIntegrationId,
        ]);
      },
    },
  );
};

/**
 * useDeletePeIntegrationV2
 */

type UseDeletePeIntegrationV2 = MutationHook<
  typeof PeIntegrationsV2Service,
  'deletePeIntegrationV2',
  Cd4peApiError
>;

export type UseDeletePeIntgrationV2Result =
  ReturnType<UseDeletePeIntegrationV2>;

export const useDeletePeIntegrationV2: UseDeletePeIntegrationV2 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(
        PeIntegrationsV2Service.deletePeIntegrationV2(payload),
      ),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          PE_INTEGRATION_QUERY_TAG,
          payload.peIntegrationId,
        ]);
        queryClient.invalidateQueries([PE_INTEGRATION_LIST_QUERY_TAG]);
        queryClient.invalidateQueries(
          [PE_PROTECTED_ENVIRONMENTS_QUERY_TAG, payload.peIntegrationId],
          {
            refetchType: 'none',
          },
        );
      },
    },
  );
};

/**
 * useListProtectedEnvironmentsV2
 */

type UseListProtectedEnvironmentsV2 = QueryHook<
  typeof PeIntegrationsV2Service,
  'listProtectedEnvironmentsV2',
  Cd4peApiError
>;

export type UseListProtectedEnvironmentsV2Result =
  ReturnType<UseListProtectedEnvironmentsV2>;

export const useListProtectedEnvironmentsV2: UseListProtectedEnvironmentsV2 = (
  payload,
  options,
) =>
  useQuery(
    [PE_PROTECTED_ENVIRONMENTS_QUERY_TAG, payload.peIntegrationId],
    async () =>
      handleQueryRequest(
        PeIntegrationsV2Service.listProtectedEnvironmentsV2(payload),
      ),
    options,
  );

/**
 * useDeleteProtectedEnvironmentV2
 */

type UseDeleteProtectedEnvironmentV2 = MutationHook<
  typeof PeIntegrationsV2Service,
  'deleteProtectedEnvironmentV2',
  Cd4peApiError
>;

export type UseDeleteProtectedEnvironmentV2Result =
  ReturnType<UseDeleteProtectedEnvironmentV2>;

export const useDeleteProtectedEnvironmentV2: UseDeleteProtectedEnvironmentV2 =
  () => {
    const queryClient = useQueryClient();
    return useMutation(
      (payload) =>
        handleQueryRequest(
          PeIntegrationsV2Service.deleteProtectedEnvironmentV2(payload),
        ),
      {
        onSuccess: (_, payload) => {
          queryClient.invalidateQueries([
            PE_PROTECTED_ENVIRONMENTS_QUERY_TAG,
            payload.peIntegrationId,
          ]);
        },
      },
    );
  };

/**
 * useRegenerateTokenV2
 */

type UseRegenerateTokenV2 = MutationHook<
  typeof PeIntegrationsV2Service,
  'regenerateTokenV2',
  Cd4peApiError
>;

export type UseRegenerateTokenV2Result = ReturnType<UseRegenerateTokenV2>;

export const useRegenerateTokenV2: UseRegenerateTokenV2 = () =>
  useMutation((payload) =>
    handleQueryRequest(PeIntegrationsV2Service.regenerateTokenV2(payload)),
  );

/**
 * useCreateApprovalGroupsV2
 */

type UseBulkCreateApprovalGroupsV2 = MutationHook<
  typeof PeIntegrationsV2Service,
  'bulkCreateApprovalGroupsV2',
  Cd4peApiError
>;

export type UseBulkCreateApprovalGroupsV2Result =
  ReturnType<UseBulkCreateApprovalGroupsV2>;

export const useBulkCreateApprovalGroupsV2: UseBulkCreateApprovalGroupsV2 =
  () => {
    return useMutation((payload) =>
      handleQueryRequest(
        PeIntegrationsV2Service.bulkCreateApprovalGroupsV2(payload),
      ),
    );
  };

/**
 * useDeleteApprovalGroupsV2
 */

type UseBulkDeleteApprovalGroupsV2 = MutationHook<
  typeof PeIntegrationsV2Service,
  'bulkDeleteApprovalGroupsV2',
  Cd4peApiError
>;

export type UseBulkDeleteApprovalGroupsV2Result =
  ReturnType<UseBulkDeleteApprovalGroupsV2>;

export const useBulkDeleteApprovalGroupsV2: UseBulkDeleteApprovalGroupsV2 =
  () => {
    return useMutation((payload) =>
      handleQueryRequest(
        PeIntegrationsV2Service.bulkDeleteApprovalGroupsV2(payload),
      ),
    );
  };

/**
 * useListEnvironmentsV2
 */

type UseListEnvironmentsV2 = QueryHook<
  typeof PeIntegrationsV2Service,
  'listEnvironmentsV2',
  Cd4peApiError
>;

export const useListEnvironmentsV2: UseListEnvironmentsV2 = (
  payload,
  options,
) =>
  useQuery(
    [PE_ENVIRONMENTS_QUERY_TAG, payload.peIntegrationId],
    () =>
      handleQueryRequest(PeIntegrationsV2Service.listEnvironmentsV2(payload)),
    options,
  );

/**
 * useListProtectedEnvironmentCandidatesV2
 */

type UseListProtectedEnvironmentCandidatesV2 = QueryHook<
  typeof PeIntegrationsV2Service,
  'listProtectedEnvironmentCandidatesV2',
  Cd4peApiError
>;

export const useListProtectedEnvironmentCandidatesV2: UseListProtectedEnvironmentCandidatesV2 =
  (payload) =>
    useQuery(
      [PE_PROTECTED_ENVIRONMENT_CANDIDATES_QUERY_TAG, payload.peIntegrationId],
      () =>
        handleQueryRequest(
          PeIntegrationsV2Service.listProtectedEnvironmentCandidatesV2(payload),
        ),
    );

/**
 * useCreateProtectedEnvironmentV2
 */

type UseCreateProtectedEnvironmentV2 = MutationHook<
  typeof PeIntegrationsV2Service,
  'createProtectedEnvironmentV2',
  Cd4peApiError
>;

export const useCreateProtectedEnvironmentV2: UseCreateProtectedEnvironmentV2 =
  () => {
    const queryClient = useQueryClient();
    return useMutation(
      (payload) =>
        handleQueryRequest(
          PeIntegrationsV2Service.createProtectedEnvironmentV2(payload),
        ),
      {
        onSuccess: (_, payload) => {
          queryClient.invalidateQueries([
            PE_PROTECTED_ENVIRONMENT_CANDIDATES_QUERY_TAG,
            payload.peIntegrationId,
          ]);
        },
      },
    );
  };

/**
 * useListEnvironmentPrefixesV2
 */

type UseListEnvironmentPrefixesV2 = QueryHook<
  typeof PeIntegrationsV2Service,
  'listEnvironmentPrefixesV2',
  Cd4peApiError
>;

export const useListEnvironmentPrefixesV2: UseListEnvironmentPrefixesV2 = (
  payload,
  options,
) =>
  useQuery(
    [
      PE_INTEGRATION_LIST_ENVIRONMENT_PREFIXES_QUERY_TAG,
      payload.peIntegrationId,
    ],
    async () =>
      handleQueryRequest(
        PeIntegrationsV2Service.listEnvironmentPrefixesV2(payload),
      ),
    options,
  );
