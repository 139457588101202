import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button, Text } from '@puppet/react-components';
import {
  AppEventV1,
  DeploymentAppEventV1,
  PEImpactAnalysisEventV1,
  VmJobInstanceEventV1,
} from '@puppet/cd4pe-client-ts';
import useWorkspaceName from '@hooks/useWorkspaceName';
import ConditionalRender from '@components/ConditionalRender';
import { LINKS } from 'src/routes';
import {
  EVENT_TYPE_DEPLOYMENT,
  EVENT_TYPE_IMPACT_ANALYSIS,
  EVENT_TYPE_JOB,
  EventStatus,
  getEventStatus,
} from '../../../../utils';
import Event from '../Event/Event';
import StageBadge from '../StageBadge';
import RerunJobModal from '../RerunJobModal';

interface Props {
  name: string;
  events: AppEventV1[];
}

const StageEvent = ({ name, events }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const [isOpen, setIsOpen] = useState(true);
  const [rerunModalData, setRerunModalData] = useState<
    VmJobInstanceEventV1 | undefined
  >(undefined);

  const onCloseRerunModal = () => {
    setRerunModalData(undefined);
  };

  return (
    <div className={classnames('stage-event')}>
      <div className="stage-event__details">
        <div className="stage-event__status">
          <Text size="small">{name}</Text>
          <StageBadge events={events} />
        </div>
        <div className="stage-event__icon">
          <Button
            type="transparent"
            icon={isOpen ? 'chevron-up' : 'chevron-down'}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </div>
      </div>
      <ConditionalRender enable={isOpen}>
        {events.map(
          (
            stageEvent:
              | VmJobInstanceEventV1
              | DeploymentAppEventV1
              | PEImpactAnalysisEventV1,
          ) => {
            if (stageEvent.eventType === EVENT_TYPE_JOB) {
              const vmJobInstanceEvent = stageEvent as VmJobInstanceEventV1;
              const status = getEventStatus(vmJobInstanceEvent);

              const onClickActionButton = () => {
                setRerunModalData(vmJobInstanceEvent);
              };

              return (
                <Event
                  key={vmJobInstanceEvent.vmJobInstanceId}
                  id={vmJobInstanceEvent.vmJobInstanceId}
                  name={vmJobInstanceEvent.jobName}
                  icon="clipboard"
                  status={status as EventStatus}
                  statusTranslationKey="viewPipeline.events.table.row.details.job.status"
                  startTime={vmJobInstanceEvent.jobStartTime}
                  environment={vmJobInstanceEvent.branch}
                  eventLink={LINKS.codeDelivery.jobDetails({
                    path: {
                      workspace: workspaceName,
                      jobInstanceId: vmJobInstanceEvent.vmJobInstanceId!,
                    },
                  })}
                  actionButton={
                    <Button
                      type="secondary"
                      icon="refresh"
                      onClick={onClickActionButton}
                      data-testid={`rerun-job-button-${vmJobInstanceEvent.vmJobInstanceId}`}
                    >
                      {t('viewPipeline.events.table.row.details.job.rerun')}
                    </Button>
                  }
                />
              );
            }

            if (stageEvent.eventType === EVENT_TYPE_DEPLOYMENT) {
              const deployEvent = stageEvent as DeploymentAppEventV1;
              return (
                <Event
                  key={deployEvent.deploymentId}
                  id={deployEvent.deploymentId}
                  icon="rocket"
                  eventLink={LINKS.cd4pe.viewDeployment({
                    path: {
                      workspace: workspaceName,
                      id: deployEvent.deploymentId!,
                    },
                  })}
                  startTime={deployEvent.deploymentStartTime}
                  status={deployEvent.deploymentState}
                  statusTranslationKey="viewPipeline.events.table.row.details.deployment.status"
                  environment={deployEvent.targetBranch}
                  peInstance={deployEvent.puppetEnterpriseServerName}
                  policy={deployEvent.deploymentPlanName}
                  href
                />
              );
            }

            if (stageEvent.eventType === EVENT_TYPE_IMPACT_ANALYSIS) {
              const impactAnalysisEvent = stageEvent as PEImpactAnalysisEventV1;
              const environmentsString = Array.isArray(
                impactAnalysisEvent.environments,
              )
                ? impactAnalysisEvent.environments.length
                : t(
                    'viewPipeline.events.table.row.details.environmentsAnalyzed.all',
                  );

              return (
                <Event
                  key={impactAnalysisEvent.eventId}
                  id={impactAnalysisEvent.impactAnalysisId}
                  icon="basics"
                  eventLink={LINKS.cd4pe.viewImpactAnalysis({
                    path: {
                      workspace: workspaceName,
                      id: impactAnalysisEvent.impactAnalysisId!,
                    },
                  })}
                  startTime={impactAnalysisEvent.startTime}
                  status={impactAnalysisEvent.state}
                  statusTranslationKey="viewPipeline.events.table.row.details.impactAnalysis.status"
                  environmentsAnalyzed={environmentsString}
                  href
                />
              );
            }

            // TODO Validate legacy event types below

            // if (stageEvent.eventType === LEGACY_EVENT_TYPE_ROLLING_DEPLOYMENT) {
            //   const deployEvent = stageEvent as AppDeployEventV1;
            //   return (
            //     <Event
            //       key={deployEvent.deploymentId}
            //       id={deployEvent.deploymentId ?? 0}
            //       startTime={deployEvent.deploymentStartTime}
            //       status={deployEvent.deploymentState}
            //       environment={deployEvent.nodeGroupName}
            //       peInstance={deployEvent.puppetEnterpriseServerName}
            //       policy={deployEvent.deploymentType}
            //     />
            //   );
            // }

            // if (stageEvent.eventType === LEGACY_EVENT_TYPE_MODULE_DEPLOYMENT) {
            //   const deployEvent = stageEvent as DeploymentAppEventV1;
            //   return (
            //     <Event
            //       key={deployEvent.deploymentId}
            //       id={deployEvent.moduleDeploymentId}
            //       startTime={deployEvent.startTime}
            //       status={deployEvent.state}
            //       environment={
            //         deployEvent.environment.nodeGroupName || stageEvent.branch
            //       }
            //       peInstance={deployEvent.environment.peCredentialsId.name}
            //     />
            //   );
            // }

            return null;
          },
        )}
      </ConditionalRender>
      {rerunModalData && (
        <RerunJobModal
          key={rerunModalData.vmJobInstanceId}
          isOpen={!!rerunModalData}
          onClose={onCloseRerunModal}
          stageEvent={rerunModalData}
        />
      )}
    </div>
  );
};

export default StageEvent;
