import React from 'react';
import NodeExplorer from '@inventory/views/NodeExplorer';
import NodeDetails from '@inventory/views/NodeDetails';
import StoredViews from '@inventory/views/StoredViews';
import { Route } from 'react-router-dom';
import { ROUTE_TYPES } from '@utils/constants';
import Layout from '@components/Layout';
import { getSegment } from 'src/routes';

const Inventory = (
  <Route
    path={getSegment('workspace')}
    element={<Layout routeType={ROUTE_TYPES.inventory} />}
  >
    <Route path={getSegment('workspace.inventory')}>
      <Route path={getSegment('workspace.inventory.nodes')}>
        <Route index element={<NodeExplorer />} />
        <Route
          path={getSegment('workspace.inventory.nodes.savedViews')}
          element={<StoredViews />}
        />
        <Route
          path={getSegment('workspace.inventory.nodes.view')}
          element={<NodeExplorer />}
        />
        <Route path={getSegment('workspace.inventory.nodes.source')}>
          <Route
            path={getSegment('workspace.inventory.nodes.source.id')}
            element={<NodeDetails />}
          />
        </Route>
      </Route>
    </Route>
  </Route>
);

export default Inventory;
