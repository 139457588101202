import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text, Form, Loading } from '@puppet/react-components';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import PageLayout from '@components/PageLayout';
import Breadcrumbs from '@components/Breadcrumbs';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { LINKS } from 'src/routes';
import { useGenerateValueReportV1 } from '@services/cd4pe/valueReporting';
import { useGetLicenseV1 } from '@services/cd4pe/license';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import Cd4peError from '@components/Cd4peError';
import ConditionalRender from '@components/ConditionalRender';
import { ValueReportV1 } from '@puppet/cd4pe-client-ts';
import ValueCard from './components/ValueCard/ValueCard';
import ValueChart from './components/ValueChart';
import InfoPopup from './components/InfoPopup';

const dateFormat = 'yyyy-MM-dd';
const getDefaultStart = () => format(subDays(new Date(), 7), dateFormat);
const getDefaultEnd = () => format(new Date(), dateFormat);

type FormValues = {
  startDate: string;
  endDate: string;
};

const VALUES_OVER_TIME_KEYS: (keyof ValueReportV1)[] = [
  'nodesPatched',
  'tasksRan',
  'plansRan',
  'correctiveChanges',
  'intentionalChanges',
  'impactAnalysisResourceChanges',
];

const AUTOMATED_KEYS: (keyof ValueReportV1)[] = [
  'avgModulesDeployed',
  'avgResourcesManaged',
  'avgNodesCheckedForPatches',
];

const CHART_KEYS: (keyof ValueReportV1)[] = [
  'patchingSavedTimeHours',
  'tasksSavedTimeHours',
  'plansSavedTimeHours',
  'correctiveChangesSavedTimeHours',
  'intentionalChangesSavedTimeHours',
];

const Activity = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [formValues, setFormValues] = useState({
    startDate: getDefaultStart(),
    endDate: getDefaultEnd(),
  });
  const [impactAnalysisPopover, setImpactAnalysisPopover] = useState(false);

  const { data, isLoading, error, fetchStatus } = useGenerateValueReportV1(
    {
      workspaceId,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
    },
    {
      enabled: !startDateError && !endDateError,
    },
  );

  const license = useGetLicenseV1(undefined);

  const breadcrumbs = [
    {
      displayName: workspaceName,
    },
    {
      displayName: t('activity.breadcrumb.report'),
      linkDestination: LINKS.activity.root({
        path: { workspace: workspaceName },
      }),
    },
  ];

  const onFormChange = (_: keyof FormValues, values: FormValues) => {
    setStartDateError(!isValid(new Date(values.startDate)));
    setEndDateError(!isValid(new Date(values.endDate)));

    if (isAfter(new Date(values.startDate), new Date(values.endDate))) {
      setFormValues({
        startDate: values.endDate,
        endDate: values.endDate,
      });
      return;
    }

    setFormValues(values);
  };

  const chartValues = CHART_KEYS.map((key: keyof ValueReportV1) => {
    return {
      name: t(`activity.chart.xaxis.${key}`),
      value: data?.[key] ?? 0,
    };
  });

  const isLoadingData = isLoading && fetchStatus !== 'idle';
  const invalidDateError = t('activity.filters.error.invalidDate');

  const isFeatureLocked = (key: keyof ValueReportV1) => {
    switch (key) {
      case 'impactAnalysisResourceChanges':
        return (
          !license.isLoading &&
          (license.error?.status === 404 ||
            !license.data?.included?.some(
              (included) => included.attributes?.code === 'IMPACT_ANALYSIS',
            ))
        );
      default:
        return false;
    }
  };

  const onValueClickHandler = (key: keyof ValueReportV1) => {
    if (key === 'impactAnalysisResourceChanges' && isFeatureLocked(key)) {
      setImpactAnalysisPopover(true);
    }
  };

  const renderIAPopover = (key: keyof ValueReportV1) => {
    if (key === 'impactAnalysisResourceChanges' && impactAnalysisPopover) {
      return (
        <div className="activity-unlock-ia-popup-container">
          <InfoPopup onClose={() => setImpactAnalysisPopover(false)} />
        </div>
      );
    }

    return null;
  };

  const getDataValue = (
    valueData: ValueReportV1 | undefined,
    key: keyof ValueReportV1,
  ) => {
    // Its possible that the values are returned before the license check is finished. When we are loading the license,
    // we should continue showing the initial character instead of 0 to indicate its still in an indeterminate state.
    if (key === 'impactAnalysisResourceChanges' && license.isLoading) {
      return '-';
    }

    return valueData?.[key] ?? '-';
  };

  return (
    <PageLayout className="activity-page">
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('activity.header')}</Heading>
        <Text color="medium">{t('activity.description')}</Text>
      </PageLayout.Header>
      <PageLayout.Content className="page-layout-content--full-width activity-page-content">
        <Cd4peError error={error} />
        <div className="activity-page-configuration">
          <Form
            className="activity-page-configuration__form"
            values={formValues}
            onChange={onFormChange}
          >
            <Form.Field
              type="date"
              name="startDate"
              max={formValues.endDate}
              label={t('activity.filters.startDate.label')}
              required
              error={startDateError && invalidDateError}
            />
            <Form.Field
              type="date"
              name="endDate"
              max={getDefaultEnd()}
              label={t('activity.filters.endDate.label')}
              required
              error={endDateError && invalidDateError}
            />
          </Form>
          <ConditionalRender enable={isLoadingData}>
            <Loading className="activity-page-configuration__loading" />
          </ConditionalRender>
        </div>
        <div className="activity-page-section">
          <Heading as="h4">{t('activity.valuesOverTime.heading')}</Heading>
          <div className="activity-page-cards">
            {VALUES_OVER_TIME_KEYS.map((key) => (
              <div>
                <ValueCard
                  key={key}
                  data-testid={`value-card-${key}`}
                  title={t(`activity.valuesOverTime.card.title.${key}`)}
                  value={getDataValue(data, key)}
                  isFeatureLocked={isFeatureLocked(key)}
                  onClick={() => onValueClickHandler(key)}
                />
                {renderIAPopover(key)}
              </div>
            ))}
          </div>
        </div>
        <div className="activity-page-section">
          <Heading as="h4">{t('activity.timeSaved.heading')}</Heading>
          <ValueChart data={chartValues} />
        </div>
        <div className="activity-page-section">
          <Heading as="h4">{t('activity.automating.heading')}</Heading>
          <div className="activity-page-cards">
            {AUTOMATED_KEYS.map((key) => (
              <ValueCard
                key={key}
                data-testid={`automation-card-${key}`}
                title={t(`activity.automatedValues.card.title.${key}`)}
                value={data?.[key] ?? '-'}
              />
            ))}
          </div>
        </div>
      </PageLayout.Content>
    </PageLayout>
  );
};

export default Activity;
