import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OptionsArray } from 'utils/en.propTypes';
import { useTranslation } from 'react-i18next';
import { Button } from '@puppet/react-components';
import FactPicker from 'inventory/components/FactPicker';
import FactListItem from './FactListItem';
import './ColumnPicker.scss';

const FactList = ({
  factOptions,
  addFact,
  onRemoveItem,
  onClickItem,
  visibleFacts,
  onClickApply,
  onClickReset,
  onClickCancel,
  factLimitSize,
}) => {
  const [filteredFacts, setFilteredFacts] = useState(factOptions);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation('inventory');

  useEffect(() => {
    const newFilteredFacts = factOptions.filter(({ value }) => {
      return value.includes(searchTerm);
    });
    setFilteredFacts(newFilteredFacts);
  }, [searchTerm, factOptions]);

  const handleReset = () => {
    setSearchTerm('');
    onClickReset();
  };

  const handleCancel = () => {
    setSearchTerm('');
    onClickCancel();
  };
  return (
    <div className="fl-container">
      <ul role="listbox" tabIndex={0} className="fl-menu-list-inner">
        {visibleFacts.map(({ factname, isActive, defaultFact }, index) => {
          const keyId = `${factname}-${index}`;
          return (
            <FactListItem
              id={{ factname }}
              key={keyId}
              isActive={isActive}
              onClick={() => onClickItem(factname)}
              onRemove={() => onRemoveItem(factname)}
              defaultFact={defaultFact}
              isLocked={index === 0}
            >
              {factname}
            </FactListItem>
          );
        })}
      </ul>
      <FactPicker
        factOptions={filteredFacts.slice(0, factLimitSize)}
        onClick={(fact) => {
          // Only clear the search text if it is accepted (not a duplicate or invalid fact name).
          if (addFact(fact)) {
            setSearchTerm('');
          }
        }}
        onFilter={setSearchTerm}
        isOpen={searchTerm}
        totalFactOptions={filteredFacts.length}
        displayFooter={filteredFacts.length > factLimitSize}
      />
      <div className="fl-action-button-container">
        <Button onClick={handleReset} className="fl-reset-button" type="text">
          {t('factList.button.reset')}
        </Button>
        <div className="fl-buttons-container">
          <Button onClick={handleCancel} type="text">
            {t('factList.button.cancel')}
          </Button>
          <Button data-testid="onApply" onClick={onClickApply}>
            {t('factList.button.apply')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FactList;

FactList.propTypes = {
  factOptions: OptionsArray.isRequired,
  addFact: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  visibleFacts: PropTypes.arrayOf(
    PropTypes.shape({
      factname: PropTypes.string,
      isActive: PropTypes.bool,
      defaultFact: PropTypes.bool,
    }),
  ).isRequired,
  onClickApply: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  factLimitSize: PropTypes.number.isRequired,
};
