import * as vcsAt from '@utils/api/cd4pe/vcs/actionTypes';
import {
  StdVcsBranch,
  StdVcsRepo,
  StdVcsSource,
} from '@utils/api/cd4pe/vcs/actions';
import { ADD_CODE_PROJECT_FORM_ACTION_TYPES } from '@codeDelivery/components/AddCodeProjectForm';
import { Cd4peApiError } from '@utils/api/cd4pe';
import * as at from './actionTypes';
import { NewModuleActions } from './actions';

export interface NewModuleState {
  getSourcesLoading: boolean;
  getSourcesError: string | null;
  getReposLoading: boolean;
  getReposError: string | null;
  getBranchesLoading: boolean;
  getBranchesError: string | null;
  saveModuleLoading: boolean;
  saveModuleError: Cd4peApiError | string | null;
  saveModuleWebhookError: boolean;
  sources: StdVcsSource[];
  selectedSource: string;
  repositories: StdVcsRepo[];
  selectedRepository: string;
  branches: StdVcsBranch[];
  mainBranch: StdVcsBranch | null;
  createMainBranch: boolean;
  selectedBranch: string;
  name: string;
  saveModuleSuccess: boolean;
  currentModalClosed: boolean;
  newModuleName?: null | string;
}

export const defaultNewModuleState: NewModuleState = {
  getSourcesLoading: false,
  getSourcesError: null,
  getReposLoading: false,
  getReposError: null,
  getBranchesLoading: false,
  getBranchesError: null,
  saveModuleLoading: false,
  saveModuleError: null,
  saveModuleWebhookError: false,

  sources: [],
  selectedSource: '',
  repositories: [],
  selectedRepository: '',
  branches: [],
  mainBranch: null,
  createMainBranch: true,
  selectedBranch: '',
  name: '',
  saveModuleSuccess: false,
  currentModalClosed: false,
  newModuleName: null,
};

export const reducer = (
  state: NewModuleState,
  action: NewModuleActions,
): NewModuleState => {
  switch (action.type) {
    case vcsAt.GET_SOURCES_START:
      return {
        ...state,
        getSourcesLoading: true,
      };
    case vcsAt.GET_SOURCES_COMPLETE:
      return {
        ...state,
        getSourcesLoading: false,
        sources: action.payload,
      };
    case vcsAt.GET_SOURCES_ERROR:
      return {
        ...state,
        getSourcesLoading: false,
        getSourcesError: action.payload,
      };
    case vcsAt.GET_REPOS_START:
      return {
        ...state,
        getReposLoading: true,
      };
    case vcsAt.GET_REPOS_COMPLETE:
      return {
        ...state,
        getReposLoading: false,
        repositories: action.payload,
      };
    case vcsAt.GET_REPOS_ERROR:
      return {
        ...state,
        getReposLoading: false,
        getReposError: action.payload,
      };
    case vcsAt.GET_BRANCHES_START:
      return {
        ...state,
        getBranchesLoading: true,
      };
    case vcsAt.GET_BRANCHES_COMPLETE:
      return {
        ...state,
        getBranchesLoading: false,
        branches: action.payload,
        mainBranch:
          state.mainBranch ||
          action.payload.find((branch) => branch.actualValue.name === 'main') ||
          null,
      };
    case vcsAt.GET_BRANCHES_ERROR:
      return {
        ...state,
        getBranchesLoading: false,
        getBranchesError: action.payload,
      };
    case at.SAVE_MODULE_START:
      return {
        ...state,
        saveModuleLoading: true,
      };
    case at.SAVE_MODULE_COMPLETE:
      return {
        ...state,
        saveModuleLoading: false,
        saveModuleSuccess: true,
        newModuleName: action.payload.name,
      };
    case at.SAVE_MODULE_ERROR:
      return {
        ...state,
        saveModuleLoading: false,
        saveModuleSuccess: false,
        saveModuleError: action.payload,
      };
    case at.SAVE_MODULE_WEBHOOK_ERROR:
      return {
        ...state,
        saveModuleLoading: false,
        saveModuleSuccess: false,
        saveModuleWebhookError: true,
        currentModalClosed: false,
      };
    case ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_SOURCE:
      return {
        ...state,
        selectedSource: action.payload,
        repositories: [],
        selectedRepository: '',
        branches: [],
        mainBranch: null,
        selectedBranch: '',
        saveModuleError: null,
        getReposError: null,
        name: '',
      };
    case ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_REPOSITORY:
      return {
        ...state,
        selectedRepository: action.payload,
        branches: [],
        mainBranch: null,
        selectedBranch: '',
        saveModuleError: null,
        getBranchesError: null,
        name: '',
      };
    case at.CREATE_MAIN_BRANCH:
      return {
        ...state,
        createMainBranch: action.payload,
      };
    case ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_BRANCH:
      return {
        ...state,
        selectedBranch: action.payload,
      };
    case ADD_CODE_PROJECT_FORM_ACTION_TYPES.NAME:
      return {
        ...state,
        name: action.payload,
      };
    case at.CLOSE_MODAL:
      return {
        ...state,
        currentModalClosed: true,
      };
    default:
      throw new Error();
  }
};
