import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import useWorkspaceName from '@hooks/useWorkspaceName';
import JobTemplate from '@codeDelivery/components/JobTemplate';
import { JobTemplateFormValues } from '@codeDelivery/components/JobTemplate/actions';
import { useCreateTemplateV1 } from '@services/cd4pe/jobs';
import { LINKS } from 'src/routes';
import { useListCapabilitiesV1 } from '@services/cd4pe/hardware';
import useGetSharedDockerImageSettingsV1 from '@services/cd4pe/docker';

const NewJobTemplate = () => {
  const workspaceId = useWorkspaceDomain();
  const workspace = useWorkspaceName();
  const navigate = useNavigate();
  const createTemplate = useCreateTemplateV1();
  const capabilities = useListCapabilitiesV1({ workspaceId });
  const dockerContainer = useGetSharedDockerImageSettingsV1(undefined);

  const jobsPageUrl = LINKS.codeDelivery.listJobTemplates({
    path: { workspace },
  });

  useEffect(() => {
    if (createTemplate.isSuccess) {
      navigate(jobsPageUrl);
    }
  }, [createTemplate.isSuccess, jobsPageUrl, navigate]);

  const breadcrumbs = [
    {
      translationKey: 'newJobTemplate.breadcrumbs.jobs',
      linkDestination: jobsPageUrl,
    },
    { translationKey: 'newJobTemplate.breadcrumbs.editJob' },
  ];

  const onSave = (formValues: JobTemplateFormValues) => {
    if (!workspaceId || !dockerContainer.data) {
      return;
    }

    createTemplate.mutate({
      workspaceId,
      requestBody: {
        workspaceId,
        description: formValues.description,
        name: formValues.name,
        manifest: {
          build: formValues.commands,
          onSuccess: formValues.commandsSuccess,
          onError: formValues.commandsError,
        },
        config: {
          sharedHardware: formValues.runOnSharedHardware,
          buildCapabilities: formValues.hardwareCapabilities,
          buildVars: formValues.envVars,
          vmArgs: formValues.dockerArgs,
          vmImage: formValues.imageName,
          useDefaultImage: formValues.runOnDefaultImage,
        },
      },
    });
  };

  return (
    <JobTemplate
      breadcrumbs={breadcrumbs}
      titleTranslationKey="newJobTemplate.page.title"
      subtitleTranslationKey="newJobTemplate.page.subtitle"
      defaultContainer={dockerContainer.data}
      hardwareCapabilities={capabilities.data?.jobHardwareCapabilities}
      submitting={createTemplate.isLoading}
      formError={createTemplate.error}
      onCancel={() => navigate(jobsPageUrl)}
      onSubmit={onSave}
      loadError={dockerContainer.error || capabilities.error}
      submitTranslationKey="newJobTemplate.form.submit"
    />
  );
};

export default NewJobTemplate;
