import { useListIntegrationsV1 } from '@services/cd4pe/vcs';
import { VcsProviderV1 } from '@puppet/cd4pe-client-ts';
import {
  getCommitLink as getCommitUrl,
  getBranchLink as getBranchUrl,
  getRepoLink as getRepoUrl,
  getRepoHost,
} from '@codeDelivery/utils/vcsLinkHelper';
import { useMemo } from 'react';
import useWorkspaceDomain from './useWorkspaceDomain';

const useVcsLinks = () => {
  const workspaceId = useWorkspaceDomain();
  const { data: vcsIntegrations, error } = useListIntegrationsV1({
    workspaceId,
  });

  const hostUrls = useMemo(() => {
    const urlMap = new Map<string, string>();
    if (vcsIntegrations?.vcsIntegrations) {
      vcsIntegrations.vcsIntegrations.forEach(
        ({ provider, connected, host }) => {
          if (connected) {
            urlMap.set(provider, host!);
          }
        },
      );
    }

    return urlMap;
  }, [vcsIntegrations?.vcsIntegrations]);

  const getCommitLink = (
    provider: VcsProviderV1,
    repoOwner: string,
    repoName: string,
    repoId: string,
    commitId: string,
  ) => {
    let url = null;

    const repoHost = getRepoHost(provider, hostUrls) || '';
    url = getCommitUrl(
      provider,
      repoOwner,
      repoName,
      repoId,
      repoHost,
      commitId,
    );

    return url;
  };

  const getBranchLink = (
    provider: VcsProviderV1,
    repoOwner: string,
    repoName: string,
    repoId: string,
    branch: string,
  ) => {
    let url = null;

    const repoHost = getRepoHost(provider, hostUrls) || '';
    url = getBranchUrl(provider, repoOwner, repoName, repoId, repoHost, branch);

    return url;
  };

  const getRepoLink = (
    provider: VcsProviderV1,
    repoOwner: string,
    repoName: string,
    repoId: string,
  ) => {
    let url = null;

    const repoHost = getRepoHost(provider, hostUrls) || '';
    url = getRepoUrl(provider, repoOwner, repoName, repoId, repoHost);

    return url;
  };

  return { getCommitLink, getBranchLink, getRepoLink, error };
};

export default useVcsLinks;
