import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Heading, Button, Text } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import ModulePermissionsSubsetModal from './ModulePrivilegesSubsetModal';
import ControlReposPermissionsSubsetModal from './ControlReposPrivilegesSubsetModal';
import {
  SUBSETS_SUPPORTED_ACTIONS,
  SUBSETS_SUPPORTED_CATEGORIES,
} from '../utils/constants';
import { PrivilegeFormCategory, stripSubsetPrefix } from '../utils/utils';

type PrivilegeCategoryProps = {
  editable: boolean;
  category: PrivilegeFormCategory;
  setSubsets: (newSubsets: string[]) => void;
};

const PrivilegeCategory = ({
  children,
  editable,
  category,
  setSubsets,
}: PropsWithChildren<PrivilegeCategoryProps>) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const { name, subsets = [], actions } = category;
  const categoryDisplayName =
    name === 'ControlRepos' ? t('privilege.controlrepos.label') : name;

  const willSaveSubset = () => {
    return actions.some(
      (action) =>
        action.selected &&
        action.usesSubsets &&
        SUBSETS_SUPPORTED_ACTIONS.includes(action.type),
    );
  };
  const strippedSubsets = [...subsets].map((subset) =>
    stripSubsetPrefix(subset),
  );
  const setSubsetsWithId = (newSubsets: string[]) => {
    setSubsets(newSubsets.map((subset) => `${workspaceId}:${subset}`));
  };

  return (
    <div className="privilege-category__container">
      <div className="privilege-category__header_container">
        <div className="privilege-category__title_container">
          <Heading as="h5" className="privilege-category__title">
            {t(`privilege.${name.toLowerCase()}.label`)}
          </Heading>
        </div>
        {editable && SUBSETS_SUPPORTED_CATEGORIES.includes(name) && (
          <div className="privilege-category__subsets_button">
            <Text className="privilege-category__button_text">
              {t('privilege.subset.subset.category.label', {
                name: categoryDisplayName,
              })}
            </Text>
            <Button
              name={`${name}-subset-modal-button`}
              type="transparent"
              icon="pencil"
              aria-label={t('privilege.subset.subset.category.edit.ariaLabel', {
                name: categoryDisplayName,
              })}
              onClick={() => {
                setOpen(true);
                setSelectedCategory(name);
              }}
            />
          </div>
        )}
      </div>

      <div className="privilege-category__actions">{children}</div>

      <ModulePermissionsSubsetModal
        open={open && selectedCategory === 'Modules'}
        setOpen={(isOpen: boolean) => setOpen(isOpen)}
        subsets={strippedSubsets}
        setSubsets={setSubsetsWithId}
      />

      <ControlReposPermissionsSubsetModal
        open={open && selectedCategory === 'ControlRepos'}
        setOpen={(isOpen: boolean) => setOpen(isOpen)}
        subsets={strippedSubsets}
        setSubsets={setSubsetsWithId}
      />
      {SUBSETS_SUPPORTED_CATEGORIES.includes(name) &&
        subsets?.length > 0 &&
        !willSaveSubset() && (
          <Alert type="danger">
            {t('privilege.subset.without.permissions.error')}
            <Alert.Message>
              {t('privilege.subset.permissions.error.message')}
            </Alert.Message>
          </Alert>
        )}
    </div>
  );
};

export default PrivilegeCategory;
