import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@puppet/react-components';
import { Table } from '@puppet/data-grid';
import {
  useListGroupsMembersV1,
  useDeleteGroupMemberV1,
} from '@services/cd4pe/groups';
import Cd4peError from '@components/Cd4peError';
import { stringCompare } from '@utils/compare';
import { LINKS } from 'src/routes';
import useWorkspaceName from '@hooks/useWorkspaceName';

interface Props {
  groupId: number;
}

type SortUsers = {
  direction: string;
  sortDataKey: string;
};

const UserTab = ({ groupId }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const navigate = useNavigate();
  const workspace = useWorkspaceName();
  const [searchValue, setSearchValue] = useState('');
  const [sortUsers, setSortUsers] = useState<SortUsers>({
    direction: 'asc',
    sortDataKey: 'username',
  });
  const [deleteUserId, setDeleteuserId] = useState<number | null>(null);
  const { data: users, error, isLoading } = useListGroupsMembersV1({ groupId });
  const deleteGroupMemberV1 = useDeleteGroupMemberV1();

  const tableColumns = [
    {
      label: t('viewGroup.users.table.header.username'),
      dataKey: 'username',
      sortable: true,
      className: 'group-users-table__cell--truncate',
    },
    {
      label: t('viewGroup.users.table.header.actions'),
      dataKey: 'userId',
      className: 'group-users-table__cell-actions',
      style: { width: '200px', textAlign: 'center' },
      cellRenderer: (props: {
        cellData: number;
        rowData: { username: string };
      }) => {
        if (deleteUserId === props.cellData) {
          return (
            <div className="group-users-table__cell-actions">
              <Button
                aria-label={t('viewGroup.users.table.button.delete.ariaLabel', {
                  username: props.rowData.username,
                })}
                className="group-users-table__cell-actions__button"
                icon="trash"
                type="danger"
                onClick={() => {
                  deleteGroupMemberV1.mutate(
                    {
                      groupId,
                      userId: props.cellData,
                    },
                    {
                      onSettled: () => setDeleteuserId(null),
                    },
                  );
                }}
                data-testid={`delete-group-user-confirm-${props.rowData.username}`}
              >
                {t('viewGroup.users.table.button.delete')}
              </Button>
              <Button
                aria-label={t('viewGroup.users.table.button.cancel.ariaLabel', {
                  username: props.rowData.username,
                })}
                className="group-users-table__cell-actions__button"
                type="secondary"
                onClick={() => setDeleteuserId(null)}
              >
                {t('viewGroup.users.table.button.cancel')}
              </Button>
            </div>
          );
        }
        return (
          <div className="group-users-table__cell-actions">
            <Button
              // aria-label={t('viewGroup.users.table.icon.delete', {
              //   username: props.rowData.username,
              // })}
              className="group-users-table__cell-actions__button"
              icon="trash"
              type="secondary"
              onClick={() => setDeleteuserId(props.cellData)}
              data-testid={`delete-group-user-${props.rowData.username}`}
            >
              {t('viewGroup.users.table.icon.delete', {
                username: props.rowData.username,
              })}
            </Button>
          </div>
        );
      },
    },
  ];

  const tableData =
    users?.groupMembers
      ?.filter(({ username }) =>
        username.toLowerCase().includes(searchValue.toLowerCase()),
      )
      .sort(
        (a, b) =>
          (sortUsers.direction === 'asc' ? 1 : -1) *
          stringCompare(a.username, b.username),
      ) ?? [];

  return (
    <>
      <Cd4peError error={error} />
      <Cd4peError error={deleteGroupMemberV1.error} />
      <div
        className="group-users-table"
        data-testid="group-users-table-container"
      >
        <Table.TableHeader
          search
          searchPlaceholder={t('viewGroup.users.table.searchPlaceholder')}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          actionButtons={[
            {
              label: t('viewGroup.users.table.button.addUser'),
              icon: 'plus',
              type: 'secondary',
              onClick: () =>
                navigate(
                  LINKS.settings.addGroupUsers({
                    path: { workspace, groupId: `${groupId}` },
                  }),
                ),
            },
          ]}
        />
        <Table
          columns={tableColumns}
          loading={isLoading}
          data={tableData}
          onSort={(
            direction: SortUsers['direction'],
            sortDataKey: SortUsers['sortDataKey'],
          ) => setSortUsers({ direction, sortDataKey })}
          sortedColumn={sortUsers}
          data-testid="group-users-table"
        />
      </div>
    </>
  );
};

export default UserTab;
