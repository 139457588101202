export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const GET_USERS_COMPLETE = 'GET_USERS_COMPLETE';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_GROUPS_LOADING = 'GET_GROUPS_LOADING';
export const GET_GROUPS_COMPLETE = 'GET_GROUPS_COMPLETE';
export const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR';

export const ADD_USERS_LOADING = 'ADD_USERS_LOADING';
export const ADD_USERS_COMPLETE = 'ADD_USERS_COMPLETE';
export const ADD_USERS_ERROR = 'ADD_USERS_ERROR';

export const TOGGLE_ALL_USERS_SELECTION = 'TOGGLE_ALL_USERS_SELECTION';
export const TOGGLE_USER_ROW_SELECTION = 'TOGGLE_USER_ROW_SELECTION';

export const TOGGLE_ALL_GROUPS_SELECTION = 'TOGGLE_ALL_GROUPS_SELECTION';
export const TOGGLE_GROUP_ROW_SELECTION = 'TOGGLE_GROUP_ROW_SELECTION';

export const FILTER_USERS = 'FILTER_USERS';

export const SORT_USERS = 'SORT_USERS';
export const SORT_GROUPS = 'SORT_GROUPS';
