import { nanoid } from 'nanoid';
import chartColorPallets from 'inventory/utils/index';
import getPercentages from 'inventory/utils/getPercentages';
import {
  VALUE_LIMIT,
  PUPPET_FACT,
  CHART_LEGEND_LABEL_ELLIPSE_POSITION,
  CHART_LEGEND_LABEL_MAX_LENGTH,
  PUPPETN400,
} from 'utils/constants';
import i18next from '../../../i18n';
// eslint-disable-next-line import/no-cycle
import { puppetFact as cardDefinition } from './definitions/puppetFact';

export const initialCards = [
  {
    id: nanoid(10),
    cardDefinitionName: PUPPET_FACT,
    factname: 'aio_agent_version',
    label: 'aio_agent_version',
    areNodesExcluded: true,
    order: 4,
  },
  {
    id: nanoid(10),
    cardDefinitionName: PUPPET_FACT,
    factname: 'networking.network',
    label: 'networking.network',
    areNodesExcluded: true,
    order: 3,
  },
  {
    id: nanoid(10),
    cardDefinitionName: PUPPET_FACT,
    factname: 'os.family',
    label: 'os.family',
    areNodesExcluded: true,
    order: 2,
  },
  {
    id: nanoid(10),
    cardDefinitionName: PUPPET_FACT,
    factname: 'virtual',
    label: 'virtual',
    areNodesExcluded: true,
    order: 1,
  },
];

export const cardService = {
  getCards(cards) {
    return cards.map((userCard) => {
      const matchedDefinition = cardDefinition.find(
        (cardDef) => cardDef.name === userCard.cardDefinitionName,
      );

      return {
        ...userCard,
        ...matchedDefinition,
      };
    });
  },

  accessData(accessKey, data) {
    return accessKey?.split('.').reduce((o, i) => o[i], data);
  },

  updateCards(newCard, cards, cardDef) {
    const newCards = [
      {
        cardDefinitionName: cardDef,
        id: nanoid(10),
        factname: newCard?.factname,
        label: newCard?.label,
        areNodesExcluded: newCard?.areNodesExcluded,
        // TODO When we add order as an option for the user, this will need to change to handle that
        order: cards.length + 1,
      },
      ...cards,
    ];

    return this.getCards(newCards);
  },

  removeCard(cardID, cards) {
    const newCards = cards.filter(({ id }) => id !== cardID);
    return this.getCards(newCards);
  },

  editCard(editedCard, cards) {
    const editedCards = cards.map((card) => {
      if (card.id !== editedCard.id) {
        return card;
      }
      return {
        ...card,
        areNodesExcluded: editedCard?.areNodesExcluded,
        factname: editedCard?.factname,
        label: editedCard?.label,
      };
    });

    return this.getCards(editedCards);
  },

  /**
   *  Returns the data structure for Chart.jsx component specifically for fact chart data
   * @param {number} totalCount: count of nodes returned with matching fact
   * @param {array} values: [{ value: (string, boolean, number), count: number, __typename: string }}
   * @param {number} colorPaletteIndex: int to denote what color array it maps to
   * @param {number} hostTotalCount: count of all nodes within workspace
   * @param {boolean} areNodesExcluded: toggle to display nodes with fact present
   *
   * @return {obj} {categories: ([string, obj]), series: [{ label: string, data: number }]}
   */

  formatData(
    totalCount,
    values,
    hostTotalCount,
    areNodesExcluded,
    colorPalletIndex = 0,
  ) {
    // sort so any values that need to go into 'other' are not the largest values
    const sortedValues = values
      .slice()
      .sort((a, b) => (a.count > b.count ? -1 : 1));
    const categories = [];
    const series = [];
    const chartColorPallet =
      chartColorPallets[colorPalletIndex % chartColorPallets.length];

    // Temp function to manually ellipse legend titles that are over 11 characters in length
    // Remove once another charting library has been chosen
    const labelGenerator = (label) => {
      if (label.toString().length <= CHART_LEGEND_LABEL_MAX_LENGTH) {
        return label;
      }
      return `${label
        .toString()
        .slice(0, CHART_LEGEND_LABEL_ELLIPSE_POSITION)}...`;
    };

    sortedValues.forEach(({ value, count }, index) => {
      if (index + 1 < VALUE_LIMIT) {
        const label = labelGenerator(value);
        series.push(
          (count / (areNodesExcluded ? totalCount : hostTotalCount)) * 100,
        );
        categories.push({
          label: `${label} (${count})`,
          color: chartColorPallet[index],
        });
      } else if (index + 1 === VALUE_LIMIT) {
        series.push(100 - series.reduce((a, b) => a + b));
        categories.push({
          label: i18next.t('inventory:chart.categories.legend.other'),
          color: chartColorPallet[index],
        });
      }
    });

    if (!areNodesExcluded) {
      const count = hostTotalCount - totalCount;
      series.push((count / hostTotalCount) * 100);
      categories.push({
        label: `${labelGenerator(
          i18next.t('inventory:chart.categories.legend.noFact'),
        )} (${count})`,
        color: PUPPETN400,
      });
    }

    const adjustedSeries = getPercentages(series);
    adjustedSeries.forEach((percentage, index) => {
      let displayPercent = percentage;
      if (percentage === 0 && (sortedValues[index]?.count !== undefined || 0)) {
        displayPercent = '<1';
      }
      categories[index].label = `${displayPercent}%  `.concat(
        categories[index].label,
      );
    });

    return {
      categories,
      series: [{ data: adjustedSeries }],
    };
  },

  getSavedViewsFormat(cards) {
    return cards.map((card) => {
      return {
        id: card.id,
        cardDefinitionName: card.cardDefinitionName,
        factname: card.factname,
        label: card.label,
        areNodesExcluded: card.areNodesExcluded,
        order: card.order,
      };
    });
  },
};
