import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Text } from '@puppet/react-components';
import ConditionalRender from 'inventory/components/ConditionalRender';
import {
  ChartModalProptypes,
  CardPropTypes,
} from 'inventory/utils/en.propTypes';
import './FactModalView.scss';

const FactModalView = ({
  options,
  onCancel,
  formMessages,
  onSubmit,
  isEditing,
  card,
}) => {
  const [factname, setFactname] = useState(isEditing ? card?.factname : '');
  const [label, setLabel] = useState(isEditing ? card?.label : '');
  const [filteredList, setFilteredList] = useState([]);

  const areNodesExcluded = isEditing ? card?.areNodesExcluded : true;

  useEffect(() => {
    if (options) {
      setFilteredList(options);
    }
  }, [options]);

  // This is used to auto populate the form fields on selecting the factname.
  // When this form is used to edit, this can be overwritten before the form is initially rendered
  const initialValues = {
    id: card?.id,
    factname,
    label,
    areNodesExcluded,
  };

  return (
    <div className="fact-modal">
      <Modal.Title>
        {isEditing ? formMessages.editModalTitle : formMessages.modalTitle}
      </Modal.Title>
      <Form
        // TODO disable save button temporarily PDS-357
        submittable
        cancellable
        onCancel={onCancel}
        actionsPosition="right"
        submitLabel={formMessages.save}
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitDisabled={factname === ''}
      >
        <Form.Field
          id="chart-modal-fact-select"
          type="autocomplete"
          name="factname"
          label={formMessages.chooseFactInfo}
          placeholder={formMessages.chooseFactPlaceholder}
          options={filteredList}
          onFilter={(searchTerm) => {
            setFilteredList(
              options.filter(({ value }) => {
                return value.toLowerCase().includes(searchTerm.toLowerCase());
              }),
            );
            setFactname(searchTerm);
            setLabel(searchTerm);
          }}
        />
        <ConditionalRender enable={factname !== ''}>
          <Form.Field
            type="text"
            name="label"
            label={formMessages.chartTitle}
          />
          <Text className="fact-modal-checkbox-description">
            {formMessages.chartOptions}
          </Text>
          <Form.Field
            type="checkbox"
            name="areNodesExcluded"
            label={formMessages.excludeCheckbox}
          />
        </ConditionalRender>
      </Form>
    </div>
  );
};

export default FactModalView;

FactModalView.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formMessages: ChartModalProptypes.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  isEditing: PropTypes.bool.isRequired,
  card: CardPropTypes.isRequired,
};

FactModalView.defaultProps = {
  options: [],
};
