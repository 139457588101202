import React from 'react';
import {
  Alert,
  Button,
  Code,
  Copy,
  Modal,
  Text,
  TooltipHoverArea,
} from '@puppet/react-components';
import { SecretDetailsV1, SecretEnvironmentVariableV1 } from '@utils/api/cd4pe';
import { useTranslation } from 'react-i18next';
import { stringCompare } from '@utils/compare';

interface Props {
  secretDetails: SecretDetailsV1;
  onClose: () => void;
  successSaveAlert: boolean;
  onEdit: (secretName: string) => void;
  onDelete: (secretName: string) => void;
}

const generateSecretInfo = (variableName: SecretEnvironmentVariableV1) => (
  <div key={variableName.name} className="job-template-secret-details-group">
    <dt className="job-template-secret-details-group__label">
      {variableName.name}
    </dt>
    <Code
      as="dl"
      type="block"
      className="job-template-secret-details-group__variable-name"
    >
      <Copy value={`$${variableName.environmentVariable}`}>
        ${variableName.environmentVariable}
      </Copy>
    </Code>
  </div>
);

const JobTemplateSecretDetails = ({
  secretDetails,
  onClose,
  successSaveAlert,
  onEdit,
  onDelete,
}: Props) => {
  const { t } = useTranslation('codeDelivery');

  const successAlert = successSaveAlert ? (
    <Alert className="job-template-secret-details__alert" type="success">
      {t('jobTemplateSecretDetails.alerts.successSave')}
    </Alert>
  ) : null;

  return (
    <Modal className="job-template-secret-details" onClose={onClose}>
      {successAlert}

      <div className="job-template-secret-details__header-group">
        <Modal.Title>{secretDetails.name}</Modal.Title>

        <div>
          <TooltipHoverArea
            tooltip={t(
              'jobTemplateSecretDetails.iconButtons.editSecret.tooltip',
            )}
            anchor="bottom"
          >
            <Button
              aria-label={t(
                'jobTemplateSecretDetails.iconButtons.editSecret.ariaLabel',
                { secretName: secretDetails.name },
              )}
              type="transparent"
              icon="pencil"
              onClick={() => onEdit(secretDetails.name)}
            />
          </TooltipHoverArea>
          <TooltipHoverArea
            tooltip={t(
              'jobTemplateSecretDetails.iconButtons.deleteSecret.tooltip',
            )}
            anchor="bottom"
          >
            <Button
              aria-label={t(
                'jobTemplateSecretDetails.iconButtons.deleteSecret.ariaLabel',
                { secretName: secretDetails.name },
              )}
              type="transparent"
              icon="trash"
              onClick={() => onDelete(secretDetails.name)}
            />
          </TooltipHoverArea>
        </div>
      </div>
      <Text className="job-template-secret-details__subtitle" size="small">
        {t('jobTemplateSecretDetails.page.subtitle')}
      </Text>
      <dl>
        {secretDetails.environmentVariables
          .sort(({ name: s1 }, { name: s2 }) => stringCompare(s2, s1))
          .map(generateSecretInfo)}
      </dl>

      <Button type="tertiary" onClick={onClose}>
        {t('jobTemplateSecretDetails.buttons.close')}
      </Button>
    </Modal>
  );
};

export default JobTemplateSecretDetails;
