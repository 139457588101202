import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OptionsArray } from 'utils/en.propTypes';
import { useTranslation } from 'react-i18next';
import { Form } from '@puppet/react-components';
import './NodeTableFilter.scss';

const SingleSelectFilter = ({
  filterType,
  onCancel,
  onSubmit,
  label,
  placeholder,
  options,
  filterValue,
}) => {
  const { t } = useTranslation('inventory');
  const [optionLabel, setOptionLabel] = useState('');
  const [optionFilter, setOptionFilter] = useState('');

  const getFilterLabels = (selectedFilter, filter) => {
    const selectedValue = filter[selectedFilter];
    const selectedOption = options.find(
      (option) => option.value === selectedValue,
    );
    setOptionLabel(selectedOption.label);
    setOptionFilter(filterValue[selectedValue]);
  };

  const assembleFilterValue = () => {
    if (optionLabel && optionFilter) {
      onSubmit({
        value: optionLabel,
        filter: optionFilter,
      });
    }
  };

  return (
    <Form
      submittable
      cancellable
      actionsPosition="left"
      submitType="secondary"
      cancelType="text"
      submitDisabled={optionFilter === ''}
      onCancel={onCancel}
      onSubmit={assembleFilterValue}
      onChange={getFilterLabels}
      submitLabel={t('nodeTable.filter.add.button')}
    >
      <Form.Field
        id="single-select-filter-select"
        type="select"
        name={filterType}
        label={t(label)}
        placeholder={t(placeholder)}
        options={options || []}
      />
    </Form>
  );
};

export default SingleSelectFilter;

SingleSelectFilter.propTypes = {
  filterType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: OptionsArray.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  filterValue: PropTypes.shape({}).isRequired,
};
