import React from 'react';
import { Heading, Text, Form } from '@puppet/react-components';
import Cd4peError from '@components/Cd4peError';
import ConditionalRender from '@components/ConditionalRender';
import { useAppDispatch } from '@hooks/redux';
import { useNavigate } from 'react-router-dom';
import { useCreateWorkspaceV1 } from '@services/cd4pe/workspaces';
import session from '@state/session/session';
import { LINKS } from 'src/routes';
import { useTranslation } from 'react-i18next';

interface FormValues {
  newWorkspaceName: string;
}

const ChooseWorkspaceForm = () => {
  const { t } = useTranslation('codeDelivery');
  const [newName, setNewName] = React.useState<string>('');
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const { mutate, isLoading, isError, error } = useCreateWorkspaceV1();

  const onSubmit = (values: FormValues) => {
    mutate(
      {
        requestBody: { name: values.newWorkspaceName },
      },
      {
        onSuccess: (response) => {
          appDispatch(session.actions.createWorkspace(response));

          navigate(
            LINKS.settings.workspace({
              path: { workspace: response.name },
            }),
          );
        },
      },
    );
  };

  return (
    <>
      <Heading as="h3">{t('chooseWorkspace.new.header')}</Heading>
      <Text className="chooseWorkspace__description">
        {t('chooseWorkspace.new.description')}
      </Text>
      <Form
        className="chooseWorkspace__form"
        data-testid="chooseWorkspace__form"
        submittable
        values={{ newWorkspaceName: newName }}
        onSubmit={onSubmit}
        onChange={(_: unknown, values: FormValues) => {
          setNewName(values.newWorkspaceName?.replaceAll(' ', '-'));
        }}
        submitLabel={t('chooseWorkspace.new.createWorkspace')}
        submitting={isLoading}
      >
        <Form.Field
          type="text"
          name="newWorkspaceName"
          required
          placeholder={t('chooseWorkspace.new.newName.placeholder')}
          label=""
          aria-label={t('chooseWorkspace.new.newName.aria')}
          data-testid="choose-workspace-form-field"
        />
      </Form>
      <ConditionalRender enable={isError}>
        <div className="chooseWorkspace-content__item">
          <Cd4peError error={error} />
        </div>
      </ConditionalRender>
    </>
  );
};

export default ChooseWorkspaceForm;
