import { JobTemplateActions, JobTemplateFormValues } from './actions';
import * as at from './actionTypes';

export interface JobTemplateState {
  formState: JobTemplateFormValues;
  isExpanded: boolean;
  commandsMissing: boolean;
  submitAttempted: boolean;
}

export const defaultJobTemplateState: JobTemplateState = {
  formState: {
    name: '',
    description: '',
    commands: '',
    commandsSuccess: '',
    commandsError: '',
    runOnSharedHardware: true,
    runOnDefaultImage: true,
    hardwareCapabilities: [],
    runInDocker: true,
    imageName: '',
    dockerArgs: '',
    envVars: '',
  },
  isExpanded: false,
  commandsMissing: false,
  submitAttempted: false,
};

export const reducer = (
  state: JobTemplateState,
  action: JobTemplateActions,
): JobTemplateState => {
  switch (action.type) {
    case at.EDIT_FORM:
      return {
        ...state,
        formState: {
          ...state.formState,
          ...action.payload,
        },
      };
    case at.SET_RUN_ON_SHARED_HARDWARE:
      if (!action.payload) {
        return {
          ...state,
          formState: {
            ...state.formState,
            runInDocker: false,
            runOnSharedHardware: false,
            runOnDefaultImage: false,
            imageName: '',
          },
        };
      }
      return {
        ...state,
        formState: {
          ...state.formState,
          runOnSharedHardware: true,
          hardwareCapabilities: [],
          runInDocker: true,
          runOnDefaultImage: true,
          imageName: '',
          dockerArgs: '',
        },
      };
    case at.SET_RUN_IN_DOCKER:
      if (action.payload) {
        return {
          ...state,
          formState: {
            ...state.formState,
            runInDocker: action.payload,
            runOnDefaultImage: true,
            imageName: '',
          },
        };
      }
      return {
        ...state,
        formState: {
          ...state.formState,
          runInDocker: action.payload,
          runOnDefaultImage: false,
          imageName: '',
          dockerArgs: '',
        },
      };
    case at.SET_COMMANDS:
      return {
        ...state,
        formState: {
          ...state.formState,
          commands: action.payload,
        },
        commandsMissing: state.submitAttempted && !action.payload,
      };
    case at.SUBMIT_ATTEMPTED:
      return {
        ...state,
        submitAttempted: true,
        commandsMissing: !state.formState.commands,
      };
    case at.TOGGLE_COMMANDS_VIEW:
      return {
        ...state,
        isExpanded: !state.isExpanded,
      };
    case at.INITIALIZE_FORM_STATE:
      return {
        ...state,
        formState: action.payload,
      };
    default:
      return state;
  }
};
