import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Link as PdsLink, Text } from '@puppet/react-components';
import { Authentication } from '@puppet/react-layouts';
import CopyrightFooter from '@components/CopyrightFooter';
import FormError from '@components/FormError';
import { useSignUpV1 } from '@services/cd4pe/users';
import { LINKS } from 'src/routes';
import SignUpForm, { SignUpFormValues } from './components/SignUpForm';

const SignUp = () => {
  const { t } = useTranslation('codeDelivery');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const signUp = useSignUpV1();

  const onSubmit = ({
    firstName,
    lastName,
    companyName,
    username,
    email,
    password,
    confirmPassword,
  }: SignUpFormValues) => {
    if (password !== confirmPassword) {
      setError(t('signup.form.field.password.error'));
      return;
    }

    signUp.mutate(
      {
        firstName: firstName!,
        lastName: lastName!,
        username,
        email,
        passwd: password,
        passwd2: confirmPassword,
        ...(companyName && { companyName }),
      },
      {
        onSuccess: () => {
          navigate(
            LINKS.username.chooseWorkspace({
              path: { username },
            }),
          );
        },
      },
    );
  };

  return (
    <Authentication
      className="signup"
      product="continuous-delivery"
      title={t('signup.header')}
      footer={<CopyrightFooter />}
    >
      <Text className="signup__description" color="medium">
        {t('signup.description')}
      </Text>
      <SignUpForm
        isLoading={signUp.isLoading}
        onSubmit={onSubmit}
        error={error}
      />
      {signUp.error && <FormError message={signUp.error.toString()} />}
      <div className="signup__footer">
        <Trans
          t={t}
          i18nKey="signup.links.signIn"
          components={[
            <Text color="medium" />,
            <PdsLink as={Link} to={LINKS.login({})} />,
          ]}
        />
      </div>
    </Authentication>
  );
};

export default SignUp;
