import React, { Dispatch, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import { ConfirmationModal } from '@puppet/react-components';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import useWorkspaceName from '@hooks/useWorkspaceName';
import {
  createJobSecret,
  deleteJobSecret,
  editJobSecret,
  listJobSecrets,
} from '@codeDelivery/utils/actions';
import JobTemplate from '@codeDelivery/components/JobTemplate';
import JobTemplateSecretForm, {
  SecretsForm,
} from '@codeDelivery/components/JobTemplateSecretForm';
import JobTemplateSecretDetails from '@codeDelivery/components/JobTemplateSecretDetails';
import { JobTemplateFormValues } from '@codeDelivery/components/JobTemplate/actions';
import {
  CreateSecretRequestV1,
  EditSecretRequestV1,
  JobTemplateV1,
} from '@utils/api/cd4pe';
import {
  useGetTemplateV1,
  UseGetTemplateV1Result,
  useReplaceTemplateV1,
} from '@services/cd4pe/jobs';
import { LINKS } from 'src/routes';
import { useListCapabilitiesV1 } from '@services/cd4pe/hardware';
import useGetSharedDockerImageSettingsV1 from '@services/cd4pe/docker';
import {
  deleteSecretDetails,
  editJobTemplateActions,
  EditJobTemplateActions,
  editSecretDetails,
  viewSecretDetails,
} from './actions';
import { defaultState, EditJobTemplateState, reducer } from './reducer';

const renderSecretsFormModal = (
  state: EditJobTemplateState,
  dispatch: Dispatch<EditJobTemplateActions>,
  jobTemplate: JobTemplateV1 | null,
) => {
  if (state.modal !== 'SECRET_FORM_MODAL') {
    return null;
  }

  const onSecretSubmit = (values: SecretsForm) => {
    if (!jobTemplate) {
      return;
    }

    const {
      secretName: name,
      secretDescription: description,
      type,
      ...rest
    } = values;

    if (state.selectedSecret) {
      // This is needed because the backend does not currently support sending null
      // or missing values even if they're option. As a result, this request needs to
      // include secret, username, password properties regardless of the secret type.
      const editBody =
        values.type === 'USERNAME_PASSWORD'
          ? ({
              description,
              secret: '',
              ...rest,
            } as unknown as EditSecretRequestV1)
          : ({
              description,
              username: '',
              password: '',
              ...rest,
            } as unknown as EditSecretRequestV1);
      editJobSecret(jobTemplate.workspaceId, name, editBody, dispatch);
      return;
    }

    // This is needed because the backend does not currently support sending null
    // or missing values even if they're option. As a result, this request needs to
    // include secret, username, password properties regardless of the secret type.
    const createBody =
      values.type === 'USERNAME_PASSWORD'
        ? ({
            name,
            description,
            secret: '',
            type,
            ...rest,
          } as unknown as CreateSecretRequestV1)
        : ({
            name,
            description,
            type,
            username: '',
            password: '',
            ...rest,
          } as unknown as CreateSecretRequestV1);

    createJobSecret(jobTemplate, createBody, dispatch);
  };

  return (
    <JobTemplateSecretForm
      secretDetails={state.selectedSecret}
      onClose={() => dispatch(editJobTemplateActions.toggleModal(null))}
      onCancel={() => dispatch(editJobTemplateActions.toggleModal(null))}
      loading={state.createJobSecretLoading || state.editJobSecretLoading}
      onSubmit={onSecretSubmit}
      error={state.createJobSecretError || state.editJobSecretError}
    />
  );
};

const renderSecretDetailsModal = (
  state: EditJobTemplateState,
  dispatch: Dispatch<EditJobTemplateActions>,
) => {
  if (state.modal !== 'VIEW_SECRET_MODAL' || state.selectedSecret === null) {
    return null;
  }

  return (
    <JobTemplateSecretDetails
      secretDetails={state.selectedSecret}
      onClose={() => dispatch(editJobTemplateActions.toggleModal(null))}
      successSaveAlert={state.createJobSecretSuccess}
      onEdit={(s) => editSecretDetails(state.jobSecrets, s, dispatch)}
      onDelete={(s) => deleteSecretDetails(state.jobSecrets, s, dispatch)}
    />
  );
};

const renderDeleteSecretModal = (
  t: TFunction<'codeDelivery'>,
  state: EditJobTemplateState,
  dispatch: Dispatch<EditJobTemplateActions>,
  templateState: UseGetTemplateV1Result,
) => {
  if (
    state.modal !== 'DELETE_SECRET_MODAL' ||
    state.selectedSecret === null ||
    !templateState.isSuccess
  ) {
    return null;
  }

  const onDelete = () => {
    if (!templateState.isSuccess || !state.selectedSecret) {
      return;
    }
    deleteJobSecret(templateState.data, state.selectedSecret.name, dispatch);
  };

  return (
    <ConfirmationModal
      title={t('editJobTemplate.deleteSecretModal.title', {
        secretName: state.selectedSecret.name,
      })}
      description={t('editJobTemplate.deleteSecretModal.description')}
      cancelLabel={t('editJobTemplate.buttons.deleteSecretCancel')}
      confirmLabel={t('editJobTemplate.buttons.deleteSecret')}
      confirmButtonLoading={state.deleteJobSecretLoading}
      confirmButtonType="danger"
      onCancel={() => dispatch(editJobTemplateActions.toggleModal(null))}
      onConfirm={onDelete}
    />
  );
};

const EditJobTemplate = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const { t } = useTranslation('codeDelivery');
  const [state, dispatch] = useReducer(reducer, defaultState);
  const workspaceId = useWorkspaceDomain();
  const workspace = useWorkspaceName();
  const navigate = useNavigate();
  const templateState = useGetTemplateV1({
    id: Number(templateId),
    workspaceId,
  });
  const editTemplate = useReplaceTemplateV1();
  const capabilities = useListCapabilitiesV1({ workspaceId });
  const dockerContainer = useGetSharedDockerImageSettingsV1(undefined);

  useEffect(() => {
    if (templateState.isSuccess) {
      listJobSecrets(templateState.data, dispatch);
    }
  }, [templateState.data, templateState.isSuccess]);

  useEffect(() => {
    if (state.createJobSecretSuccess || state.editJobSecretSuccess) {
      dispatch(editJobTemplateActions.toggleModal('VIEW_SECRET_MODAL'));
    }
  }, [state.createJobSecretSuccess, state.editJobSecretSuccess]);

  const jobsPageUrl = LINKS.codeDelivery.listJobTemplates({
    path: { workspace },
  });

  useEffect(() => {
    if (editTemplate.isSuccess) {
      navigate(jobsPageUrl);
    }
  }, [editTemplate.isSuccess, jobsPageUrl, navigate]);

  const breadcrumbs = [
    {
      translationKey: 'editJobTemplate.breadcrumbs.jobs',
      linkDestination: jobsPageUrl,
    },
    { translationKey: 'editJobTemplate.breadcrumbs.editJob' },
  ];

  const onSave = (formValues: JobTemplateFormValues) => {
    if (!templateState.isSuccess || !dockerContainer.data) {
      return;
    }

    editTemplate.mutate({
      workspaceId: templateState.data.workspaceId,
      id: Number(templateState.data.id),
      requestBody: {
        workspaceId: templateState.data.workspaceId,
        description: formValues.description,
        name: formValues.name,
        manifest: {
          build: formValues.commands,
          onSuccess: formValues.commandsSuccess,
          onError: formValues.commandsError,
        },
        config: {
          sharedHardware: formValues.runOnSharedHardware,
          buildCapabilities: formValues.hardwareCapabilities,
          buildVars: formValues.envVars,
          vmArgs: formValues.dockerArgs,
          vmImage: formValues.imageName,
          useDefaultImage: formValues.runOnDefaultImage,
        },
      },
    });
  };

  return (
    <>
      <JobTemplate
        breadcrumbs={breadcrumbs}
        jobTemplate={templateState.data}
        titleTranslationKey="editJobTemplate.page.title"
        subtitleTranslationKey="editJobTemplate.page.subtitle"
        defaultContainer={dockerContainer.data}
        hardwareCapabilities={capabilities.data?.jobHardwareCapabilities}
        loading={templateState.isLoading}
        submitting={editTemplate.isLoading}
        formError={editTemplate.error}
        onCancel={() => navigate(jobsPageUrl)}
        onSubmit={onSave}
        loadError={
          templateState.error || dockerContainer.error || capabilities.error
        }
        submitTranslationKey="editJobTemplate.form.submit"
        secretsError={state.getJobSecretsError || state.deleteJobSecretError}
        secrets={state.jobSecrets}
        onAddSecret={() =>
          dispatch(editJobTemplateActions.toggleModal('SECRET_FORM_MODAL'))
        }
        onEditSecret={(s) => editSecretDetails(state.jobSecrets, s, dispatch)}
        onDeleteSecret={(s) =>
          deleteSecretDetails(state.jobSecrets, s, dispatch)
        }
        onViewSecret={(s) => viewSecretDetails(state.jobSecrets, s, dispatch)}
      />
      {renderSecretsFormModal(state, dispatch, templateState.data ?? null)}
      {renderSecretDetailsModal(state, dispatch)}
      {renderDeleteSecretModal(t, state, dispatch, templateState)}
    </>
  );
};

export default EditJobTemplate;
