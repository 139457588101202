/* eslint-disable import/prefer-default-export */
import { useQuery } from '@tanstack/react-query';
import {
  Cd4peApiError,
  handleQueryRequest,
  LicenseV1Service,
  QueryHook,
} from '@utils/api/cd4pe';

const GET_LICENSE_QUERY_TAG = 'jobInstancesLog';

type UseGetLicenseV1 = QueryHook<
  typeof LicenseV1Service,
  'getLicenseV1',
  Cd4peApiError
>;

export const useGetLicenseV1: UseGetLicenseV1 = () =>
  useQuery([GET_LICENSE_QUERY_TAG], () =>
    handleQueryRequest(LicenseV1Service.getLicenseV1()),
  );
