import React, { ReactNode } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import format from 'date-fns/format';
import { TaskStateV1 } from '@puppet/cd4pe-client-ts';
import { Link, Text, Icon } from '@puppet/react-components';
import ConditionalRender from '@components/ConditionalRender';
import { EventStatus, returnStatusColor } from '../../../../utils';

interface Props {
  id?: number;
  name?: string;
  startTime?: number;
  icon?: string;
  status?: EventStatus | TaskStateV1;
  statusTranslationKey?: string;
  environment?: string;
  environmentsAnalyzed?: string | number;
  peInstance?: string;
  policy?: string;
  eventLink?: string;
  webhookType?: string;
  actionButton?: ReactNode;
  href?: boolean;
}

const Event = ({
  id,
  name = '',
  startTime = 0,
  icon = 'clipboard',
  status,
  statusTranslationKey = '',
  environment = '',
  peInstance = '',
  policy = '',
  environmentsAnalyzed = '',
  webhookType = '',
  eventLink,
  actionButton,
  href = false,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const statusColor = returnStatusColor(status);
  const linkProps = href
    ? { as: 'a', href: eventLink }
    : { as: NavLink, to: eventLink };

  return (
    <div className={classnames('event', { webhook: !!webhookType })}>
      <div className={classnames('event__icon', { [statusColor]: true })}>
        <ConditionalRender enable={!!icon}>
          <Icon type={icon} size="medium" />
        </ConditionalRender>
        <ConditionalRender enable={!webhookType}>
          <div className="event__icon-divide" />
        </ConditionalRender>
      </div>
      <div className="event__event-detail-section">
        <div className="event__event-detail">
          <ConditionalRender enable={!!status && !!statusTranslationKey}>
            <Text size="small" color={statusColor}>
              {t(statusTranslationKey, {
                status: t(
                  `viewPipeline.events.status.${status?.toLowerCase()}`,
                ),
              })}
            </Text>
          </ConditionalRender>
          <ConditionalRender enable={!!eventLink}>
            <Trans t={t} i18nKey="viewPipeline.events.table.row.details.link">
              <Link size="small" data-testid={`job-link-${id}`} {...linkProps}>
                {{
                  id,
                }}
              </Link>
            </Trans>
          </ConditionalRender>
          <ConditionalRender enable={!!name}>
            <Text size="small">{name}</Text>
          </ConditionalRender>
          <ConditionalRender enable={!!environment}>
            <div className="event__detail-value">
              <Trans
                t={t}
                i18nKey="viewPipeline.events.table.row.details.environment"
              >
                <Text size="small" />
                <Text size="small" color="medium">
                  {{
                    environment,
                  }}
                </Text>
              </Trans>
            </div>
          </ConditionalRender>
          <ConditionalRender enable={!!environmentsAnalyzed}>
            <div className="event__detail-value">
              <Trans
                t={t}
                i18nKey="viewPipeline.events.table.row.details.environmentsAnalyzed"
              >
                <Text size="small" />
                <Text size="small" color="medium">
                  {{
                    environmentsAnalyzed,
                  }}
                </Text>
              </Trans>
            </div>
          </ConditionalRender>
          <ConditionalRender enable={!!peInstance}>
            <div className="event__detail-value">
              <Trans
                t={t}
                i18nKey="viewPipeline.events.table.row.details.peInstance"
              >
                <Text size="small" />
                <Text size="small" color="medium">
                  {{
                    peInstance,
                  }}
                </Text>
              </Trans>
            </div>
          </ConditionalRender>
          <ConditionalRender enable={!!policy}>
            <div className="event__detail-value">
              <Trans
                t={t}
                i18nKey="viewPipeline.events.table.row.details.deployment.policy"
              >
                <Text size="small" />
                <Text size="small" color="medium">
                  {{
                    policy,
                  }}
                </Text>
              </Trans>
            </div>
          </ConditionalRender>
          <ConditionalRender enable={!!webhookType}>
            <Text size="small">{webhookType}</Text>
          </ConditionalRender>
          <ConditionalRender enable={!!startTime}>
            <div className="event__detail-value">
              <Trans
                t={t}
                i18nKey="viewPipeline.events.table.row.details.startTime"
              >
                <Text size="small" />
                <Text size="small" color="medium">
                  {{
                    date: format(new Date(startTime), 'PPPp'),
                  }}
                </Text>
              </Trans>
            </div>
          </ConditionalRender>
        </div>
        <ConditionalRender enable={!!actionButton}>
          <div className="event__button">{actionButton}</div>
        </ConditionalRender>
      </div>
    </div>
  );
};

export default Event;
