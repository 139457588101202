import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Cd4peApiError,
  handleQueryRequest,
  MutationHook,
  QueryHook,
} from '@utils/api/cd4pe';
import { PipelinesV1Service } from '@puppet/cd4pe-client-ts';
import { CONTROL_REPOS_GET_QUERY_TAG } from './controlRepos';
import { MODULES_GET_QUERY_TAG } from './modules';
import { LIST_TRIGGER_EVENTS_QUERY_TAG } from './events';
import { PREVIEW_PIPELINE_AS_CODE_TAG } from './pipelinesAsCode';

export const GET_PIPELINE_QUERY_TAG = 'getPipeline';
export const UPSERT_PIPELINE_STAGES = 'upsertPipelineStages';
export const GET_IS_BUILD_PR_ALLOWED = 'getIsBuildPullRequestAllowed';

/**
 * useCreatePipelineV1
 */
type UseCreatePipelineV1 = MutationHook<
  typeof PipelinesV1Service,
  'createPipelineV1',
  Cd4peApiError
>;

export type UseCreatePipelineV1Result = ReturnType<UseCreatePipelineV1>;

export const useCreatePipelineV1: UseCreatePipelineV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(PipelinesV1Service.createPipelineV1(payload)),
    {
      onSuccess: (_, payload) => {
        if (payload.requestBody.projectType === 'CONTROL_REPO') {
          queryClient.invalidateQueries([
            CONTROL_REPOS_GET_QUERY_TAG,
            payload.workspaceId,
            payload.requestBody.projectName.toLowerCase(),
          ]);
        }

        if (payload.requestBody.projectType === 'MODULE') {
          queryClient.invalidateQueries([
            MODULES_GET_QUERY_TAG,
            payload.workspaceId,
            payload.requestBody.projectName.toLowerCase(),
          ]);
        }
      },
    },
  );
};

/**
 * useGetPipelineV1
 */
type UseGetPipelineV1 = QueryHook<
  typeof PipelinesV1Service,
  'getPipelineV1',
  Cd4peApiError
>;

export type UseGetPipelineV1Result = ReturnType<UseGetPipelineV1>;

export const useGetPipelineV1: UseGetPipelineV1 = (payload, options) =>
  useQuery(
    [
      GET_PIPELINE_QUERY_TAG,
      payload.workspaceId,
      payload.projectName,
      payload.pipelineId,
    ],
    () => handleQueryRequest(PipelinesV1Service.getPipelineV1(payload)),
    options,
  );

/**
 * useCreateDefaultPipelineV1
 */

type UseCreateDefaultPipelineV1 = MutationHook<
  typeof PipelinesV1Service,
  'createDefaultPipelineV1',
  Cd4peApiError
>;

export type UseCreateDefaultPipelineV1Result =
  ReturnType<UseCreateDefaultPipelineV1>;

export const useCreateDefaultPipelineV1: UseCreateDefaultPipelineV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(PipelinesV1Service.createDefaultPipelineV1(payload)),
    {
      onSuccess(data, payload) {
        queryClient.setQueryData(
          [
            GET_PIPELINE_QUERY_TAG,
            payload.workspaceId,
            payload.requestBody.projectName,
            payload.pipelineId,
          ],
          data,
        );
      },
    },
  );
};

/**
 * useUpsertPipelineStagesV1
 */

type UseUpsertPipelineStagesV1 = MutationHook<
  typeof PipelinesV1Service,
  'upsertPipelineStagesV1',
  Cd4peApiError
>;

export type UseUpsertPipelineStagesV1Result =
  ReturnType<UseUpsertPipelineStagesV1>;

export const useUpsertPipelineStagesV1Optimistic: UseUpsertPipelineStagesV1 =
  () => {
    const queryClient = useQueryClient();
    return useMutation(
      (payload) =>
        handleQueryRequest(PipelinesV1Service.upsertPipelineStagesV1(payload)),
      {
        onMutate: async (payload) => {
          /**
           *  NOTE:
           *  This approach uses optimistic updates to allow the UI to show immediate changes to improve performance.
           *  We directly update the cache while we are waiting for the request to complete.
           *  If the request does eventually fail, we recover by restoring the cache back to its original value.
           *
           *  We benefit of this approach because this is a heavy weight API that supports a wide range of changes from the UI such as:
           *    - renaming stages
           *    - removing individual job items
           *    - toggling auto promotion
           *    - promotion conditions and more
           *
           *  See here for more information - https://tkdodo.eu/blog/mastering-mutations-in-react-query#optimistic-updates and https://tanstack.com/query/latest/docs/react/guides/optimistic-updates?from=reactQueryV3&original=https%3A%2F%2Ftanstack.com%2Fquery%2Fv3%2Fdocs%2Fguides%2Foptimistic-updates
           */

          const queryKey = [
            GET_PIPELINE_QUERY_TAG,
            payload.workspaceId,
            payload.requestBody.projectName,
            payload.pipelineId,
          ];

          // Cancel any outgoing refetches
          // (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries({ queryKey });

          // Snapshot the previous value
          const previousValue = <UseGetPipelineV1Result>(
            queryClient.getQueryData(queryKey)
          );

          // Optimistically update to the new value
          queryClient.setQueryData(queryKey, {
            ...previousValue,
            stages: payload.requestBody?.stages,
          });

          // Return a context with the previous and new todo
          return { previousValue, newValue: payload.requestBody.stages };
        },
        // If the mutation fails, use the context we returned above
        onError: (_, payload, context) => {
          // set stages to previous value
          queryClient.setQueryData(
            [
              GET_PIPELINE_QUERY_TAG,
              payload.workspaceId,
              payload.requestBody.projectName,
              payload.pipelineId,
            ],
            context?.previousValue,
          );
        },
        // Always refetch after error or success:
        onSettled: (_, error, payload) => {
          queryClient.invalidateQueries([
            GET_PIPELINE_QUERY_TAG,
            payload.workspaceId,
            payload.requestBody.projectName,
            payload.pipelineId,
          ]);
        },
      },
    );
  };

export const useUpsertPipelineStagesV1: UseUpsertPipelineStagesV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(PipelinesV1Service.upsertPipelineStagesV1(payload)),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          GET_PIPELINE_QUERY_TAG,
          payload.workspaceId,
          payload.requestBody.projectName,
          payload.pipelineId,
        ]);
      },
    },
  );
};

/**
 * useDeletePipelineV1
 */

type UseDeletePipelineV1 = MutationHook<
  typeof PipelinesV1Service,
  'deletePipelineV1',
  Cd4peApiError
>;

export type UseDeletePipelineV1Result = ReturnType<UseDeletePipelineV1>;

export const useDeletePipelineV1: UseDeletePipelineV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(PipelinesV1Service.deletePipelineV1(payload)),
    {
      onSuccess: (_, payload) => {
        queryClient.removeQueries([
          GET_PIPELINE_QUERY_TAG,
          payload.workspaceId,
          payload.projectName,
          payload.pipelineId,
        ]);

        queryClient.removeQueries([
          PREVIEW_PIPELINE_AS_CODE_TAG,
          payload.workspaceId,
          payload.projectName,
          payload.pipelineId,
        ]);

        queryClient.removeQueries([
          GET_IS_BUILD_PR_ALLOWED,
          payload.workspaceId,
          payload.projectName,
          payload.pipelineId,
        ]);

        queryClient.invalidateQueries([
          CONTROL_REPOS_GET_QUERY_TAG,
          payload.workspaceId,
          payload.projectName.toLowerCase(),
        ]);

        queryClient.invalidateQueries([
          MODULES_GET_QUERY_TAG,
          payload.workspaceId,
          payload.projectName.toLowerCase(),
        ]);
      },
    },
  );
};

/**
 * useDeletePipelineV1
 */

type UseDeletePipelineStagesV1 = MutationHook<
  typeof PipelinesV1Service,
  'deletePipelineStagesV1',
  Cd4peApiError
>;

export type UseDeletePipelineStagesV1Result =
  ReturnType<UseDeletePipelineStagesV1>;

export const useDeletePipelineStagesV1: UseDeletePipelineStagesV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(PipelinesV1Service.deletePipelineStagesV1(payload)),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          GET_PIPELINE_QUERY_TAG,
          payload.workspaceId,
          payload.projectName,
          payload.pipelineId,
        ]);

        queryClient.invalidateQueries([
          CONTROL_REPOS_GET_QUERY_TAG,
          payload.workspaceId,
          payload.projectName.toLowerCase(),
        ]);

        queryClient.invalidateQueries([
          MODULES_GET_QUERY_TAG,
          payload.workspaceId,
          payload.projectName.toLowerCase(),
        ]);
      },
    },
  );
};

/**
 * useTriggerPipelineV1
 */

type UseTriggerPipelineV1 = MutationHook<
  typeof PipelinesV1Service,
  'triggerPipelineV1',
  Cd4peApiError
>;

export type UseTriggerPipelineV1Result = ReturnType<UseTriggerPipelineV1>;

export const useTriggerPipelineV1: UseTriggerPipelineV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(PipelinesV1Service.triggerPipelineV1(payload)),
    {
      onSuccess() {
        queryClient.invalidateQueries([LIST_TRIGGER_EVENTS_QUERY_TAG]);
      },
    },
  );
};

/**
 * useUpdatePipelineTriggersV1
 */

type UseUpdatePipelineTriggersV1 = MutationHook<
  typeof PipelinesV1Service,
  'updatePipelineTriggersV1',
  Cd4peApiError
>;

export const useUpdatePipelineTriggersV1: UseUpdatePipelineTriggersV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      handleQueryRequest(PipelinesV1Service.updatePipelineTriggersV1(payload)),
    {
      onSuccess(_, payload) {
        queryClient.invalidateQueries([
          GET_PIPELINE_QUERY_TAG,
          payload.workspaceId,
          payload.requestBody.projectName,
          payload.pipelineId,
        ]);
      },
    },
  );
};

/**
 * useSetIsBuildPullRequestAllowedV1
 */

type UseSetIsBuildPullRequestAllowedV1 = MutationHook<
  typeof PipelinesV1Service,
  'setIsBuildPullRequestAllowedV1',
  Cd4peApiError
>;

export const useSetIsBuildPullRequestAllowedV1: UseSetIsBuildPullRequestAllowedV1 =
  () => {
    const queryClient = useQueryClient();
    return useMutation(
      (payload) =>
        handleQueryRequest(
          PipelinesV1Service.setIsBuildPullRequestAllowedV1(payload),
        ),
      {
        onSuccess: (_, payload) => {
          queryClient.invalidateQueries([
            GET_IS_BUILD_PR_ALLOWED,
            payload.workspaceId,
            payload.requestBody?.projectName,
            payload.pipelineId,
          ]);
        },
      },
    );
  };

/**
 * useGetIsBuildPullRequestAllowedV1
 */

type UseGetIsBuildPullRequestAllowedV1 = QueryHook<
  typeof PipelinesV1Service,
  'getIsBuildPullRequestAllowedV1',
  Cd4peApiError
>;

export const useGetIsBuildPullRequestAllowedV1: UseGetIsBuildPullRequestAllowedV1 =
  (payload, options) =>
    useQuery(
      [
        GET_IS_BUILD_PR_ALLOWED,
        payload.workspaceId,
        payload.projectName,
        payload.pipelineId,
      ],
      () =>
        handleQueryRequest(
          PipelinesV1Service.getIsBuildPullRequestAllowedV1(payload),
        ),
      options,
    );
