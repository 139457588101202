import * as Provider from '@utils/vcs/providerConsts';
import { VcsProviderV1 } from '@puppet/cd4pe-client-ts';

// eslint-disable-next-line no-script-url
export const DEFAULT_LINK = 'javascript:;';
const GITHUB_HOST = 'https://github.com';
const AZURE_DEVOPS_HOST = 'https://dev.azure.com';

interface Event {
  repoProvider: VcsProviderV1;
  repoUrl: string;
  branch: string;
  commitId: string;
}

// Repository links
function getAzureDevOpsRepoLink(
  host: string,
  owner: string,
  project: string,
  repo: string,
) {
  if (!owner || !project || !repo) {
    return DEFAULT_LINK;
  }

  if (host) {
    return `https://${host}/${owner}/${project}/_git/${repo}`;
  }

  return `${AZURE_DEVOPS_HOST}/${owner}/${project}/_git/${repo}`;
}

function getGithubRepoLink(owner: string, repo: string) {
  if (!owner || !repo) {
    return DEFAULT_LINK;
  }

  return `https://github.com/${owner.toLowerCase()}/${repo.toLowerCase()}`;
}

function getGithubEnterpriseRepoLink(
  host: string,
  owner: string,
  repo: string,
) {
  if (!host || !owner || !repo) {
    return DEFAULT_LINK;
  }

  return `https://${host}/${owner.toLowerCase()}/${repo.toLowerCase()}`;
}

function getGitLabRepoLink(host: string, owner: string, repo: string) {
  if (!host || !owner || !repo) {
    return DEFAULT_LINK;
  }

  return `${host}/${owner.toLowerCase()}/${repo
    .toLowerCase()
    .split(' ')
    .join('-')}`;
}

function getBitbucketServerRepoLink(host: string, owner: string, repo: string) {
  if (!host || !owner || !repo) {
    return DEFAULT_LINK;
  }

  return `${host}/projects/${owner}/repos/${repo.toLowerCase()}`;
}

function getBitbucketRepoLink(owner: string, repo: string) {
  if (!owner || !repo) {
    return DEFAULT_LINK;
  }

  return `https://bitbucket.org/${owner.toLowerCase()}/${repo
    .toLowerCase()
    .split(' ')
    .join('-')}`;
}

// Branch links
function getAzureDevOpsBranchLink(
  host: string,
  owner: string,
  project: string,
  repo: string,
  branch: string,
) {
  if (!owner || !project || !branch || !repo) {
    return DEFAULT_LINK;
  }

  return `${getAzureDevOpsRepoLink(
    host,
    owner,
    project,
    repo,
  )}?version=GB${branch}`;
}

function getGithubRepoBranchLink(owner: string, repo: string, branch: string) {
  if (!owner || !branch || !repo) {
    return DEFAULT_LINK;
  }

  return `https://github.com/${owner.toLowerCase()}/${repo.toLowerCase()}/tree/${branch}`;
}

function getGithubEnterpriseRepoBranchLink(
  host: string,
  owner: string,
  repo: string,
  branch: string,
) {
  if (!host || !owner || !repo || !branch) {
    return DEFAULT_LINK;
  }

  return `https://${host}/${owner.toLowerCase()}/${repo.toLowerCase()}/tree/${branch}`;
}

function getGitLabRepoBranchLink(
  host: string,
  owner: string,
  repo: string,
  branch: string,
) {
  if (!host || !owner || !repo || !branch) {
    return DEFAULT_LINK;
  }

  return `${host}/${owner.toLowerCase()}/${repo.toLowerCase()}/tree/${branch}`;
}

function getBitbucketServerRepoBranchLink(
  host: string,
  owner: string,
  repo: string,
  branch: string,
) {
  if (!host || !owner || !repo || !branch) {
    return DEFAULT_LINK;
  }

  return `${host}/projects/${owner}/repos/${repo.toLowerCase()}/browse?at=${encodeURIComponent(
    `refs/heads/${branch}`,
  )}`;
}

function getBitbucketRepoBranchLink(
  owner: string,
  repo: string,
  branch: string,
) {
  if (!owner || !repo || !branch) {
    return DEFAULT_LINK;
  }

  return `https://bitbucket.org/${owner.toLowerCase()}/${repo
    .toLowerCase()
    .split(' ')
    .join('-')}/branch/${branch}`;
}

// commit links
function getAzureDevOpsCommitLink(
  host: string,
  owner: string,
  project: string,
  repo: string,
  commitId: string,
) {
  if (!owner || !project || !repo || !commitId) {
    return DEFAULT_LINK;
  }

  return `${getAzureDevOpsRepoLink(
    host,
    owner,
    project,
    repo,
  )}/commit/${commitId}`;
}

function getGithubCommitLink(owner: string, repo: string, commitId: string) {
  if (!owner || !repo || !commitId) {
    return DEFAULT_LINK;
  }

  return `https://github.com/${owner.toLowerCase()}/${repo.toLowerCase()}/commit/${commitId.toLowerCase()}`;
}

function getGithubEnterpriseCommitLink(
  host: string,
  owner: string,
  repo: string,
  commitId: string,
) {
  if (!host || !owner || !repo || !commitId) {
    return DEFAULT_LINK;
  }

  return `https://${host}/${owner.toLowerCase()}/${repo.toLowerCase()}/commit/${commitId.toLowerCase()}`;
}

function getGitLabCommitLink(
  host: string,
  owner: string,
  repo: string,
  commitId: string,
) {
  if (!host || !owner || !repo || !commitId) {
    return DEFAULT_LINK;
  }

  return `${host}/${owner.toLowerCase()}/${repo.toLowerCase()}/commit/${commitId.toLowerCase()}`;
}

function getBitbucketServerCommitLink(
  host: string,
  owner: string,
  repo: string,
  commitId: string,
) {
  if (!host || !owner || !repo || !commitId) {
    return DEFAULT_LINK;
  }

  const indexOfTilde = owner.indexOf('~');
  let repoOwner = owner;
  let usersOrProjects = 'projects';

  if (indexOfTilde === 0) {
    repoOwner = owner.slice(indexOfTilde + 1);
    usersOrProjects = 'users';
  }

  return `${host}/${usersOrProjects}/${repoOwner}/repos/${repo.toLowerCase()}/commits/${commitId}`;
}

function getBitbucketCommitLink(owner: string, repo: string, commitId: string) {
  if (!owner || !repo || !commitId) {
    return DEFAULT_LINK;
  }

  return `https://bitbucket.org/${owner.toLowerCase()}/${repo
    .toLowerCase()
    .split(' ')
    .join('-')}/commits/${commitId.toLowerCase()}`;
}

// profile links
function getAzureDevOpsProfileLink() {
  // not currently supported for Azure Dev Ops
  return DEFAULT_LINK;
}

function getGithubProfileLink(owner: string) {
  if (!owner) {
    return DEFAULT_LINK;
  }

  return `https://github.com/${owner.toLowerCase()}`;
}

function getBitbucketProfileLink(owner: string) {
  if (!owner) {
    return DEFAULT_LINK;
  }

  return `https://bitbucket.org/${owner.toLowerCase()}`;
}

function getGithubEnterpriseProfileLink(host: string, owner: string) {
  if (!host || !owner) {
    return DEFAULT_LINK;
  }

  return `https://${host}/${owner.toLowerCase()}`;
}

export function getCommitLink(
  provider: VcsProviderV1,
  owner: string,
  repoName: string,
  repoId: string,
  host: string,
  commitId: string,
) {
  let link = DEFAULT_LINK;

  if (provider === Provider.GITHUB) {
    link = getGithubCommitLink(owner, repoName, commitId);
  } else if (provider === Provider.BITBUCKET) {
    link = getBitbucketCommitLink(owner, repoName, commitId);
  } else if (provider === Provider.GITLAB) {
    link = getGitLabCommitLink(host, owner, repoName, commitId);
  } else if (provider === Provider.BITBUCKET_SERVER) {
    link = getBitbucketServerCommitLink(host, repoId, repoName, commitId);
  } else if (provider === Provider.GITHUB_ENTERPRISE) {
    link = getGithubEnterpriseCommitLink(host, owner, repoName, commitId);
  } else if (provider === Provider.AZURE_DEV_OPS) {
    link = getAzureDevOpsCommitLink(host, owner, repoId, repoName, commitId);
  }

  return link;
}

export function getRepoLink(
  provider: VcsProviderV1,
  owner: string,
  repoName: string,
  repoId: string,
  host: string,
) {
  let link = DEFAULT_LINK;

  if (provider === Provider.GITHUB) {
    link = getGithubRepoLink(owner, repoName);
  } else if (provider === Provider.BITBUCKET) {
    link = getBitbucketRepoLink(owner, repoName);
  } else if (provider === Provider.GITLAB) {
    link = getGitLabRepoLink(host, owner, repoName);
  } else if (provider === Provider.BITBUCKET_SERVER) {
    link = getBitbucketServerRepoLink(host, repoId, repoName);
  } else if (provider === Provider.GITHUB_ENTERPRISE) {
    link = getGithubEnterpriseRepoLink(host, owner, repoName);
  } else if (provider === Provider.AZURE_DEV_OPS) {
    link = getAzureDevOpsRepoLink(host, owner, repoId, repoName);
  }

  return link;
}

export function getBranchLink(
  provider: VcsProviderV1,
  owner: string,
  repoName: string,
  repoId: string,
  host: string,
  branch: string,
) {
  let link = DEFAULT_LINK;

  if (provider === Provider.GITHUB) {
    link = getGithubRepoBranchLink(owner, repoName, branch);
  } else if (provider === Provider.BITBUCKET) {
    link = getBitbucketRepoBranchLink(owner, repoName, branch);
  } else if (provider === Provider.GITLAB) {
    link = getGitLabRepoBranchLink(host, owner, repoName, branch);
  } else if (provider === Provider.BITBUCKET_SERVER) {
    link = getBitbucketServerRepoBranchLink(host, repoId, repoName, branch);
  } else if (provider === Provider.GITHUB_ENTERPRISE) {
    link = getGithubEnterpriseRepoBranchLink(host, owner, repoName, branch);
  } else if (provider === Provider.AZURE_DEV_OPS) {
    link = getAzureDevOpsBranchLink(host, owner, repoId, repoName, branch);
  }

  return link;
}

export function getProfileLink(
  provider: VcsProviderV1,
  owner: string,
  host: string,
) {
  let link = DEFAULT_LINK;

  if (provider === Provider.GITHUB) {
    link = getGithubProfileLink(owner);
  } else if (provider === Provider.BITBUCKET) {
    link = getBitbucketProfileLink(owner);
  } else if (provider === Provider.GITLAB) {
    link = DEFAULT_LINK; // TODO: implement this
  } else if (provider === Provider.BITBUCKET_SERVER) {
    link = DEFAULT_LINK; // TODO: implement this
  } else if (provider === Provider.GITHUB_ENTERPRISE) {
    link = getGithubEnterpriseProfileLink(host, owner);
  } else if (provider === Provider.AZURE_DEV_OPS) {
    link = getAzureDevOpsProfileLink();
  }

  return link;
}

export function getAppEventBranchLink(event: Event) {
  if (
    event.repoProvider === Provider.GITHUB ||
    event.repoProvider === Provider.GITHUB_ENTERPRISE
  ) {
    return `${event.repoUrl}/tree/${event.branch}`;
  }

  if (event.repoProvider === Provider.BITBUCKET) {
    return `${event.repoUrl}/branch/${event.branch}`;
  }

  if (event.repoProvider === Provider.BITBUCKET_SERVER) {
    return `${event.repoUrl}/browse?at=${encodeURIComponent(
      `refs/heads/${event.branch}`,
    )}`;
  }

  if (event.repoProvider === Provider.GITLAB) {
    return `${event.repoUrl}/tree/${event.branch}`;
  }

  if (event.repoProvider === Provider.AZURE_DEV_OPS) {
    return `${event.repoUrl}?version=GB${event.branch}`;
  }

  return DEFAULT_LINK;
}

export function getAppEventCommitLink(event: Event) {
  if (
    event.repoProvider === Provider.GITHUB ||
    event.repoProvider === Provider.GITHUB_ENTERPRISE ||
    event.repoProvider === Provider.GITLAB ||
    event.repoProvider === Provider.AZURE_DEV_OPS
  ) {
    return `${event.repoUrl}/commit/${event.commitId}`;
  }

  if (
    event.repoProvider === Provider.BITBUCKET ||
    event.repoProvider === Provider.BITBUCKET_SERVER
  ) {
    return `${event.repoUrl}/commits/${event.commitId}`;
  }

  return DEFAULT_LINK;
}

export function toPrettyProviderName(srcProvider: VcsProviderV1) {
  let provider = '';

  if (srcProvider === Provider.GITHUB) {
    provider = 'GitHub';
  } else if (srcProvider === Provider.BITBUCKET) {
    provider = 'Bitbucket Cloud';
  } else if (srcProvider === Provider.GITLAB) {
    provider = 'GitLab';
  } else if (srcProvider === Provider.BITBUCKET_SERVER) {
    provider = 'Bitbucket Server';
  } else if (srcProvider === Provider.GITHUB_ENTERPRISE) {
    provider = 'GitHub Enterprise';
  } else if (srcProvider === Provider.AZURE_DEV_OPS) {
    provider = 'Azure DevOps';
  }

  return provider;
}

export function getRepoHost(
  provider: VcsProviderV1,
  hostMap: Map<string, string>,
) {
  if (!provider) {
    return null;
  }

  switch (provider) {
    case Provider.GITHUB:
      return GITHUB_HOST;
    default:
      return hostMap.get(provider);
  }
}
