import actionCreator, { Actions } from '@utils/actionCreator';
import { Dispatch } from 'react';
import {
  formatError,
  GroupsV1Service,
  isErrorResponse,
  ListRbacGroupsV1,
  ListWorkspaceMemberCandidatesResponseV1,
  RbacGroupV1,
  unwrapApiResponse,
  WorkspaceMemberCandidateV1,
  WorkspacesV1Service,
} from '@utils/api/cd4pe';
import { TFunction } from 'react-i18next';
import { stringCompare } from '@utils/compare';
import { AddUserState } from './reducer';
import * as at from './actionTypes';
import { GROUP_SORTS } from '../GroupList/actions';

const actions = {
  getUsersLoading: () => actionCreator(at.GET_USERS_LOADING),
  getUsersComplete: (users: ListWorkspaceMemberCandidatesResponseV1) =>
    actionCreator(at.GET_USERS_COMPLETE, users),
  getUsersError: (error: string) => actionCreator(at.GET_USERS_ERROR, error),

  getGroupsLoading: () => actionCreator(at.GET_GROUPS_LOADING),
  getGroupsComplete: (groups: ListRbacGroupsV1) =>
    actionCreator(at.GET_GROUPS_COMPLETE, groups),
  getGroupsError: (error: string) => actionCreator(at.GET_GROUPS_ERROR, error),

  addUsersLoading: () => actionCreator(at.ADD_USERS_LOADING),
  addUsersComplete: () => actionCreator(at.ADD_USERS_COMPLETE),
  addUsersError: (error: string) => actionCreator(at.ADD_USERS_ERROR, error),

  toggleAllUsersSelection: (toggleAllPayload: {
    indeterminateUserState: boolean;
    selectAll: boolean;
  }) => actionCreator(at.TOGGLE_ALL_USERS_SELECTION, toggleAllPayload),
  toggleUserRowSelection: (rowPayload: {
    users: ListCandidatesWithSelectedAttr;
    usersSelectedCount: number;
  }) => actionCreator(at.TOGGLE_USER_ROW_SELECTION, rowPayload),

  toggleAllGroupsSelection: (toggleAllPayload: {
    indeterminateGroupState: boolean;
    selectAll: boolean;
  }) => actionCreator(at.TOGGLE_ALL_GROUPS_SELECTION, toggleAllPayload),
  toggleGroupRowSelection: (rowPayload: {
    groups: ListRBACGroupsWithSelectedAttr;
    groupsSelectedCount: number;
  }) => actionCreator(at.TOGGLE_GROUP_ROW_SELECTION, rowPayload),

  filterUsers: (filteredPayload: {
    query: string;
    filteredUsers: ListWorkspaceMemberCandidatesResponseV1['workspaceMemberCandidates'];
    usersSelectedCount: number;
  }) => actionCreator(at.FILTER_USERS, filteredPayload),

  sortUsers: (sortedPayload: {
    sortUsers: { sortDataKey: 'username'; direction: string };
    users: ListCandidatesWithSelectedAttr;
  }) => actionCreator(at.SORT_USERS, sortedPayload),
  sortGroups: (sortPayload: {
    sortGroups: { sortDataKey: 'name' | 'description'; direction: string };
    groups: ListRBACGroupsWithSelectedAttr;
  }) => actionCreator(at.SORT_GROUPS, sortPayload),
};

export type AddUserActions = Actions<typeof actions>;

export interface RowData {
  id: string;
  userId: number;
  selected?: boolean;
}

export type BreadCrumbType = {
  translationKey: string;
  linkDestination?: string;
};

export interface WorkspaceCandidateWithSelectedAttr
  extends WorkspaceMemberCandidateV1 {
  selected?: boolean;
}

export interface RbacGroupWithSelectedAttr extends RbacGroupV1 {
  selected?: boolean;
}

export type ListCandidatesWithSelectedAttr =
  WorkspaceCandidateWithSelectedAttr[];
export type ListRBACGroupsWithSelectedAttr = RbacGroupWithSelectedAttr[];

export const filterUsers = (
  query: string,
  users: ListCandidatesWithSelectedAttr,
  dispatch: Dispatch<AddUserActions>,
) => {
  const userNameMatches = ({ username }: WorkspaceMemberCandidateV1) =>
    username.toLowerCase().includes(query);
  const filteredUsers = (users || []).filter(userNameMatches);
  const usersSelectedCount = filteredUsers.reduce(
    (acc, u) => (u.selected ? acc + 1 : acc),
    0,
  );

  dispatch(
    actions.filterUsers({
      query,
      filteredUsers,
      usersSelectedCount,
    }),
  );
};

export const getUsers = async (
  workspaceId: string,
  query: string,
  dispatch: Dispatch<AddUserActions>,
  t: TFunction<'codeDelivery'>,
) => {
  dispatch(actions.getUsersLoading());

  const response = await unwrapApiResponse(
    WorkspacesV1Service.listMemberCandidatesV1({ workspaceId }),
  );
  if (isErrorResponse(response)) {
    dispatch(
      actions.getUsersError(formatError(response, t('userList.error.fetch'))),
    );
    return;
  }

  dispatch(actions.getUsersComplete(response));
  filterUsers(query, response.workspaceMemberCandidates, dispatch);
};

export const toggleAllUsersSelection = (
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
  isChecked: boolean,
) => {
  dispatch(
    actions.toggleAllUsersSelection({
      indeterminateUserState: false,
      selectAll: isChecked,
    }),
  );
};

export const toggleAllGroupsSelection = (
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
  isChecked: boolean,
) => {
  dispatch(
    actions.toggleAllGroupsSelection({
      indeterminateGroupState: false,
      selectAll: isChecked,
    }),
  );
};

export const toggleAllSelectionOfType = (
  type: 'users' | 'groups',
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
  isChecked: boolean,
) => {
  if (type === 'users') {
    toggleAllUsersSelection(state, dispatch, isChecked);
  } else if (type === 'groups') {
    toggleAllGroupsSelection(state, dispatch, isChecked);
  }
};

export const toggleUserRowSelection = (
  isChecked: boolean,
  rowData: RowData,
  users: ListCandidatesWithSelectedAttr,
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
) => {
  if (state.selectAllUsers) {
    dispatch(
      actions.toggleAllUsersSelection({
        indeterminateUserState: !state.indeterminateUserState,
        selectAll: !state.selectAllUsers,
      }),
    );
  }

  const updatedUsers = [...users];
  if (isChecked !== rowData.selected) {
    const user = updatedUsers.find((u) => u.userId === rowData.userId);
    if (user) {
      user.selected = !user.selected;
    }
  }

  const usersSelectedCount = updatedUsers.reduce(
    (acc, u) => (u.selected ? acc + 1 : acc),
    0,
  );
  dispatch(
    actions.toggleUserRowSelection({ users: updatedUsers, usersSelectedCount }),
  );
  filterUsers(state.query, updatedUsers, dispatch);
};

export const toggleGroupRowSelection = (
  isChecked: boolean,
  rowData: RowData,
  groups: ListRBACGroupsWithSelectedAttr,
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
) => {
  if (state.selectAllGroups) {
    dispatch(
      actions.toggleAllGroupsSelection({
        indeterminateGroupState: !state.indeterminateGroupState,
        selectAll: !state.selectAllGroups,
      }),
    );
  }
  const updatedGroups = [...groups];
  if (isChecked !== rowData.selected) {
    const group = updatedGroups.find((g) => g.id === rowData.userId);
    if (group) {
      group.selected = !group.selected;
    }
  }
  const groupsSelectedCount = updatedGroups.reduce(
    (acc, g) => (g.selected ? acc + 1 : acc),
    0,
  );

  dispatch(
    actions.toggleGroupRowSelection({
      groups: updatedGroups,
      groupsSelectedCount,
    }),
  );
};

export const USERNAME_SORTS = {
  username: (
    { username: a }: WorkspaceMemberCandidateV1,
    { username: b }: WorkspaceMemberCandidateV1,
  ) => stringCompare(a, b),
};
export const sortUsers = (
  direction: string,
  sortDataKey: 'username',
  users: ListWorkspaceMemberCandidatesResponseV1['workspaceMemberCandidates'],
  query: string,
  dispatch: Dispatch<AddUserActions>,
) => {
  const multiplier = direction === 'asc' ? 1 : -1;
  const sortedUsers = [...users].sort(
    (a, b) => multiplier * USERNAME_SORTS[sortDataKey](a, b),
  );
  dispatch(
    actions.sortUsers({
      users: sortedUsers,
      sortUsers: { direction, sortDataKey },
    }),
  );
};

export const sortGroups = (
  direction: string,
  sortDataKey: 'name' | 'description',
  groups: ListRBACGroupsWithSelectedAttr,
  query: string,
  dispatch: Dispatch<AddUserActions>,
) => {
  const multiplier = direction === 'asc' ? 1 : -1;
  const sortedGroups = [...groups].sort(
    (a, b) => multiplier * GROUP_SORTS[sortDataKey](a, b),
  );
  dispatch(
    actions.sortGroups({
      groups: sortedGroups,
      sortGroups: { direction, sortDataKey },
    }),
  );
};

export const getGroups = async (
  workspaceId: string,
  query: string,
  dispatch: Dispatch<AddUserActions>,
  t: TFunction<'codeDelivery'>,
  sort: { direction: string; sortDataKey: 'name' | 'description' },
) => {
  dispatch(actions.getGroupsLoading());
  const response = await unwrapApiResponse(
    GroupsV1Service.listGroupsV1({ workspaceId }),
  );
  if (isErrorResponse(response)) {
    dispatch(
      actions.getGroupsError(formatError(response, t('groupList.error.fetch'))),
    );
    return;
  }
  dispatch(actions.getGroupsComplete(response));

  sortGroups(
    sort.direction,
    sort.sortDataKey,
    response.groups,
    query,
    dispatch,
  );
};

export const addUsersToWorkspace = async (
  workspaceId: string,
  t: TFunction<'codeDelivery'>,
  state: AddUserState,
  dispatch: Dispatch<AddUserActions>,
) => {
  dispatch(actions.addUsersLoading());

  const userDomains = state.cachedUsers
    .filter((user) => user.selected)
    .map((user) => user.userId);
  const isAnyGroupSelected = state.groups.some((group) => group.selected);
  const isAnyCachedUserSelected = state.cachedUsers.some(
    (user) => user.selected,
  );

  if (!isAnyCachedUserSelected || !isAnyGroupSelected) {
    dispatch(actions.addUsersError(t('addUsers.users.error.addNewUsers')));
    return;
  }

  const groupIdsSelected = state.groups.reduce((acc: number[], group) => {
    if (group.selected) {
      acc.push(group.id);
    }
    return acc;
  }, []);

  const addToWorkspaceResponse = await unwrapApiResponse(
    WorkspacesV1Service.addMembersV1({
      workspaceId,
      requestBody: { userIds: [...userDomains] },
    }),
  );

  if (isErrorResponse(addToWorkspaceResponse)) {
    dispatch(actions.addUsersError(formatError(addToWorkspaceResponse)));
    return;
  }

  const addUsersToGroupsResponseList = await Promise.all(
    groupIdsSelected.map((groupId) => {
      return unwrapApiResponse(
        GroupsV1Service.addGroupMembersV1({
          groupId,
          requestBody: { userIds: userDomains },
        }),
      );
    }),
  );

  const responseListError = addUsersToGroupsResponseList.reduce((acc, prom) => {
    if (acc) {
      return acc;
    }
    if (isErrorResponse(prom)) {
      return formatError(prom);
    }
    return null;
  }, null as null | string);

  if (responseListError) {
    dispatch(actions.addUsersError(responseListError));
    return;
  }

  dispatch(actions.addUsersComplete());
};
