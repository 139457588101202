import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Text, Button, TooltipHoverArea } from '@puppet/react-components';
import ConditionalRender from 'inventory/components/ConditionalRender';
import { ESTATE_REPORTING_FILTER_OPERATORS } from 'utils/constants';

import './FilterTag.scss';

const FilterTag = ({ filter, isSaved, isEditing, onDelete }) => {
  const { t } = useTranslation('inventory');

  let displayValue = filter.value;

  if (typeof filter.value === 'boolean') {
    displayValue = filter.value.toString();
  } else if (filter.operator) {
    displayValue = `'${filter.value}'`;
  }

  const label = filter.fact
    ? `${t('compoundFiltering.filterTag.fact.label')} '${filter.fact}'`
    : filter.label;

  const displayOperator = filter.operator
    ? ESTATE_REPORTING_FILTER_OPERATORS[filter.operator.toLowerCase()].symbol
    : '=';

  return (
    <div
      data-testid={`filter-tag-text-${label}-${displayOperator}-${displayValue}`}
      className={classnames({
        'filter-tag-container': true,
        'filter-tag-editing': isEditing || (!isSaved && !isEditing),
        'filter-tag-fixed': !isEditing && isSaved,
      })}
    >
      <Text className="filter-tag-label">
        <strong>{label}</strong>
      </Text>
      {displayOperator && (
        <Text className="filter-tag-label" color="subtle">
          {displayOperator}
        </Text>
      )}
      <Text>{displayValue}</Text>
      <ConditionalRender enable={isEditing}>
        <TooltipHoverArea
          tooltip={t('compoundFiltering.filterTag.remove')}
          anchor="top"
        >
          <Button
            data-testid={`filter-tag-delete-${label}-${displayOperator}-${displayValue}`}
            type="transparent"
            icon="close"
            onClick={() => onDelete(filter.filter)}
          />
        </TooltipHoverArea>
      </ConditionalRender>
    </div>
  );
};

FilterTag.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string.isRequired,
    fact: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.string.isRequired,
    filter: PropTypes.shape({}).isRequired,
  }).isRequired,
  isSaved: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default FilterTag;
