import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Text } from '@puppet/react-components';
import { AppEventV1, TriggerEventV1 } from '@puppet/cd4pe-client-ts';
import ConditionalRender from '@components/ConditionalRender';
import {
  consolidateStatusMap,
  buildStatusMap,
  isAllTerminal,
  statusBadgeType,
  EventStatus,
} from '../../../../utils';
import Event from '../Event';
import StageEvent from '../StageEvent';

interface Props {
  event: TriggerEventV1;
  onClickWebhookEvent: (event: TriggerEventV1) => void;
}

const PipelineEvent = ({ event, onClickWebhookEvent }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [isOpen, setIsOpen] = useState(false);
  const statusMap = buildStatusMap(
    Object.values(event.eventsByStage ?? []).flat(),
  );
  const pipelineStatusMap = consolidateStatusMap(statusMap);
  let runningStatus = t('viewPipeline.events.table.row.details.status.pending');

  if (isAllTerminal(pipelineStatusMap)) {
    runningStatus = t('viewPipeline.events.table.row.details.status.complete');
  }

  const stateBadges = Object.entries(pipelineStatusMap)
    .map(([key, value]) => {
      if (!value) return null;
      return (
        <div>
          <Badge weight="subtle" type={statusBadgeType(key as EventStatus)}>
            {`${value} ${t(`viewPipeline.events.status.${key.toLowerCase()}`)}`}
          </Badge>
        </div>
      );
    })
    .filter(Boolean);

  const isWebhookPushRequest =
    !event?.webhookEvent?.pullRequestId &&
    !event?.webhookEvent?.pullRequestNumber;

  return (
    <>
      <div className="pipeline-event">
        <div className="pipeline-event__status">
          <Text size="tiny">{runningStatus.toUpperCase()}</Text>
          {stateBadges}
        </div>
        <Button
          type="transparent"
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          onClick={() => setIsOpen(!isOpen)}
          data-testid={`pipeline-event-toggle-${event.id}`}
        />
      </div>
      <ConditionalRender enable={isOpen}>
        {[
          ...Object.values(event.eventsByStage ?? []).map(
            (stageEvents: AppEventV1[], index: number) => {
              const stageNumber = index + 1;
              const name =
                event.stageNames?.[stageNumber] ??
                t('viewPipeline.events.table.row.details.defaultStageName', {
                  stageNumber,
                })!;
              return <StageEvent key={name} name={name} events={stageEvents} />;
            },
          ),
          <ConditionalRender enable={!!event.webhookEvent}>
            <Event
              key={event.id}
              icon={isWebhookPushRequest ? 'share' : 'export'}
              webhookType={
                isWebhookPushRequest
                  ? t('viewPipeline.events.table.row.details.webhook.push')
                  : t('viewPipeline.events.table.row.details.webhook.pull')
              }
              startTime={event?.webhookEvent?.eventTime}
              actionButton={
                <Button
                  type="secondary"
                  icon="hook"
                  onClick={() => onClickWebhookEvent(event)}
                >
                  {t(
                    'viewPipeline.events.table.row.details.webhook.viewWebhook',
                  )}
                </Button>
              }
            />
          </ConditionalRender>,
        ]}
      </ConditionalRender>
    </>
  );
};

export default PipelineEvent;
