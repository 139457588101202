import React from 'react';
import { useTranslation } from 'react-i18next';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import useListControlReposV1 from '@services/cd4pe/controlRepos';
import Cd4peError from '@components/Cd4peError';
import { CodeProjectDetailsV1 } from '@utils/api/cd4pe';
import useDebouncedSearch from '@hooks/useDebouncedSearch';
import AddGroupPermissionSubsetModal, {
  ModalProps,
} from './PrivilegeSubsetModal';

const getTableTranslations = (
  loading: boolean,
  data: CodeProjectDetailsV1[][],
  searchTerm: string,
  t: Function,
) => {
  if (loading) {
    return {
      header: '',
      message: '',
    };
  }

  if (data?.length === 0) {
    if (searchTerm !== '') {
      return {
        header: t('privilege.subset.permissions.table.empty.search.header'),
        message: t(
          'privilege.subset.permissions.controlRepos.table.empty.search.message',
        ),
      };
    }

    return {
      header: t('privilege.subset.permissions.table.empty.header'),
      message: t(
        'privilege.subset.permissions.controlRepos.table.empty.message',
      ),
    };
  }

  return {
    header: '',
    message: '',
  };
};

const ControlReposPrivilegesSubsetModal = ({
  setOpen,
  open,
  subsets,
  setSubsets,
}: ModalProps) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const { searchTerm, appliedSearchTerm, handleSearch } = useDebouncedSearch();

  const { isLoading, error, data, fetchNextPage, isFetching, hasNextPage } =
    useListControlReposV1({
      workspaceId,
      pageSize: 10,
      prefix: appliedSearchTerm,
    });

  const controlRepos = [...(data?.pages ?? [])].map(
    (page) => page.controlRepos,
  );

  const tableTranslations = getTableTranslations(
    isLoading,
    controlRepos,
    appliedSearchTerm,
    t,
  );

  if (!open) {
    return null;
  }

  return (
    <>
      <AddGroupPermissionSubsetModal
        onClose={() => setOpen(false)}
        onSearchChange={handleSearch}
        searchSubsetTerm={searchTerm}
        loading={isLoading || isFetching}
        data={controlRepos}
        setSelectedSubsets={setSubsets}
        initialSubsets={subsets}
        title={t('privilege.controlrepos.label')}
        searchPlaceHolder={t(
          'privilege.subset.permissions.controlRepos.search',
        )}
        tableTranslations={tableTranslations}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
      <Cd4peError error={error} />
    </>
  );
};

export default ControlReposPrivilegesSubsetModal;
