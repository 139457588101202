import 'regenerator-runtime/runtime';
import { OpenAPI } from '@puppet/cd4pe-client-ts';
import { getAuthCookie } from '../helpers';

const authCookie = getAuthCookie();

if (authCookie) {
  OpenAPI.HEADERS = {
    authorization: authCookie,
    Accept: '*/*',
  };
}
OpenAPI.BASE = '/cd4pe/api';
