import { Dispatch } from 'react';
import {
  PERMISSION_OBJECTS,
  PERMISSION_ACTIONS,
  PERMISSION_INSTANCES,
} from '@utils/constants';
import actionCreator, { Actions } from '@utils/actionCreator';
import {
  PermittedV1Service,
  formatError,
  isApiError,
  isErrorResponse,
  unwrapApiResponse,
  WorkspaceIdV1,
  PermittedV1,
  PermissionTripletV1,
  EvaluatePermissionsRequestV1,
  isGenericError,
} from '@utils/api/cd4pe';
import * as ACTION_TYPES from './actionTypes';

export const actions = {
  // evaluate permissions
  evaluatePermissionsStart: () =>
    actionCreator(ACTION_TYPES.EVAL_PERMISSIONS_START),
  evaluatePermissionsComplete: (isPermitted: PermittedV1) =>
    actionCreator(ACTION_TYPES.EVAL_PERMISSIONS_COMPLETE, isPermitted),
  evaluatePermissionsError: (error: string | null) =>
    actionCreator(ACTION_TYPES.EVAL_PERMISSIONS_ERROR, error),
};

export type NodeExplorerActions = Actions<typeof actions>;

export const evaluatePermissions = async (
  workspaceId: WorkspaceIdV1,
  userId: number,
  dispatch: Dispatch<NodeExplorerActions>,
) => {
  // build triplet to verify view inventory permissions
  const viewInventoryTriplet: PermissionTripletV1 = {
    objectType: PERMISSION_OBJECTS.SETTING as PermissionTripletV1['objectType'],
    action: PERMISSION_ACTIONS.READ as PermissionTripletV1['action'],
    instance: PERMISSION_INSTANCES.INVENTORY,
  };

  // build object to check both permissions for this user
  const permissionsToCheck: EvaluatePermissionsRequestV1 = {
    token: userId,
    permissions: [viewInventoryTriplet],
  };

  // build request object
  const request = {
    workspaceId,
    requestBody: permissionsToCheck,
  };
  dispatch(actions.evaluatePermissionsStart());

  // perform the check
  const response = await unwrapApiResponse(
    PermittedV1Service.evaluatePermissionsV1(request),
  );

  if (
    (isApiError(response) && response.kind === 'NOTFOUND') ||
    (isGenericError(response) && response.status === 404) ||
    !response
  ) {
    dispatch(actions.evaluatePermissionsError('notfound'));
    return;
  }

  if (isErrorResponse(response)) {
    dispatch(actions.evaluatePermissionsError(formatError(response)));
    return;
  }

  // return an array of the permission results
  dispatch(actions.evaluatePermissionsComplete(response));
};
