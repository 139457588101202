import actionCreator, { Actions } from '@utils/actionCreator';
import { JobTemplateV1 } from '@utils/api/cd4pe';
import { Dispatch } from 'react';
import * as at from './actionTypes';

export interface JobTemplateFormValues {
  name: string;
  description: string;
  commands: string;
  commandsSuccess: string;
  commandsError: string;
  envVars: string;
  runOnSharedHardware: boolean;
  runOnDefaultImage: boolean;
  hardwareCapabilities: string[];
  runInDocker: boolean;
  imageName: string;
  dockerArgs: string;
}

export const jobTemplateActions = {
  editForm: (payload: Partial<JobTemplateFormValues>) =>
    actionCreator(at.EDIT_FORM, payload),
  toggleCommandsView: () => actionCreator(at.TOGGLE_COMMANDS_VIEW),
  initializeFormState: (payload: JobTemplateFormValues) =>
    actionCreator(at.INITIALIZE_FORM_STATE, payload),
  submitAttempted: () => actionCreator(at.SUBMIT_ATTEMPTED),
  setRunOnSharedHardware: (state: boolean) =>
    actionCreator(at.SET_RUN_ON_SHARED_HARDWARE, state),
  setRunInDocker: (state: boolean) =>
    actionCreator(at.SET_RUN_IN_DOCKER, state),
  setCommands: (commands: string) => actionCreator(at.SET_COMMANDS, commands),
};

export type JobTemplateActions = Actions<typeof jobTemplateActions>;

const getDockerConfig = (jobTemplate: JobTemplateV1 | null) => {
  if (!jobTemplate) {
    return {
      runInDocker: true,
      imageName: '',
      dockerArgs: '',
      runOnDefaultImage: true,
    };
  }

  if (!jobTemplate.config.vmImage) {
    return {
      runInDocker: jobTemplate.config.useDefaultImage,
      imageName: '',
      dockerArgs: jobTemplate.config.vmArgs || '',
      runOnDefaultImage: jobTemplate.config.useDefaultImage,
    };
  }

  return {
    runInDocker: true,
    imageName: jobTemplate.config.vmImage || '',
    dockerArgs: jobTemplate.config.vmArgs || '',
    runOnDefaultImage: false,
  };
};

const getHardwareConfig = (jobTemplate: JobTemplateV1) => {
  if (!jobTemplate || jobTemplate.config.sharedHardware) {
    return {
      runOnSharedHardware: true,
      hardwareCapabilities: [],
      ...getDockerConfig(null),
    };
  }

  return {
    runOnSharedHardware: false,
    hardwareCapabilities: jobTemplate.config.buildCapabilities,
    ...getDockerConfig(jobTemplate),
  };
};

export const initializeFormState = (
  jobTemplate: JobTemplateV1 | null,
  dispatch: Dispatch<JobTemplateActions>,
) => {
  if (!jobTemplate) {
    return;
  }

  const formState = {
    name: jobTemplate.name,
    description: jobTemplate.description || '',
    commands: jobTemplate.manifest.build,
    commandsSuccess: jobTemplate.manifest.onSuccess || '',
    commandsError: jobTemplate.manifest.onError || '',
    envVars: jobTemplate.config.buildVars || '',
    ...getHardwareConfig(jobTemplate),
  };

  dispatch(jobTemplateActions.initializeFormState(formState));
};
