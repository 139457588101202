import { useInfiniteQuery } from '@tanstack/react-query';
import {
  Cd4peApiError,
  handleQueryRequest,
  EventsV1Service,
  InfiniteQueryHook,
} from '@utils/api/cd4pe';

export const LIST_TRIGGER_EVENTS_QUERY_TAG = 'triggerEvents';

type UseListTriggerEventsV1 = InfiniteQueryHook<
  typeof EventsV1Service,
  'listTriggerEvents',
  Cd4peApiError
>;

export const useListTriggerEventsV1: UseListTriggerEventsV1 = (payload) =>
  useInfiniteQuery(
    [
      LIST_TRIGGER_EVENTS_QUERY_TAG,
      payload.workspaceId,
      payload.controlRepoName || payload.moduleName,
      payload.pipelineId,
      payload.eventId,
    ],
    ({ pageParam = '' }) =>
      handleQueryRequest(
        EventsV1Service.listTriggerEvents({
          ...payload,
          nextMarker: pageParam,
        }),
      ),
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageDetails.nextMarker ?? undefined,
    },
  );
