import { isInitialized as isInitializedSelector } from '@state/session/selectors';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import React from 'react';
import { useAppSelector } from '@hooks/redux';
import { useDispatch } from 'react-redux';
import { initializeApp } from '@state/session/session';
import PageLoader from '@components/PageLoader';
import { LINKS } from 'src/routes';
import AccessError from '@components/AccessError';
import useAuth from '@hooks/useAuth';

const getLoginRoute = (locationPathname: string) => {
  if (
    locationPathname !== '' &&
    locationPathname !== '/' &&
    locationPathname !== LINKS.login({})
  ) {
    return LINKS.login({ query: { url: locationPathname } });
  }

  return LINKS.login({});
};

const ProtectedRoute = () => {
  const { user, workspaces, isLoading, isError } = useAuth();
  const { workspace, username } = useParams<'workspace' | 'username'>();
  const isInitialized = useAppSelector(isInitializedSelector);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError) {
    return <Navigate replace to={getLoginRoute(pathname)} />;
  }

  if (
    (username && username !== user?.userName) ||
    (workspace && !workspaces?.find((w) => w.name === workspace))
  ) {
    return <AccessError code="404" />;
  }

  if (!isInitialized && user) {
    dispatch(initializeApp(user, workspaces));
  }

  return <Outlet />;
};

export default ProtectedRoute;
