import React, { PropsWithChildren } from 'react';
import { Icon } from '@puppet/react-components';

interface Props {
  icon: string;
  className?: string;
}

const ErrorWrapper = ({
  icon,
  children,
  className = '',
}: PropsWithChildren<Props>) => (
  <div className={`cd4pe-error__container ${className}`}>
    <Icon className="cd4pe-error__icon" type={icon} />
    <div className="cd4pe-error__body">{children}</div>
  </div>
);

export default ErrorWrapper;
