import React, { ReactElement, useState } from 'react';
import {
  Input,
  Modal,
  SidePanel,
  Button,
  Heading,
} from '@puppet/react-components';
import { Table } from '@puppet/data-grid';
import { CodeProjectDetailsV1 } from '@utils/api/cd4pe';
import CursorPagination from '@codeDelivery/components/CursorPagination';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useGetGroupV1 } from '@services/cd4pe/groups';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type SetSelectedSubsets = (newSubset: string[]) => void;
export interface ModalProps {
  open: boolean;
  setOpen: Function;
  subsets: string[];
  setSubsets: SetSelectedSubsets;
}

interface tableProps {
  header: string;
  message: string;
}

interface props {
  onClose: Function;
  onSearchChange: Function;
  searchSubsetTerm: string;
  loading: boolean;
  data: CodeProjectDetailsV1[][];
  setSelectedSubsets: SetSelectedSubsets;
  initialSubsets: string[];
  title: string;
  tableTranslations: tableProps;
  searchPlaceHolder: string;
  hasNextPage: boolean | undefined;
  fetchNextPage: Function;
}

const checkIfIndeterminateState = (
  subset: CodeProjectDetailsV1[],
  selectedData: Set<String>,
) => {
  if (subset.every((s) => selectedData.has(s.name))) {
    return { checkAll: true, indeterminateState: false };
  }
  if (subset.some((s) => selectedData.has(s.name))) {
    return { checkAll: false, indeterminateState: true };
  }

  return { checkAll: false, indeterminateState: false };
};

export const useGetGroupV1WithParams = () => {
  const workspaceId = useWorkspaceDomain();
  const [searchParams] = useSearchParams('');
  const groupId = searchParams.get('groupId');

  const query = useGetGroupV1(
    {
      workspaceId,
      groupId: Number(groupId),
    },
    {
      enabled: groupId !== null,
    },
  );
  return query;
};

const PrivilegeSubsetModal = ({
  onClose,
  onSearchChange,
  searchSubsetTerm,
  loading,
  data,
  setSelectedSubsets,
  initialSubsets,
  title,
  tableTranslations,
  searchPlaceHolder,
  hasNextPage,
  fetchNextPage,
}: props) => {
  const [selectedData, setSelectedData] = useState<Set<string>>(
    new Set(initialSubsets),
  );
  const [currentPage, setCurrentPage] = useState(0);
  const currentPageData = data[currentPage] ?? [];
  const { checkAll, indeterminateState } = checkIfIndeterminateState(
    currentPageData,
    selectedData,
  );
  const { t } = useTranslation('codeDelivery');
  const { data: group } = useGetGroupV1WithParams();

  const columns = [
    { label: t('privilege.subset.column.name'), dataKey: 'name' },
    { label: t('privilege.subset.column.repo'), dataKey: 'srcRepoDisplayName' },
  ];

  const onHeaderChecked = async (checked: boolean) => {
    if (currentPageData.length === 0) {
      return;
    }

    const newSelectedData = new Set(selectedData);
    if (checked) {
      currentPageData.forEach((c) => newSelectedData.add(c.name));
      setSelectedData(newSelectedData);
    } else {
      currentPageData.forEach((c) => newSelectedData.delete(c.name));
      setSelectedData(newSelectedData);
    }
  };

  const onRowChecked = (checked: boolean, name: string) => {
    if (currentPageData.length === 0) {
      return;
    }

    const newSelectedData = new Set(selectedData);
    if (checked) {
      setSelectedData(newSelectedData.add(name));
    } else {
      newSelectedData.delete(name);
      setSelectedData(newSelectedData);
    }
  };

  const pageSelect = (newPage: number) => {
    if (newPage > currentPage && hasNextPage) {
      fetchNextPage();
    }
    setCurrentPage(newPage);
  };

  const listSelectedSubsets: ReactElement[] = [];
  selectedData.forEach((sub) => {
    listSelectedSubsets.push(
      <li key={sub}>
        <div>{sub}</div>
        <Button
          type="transparent"
          icon="trash"
          onClick={() => onRowChecked(false, sub)}
          aria-label={t(
            'privilege.subset.subset.category.modal.delete.ariaLabel',
            {
              name: title,
            },
          )}
        />
      </li>,
    );
  });

  const tData = currentPageData.map((cp) => {
    if (selectedData.has(cp.name)) {
      return { ...cp, selected: true };
    }
    return cp;
  });

  return (
    <Modal onClose={onClose} className="privilege-modal">
      <Heading as="h2">
        {t('privilege.subset.permissions.modal.title', {
          category: title,
          name: group?.name,
        })}
      </Heading>
      <div className="privilege-modal__content">
        <div className="privilege-modal__table">
          <Input
            className="privilege-modal__list_search"
            placeholder={searchPlaceHolder}
            value={searchSubsetTerm}
            onChange={(value: string) => onSearchChange(value)}
            name="searchCategorySubsets"
            icon="search"
          />
          <Table
            data-testid="data-table"
            className="privilege-modal__table"
            data={tData}
            loading={loading}
            columns={columns}
            selectable
            onRowChecked={(checked: boolean, rowData: CodeProjectDetailsV1) =>
              onRowChecked(checked, rowData.name)
            }
            onHeaderChecked={onHeaderChecked}
            headerCheckState={checkAll}
            headerIndeterminateState={indeterminateState}
            emptyStateHeader={tableTranslations.header}
            emptyStateMessage={tableTranslations.message}
          />
          <CursorPagination
            pageSelect={pageSelect}
            currentPage={currentPage}
            hasNextPage={hasNextPage || !!data[currentPage + 1]}
            hasPreviousPage={!!data[currentPage - 1]}
          />
        </div>
        <SidePanel
          border
          className="privilege-modal__sidebar"
          title={t(title)}
          type="toolbar"
          onClose={() => onHeaderChecked(false)}
          closeButtonProps={{
            onClick: () => onHeaderChecked(false),
            'aria-label': t(
              'privilege.subset.subset.category.modal.delete.ariaLabel',
              { name: title },
            ),
          }}
          closeButtonIcon="trash"
        >
          <ul className="privilege-modal__list">{listSelectedSubsets}</ul>
        </SidePanel>
      </div>

      <div className="privilege-modal__border">
        <Modal.Actions>
          <Button
            onClick={() => {
              setSelectedSubsets(Array.from(selectedData));
              onClose();
            }}
          >
            {t('privilege.subset.modal.save')}
          </Button>
          <Button type="tertiary" onClick={() => onClose()}>
            {t('privilege.subset.modal.cancel')}
          </Button>
        </Modal.Actions>
      </div>
    </Modal>
  );
};

export default PrivilegeSubsetModal;
