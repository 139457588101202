/* eslint-disable dot-notation */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '@puppet/react-components';
import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import format from 'date-fns/format';
import { WEB_API_TOKEN_DEFAULT_AGE_IN_DAYS } from '@utils/constants';
import { add, isAfter } from 'date-fns';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import { useCreateTokenV1 } from '@services/cd4pe/auth';
import Cd4peError from '@components/Cd4peError';
import TokenCreatedDialog from '../TokenCreatedDialog';

type FormValues = {
  name: string;
  expiresOn: string;
};

const getDefaultExpires = () =>
  format(
    add(new Date(), {
      days: WEB_API_TOKEN_DEFAULT_AGE_IN_DAYS,
    }),
    'yyyy-MM-dd',
  );

const AccessTokenForm = () => {
  const { t } = useTranslation('codeDelivery');
  const [expiresError, setExpiresError] = useState(undefined);
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    expiresOn: getDefaultExpires(),
  });
  const [token, setToken] = useState('');
  const [formKey, setFormKey] = useState(1);
  const appDispatch = useAppDispatch();

  const createToken = useCreateTokenV1();

  const isDateValid = (chosenDate: string | number | Date) => {
    if (isBefore(startOfDay(new Date(chosenDate)), startOfDay(new Date()))) {
      setExpiresError(t('profile.tokens.form.field.expires.error'));
      return false;
    }

    if (isAfter(new Date(chosenDate), add(new Date(), { years: 1 }))) {
      setExpiresError(
        t('profile.tokens.form.field.expires.max.error', { days: 365 }),
      );
      return false;
    }

    setExpiresError(undefined);
    return true;
  };

  const onFormChange = (_: keyof FormValues, values: FormValues) => {
    if (values.expiresOn !== '') {
      isDateValid(values.expiresOn);
    }
    setFormValues(values);
  };

  const onTokenCreatedDialogClose = () => {
    setToken('');
    setFormKey(formKey + 1);
    setFormValues({
      name: '',
      expiresOn: getDefaultExpires(),
    });
  };

  const onSubmit = (values: FormValues) => {
    if (!isDateValid(values.expiresOn)) {
      // eslint-disable-next-line no-useless-return
      return;
    }

    createToken.mutate(
      {
        requestBody: {
          name: formValues.name,
          expiresOn: formValues.expiresOn,
        },
      },
      {
        onSuccess: (newToken) => {
          setToken(newToken);
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('profile.tokens.created.alerts.success', {
                name: values.name,
              }),
            }),
          );
        },
      },
    );
  };

  return (
    <>
      <Form
        key={formKey}
        className="access-token-form"
        values={formValues}
        onChange={onFormChange}
        submitLabel={t('profile.tokens.form.button.create.label')}
        onSubmit={onSubmit}
        submittable
        submitting={createToken.isLoading}
        submitDisabled={!!expiresError}
        error={expiresError}
        data-testid="create-token-form"
      >
        <Form.Field
          type="text"
          name="name"
          label={t('profile.tokens.form.field.name.label')}
          data-testid="create-token-name"
          required
        />
        <Form.Field
          type="date"
          min={format(new Date(), 'yyyy-MM-dd')}
          max={format(addDays(new Date(), 365), 'yyyy-MM-dd')}
          name="expiresOn"
          label={t('profile.tokens.form.field.expires.label')}
          data-testid="create-token-expires-on"
          required
        />
      </Form>
      {token !== '' && (
        <TokenCreatedDialog onClose={onTokenCreatedDialogClose} token={token} />
      )}
      <Cd4peError error={createToken.error} />
    </>
  );
};

export default AccessTokenForm;
