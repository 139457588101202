import { Cd4peApiError, PEIntegrationV2 } from '@utils/api/cd4pe';
import { AddPeIntegrationActions, AuthType, FormType } from './actions';
import { AUTO_INTEGRATION_FORM_ACTION_TYPES } from '../AutoIntegrationForm';
import {
  ManualIntegrationFormValues,
  MANUAL_INTEGRATION_FORM_ACTION_TYPES,
} from './components/ManualIntegrationForm';
import { IA_CONFIG_FORM_ACTION_TYPES } from './components/IaConfigForm/IaConfigForm';
import * as at from './actionTypes';

export interface AddPeIntegrationState {
  formType: FormType;
  name: string;
  consoleAddress: string;
  authType: AuthType;
  peUsername: string;
  password: string;
  tokenLifetime: string;
  apiToken: string;
  puppetServer: string;
  puppetDb: string;
  codeManager: string;
  orchestrator: string;
  classifier: string;
  caCert: string;
  iaCert: string;
  iaPrivateKey: string;
  iaConfigModal: boolean;
  discoverIntegrationLoading: boolean;
  discoverIntegrationError: Cd4peApiError | null;
  createIntegrationLoading: boolean;
  createIntegrationError: Cd4peApiError | null;
  configureIaLoading: boolean;
  configureIaError: Cd4peApiError | null;
  newIntegration?: PEIntegrationV2 | null;
}

export const defaultAddPeIntegrationState: AddPeIntegrationState = {
  formType: 'auto',
  name: '',
  consoleAddress: '',
  authType: 'basic',
  peUsername: '',
  password: '',
  tokenLifetime: '6',
  apiToken: '',
  puppetServer: '',
  puppetDb: '',
  codeManager: '',
  orchestrator: '',
  classifier: '',
  caCert: '',
  iaCert: '',
  iaPrivateKey: '',
  iaConfigModal: false,
  discoverIntegrationLoading: false,
  discoverIntegrationError: null,
  createIntegrationLoading: false,
  createIntegrationError: null,
  configureIaLoading: false,
  configureIaError: null,
  newIntegration: null,
};

export const reducer = (
  state: AddPeIntegrationState,
  action: AddPeIntegrationActions,
): AddPeIntegrationState => {
  switch (action.type) {
    case at.DISCOVER_INTEGRATION_START:
      return {
        ...state,
        discoverIntegrationLoading: true,
      };
    case at.DISCOVER_INTEGRATION_COMPLETE:
      return {
        ...state,
        discoverIntegrationLoading: false,
        discoverIntegrationError: null,
        newIntegration: action.payload,
      };
    case at.DISCOVER_INTEGRATION_ERROR:
      return {
        ...state,
        discoverIntegrationLoading: false,
        discoverIntegrationError: action.payload,
      };
    case at.CREATE_INTEGRATION_START:
      return {
        ...state,
        createIntegrationLoading: true,
      };
    case at.CREATE_INTEGRATION_COMPLETE:
      return {
        ...state,
        createIntegrationLoading: false,
        createIntegrationError: null,
        newIntegration: action.payload,
      };
    case at.CREATE_INTEGRATION_ERROR:
      return {
        ...state,
        createIntegrationLoading: false,
        createIntegrationError: action.payload,
      };
    case at.CONFIGURE_IA_START:
      return {
        ...state,
        configureIaLoading: true,
      };
    case at.CONFIGURE_IA_COMPLETE:
      return {
        ...state,
        configureIaLoading: false,
        configureIaError: null,
        newIntegration: action.payload,
      };
    case at.CONFIGURE_IA_ERROR:
      return {
        ...state,
        configureIaLoading: false,
        configureIaError: action.payload,
      };
    case at.FORM_TYPE:
      return {
        ...state,
        peUsername: '',
        password: '',
        apiToken: '',
        discoverIntegrationError: null,
        createIntegrationError: null,
        iaConfigModal: false,
        formType: action.payload,
      };
    case at.AUTH_TYPE:
      return {
        ...state,
        authType: action.payload,
      };
    case at.IA_CONFIG_MODAL:
      return {
        ...state,
        iaConfigModal: action.payload,
      };
    case AUTO_INTEGRATION_FORM_ACTION_TYPES.NAME:
      return {
        ...state,
        name: action.payload,
      };
    case AUTO_INTEGRATION_FORM_ACTION_TYPES.CONSOLE_ADDRESS:
      return {
        ...state,
        consoleAddress: action.payload,
      };
    case AUTO_INTEGRATION_FORM_ACTION_TYPES.PE_USERNAME:
      return {
        ...state,
        peUsername: action.payload,
      };
    case AUTO_INTEGRATION_FORM_ACTION_TYPES.PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case AUTO_INTEGRATION_FORM_ACTION_TYPES.TOKEN_LIFETIME:
      return {
        ...state,
        tokenLifetime: action.payload,
      };
    case IA_CONFIG_FORM_ACTION_TYPES.PUPPET_SERVER:
      return {
        ...state,
        puppetServer: action.payload,
      };
    case IA_CONFIG_FORM_ACTION_TYPES.IA_CERT:
      return {
        ...state,
        iaCert: action.payload,
      };
    case IA_CONFIG_FORM_ACTION_TYPES.IA_PRIVATE_KEY:
      return {
        ...state,
        iaPrivateKey: action.payload,
      };
    case AUTO_INTEGRATION_FORM_ACTION_TYPES.API_TOKEN:
      return {
        ...state,
        apiToken: action.payload,
      };
    case MANUAL_INTEGRATION_FORM_ACTION_TYPES.PATCH_FORM: {
      const formValues = (
        Object.keys(action.payload) as (keyof ManualIntegrationFormValues)[]
      ).reduce((finalForm, key) => {
        if (key === 'ignoreSyncErrors' || key === 'compileBatchSize') {
          return finalForm;
        }
        return { ...finalForm, [key]: action.payload[key] ?? state[key] };
      }, {} as AddPeIntegrationState);

      return {
        ...state,
        ...formValues,
      };
    }
    default:
      throw new Error();
  }
};
