import React from 'react';
import {
  Checkbox,
  ButtonSelect,
  Button,
  Text,
  TooltipHoverArea,
} from '@puppet/react-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  ProjectPipelineStageV1,
  TriggerConditionV1,
} from '@puppet/cd4pe-client-ts';
import ConditionalRender from '@components/ConditionalRender';
import { PIPELINE_STAGE_PROMOTE_OPTIONS } from '../../../../utils';

interface Props {
  stage: ProjectPipelineStageV1;
  manageAsCode?: boolean;
  onAutoPromote: (value: boolean) => void;
  onTriggerCondition: (value: TriggerConditionV1) => void;
  onPromote?: () => void;
  reordering?: boolean;
  enablePromote: boolean;
}

const PromoteStage = ({
  stage,
  manageAsCode = false,
  onAutoPromote,
  onTriggerCondition,
  onPromote = () => {},
  reordering = false,
  enablePromote,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const options = [
    {
      value: PIPELINE_STAGE_PROMOTE_OPTIONS.ALL_SUCCESS,
      label: t('viewPipeline.stage.promote.dropdown.all.succeeded'),
    },
    {
      value: PIPELINE_STAGE_PROMOTE_OPTIONS.ALL_COMPLETE,
      label: t('viewPipeline.stage.promote.dropdown.all.completed'),
    },
    {
      value: PIPELINE_STAGE_PROMOTE_OPTIONS.ANY_SUCCESS,
      label: t('viewPipeline.stage.promote.dropdown.any.succeeded'),
    },
    {
      value: PIPELINE_STAGE_PROMOTE_OPTIONS.ANY_COMPLETE,
      label: t('viewPipeline.stage.promote.dropdown.any.completed'),
    },
  ];

  const autoPromoteLabel = options.find(
    (option) => option.value === stage.triggerCondition,
  )?.label;

  return (
    <div
      className="promote-stage"
      data-testid={`auto-promote-stage-item-${stage.stageNum}`}
    >
      <div className="promote-stage-arrow">
        <img src="/public/images/down_arrow_long.svg" alt="pipeline" />
      </div>
      <div className="promote-stage-actions">
        <ConditionalRender enable={manageAsCode}>
          <Text size="small">
            <Trans
              t={t}
              components={[<Text as="span" size="small" color="medium" />]}
            >
              {stage.triggerOn
                ? t('viewPipeline.stage.promote.autoPromote', {
                    promoteType: autoPromoteLabel || '',
                  })
                : t('viewPipeline.stage.promote.autoPromote.disabled')}
            </Trans>
          </Text>
        </ConditionalRender>
        <ConditionalRender enable={!manageAsCode}>
          <Checkbox
            name={`auto-promote-stage-${stage.stageNum}`}
            data-testid={`auto-promote-stage-check-${stage.stageNum}`}
            label={t('viewPipeline.stage.promote.autoPromote.label')}
            value={stage?.triggerOn}
            onChange={() => onAutoPromote(!stage?.triggerOn)}
          />
          {stage?.triggerOn ? (
            <div data-testid="auto-promote-options-select">
              <ButtonSelect
                type="text"
                value={
                  stage?.triggerCondition ??
                  PIPELINE_STAGE_PROMOTE_OPTIONS.ALL_SUCCESS
                }
                options={options}
                onChange={(value: TriggerConditionV1) =>
                  onTriggerCondition(value)
                }
              />
            </div>
          ) : null}
        </ConditionalRender>
        {!reordering && (
          <TooltipHoverArea
            tooltip={t('viewPipeline.stage.promote.tooltip')}
            anchor="bottom"
            disabled={enablePromote}
          >
            <Button
              className="promote-stage-button"
              type="text"
              onClick={() => onPromote()}
              disabled={!enablePromote}
            >
              {t('viewPipeline.stage.promote')}
            </Button>
          </TooltipHoverArea>
        )}
      </div>
    </div>
  );
};

export default PromoteStage;
