import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as PdsForm } from '@puppet/react-components';
import FormError from '@components/FormError';

type Props = {
  type: 'standard' | 'ldap' | 'root';
  submitting: boolean;
  onSubmit: Function;
  isError: boolean;
};

const Form = ({ type, onSubmit, submitting, isError }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <PdsForm
        key={type}
        className={`form ${type}-form`}
        data-testid={`${type}-login-form`}
        submittable
        actionsPosition="block"
        submitLabel={t(`login.form.${type}.buttons.signIn`)}
        onSubmit={onSubmit}
        submitting={submitting}
      >
        <PdsForm.Field
          type="email"
          name="email"
          autoComplete="username"
          label={t(`login.form.${type}.fields.username.label`)}
          required
          requiredFieldMessage={t(
            `login.form.${type}.fields.username.required`,
          )}
          placeholder={t(`login.form.${type}.fields.username.placeholder`)}
          data-testid="login-form-username-field"
        />
        <PdsForm.Field
          type={showPassword ? 'text' : 'password'}
          name="passwd"
          label={t(`login.form.${type}.fields.password.label`)}
          required
          requiredFieldMessage={t(
            `login.form.${type}.fields.password.required`,
          )}
          placeholder={t(`login.form.${type}.fields.password.placeholder`)}
          trailingButtonIcon={showPassword ? 'private' : 'eye'}
          trailingButtonProps={{
            'aria-label': t(`login.form.field.password.toggle.ariaLabel`),
          }}
          onClickTrailingButton={() => setShowPassword(!showPassword)}
          data-testid="login-form-password-field"
        />
      </PdsForm>
      {isError && (
        <FormError message={t(`login.form.${type}.error.failedLogin`)} />
      )}
    </>
  );
};

export default Form;
