import React, { useState } from 'react';
import {
  Modal,
  Heading,
  Form,
  RadioButton,
  Alert,
} from '@puppet/react-components';
import {
  CodeProjectDetailsV1,
  ProjectPipelineV1,
  ProjectTypeV1,
} from '@puppet/cd4pe-client-ts';
import { useTranslation } from 'react-i18next';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import ConditionalRender from '@components/ConditionalRender';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import Cd4peError from '@components/Cd4peError';
import { useCreatePipelineV1 } from '@services/cd4pe/pipelines';
import { useListBranchesV1 } from '@services/cd4pe/vcs';

interface Props {
  codeProject: CodeProjectDetailsV1;
  isOpen: boolean;
  onClose: (open: boolean) => void;
  onAddPipeline: (name: string) => void;
  projectType: ProjectTypeV1;
}

type BranchFormValues = {
  branch: string;
};

export type RegexFormValues = {
  regexbranch: string;
};

const AddPipelineDialog = ({
  isOpen,
  onClose,
  codeProject,
  projectType,
  onAddPipeline,
}: Props) => {
  const [branchType, setBranchType] = useState<'single' | 'regex'>('single');
  const [branchValues, setBranchValues] = React.useState<BranchFormValues>({
    branch: '',
  });

  const regexValue = 'feature_.*';

  const [regexValues, setRegexValues] = React.useState<RegexFormValues>({
    regexbranch: regexValue,
  });
  const [regexWarning, setRegexWarning] = useState(false);

  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const appDispatch = useAppDispatch();

  const { data, isLoading, error } = useListBranchesV1({
    workspaceId,
    provider: codeProject.srcRepoProvider,
    name: codeProject.srcRepoName,
    project: codeProject.srcRepoId,
    organization: codeProject.srcRepoOwner,
  });

  const branchOptions = data?.vcsBranches
    ?.filter((branch) => branch?.name.includes(branchValues.branch))
    ?.map((b) => ({
      value: b.name,
      label: b.name,
    }));

  const addPipeline = useCreatePipelineV1();

  const onSuccessHandler = (newPipeline: ProjectPipelineV1) => {
    onAddPipeline(newPipeline.id ?? '');
    onClose(!isOpen);
    appDispatch(
      alerts.actions.createAlert({
        type: 'Success',
        message: t('viewPipeline.dialog.alerts.add.pipeline.success', {
          name: newPipeline.name,
        }),
      }),
    );
  };

  const onAddPipelineBranch = () => {
    addPipeline.mutate(
      {
        workspaceId,
        requestBody: {
          name: branchValues.branch,
          sources: [
            {
              autoBuildTriggers: ['COMMIT'],
              trigger: 'SOURCE_REPOSITORY',
              branch: branchValues.branch,
              containerName: branchValues.branch,
            },
          ],
          projectName: codeProject.name,
          projectType,
        },
      },
      {
        onSuccess: (newPipeline) => {
          onSuccessHandler(newPipeline);
        },
      },
    );
  };

  const onAddPipelineRegex = () => {
    addPipeline.mutate(
      {
        workspaceId,
        requestBody: {
          name: 'regex',
          sources: [
            {
              autoBuildTriggers: ['COMMIT'],
              trigger: 'SOURCE_REPOSITORY',
              regex: regexValues.regexbranch,
              containerName: 'regex',
            },
          ],
          projectName: codeProject.name,
          projectType,
        },
      },
      {
        onSuccess: (newPipeline) => onSuccessHandler(newPipeline),
      },
    );
  };

  const regexPipelineExists = codeProject.pipelines?.some(
    (pipeline) => pipeline.name === 'regex',
  );

  const formProps = {
    submittable: true,
    cancellable: true,
    onCancel: () => onClose(!isOpen),
    submitDisabled: branchType === 'single' && !branchValues.branch,
    submitLabel: t('viewPipeline.dialog.add.pipeline.button'),
    cancelLabel: t('viewPipeline.dialog.cancel.add.pipeline.button'),
  };

  return (
    <Modal isOpen onClose={() => onClose(!isOpen)}>
      <Heading as="h4">{t('viewPipeline.dialog.header.addPipeline')}</Heading>
      <div className="add-pipeline-dialog" data-testid="add-pipeline-dialog">
        <ConditionalRender enable={!regexPipelineExists}>
          <div className="add-pipeline-dialog__radio-buttons">
            <RadioButton
              name="add-pipeline-branch"
              size="medium"
              label={t('viewPipeline.dialog.radio.button.single.branch')}
              value={branchType === 'single'}
              onChange={() => {
                setBranchType('single');
              }}
            />
            <RadioButton
              name="add-pipeline-regex"
              data-testid="add-pipeline-regex"
              size="medium"
              label={t('viewPipeline.dialog.radio.button.regex.branch')}
              value={branchType === 'regex'}
              onChange={() => {
                setBranchType('regex');
              }}
            />
          </div>
          <div className="add-pipeline-dialog__heading-break" />
        </ConditionalRender>
        <ConditionalRender enable={branchType === 'single'}>
          <Form
            values={branchValues}
            onChange={(
              _: keyof BranchFormValues,
              newBranch: BranchFormValues,
            ) => {
              setBranchValues(newBranch);
            }}
            onSubmit={() => onAddPipelineBranch()}
            submitting={addPipeline.isLoading}
            {...formProps}
          >
            <Heading className="add-pipeline-dialog__form-heading" as="h6">
              {t('viewPipeline.dialog.select.header')}
            </Heading>
            <div
              className="add-pipeline-dialog__form-field"
              data-testid="add-pipeline-branch-select"
            >
              <Form.Field
                type="autocomplete"
                name="branch"
                options={branchOptions}
                placeholder={
                  isLoading
                    ? t('viewPipeline.dialog.branch.input.loading')
                    : t('viewPipeline.dialog.branch.input.placeholder')
                }
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
          </Form>
        </ConditionalRender>
        <ConditionalRender enable={branchType === 'regex'}>
          <Form
            values={regexValues}
            onChange={(
              _: keyof RegexFormValues,
              newBranch: RegexFormValues,
            ) => {
              setRegexValues(newBranch);
              setRegexWarning(newBranch.regexbranch !== regexValue);
            }}
            onSubmit={() => onAddPipelineRegex()}
            {...formProps}
          >
            <Heading className="add-pipeline-dialog__form-heading" as="h6">
              {t('viewPipeline.dialog.regex.header')}
            </Heading>
            <div className="add-pipeline-dialog__form-field">
              <Form.Field
                type="text"
                name="regexbranch"
                placeholder={t('viewPipeline.dialog.branch.regex.placeholder')}
                required
              />
              {regexWarning && (
                <Alert type="danger" className="add-pipeline-dialog__alert">
                  {t('viewPipeline.dialog.alerts.add.pipeline.regex')}
                </Alert>
              )}
            </div>
          </Form>
        </ConditionalRender>
      </div>
      <Cd4peError error={addPipeline.error || error} />
    </Modal>
  );
};

export default AddPipelineDialog;
