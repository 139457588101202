import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { Table } from '@puppet/data-grid';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  Badge,
  Link,
  Code,
  Button,
  Input,
  Heading,
} from '@puppet/react-components';
import { format } from 'date-fns';
import download from 'downloadjs';
import ColumnPicker from 'inventory/components/ColumnPicker';
import ConditionalRender from 'inventory/components/ConditionalRender';
import classnames from 'classnames';
import TablePager from 'components/TablePager';
import QueryError from 'inventory/components/QueryError';
import { TABLE_LIMIT } from 'utils/constants';
import NodeTableFilter from 'inventory/components/NodeTableFilter';
import changeStatusText from 'inventory/utils/changeStatusText';
import { exportAPI } from 'utils/api/query';
import { OptionsArray } from 'utils/en.propTypes';
import filterService from 'inventory/utils/filters/index';
import filterImport from './filters';
import './NodeTable.scss';

export const GET_NODES_DATA = gql`
  query getNodesData(
    $id: ID!
    $limit: Int
    $offset: Int
    $filter: HostFilter
    $source: String
  ) {
    hosts(
      workspace: { id: $id, source: $source }
      limit: $limit
      offset: $offset
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        facts
        latestReportTime
        latestReportStatus
        latestReportCorrectiveChange
        source
      }
      pageInfo {
        totalPages
        currentPage
        hasNextPage
        hasPreviousPage
        prevOffset
        nextOffset
      }
    }
  }
`;

export const GET_FACT_PATHS = gql`
  query GET_FACT_PATHS($id: ID!) {
    factPaths(workspace: { id: $id })
  }
`;

const getBadgeFormat = (status) => {
  switch (status) {
    case 'intentional change': {
      return 'info';
    }
    case 'corrective change': {
      return 'warning';
    }
    case 'failure': {
      return 'danger';
    }
    default: {
      return 'neutral';
    }
  }
};

const LatestReportStatusCell = ({ cellData }) => {
  return cellData ? (
    <Badge weight="subtle" type={getBadgeFormat(cellData)}>
      {cellData}
    </Badge>
  ) : null;
};

const NodeDetailsLink = ({ columnData, cellData, rowIndex }) => {
  const source = columnData?.queryData?.[rowIndex]?.source;
  return (
    <Link
      className="nt-node-details-link"
      href={`${columnData.linkToNodeDetails}/${source}/${cellData}`}
    >
      {cellData}
    </Link>
  );
};

export const FactColumnCell = ({ cellData }) => {
  if (typeof cellData === 'object') {
    return (
      <Code size="small" className="nt-fact-column-cell">
        {JSON.stringify(cellData, 0, 2)}
      </Code>
    );
  }
  if (typeof cellData === 'boolean') {
    return <div>{JSON.stringify(cellData)}</div>;
  }
  return <div>{cellData}</div>;
};

const constructFactData = (data, columnsToView) => {
  return data
    ? data
        ?.map((factPickerValue) => {
          return {
            value: factPickerValue,
            label: factPickerValue,
          };
        })
        .filter((fact) => !columnsToView?.includes(fact.value))
    : [];
};

export const DEFAULT_FACTS = {
  Name: 'Name',
  LatestReport: 'Latest Report',
  Status: 'Status',
  PEServer: 'PE Server',
  ipaddress: 'ipaddress',
  operatingsystem: 'operatingsystem',
};

const defaultFactList = () => {
  const defaultFacts = [
    {
      factname: DEFAULT_FACTS.Name,
      isActive: true,
      defaultFact: true,
    },
    {
      factname: DEFAULT_FACTS.LatestReport,
      isActive: true,
      defaultFact: true,
    },
    {
      factname: DEFAULT_FACTS.Status,
      isActive: true,
      defaultFact: true,
    },
    {
      factname: DEFAULT_FACTS.PEServer,
      isActive: true,
      defaultFact: true,
    },
    {
      factname: DEFAULT_FACTS.ipaddress,
      isActive: true,
      defaultFact: true,
    },
    {
      factname: DEFAULT_FACTS.operatingsystem,
      isActive: true,
      defaultFact: true,
    },
  ];

  return defaultFacts;
};

const determineFactData = (factname) => {
  switch (factname) {
    case DEFAULT_FACTS.Name:
      return 'id';
    case DEFAULT_FACTS.LatestReport:
      return 'latestReportTime';
    case DEFAULT_FACTS.Status:
      return 'latestReportStatus';
    case DEFAULT_FACTS.PEServer:
      return 'source';
    default:
      return factname;
  }
};

const determineExportPath = (factname) => {
  switch (factname) {
    case DEFAULT_FACTS.Name:
      return 'id';
    case DEFAULT_FACTS.LatestReport:
      return 'latestReportTime';
    case DEFAULT_FACTS.Status:
      return 'latestReportStatus';
    case DEFAULT_FACTS.PEServer:
      return 'source';
    default:
      return `facts.${factname}`;
  }
};

export const determineExportData = (factname) => {
  return {
    name: factname,
    path: `$.${determineExportPath(factname)}`,
  };
};

export const constructTableData = (columnsToView, nodes = []) => {
  return nodes.map((hostData) => {
    return columnsToView.reduce((accumulator, factname) => {
      const isDefaultFact = defaultFactList().find(
        (defaultFact) => defaultFact.factname === factname,
      );
      // This is a special case to update the status field to account for intended or corrective changes.
      // TODO: move the text change functionality to a cell getter on the data grid
      if (factname === DEFAULT_FACTS.Status) {
        return {
          ...accumulator,
          [factname]: changeStatusText(
            hostData.latestReportStatus,
            hostData.latestReportCorrectiveChange,
          ),
        };
      }
      const factDataId = determineFactData(factname);

      const getNested = (obj, path) => {
        return (
          path
            .split('.')
            .reduce(
              (curLevel, nextLevel) => curLevel && curLevel[nextLevel],
              obj,
            ) ?? undefined
        );
      };

      return {
        ...accumulator,
        [factname]: isDefaultFact
          ? getNested(hostData, factDataId) ??
            getNested(hostData?.facts, factDataId)
          : getNested(hostData?.facts, factDataId),
      };
    }, {});
  });
};

export const defaultColumnsToView = (initialColumns) => {
  const defaultColumns = [
    'Name',
    'Latest Report',
    'Status',
    'PE Server',
    'ipaddress',
    'operatingsystem',
  ];

  if (initialColumns) {
    return initialColumns;
  }

  return defaultColumns;
};
async function exportData(columns, variables, setExporting) {
  setExporting(true);

  const body = {
    query: GET_NODES_DATA.loc?.source?.body,
    responsePath: '$.data.hosts.nodes',
    pageInfoPath: '$.data.hosts.pageInfo',
    variables,
    columns: columns.map((column) => column.export),
  };

  const { data } = await exportAPI('', body);
  if (data) {
    const date = format(new Date(), 'yyyy-mm-dd_HHmm');
    download(data, `nodes-${date}.csv`, 'text/csv');
  }

  setExporting(false);
}

const NodeTable = ({
  linkToNodeDetails,
  workspaceId,
  tableDisplayMessages,
  getSubmittedFilters,
  getSelectedColumns,
  initialFilter,
  initialColumns,
  openAddFilterModal,
  onModalClose,
  isModalOpen,
  setIsTableLoading,
  filterModalTitle,
  onApply,
  availableFilters,
  setSelectedFilter,
  selectedFilter,
  onAddCompoundFilter,
  compoundFilters,
  applyCompoundFilters,
  setApplyCompoundFilters,
  globalFilterUnion,
  setShowNoResults,
}) => {
  // If a initial filter exists break down and assign initial values

  let parseFilters = [];
  if (initialFilter !== undefined) {
    parseFilters = JSON.parse(initialFilter);
  }

  const parsedColumns = initialColumns ? JSON.parse(initialColumns) : '';
  const { t } = useTranslation('inventory');
  const limit = TABLE_LIMIT;
  const [offset, setOffset] = useState(0);
  const [emptyStateHeader, setEmptyStateHeader] = useState('');
  const [emptyStateMessage, setEmptyStateMessage] = useState('');
  const [closeColumnPicker, setCloseColumnPicker] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [isFiltered, setIsFiltered] = useState(!!initialFilter);
  // This will hold the current filter array used in the table filter query
  const [submittedFilters] = useState(initialFilter ? parseFilters : []);

  // This will hold the filter array the user can manipulate in the NodeTable Filter modal
  const [activeFilters, setActiveFilters] = useState(submittedFilters);

  // This will hold the columns on display, this variable is only used to pass this info to savedViews mutation
  const [selectedColumns, setSelectedColumns] = useState(
    defaultColumnsToView(parsedColumns),
  );

  const { formattedQueryFilters, formattedSourceFilters } =
    filterService.getQueryFormattedFilters(compoundFilters, globalFilterUnion);

  const [nodeSearchValue, setNodeSearchValue] = useState('');

  // Passes on the filters and columns to be stored
  useEffect(() => {
    getSubmittedFilters(submittedFilters);
  }, [submittedFilters, getSubmittedFilters]);

  useEffect(() => {
    getSelectedColumns(selectedColumns);
  }, [selectedColumns, getSelectedColumns]);

  const [queryFilter, setQueryFilter] = useState(formattedQueryFilters);
  const [sourceFilter, setSourceFilter] = useState(formattedSourceFilters);

  const [filters, setFilters] = useState(filterImport);

  const apolloClient = useApolloClient();

  const filterDisplayMessages = {
    buttonLabel: t('nodeTable.filter.button.label'),
    modalTitle: t(filterModalTitle),
    selectLabel: t('nodeTable.filter.modal.select.label'),
    selectPlaceholderText: t('nodeTable.filter.modal.select.placeholder'),
    activeTitle: t('nodeTable.filter.modal.activeTitle'),
    addFilterButton: t('nodeTable.filter.modal.button.addFilterButton'),
    clearAllButton: t('nodeTable.filter.modal.button.clearAllButton'),
    applyAllButton: t('nodeTable.filter.modal.button.applyAllButton'),
    clearFiltersButton: t('nodeTable.filter.button.label.clear'),
    activeFiltersButton: t('nodeTable.filter.button.label.active'),
    cancelButton: t('nodeTable.filter.modal.button.cancelButton'),
    emptyList: t('nodeTable.filter.modal.emptyList'),
    addFilter: t('nodeTable.add.filter.button'),
  };

  const queryVariables = {
    id: workspaceId,
    limit,
    offset,
    filter: queryFilter,
    source: sourceFilter,
  };

  const { data, loading, error, client } = useQuery(GET_NODES_DATA, {
    variables: queryVariables,
  });

  const columnsToRender = defaultColumnsToView(parsedColumns);

  const { data: factPathData } = useQuery(GET_FACT_PATHS, {
    variables: {
      id: workspaceId,
    },
  });

  const [columnsToView, setColumnsToView] = useState(columnsToRender);
  const [nodeTableData, setNodeTableData] = useState(
    constructTableData(defaultColumnsToView(), data?.hosts?.nodes),
  );

  const [columnSchema, setColumnSchema] = useState([]);

  useEffect(() => {
    const newtabledata = constructTableData(columnsToView, data?.hosts?.nodes);
    setNodeTableData(newtabledata);
    const columns = columnsToView.map((column) => {
      if (column === DEFAULT_FACTS.Name) {
        return {
          label: 'Name',
          dataKey: 'Name',
          columnData: { linkToNodeDetails, queryData: data?.hosts?.nodes },
          cellRenderer: NodeDetailsLink,
          export: determineExportData(column),
        };
      }

      if (column === DEFAULT_FACTS.Status) {
        return {
          label: 'Status',
          dataKey: 'Status',
          cellRenderer: LatestReportStatusCell,
          export: determineExportData(column),
        };
      }

      return {
        label: column,
        dataKey: column,
        cellRenderer: FactColumnCell,
        export: determineExportData(column),
      };
    });

    setColumnSchema(columns);
  }, [data, columnsToView, linkToNodeDetails]);

  // end of useEffect block

  useEffect(() => {
    setIsTableLoading(loading);
    if (isFiltered && !loading) {
      setEmptyStateHeader(tableDisplayMessages.emptyFilterDataHeader);
    } else {
      setEmptyStateHeader(
        loading
          ? tableDisplayMessages.loadingHeader
          : tableDisplayMessages.noDataHeader,
      );
    }
    setCloseColumnPicker(loading);

    setEmptyStateMessage(loading ? tableDisplayMessages.loadingMessage : '');
  }, [
    loading,
    tableDisplayMessages.loadingHeader,
    tableDisplayMessages.loadingMessage,
    tableDisplayMessages.noDataHeader,
    tableDisplayMessages.emptyFilterDataHeader,
    isFiltered,
    setIsTableLoading,
  ]);

  useEffect(() => {
    const nodeResults = data?.hosts?.nodes ?? [];
    setShowNoResults(nodeResults.length === 0 && !error && !loading);
  }, [data, error, loading, setShowNoResults]);

  const nodeQueryData = data?.hosts ?? {};
  const totalCount = data?.hosts?.totalCount ?? 0;

  const setColumns = (column) => {
    const facts = column.map((value) => {
      return value.factname;
    });
    setColumnsToView(facts);
    setSelectedColumns(facts);
  };

  const onSelectedFilterChange = async (key) => {
    setSelectedFilter(key);
    if (filters[key]?.inputMapper) {
      const selectedFilterDefaultQuery =
        filters[key]?.inputMapper?.[filters[key].defaultQuery];
      try {
        const filterQueryData = await apolloClient.query({
          query: selectedFilterDefaultQuery?.query,
          variables: queryVariables,
        });
        setFilters({
          ...filters,
          [key]: selectedFilterDefaultQuery?.after?.(
            filterQueryData,
            filters[key],
          ),
        });
      } catch (selectedFilterDefaultQueryError) {
        log(
          `onSelectedFilterChange-Default-Query: ${selectedFilterDefaultQueryError}`,
        );
      }
    } else if (filters[key]?.query) {
      try {
        const filterQueryData = await apolloClient.query({
          query: filters[key]?.query,
          // pass through all variables (workspaceID, filter, sourceFilter etc)
          // the sub queries can use whichever they need by defining them as variables in the .gql files
          variables: queryVariables,
        });

        setFilters({
          ...filters,
          [key]: filters[key]?.after?.(filterQueryData, filters[key]),
        });
      } catch (filterQueryDataError) {
        log(`onSelectedFilterChange: ${filterQueryDataError}`);
      }
    }
  };

  // Adds a new filter created by the user to the active filter array
  const onAdd = (filterArg) => {
    let filter = filterArg;
    const selectedOption = availableFilters.find(
      (option) => option.value === selectedFilter,
    );

    // Remove any cached query options from a previous render
    setFilters(filterImport);

    if (filter.nodeGroup) {
      const selectedOptionNodeGroup = filters[
        selectedFilter
      ]?.options?.nodeGroup.find((option) => option.value === filter.nodeGroup);

      const selectedNodeGroupSource = { groupSource: filter.peMasterServer };
      const nodeGroupFilterValue =
        filters[selectedFilter]?.filterValue[
          selectedOptionNodeGroup?.nodeGroupId
        ];

      const nodeGroupFilter = {
        filter: {
          ...selectedNodeGroupSource,
          nodeGroupFilterValue,
        },
        label: selectedOption?.label,
        value: selectedOptionNodeGroup?.label,
      };

      if (openAddFilterModal) {
        onAddCompoundFilter(nodeGroupFilter);
      } else {
        setActiveFilters((activeFilters1) => [
          ...activeFilters1,
          nodeGroupFilter,
        ]);
      }
    } else {
      const getFilterPreAppendedLabel =
        selectedOption?.value === 'factValue'
          ? t('nodeTable.filter.factValue.display.label')
          : selectedOption?.label;
      filter = {
        ...filter,
        label: getFilterPreAppendedLabel,
      };
      if (openAddFilterModal) {
        onAddCompoundFilter(filter);
      } else {
        setActiveFilters((activeFilters2) => [...activeFilters2, filter]);
      }
    }
    setSelectedFilter(null);
  };

  // Some minor validation for "onAdd" as it needs a very specific value
  onAdd.PropTypes = PropTypes.shape({
    fact: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.string.isRequired,
    filter: PropTypes.shape({}).isRequired,
  });

  // This wll remove a filter on the active filter array
  const onRemove = (filterIndex) => {
    setActiveFilters([
      ...activeFilters.slice(0, filterIndex),
      ...activeFilters.slice(filterIndex + 1),
    ]);
    setNodeSearchValue('');
  };

  // This will set the active filter array to an empty array
  const onClearAll = () => {
    setActiveFilters([]);
    setNodeSearchValue('');
  };

  // Called once the user has finished selecting their filters
  // Will copy the active filters to the submitted filters
  // Will loop through the submitted filters for the actual filter object and set it to the filter used in the GQL query
  const onApplyAll = useCallback(() => {
    setNodeSearchValue('');
    setIsFiltered(compoundFilters.length > 0);
    setOffset(0);
    const formattedCompoundFilters = filterService.getQueryFormattedFilters(
      compoundFilters,
      globalFilterUnion,
    );
    setQueryFilter(formattedCompoundFilters.formattedQueryFilters);
    setSourceFilter(formattedCompoundFilters.formattedSourceFilters);
  }, [compoundFilters, globalFilterUnion]);

  useEffect(() => {
    if (applyCompoundFilters) {
      onApplyAll();
      setApplyCompoundFilters(false);
    }
  }, [applyCompoundFilters, onApplyAll, setApplyCompoundFilters]);

  const onFilterFormChange = async (fieldName, values) => {
    try {
      const doesDefaultQueryNeedTriggered =
        filters[selectedFilter]?.defaultQuery === fieldName;

      if (doesDefaultQueryNeedTriggered) {
        const { nextQuery, variables } =
          filters[selectedFilter]?.inputMapper?.[fieldName]?.queryVariables?.(
            fieldName,
            values,
          ) ?? {};

        const filterInputSchema =
          filters[selectedFilter]?.inputMapper[nextQuery];

        const result = await apolloClient.query({
          query: filterInputSchema?.query,
          variables: {
            id: workspaceId,
            ...variables,
          },
        });

        // Update our filters state to propagate through to trigger re-render
        setFilters({
          ...filters,
          [selectedFilter]: filterInputSchema?.after(
            result,
            filters[selectedFilter],
          ),
        });
      }
    } catch (filterFormChangeError) {
      log(`onFilterFormChange: ${filterFormChangeError}`);
    }
  };

  useEffect(() => {
    if (!isModalOpen && activeFilters !== submittedFilters) {
      onApplyAll();
    }
  }, [activeFilters, onApplyAll, isModalOpen, submittedFilters]);

  const executeSearch = () => {
    if (nodeSearchValue.length > 0) {
      if (queryFilter) {
        const originalQueryFilters = queryFilter[globalFilterUnion];
        setQueryFilter({
          ...queryFilter,
          [globalFilterUnion]: [
            ...originalQueryFilters,
            { certname: { like: nodeSearchValue } },
          ],
        });
      } else {
        setQueryFilter({
          [globalFilterUnion]: [{ certname: { like: nodeSearchValue } }],
        });
      }
    }
  };

  const checkSearchValue = (searchValue) => {
    if (searchValue === '' && nodeSearchValue.length > 0 && queryFilter) {
      const filterIndex = queryFilter.length;
      if (activeFilters.length === 0) {
        setQueryFilter(undefined);
      } else {
        const originalQueryFilters = queryFilter[globalFilterUnion];
        setQueryFilter({
          ...queryFilter,
          [globalFilterUnion]: [...originalQueryFilters.splice(filterIndex, 1)],
        });
      }
    }
    setNodeSearchValue(searchValue);
  };

  const factData = constructFactData(factPathData?.factPaths, columnsToRender);
  return (
    <div className="nt-container">
      <div
        className={classnames({
          'nt-container-overlay': loading,
        })}
      />
      <QueryError error={queryFilter ? error : null} client={client} />
      <Heading className="search-bar-heading">
        {t('nodeTable.filter.modal.searchBarTitle')}
      </Heading>
      <Table.TableHeader>
        <div className="nt-table-header">
          <div className=" nt-actions-left">
            <NodeTableFilter
              availableFilters={availableFilters}
              filters={filters}
              onSelectedFilterChange={onSelectedFilterChange}
              selectedFilter={selectedFilter}
              filterDisplayMessages={filterDisplayMessages}
              currentFilterList={activeFilters}
              onAdd={onAdd}
              onApply={onApply}
              onApplyAll={onApplyAll}
              onRemove={onRemove}
              onClearAll={onClearAll}
              onCancel={() => {
                onModalClose();
              }}
              isModalOpen={isModalOpen}
              onModalClose={onModalClose}
              onFormChange={onFilterFormChange}
              compoundFiltering={openAddFilterModal}
              sourceFilter={sourceFilter}
            />
          </div>
          <div className="nt-actions-right">
            <ColumnPicker
              disabled={loading}
              facts={factData}
              initialColumns={columnsToRender}
              defaultFactList={defaultFactList()}
              onClickApply={(activeFacts) => {
                setColumns(activeFacts);
              }}
              factLimitSize={limit}
              closeColumnPicker={closeColumnPicker}
            />
            <Button
              data-testid="node-table-export-button"
              className="nt-export-button"
              type="transparent"
              icon="export"
              disabled={exporting}
              onClick={() =>
                exportData(columnSchema, queryVariables, setExporting)
              }
            >
              {exporting
                ? t('nodeTable.export.button.exporting')
                : t('nodeTable.export.button.export')}
            </Button>
          </div>
        </div>

        <div className="node-search-bar">
          <Input
            className="search-bar"
            data-testid="searchBar-textInput"
            icon="search"
            type="search"
            placeholder={t('nodeTable.filter.modal.searchBar')}
            name="node-search"
            size="medium"
            value={nodeSearchValue}
            onChange={checkSearchValue}
            disabled={loading}
          />
          <ConditionalRender enable={nodeSearchValue.length > 0}>
            <Button
              className="apply-button"
              onClick={executeSearch}
              type="secondary"
              aria-label="apply-search"
            >
              {t('nodeTable.filter.button.applySearch')}
            </Button>
          </ConditionalRender>
        </div>
      </Table.TableHeader>
      <div className="nt-table-container">
        <Table
          data-testid="node-table"
          data={nodeTableData}
          columns={columnSchema}
          emptyStateHeader={emptyStateHeader}
          emptyStateMessage={emptyStateMessage}
        />
      </div>
      {totalCount > 0 && (
        <TablePager
          pageData={nodeQueryData}
          limit={limit}
          onPageSelect={setOffset}
          totalCount={totalCount}
          itemType={t('nodeTable.tablepager.type', { count: totalCount })}
        />
      )}
    </div>
  );
};

export default NodeTable;

LatestReportStatusCell.propTypes = {
  cellData: PropTypes.string.isRequired,
};

NodeDetailsLink.propTypes = {
  cellData: PropTypes.string.isRequired,
  columnData: PropTypes.objectOf({
    linkToNodeDetails: PropTypes.string,
  }).isRequired,
  rowIndex: PropTypes.number.isRequired,
};

FactColumnCell.propTypes = {
  cellData: PropTypes.string.isRequired,
};

NodeTable.propTypes = {
  linkToNodeDetails: PropTypes.string.isRequired,
  tableDisplayMessages: PropTypes.shape({
    loadingHeader: PropTypes.string,
    loadingMessage: PropTypes.string,
    noDataHeader: PropTypes.string,
    emptyFilterMessage: PropTypes.string,
    emptyFilterDataHeader: PropTypes.string,
  }).isRequired,
  workspaceId: PropTypes.string.isRequired,
  getSubmittedFilters: PropTypes.func.isRequired,
  getSelectedColumns: PropTypes.func.isRequired,
  initialColumns: PropTypes.string,
  initialFilter: PropTypes.string,
  openAddFilterModal: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    changeStatus: PropTypes.instanceOf(Object),
    factValue: PropTypes.instanceOf(Object),
    nodeGroup: PropTypes.instanceOf(Object),
    noopStatus: PropTypes.instanceOf(Object),
    operatingSystem: PropTypes.instanceOf(Object),
  }).isRequired,
  setIsTableLoading: PropTypes.func.isRequired,
  filterModalTitle: PropTypes.string,
  availableFilters: OptionsArray.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string,
  onAddCompoundFilter: PropTypes.func.isRequired,
  compoundFilters: PropTypes.instanceOf(Array).isRequired,
  applyCompoundFilters: PropTypes.bool.isRequired,
  setApplyCompoundFilters: PropTypes.func.isRequired,
  globalFilterUnion: PropTypes.string.isRequired,
  setShowNoResults: PropTypes.func.isRequired,
};

NodeTable.defaultProps = {
  initialColumns: undefined,
  initialFilter: undefined,
  openAddFilterModal: false,
  filterModalTitle: 'nodeTable.filter.modal.title',
  selectedFilter: '',
};
