import callApi from '../apiClient';
import { generateAuthConfig } from '../helpers';

// eslint-disable-next-line import/prefer-default-export
export const exportAPI = async (origin, body) =>
  callApi(
    origin,
    '/export',
    generateAuthConfig('POST', JSON.stringify(body)),
    'text',
  );
