import { useTranslation } from 'react-i18next';
import React from 'react';
import { Alert, Button, Heading, Loading } from '@puppet/react-components';
import { Link } from 'react-router-dom';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { useListIntegrationsV1 } from '@services/cd4pe/vcs';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { VcsIntegrationV1 } from '@puppet/cd4pe-client-ts';
import { LINKS } from 'src/routes';
import SourceControlIntegration from './components/SourceControlIntegration';
import StatusNotificationPrefix from './components/statusNotificationPrefix';
import SourceControlEmptyState from './components/SourceControlEmptyState';

const getIntegrations = (vcsIntegrations: VcsIntegrationV1[]) => {
  const connectedIntegrations = vcsIntegrations.filter((vcs) => vcs.connected);

  if (connectedIntegrations && connectedIntegrations.length > 0) {
    return (
      <div className="source-control-grid">
        {connectedIntegrations.map((integration) => (
          <SourceControlIntegration
            key={integration.provider}
            integration={integration}
          />
        ))}
      </div>
    );
  }
  return <SourceControlEmptyState />;
};

const SourceControl = () => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const { isLoading, error, data } = useListIntegrationsV1({ workspaceId });

  return (
    <div className="source-control">
      <div className="source-control-header">
        <Heading as="h3">{t('sourceControl.pageHeader')}</Heading>
        <Button
          type="primary"
          icon="plus"
          as={Link}
          to={LINKS.settings.newSourceControl({ path: { workspace } })}
        >
          {t('sourceControl.addNewSource.button')}
        </Button>
      </div>
      {isLoading && (
        <Loading
          data-testid="loading-spinner"
          className="loading"
          size="small"
        />
      )}
      {data?.vcsIntegrations && getIntegrations(data.vcsIntegrations)}
      {error && (
        <Alert data-testid="source-control-error" type="danger">
          {error?.message}
        </Alert>
      )}
      <div className="source-control-divider" />
      <StatusNotificationPrefix />
    </div>
  );
};

export default SourceControl;
