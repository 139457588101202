export const getQueryURL = (queryParams) => {
  const query = Object.keys(queryParams)
    .map(
      (param) =>
        `${encodeURIComponent(param)}=${encodeURIComponent(
          queryParams[param],
        )}`,
    )
    .join('&');
  return query;
};

export const getAuthCookie = () => {
  if (!document.cookie) {
    return null;
  }
  const cookies = document.cookie.split(';');
  if (!cookies || cookies.length === 0) {
    return null;
  }

  const authCookie = cookies.find((cookie) =>
    cookie.trim().startsWith('com.puppet.pipelines.pfi.sid'),
  );

  if (!authCookie) {
    return null;
  }

  // Split only on the first occurrence of =
  const keyval = authCookie.split(/=(.+)/);
  if (keyval.length < 2) {
    return null;
  }

  return keyval[1];
};

export const generateBaseCorsConfig = (method, body, headers = {}) => {
  const corsHeaders = {
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
    ...headers,
  };
  const defaultConfig = {
    method,
    headers: corsHeaders,
    mode: 'cors',
    credentials: 'include',
  };
  if (body) {
    defaultConfig.body = body;
  }
  return defaultConfig;
};

export const generateAuthConfig = (method, body) => {
  return generateBaseCorsConfig(method, body, {
    Authorization: getAuthCookie(),
  });
};
