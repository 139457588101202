import PropTypes from 'prop-types';
import { FILTER_OPERATORS } from 'utils/constants';

const FormGeneratorPropTypes = PropTypes.shape({
  reqParams: PropTypes.string,
  reqParamsDes: PropTypes.string,
  expandList: PropTypes.string,
  notes: PropTypes.string,
  notesDes: PropTypes.string,
  addOptParams: PropTypes.string,
  addOptParamsSelect: PropTypes.string,
  addOptParamsDes: PropTypes.string,
  runTask: PropTypes.string,
  setTarget: PropTypes.string,
  selectParam: PropTypes.string,
  enterParam: PropTypes.string,
  noScopeSet: PropTypes.string,
  filterEditButton: PropTypes.string,
});

const TaskSidePanelPropTypes = PropTypes.shape({
  reqParams: PropTypes.string,
  optParams: PropTypes.string,
});

const OptionsArray = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
);

const FilterDisplayMessagesPropTypes = PropTypes.shape({
  buttonLabel: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  editModalTitle: PropTypes.string,
  selectLabel: PropTypes.string.isRequired,
  selectPlaceholderText: PropTypes.string.isRequired,
  addFilterButton: PropTypes.string.isRequired,
  applyAllButton: PropTypes.string.isRequired,
  clearFiltersButton: PropTypes.string.isRequired,
  activeFiltersButton: PropTypes.string.isRequired,
  cancelButton: PropTypes.string.isRequired,
  emptyList: PropTypes.string.isRequired,
  matchingNodes: PropTypes.string,
  target: PropTypes.string,
});

const TargetScopePropTypes = PropTypes.shape({
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      fact: PropTypes.string,
      operator: PropTypes.oneOf(Object.keys(FILTER_OPERATORS)),
      value: PropTypes.string,
      filter: PropTypes.instanceOf(Object),
      length: PropTypes.number,
      id: PropTypes.string,
    }),
  ),
  totalCount: PropTypes.number,
  pdbQuery: PropTypes.instanceOf(Array),
  queryFilter: PropTypes.instanceOf(Object),
  filterOperator: PropTypes.string,
}).isRequired;

const ActionDetailPropTypes = PropTypes.shape({
  actionDetails: PropTypes.string,
  showDetails: PropTypes.string,
  hideDetails: PropTypes.string,
  planAction: PropTypes.string,
  target: PropTypes.string,
  nodes: PropTypes.string,
  all: PropTypes.string,
});

export {
  FormGeneratorPropTypes,
  FilterDisplayMessagesPropTypes,
  TaskSidePanelPropTypes,
  TargetScopePropTypes,
  ActionDetailPropTypes,
  OptionsArray,
};
