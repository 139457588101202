import React from 'react';
import { Alert } from '@puppet/react-components';

interface Props {
  title: string;
  description: string;
  className?: string;
}
const GenericErrorAlert = ({ title, description, className }: Props) => (
  <Alert type="danger" className={className}>
    {title}
    <Alert.Message>{description}</Alert.Message>
  </Alert>
);

export default GenericErrorAlert;

GenericErrorAlert.defaultProps = {
  className: '',
} as Partial<Props>;
