import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link as PdsLink } from '@puppet/react-components';
import Form from './Form';

type Props = {
  submitting: boolean;
  onSubmit: Function;
  onChangeMethod: Function;
  isError: boolean;
  method: 'STANDARD' | 'LDAP' | 'SAML';
};

const RootForm = ({
  onSubmit,
  submitting,
  isError,
  onChangeMethod,
  method,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  return (
    <>
      <Form
        type="root"
        onSubmit={onSubmit}
        submitting={submitting}
        isError={isError}
      />
      <div className="root-form__links">
        <PdsLink as={Link} onClick={onChangeMethod}>
          {t(`login.form.root.links.${method.toLowerCase()}`)}
        </PdsLink>
      </div>
    </>
  );
};

export default RootForm;
