import React from 'react';
import CommonFormSectionMain from './CommonFormSectionMain';
import CommonFormSectionSidebar from './CommonFormSectionSidebar';

interface Props
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {}

const CommonFormSection = ({ children, className, ...props }: Props) => {
  return (
    <section className={`common-form-section ${className}`} {...props}>
      {children}
    </section>
  );
};

CommonFormSection.defaultProps = {
  className: '',
} as Partial<Props>;

CommonFormSection.Main = CommonFormSectionMain;
CommonFormSection.Sidebar = CommonFormSectionSidebar;

export default CommonFormSection;
