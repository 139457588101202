import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Card } from '@puppet/react-components';

type Props = {
  className?: string;
  footer?: React.ComponentPropsWithRef<'div'>;
} & PropsWithChildren<React.ComponentPropsWithRef<'div'>>;

const LandingPage = ({ children, className = '', footer, ...props }: Props) => (
  <div className={classnames('landing-page', className)} {...props}>
    <Card className={classnames('landing-page__card')}>{children}</Card>
    {footer}
  </div>
);

export default LandingPage;
