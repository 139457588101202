import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import InsightsChart from '@puppet/insights-charts';
import classnames from 'classnames';
import '@puppet/insights-charts/source/scss/all.scss';
import './Chart.scss';

const Chart = ({ className, type, data, options, events, style }) => {
  const chartRef = useRef();

  useEffect(() => {
    if (data) {
      const chart = new InsightsChart(chartRef.current, {
        type,
        data,
        options,
        events,
      });
      chart.render();

      return () => {
        chart.destroy();
      };
    }
    return () => {};
  }, [chartRef, type, data, options, events]);

  return (
    <div
      className={classnames('er-chart', className)}
      style={style}
      ref={chartRef}
    />
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  data: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),
    series: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
  }).isRequired,
  options: PropTypes.shape({
    legend: PropTypes.shape({
      enabled: PropTypes.bool,
      orientation: PropTypes.string,
    }),
    column: PropTypes.shape({
      data_labels: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
    }),
    axis: PropTypes.shape({
      y: PropTypes.shape({
        enabled: PropTypes.bool,
        orientation: PropTypes.string,
      }),
      x: PropTypes.shape({
        orientation: PropTypes.string,
      }),
    }),
  }),
  events: PropTypes.shape({
    beforeRender: PropTypes.func,
    afterRender: PropTypes.func,
    onError: PropTypes.func,
    update: PropTypes.func,
    dataPointClick: PropTypes.func,
    legendItemClick: PropTypes.func,
    highlightSeries: PropTypes.func,
    unHighlightSeries: PropTypes.func,
    activatePointOfInterest: PropTypes.func,
    tooltipMove: PropTypes.func,
    tooltipHide: PropTypes.func,
    annotationClick: PropTypes.func,
    zoomStart: PropTypes.func,
    zoom: PropTypes.func,
    zoomEnd: PropTypes.func,
  }).isRequired,
  style: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

Chart.defaultProps = {
  className: '',
  options: {},
  style: {},
};

export default Chart;
