import { SecretTypeV1 } from '@utils/api/cd4pe';

type SecretDisplayType = 'password' | 'multiline' | 'usernamePassword';

export interface SecretOption {
  value: SecretTypeV1;
  type: SecretDisplayType;
  name: string;
}

export const SECRET_TYPES: SecretOption[] = [
  {
    value: 'SSH_PRIVATE_KEY',
    type: 'multiline',
    name: 'sshPrivateKey',
  },
  {
    value: 'TLS_CERTIFICATE',
    type: 'multiline',
    name: 'tlsCertificate',
  },
  {
    value: 'TOKEN',
    type: 'password',
    name: 'token',
  },
  {
    value: 'USERNAME_PASSWORD',
    type: 'usernamePassword',
    name: 'usernamePassword',
  },
  {
    value: 'PLAIN_TEXT',
    type: 'password',
    name: 'plainText',
  },
];
