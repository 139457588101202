import React, { forwardRef, ReactElement } from 'react';
import { Form } from '@puppet/react-components';
import CommonFormSection from './CommonFormSection';

type FormValues<T extends {}> = {
  [K in keyof T]: T[K] | undefined;
};

export type CommonFormChangeFunction<FormState extends {}> = (
  field: keyof FormState,
  values: FormValues<FormState>,
) => void;

interface Props<FormState extends {}>
  extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onChange'> {
  className?: string;
  onChange?: CommonFormChangeFunction<FormState>;
  submittable?: boolean;
  cancellable?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
  onCancel?: () => void;
  values?: FormState;
  submitting?: boolean;
  error?: string | Error | null;
  submitDisabled?: boolean;
}

const CommonFormComponent = <T extends {}>(
  props: Props<T>,
  ref: React.Ref<HTMLFormElement>,
) => {
  const { className = '', children, ...rest } = props;

  return (
    <Form ref={ref} className={`common-form ${className}`} {...rest}>
      {children}
    </Form>
  );
};

const CommonForm = forwardRef(CommonFormComponent) as unknown as {
  <T extends {}>(
    p: Props<T> & { ref?: React.Ref<HTMLFormElement> },
  ): ReactElement;
  Section: typeof CommonFormSection;
};

CommonForm.Section = CommonFormSection;

export default CommonForm;
