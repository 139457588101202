import React from 'react';

interface Props
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {}

const DefinitionsEntryContent = ({ children, className, ...props }: Props) => {
  return (
    <dd className={`definitions-definition__content ${className}`} {...props}>
      {children}
    </dd>
  );
};

DefinitionsEntryContent.defaultProps = {
  className: '',
} as Partial<Props>;

export default DefinitionsEntryContent;
