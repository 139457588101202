import React, { useRef } from 'react';

export type RefListGetter<K = string | number, V = HTMLElement> = (
  id?: K | null,
) => React.RefObject<V> | null;

export type RefListSetter<K = string | number, V = HTMLElement> = (
  id: K,
) => React.RefObject<V> | null;

const setRefById =
  <K, V>(allRefs: Map<K, React.RefObject<V>>): RefListSetter<K, V> =>
  (id) => {
    if (id === null || id === undefined) {
      return null;
    }

    if (allRefs.has(id)) {
      return allRefs.get(id) || null;
    }

    const newRef = React.createRef<V>();
    allRefs.set(id, newRef);

    return newRef;
  };

const getRefById =
  <K, V>(allRefs: Map<K, React.RefObject<V>>): RefListGetter<K, V> =>
  (id) => {
    if (id === null || id === undefined) {
      return null;
    }

    return allRefs.get(id) || null;
  };

/**
 * Lets you keep a dynamic list of ref objects. Returns an array of functions
 * for getting and setting a ref by id ([getter, setter]).
 *
 * Example:
 *
 * const [getRef, setRef] = useRefList();
 *
 * const buttonList = [1, 2, 3, 4];
 *
 * return (
 *   {buttonList.map((b) => <button ref={setRef(b)}>{b}</button>)}
 * );
 */
const useRefList = <Key = string | number, RefType = HTMLElement>(): [
  RefListGetter<Key, RefType>,
  RefListSetter<Key, RefType>,
] => {
  const allRefs = useRef(new Map<Key, React.RefObject<RefType>>());

  return [getRefById(allRefs.current), setRefById(allRefs.current)];
};

export default useRefList;
