import arrayOfAll from '@codeDelivery/utils/arrayOfAll';
import {
  ApiError,
  AuthenticationErrorV1,
  AuthorizationErrorV1,
  BadRequestErrorV1,
  BaseErrorV1,
  ConflictErrorV1,
  ErrorV1,
  InternalErrorV1,
  NotFoundErrorV1,
  UnprocessableEntityErrorV1,
} from '@puppet/cd4pe-client-ts';

export type KnownCd4peError =
  | AuthenticationErrorV1
  | AuthorizationErrorV1
  | BadRequestErrorV1
  | BaseErrorV1
  | ConflictErrorV1
  | InternalErrorV1
  | NotFoundErrorV1
  | UnprocessableEntityErrorV1;

export class Cd4peError<
  T extends KnownCd4peError = KnownCd4peError,
> extends Error {
  readonly body: T;

  readonly status: number;

  constructor(body: T, status: number, message: string) {
    super(message);
    this.body = body;
    this.status = status;
  }
}

export type Cd4peApiError =
  | Cd4peError<AuthenticationErrorV1>
  | Cd4peError<AuthorizationErrorV1>
  | Cd4peError<BadRequestErrorV1>
  | Cd4peError<BaseErrorV1>
  | Cd4peError<ConflictErrorV1>
  | Cd4peError<InternalErrorV1>
  | Cd4peError<NotFoundErrorV1>
  | Cd4peError<UnprocessableEntityErrorV1>
  | ApiError;

export const isKnownCd4peError = <Response>(
  response: Response | Cd4peError | ApiError,
): response is Cd4peError => response instanceof Cd4peError;

export const isUnknownCd4peError = <Response>(
  response: Response | Cd4peError | ApiError,
): response is ApiError => response instanceof ApiError;

export const isCd4peApiError = <Response>(
  response: Response | Cd4peError | ApiError,
): response is Cd4peError | ApiError =>
  isKnownCd4peError(response) || isUnknownCd4peError(response);

const extendsBaseError = <Response>(
  error: Response | Cd4peApiError,
): error is Cd4peError<BaseErrorV1> => {
  if (!isKnownCd4peError(error)) {
    return false;
  }

  const e: BaseErrorV1 = error.body;
  const keys = arrayOfAll<keyof BaseErrorV1>()([
    'message',
    'traceId',
    'uriPath',
  ]);

  return !!e && keys.every((k) => typeof e[k] === 'string');
};

export const isBadRequestError = <Response>(
  error: Response | Cd4peApiError,
): error is Cd4peError<BadRequestErrorV1> => {
  if (!extendsBaseError(error)) {
    return false;
  }

  const e = error.body as BadRequestErrorV1;

  return Array.isArray(e.errors) && e.errors.every((v) => !!v.message);
};

export const isInternalError = <Response>(
  error: Response | Cd4peApiError,
): error is Cd4peError<InternalErrorV1> => {
  if (!extendsBaseError(error)) {
    return false;
  }

  const e = error.body as InternalErrorV1;

  return typeof e.code === 'string';
};

/**
 * All methods below are depecrated and should not be used in new components
 */

export const isApiError = <T, K = Exclude<T, ErrorV1>>(
  response: K | ErrorV1,
): response is ErrorV1 => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const r = response as any;

  if (!r) {
    return false;
  }

  if (r.traceId && r.message) {
    return true;
  }

  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getApiError = (errorResponse: any) => {
  if (!errorResponse || !errorResponse.body) {
    return null;
  }

  const errorBody = errorResponse.body;

  if (isApiError(errorBody)) {
    return errorBody;
  }

  return null;
};

export const isGenericError = <T, K = Exclude<T, ApiError>>(
  response: K | ApiError,
): response is ApiError => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const r = response as any;

  if (!r) {
    return false;
  }

  if (r.url && typeof r.status === 'number') {
    return true;
  }

  return false;
};

export const isErrorResponse = <T, K = Exclude<T, ApiError | ErrorV1>>(
  response: K | ApiError | ErrorV1,
): response is ApiError | ErrorV1 =>
  isGenericError(response) || isApiError(response);

// This function should not be used anymore. New components should use the
// Cd4peError component instead. This function will be removed in the future.
export const formatError = (
  error: Cd4peError | ErrorV1 | ApiError | null,
  fallback = 'Error',
) => {
  const finalError = isKnownCd4peError(error) ? error.body : error;

  if (!finalError || !isErrorResponse(finalError)) {
    return fallback;
  }

  if (isApiError(finalError)) {
    const { message, details = {} } = finalError;
    const { cause } = details;

    if (!message && !cause) {
      return fallback;
    }

    if (message && cause) {
      return `${message}. ${cause}`.trim();
    }

    if (message) {
      return `${message}.`;
    }

    return `${cause}`;
  }
  if (
    isGenericError(finalError) &&
    finalError.body &&
    finalError.body.uriPath
  ) {
    // Temporary way to determine that the error data is one of our models  we can make other assumptions about it.
    // These '\n' characters aren't currently respected by callers because the string is passed into an Alert component in most cases.
    // I've left them to indicate the intent of formatting whenever we create a common error component instead of just using a string
    let formattedMessage = `${finalError.body.message}`;
    if (finalError.status === 400 && finalError.body.errors) {
      formattedMessage = `${formattedMessage}\n ${finalError.body.errors.join(
        '\n',
      )}`;
    }
    return `HTTP Status:\n ${finalError.status}\n Message: ${formattedMessage}\n URI path: ${finalError.body.uriPath}\n Trace ID: ${finalError.body.traceId}\n`;
  }

  return `${finalError.status}: ${fallback}`;
};
