import { COMPOUND_FILTER_AND, COMPOUND_FILTER_OR } from 'utils/constants';
import { nanoid } from 'nanoid';
import { puppetFilter as filterDefinition } from './definitions/puppetFilter';

const getUnionValue = (filter) => {
  return filter.indexOf(COMPOUND_FILTER_OR) > -1
    ? COMPOUND_FILTER_OR
    : COMPOUND_FILTER_AND;
};

const filterService = {
  getFilters(filters) {
    return filters.map((userFilter) => {
      const matchedDefinition = filterDefinition.find(
        (filterDef) => filterDef.filter === userFilter.filter,
      );
      return {
        ...matchedDefinition,
        id: nanoid(10),
        union: getUnionValue(JSON.stringify(userFilter)),
        filters: [userFilter],
      };
    });
  },

  addFilterGroup(newFilter, filters) {
    const matchedDefinition = filterDefinition.find(
      (filterDef) => filterDef.filter === newFilter.filter,
    );

    return [
      ...filters,
      {
        ...matchedDefinition,
        id: nanoid(10),
        union: getUnionValue(JSON.stringify(newFilter)),
        filters: [newFilter],
      },
    ];
  },

  deleteFilterGroup(filterId, filters) {
    return filters.filter(({ id }) => id !== filterId);
  },

  // switch Union
  // delete filter tab
  // check if updatedFilterGroup is [] and call deleteFilterGroup
  updateFilterGroup(updatedFilterGroup, filters) {
    if (updatedFilterGroup.filters.length === 0) {
      return this.deleteFilterGroup(updatedFilterGroup.id, filters);
    }
    return filters.map((filter) => {
      if (filter.id !== updatedFilterGroup.id) {
        return filter;
      }
      return {
        ...filter,
        ...updatedFilterGroup,
        union: updatedFilterGroup.union,
        filters: updatedFilterGroup.filters,
      };
    });
  },

  appendFilter(originalFilter, newFilter) {
    const oldFilters = originalFilter.filters;
    return {
      ...originalFilter,
      filters: [...oldFilters, newFilter],
    };
  },

  hasPeServerFilter(currentFilters) {
    return currentFilters.some((groupFilters) => {
      return groupFilters.filters.some((filterCriteria) => {
        return (
          filterCriteria.filter.groupSource || filterCriteria.filter.source
        );
      });
    });
  },

  // This function will take the compound filters and convert them into the source and
  // query parameters for the node GQL query fro the node table
  // Due to limitations of PE Server filtering we can only filter by 1 PE Server or all PE servers.
  // This function will select the first PE in the compound filter, use it and ignore all others.
  getQueryFormattedFilters(filters, globalUnion = COMPOUND_FILTER_AND) {
    let formattedQueryFilters = {};
    let formattedSourceFilters = null;
    const sortedFilters = filters.map((filter) => {
      const formattedUnionFilter = [];

      // loop through all internal filter tags/criteria
      filter.filters.forEach((filterItem) => {
        if (filterItem.filter.source) {
          // capture any PE Server filters
          if (typeof formattedSourceFilters !== 'string') {
            formattedSourceFilters = filterItem.filter.source;
          }
          return true;
        }

        if (filterItem.filter.groupSource) {
          // capture and Node Group Filters
          if (typeof formattedSourceFilters !== 'string') {
            formattedSourceFilters = filterItem.filter.groupSource;
          }
          formattedUnionFilter.push(filterItem.filter.nodeGroupFilterValue);
          return true;
        }
        // capture any sourceless filters
        formattedUnionFilter.push(filterItem.filter);
        return true;
      });
      return formattedUnionFilter.length > 0
        ? { [filter.union]: formattedUnionFilter }
        : {};
    });

    formattedQueryFilters[globalUnion] = sortedFilters.filter(
      (value) => Object.keys(value).length !== 0,
    );
    formattedQueryFilters =
      formattedQueryFilters[globalUnion].length > 0
        ? formattedQueryFilters
        : undefined;

    // return the query filters and source filters as separate attributes
    return { formattedQueryFilters, formattedSourceFilters };
  },
};

export default filterService;
