import React, { useState } from 'react';
import {
  Text,
  Modal,
  Button,
  Alert,
  Link as PdsLink,
} from '@puppet/react-components';
import { VmJobInstanceEventV1 } from '@puppet/cd4pe-client-ts';
import { useRerunJobInstanceV1 } from '@services/cd4pe/jobs';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import useVcsLinks from '@hooks/useVcsLinks';
import { useTranslation, Trans } from 'react-i18next';
import { LINKS } from 'src/routes';
import Cd4peError from '@components/Cd4peError';
import useWorkspaceName from '@hooks/useWorkspaceName';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  stageEvent: VmJobInstanceEventV1;
}

const RerunJobModal = ({ isOpen, onClose, stageEvent }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const rerunJobInstance = useRerunJobInstanceV1();
  const { getCommitLink, getBranchLink } = useVcsLinks();
  const [successfulRerun, setSuccessfulRerun] = useState(false);
  const workspace = useWorkspaceName();

  return (
    <Modal
      isOpen={isOpen}
      confirmButtonType="primary"
      cancelButtonType="secondary"
      onClose={() => onClose()}
      className="job-rerun-modal"
    >
      <Modal.Title>
        {t('viewPipeline.events.rerun.job.modal.title')}
      </Modal.Title>
      <Trans
        parent={Text}
        t={t}
        i18nKey="viewPipeline.event.rerun.job.text"
        components={[
          <PdsLink
            size="small"
            as="a"
            href={LINKS.codeDelivery.jobDetails({
              path: {
                workspace,
                jobInstanceId: stageEvent.vmJobInstanceId!,
              },
            })}
            target="_blank"
          >
            {stageEvent?.jobName}
          </PdsLink>,
          <PdsLink
            size="small"
            as="a"
            href={getBranchLink(
              stageEvent.repoProvider!,
              stageEvent.repoOwner!,
              stageEvent.repoName!,
              stageEvent.repoId!,
              stageEvent.branch!,
            )}
            target="_blank"
          >
            {stageEvent?.branch}
          </PdsLink>,
          <PdsLink
            size="small"
            as="a"
            href={getCommitLink(
              stageEvent.repoProvider!,
              stageEvent.repoOwner!,
              stageEvent.repoName!,
              stageEvent.repoId!,
              stageEvent.commitId!,
            )}
            target="_blank"
          >
            {stageEvent?.commitId}
          </PdsLink>,
        ]}
        values={{
          name: stageEvent?.jobName,
          branch: stageEvent?.branch,
          commit: stageEvent?.commitId,
        }}
      />
      {successfulRerun && (
        <Alert type="success" className="job-rerun-modal__alert">
          {t('viewPipeline.events.rerun.job.modal.alerts.success')}
        </Alert>
      )}

      <Cd4peError error={rerunJobInstance.error} />
      <Modal.Actions>
        {successfulRerun ? (
          <Button
            as="a"
            href={LINKS.codeDelivery.jobDetails({
              path: {
                jobInstanceId: stageEvent.vmJobInstanceId!,
                workspace,
              },
            })}
            loading={rerunJobInstance.isLoading}
          >
            {t('viewPipeline.events.go.to.job.button')}
          </Button>
        ) : (
          <Button
            onClick={() => {
              rerunJobInstance.mutate(
                {
                  workspaceId,
                  instanceId: stageEvent.vmJobInstanceId!,
                },
                {
                  onSuccess: () => {
                    setSuccessfulRerun(!successfulRerun);
                  },
                },
              );
            }}
            loading={rerunJobInstance.isLoading}
          >
            {t('viewPipeline.events.rerun.job.button')}
          </Button>
        )}
        <Button type="secondary" onClick={() => onClose()}>
          {t('viewPipeline.events.rerun.job.cancel.button')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RerunJobModal;
