import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Card,
  TooltipHoverArea,
  Icon,
  Text,
} from '@puppet/react-components';
import classnames from 'classnames';

interface Props {
  title: string;
  value: string | number | boolean;
  'data-testid'?: string;
  isFeatureLocked?: boolean;
  onClick?: () => void;
}

const ValueCard = ({
  title,
  value,
  'data-testid': dataTestId,
  isFeatureLocked = false,
  onClick = () => {},
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  return (
    <div>
      <Card
        key={`${title}-${value}`}
        data-testid={dataTestId}
        className={classnames('activity-page-cards__card', {
          'activity-page-cards__card--locked': isFeatureLocked,
        })}
        selectable={isFeatureLocked}
        onClick={onClick}
      >
        <Heading as="label" color={isFeatureLocked ? 'medium' : null}>
          {title}
        </Heading>
        {isFeatureLocked ? (
          <div className="activity-page-cards__locked-content">
            <Icon
              className="activity-page-cards__locked-content__icon"
              type="locked"
            />
            <Text
              color="medium"
              className="activity-page-cards__locked-content__title"
            >
              {t('activity.valuesOverTime.card.locked')}
            </Text>
          </div>
        ) : (
          <TooltipHoverArea tooltip={value} anchor="bottom">
            <Heading
              className="activity-page-cards__card-value"
              data-testid={`${dataTestId}-value`}
            >
              {value}
            </Heading>
          </TooltipHoverArea>
        )}
      </Card>
    </div>
  );
};

export default ValueCard;
