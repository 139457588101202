import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Card, Text } from '@puppet/react-components';
import { useSelector } from 'react-redux';
import { selectUser } from '@state/session/selectors';
import PageLayout from '@components/PageLayout';
import { AuthTokenV1 } from '@utils/api/cd4pe';
import UserDetailsCard from '@codeDelivery/components/UserDetailsCard';
import AccessTokenForm from './components/AccessTokenForm';
import AccessTokenTable from './components/AccessTokenTable';
import RevokeTokenDialog from './components/RevokeTokenDialog';

const Profile = () => {
  const { t } = useTranslation('codeDelivery');
  const user = useSelector(selectUser);
  const [tokenToRevoke, setTokenToRevoke] = React.useState<AuthTokenV1 | null>(
    null,
  );

  const onRevokeDialogClose = () => {
    setTokenToRevoke(null);
  };

  const onRevokeTokenHandler = (token: AuthTokenV1) => {
    setTokenToRevoke(token);
  };

  return (
    <>
      <PageLayout className="profile-page">
        <PageLayout.Header>
          <Heading>{t('profile.header')}</Heading>
        </PageLayout.Header>
        <PageLayout.Content>
          <UserDetailsCard user={user} />
          <Card className="profile-page-token-card">
            <div className="profile-page-token-card__description">
              <Heading as="h3">{t('profile.tokens.description.title')}</Heading>
              <Text>{t('profile.tokens.description.content')}</Text>
            </div>
            <div className="profile-page-token-card__token-form">
              <div className="profile-page-token-card__token-form__description">
                <Heading as="h5">{t('profile.tokens.form.title')}</Heading>
                <Text>{t('profile.tokens.form.description')}</Text>
              </div>
              <AccessTokenForm />
            </div>
            <div className="profile-page-token-card__divider" />
            <div className="profile-page-token-card__token-table">
              <Heading as="h5">{t('profile.tokens.table.title')}</Heading>
              <AccessTokenTable
                user={user}
                onRevokeToken={onRevokeTokenHandler}
              />
            </div>
          </Card>
        </PageLayout.Content>
      </PageLayout>
      {tokenToRevoke && (
        <RevokeTokenDialog
          token={tokenToRevoke}
          onClose={onRevokeDialogClose}
        />
      )}
    </>
  );
};

export default Profile;
