import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  Cd4peApiError,
  ControlReposV1Service,
  handleQueryRequest,
  InfiniteQueryHook,
  MutationHook,
  QueryHook,
} from '@utils/api/cd4pe';
import { GET_IS_BUILD_PR_ALLOWED, GET_PIPELINE_QUERY_TAG } from './pipelines';
import { PREVIEW_PIPELINE_AS_CODE_TAG } from './pipelinesAsCode';

export const CONTROL_REPOS_LIST_QUERY_TAG = 'listControlRepos';
export const CONTROL_REPOS_GET_QUERY_TAG = 'getControlRepos';

/**
 * useListControlReposV1
 */

type UseListControlReposV1 = InfiniteQueryHook<
  typeof ControlReposV1Service,
  'listControlReposV1',
  Cd4peApiError
>;

const useListControlReposV1: UseListControlReposV1 = (payload) =>
  useInfiniteQuery(
    [CONTROL_REPOS_LIST_QUERY_TAG, payload.workspaceId, payload.prefix],
    ({ pageParam }) => {
      return handleQueryRequest(
        ControlReposV1Service.listControlReposV1({
          ...payload,
          nextMarker: pageParam,
        }),
      );
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageDetails.nextMarker ?? undefined,
    },
  );

export default useListControlReposV1;

/**
 * useDeleteControlRepoV1
 */

type UseDeleteControlRepoV1 = MutationHook<
  typeof ControlReposV1Service,
  'deleteControlRepoV1',
  Cd4peApiError
>;

export type UseDeleteControlRepoResult = ReturnType<UseDeleteControlRepoV1>;

export const useDeleteControlRepoV1: UseDeleteControlRepoV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (p) => handleQueryRequest(ControlReposV1Service.deleteControlRepoV1(p)),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries([
          CONTROL_REPOS_LIST_QUERY_TAG,
          payload.workspaceId,
        ]);

        queryClient.removeQueries([
          CONTROL_REPOS_GET_QUERY_TAG,
          payload.workspaceId,
          payload.controlRepoName,
        ]);

        queryClient.removeQueries([
          GET_PIPELINE_QUERY_TAG,
          payload.workspaceId,
          payload.controlRepoName,
        ]);

        queryClient.removeQueries([
          GET_IS_BUILD_PR_ALLOWED,
          payload.workspaceId,
          payload.controlRepoName,
        ]);

        queryClient.removeQueries([
          PREVIEW_PIPELINE_AS_CODE_TAG,
          payload.workspaceId,
          payload.controlRepoName,
        ]);
      },
    },
  );
};

/**
 * useGetControlRepoV1
 */

type UseGetControlRepoV1 = QueryHook<
  typeof ControlReposV1Service,
  'getControlRepoV1',
  Cd4peApiError
>;

export const useGetControlRepoV1: UseGetControlRepoV1 = (payload) =>
  useQuery(
    [
      CONTROL_REPOS_GET_QUERY_TAG,
      payload.workspaceId,
      payload.controlRepoName.toLowerCase(),
    ],
    () => {
      return handleQueryRequest(
        ControlReposV1Service.getControlRepoV1({
          ...payload,
        }),
      );
    },
  );
