import SingleSelectFilter from 'inventory/components/NodeTableFilter/SingleSelectFilter';

export default {
  component: SingleSelectFilter,
  placeholder: 'nodeTable.filter.status.input.placeholder',
  label: 'nodeTable.filter.status.input.label',
  filterValue: {
    noop: { latestReportNoop: { equalTo: true } },
    enforced: { latestReportNoop: { equalTo: false } },
  },
  options: [
    { value: 'noop', label: 'No-op' },
    { value: 'enforced', label: 'Enforced' },
  ],
};
