import React from 'react';
import { Icon } from '@puppet/react-components';
import DefinitionsEntryTitle from './DefinitionsEntryTitle';
import DefinitionsEntryContent from './DefinitionsEntryContent';

interface Props
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  icon?: string | null;
}

const DefinitionsEntry = ({ children, className, icon, ...props }: Props) => {
  const modifierClass = icon === null ? 'definitions-definition--no-icon' : '';

  return (
    <div className={`definitions-entry ${className}`} {...props}>
      {icon === null ? null : (
        <Icon
          type={icon}
          className={`definitions-entry__icon definitions-entry__icon--${icon}`}
        />
      )}

      <dl className={`definitions-definition ${modifierClass}`}>{children}</dl>
    </div>
  );
};

DefinitionsEntry.Title = DefinitionsEntryTitle;
DefinitionsEntry.Content = DefinitionsEntryContent;

DefinitionsEntry.defaultProps = {
  icon: null,
  className: '',
} as Partial<Props>;

export default DefinitionsEntry;
