import { CARDTYPES, PUPPET_FACT } from 'utils/constants';
// eslint-disable-next-line import/no-cycle
import { cardService } from '../../index';
import factQuery from './factQuery.gql';

export const puppetFact = [
  {
    name: PUPPET_FACT,
    query: factQuery,
    chartType: CARDTYPES.chart.donut,
    // TODO remove chartOptions and include in defined chart schema
    chartOptions: { grid: { enabled: true } },
    type: CARDTYPES.data.fact,
    responseKey: 'chart.facts',
    totalCountKey: 'chart.totalCount',
    hostCountKey: 'hosts.totalCount',
    after: (
      data,
      responseKey,
      totalCountKey,
      colorPaletteIndex,
      hostCountKey,
      areNodesExcluded,
    ) => {
      if (!data) return CARDTYPES.empty.donut;
      const values = cardService.accessData(responseKey, data);
      const totalCount = cardService.accessData(totalCountKey, data);
      const hostTotalCount = cardService.accessData(hostCountKey, data);
      // TODO have a separate formatter that takes chartType to determine what shape the data will be in
      return cardService.formatData(
        totalCount,
        values,
        hostTotalCount,
        areNodesExcluded,
        colorPaletteIndex,
      );
    },
  },
];

export default puppetFact;
