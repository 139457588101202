import { VcsIntegrationV1 } from '@puppet/cd4pe-client-ts';
import * as Provider from '@utils/vcs/providerConsts';
import React from 'react';
import AzureDevOpsForm from './forms/AzureDevOpsForm';
import GitlabForm from './forms/GitlabForm';
import OAuthForm from './forms/OAuthForm';
import AddSourceControlBlankForm from './forms/AddSourceControlBlankForm';
import GitHubEnterpriseForm from './forms/GitHubEnterpriseForm';
import BitBucketServerForm from './forms/BitBucketServerForm';

type Props = {
  integration?: VcsIntegrationV1 | undefined;
  onSuccess: (provider: string) => void;
  onCancel: () => void;
};

const AddSourceControlForm = ({ integration, onSuccess, onCancel }: Props) => {
  if (!integration) {
    return <AddSourceControlBlankForm />;
  }

  switch (integration.provider) {
    case Provider.BITBUCKET_SERVER:
      return <BitBucketServerForm onSuccess={onSuccess} onCancel={onCancel} />;
    case Provider.GITHUB_ENTERPRISE:
      return <GitHubEnterpriseForm onSuccess={onSuccess} onCancel={onCancel} />;
    case Provider.GITLAB:
      return <GitlabForm onSuccess={onSuccess} onCancel={onCancel} />;
    case Provider.AZURE_DEV_OPS:
      return (
        <AzureDevOpsForm
          integration={integration}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      );
    case Provider.BITBUCKET:
    case Provider.GITHUB:
      return <OAuthForm integration={integration} onCancel={onCancel} />;
    default:
      return <AddSourceControlBlankForm error />;
  }
};

AddSourceControlForm.defaultProps = {
  integration: undefined,
};

export default AddSourceControlForm;
