import React from 'react';
import { Heading, Loading } from '@puppet/react-components';
import PageLayout from '@components/PageLayout';
import Breadcrumbs from '@components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { useNavigate, useParams } from 'react-router-dom';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useAppDispatch } from '@hooks/redux';
import {
  useUpdateHardwareCapabilityV1,
  useGetJobHardwareCapabilityV1,
} from '@services/cd4pe/hardware';

import alerts from '@state/ui/alerts';
import Cd4peError from '@components/Cd4peError';
import JobHardwareCapabilityForm from '@codeDelivery/components/JobHardwareCapabilityForm';
import { UpdateJobHardwareCapabilitiesRequestV1 } from '@puppet/cd4pe-client-ts';
import { LINKS } from 'src/routes';

const breadcrumbsRender = (t: Function, workspace: string) => {
  const breadcrumbs = [
    { displayName: `${workspace}` },
    {
      displayName: t('jobHardware.breadcrumbs.hardware'),
      linkDestination: LINKS.codeDelivery.jobHardware({
        path: { workspace },
      }),
    },
    { displayName: `${t('editCapability.breadcrumb')}` },
  ];
  return breadcrumbs;
};

const EditCapability = () => {
  const { capabilityId } = useParams<{ capabilityId: string }>();
  const { t } = useTranslation('codeDelivery');
  const navigate = useNavigate();
  const workspaceName = useWorkspaceName();
  const workspaceId = useWorkspaceDomain();
  const appDispatch = useAppDispatch();
  const updateCapability = useUpdateHardwareCapabilityV1();

  const {
    error: getCapabilityError,
    isLoading,
    data: capability,
  } = useGetJobHardwareCapabilityV1({ workspaceId, id: Number(capabilityId) });

  const navigateToHardwareList = () => {
    navigate(
      LINKS.codeDelivery.jobHardware({ path: { workspace: workspaceName } }),
    );
  };

  const onSubmit = (payload: UpdateJobHardwareCapabilitiesRequestV1) => {
    updateCapability.mutate(
      {
        workspaceId,
        id: Number(capabilityId),
        requestBody: {
          displayName: payload.displayName!,
          nodes: payload.nodes!,
        },
      },
      {
        onSuccess: (updatedCapability) => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('jobHardware.alerts.update.success', {
                capabilityName: updatedCapability.displayName,
              }),
            }),
          );
          navigateToHardwareList();
        },
      },
    );
  };

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbsRender(t, workspaceName)} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('editCapability.header')}</Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        {isLoading ? (
          <Loading
            data-testid="edit-capability-loading-spinner"
            className="loading"
            size="small"
          />
        ) : (
          <JobHardwareCapabilityForm
            initialName={capability?.displayName}
            initialNodes={capability?.nodes}
            onCancel={navigateToHardwareList}
            onSubmit={onSubmit}
          />
        )}
        <Cd4peError error={getCapabilityError} />
        <Cd4peError error={updateCapability.error} />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default EditCapability;
