import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@components/Breadcrumbs';
import { Heading } from '@puppet/react-components';
import PageLayout from '@components/PageLayout';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { LINKS } from 'src/routes';
import { useNavigate } from 'react-router-dom';
import AddPeIntegrationForm from '@codeDelivery/components/AddPeIntegrationForm';

const AddPeIntegration = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const navigate = useNavigate();

  const navigateToPeList = useCallback(
    () =>
      navigate(
        LINKS.settings.listPuppetEnterprise({
          path: { workspace: workspaceName },
        }),
      ),
    [navigate, workspaceName],
  );

  const breadcrumbs = [
    { displayName: workspaceName },
    {
      displayName: t('addPeIntegration.breadcrumbs.settingsPage'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('addPeIntegration.breadcrumbs.peIntegrationsPage'),
      linkDestination: LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceName },
      }),
    },
    { displayName: t('addPeIntegration.breadcrumbs.addPeIntegrationPage') },
  ];
  return (
    <PageLayout
      className="add-pe-integration-page"
      data-testid="add-pe-integration-form"
    >
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('addPeIntegration.header.text')}</Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        <AddPeIntegrationForm
          onCancel={navigateToPeList}
          onSuccess={navigateToPeList}
        />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default AddPeIntegration;
