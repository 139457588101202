import { RootState } from '@state/store';
import { pathOr } from 'ramda';

export const isInitialized = (state: RootState) => state.session.initialized;
// TODO: selectUser should return null instead of {}
export const selectUser = (
  state: RootState,
): NonNullable<RootState['session']['user']> | {} => state.session.user || {};
export const isCurrentWorkspacePending = (state: RootState) =>
  state.session.currentWorkspacePending;
export const selectWorkspaceName = (
  state: RootState,
): NonNullable<RootState['session']['currentWorkspace']>['name'] | null =>
  pathOr(null, ['session', 'currentWorkspace', 'name'])(state);
export const selectWorkspaceDomain = (
  state: RootState,
): NonNullable<RootState['session']['currentWorkspace']>['domain'] | null =>
  pathOr(null, ['session', 'currentWorkspace', 'domain'])(state);
// TODO: selectWorkspace should return null instead of {}
export const selectWorkspace = (
  state: RootState,
): NonNullable<RootState['session']['currentWorkspace']> | {} =>
  state.session.currentWorkspace || {};
export const selectWorkspaces = (state: RootState) => state.session.workspaces;
// TODO: selectFeatures should return null instead of {}
