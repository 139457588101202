import CommonForm from '@components/CommonForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Link, Text } from '@puppet/react-components';
import { VcsIntegrationV1, VcsProviderV1 } from '@puppet/cd4pe-client-ts';
import getProviderDisplayName from '@utils/vcs/displayNameHelper';
import docsLinks from '@codeDelivery/utils/docsLinks';
import * as Provider from '@utils/vcs/providerConsts';
import { createSearchParams } from 'react-router-dom';

const getDocsLink = (provider: VcsProviderV1) => {
  switch (provider) {
    case Provider.AZURE_DEV_OPS:
      return docsLinks().integrateAzureDevOps;
    case Provider.BITBUCKET:
      return docsLinks().integrateBitbucketCloud;
    case Provider.GITHUB:
      return docsLinks().integrateGithub;
    default:
      return null;
  }
};

const OAuthForm = ({
  integration,
  onCancel,
  nested = false,
}: {
  integration: VcsIntegrationV1;
  onCancel: () => void;
  nested?: boolean;
}) => {
  const { t } = useTranslation('codeDelivery');
  const name = getProviderDisplayName(integration.provider);

  const getRedirectUrl = () => {
    if (
      integration.clientId &&
      integration.oauthRedirectUrl &&
      integration.scopes &&
      integration.state &&
      integration.authorizeUrl
    ) {
      const searchParams = createSearchParams([
        ['client_id', integration.clientId],
        ['redirect_uri', integration.oauthRedirectUrl],
        ['scope', integration.scopes],
        ['state', integration.state],
      ]);

      if (integration.responseType) {
        searchParams.append('response_type', integration.responseType);
      }

      return new URL(`${integration.authorizeUrl}?${searchParams.toString()}`);
    }
    return null;
  };

  const getOAuthButton = (redirectUrl: URL) => (
    <>
      <div className="add-source-control-form-credentials-field__wrapper">
        <Text>
          {t('addSourceControl.oauth.form.credentials.label', { name })}
        </Text>
      </div>
      <Button as="a" href={redirectUrl}>
        {t('addSourceControl.oauth.form.button.label', { name })}
      </Button>
    </>
  );

  const getOAuthError = () => (
    <Alert type="danger">
      <b>{t('addSourceControl.oauth.form.error.title', { name })}</b>
      <Alert.Message>
        {t(`addSourceControl.oauth.form.error.text.${integration.provider}`)}
      </Alert.Message>
      <Link
        size="small"
        as="a"
        href={getDocsLink(integration.provider)}
        target="_blank"
      >
        {t('addSourceControl.oauth.form.link.text', { name })}
      </Link>
    </Alert>
  );

  const getOAuthBody = () => {
    const redirectUrl = getRedirectUrl();
    return redirectUrl ? getOAuthButton(redirectUrl) : getOAuthError();
  };

  if (nested) {
    return getOAuthBody();
  }

  return (
    <CommonForm
      values={{}}
      cancellable
      submitLabel={t('addSourceControl.form.submitButton')}
      onCancel={onCancel}
    >
      <CommonForm.Section className="add-source-control-form__empty">
        <CommonForm.Section.Main>
          <h3 className="add-source-control-form-section__header">
            {t('addSourceControl.credentials.header')}
          </h3>
          {getOAuthBody()}
        </CommonForm.Section.Main>
      </CommonForm.Section>
    </CommonForm>
  );
};

export default OAuthForm;
