import React, { useRef, useEffect } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import classnames from 'classnames';
import { Icon } from '@puppet/react-components';
import 'codemirror/mode/shell/shell';
import 'codemirror/addon/comment/comment';

const codeMirrorConfig: CodeMirror.EditorConfiguration = {
  mode: 'shell',
  theme: 'dracula',
  lineNumbers: true,
  tabSize: 2,
  indentWithTabs: false,
  lineWrapping: true,
  extraKeys: {
    // For some reason, the default behavior was to use hard tabs (tab character). This fixes that:
    Tab: (cm) => {
      if (cm.somethingSelected()) {
        cm.execCommand('indentMore');
      } else {
        cm.execCommand('insertSoftTab');
      }
    },
    'Shift-Tab': (cm) => cm.execCommand('indentLess'),
    // turns on line-block commenting / uncommenting
    'Ctrl-/': (cm) => {
      cm.execCommand('toggleComment');
    },
    'Cmd-/': (cm) => {
      cm.execCommand('toggleComment');
    },
  },
  autofocus: false,
};

const defaultProps = {
  active: true,
  className: '',
  error: null,
  label: null,
  readOnly: false,
};

interface Props {
  active?: boolean;
  className?: string;
  error?: string | null;
  label?: string | React.ReactElement;
  name: string;
  onChange: (v: string) => void;
  value: string;
  readOnly?: boolean;
}

const renderError = (error: Props['error']) => {
  if (!error) {
    return null;
  }

  return (
    <div className="shell-editor__error">
      <Icon type="alert" className="shell-editor__error--icon" size="small" />
      {error}
    </div>
  );
};

const ShellEditor = ({
  active,
  className,
  error,
  label,
  name,
  onChange,
  value,
  readOnly,
}: Props) => {
  // If the user loads a page in which the editor is hidden by css, for example
  // if it is in a hidden tab, codemiror won't render properly. This effect hook
  // allows the consumer to pass a flag that will force a refresh when the editor becomes active
  const editorRef = useRef<CodeMirror.Editor | null>(null);
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.refresh();
    }
  }, [active]);

  // This allows the label to focus the CodeMirror editor
  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    const editorTextarea = editorRef.current.getInputField();
    if (editorTextarea) {
      editorTextarea.id = name;
    }
  }, [editorRef, name]);

  // The `shell-editor-${name} class is added to simplify finding the correct
  // CodeMirror instance during testing
  return (
    <div
      className={`shell-editor shell-editor-${name}`}
      data-testid={`shell-editor-${name}`}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <CodeMirror
        value={value}
        className={classnames('shell-editor__editor', className, {
          'shell-editor__editor--error': error,
        })}
        editorDidMount={(editor) => {
          editorRef.current = editor;
        }}
        autoScroll={false}
        options={{ ...codeMirrorConfig, readOnly }}
        onBeforeChange={(_editor, _data, newValue) => {
          onChange(newValue);
        }}
      />
      {renderError(error)}
    </div>
  );
};

ShellEditor.defaultProps = defaultProps;

export default ShellEditor;
