import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { Heading, Stepper } from '@puppet/react-components';
import PageLayout from '@components/PageLayout';
import Breadcrumbs from '@components/Breadcrumbs';
import { LINKS } from 'src/routes';
import GroupPrivilegesForm from '@codeDelivery/components/GroupPrivilegesForm';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import AddGroupForm from './components/AddGroupForm';

const AddGroup = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const groupName = searchParams.get('groupName');
  const stepperIndex = Number(searchParams.get('stepperIndex'));

  const onCreateGroupSuccess = (id: string, name: string) => {
    navigate(
      LINKS.settings.addGroup({
        path: { workspace: workspaceName },
        query: { groupId: id, groupName: name, stepperIndex: 1 },
      }),
    );
  };

  const navigateToGroupsList = () => {
    navigate(
      LINKS.settings.listGroups({
        path: { workspace: workspaceName },
        query: { newGroupName: groupName || '' },
      }),
    );
  };

  const onSetPermissionsSuccess = () => {
    navigateToGroupsList();
    appDispatch(
      alerts.actions.createAlert({
        type: 'Success',
        message: t('addGroup.alerts.setPermissions.success', {
          name: groupName,
        }),
      }),
    );
  };

  const breadcrumbs = [
    { displayName: `${workspaceName}` },
    {
      displayName: t('addGroup.breadcrumbs.settings'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('addGroup.breadcrumbs.groupsPage'),
      linkDestination: LINKS.settings.listGroups({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('addGroup.breadcrumbs.createNewGroup'),
    },
  ];

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('addGroup.page.title')}</Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        <Stepper
          activeStepIndex={stepperIndex ?? 0}
          className="add-group-stepper"
          data-testid="add-group-stepper"
        >
          <Stepper.Step>{t('addGroup.stepper.createGroup')}</Stepper.Step>
          <Stepper.Step>{t('addGroup.stepper.setPermissions')}</Stepper.Step>
        </Stepper>
        <AddGroupForm
          onCreateGroup={onCreateGroupSuccess}
          cancelCallback={navigateToGroupsList}
          groupId={groupId ?? ''}
        />
        {groupId && (
          <GroupPrivilegesForm
            editable
            groupId={+groupId}
            onSuccess={onSetPermissionsSuccess}
            onCancel={navigateToGroupsList}
            confirmLabel={t('addGroup.button.savePermissions')}
          />
        )}
      </PageLayout.Content>
    </PageLayout>
  );
};

export default AddGroup;
