import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeader } from '@puppet/data-grid';
import Table from '@components/Table';
import CursorPagination from '@codeDelivery/components/CursorPagination';
import { UseListAvailableNodesV1Result } from '@services/cd4pe/hardware';
import { compareNodes, Node, NodeList } from './utils';

const getTableTranslations = (
  searchTerm: string,
  isLoading: boolean,
  t: Function,
) => {
  if (isLoading) {
    return {
      heading: '',
      message: '',
    };
  }

  if (searchTerm) {
    return {
      heading: t('jobHardwareCapability.table.emptyState.noResults'),
      message: '',
    };
  }

  return {
    heading: t('jobHardwareCapability.table.emptyState.noData'),
    message: '',
  };
};

const checkIfIndeterminateState = (data: NodeList, selectedData: NodeList) => {
  if (data.length === 0) {
    return { checkAll: false, indeterminateState: false };
  }

  if (data.every((d) => selectedData.find((s) => compareNodes(d, s)))) {
    return { checkAll: true, indeterminateState: false };
  }

  if (data.some((d) => selectedData.find((s) => compareNodes(d, s)))) {
    return { checkAll: false, indeterminateState: true };
  }

  return { checkAll: false, indeterminateState: false };
};

const NodeTable = ({
  searchTerm,
  appliedSearchTerm,
  handleSearch,
  nodesQuery,
  selectedNodes,
  onSetSelectedNodes,
  currentPage,
  onChangeCurrentPage,
}: {
  searchTerm: string;
  appliedSearchTerm: string;
  handleSearch: (newSearchTerm: string) => void;
  selectedNodes: NodeList;
  nodesQuery: UseListAvailableNodesV1Result;
  onSetSelectedNodes: (newSelectedNodes: NodeList) => void;
  currentPage: number;
  onChangeCurrentPage: (newPage: number) => void;
}) => {
  const { t } = useTranslation('codeDelivery');

  const tableColumns = [
    {
      label: t('jobHardwareCapability.section.nodes.table.columns.name'),
      dataKey: 'name',
    },
    {
      label: t('jobHardwareCapability.section.nodes.table.columns.os'),
      dataKey: 'operatingSystem',
    },
  ];

  const page: NodeList = useMemo(
    () =>
      nodesQuery?.data?.pages?.[currentPage]?.nodes?.map((node) => ({
        ...node,
        selected: selectedNodes?.some((selectedNode) =>
          compareNodes(selectedNode, node),
        ),
      })) ?? [],
    [nodesQuery, currentPage, selectedNodes],
  );

  const { checkAll, indeterminateState } = checkIfIndeterminateState(
    page,
    selectedNodes,
  );

  const { heading, message } = getTableTranslations(
    appliedSearchTerm,
    nodesQuery.isLoading || nodesQuery.isFetching,
    t,
  );

  const onRowChecked = (checked: boolean, rowData: Node) => {
    if (checked) {
      onSetSelectedNodes([...selectedNodes, rowData]);
    } else {
      onSetSelectedNodes(
        selectedNodes.filter((node) => !compareNodes(node, rowData)),
      );
    }
  };

  const onHeaderChecked = (checked: boolean) => {
    const selectedNodesClone = window.structuredClone(selectedNodes);

    if (checked) {
      const nodesToAdd = page.filter((node) => !node.selected);
      if (nodesToAdd.length) {
        onSetSelectedNodes(selectedNodesClone.concat(nodesToAdd));
      }
    } else {
      page.forEach((node) => {
        if (node.selected) {
          const index = selectedNodesClone.findIndex((selected: Node) =>
            compareNodes(node, selected),
          );
          selectedNodesClone.splice(index, 1);
        }
      });

      onSetSelectedNodes(selectedNodesClone);
    }
  };

  return (
    <div data-testid="job-hardware-node-table">
      <TableHeader
        search
        searchPlaceholder={t(
          'jobHardwareCapability.section.nodes.fields.search.placeholder',
        )}
        searchValue={searchTerm}
        onSearchChange={(newSearchTerm: string) => {
          handleSearch(newSearchTerm);
          onChangeCurrentPage(0);
        }}
      />
      <Table
        data={page}
        loading={
          (nodesQuery.isLoading && nodesQuery.fetchStatus !== 'idle') ||
          (nodesQuery.isFetchingNextPage &&
            currentPage === nodesQuery.data?.pages.length)
        }
        columns={tableColumns}
        selectable
        emptyStateHeader={heading}
        emptyStateMessage={message}
        onRowChecked={onRowChecked}
        onHeaderChecked={onHeaderChecked}
        headerCheckState={checkAll}
        headerIndeterminateState={indeterminateState}
        data-testid="job-hardware-node-table-table"
      />
      <CursorPagination
        pageSelect={(newPage: number) => {
          if (
            newPage === (nodesQuery.data?.pages.length ?? 0) &&
            !nodesQuery.isFetchingNextPage
          ) {
            nodesQuery.fetchNextPage();
          }
          onChangeCurrentPage(newPage);
        }}
        currentPage={currentPage}
        hasNextPage={
          (nodesQuery.hasNextPage && !nodesQuery.isFetchingNextPage) ||
          (nodesQuery.data?.pages?.length === currentPage + 1 &&
            nodesQuery.isFetchingNextPage) ||
          !!nodesQuery.data?.pages?.[currentPage + 1]
        }
        hasPreviousPage={!!nodesQuery.data?.pages?.[currentPage - 1]}
      />
    </div>
  );
};

export default NodeTable;
