import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Alert, Link } from '@puppet/react-components';
import { DOCS_URL } from 'utils/constants';

// eslint-disable-next-line react/prop-types
const QueryError = ({ error, client }) => {
  const gqlErrors = [];
  if (error) {
    if (error.networkError) {
      gqlErrors.push(
        <Alert key={0} role="img" className="nt-error-alert" type="danger">
          <Trans
            i18nKey="queryError.message"
            // eslint-disable-next-line react/prop-types
            values={{ uri: client.uri }}
            components={[
              <Link
                key={0}
                className="node-details-docs-link"
                as="a"
                href={DOCS_URL}
                target="_blank"
              >
                {' '}
              </Link>,
            ]}
          />
        </Alert>,
      );
    } else {
      gqlErrors.push(
        <Alert key={0} role="img" className="nt-error-alert" type="danger">
          {error.message}
        </Alert>,
      );
    }
    error.graphQLErrors.forEach((errorSting, index) => {
      if (error.message.indexOf(errorSting.message) === -1) {
        const keyId = index + 1;
        gqlErrors.push(
          <Alert
            key={keyId}
            role="img"
            className="nt-error-alert"
            type="danger"
          >
            {errorSting.message}
          </Alert>,
        );
      }
    });
  }
  return <div className="qe_container">{gqlErrors}</div>;
};

export default QueryError;

QueryError.propTypes = {
  error: PropTypes.shape({
    networkError: PropTypes.string,
    message: PropTypes.string,
    graphQLErrors: PropTypes.instanceOf(Array),
  }),
};

QueryError.defaultProps = {
  error: null,
};
