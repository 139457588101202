import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useWorkspaceName from '@hooks/useWorkspaceName';
import Breadcrumbs from '@components/Breadcrumbs';
import AutoIntegrationForm, {
  AutoIntegrationFormOnChange,
} from '@codeDelivery/components/AutoIntegrationForm';
import { Link as PDLink, Loading, Heading } from '@puppet/react-components';
import {
  useGetPeIntegrationV2,
  useRegenerateTokenV2,
} from '@services/cd4pe/peIntegrations';
import PageLayout from '@components/PageLayout';
import alerts from '@state/ui/alerts';
import { useAppDispatch } from '@hooks/redux';
import Cd4peError from '@components/Cd4peError';
import { LINKS } from 'src/routes';

const DAYS_IN_A_MONTH = Math.ceil(365 / 12);

const RegenerateAuthToken = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceName = useWorkspaceName();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { peId: peIntegrationId } = useParams<{ peId: string }>();
  const [formData, setFormData] = useState({
    PE_USERNAME: '',
    PASSWORD: '',
    TOKEN_LIFETIME: '6',
  });

  const navigateToPeList = () =>
    navigate(
      LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceName },
      }),
    );

  const settingsPeListPath = LINKS.settings.editPuppetEnterprise({
    path: { workspace: workspaceName, peId: peIntegrationId },
  });

  const {
    isLoading,
    error,
    data: peIntegration,
  } = useGetPeIntegrationV2({ peIntegrationId: peIntegrationId! });

  const regenerateToken = useRegenerateTokenV2();

  const onSubmit = async () =>
    regenerateToken.mutate(
      {
        peIntegrationId: peIntegrationId!,
        requestBody: {
          username: formData.PE_USERNAME,
          password: formData.PASSWORD,
          tokenLifetime: `${Math.ceil(
            DAYS_IN_A_MONTH * Number(formData.TOKEN_LIFETIME),
          )}d`,
        },
      },
      {
        onSuccess: () => {
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('regenerateAuthToken.alerts.success'),
            }),
          );
          navigateToPeList();
        },
      },
    );

  const onAutoFormChange: AutoIntegrationFormOnChange = (fieldName, values) => {
    setFormData({ ...formData, [fieldName]: values[fieldName] || '' });
  };

  const breadcrumbs = [
    { displayName: workspaceName },
    {
      displayName: t('regenerateAuthToken.breadcrumbs.settingsPage'),
      linkDestination: LINKS.settings.workspace({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('regenerateAuthToken.breadcrumbs.peIntegrationsPage'),
      linkDestination: LINKS.settings.listPuppetEnterprise({
        path: { workspace: workspaceName },
      }),
    },
    {
      displayName: t('regenerateAuthToken.breadcrumbs.regenerateAuthTokenPage'),
    },
  ];

  return (
    <PageLayout className="regenerate-auth-token-container">
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <Heading>{t('editPeIntegration.header.text')}</Heading>
      </PageLayout.Header>
      <PageLayout.Content>
        {isLoading && (
          <Loading
            data-testid="loading-spinner"
            className="loading"
            size="small"
          />
        )}
        {peIntegration && (
          <AutoIntegrationForm
            name={peIntegration.name}
            consoleAddress={peIntegration.puppetServerUrl?.split(':')[0]}
            peUsername={formData.PE_USERNAME}
            password={formData.PASSWORD}
            tokenLifetime={formData.TOKEN_LIFETIME}
            onFormChange={onAutoFormChange}
            submitCallback={onSubmit}
            cancelCallback={() => navigateToPeList()}
            saveLoading={regenerateToken.isLoading}
            saveError={regenerateToken.error}
            submitLabel={t(
              `regenerateAuthToken.autoIntegrationForm.submitButton`,
            )}
            regenerateToken
            infoHeader={t(
              'regenerateAuthToken.autoIntegrationForm.authSection.infoHeader',
            )}
            infoBody={
              <Trans
                t={t}
                i18nKey="regenerateAuthToken.autoIntegrationForm.authSection.infoBody"
              >
                <PDLink as={Link} size="small" to={settingsPeListPath} />
              </Trans>
            }
          />
        )}
        <Cd4peError error={error} />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default RegenerateAuthToken;
