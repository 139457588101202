import React, { useState } from 'react';
import { Modal, Form } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import { UserV1 } from '@puppet/cd4pe-client-ts';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import { useSetUserPassword } from '@services/cd4pe/users';
import FormError from '@components/FormError';

type Props = {
  isOpen: boolean;
  onClose: (open: boolean) => void;
  user: UserV1;
};

type FormValues = {
  password: string;
  confirmPassword: string;
};

const ChangePasswordDialog = ({ isOpen, onClose, user }: Props) => {
  const { t } = useTranslation('codeDelivery');
  const appDispatch = useAppDispatch();
  const updateUserPassword = useSetUserPassword();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState<string>('');

  const onSubmitHandler = ({ password, confirmPassword }: FormValues) => {
    if (password !== confirmPassword) {
      setError(t('profile.change.password.dialog.nomatch.error'));
      return;
    }
    updateUserPassword.mutate(
      {
        username: user.userName!,
        password,
      },
      {
        onSuccess: () => {
          onClose(!isOpen);
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t('profile.change.password.dialog.success'),
            }),
          );
        },
      },
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(!isOpen)}>
      <Modal.Title>{t('profile.change.password.dialog.title')}</Modal.Title>
      <Form
        submittable
        cancellable
        onSubmit={(newPassword: FormValues) => {
          onSubmitHandler(newPassword);
        }}
        submitting={updateUserPassword.isLoading}
        onCancel={() => onClose(!isOpen)}
        submitLabel={t('profile.change.password.dialog.save.changes.button')}
        cancelLabel={t('profile.change.password.dialog.cancel.button')}
        data-testid="profile-change-password-form"
      >
        <Form.Field
          name="password"
          type={showPassword ? 'text' : 'password'}
          label={t('profile.change.password.dialog.new.password.label')}
          placeholder={t(
            'profile.change.password.dialog.new.password.placeholder',
          )}
          required
          data-testid="profile-change-password-input"
          trailingButtonIcon={showPassword ? 'private' : 'eye'}
          trailingButtonProps={{
            'aria-label': t(
              `signup.form.field.confirmPassword.toggle.ariaLabel`,
            ),
          }}
          onClickTrailingButton={() => setShowPassword(!showPassword)}
          error={error}
        />
        <Form.Field
          name="confirmPassword"
          type={showConfirmPassword ? 'text' : 'password'}
          label={t('profile.change.password.dialog.confirm.password.label')}
          placeholder={t(
            'profile.change.password.dialog.confirm.password.placeholder',
          )}
          required
          data-testid="profile-change-password-input"
          trailingButtonIcon={showConfirmPassword ? 'private' : 'eye'}
          trailingButtonProps={{
            'aria-label': t(
              `signup.form.field.confirmPassword.toggle.ariaLabel`,
            ),
          }}
          onClickTrailingButton={() =>
            setShowConfirmPassword(!showConfirmPassword)
          }
          error={error}
        />
      </Form>
      {updateUserPassword.error && (
        <FormError message={updateUserPassword.error.toString()} />
      )}
    </Modal>
  );
};

export default ChangePasswordDialog;
