import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Alert,
  Button,
  Form,
  Icon,
  Link,
  Modal,
  RadioButton,
  Text,
  Loading,
} from '@puppet/react-components';
import CommonForm from '@components/CommonForm';
import Definitions from '@components/Definitions';
import { Trans, useTranslation } from 'react-i18next';
import {
  StdVcsBranch,
  StdVcsRepo,
  StdVcsSource,
} from '@utils/api/cd4pe/vcs/actions';
import * as ERRORS from '@utils/boltProjects/errorTypesConsts';
import actionCreator, { Actions } from '@utils/actionCreator';
import { CommonFormChangeFunction } from '@components/CommonForm/CommonForm';
import { Cd4peApiError } from '@utils/api/cd4pe';
import Cd4peError from '@components/Cd4peError';
import { LINKS } from 'src/routes';
import docsLinks from '@codeDelivery/utils/docsLinks';
import GenericErrorAlert from '../GenericErrorAlert';

interface AddCodeProjectFormActionTypes {
  SELECTED_SOURCE: 'SELECTED_SOURCE';
  SELECTED_REPOSITORY: 'SELECTED_REPOSITORY';
  SELECTED_BRANCH: 'SELECTED_BRANCH';
  NAME: 'NAME';
}

export const ADD_CODE_PROJECT_FORM_ACTION_TYPES: AddCodeProjectFormActionTypes =
  {
    SELECTED_SOURCE: 'SELECTED_SOURCE',
    SELECTED_REPOSITORY: 'SELECTED_REPOSITORY',
    SELECTED_BRANCH: 'SELECTED_BRANCH',
    NAME: 'NAME',
  };

export const addCodeProjectFormActions = {
  name: (name: string) =>
    actionCreator(ADD_CODE_PROJECT_FORM_ACTION_TYPES.NAME, name),
  selectedSource: (source: string) =>
    actionCreator(ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_SOURCE, source),
  selectedBranch: (branch: string) =>
    actionCreator(ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_BRANCH, branch),
  selectedRepository: (repository: string) =>
    actionCreator(
      ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_REPOSITORY,
      repository,
    ),
};

export type AddCodeProjectFormActions = Actions<
  typeof addCodeProjectFormActions
>;

type FormFieldValues = {
  [K in keyof AddCodeProjectFormActionTypes]: string | null | undefined;
};

export type AddCodeProjectFormOnChange =
  CommonFormChangeFunction<FormFieldValues>;

interface Props {
  workspaceName: string;
  type: 'module' | 'controlRepo';
  sources?: StdVcsSource[] | null;
  selectedSource?: string | null;
  getSourcesLoading?: boolean | null;
  getSourcesError?: string | null;
  repositories?: StdVcsRepo[] | null;
  repoFilterCallback: (v: string) => void;
  selectedRepository?: string | null;
  getReposLoading?: boolean | null;
  getReposError?: string | null;
  branches?: StdVcsBranch[] | null;
  selectedBranch?: string | null;
  mainBranch?: StdVcsBranch | null;
  getBranchesLoading?: boolean;
  getBranchesError?: string | null;
  createMainBranch?: boolean | null;
  name?: string | null;
  saveLoading?: boolean;
  saveError?: Cd4peApiError | string | null;
  onFormChange: AddCodeProjectFormOnChange;
  createMainBranchChange: (payload: boolean) => void;
  submitCallback: () => Promise<void>;
  cancelCallback: () => void;
  webhookError?: boolean;
  closeModal: () => void;
  currentModalClosed?: boolean;
}

const AddCodeProjectForm = ({
  sources,
  selectedSource,
  getSourcesLoading,
  getSourcesError,
  repositories,
  selectedRepository,
  getReposLoading,
  getReposError,
  branches,
  selectedBranch,
  mainBranch,
  getBranchesLoading,
  getBranchesError,
  createMainBranch,
  name,
  saveLoading,
  saveError,
  webhookError,
  currentModalClosed,
  workspaceName,
  type,
  repoFilterCallback,
  onFormChange,
  createMainBranchChange,
  submitCallback,
  cancelCallback,
  closeModal,
}: Props) => {
  const { t } = useTranslation('codeDelivery');

  const formVals = {
    SELECTED_SOURCE: selectedSource,
    SELECTED_REPOSITORY: selectedRepository,
    SELECTED_BRANCH: selectedBranch,
    NAME: name,
  };

  const renderLoader = (condition?: boolean | null) => {
    return condition ? (
      <span className="add-code-project-field__loader-wrapper">
        <Loading
          data-testid="add-code-project-loader"
          className="add-code-project-field__loader-icon"
        />
      </span>
    ) : null;
  };

  const sourceOrRepoEmpty = !selectedSource || !selectedRepository;

  const renderSourcesAlert = () => {
    if (getSourcesError === ERRORS.NO_SOURCES_FOUND) {
      return (
        <Alert type="warning" className="add-code-project__generic-error">
          {t('addCodeProject.sourceSection.noSourcesFound.title')}
          <Alert.Message>
            <Trans
              t={t}
              i18nKey={`addCodeProject.sourceSection.noSourcesFound.body.${type}`}
              components={[
                <Link
                  as={NavLink}
                  to={LINKS.settings.listSourceControl({
                    path: { workspace: workspaceName },
                  })}
                  size="small"
                />,
              ]}
            />
          </Alert.Message>
        </Alert>
      );
    }

    if (getSourcesError) {
      return (
        <GenericErrorAlert
          title={t('addCodeProject.sourceSection.sourcesGenericError')}
          description={getSourcesError}
          className="add-code-project__generic-error"
        />
      );
    }
    return null;
  };

  const renderRepositorySelect = () => {
    if (getReposError === ERRORS.NO_REPOS_FOUND) {
      return (
        <div className="add-code-project-field__wrapper">
          <Alert type="warning">
            {t('addCodeProject.sourceSection.noReposFound')}
            <Alert.Message>
              {t(
                `addCodeProject.sourceSection.noReposFoundDescription.${type}`,
              )}
            </Alert.Message>
          </Alert>
        </div>
      );
    }

    if (getReposError) {
      return (
        <div className="add-code-project-field__wrapper">
          <GenericErrorAlert
            title={t('addCodeProject.sourceSection.reposGenericError')}
            description={getReposError}
          />
        </div>
      );
    }

    return (
      <div
        className="add-code-project-field__wrapper"
        data-testid="add-code-project-repo-select"
      >
        <Form.Field
          type="autocomplete"
          onFilter={(value: string) => repoFilterCallback(value)}
          name={ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_REPOSITORY}
          label={t('addCodeProject.sourceSection.repoLabel')}
          placeholder={t('addCodeProject.sourceSection.repoPlaceholder')}
          required
          options={repositories}
          disabled={selectedSource === ''}
          footer={
            repositories?.length! >= 10
              ? t('addCodeProject.sourceSection.repoDisplayCountWarning', {
                  count: repositories?.length,
                })
              : null
          }
        />
        {renderLoader(getReposLoading)}
      </div>
    );
  };

  const renderBranchSelect = () => {
    const getBranchSelectLabel = () => {
      if (mainBranch || !selectedRepository) {
        return t('addCodeProject.sourceSection.branchSelectLabel');
      }
      return createMainBranch
        ? t('addCodeProject.sourceSection.branchSelectLabel.createMain')
        : t('addCodeProject.sourceSection.branchSelectLabel');
    };

    if (getBranchesError === ERRORS.NO_BRANCHES_FOUND) {
      return (
        <div className="add-code-project-field__wrapper">
          <h3 className="add-code-project-section__subheader">
            {t('addCodeProject.sourceSection.branchSubheader')}
          </h3>
          <Alert type="warning">
            {t('addCodeProject.sourceSection.noBranchesFound')}
          </Alert>
        </div>
      );
    }

    if (getBranchesError) {
      return (
        <div className="add-code-project-field__wrapper">
          <h3 className="add-code-project-section__subheader">
            {t('addCodeProject.sourceSection.branchSubheader')}
          </h3>
          <GenericErrorAlert
            title={t('addCodeProject.sourceSection.branchesGenericError')}
            description={getBranchesError}
          />
        </div>
      );
    }

    if (sourceOrRepoEmpty) {
      return (
        <div className="add-code-project-field__wrapper">
          <h3 className="add-code-project-section__subheader">
            {t('addCodeProject.sourceSection.branchSubheader')}
          </h3>
          <div
            className="add-code-project-field__wrapper"
            data-testid="add-code-project-branch-select"
          >
            <Form.Field
              type="autocomplete"
              name={ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_BRANCH}
              placeholder={t(
                'addCodeProject.sourceSection.branchSelectPlaceholder',
              )}
              label={getBranchSelectLabel()}
              options={branches}
              disabled={sourceOrRepoEmpty}
              required
            />
            {renderLoader(getBranchesLoading)}
          </div>
        </div>
      );
    }

    if (mainBranch) {
      return (
        <div className="add-code-project-field__wrapper">
          <h3 className="add-code-project-section__subheader">
            {t('addCodeProject.sourceSection.branchSubheader')}
          </h3>
          <Alert type="success" className="add-code-project-field__alert">
            {t('addCodeProject.sourceSection.mainBranchAlert')}
            <Alert.Message>
              {t(`addCodeProject.sourceSection.mainBranchAlertMessage.${type}`)}
            </Alert.Message>
          </Alert>
          <div
            className="add-code-project-field__wrapper"
            data-testid="add-code-project-branch-select"
          >
            <Form.Field
              type="autocomplete"
              name={ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_BRANCH}
              placeholder={t(
                'addCodeProject.sourceSection.branchSelectPlaceholder',
              )}
              label={getBranchSelectLabel()}
              options={branches}
              disabled={sourceOrRepoEmpty}
              value={selectedBranch}
              required
            />
            {renderLoader(getBranchesLoading)}
          </div>
        </div>
      );
    }

    return (
      <div className="add-code-project-field__wrapper">
        <h3 className="add-code-project-section__subheader">
          {t('addCodeProject.sourceSection.branchSubheader')}
        </h3>
        <Alert type="info" className="add-code-project-field__alert">
          {t('addCodeProject.sourceSection.noMainBranchAlert')}
          <Alert.Message>
            {t(`addCodeProject.sourceSection.noMainBranchAlertMessage.${type}`)}
          </Alert.Message>
        </Alert>
        <div className="add-code-project-create-main-branch">
          <RadioButton
            className="add-code-project-create-main-branch__radio"
            name="true"
            label={t('addCodeProject.sourceSection.createMainBranchTrueRadio')}
            value={createMainBranch === true}
            onChange={createMainBranchChange(true)}
            disabled={sourceOrRepoEmpty}
          />
          <RadioButton
            className="add-code-project-create-main-branch__radio"
            name="false"
            label={t('addCodeProject.sourceSection.createMainBranchFalseRadio')}
            value={createMainBranch === false}
            onChange={createMainBranchChange(false)}
            disabled={sourceOrRepoEmpty}
          />
        </div>
        <div
          className="add-code-project-field__wrapper"
          data-testid="add-code-project-branch-select"
        >
          <Form.Field
            type="autocomplete"
            name={ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_BRANCH}
            placeholder={t(
              'addCodeProject.sourceSection.branchSelectPlaceholder',
            )}
            label={getBranchSelectLabel()}
            options={branches}
            disabled={sourceOrRepoEmpty}
            required
          />
          {renderLoader(getBranchesLoading)}
        </div>
      </div>
    );
  };

  const renderWebhookModal = () => {
    const newlyAddedUrl = () => {
      if (type === 'module' && name) {
        return LINKS.codeDelivery.viewModule({
          path: { workspace: workspaceName, name },
        });
      }
      if (type === 'controlRepo' && name) {
        return LINKS.codeDelivery.viewRepository({
          path: { workspace: workspaceName, name },
        });
      }
      return null;
    };

    if (!currentModalClosed && webhookError) {
      return (
        <Modal className="add-code-project-modal" onClose={closeModal}>
          <Modal.Title>
            {t(`addCodeProject.webhookErrorModal.title.${type}`)}
          </Modal.Title>
          <Alert type="warning">
            {t(`addCodeProject.webhookErrorModal.warning`)}
          </Alert>
          {t(`addCodeProject.webhookErrorModal.text.${type}`)}
          <Modal.Actions>
            <Button
              as="a"
              href={docsLinks().webhookTroubleshooting}
              icon="book"
            >
              {t(`addCodeProject.webhookErrorModal.docsButton`)}
            </Button>
            <Button
              as={NavLink}
              to={newlyAddedUrl()}
              type="secondary"
              onClick={closeModal}
            >
              {t(`addCodeProject.webhookErrorModal.goToButton.${type}`)}
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  };

  return (
    <CommonForm
      submittable
      cancellable
      className="add-code-project"
      submitLabel={t(`addCodeProject.form.submitButton.${type}`)}
      onSubmit={submitCallback}
      onCancel={cancelCallback}
      values={formVals}
      error={typeof saveError === 'string' ? saveError : null}
      onChange={onFormChange}
      submitting={saveLoading}
      data-testid="add-code-project-form"
    >
      {renderSourcesAlert()}
      <CommonForm.Section>
        <CommonForm.Section.Main>
          <h2 className="add-code-project-section__header">
            {t('addCodeProject.sourceSection.header')}
          </h2>
          <div
            className="add-code-project-field__wrapper"
            data-testid="add-code-project-source-select"
          >
            <Form.Field
              type="select"
              name={ADD_CODE_PROJECT_FORM_ACTION_TYPES.SELECTED_SOURCE}
              label={t('addCodeProject.sourceSection.sourceLabel')}
              placeholder={t('addCodeProject.sourceSection.sourcePlaceholder')}
              required
              options={sources}
              disabled={!sources || sources.length === 0}
            />
            {renderLoader(getSourcesLoading)}
          </div>
          {renderRepositorySelect()}
          {renderBranchSelect()}
        </CommonForm.Section.Main>
        <CommonForm.Section.Sidebar>
          <Definitions.Entry icon="info-circle">
            <Definitions.Entry.Content>
              <Trans
                t={t}
                i18nKey="addCodeProject.sourceSection.rightboundText"
                components={[
                  <Link
                    as={NavLink}
                    to={LINKS.settings.listSourceControl({
                      path: { workspace: workspaceName },
                    })}
                    size="small"
                  />,
                ]}
              />
            </Definitions.Entry.Content>
          </Definitions.Entry>
        </CommonForm.Section.Sidebar>
      </CommonForm.Section>

      <CommonForm.Section>
        <CommonForm.Section.Main>
          <h2 className="add-code-project-section__header">
            {t('addCodeProject.nameSection.header')}
          </h2>
          <div
            className="add-code-project-field__wrapper"
            data-testid="add-code-project-name"
          >
            <Form.Field
              type="text"
              name={ADD_CODE_PROJECT_FORM_ACTION_TYPES.NAME}
              label={t('addCodeProject.nameSection.nameLabel')}
              placeholder={t(
                `addCodeProject.nameSection.namePlaceholder.${type}`,
              )}
              validator={(value: string) => {
                if (!value.match('^[A-Za-z0-9-_ ]+$')) {
                  return t('addCodeProject.nameSection.validationError');
                }
                return false;
              }}
              required
              disabled={sourceOrRepoEmpty}
            />
            <span className="add-code-project-field__info-text">
              <Icon type="info-circle" />
              <Text size="tiny">
                {t('addCodeProject.nameSection.nameInfoText')}
              </Text>
            </span>
          </div>
        </CommonForm.Section.Main>
      </CommonForm.Section>
      {renderWebhookModal()}
      <Cd4peError error={typeof saveError === 'string' ? null : saveError} />
    </CommonForm>
  );
};

export default AddCodeProjectForm;

AddCodeProjectForm.defaultProps = {
  sources: null,
  selectedSource: null,
  getSourcesLoading: false,
  getSourcesError: null,
  repositories: null,
  selectedRepository: null,
  getReposLoading: null,
  getReposError: null,
  branches: null,
  selectedBranch: null,
  mainBranch: null,
  getBranchesLoading: false,
  getBranchesError: null,
  createMainBranch: null,
  name: null,
  saveLoading: false,
  saveError: null,
  formError: null,
  webhookError: false,
  currentModalClosed: false,
} as Partial<Props>;
