import React from 'react';
import {
  Icon,
  Link as PdsLink,
  Popover,
  Text,
  Heading,
} from '@puppet/react-components';
import { Trans, useTranslation } from 'react-i18next';
import docsLinks from '@codeDelivery/utils/docsLinks';

interface Props {
  onClose: () => void;
}

const InfoPopup = ({ onClose }: Props) => {
  const { t } = useTranslation('codeDelivery');

  return (
    <Popover
      side="top-right"
      elevation={100}
      data-testid="activity-ia-popup"
      className="activity-unlock-ia-popup"
      onClose={onClose}
    >
      <div className="activity-unlock-ia-popup-content">
        <div className="activity-unlock-ia-popup-content__header">
          <Icon type="unlocked" size="large" style={{ fill: '#067F77' }} />
          <Heading as="h4" className="activity-unlock-ia-popup-content__header">
            {t('activity.unlockImpactAnalysis.popup.header')}
          </Heading>
        </div>
        <div className="activity-unlock-ia-popup-content__body">
          <Text>{t('activity.unlockImpactAnalysis.popup.body1')}</Text>
          <div className="activity-unlock-ia-popup-content__link">
            <Trans t={t} i18nKey="activity.unlockImpactAnalysis.popup.body2">
              <PdsLink
                as="a"
                href={docsLinks().impactAnalysisProduct}
                target="_blank"
                size="small"
              >
                {t('activity.unlockImpactAnalysis.popup.body2')}
              </PdsLink>
            </Trans>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default InfoPopup;
