import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConditionalRender from 'inventory/components/ConditionalRender';
import { Text } from '@puppet/react-components';
import { ESTATE_REPORTING_FILTER_OPERATORS } from 'utils/constants';

import './FilterDetails.scss';

const FilterDetails = ({ savedFilters, globalUnion }) => {
  const { t } = useTranslation('inventory');
  const globalUnionText = globalUnion.toLowerCase();

  const renderSavedDetailGroups = (filters, union) => {
    return filters.map(({ label, fact, operator, value }, index) => {
      const operatorValue =
        ESTATE_REPORTING_FILTER_OPERATORS[operator?.toLowerCase()]?.symbol;
      const displayValue = fact ? `'${value}'` : value;
      const displayFact = fact ? ` '${fact}' ` : '';
      const keyId = `filter-${index}`;
      const unionText = union.toLowerCase();
      return (
        <div className="li-text-row" key={keyId}>
          <ConditionalRender enable={index > 0 && union.length > 0}>
            <Text className="li-text-union" size="small" color="medium">
              {unionText}
            </Text>
          </ConditionalRender>
          <Text className="li-text-item" size="small" color="medium">
            {label}
          </Text>
          {fact && (
            <Text className="li-text-item" size="small" color="medium">
              {displayFact}
            </Text>
          )}
          {operator ? (
            <Text className="li-text-item" size="small" color="medium">
              {operatorValue}
            </Text>
          ) : (
            <Text className="li-text-item" size="small" color="medium">
              =
            </Text>
          )}
          <Text className="li-text-item" size="small" color="medium">
            {displayValue}
          </Text>
        </div>
      );
    });
  };

  const renderSavedDetailRow = () => {
    if (Array.isArray(savedFilters) && savedFilters.length > 0) {
      return savedFilters.map(({ filters, union }, index) => {
        const keyId = `filter-${index}`;
        return (
          <div className="filter-details-item" key={keyId}>
            <ConditionalRender enable={index > 0}>
              <Text className="li-text-union" size="small" color="medium">
                {globalUnionText}
              </Text>
            </ConditionalRender>
            <Text className="li-text-item" size="small" color="medium">
              ({renderSavedDetailGroups(filters, union)})
            </Text>
          </div>
        );
      });
    }
    return (
      <Text size="small" color="medium">
        {t('nodeExplorer.drawer.filter.field.empty')}
      </Text>
    );
  };

  return <div className="filter-details-row">{renderSavedDetailRow()}</div>;
};

FilterDetails.propTypes = {
  savedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      fact: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.string,
      filter: PropTypes.shape({}).isRequired,
    }),
  ).isRequired,
  globalUnion: PropTypes.string.isRequired,
};

export default FilterDetails;
