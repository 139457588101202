import { Icon, Text } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import docsLinks from '@codeDelivery/utils/docsLinks';
import React from 'react';

const SourceControlEmptyState = () => {
  const { t } = useTranslation('codeDelivery');
  return (
    <div className="source-control-empty-state">
      <Icon type="integration" size="large" />
      <Text color="medium">
        <strong>{t('sourceControl.integrations.noIntegrationsHeading')}</strong>
      </Text>
      <Text>
        <a
          href={docsLinks().addIntegration}
          aria-label={t('sourceControl.integrations.noIntegrationsSubheading')}
          target="_blank"
          rel="noreferrer"
        >
          {t('sourceControl.integrations.noIntegrationsSubheading')}
        </a>
      </Text>
    </div>
  );
};

export default SourceControlEmptyState;
