import React, { useEffect, useState } from 'react';
import {
  Modal,
  Heading,
  RadioButton,
  Button,
  Text,
  Form,
  Checkbox,
  ButtonSelect,
  Code,
  Select,
  Link as PdsLink,
  Loading,
  Alert,
} from '@puppet/react-components';
import { Trans, useTranslation } from 'react-i18next';
import docsLinks from '@codeDelivery/utils/docsLinks';
import {
  useGetPipelineV1,
  useUpdatePipelineTriggersV1,
  useSetIsBuildPullRequestAllowedV1,
  useGetIsBuildPullRequestAllowedV1,
} from '@services/cd4pe/pipelines';
import {
  useCreatePipelinesFromCodeV1,
  useDisablePipelinesAsCodeV1,
  usePreviewPipelineAsCodeV1,
  useValidatePipelinesAsCodeV1,
} from '@services/cd4pe/pipelinesAsCode';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import {
  AutoBuildTriggerV1,
  CodeProjectDetailsV1,
  ProjectTypeV1,
} from '@puppet/cd4pe-client-ts';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import ConditionalRender from '@components/ConditionalRender';
import Cd4peError from '@components/Cd4peError';
import { useListBranchesV1 } from '@services/cd4pe/vcs';
import { RegexFormValues } from '../AddPipelineDialog';
import { getProjectTypeString } from '../../utils';

interface Props {
  onClose: (open: boolean) => void;
  pipelineId: string;
  setPipelineId: (id: string) => void;
  codeProject: CodeProjectDetailsV1;
  projectType: ProjectTypeV1;
}

const getBuildTriggers = ({
  commitTrigger,
  prTrigger,
}: {
  commitTrigger: boolean;
  prTrigger: boolean;
}) => {
  const pipelineTriggers: AutoBuildTriggerV1[] = [];
  if (commitTrigger) {
    pipelineTriggers.push('COMMIT');
  }

  if (prTrigger) {
    pipelineTriggers.push('PULL_REQUEST');
  }

  return pipelineTriggers;
};

const regexValue = 'feature_.*';

const ManagePipelinesDialog = ({
  onClose,
  pipelineId,
  setPipelineId,
  codeProject,
  projectType,
}: Props) => {
  const [allowPRForkCheck, setAllowPRForkCheck] = useState(false);
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const appDispatch = useAppDispatch();

  const branches = useListBranchesV1({
    workspaceId,
    provider: codeProject.srcRepoProvider,
    name: codeProject.srcRepoName,
    project: codeProject.srcRepoId,
    organization: codeProject.srcRepoOwner,
  });

  const branchOptions = branches.data?.vcsBranches.map((branch) => ({
    value: branch.name,
    label: branch.name,
  }));

  const pipelineOptions =
    codeProject.pipelines?.map((pipeline) => ({
      value: pipeline.pipelineId,
      label: pipeline.name,
    })) ?? [];

  const selectedPipeline = pipelineOptions.find(
    ({ value }) => value === pipelineId,
  );

  const [selectedPACBranch, setSelectedPACBranch] = useState(
    codeProject.pacBranch,
  );

  const [regexValues, setRegexValues] = React.useState<RegexFormValues>({
    regexbranch: regexValue,
  });

  const pipeline = useGetPipelineV1(
    {
      workspaceId,
      pipelineId,
      projectName: codeProject.name,
    },
    {
      enabled: pipelineId !== '',
    },
  );

  useEffect(() => {
    if (pipeline.data && pipeline.data?.name === 'regex') {
      const regex = pipeline.data?.sources?.[0]?.regex;
      if (regex) {
        setRegexValues({ regexbranch: regex });
      }
    }
  }, [pipeline.data, pipelineId]);

  const isBuildPullRequest = useGetIsBuildPullRequestAllowedV1({
    workspaceId,
    pipelineId,
    projectName: codeProject.name,
  });

  const pipelinesAsCodeObject = usePreviewPipelineAsCodeV1(
    {
      workspaceId,
      pipelineId,
      projectName: codeProject.name,
    },
    {
      enabled: pipelineId !== '',
    },
  );
  const managementType = codeProject.pacEnabled ? 'code' : 'ui';
  const [commitTrigger, setCommitTrigger] = useState(false);
  const [prTrigger, setPrTrigger] = useState(false);
  const [validYaml, setValidYaml] = useState(false);
  const [pipelineManagementType, setPipelineManagementType] = useState<
    'ui' | 'code'
  >(managementType);

  useEffect(() => {
    if (codeProject.pacEnabled) {
      setPipelineManagementType('code');
    } else {
      setPipelineManagementType('ui');
    }
  }, [codeProject.pacEnabled]);

  useEffect(() => {
    setCommitTrigger(
      pipeline?.data?.sources?.some((src) =>
        src.autoBuildTriggers?.includes('COMMIT'),
      ) ?? false,
    );
    setPrTrigger(
      pipeline.data?.sources?.some((src) =>
        src.autoBuildTriggers?.includes('PULL_REQUEST'),
      ) ?? false,
    );
  }, [pipeline?.data?.sources]);

  useEffect(() => {
    if (isBuildPullRequest.data?.isBuildPullRequestAllowed !== undefined) {
      setAllowPRForkCheck(isBuildPullRequest.data?.isBuildPullRequestAllowed);
    }
  }, [isBuildPullRequest.data]);

  const pipelineTriggers = getBuildTriggers({ commitTrigger, prTrigger });
  const disablePipelineAsCode = useDisablePipelinesAsCodeV1();
  const updatePipelineTriggers = useUpdatePipelineTriggersV1();
  const setBuildPullRequestAllowed = useSetIsBuildPullRequestAllowedV1();

  useEffect(() => {
    if (
      disablePipelineAsCode.isSuccess &&
      updatePipelineTriggers.isSuccess &&
      setBuildPullRequestAllowed.isSuccess
    ) {
      onClose(false);
      appDispatch(
        alerts.actions.createAlert({
          type: 'Success',
          message: t(
            'viewPipeline.dialog.managePipelines.alerts.savePipeline.success',
          ),
        }),
      );
    }
  }, [
    disablePipelineAsCode.isSuccess,
    updatePipelineTriggers.isSuccess,
    setBuildPullRequestAllowed.isSuccess,
    onClose,
    appDispatch,
    t,
  ]);

  const onSavePipelinesAsUIHandler = () => {
    setBuildPullRequestAllowed.mutate({
      workspaceId,
      pipelineId,
      requestBody: {
        projectName: codeProject.name,
        isBuildPullRequestAllowed: allowPRForkCheck,
      },
    });

    const isRegexPipeline = selectedPipeline?.label === 'regex';

    updatePipelineTriggers.mutate({
      workspaceId,
      pipelineId,
      requestBody: {
        triggers: pipelineTriggers,
        projectName: codeProject.name,
        [isRegexPipeline ? 'regex' : 'branch']: isRegexPipeline
          ? regexValues.regexbranch
          : selectedPipeline?.label,
      },
    });

    disablePipelineAsCode.mutate({
      workspaceId,
      pipelineId,
      projectName: codeProject.name,
    });
  };

  const createPipelineAsCode = useCreatePipelinesFromCodeV1();
  const onSavePipelinesAsCodeHandler = () => {
    if (selectedPACBranch) {
      createPipelineAsCode.mutate(
        {
          workspaceId,
          pipelineId,
          branch: selectedPACBranch,
          projectName: codeProject.name,
        },
        {
          onSuccess: () => {
            appDispatch(
              alerts.actions.createAlert({
                type: 'Success',
                message: t(
                  'viewPipeline.dialog.managePipelines.as.code.save.success',
                  {
                    branch: selectedPACBranch,
                  },
                ),
              }),
            );
          },
        },
      );
    }
  };

  const validatePipelinesAsCode = useValidatePipelinesAsCodeV1();
  const validatePipelinesAsCodeAsYaml = () => {
    validatePipelinesAsCode.mutate(
      {
        pipelineId,
        requestBody: {
          workspaceId,
          projectName: codeProject.name,
          pipelinesAsCodeYaml: pipelinesAsCodeObject.data?.pipelineAsCode!!,
        },
      },
      {
        onSuccess: () => {
          setValidYaml(!validYaml);
          appDispatch(
            alerts.actions.createAlert({
              type: 'Success',
              message: t(
                'viewPipeline.dialog.managePipelines.validate.yaml.success.alert',
              ),
            }),
          );
        },
      },
    );
  };

  const getPipelineAsCodeWarning = () => {
    return (
      <Alert type="warning">
        <div>
          {t('viewPipeline.dialog.managePipelines.validate.yaml.error')}
        </div>
        <Text color="medium" size="small">
          {createPipelineAsCode?.error?.body?.message}
        </Text>
      </Alert>
    );
  };

  const isSavingPipelineAsUI =
    disablePipelineAsCode.isLoading ||
    updatePipelineTriggers.isLoading ||
    setBuildPullRequestAllowed.isLoading;

  return (
    <Modal
      isOpen
      onClose={() => onClose(false)}
      className="manage-pipelines-dialog"
    >
      <Heading as="h4">
        {t('viewPipeline.dialog.managePipelines.header')}
      </Heading>
      <div className="manage-pipelines-dialog__radio-buttons">
        <RadioButton
          name="manage-pipeline-ui"
          size="medium"
          label={t('viewPipeline.dialog.managePipelines.ui.radio.button')}
          value={pipelineManagementType === 'ui'}
          onChange={() => {
            setPipelineManagementType('ui');
          }}
        />
        <RadioButton
          name="manage-pipeline-code"
          size="medium"
          label={t('viewPipeline.dialog.managePipelines.code.radio.button')}
          value={pipelineManagementType === 'code'}
          onChange={() => {
            setPipelineManagementType('code');
          }}
        />
      </div>
      <div className="manage-pipelines-dialog__heading-break" />
      {pipelineManagementType === 'ui' && (
        <>
          <ConditionalRender
            enable={isBuildPullRequest.isLoading || pipeline.isLoading}
          >
            <Loading data-testid="manage-pipelines-as-ui-loading" />
          </ConditionalRender>
          <ConditionalRender
            enable={!isBuildPullRequest.isLoading || !pipeline.isLoading}
          >
            <div className="global-settings">
              <Heading as="h6">
                {t(
                  'viewPipeline.dialog.managePipelines.global.settings.header',
                )}
              </Heading>
              <Text size="small" color="subtle">
                {t(
                  'viewPipeline.dialog.managePipelines.global.settings.description',
                )}
              </Text>
              <div className="checkbox-pr">
                <Checkbox
                  name="allow-pr-from-fork"
                  label={t(
                    'viewPipeline.dialog.managePipelines.global.settings.allow.pr.from.fork.checkbox',
                  )}
                  value={allowPRForkCheck}
                  onChange={() => setAllowPRForkCheck(!allowPRForkCheck)}
                />
              </div>
            </div>
            <div className="manage-pipelines-dialog__heading-break" />
            <div className="pipeline-specific-settings">
              <Heading as="h6">
                {t(
                  'viewPipeline.dialog.managePipelines.specific.settings.header',
                )}
              </Heading>
              <ButtonSelect
                data-testid="pipeline-select"
                className="branch-select"
                type="tertiary"
                options={pipelineOptions}
                value={selectedPipeline?.value}
                onChange={(value: string) => {
                  setPipelineId(value);
                }}
              />
            </div>
            <Text size="small" color="subtle">
              {t('viewPipeline.dialog.managePipelines.triggers.header')}
            </Text>
            <Checkbox
              className="commit-trigger"
              name="commit-trigger"
              label={t(
                'viewPipeline.dialog.managePipelines.triggers.commit.checkbox',
              )}
              value={commitTrigger}
              onChange={(val: boolean) => setCommitTrigger(val)}
            />
            <Checkbox
              className="pullrequest-trigger"
              name="pr-trigger"
              label={t(
                'viewPipeline.dialog.managePipelines.triggers.pullRequest.checkbox',
              )}
              value={prTrigger}
              onChange={(val: boolean) => {
                setPrTrigger(val);
              }}
            />
            <div className="manage-pipelines-dialog__heading-break" />
            {selectedPipeline?.label === 'regex' && (
              <>
                <Form
                  values={regexValues}
                  onChange={(_: string, newBranch: RegexFormValues) =>
                    setRegexValues(newBranch)
                  }
                >
                  <Form.Field
                    type="text"
                    name="regexbranch"
                    placeholder={t(
                      'viewPipeline.dialog.branch.regex.placeholder',
                    )}
                    required
                  />
                </Form>
                {regexValues.regexbranch !== regexValue && (
                  <Alert className="regex-warning" type="danger">
                    {t('viewPipeline.dialog.managePipelines.regex')}
                  </Alert>
                )}
                <div className="manage-pipelines-dialog__heading-break" />
              </>
            )}
            <div className="manage-pipelines-dialog__webhook">
              <Heading as="h6">
                {t(
                  'viewPipeline.dialog.managePipelines.automation.webhook.header',
                )}
              </Heading>
              <Text size="small" color="subtle">
                {t(
                  'viewPipeline.dialog.managePipelines.webhook.description.webhook.added',
                )}
              </Text>
              <Text size="small" color="subtle">
                {t(
                  'viewPipeline.dialog.managePipelines.webhook.description.webhook.url',
                )}
              </Text>
              <Text size="small" color="subtle">
                {t(
                  'viewPipeline.dialog.managePipelines.webhook.description.scopes',
                )}
              </Text>
              <Code
                className="automation-webhook"
                type="block"
                size="small"
                copyable
              >
                {codeProject.webhookUrl}
              </Code>
            </div>
            <ConditionalRender
              enable={
                pipelineManagementType === 'ui' && managementType === 'code'
              }
            >
              <Alert type="warning">
                {t('viewPipeline.dialog.managePipelines.ui.save.warning')}
              </Alert>
            </ConditionalRender>
            <Modal.Actions>
              <Button
                onClick={onSavePipelinesAsUIHandler}
                loading={isSavingPipelineAsUI}
                disabled={isSavingPipelineAsUI}
              >
                {t('viewPipeline.dialog.managePipelines.save.settings.button')}
              </Button>
              <Button
                type="secondary"
                onClick={() => {
                  onClose(false);
                }}
              >
                {t('viewPipeline.dialog.managePipelines.cancel.button')}
              </Button>
            </Modal.Actions>
          </ConditionalRender>
        </>
      )}
      {pipelineManagementType === 'code' && (
        <>
          <ConditionalRender enable={pipeline.isLoading}>
            <Loading data-testid="manage-pipelines-as-code-laoding" />
          </ConditionalRender>
          <ConditionalRender enable={!pipeline.isLoading}>
            <div className="pipelines-as-code-steps">
              <div className="pipelines-as-code-steps__steps">
                <ConditionalRender enable={codeProject.pacEnabled!!}>
                  <Heading as="h6" className="pipelines-as-code-steps__heading">
                    <Trans
                      className="pipelines-as-code-heading"
                      t={t}
                      i18nKey="viewPipeline.dialog.managePipelines.as.code.header.enabled"
                      values={{
                        projectType: getProjectTypeString(projectType),
                        name: selectedPipeline?.label,
                      }}
                      components={[
                        <Text size="small" color="medium" />,
                        <Text size="small" color="medium" />,
                      ]}
                    />
                  </Heading>
                </ConditionalRender>
                <ConditionalRender enable={!codeProject.pacEnabled}>
                  <Heading as="h6" className="pipelines-as-code-steps__heading">
                    {t('viewPipeline.dialog.managePipelines.as.code.header', {
                      projectType: getProjectTypeString(projectType),
                    })}
                  </Heading>
                  <Text size="small" color="subtle">
                    {t('viewPipeline.dialog.managePipelines.as.code.step.one')}
                  </Text>
                  <Text size="small" color="subtle">
                    {t('viewPipeline.dialog.managePipelines.as.code.step.two')}
                  </Text>
                  <Text size="small" color="subtle">
                    {t(
                      'viewPipeline.dialog.managePipelines.as.code.step.three',
                      {
                        projectType: getProjectTypeString(projectType),
                      },
                    )}
                  </Text>
                </ConditionalRender>
              </div>
              <ConditionalRender enable={!codeProject.pacEnabled}>
                <Trans
                  t={t}
                  i18nKey="viewPipeline.dialog.managePipelines.as.code.link"
                >
                  <PdsLink
                    as="a"
                    href={docsLinks().pipelinesAsCode}
                    target="_blank"
                    size="small"
                  >
                    {t('viewPipeline.dialog.managePipelines.as.code.link')}
                  </PdsLink>
                </Trans>
              </ConditionalRender>
            </div>
            <div className="manage-pipelines-dialog__heading-break" />

            <Heading as="h6">
              {t('viewPipeline.dialog.managePipelines.as.code.select.branch')}
            </Heading>
            <Text
              size="small"
              color="subtle"
              className="pipelines-as-code-select-branch-description"
            >
              {t(
                'viewPipeline.dialog.managePipelines.as.code.select.branch.description',
              )}
            </Text>
            <Select
              loading={branches.isLoading}
              className="pipelines-as-code-branch-select"
              options={branchOptions}
              placeholder={t(
                'viewPipeline.dialog.managePipelines.as.code.select.branch',
              )}
              value={selectedPACBranch}
              onChange={setSelectedPACBranch}
              name="branch"
            />
            <div className="pipelines-as-code-action-btns">
              <Button
                onClick={onSavePipelinesAsCodeHandler}
                loading={createPipelineAsCode.isLoading}
                disabled={!selectedPACBranch || createPipelineAsCode.isLoading}
              >
                {t('viewPipeline.dialog.managePipelines.save.settings.button')}
              </Button>
              <Button
                type="secondary"
                onClick={() => {
                  onClose(false);
                }}
              >
                {t('viewPipeline.dialog.managePipelines.cancel.button')}
              </Button>
            </div>
            <Heading as="h6">
              {t('viewPipeline.dialog.managePipelines.as.code.yaml.header', {
                projectType: getProjectTypeString(projectType),
              })}
            </Heading>
            <Text
              size="small"
              color="subtle"
              className="pipelines-as-code-description"
            >
              {t(
                'viewPipeline.dialog.managePipelines.as.code.yaml.description',
              )}
            </Text>
            <Code
              className="pipelines-as-code"
              type="block"
              size="small"
              copyable
            >
              {pipelinesAsCodeObject.data?.pipelineAsCode}
            </Code>
            <ConditionalRender
              enable={
                pipelineManagementType === 'code' && managementType === 'ui'
              }
            >
              <Alert type="warning">
                {t('viewPipeline.dialog.managePipelines.as.code.save.warning')}
              </Alert>
            </ConditionalRender>
            <Modal.Actions>
              <ConditionalRender enable={codeProject.pacEnabled!!}>
                <Button
                  onClick={validatePipelinesAsCodeAsYaml}
                  loading={validatePipelinesAsCode.isLoading}
                  disabled={validatePipelinesAsCode.isLoading}
                >
                  {t(
                    'viewPipeline.dialog.managePipelines.validate.yaml.button',
                  )}
                </Button>
              </ConditionalRender>
            </Modal.Actions>
          </ConditionalRender>
        </>
      )}
      <Cd4peError error={branches.error} />
      <Cd4peError error={isBuildPullRequest.error} />
      <Cd4peError error={pipeline.error} />
      <Cd4peError error={disablePipelineAsCode.error} />
      <Cd4peError error={pipelinesAsCodeObject.error} />
      <Cd4peError error={setBuildPullRequestAllowed.error} />
      <Cd4peError error={validatePipelinesAsCode.error} />
      {createPipelineAsCode.error?.status === 404 && codeProject.pacEnabled ? (
        getPipelineAsCodeWarning()
      ) : (
        <Cd4peError error={createPipelineAsCode.error} />
      )}
      <Cd4peError error={updatePipelineTriggers.error} />
    </Modal>
  );
};

export default ManagePipelinesDialog;
