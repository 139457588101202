/**
 *
 * Non-OpenAPI Ajax endpoints
 *
 */

import { PROXY_HEADER } from '@utils/constants';
import { ApiError, OpenAPI } from '@utils/api/cd4pe';
import log from 'loglevel';
import { LINKS } from '../../routes';
import { User } from './ajaxTypes';

/**
 * Login API
 */

export type LoginPayload = {
  email: string;
  passwd: string;
};

export type LoginResponse = {
  domain: string;
  redirectTo: string;
  success: boolean;
  username: string;
};

export type AjaxError = {
  error: {
    message: string;
    code: string;
  };
};

export type SignUpPayload = {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  passwd: string;
  passwd2: string;
  companyName?: string;
};

export type SignUpResponse = {
  domain: string;
  redirectTo: string;
  success: boolean;
  username: string;
};

export interface ListUsersByPrefixPayload {
  prefix: string;
  scope: string;
}

export type DeleteUserPayload = {
  usernameToDelete: string;
};

export type SetSuperUserPayload = {
  username: string;
  superUser: 'true' | 'false';
};

export type SetUserPasswordPayload = {
  username: string;
  password: string;
};

export type SetUserPasswordResponse = {
  domain: string;
  redirectTo: string;
  success: boolean;
  username: string;
};

export const login = async (
  path: string,
  payload: LoginPayload,
): Promise<LoginResponse> => {
  const response = await fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      op: 'PfiLogin',
      content: payload,
    }),
  });

  const body: LoginResponse | AjaxError = await response.json();

  if (response.ok) {
    return body as LoginResponse;
  }

  return 'error' in body
    ? Promise.reject(new Error(body.error?.message))
    : Promise.reject(new Error('Unknown error'));
};

export const performLogin = async (
  payload: LoginPayload,
): Promise<LoginResponse> => login('/cd4pe/login', payload);

export const performRootLogin = async (
  payload: LoginPayload,
): Promise<LoginResponse> => login('/cd4pe/root/login', payload);

export const signUp = async (
  payload: SignUpPayload,
): Promise<SignUpResponse> => {
  const response = await fetch('/cd4pe/signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      op: 'PfiSignup',
      content: payload,
    }),
  });

  const body: SignUpResponse | AjaxError = await response.json();

  if (response.ok) {
    return body as SignUpResponse;
  }

  return 'error' in body
    ? Promise.reject(new Error(body.error.message))
    : Promise.reject(new Error('Unknown error'));
};

export const signOut = async (): Promise<boolean> => {
  try {
    const response = await fetch(LINKS.cd4pe.logout, {
      method: 'post',
      headers: new Headers({
        'content-type': 'application/json',
        [PROXY_HEADER.key]: PROXY_HEADER.value,
      }),
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({
        op: 'PfiLogout',
        content: null,
      }),
    });

    OpenAPI.HEADERS = {};
    // ok is true if a successful status code was returned
    return response.ok;
  } catch (e) {
    log.error(e);
  }

  return false;
};

export const setUserPassword = async (
  path: string,
  payload: SetUserPasswordPayload,
): Promise<SetUserPasswordResponse> => {
  const apiUrl = `${path}/${payload.username}/ajax?op=SetUserPassword&username=${payload.username}&password=${payload.password}`;
  const response = await fetch(apiUrl, {
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
  });

  const body: SetUserPasswordResponse | AjaxError = await response.json();

  if (response.ok) {
    return body as SetUserPasswordResponse;
  }

  return 'error' in body
    ? Promise.reject(new Error(body.error.message))
    : Promise.reject(new Error('Unknown error'));
};

export const performSetUserPassword = async (
  payload: SetUserPasswordPayload,
): Promise<SetUserPasswordResponse> => setUserPassword('/cd4pe', payload);

export const listUsersByPrefix = async (
  payload: ListUsersByPrefixPayload,
  loggedInUsername: string,
): Promise<User[]> => {
  const { prefix, scope } = payload;
  const query = new URLSearchParams(
    Object.entries({ prefix, scope, op: 'ListUsersByPrefix' }),
  );

  const response = await fetch(
    `/${loggedInUsername}/ajax?${query.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const body: User[] | AjaxError = await response.json();

  if (response.ok) {
    return body as User[];
  }

  return 'error' in body
    ? Promise.reject(new ApiError(response, body.error.message))
    : Promise.reject(new ApiError(response, 'unknown error'));
};

export const deleteUser = async (
  payload: DeleteUserPayload,
  loggedInUsername: string,
): Promise<undefined> => {
  const { usernameToDelete } = payload;
  const query = new URLSearchParams(
    Object.entries({ userName: usernameToDelete, op: 'DeleteUserAccount' }),
  );

  const response = await fetch(
    `/${loggedInUsername}/ajax?${query.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.ok) {
    return undefined;
  }

  const body: AjaxError = await response.json();

  return 'error' in body
    ? Promise.reject(new ApiError(response, body.error.message))
    : Promise.reject(new ApiError(response, 'Unknown error'));
};

export const setSuperUser = async (
  loggedInUsername: string,
  payload: SetSuperUserPayload,
): Promise<undefined> => {
  const query = new URLSearchParams(
    Object.entries({ ...payload, op: 'SetSuperUser' }),
  );

  const response = await fetch(
    `/${loggedInUsername}/ajax?${query.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.ok) {
    return undefined;
  }

  const body: AjaxError = await response.json();

  return 'error' in body
    ? Promise.reject(new ApiError(response, body.error.message))
    : Promise.reject(new ApiError(response, 'Unknown error'));
};
