const getPercentages = (rawPercentages) => {
  const roundedPercentages = rawPercentages.map((fraction) => {
    return Math.round(fraction);
  });

  // The percentages rounded, total 100% and there is no rounding issues present
  if (roundedPercentages.reduce((a, b) => a + b, 0) === 100) {
    return roundedPercentages;
  }

  // calculate the percentage deficit when all vales are rounded down
  let diff =
    100 -
    rawPercentages
      .map((fraction) => {
        return Math.floor(fraction);
      })
      .reduce((a, b) => a + b, 0);

  // create an array of objects to track original order
  const sortArray = rawPercentages.map((data, index) => {
    return { index, data };
  });

  // sort the values by they largest decimal value
  sortArray.sort((a, b) => {
    return b.data - Math.floor(b.data) - (a.data - Math.floor(a.data));
  });
  // add the remaining percentage deficit to the values with the highest decimal value
  const results = sortArray.map((fraction) => {
    if (diff) {
      diff += -1;
      return {
        index: fraction.index,
        data: Math.floor(fraction.data) + 1,
      };
    }
    return {
      index: fraction.index,
      data: Math.floor(fraction.data),
    };
  });

  // revert the array order to the original order
  results.sort((a, b) => {
    return a.index - b.index;
  });

  // return just the percentage values
  return results.map((fraction) => {
    return fraction.data;
  });
};

export default getPercentages;
