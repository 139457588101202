import React, { Dispatch, useEffect, useReducer, useRef } from 'react';
import {
  Button,
  Form,
  Heading,
  Link,
  Loading,
  RadioButton,
  Text,
  TooltipHoverArea,
} from '@puppet/react-components';
import { Table } from '@puppet/data-grid';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import docsLinks from '@codeDelivery/utils/docsLinks';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { CommonFormChangeFunction } from '@components/CommonForm/CommonForm';
import CommonForm from '@components/CommonForm';
import Breadcrumbs from '@components/Breadcrumbs';
import Definitions from '@components/Definitions';
import ShellEditor from '@codeDelivery/components/ShellEditor';
import {
  Cd4peApiError,
  JobTemplateV1,
  ListJobHardwareCapabilitiesResponseV1,
  SecretDetailsV1,
  SharedDockerImageSettingsV1,
} from '@utils/api/cd4pe';
import Cd4peError from '@components/Cd4peError';
import { defaultJobTemplateState, JobTemplateState, reducer } from './reducer';
import {
  initializeFormState,
  JobTemplateActions,
  jobTemplateActions,
  JobTemplateFormValues,
} from './actions';

/* eslint-disable react/no-unused-prop-types */
interface Props {
  breadcrumbs: {
    translationKey: string;
    linkDestination?: string | null;
  }[];
  titleTranslationKey: string;
  subtitleTranslationKey: string;
  // eslint-disable-next-line react/no-unused-prop-types
  defaultContainer: SharedDockerImageSettingsV1 | null;
  jobTemplate?: JobTemplateV1 | null;
  onSubmit: (formValues: JobTemplateFormValues) => void;
  onCancel: () => void;
  submitting: boolean;
  hardwareCapabilities:
    | ListJobHardwareCapabilitiesResponseV1['jobHardwareCapabilities']
    | null;
  submitTranslationKey: string;
  loading: boolean;
  loadError?: Cd4peApiError | null;
  formError?: Cd4peApiError | null;
  secrets?: SecretDetailsV1[];
  secretsError?: Cd4peApiError | null;
  onViewSecret?: (secretName: string) => void;
  onEditSecret?: (secretName: string) => void;
  onAddSecret?: () => void;
  onDeleteSecret?: (secretName: string) => void;
}

/* eslint-enable react/no-unused-prop-types */

const CLASS_PREFIX = 'job-template';

const FIELD_NAMES = {
  name: 'name',
  description: 'description',
  commands: 'commands',
  commandsSuccess: 'commandsSuccess',
  commandsError: 'commandsError',
  runOnSharedHardware: 'runOnSharedHardware',
  hardwareCapabilities: 'hardwareCapabilities',
  runInDocker: 'runInDocker',
  imageName: 'imageName',
  dockerArgs: 'dockerArgs',
  envVars: 'envVars',
};

const transformBreadcrumbs = (
  breadcrumbs: Props['breadcrumbs'],
  workspace: string,
  t: TFunction<'codeDelivery'>,
) => {
  const crumbs = breadcrumbs.map((b) => {
    const { translationKey, ...rest } = b;
    return {
      displayName: t(translationKey),
      ...rest,
    };
  });
  return [{ displayName: workspace }, ...crumbs];
};

const nameSection = (t: TFunction<'codeDelivery'>) => {
  return (
    <CommonForm.Section>
      <CommonForm.Section.Main>
        <h2 className={`${CLASS_PREFIX}__header`}>
          {t('jobTemplate.section.name.header')}
        </h2>
        <Text className={`${CLASS_PREFIX}--stack-spacing-m`} size="small">
          {t('jobTemplate.section.name.subtitle')}
        </Text>
        <Form.Field
          type="text"
          name={FIELD_NAMES.name}
          label={t('jobTemplate.fields.name.label')}
          placeholder={t('jobTemplate.fields.name.placeholder')}
          required
        />
        <Form.Field
          type="text"
          name={FIELD_NAMES.description}
          label={t('jobTemplate.fields.description.label')}
          placeholder={t('jobTemplate.fields.description.placeholder')}
        />
      </CommonForm.Section.Main>
    </CommonForm.Section>
  );
};

const expandedCommands = (
  t: TFunction<'codeDelivery'>,
  formState: JobTemplateFormValues,
  dispatch: Dispatch<JobTemplateActions>,
) => {
  const successCommandsLabel = (
    <span
      className={`${CLASS_PREFIX}__label ${CLASS_PREFIX}__label--spacing-s`}
    >
      {t('jobTemplate.fields.commandsSuccess.label')}
    </span>
  );
  const errorCommandsLabel = (
    <span
      className={`${CLASS_PREFIX}__label ${CLASS_PREFIX}__label--spacing-s`}
    >
      {t('jobTemplate.fields.commandsError.label')}
    </span>
  );

  return (
    <>
      <h3 className={`${CLASS_PREFIX}__header--small`}>
        {t('jobTemplate.section.jobCommandsSuccess.header')}
      </h3>
      <ShellEditor
        className={`${CLASS_PREFIX}-code`}
        value={formState.commandsSuccess}
        onChange={(v) =>
          dispatch(jobTemplateActions.editForm({ commandsSuccess: v }))
        }
        name={FIELD_NAMES.commandsSuccess}
        label={successCommandsLabel}
      />
      <h3 className={`${CLASS_PREFIX}__header--small`}>
        {t('jobTemplate.section.jobCommandsError.header')}
      </h3>
      <ShellEditor
        className={`${CLASS_PREFIX}-code`}
        value={formState.commandsError}
        onChange={(v) =>
          dispatch(jobTemplateActions.editForm({ commandsError: v }))
        }
        name={FIELD_NAMES.commandsError}
        label={errorCommandsLabel}
      />
    </>
  );
};

const jobSecretsSection = (
  t: TFunction<'codeDelivery'>,
  {
    onAddSecret,
    onViewSecret,
    onEditSecret,
    onDeleteSecret,
    jobTemplate,
    secrets,
    secretsError,
  }: Props,
) => {
  const columns = [
    {
      label: t('jobTemplate.section.secrets.table.header.name'),
      dataKey: 'name',
    },
    {
      label: t('jobTemplate.section.secrets.table.header.actions'),
      dataKey: 'actions',
      className: 'job-template-secrets-table__cell--actions',
      style: { textAlign: 'right' },
    },
  ];

  const secretDocsLink = (
    <Link href={docsLinks().jobTemplateSecrets} size="small" />
  );

  if (!jobTemplate) {
    return (
      <div className="job-template-secrets">
        <h4>{t('jobTemplate.section.secrets.header')}</h4>
        <Text className="job-template-secrets__subtitle" size="small">
          <Trans
            t={t}
            i18nKey="jobTemplate.section.secrets.newJobSubtitle"
            components={[secretDocsLink]}
          />
        </Text>
        <Button type="text" icon="plus-circle" disabled>
          {t('jobTemplate.buttons.addSecret')}
        </Button>
      </div>
    );
  }

  if (!onViewSecret || !onAddSecret || !onEditSecret || !onDeleteSecret) {
    return (
      <div className="job-template-secrets job-template-secrets--disabled">
        <h4>{t('jobTemplate.section.secrets.header')}</h4>
        <Text className="job-template-secrets__subtitle" size="small">
          <Trans
            t={t}
            i18nKey="jobTemplate.section.secrets.subtitle"
            components={[secretDocsLink]}
          />
        </Text>
        <Button type="text" icon="plus-circle" disabled>
          {t('jobTemplate.buttons.addSecret')}
        </Button>
      </div>
    );
  }

  const rows = (secrets || []).map((s) => ({
    name: (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        className="job-template-secrets-table__link-button"
        as={Button}
        onClick={() => onViewSecret(s.name)}
        size="small"
        aria-label={s.name}
      >
        {s.name}
      </Link>
    ),
    actions: (
      <>
        <TooltipHoverArea
          tooltip={t('jobTemplate.iconButtons.editSecret.tooltip')}
          anchor="bottom"
        >
          <Button
            aria-label={t('jobTemplate.iconButtons.editSecret.ariaLabel', {
              secretName: s.name,
            })}
            icon="pencil"
            type="transparent"
            onClick={() => onEditSecret(s.name)}
          />
        </TooltipHoverArea>
        <TooltipHoverArea
          tooltip={t('jobTemplate.iconButtons.deleteSecret.tooltip')}
          anchor="bottom"
        >
          <Button
            aria-label={t('jobTemplate.iconButtons.deleteSecret.ariaLabel', {
              secretName: s.name,
            })}
            icon="trash"
            type="transparent"
            onClick={() => onDeleteSecret(s.name)}
          />
        </TooltipHoverArea>
      </>
    ),
  }));

  const secretsTable =
    !secrets || secrets.length <= 0 ? null : (
      <div className="job-template-secrets__table job-template--stack-spacing-l">
        <Table columns={columns} data={rows} />
      </div>
    );

  return (
    <div className="job-template-secrets">
      <h4>{t('jobTemplate.section.secrets.header')}</h4>
      <Text className="job-template-secrets__subtitle" size="small">
        <Trans
          t={t}
          i18nKey="jobTemplate.section.secrets.subtitle"
          components={[secretDocsLink]}
        />
      </Text>
      <Cd4peError error={secretsError} />
      {secretsTable}
      <Button type="text" icon="plus-circle" onClick={onAddSecret}>
        {t('jobTemplate.buttons.addSecret')}
      </Button>
    </div>
  );
};

const jobCommandsSection = (
  t: TFunction<'codeDelivery'>,
  state: JobTemplateState,
  props: Props,
  dispatch: Dispatch<JobTemplateActions>,
) => {
  const commandsLabel = (
    <span
      className={`${CLASS_PREFIX}__label ${CLASS_PREFIX}__label--spacing-s`}
    >
      {t('jobTemplate.fields.commands.label')}
    </span>
  );

  const secretsSidebar = (
    <CommonForm.Section.Sidebar>
      {jobSecretsSection(t, props)}
    </CommonForm.Section.Sidebar>
  );

  const commandsHelp = (
    <Definitions.Entry
      icon="info-circle"
      className={`${CLASS_PREFIX}-commands__sidebar`}
    >
      <Definitions.Entry.Title
        className={`${CLASS_PREFIX}-commands-help__header`}
      >
        {t('jobTemplate.commandsSidebar.needHelp.title')}
      </Definitions.Entry.Title>
      <Definitions.Entry.Content>
        <Link href={docsLinks().preBuiltJobsReference} size="small">
          {t('jobTemplate.commandsSidebar.needHelp.link')}
        </Link>
      </Definitions.Entry.Content>
    </Definitions.Entry>
  );

  return (
    <CommonForm.Section>
      <CommonForm.Section.Main className={`${CLASS_PREFIX}-commands`} fullWidth>
        <h2
          className={`${CLASS_PREFIX}__header ${CLASS_PREFIX}--stack-spacing-s`}
        >
          {t('jobTemplate.section.jobCommands.header')}
        </h2>

        {commandsHelp}

        <div className={`${CLASS_PREFIX}-commands__column`}>
          <div className={`${CLASS_PREFIX}-commands__field`}>
            <ShellEditor
              className={`${CLASS_PREFIX}-code`}
              value={state.formState.commands}
              onChange={(v) => dispatch(jobTemplateActions.setCommands(v))}
              error={
                state.commandsMissing
                  ? t('jobTemplate.error.requiredField')
                  : null
              }
              name={FIELD_NAMES.commands}
              label={commandsLabel}
            />

            <Button
              className={classnames(`${CLASS_PREFIX}__expand-commands`, {
                [`${CLASS_PREFIX}--stack-spacing-l`]: state.isExpanded,
              })}
              type="text"
              trailingIcon={
                state.isExpanded ? 'chevron-double-up' : 'chevron-double-down'
              }
              onClick={() => dispatch(jobTemplateActions.toggleCommandsView())}
            >
              {t('jobTemplate.button.expandCommands')}
            </Button>
            {state.isExpanded && expandedCommands(t, state.formState, dispatch)}
          </div>
        </div>
      </CommonForm.Section.Main>
      {secretsSidebar}
    </CommonForm.Section>
  );
};

const hardwareCapabilitiesSection = (
  t: TFunction<'codeDelivery'>,
  hardwareCapabilities: ListJobHardwareCapabilitiesResponseV1['jobHardwareCapabilities'],
) => {
  const hardwareCapabilitiesLabel = (
    <span className={`${CLASS_PREFIX}__label`}>
      {t('jobTemplate.fields.hardwareCapabilities.label')}
    </span>
  );

  const options = hardwareCapabilities.map((h) => ({
    label: h.displayName,
    value: h.displayName.toLowerCase(),
  }));

  return (
    <CommonForm.Section.Main
      className={`${CLASS_PREFIX}-run-location__section`}
    >
      <h3 className={`${CLASS_PREFIX}__header--small`}>
        {t('jobTemplate.section.hardwareCapabilities.header')}
      </h3>
      <Form.Field
        type="multiselect"
        name={FIELD_NAMES.hardwareCapabilities}
        options={options}
        label={hardwareCapabilitiesLabel}
        placeholder={t('jobTemplate.fields.hardwareCapabilities.placeholder')}
      />
    </CommonForm.Section.Main>
  );
};

const defaultDockerImage = (
  t: TFunction<'codeDelivery'>,
  imageName: string,
) => (
  <div>
    <Text size="small">
      {t('jobTemplate.section.runLocation.defaultContainer')}
    </Text>
    <Text data-testid="default-docker-name" size="small" color="medium">
      {imageName}
    </Text>
  </div>
);

const dockerConfig = (
  t: TFunction<'codeDelivery'>,
  workspaceName: string,
  dispatch: Dispatch<JobTemplateActions>,
  imageName: string,
  formState: JobTemplateFormValues,
) => {
  const imageNameLabel = (
    <span className={`${CLASS_PREFIX}__label`}>
      <Trans
        t={t}
        i18nKey="jobTemplate.fields.imageName.label"
        components={[<span className={`${CLASS_PREFIX}__label--light`} />]}
      />
    </span>
  );

  const dockerArgsLabel = (
    <span className={`${CLASS_PREFIX}__label`}>
      <Trans
        t={t}
        i18nKey="jobTemplate.fields.dockerArgs.label"
        components={[<span className={`${CLASS_PREFIX}__label--light`} />]}
      />
    </span>
  );

  return (
    <>
      <div
        className={`${CLASS_PREFIX}-run-location__radio-group ${CLASS_PREFIX}--stack-spacing-xl`}
      >
        <RadioButton
          className={`${CLASS_PREFIX}-run-location__radio-group--radio`}
          name="hardwareTypeWorkspaceDefault"
          value={formState.runOnDefaultImage}
          label={t(
            'jobTemplate.fields.hardwareTypeWorkspaceDefaultImage.label',
          )}
          onChange={() =>
            dispatch(
              jobTemplateActions.editForm({
                runOnDefaultImage: true,
                imageName: '',
              }),
            )
          }
        />
        <RadioButton
          className={`${CLASS_PREFIX}-run-location__radio-group--radio`}
          name="hardwareTypeWorkspaceCustom"
          value={!formState.runOnDefaultImage}
          label={t(
            'jobTemplate.fields.hardwareTypeWorkspaceCustomImage.label',
            {
              workspaceName,
            },
          )}
          onChange={() =>
            dispatch(jobTemplateActions.editForm({ runOnDefaultImage: false }))
          }
        />
      </div>
      {formState.runOnDefaultImage ? (
        defaultDockerImage(t, imageName)
      ) : (
        <Form.Field
          type="text"
          name={FIELD_NAMES.imageName}
          label={imageNameLabel}
          placeholder={t('jobTemplate.fields.imageName.placeholder')}
          required
        />
      )}

      <Form.Field
        className={`${CLASS_PREFIX}--stack-spacing-xxl_top_xl_bottom`}
        type="text"
        name={FIELD_NAMES.dockerArgs}
        label={dockerArgsLabel}
        placeholder={t('jobTemplate.fields.dockerArgs.placeholder')}
      />
    </>
  );
};
const runInDocker = (
  t: TFunction<'codeDelivery'>,
  formState: JobTemplateFormValues,
  workspaceName: string,
  dispatch: Dispatch<JobTemplateActions>,
  imageName: string,
) => {
  return (
    <>
      <Text className={`${CLASS_PREFIX}--stack-spacing-xs`} size="small">
        {t('jobTemplate.section.optionalConfig.subtitle')}
      </Text>

      <Form.Field
        className={`${CLASS_PREFIX}--stack-spacing-xl`}
        type="switch"
        name={FIELD_NAMES.runInDocker}
        label={t('jobTemplate.fields.runInDocker.label')}
      />
      {formState.runInDocker &&
        dockerConfig(t, workspaceName, dispatch, imageName, formState)}
    </>
  );
};

const optionalConfigSection = (
  t: TFunction<'codeDelivery'>,
  formState: JobTemplateFormValues,
  workspaceName: string,
  dispatch: Dispatch<JobTemplateActions>,
  imageName: string,
) => {
  const envVarsLabel = (
    <span className={`${CLASS_PREFIX}__label`}>
      {t('jobTemplate.fields.envVars.label')}
    </span>
  );

  return (
    <CommonForm.Section.Main
      className={`${CLASS_PREFIX}-run-location__section ${CLASS_PREFIX}__section--wide`}
      fullWidth
    >
      <h3
        className={`${CLASS_PREFIX}__header--small ${CLASS_PREFIX}--stack-spacing-m`}
      >
        {t('jobTemplate.section.optionalConfig.header')}
      </h3>

      {!formState.runOnSharedHardware &&
        runInDocker(t, formState, workspaceName, dispatch, imageName)}

      <Form.Field
        className={`${CLASS_PREFIX}-run-location__env-vars`}
        type="multiline"
        name={FIELD_NAMES.envVars}
        label={envVarsLabel}
        placeholder={t('jobTemplate.fields.envVars.placeholder')}
      />
    </CommonForm.Section.Main>
  );
};

const runSection = (
  t: TFunction<'codeDelivery'>,
  formState: JobTemplateFormValues,
  props: Props,
  workspaceName: string,
  dispatch: Dispatch<JobTemplateActions>,
) => {
  const imageName = props.defaultContainer?.imageName || '';
  return (
    <CommonForm.Section className={`${CLASS_PREFIX}-run-location`}>
      <CommonForm.Section.Main
        className={`${CLASS_PREFIX}-run-location__section`}
      >
        <h2 className={`${CLASS_PREFIX}__header`}>
          {t('jobTemplate.section.runLocation.header')}
        </h2>

        <Text className={`${CLASS_PREFIX}--stack-spacing-l`} size="small">
          {t('jobTemplate.section.runLocation.subtitle')}
        </Text>

        <div
          className={`${CLASS_PREFIX}-run-location__radio-group ${CLASS_PREFIX}--stack-spacing-xl`}
        >
          <RadioButton
            className={`${CLASS_PREFIX}-run-location__radio-group--radio`}
            name="hardwareTypeShared"
            value={formState.runOnSharedHardware}
            label={t('jobTemplate.fields.hardwareTypeShared.label')}
            onChange={() =>
              dispatch(jobTemplateActions.setRunOnSharedHardware(true))
            }
          />
          <RadioButton
            className={`${CLASS_PREFIX}-run-location__radio-group--radio`}
            name="hardwareTypeWorkspace"
            value={!formState.runOnSharedHardware}
            label={t('jobTemplate.fields.hardwareTypeWorkspace.label', {
              workspaceName,
            })}
            onChange={() =>
              dispatch(jobTemplateActions.setRunOnSharedHardware(false))
            }
          />
        </div>

        {formState.runOnSharedHardware && defaultDockerImage(t, imageName)}
      </CommonForm.Section.Main>
      {!formState.runOnSharedHardware &&
        hardwareCapabilitiesSection(t, props.hardwareCapabilities || [])}
      {optionalConfigSection(t, formState, workspaceName, dispatch, imageName)}
    </CommonForm.Section>
  );
};

const pageBody = (
  t: TFunction<'codeDelivery'>,
  state: JobTemplateState,
  props: Props,
  workspaceName: string,
  dispatch: Dispatch<JobTemplateActions>,
  formRef: React.RefObject<HTMLFormElement>,
) => {
  const { defaultContainer, hardwareCapabilities } = props;
  if (props.loading || !defaultContainer || !hardwareCapabilities) {
    return <Loading data-testid="job-template-spinner" />;
  }

  const onChange: CommonFormChangeFunction<JobTemplateFormValues> = (
    fieldName,
    values,
  ) => {
    if (fieldName === 'runInDocker') {
      dispatch(jobTemplateActions.setRunInDocker(values.runInDocker || false));
      return;
    }
    dispatch(jobTemplateActions.editForm({ [fieldName]: values[fieldName] }));
  };

  const onSubmit = () => {
    if (state.formState.commands) {
      props.onSubmit(state.formState);
    }
  };

  return (
    <CommonForm
      ref={formRef}
      values={state.formState}
      onChange={onChange}
      submitLabel={t(props.submitTranslationKey)}
      onCancel={props.onCancel}
      onSubmit={onSubmit}
      submitting={props.submitting}
      submittable
      cancellable
    >
      {nameSection(t)}
      {jobCommandsSection(t, state, props, dispatch)}
      {runSection(t, state.formState, props, workspaceName, dispatch)}
      <Cd4peError error={props.formError} />
    </CommonForm>
  );
};

const JobTemplate = (props: Props) => {
  const { t } = useTranslation('codeDelivery');
  const [state, dispatch] = useReducer(reducer, defaultJobTemplateState);
  const workspaceName = useWorkspaceName();
  const formRef = useRef<HTMLFormElement>(null);

  const {
    breadcrumbs,
    titleTranslationKey,
    subtitleTranslationKey,
    jobTemplate,
    loadError,
  } = props;

  // Since the onSubmit function for the Form component does not get called if
  // there are errors in the form, the errors for the ShellEditor cannot be correctly
  // set. This listener allows the JobTemplate component to track if the submit button
  // was clicked even if the onSubmit function isn't called.
  useEffect(() => {
    const submitInterceptor = () => {
      dispatch(jobTemplateActions.submitAttempted());
    };

    if (formRef.current) {
      formRef.current.addEventListener('submit', submitInterceptor, false);
    }
  });

  useEffect(() => {
    initializeFormState(jobTemplate || null, dispatch);
  }, [jobTemplate]);

  const body = loadError ? (
    <Cd4peError error={loadError} />
  ) : (
    pageBody(t, state, props, workspaceName, dispatch, formRef)
  );

  return (
    <>
      <div className={`${CLASS_PREFIX}-header`}>
        <Breadcrumbs
          breadcrumbs={transformBreadcrumbs(breadcrumbs, workspaceName, t)}
        />
        <Heading>{t(titleTranslationKey)}</Heading>
        <Text>{t(subtitleTranslationKey)}</Text>
      </div>
      <div className={`${CLASS_PREFIX}-body`}>{body}</div>
    </>
  );
};

JobTemplate.defaultProps = {
  jobTemplate: null,
  formError: null,
} as Partial<Props>;

export default JobTemplate;
