import React from 'react';
import { Icon } from '@puppet/react-components';

type Props = {
  message: string;
};

const FormError = ({ message }: Props) => {
  return (
    <div className="form-error">
      <Icon className="form-error__icon" type="error" size="medium" />
      <div className="form-error__message">{message}</div>
    </div>
  );
};

export default FormError;
