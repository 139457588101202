import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ErrorBoundary from '@views/ErrorBoundary';
import Login from '@views/Login';
import SignUp from '@views/SignUp';
import Alerts from '@components/Alerts';
import ProtectedRoute from '@components/ProtectedRoute';
import WorkspaceRedirect from '@components/WorkspaceRedirect';
import AccessError from '@components/AccessError';
import { getSegment } from 'src/routes';
import ForgotPassword from '@views/ForgotPassword';
import ResetPassword from '@views/ResetPassword';
import ChooseWorkspace from '@views/ChooseWorkspace';
import Profile from '@views/Profile';
import Activity from '@views/Activity';
import RootAccounts from '@views/RootAccounts';
import PublicRoute from '@components/PublicRoute';
import Layout from '@components/Layout';
import { ROUTE_TYPES } from '@utils/constants';
import Cd4pe from './cd4pe';
import CodeDelivery from './codeDelivery';
import Inventory from './inventory';
import './App.scss';

const App = () => {
  return (
    <div className="app">
      <ErrorBoundary>
        <Alerts />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<WorkspaceRedirect />}>
              {CodeDelivery}
              {Inventory}
              {Cd4pe}
              <Route
                path={getSegment('username')}
                element={<Layout routeType={ROUTE_TYPES.codeDelivery} />}
              >
                <Route
                  path={getSegment('username.profile')}
                  element={<Profile />}
                />
              </Route>
              <Route
                path={getSegment('workspace')}
                element={<Layout routeType={ROUTE_TYPES.codeDelivery} />}
              >
                <Route
                  path={getSegment('workspace.activity')}
                  element={<Activity />}
                />
              </Route>
            </Route>
            <Route
              path={getSegment('root')}
              element={<Layout routeType={ROUTE_TYPES.codeDelivery} />}
            >
              <Route index element={<RootAccounts />} />
            </Route>
            <Route path={getSegment('username')}>
              <Route
                path={getSegment('username.chooseWorkspace')}
                element={<ChooseWorkspace />}
              />
            </Route>
          </Route>
          <Route element={<PublicRoute />}>
            <Route path={getSegment('login')} element={<Login />} />
            <Route path={getSegment('signup')} element={<SignUp />} />
            <Route
              path={getSegment('forgotPassword')}
              element={<ForgotPassword />}
            />
            <Route
              path={getSegment('resetPassword')}
              element={<ResetPassword />}
            />
          </Route>
          <Route
            path={getSegment('forbidden')}
            element={<AccessError code="403" />}
          />
          <Route
            path={getSegment('notFound')}
            element={<AccessError code="404" />}
          />
          <Route path="*" element={<AccessError code="404" />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
};

export default App;
