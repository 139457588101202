import React from 'react';
import PropTypes from 'prop-types';
import './CardGrid.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: 'cb-grid-container',
};

const CardGrid = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

CardGrid.propTypes = propTypes;
CardGrid.defaultProps = defaultProps;

export default CardGrid;
