import React, { ReactNode, useState } from 'react';
import Table, { DataItem } from '@components/Table';
import { CodeProjectDetailsV1 } from '@puppet/cd4pe-client-ts';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { Link as PdsLink } from '@puppet/react-components';
import { Table as PdsTable } from '@puppet/data-grid';
import { Cd4peApiError } from '@utils/api/cd4pe';
import { useTranslation } from 'react-i18next';
import CursorPagination from '@codeDelivery/components/CursorPagination';
import { LINKS } from 'src/routes';
import { NavLink } from 'react-router-dom';
import useWorkspaceName from '@hooks/useWorkspaceName';
import useVcsLinks from '@hooks/useVcsLinks';
import Cd4peError from '@components/Cd4peError';
import DeleteProjectButton from './components/DeleteProjectButton';
import EventDetails, {
  eventDetailsFromDeployment,
  eventDetailsFromJob,
} from './components/EventDetails';

type TableRow = DataItem & {
  projects: ReactNode;
  latest: ReactNode;
  actions: ReactNode;
};

type Props = {
  projectsByPage: CodeProjectDetailsV1[][];
  infiniteQueryDetails: Omit<
    UseInfiniteQueryResult<unknown, Cd4peApiError>,
    'data'
  >;
  onDelete: (projectName: string, onSuccess: () => void) => void;
  deleteError: Cd4peApiError | null;
  getProjectLink: (name: string) => string;
  translationSuffix: 'controlRepo' | 'module';
  prefix: string;
  setPrefix: (prefix: string) => void;
};

const ListCodeProjects = ({
  translationSuffix,
  projectsByPage,
  infiniteQueryDetails,
  onDelete,
  deleteError,
  getProjectLink,
  prefix,
  setPrefix,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspace = useWorkspaceName();
  const { getCommitLink, getBranchLink } = useVcsLinks();
  const [currentPage, setCurrentPage] = useState(0);

  const selectPage = (newPage: number) => {
    if (newPage < 0) {
      setCurrentPage(0);
      return;
    }

    if (newPage >= (projectsByPage.length ?? 0)) {
      if (
        !infiniteQueryDetails.isFetchingNextPage &&
        infiniteQueryDetails.hasNextPage
      ) {
        infiniteQueryDetails.fetchNextPage();
      }
      setCurrentPage(projectsByPage.length ?? 0);
      return;
    }

    setCurrentPage(newPage);
  };

  const transformTableData = (
    projectPage: CodeProjectDetailsV1[],
  ): TableRow[] => {
    return projectPage.map((project) => {
      const projectBranchLink = (branch: string) =>
        getBranchLink(
          project.srcRepoProvider,
          project.srcRepoOwner,
          project.srcRepoDisplayName,
          project.srcRepoId,
          branch,
        ) ?? '';

      const projectCommitLink = (commitId: string) =>
        getCommitLink(
          project.srcRepoProvider,
          project.srcRepoOwner,
          project.srcRepoDisplayName,
          project.srcRepoId,
          commitId,
        ) ?? '';

      const row: TableRow = {
        projects: (
          <PdsLink
            as={NavLink}
            data-testid={`list-code-project-link-${project.name}`}
            to={getProjectLink(project.name)}
          >
            {project.name}
          </PdsLink>
        ),
        latest: (
          <>
            {project.lastJob && (
              <EventDetails
                data-testid="list-code-project-last-job"
                title={t('listProjects.latest.title.job')}
                eventLink={LINKS.codeDelivery.jobDetails({
                  path: {
                    jobInstanceId: project.lastJob.vmJobInstanceId,
                    workspace,
                  },
                })}
                branchLink={projectBranchLink(project.lastJob.branch)}
                commitLink={projectCommitLink(project.lastJob.commitId)}
                details={eventDetailsFromJob(project.lastJob)}
              />
            )}
            {project.lastDeployment && (
              <EventDetails
                data-testid="list-code-project-last-deployment"
                title={t('listProjects.latest.title.deployment')}
                eventLink={LINKS.cd4pe.viewDeployment({
                  path: {
                    id: project.lastDeployment.deploymentId,
                    workspace,
                  },
                })}
                branchLink={projectBranchLink(
                  project.lastDeployment.sourceBranch,
                )}
                commitLink={projectCommitLink(project.lastDeployment.commitId)}
                details={eventDetailsFromDeployment(project.lastDeployment)}
                deployment={{
                  targetBranch: project.lastDeployment.targetBranch,
                  link: projectBranchLink(project.lastDeployment.targetBranch),
                }}
                href
              />
            )}
          </>
        ),
        actions: (
          <DeleteProjectButton
            projectName={project.name}
            typeName={t(`listProjects.delete.typeName.${translationSuffix}`)}
            onDelete={(onSuccess) =>
              onDelete(project.name, () => {
                if (projectsByPage[currentPage]?.length === 1) {
                  selectPage(currentPage - 1);
                }
                return onSuccess();
              })
            }
            error={deleteError}
          />
        ),
      };

      return row;
    });
  };

  return (
    <>
      <Cd4peError error={infiniteQueryDetails.error} />
      <PdsTable.TableHeader
        search
        searchPlaceholder={t(
          `listProjects.search.placeholder.${translationSuffix}`,
        )}
        searchValue={prefix}
        onSearchChange={(newPrefix: string) => {
          setCurrentPage(0);
          setPrefix(newPrefix);
        }}
      />
      <Table
        data={transformTableData(projectsByPage[currentPage] ?? [])}
        loading={
          infiniteQueryDetails.isFetching &&
          currentPage === projectsByPage.length
        }
        columns={[
          {
            label: t(
              `listProjects.table.columns.projects.${translationSuffix}`,
            ),
            dataKey: 'projects',
            className: 'listCodeProjects-table-columns__projects',
          },
          {
            label: t('listProjects.table.columns.latest'),
            dataKey: 'latest',
            className: 'listCodeProjects-table-columns__latest',
          },
          {
            label: t('listProjects.table.columns.actions'),
            dataKey: 'actions',
            className: 'listCodeProjects-table-columns__actions',
          },
        ]}
        emptyStateHeader={
          infiniteQueryDetails.isFetching
            ? ''
            : t(`listProjects.table.emptyState.header.${translationSuffix}`)
        }
        emptyStateMessage=""
        data-testid="code-projects-table"
      />
      <CursorPagination
        currentPage={currentPage}
        pageSelect={selectPage}
        hasNextPage={
          infiniteQueryDetails.hasNextPage ||
          projectsByPage[currentPage + 1] !== undefined
        }
        hasPreviousPage={currentPage > 0}
      />
    </>
  );
};

export default ListCodeProjects;
