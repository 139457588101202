import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Form, Modal, Text } from '@puppet/react-components';
import { Cd4peApiError } from '@utils/api/cd4pe';
import Cd4peError from '@components/Cd4peError';

interface ConfirmDeleteModalProps {
  dataTestId?: string;
  isOpen: boolean;
  onCancel: Function;
  onDelete: Function;
  title: ReactNode;
  alert: ReactNode;
  description?: ReactNode;
  checkbox?: boolean;
  checkboxLabel?: ReactNode;
  confirmTextEntry?: string;
  textEntryAriaLabel?: string;
  confirmLabel?: ReactNode;
  cancelLabel?: ReactNode;
  error?: Cd4peApiError | null;
}

interface FormVals {
  checkbox: boolean;
  confirmTextEntry: string;
}

const defaultProps = {
  description: '',
  checkbox: false,
  checkboxLabel: '',
  confirmTextEntry: '',
  textEntryAriaLabel: '',
  confirmLabel: 'Delete',
  cancelLabel: 'Cancel',
  error: null,
  dataTestId: '',
};

const ConfirmDeleteModal = ({
  dataTestId,
  isOpen,
  onCancel,
  onDelete,
  title,
  alert,
  description,
  checkbox,
  checkboxLabel,
  confirmTextEntry,
  textEntryAriaLabel,
  confirmLabel,
  cancelLabel,
  error,
}: ConfirmDeleteModalProps) => {
  const { t } = useTranslation('codeDelivery');
  const [checked, setChecked] = useState(false);
  const [confirmEntryInput, setConfirmEntryInput] = useState('');

  const onCloseHandler = () => {
    setChecked(false);
    setConfirmEntryInput('');
    onCancel();
  };

  const onDeleteHandler = () => {
    setChecked(false);
    setConfirmEntryInput('');
    onDelete();
  };

  const onChangeHandler = (field: string, allValues: FormVals) => {
    switch (field) {
      case 'checkbox':
        setChecked(allValues[field]);
        break;
      case 'confirmTextEntry':
        setConfirmEntryInput(allValues[field]);
        break;
      default:
        break;
    }
  };

  const validConfirmation = () => {
    if (checkbox) {
      if (!checked) {
        return false;
      }
    }

    if (confirmTextEntry) {
      if (confirmEntryInput !== confirmTextEntry) {
        return false;
      }
    }

    return true;
  };

  return (
    <Modal
      className="confirm-delete-modal"
      isOpen={isOpen}
      onClose={() => onCloseHandler()}
    >
      <div data-testid="confirm-delete-modal">
        <Modal.Title>{title}</Modal.Title>
        <div className="confirm-delete-modal-description-alert">
          <Icon type="alert" className="icon" />
          {alert}
        </div>
        <Text className="confirm-delete-modal-description">{description}</Text>
        <Form
          data-testid={dataTestId}
          submittable
          cancellable
          submitDisabled={!validConfirmation()}
          submitType="danger"
          cancelType="tertiary"
          onCancel={onCloseHandler}
          onSubmit={onDeleteHandler}
          cancelLabel={cancelLabel ?? t('confirm.delete.modal.button.cancel')}
          submitLabel={confirmLabel ?? t('confirm.delete.modal.button.delete')}
          values={
            {
              checkbox: checked,
              confirmTextEntry: confirmEntryInput,
            } as FormVals
          }
          onChange={onChangeHandler}
        >
          {checkbox && (
            <div className="confirm-delete-modal-item">
              <Form.Field
                type="checkbox"
                name="checkbox"
                data-testid="delete-checkbox"
                label={<Text>{checkboxLabel}</Text>}
                value={checked}
              />
            </div>
          )}
          {confirmTextEntry && (
            <div className="confirm-delete-modal-item">
              <Form.Field
                aria-label={textEntryAriaLabel}
                type="text"
                name="confirmTextEntry"
                label=""
                data-testid="confirm-delete-text-entry"
                value={confirmEntryInput}
              />
            </div>
          )}
          <Cd4peError error={error} />
        </Form>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;

ConfirmDeleteModal.defaultProps = defaultProps;
