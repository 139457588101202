import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { GraphQLObjectType } from 'graphql';
import ChartCard from '@inventory/components/ChartCard';
import ChartModal from '@inventory/components/ChartModal';
import ConditionalRender from '@inventory/components/ConditionalRender';
import CardGrid from './CardGrid';
import CardBoardToolbar from './CardBoardToolbar';

import CardBoardEmptyCard from './CardBoardEmptyCard';

import './CardBoard.scss';

const CardBoard = ({
  workspaceId,
  submittedCards,
  onEditCard,
  onAdd,
  onDelete,
  loading,
}) => {
  const { t } = useTranslation('inventory');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [card, setCard] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const onModalClose = () => {
    setIsEditing(false);
    setCard(null);
    setIsModalOpen(false);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const chartModalMessages = {
    modalTitle: t('chart.modal.addTitle'),
    editModalTitle: t('chart.modal.editTitle'),
    chooseFactInfo: t('chart.modal.chooseFact.info'),
    chooseFactPlaceholder: t('chart.modal.chooseFact.placeholder'),
    save: t('chart.modal.save'),
    chartTitle: t('chart.modal.factForm.chartTitle'),
    chartOptions: t('chart.modal.factForm.chartOptions'),
    excludeCheckbox: t('chart.modal.factForm.excludeCheckbox'),
  };

  const onSubmit = (values) => {
    if (isEditing) {
      onEditCard(values);
    } else {
      onAdd(values);
    }
    onModalClose();
  };

  const onEdit = (values) => {
    onModalOpen();
    setCard(values);
    setIsEditing(true);
  };

  return (
    <div className="cb-container">
      <div
        className={classnames({
          'cb-container-overlay': loading,
        })}
      />
      <CardBoardToolbar onAddChartHandler={onModalOpen} />
      <CardGrid className="cb-grid-container">
        <ConditionalRender enable={submittedCards.length >= 1}>
          {submittedCards.map((element) => {
            return (
              <ChartCard
                key={element?.id}
                id={element?.id}
                workspaceId={workspaceId}
                queryName={element?.query}
                filter={element?.factname}
                label={element?.label}
                after={element?.after}
                responseKey={element?.responseKey}
                totalCountKey={element?.totalCountKey}
                hostCountKey={element?.hostCountKey}
                chartType={element?.chartType}
                chartOptions={element?.chartOptions}
                order={element?.order}
                areNodesExcluded={element?.areNodesExcluded}
                onDelete={onDelete}
                onEdit={() => onEdit(element)}
              />
            );
          })}
        </ConditionalRender>
        <ConditionalRender enable={submittedCards.length <= 0}>
          <CardBoardEmptyCard
            text={t('emptyGrid.card.button.text')}
            onClick={onModalOpen}
          />
        </ConditionalRender>
      </CardGrid>
      <ChartModal
        workspaceId={workspaceId}
        isOpen={isModalOpen}
        onClose={onModalClose}
        chartModalMessages={chartModalMessages}
        onSubmit={onSubmit}
        isEditing={isEditing}
        card={card}
      />
    </div>
  );
};

export default CardBoard;

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  submittedCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      queryName: PropTypes.instanceOf(GraphQLObjectType),
      chartType: PropTypes.string.isRequired,
      onEdit: PropTypes.func,
      onDelete: PropTypes.func,
      workspaceId: PropTypes.string,
      order: PropTypes.number.isRequired,
      after: PropTypes.func.isRequired,
      responseKey: PropTypes.string,
      totalCountKey: PropTypes.string,
      hostCountKey: PropTypes.string,
      filter: PropTypes.string,
      chartOptions: PropTypes.shape({
        grid: PropTypes.shape({
          enabled: PropTypes.bool,
        }),
      }).isRequired,
      areNodesExcluded: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onEditCard: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

CardBoard.defaultProps = {
  loading: false,
};

CardBoard.propTypes = propTypes;
