import React from 'react';
import classNames from 'classnames';

interface Props
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  size?: 'small' | 'medium' | 'large';
}

const CommonFormSectionSidebar = ({
  children,
  className,
  size,
  ...props
}: Props) => {
  return (
    <div
      className={classNames(
        `common-form-section__column`,
        `common-form-section__column--${size}`,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

CommonFormSectionSidebar.defaultProps = {
  className: '',
  size: 'small',
} as Partial<Props>;

export default CommonFormSectionSidebar;
