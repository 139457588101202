import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Form,
  Link,
  TooltipHoverArea,
  Button,
  Heading,
  Text,
} from '@puppet/react-components';
import docsLinks from '@codeDelivery/utils/docsLinks';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import {
  useCreateBuildStatusPrefixV1,
  useDeleteBuildStatusPrefixV1,
  useGetBuildStatusPrefixV1,
} from '@services/cd4pe/vcs';
import Cd4peError from '@components/Cd4peError';

const StatusNotificationPrefix = () => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const { isLoading, error, data } = useGetBuildStatusPrefixV1({ workspaceId });
  const [prefixValue, setPrefixValue] = useState<string>('');
  const [buttonsVisible, setButtonsVisible] = useState<boolean>(false);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);

  const persistPrefix = useCreateBuildStatusPrefixV1();
  const deletePrefix = useDeleteBuildStatusPrefixV1();

  useEffect(() => {
    if (data) {
      setPrefixValue(data.name);
    }

    if (!isLoading && error) {
      setPrefixValue('');
    }
  }, [isLoading, error, data]);

  const updatePrefixAndButtonState = (newValue: string) => {
    setPrefixValue(newValue);
    setButtonsVisible(false);
    setDeleteMode(false);
  };

  const onEdit = () => {
    setButtonsVisible(true);
  };

  const onDelete = () => {
    setButtonsVisible(true);
    setDeleteMode(true);
  };

  const onCancel = () => {
    updatePrefixAndButtonState(data?.name || '');
    persistPrefix.reset();
    deletePrefix.reset();
  };

  const savePrefix = (formData: { prefixValue: string }) => {
    if (deleteMode) {
      deletePrefix.mutate(
        { workspaceId },
        {
          onSuccess: () => {
            updatePrefixAndButtonState('');
          },
        },
      );
    } else {
      persistPrefix.mutate(
        {
          requestBody: {
            workspaceId,
            name: formData.prefixValue,
          },
        },
        {
          onSuccess: () => {
            updatePrefixAndButtonState(formData.prefixValue);
          },
        },
      );
    }
  };

  return (
    <div className="prefix">
      <div className="prefix-main">
        <Heading as="h4">
          {t('sourceControl.statusNotification.header')}
        </Heading>
        <Text>{t('sourceControl.statusNotification.mainText')}</Text>
        <div>
          <Text className="prefix-main-example-label" color="medium">
            {t('sourceControl.statusNotification.field.example')}
          </Text>
          <div className="prefix-main-example-text">
            <Text size="small">
              {prefixValue === '' ||
              prefixValue ===
                t('sourceControl.statusNotification.prefixPlaceholder')
                ? t('sourceControl.statusNotification.emptyPrefix')
                : prefixValue}
            </Text>
            <Text size="small">
              {t('sourceControl.statusNotification.exampleText1')}
            </Text>
            <Text size="small" color="medium">
              {t('sourceControl.statusNotification.exampleText2')}
            </Text>
          </div>
        </div>
        <div className="prefix-main-form">
          <Form
            submittable={buttonsVisible}
            cancellable={buttonsVisible}
            initialValues={{ prefixValue }}
            onSubmit={(formData: { prefixValue: string }) =>
              savePrefix(formData)
            }
            onCancel={() => onCancel()}
            onChange={(
              e: string | number,
              formValues: { [x: string]: string },
            ) => setPrefixValue(formValues[e])}
            submitType={deleteMode ? 'danger' : 'primary'}
            submitLabel={
              deleteMode
                ? t('sourceControl.statusNotification.button.delete')
                : t('sourceControl.statusNotification.button.save')
            }
            cancelLabel={t('sourceControl.statusNotification.button.cancel')}
          >
            <div className="prefix-main-form-field">
              <Form.Field
                type="text"
                name="prefixValue"
                label={t('sourceControl.statusNotification.field.prefix')}
                placeholder={t(
                  'sourceControl.statusNotification.prefixPlaceholder',
                )}
                className="prefix-input"
                disabled={!buttonsVisible}
              />
              <div className="prefix-main-form-field-buttons">
                <TooltipHoverArea
                  tooltip={t(
                    'sourceControl.statusNotification.tooltip.editPrefix',
                  )}
                >
                  <Button
                    icon="pencil"
                    aria-label={t(
                      'sourceControl.statusNotification.tooltip.editPrefix',
                    )}
                    type="transparent"
                    onClick={() => onEdit()}
                    disabled={buttonsVisible}
                    loading={isLoading}
                    name="editPrefix"
                    data-testid="editPrefix"
                  />
                </TooltipHoverArea>
                <TooltipHoverArea
                  tooltip={t(
                    'sourceControl.statusNotification.tooltip.deletePrefix',
                  )}
                >
                  <Button
                    aria-label={t(
                      'sourceControl.statusNotification.tooltip.deletePrefix',
                    )}
                    icon="trash"
                    type="transparent"
                    onClick={() => onDelete()}
                    disabled={prefixValue === '' || !data}
                    loading={isLoading}
                    name="deletePrefix"
                    data-testid="deletePrefix"
                  />
                </TooltipHoverArea>
              </div>
            </div>
            {deleteMode && (
              <Text className="prefix-delete-question">
                {t('sourceControl.statusNotification.deleteQuestion')}
              </Text>
            )}
          </Form>
          {/* 404 is expected if the status prefix has not been set previously */}
          {error?.status !== 404 && <Cd4peError error={error} />}
          <Cd4peError error={persistPrefix.error} />
          <Cd4peError error={deletePrefix.error} />
        </div>
      </div>
      <div className="prefix-sidebar">
        <Icon type="info-circle" size="large" className="prefix-sidebar-icon" />
        <div className="prefix-sidebar-text">
          <Heading as="h4">
            {t('sourceControl.statusNotification.needPrefix')}
          </Heading>
          <Text color="medium">
            {t('sourceControl.statusNotification.learnMorePrefix1')}
            <Link as="a" href={docsLinks().learnAboutPrefix} target="_blank">
              {t('sourceControl.statusNotification.learnMorePrefix2')}
            </Link>
          </Text>
        </div>
      </div>
    </div>
  );
};
export default StatusNotificationPrefix;
