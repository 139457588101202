import { getAuthCookie } from '@utils/api/helpers';
import { OpenAPI } from '@puppet/cd4pe-client-ts';
import { useGetUserV1 } from '@services/cd4pe/users';

const useAuth = () => {
  const authCookie = getAuthCookie();

  if (authCookie) {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      authorization: authCookie,
      Accept: '*/*',
    };
  }

  const { isLoading, isError, data } = useGetUserV1();

  return {
    user: data?.user,
    workspaces: data?.workspaces,
    isLoading,
    isError,
  };
};

export default useAuth;
