import SingleSelectFilter from 'inventory/components/NodeTableFilter/SingleSelectFilter';
import peMasterServerQuery from 'inventory/services/graphql/queries/queryPeMasterServer.gql';

export default {
  component: SingleSelectFilter,
  placeholder: 'nodeTable.filter.peMasterServer.input.placeholder',
  label: 'nodeTable.filter.peMasterServer.input.label',
  query: peMasterServerQuery,
  options: [],
  after: (data, peMasterServer) => {
    const results = data?.data?.puppetEnterprise?.puppetEnterprise;
    return {
      ...peMasterServer,
      // set the options and filterValue metadata based on the query results
      options: results.map(({ name }) => ({
        value: name,
        label: name,
      })),
      filterValue: results.reduce(
        (result, { name, puppetServerEndpoint }) => ({
          ...result,
          [name]: {
            source: puppetServerEndpoint.substring(
              0,
              puppetServerEndpoint.indexOf(':'),
            ),
          },
        }),
        {},
      ),
    };
  },
};
