import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { Button, Text } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';

type Props = {
  icon: ReactElement;
  message: string;
  defaultOpen?: boolean;
  'data-testid'?: string;
};

const BannerDropdown = ({
  icon,
  message,
  children,
  defaultOpen = false,
  'data-testid': dataTestId = '',
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation('codeDelivery');
  const [expanded, setExpanded] = useState(defaultOpen);

  return (
    <>
      <div className="banner-dropdown__container" data-testid={dataTestId}>
        <div className="banner-dropdown__message">
          <div
            className="banner-dropdown__icon"
            data-testid="banner-dropdown-icon"
          >
            {icon}
          </div>
          <Text size="small" data-testid="banner-dropdown-message">
            <strong>{message}</strong>
          </Text>
        </div>
        <Button
          type="text"
          trailingIcon={expanded ? 'chevron-up' : 'chevron-down'}
          onClick={() => setExpanded((v) => !v)}
          data-testid="banner-dropdown-toggle-details-button"
        >
          {expanded
            ? t('bannerDropdown.button.hideDetails')
            : t('bannerDropdown.button.showDetails')}
        </Button>
      </div>
      {expanded && children}
    </>
  );
};

export default BannerDropdown;
