import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loading } from '@puppet/react-components';
import { Authentication } from '@puppet/react-layouts';
import Cd4peError from '@components/Cd4peError';
import { useGetAuthMethod, useLogin, useRootLogin } from '@services/cd4pe/auth';
import CopyrightFooter from '@components/CopyrightFooter';
import RootForm from './Forms/RootForm';
import StandardForm from './Forms/StandardForm';
import LdapForm from './Forms/LdapForm';
import SamlForm from './Forms/SamlForm';

const Login = () => {
  const { search } = useLocation();
  const { t } = useTranslation('codeDelivery');
  const { data: auth, error, isLoading } = useGetAuthMethod(undefined);
  const login = useLogin();
  const rootLogin = useRootLogin();
  const [loginMethod, setLoginMethod] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const success = login.data?.success || rootLogin?.data?.success;
    const redirectURL =
      params.get('url') ||
      login?.data?.redirectTo ||
      rootLogin?.data?.redirectTo;

    if (success && redirectURL) {
      navigate(redirectURL);
    }
  }, [search, login.data, rootLogin?.data, navigate]);

  useEffect(() => {
    if (auth?.method) {
      setLoginMethod(auth.method);
      return;
    }

    if (error) {
      setLoginMethod('STANDARD');
    }
  }, [auth?.method, error]);

  const handleChangeMethod = (e: Event) => {
    e.preventDefault();
    setLoginMethod(auth?.method ?? 'STANDARD');
  };

  const handleChangeToRoot = (e: Event) => {
    e.preventDefault();
    setLoginMethod('ROOT');
  };

  return (
    <Authentication
      className="login"
      title={t('login.header')}
      product="continuous-delivery"
      footer={<CopyrightFooter />}
    >
      {isLoading && <Loading data-testid="login-loading-spinner" />}
      {loginMethod === 'STANDARD' && (
        <StandardForm
          onSubmit={login.mutate}
          submitting={login.isLoading}
          isError={login.isError}
        />
      )}
      {loginMethod === 'LDAP' && (
        <LdapForm
          onSubmit={login.mutate}
          submitting={login.isLoading}
          isError={login.isError}
          onChangeMethod={handleChangeToRoot}
        />
      )}
      {loginMethod === 'SAML' && (
        <SamlForm
          ssoUrl={auth?.ssoUrl ?? ''}
          onChangeMethod={handleChangeToRoot}
        />
      )}
      {loginMethod === 'ROOT' && (
        <RootForm
          onSubmit={rootLogin.mutate}
          submitting={rootLogin.isLoading}
          isError={rootLogin.isError}
          onChangeMethod={handleChangeMethod}
          method={auth?.method ?? 'STANDARD'}
        />
      )}
      <Cd4peError error={error} />
    </Authentication>
  );
};

export default Login;
