import React from 'react';
import Breadcrumbs from '@components/Breadcrumbs';
import { Heading, Button } from '@puppet/react-components';
import PageLayout from '@components/PageLayout';
import { BreadcrumbsType } from '@components/Breadcrumbs/Breadcrumbs';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LINKS } from 'src/routes';
import useListControlReposV1, {
  useDeleteControlRepoV1,
} from '@services/cd4pe/controlRepos';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import ListCodeProjects from '@codeDelivery/components/ListCodeProjects';

const ListControlRepos = () => {
  const { t } = useTranslation('codeDelivery');
  const navigate = useNavigate();
  const workspaceName = useWorkspaceName();
  const [prefix, setPrefix] = React.useState('');

  const workspaceId = useWorkspaceDomain();
  const controlRepos = useListControlReposV1({
    workspaceId,
    prefix,
  });
  const deleteControlRepo = useDeleteControlRepoV1();

  const breadcrumbs: BreadcrumbsType = [
    {
      displayName: workspaceName,
    },
    {
      displayName: t('listControlRepos.breadcrumb.controlRepos'),
    },
  ];

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </PageLayout.Breadcrumbs>
        <div className="listControlRepos__header">
          <Heading>{t('listControlRepos.header.title')}</Heading>
          <Button
            data-testid="list-control-repos-add-new-button"
            onClick={() =>
              navigate(
                LINKS.codeDelivery.newRepository({
                  path: { workspace: workspaceName },
                }),
              )
            }
          >
            {t('listControlRepos.header.newControlRepo')}
          </Button>
        </div>
      </PageLayout.Header>
      <PageLayout.Content>
        <ListCodeProjects
          translationSuffix="controlRepo"
          projectsByPage={
            controlRepos.data?.pages?.map((page) => page.controlRepos) ?? []
          }
          infiniteQueryDetails={controlRepos}
          getProjectLink={(name) =>
            LINKS.codeDelivery.viewRepository({
              path: { workspace: workspaceName, name },
            })
          }
          onDelete={(name, onSuccess: () => void) => {
            deleteControlRepo.mutate(
              {
                workspaceId,
                controlRepoName: name,
              },
              {
                onSuccess,
              },
            );
          }}
          deleteError={deleteControlRepo.error}
          prefix={prefix}
          setPrefix={setPrefix}
        />
      </PageLayout.Content>
    </PageLayout>
  );
};

export default ListControlRepos;
