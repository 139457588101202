import React from 'react';

interface Props
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {}

const DefinitionsEntryTitle = ({ children, className, ...props }: Props) => {
  return (
    <dt className={`definitions-definition__title ${className}`} {...props}>
      {children}
    </dt>
  );
};

DefinitionsEntryTitle.defaultProps = {
  className: '',
} as Partial<Props>;

export default DefinitionsEntryTitle;
