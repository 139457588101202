import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Link as PdsLink, Text } from '@puppet/react-components';
import { LINKS } from 'src/routes';
import Form from './Form';

type Props = {
  submitting: boolean;
  onSubmit: Function;
  isError: boolean;
};

const StandardForm = ({ onSubmit, submitting, isError }: Props) => {
  const { t } = useTranslation('codeDelivery');

  return (
    <>
      <Form
        type="standard"
        onSubmit={onSubmit}
        submitting={submitting}
        isError={isError}
      />
      <div className="standard-form__links">
        <PdsLink as={Link} to={LINKS.forgotPassword}>
          {t('login.form.standard.links.forgotPassword')}
        </PdsLink>
        <div className="standard-form__links-create-account">
          <Trans
            t={t}
            i18nKey="login.form.standard.links.createAccount"
            components={[
              <Text color="medium" />,
              <PdsLink as={Link} to={LINKS.signup} />,
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default StandardForm;
