/* eslint-disable no-nested-ternary */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { OptionsArray } from 'utils/en.propTypes';
import './NodeTableFilter.scss';
import { Modal, Select, Heading } from '@puppet/react-components';
import ActiveFilterList from 'inventory/components/ActiveFilterList';
import { useTranslation } from 'react-i18next';

const NodeTableFilter = ({
  availableFilters,
  filters,
  selectedFilter,
  onSelectedFilterChange,
  filterDisplayMessages,
  currentFilterList,
  onAdd,
  onApply,
  onApplyAll,
  onRemove,
  onClearAll,
  onCancel,
  isModalOpen,
  onModalClose,
  onFormChange,
  compoundFiltering,
}) => {
  const { t } = useTranslation('inventory');
  const [isEditing, setIsEditing] = useState(true);
  const FilterComponent = filters[selectedFilter]?.component;
  const {
    label,
    options,
    placeholder,
    name,
    defaultOptions,
    filterValue,
    operator,
    textInput,
    autoComplete,
  } = filters[selectedFilter] || '';
  const addFilter = (filter) => {
    if (compoundFiltering) {
      onAdd(filter);
      onApply();
    } else {
      setIsEditing(false);
      onAdd(filter);
    }
  };

  return (
    <div>
      <Modal onClose={onModalClose} className="cff-modal" isOpen={isModalOpen}>
        <Modal.Title>
          {' '}
          {isEditing
            ? filterDisplayMessages.modalTitle
            : filterDisplayMessages.activeTitle}
        </Modal.Title>
        {isEditing ? (
          <>
            <Heading className="ntf-select-heading" label color="medium">
              {filterDisplayMessages.selectLabel}
            </Heading>
            <Select
              type="select"
              name="Filter Select"
              className="ntf-filter-field"
              placeholder={filterDisplayMessages.selectPlaceholderText}
              value={selectedFilter}
              onChange={onSelectedFilterChange}
              options={availableFilters}
              data-testid="filter-select"
            />
            {FilterComponent && (
              <div className="ntf-filter-component-container">
                <FilterComponent
                  filterType={selectedFilter}
                  onCancel={onCancel}
                  onSubmit={addFilter}
                  submitLabel={t('nodeTable.filter.add.button')}
                  submitDisabled={selectedFilter}
                  actionsPosition="left"
                  submitType="secondary"
                  cancelType="text"
                  label={label}
                  filterValue={filterValue}
                  options={options}
                  name={name}
                  defaultOptions={defaultOptions}
                  placeholder={placeholder}
                  onFormChange={onFormChange}
                  operator={operator}
                  textInput={textInput}
                  autoComplete={autoComplete}
                />
              </div>
            )}
          </>
        ) : (
          <div className="ntf-active-filter-container">
            <ActiveFilterList
              currentFilterList={currentFilterList}
              onApplyAll={onApplyAll}
              onClearAll={onClearAll}
              onRemove={onRemove}
              filterDisplayMessages={filterDisplayMessages}
              isEditing={setIsEditing}
              onCancel={onCancel}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default NodeTableFilter;

NodeTableFilter.propTypes = {
  availableFilters: OptionsArray.isRequired,
  selectedFilter: PropTypes.string,
  onSelectedFilterChange: PropTypes.func.isRequired,
  filterDisplayMessages: PropTypes.shape({
    buttonLabel: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
    selectLabel: PropTypes.string.isRequired,
    selectPlaceholderText: PropTypes.string.isRequired,
    activeTitle: PropTypes.string.isRequired,
    addFilterButton: PropTypes.string.isRequired,
    clearAllButton: PropTypes.string.isRequired,
    applyAllButton: PropTypes.string.isRequired,
    clearFiltersButton: PropTypes.string.isRequired,
    activeFiltersButton: PropTypes.string.isRequired,
    cancelButton: PropTypes.string.isRequired,
    emptyList: PropTypes.string.isRequired,
  }).isRequired,
  currentFilterList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      fact: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.string,
      filter: PropTypes.shape({}).isRequired,
    }),
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onApplyAll: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  onFormChange: PropTypes.func.isRequired,
  compoundFiltering: PropTypes.bool.isRequired,
};

NodeTableFilter.defaultProps = {
  selectedFilter: '',
};
