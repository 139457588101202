import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonForm from '@components/CommonForm';
import { Text, Alert } from '@puppet/react-components';
import { useNavigate } from 'react-router-dom';
import useWorkspaceName from '@hooks/useWorkspaceName';
import { LINKS } from 'src/routes';

const AddSourceControlBlankForm = ({ error }: { error?: boolean }) => {
  const { t } = useTranslation('codeDelivery');
  const navigate = useNavigate();
  const workspace = useWorkspaceName();
  const navigateToSettings = () =>
    navigate(LINKS.settings.listSourceControl({ path: { workspace } }));

  return (
    <CommonForm
      submittable
      cancellable
      className="add-source-control-form"
      submitLabel={t('addSourceControl.form.submitButton')}
      values={{}}
      onCancel={navigateToSettings}
      submitDisabled
    >
      <CommonForm.Section>
        <CommonForm.Section.Main>
          <div className="add-source-control-form__empty">
            <h3 className="add-source-control-form-section__header">
              {t('addSourceControl.credentials.header')}
            </h3>
            {error ? (
              <Alert type="danger">
                <Alert.Error error={t('addSourceControl.credentials.error')} />
              </Alert>
            ) : (
              <Text>{t('addSourceControl.credentials.body')}</Text>
            )}
          </div>
        </CommonForm.Section.Main>
      </CommonForm.Section>
    </CommonForm>
  );
};

export default AddSourceControlBlankForm;

AddSourceControlBlankForm.defaultProps = {
  error: false,
};
