/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  useDeletePipelineV1,
  useDeletePipelineStagesV1,
} from '@services/cd4pe/pipelines';
import { Checkbox, Modal, Button, Icon, Text } from '@puppet/react-components';
import { useTranslation } from 'react-i18next';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { useAppDispatch } from '@hooks/redux';
import alerts from '@state/ui/alerts';
import { ProjectPipelineStageV1 } from '@puppet/cd4pe-client-ts';
import Cd4peError from '@components/Cd4peError';

interface Props {
  isOpen: boolean;
  onToggleOpen: (open: boolean) => void;
  codeProjectName: string;
  pipelineId: string;
  availablePipelines: { name?: string; pipelineId?: string }[] | undefined;
  stages: ProjectPipelineStageV1[] | undefined;
  onDeletePipeline?: () => void;
  onDeleteStages?: () => void;
}

const DeletePipeline = ({
  isOpen,
  onToggleOpen,
  codeProjectName,
  pipelineId,
  availablePipelines = [],
  stages = [],
  onDeletePipeline = () => {},
  onDeleteStages = () => {},
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const appDispatch = useAppDispatch();
  const deletePipeline = useDeletePipelineV1();
  const deletePipelineStages = useDeletePipelineStagesV1();
  const [deleteCheck, setDeleteCheck] = useState(false);
  const onConfirm = () => {
    if (availablePipelines.length > 1) {
      deletePipeline.mutate(
        {
          workspaceId,
          pipelineId,
          projectName: codeProjectName,
        },
        {
          onSuccess: () => {
            onDeletePipeline();
            setDeleteCheck(false);
            onToggleOpen(!isOpen);
            appDispatch(
              alerts.actions.createAlert({
                type: 'Success',
                message: t('viewPipeline.pipeline.alerts.delete.success', {
                  name:
                    availablePipelines.find(
                      (pipeline) => pipeline.pipelineId === pipelineId,
                    )?.name ?? '',
                }),
              }),
            );
          },
        },
      );
    } else {
      deletePipelineStages.mutate(
        {
          workspaceId,
          pipelineId,
          projectName: codeProjectName,
        },
        {
          onSuccess: () => {
            onDeleteStages();
            setDeleteCheck(false);
            onToggleOpen(!isOpen);
            appDispatch(
              alerts.actions.createAlert({
                type: 'Success',
                message: t(
                  'viewPipeline.pipeline.alerts.delete.stages.success',
                  {
                    name:
                      availablePipelines.find(
                        (pipeline) => pipeline.pipelineId === pipelineId,
                      )?.name ?? '',
                  },
                ),
              }),
            );
          },
        },
      );
    }
  };

  return (
    <Modal
      className="delete-pipeline-modal"
      isOpen={isOpen}
      onClose={() => {
        setDeleteCheck(false);
        onToggleOpen(!isOpen);
      }}
    >
      <Modal.Title>{t('viewPipeline.delete.pipeline')}</Modal.Title>
      {availablePipelines.length <= 1 && stages.length > 0 ? (
        <Text>{t('viewPipeline.delete.last.pipeline.warning')}</Text>
      ) : availablePipelines.length <= 1 && stages?.length <= 0 ? (
        <Text>
          {t('viewPipeline.delete.last.pipeline.no.stages.warning', {
            name: availablePipelines.map((pipeline) => {
              return pipeline.pipelineId === pipelineId ? pipeline.name : '';
            }),
          })}
        </Text>
      ) : (
        <div className="delete-pipeline-modal__alert">
          <Text className="delete-pipeline-modal__alert-icon">
            <Icon type="alert" />
            {t('viewPipeline.delete.pipeline.confirmation.checkbox')}
          </Text>
          <Checkbox
            name="delete-pipeline-checkbox"
            data-testid="delete-pipeline-checkbox"
            size="medium"
            label={
              <Text>
                {t('viewPipeline.delete.pipeline.confirmation.name', {
                  name:
                    availablePipelines.find(
                      (pipeline) => pipeline.pipelineId === pipelineId,
                    )?.name ?? '',
                })}
              </Text>
            }
            value={deleteCheck}
            onChange={() => setDeleteCheck(!deleteCheck)}
          />
        </div>
      )}
      <Cd4peError error={deletePipeline.error || deletePipelineStages.error} />
      <Modal.Actions>
        {availablePipelines.length > 1 || stages.length > 0 ? (
          <>
            <Button
              type="danger"
              onClick={() => onConfirm()}
              loading={
                deletePipeline.isLoading || deletePipelineStages.isLoading
              }
              disabled={availablePipelines.length > 1 && !deleteCheck}
            >
              {t('viewPipeline.delete.pipeline.confirmation.button.confirm')}
            </Button>
            <Button
              type="danger"
              weight="subtle"
              onClick={() => {
                setDeleteCheck(false);
                onToggleOpen(!isOpen);
              }}
              disabled={
                deletePipeline.isLoading || deletePipelineStages.isLoading
              }
            >
              {t('viewPipeline.delete.pipeline.confirmation.button.cancel')}
            </Button>
          </>
        ) : (
          <Button
            type="secondary"
            onClick={() => {
              setDeleteCheck(false);
              onToggleOpen(!isOpen);
            }}
          >
            {t('viewPipeline.delete.pipeline.confirmation.button.done')}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default DeletePipeline;
