/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  Form,
  Icon,
  Link as PdsLink,
  Text,
} from '@puppet/react-components';
import Cd4peError from '@components/Cd4peError';
import { useListBranchesV1, useListCommitsV1 } from '@services/cd4pe/vcs';
import {
  useListEnvironmentPrefixesV2,
  useListEnvironmentsV2,
  useListPeIntegrationsV2,
} from '@services/cd4pe/peIntegrations';
import { useGetDeploymentPoliciesV1 } from '@services/cd4pe/deployments';
import {
  DeploymentPolicyV1,
  PEIntegrationEnvironmentV2,
  PEIntegrationV2,
} from '@utils/api/cd4pe';
import useWorkspaceDomain from '@hooks/useWorkspaceDomain';
import { CodeProjectDetailsV1, ProjectTypeV1 } from '@puppet/cd4pe-client-ts';
import useListControlReposV1 from '@services/cd4pe/controlRepos';
import {
  DEPLOYMENT_POLICY_TYPES,
  OPTIONAL_PARAMETER_TYPES,
  PARAMETER_TYPES,
} from './utils';
import {
  PeIntegrationsOptionsLabel,
  formatBranchesForSelect,
  formatCommitsForSelect,
  formatEnvironmentsForSelect,
  formatControlReposForSelect,
} from '../../formHelpers';

const defaultProps = {
  submittable: true,
  cancellable: true,
};

interface Props {
  type: 'manual' | 'stage' | 'edit';
  initialBranch?: string;
  codeProject: CodeProjectDetailsV1;
  onSubmit: (deployment: Deployment) => void;
  onCancel: () => void;
  disabled?: boolean;
  submittable?: boolean;
  cancellable?: boolean;
  submitting: boolean;
  initialState?: DeploymentFormValues;
  submitKey?: string;
  cancelKey?: string;
  projectType: ProjectTypeV1;
}

export type Deployment = {
  branch: string;
  commit: string;
  puppetEnterpriseServer: PEIntegrationV2 | undefined;
  nodeGroup: PEIntegrationEnvironmentV2 | undefined;
  deploymentPolicy: DeploymentPolicyV1 | undefined;
  timeout?: number;
  description?: string;
  prefix?: string;
  controlRepoName?: string;
  controlRepoBaseFeatureBranch?: string;
};

export type DeploymentFormValues = {
  branch: string;
  commit: string;
  puppetEnterpriseServer: string;
  nodeGroup: string;
  deploymentPolicy: string;
  deploymentPolicyParameters: { [key: string]: any };
  timeout?: number;
  description?: string;
  prefix?: string;
  controlRepo: string;
  controlRepoBranch: string;
};

type GroupedOptions = {
  label: string;
  value: { value: string; label: string }[];
}[];

const getDeploymentParameterField = ({
  policy,
  name,
  type,
  value,
}: {
  policy: string;
  name: string;
  type: string;
  value?: boolean | number | string | undefined;
}) => {
  const props = {
    name,
    label: name,
    value,
    path: `deploymentPolicyParameters.${policy}.${name}`,
    'data-testid': `parameter-${name}`,
  };

  if (type && type.match(/.*Sensitive.*/)) {
    return <Form.Field {...props} type="password" placeholder={type} />;
  }

  switch (type) {
    case PARAMETER_TYPES.BOOLEAN:
    case OPTIONAL_PARAMETER_TYPES.BOOLEAN:
      return <Form.Field type="checkbox" {...props} />;
    case PARAMETER_TYPES.INTEGER:
    case PARAMETER_TYPES.FLOAT:
    case PARAMETER_TYPES.NUMERIC:
      return (
        <Form.Field
          {...props}
          placeholder={type}
          type="number"
          min={0}
          required
        />
      );
    case OPTIONAL_PARAMETER_TYPES.INTEGER:
    case OPTIONAL_PARAMETER_TYPES.FLOAT:
    case OPTIONAL_PARAMETER_TYPES.NUMERIC:
      return <Form.Field {...props} placeholder={type} type="number" min={0} />;
    default:
      return <Form.Field {...props} />;
  }
};

const DeploymentForm = ({
  type,
  initialBranch = '',
  codeProject,
  onSubmit,
  onCancel,
  disabled,
  submittable,
  cancellable,
  submitting,
  initialState,
  submitKey,
  cancelKey,
  projectType,
}: Props) => {
  const { t } = useTranslation('codeDelivery');
  const workspaceId = useWorkspaceDomain();
  const [infiniteDeploymentError, setInfiniteDeploymentError] = useState(false);

  const [values, setValues] = useState<DeploymentFormValues>(
    initialState || {
      branch: initialBranch,
      commit: '',
      puppetEnterpriseServer: '',
      nodeGroup: '',
      deploymentPolicy: '',
      deploymentPolicyParameters: {},
      timeout: 60,
      prefix: '',
      controlRepo: '',
      controlRepoBranch: '',
    },
  );

  const isRegexPipeline = values.branch === 'regex';

  const branches = useListBranchesV1(
    {
      workspaceId,
      provider: codeProject.srcRepoProvider,
      name: codeProject.srcRepoName,
      project: codeProject.srcRepoId,
      organization: codeProject.srcRepoOwner,
    },
    {
      enabled: type === 'manual',
    },
  );

  const commits = useListCommitsV1(
    {
      workspaceId,
      provider: codeProject.srcRepoProvider,
      branch: values.branch,
      name: codeProject.srcRepoName,
      project: codeProject.srcRepoId,
      organization: codeProject.srcRepoOwner,
    },
    {
      enabled: type === 'manual' && values.branch !== '',
    },
  );

  const peIntegrations = useListPeIntegrationsV2({
    workspaceId,
  });

  const peServer = peIntegrations.data?.peIntegrations?.find(
    (peIntegration) => peIntegration.name === values.puppetEnterpriseServer,
  );

  const nodeGroups = useListEnvironmentsV2(
    {
      peIntegrationId: peServer?.id!,
    },
    {
      enabled: !!peServer,
    },
  );

  const prefixes = useListEnvironmentPrefixesV2(
    {
      peIntegrationId: peServer?.id!,
    },
    {
      enabled: !!peServer,
    },
  );

  const deploymentPolicies = useGetDeploymentPoliciesV1(
    {
      workspaceId,
      projectName: codeProject.name,
      projectType,
      isRegexPipeline,
    },
    {
      enabled: values.branch !== '',
    },
  );

  const branchOptions = formatBranchesForSelect(branches);

  const commitOptions = formatCommitsForSelect(
    commits,
    codeProject,
    values.branch,
  );

  const controlRepos = useListControlReposV1(
    {
      workspaceId,
      prefix: '',
    },
    {
      enabled: projectType === 'MODULE' && isRegexPipeline,
    },
  );
  const controlRepoOptions = formatControlReposForSelect(controlRepos);

  const controlRepoMatch = controlRepos.data?.pages[0]?.controlRepos?.find(
    (repo) => repo.name === values.controlRepo,
  );

  const controlRepoBranches = useListBranchesV1(
    {
      workspaceId,
      provider: controlRepoMatch?.srcRepoProvider!,
      name: values.controlRepo,
      project: controlRepoMatch?.srcRepoId!,
      organization: controlRepoMatch?.srcRepoOwner!,
    },
    {
      enabled: isRegexPipeline && !!controlRepoMatch,
    },
  );

  const controlRepoBranchOptions = formatBranchesForSelect(controlRepoBranches);

  const peInstanceOptions = peIntegrations?.data?.peIntegrations?.map(
    (peIntegration) => ({
      value: peIntegration.name,
      label: <PeIntegrationsOptionsLabel peIntegration={peIntegration} />,
    }),
  );

  const nodeGroupOptions = formatEnvironmentsForSelect(nodeGroups, codeProject);

  const deploymentPolicyOptions =
    deploymentPolicies.data?.deploymentPolicies?.reduce(
      (accumulator: GroupedOptions, policy: DeploymentPolicyV1) => {
        const item = {
          value: policy.name ?? '',
          label: policy.displayName ?? '',
        };
        accumulator[policy.custom ? 1 : 0].value.push(item);
        return accumulator;
      },
      [
        {
          label: t(
            'viewPipeline.form.deployment.field.deploymentPolicy.builtin',
          ),
          value: [],
        },
        {
          label: t(
            'viewPipeline.form.deployment.field.deploymentPolicy.custom',
          ),
          value: [],
        },
      ],
    );

  const prefixOptions =
    prefixes.data?.prefixes.map((prefix) => ({
      value: prefix,
      label: prefix,
    })) ?? [];

  const selectedDeploymentPolicy =
    deploymentPolicies.data?.deploymentPolicies?.find(
      (policy) => policy.name === values.deploymentPolicy,
    ) ?? {};

  const parameterComponents = deploymentPolicies.data?.deploymentPolicies
    ?.find((policy) => policy.name === values.deploymentPolicy)
    ?.parameters?.map((parameter) => {
      if (!parameter || !parameter.name || !parameter.type) {
        return null;
      }

      return getDeploymentParameterField({
        policy: values.deploymentPolicy,
        name: parameter.name,
        type: parameter.type,
        value: parameter.value,
      });
    });

  const onChangeHandler = (
    nameChanged: keyof DeploymentFormValues,
    newValues: DeploymentFormValues,
  ) => {
    setInfiniteDeploymentError(false);
    const newValuesCopy = { ...newValues };

    if (nameChanged === 'branch') {
      newValuesCopy.commit = '';
    }

    if (nameChanged === 'puppetEnterpriseServer') {
      newValuesCopy.nodeGroup = '';
      newValuesCopy.prefix = '';
      newValuesCopy.deploymentPolicy = '';
      newValuesCopy.controlRepo = '';
    }

    if (
      nameChanged === 'deploymentPolicy' &&
      !newValuesCopy.deploymentPolicyParameters[newValuesCopy[nameChanged]]
    ) {
      const deploymentPolicyParameters =
        deploymentPolicies.data?.deploymentPolicies?.find(
          (policy) => policy.name === newValuesCopy.deploymentPolicy,
        )?.parameters ?? [];

      const entries = deploymentPolicyParameters?.map((parameter) => [
        parameter.name,
        parameter.value,
      ]);

      newValuesCopy.deploymentPolicyParameters[newValuesCopy[nameChanged]] =
        Object.fromEntries(entries);

      setValues(newValuesCopy);
      return;
    }

    if (nameChanged === 'controlRepo') {
      newValuesCopy.controlRepoBranch = '';
    }

    setValues(newValuesCopy);
  };

  const onSubmitHandler = (submitValues: DeploymentFormValues) => {
    const nodeGroup = nodeGroups?.data?.environments?.find(
      (group) => group.id === submitValues.nodeGroup,
    );

    if (nodeGroup?.environment === values.branch) {
      setInfiniteDeploymentError(true);
      return;
    }

    const puppetEnterpriseServer = peIntegrations.data?.peIntegrations?.find(
      (peIntegration) =>
        peIntegration.name === submitValues.puppetEnterpriseServer,
    );

    let deploymentPolicy = selectedDeploymentPolicy;

    const updatedParameters =
      deploymentPolicy.parameters?.map((p) => ({
        ...p,
        value:
          submitValues.deploymentPolicyParameters[
            submitValues.deploymentPolicy
          ][p.name!],
      })) ?? [];

    deploymentPolicy = {
      ...deploymentPolicy,
      parameters: updatedParameters,
    };

    onSubmit({
      branch: submitValues.branch,
      commit: submitValues.commit,
      puppetEnterpriseServer,
      nodeGroup,
      deploymentPolicy,
      timeout: submitValues.timeout,
      description: submitValues.description,
      prefix: submitValues.prefix,
      controlRepoName: submitValues.controlRepo,
      controlRepoBaseFeatureBranch: submitValues.controlRepoBranch,
    });
  };

  return (
    <>
      <Form
        className="deploy-form"
        data-testid="deploy-form"
        submittable={submittable}
        cancellable={cancellable}
        values={values}
        onChange={onChangeHandler}
        onSubmit={onSubmitHandler}
        onCancel={() => onCancel()}
        disabled={disabled}
        submitting={submitting}
        submitLabel={
          submitKey
            ? t(submitKey)
            : t('viewPipeline.form.deployment.buttons.submit.manual')
        }
        cancelLabel={
          cancelKey
            ? t(cancelKey)
            : t('viewPipeline.form.deployment.buttons.cancel')
        }
      >
        {type === 'manual' && (
          <div data-testid="select-branch" className="deploy-form__field">
            <Form.Field
              type="select"
              name="branch"
              label={t('viewPipeline.form.deployment.field.branch.label')}
              placeholder={
                branches.isLoading || (branches.isFetching && !branches.error)
                  ? t('viewPipeline.form.deployment.field.branch.loading')
                  : t('viewPipeline.form.deployment.field.branch.placeholder')
              }
              options={branchOptions}
              required
              disabled={branches.isLoading || branches.error}
            />
          </div>
        )}
        {type === 'manual' && values.branch && (
          <Form.Field
            type="select"
            name="commit"
            label={t('viewPipeline.form.deployment.field.commit.label')}
            placeholder={
              commits.isFetching
                ? t('viewPipeline.form.deployment.field.commit.loading')
                : t('viewPipeline.form.deployment.field.commit.placeholder')
            }
            options={commitOptions}
            required
            disabled={
              values.branch === '' ||
              commits.isLoading ||
              commits.isFetching ||
              commits.error
            }
          />
        )}
        <div data-testid="select-pe-server" className="deploy-form__field">
          <Form.Field
            type="select"
            name="puppetEnterpriseServer"
            label={t(
              'viewPipeline.form.deployment.field.puppetEnterpriseServer.label',
            )}
            placeholder={
              peIntegrations.isLoading ||
              (peIntegrations.isFetching && !peIntegrations.error)
                ? t(
                    'viewPipeline.form.deployment.field.puppetEnterpriseServer.loading',
                  )
                : t(
                    'viewPipeline.form.deployment.field.puppetEnterpriseServer.placeholder',
                  )
            }
            options={peInstanceOptions}
            required
            disabled={
              peIntegrations.isLoading ||
              peIntegrations.isFetching ||
              peIntegrations.error
            }
          />
        </div>
        {!isRegexPipeline && peServer && (
          <div data-testid="select-node-group" className="deploy-form__field">
            <Form.Field
              type="select"
              name="nodeGroup"
              label={t('viewPipeline.form.deployment.field.nodeGroup.label')}
              placeholder={
                nodeGroups.isFetching
                  ? t('viewPipeline.form.deployment.field.nodeGroup.loading')
                  : t(
                      'viewPipeline.form.deployment.field.nodeGroup.placeholder',
                    )
              }
              options={nodeGroupOptions}
              required
              disabled={
                values.puppetEnterpriseServer === '' ||
                nodeGroups.isLoading ||
                nodeGroups.isFetching ||
                nodeGroups.error
              }
            />
          </div>
        )}
        {values.branch && (
          <div
            data-testid="select-deployment-policy"
            className="deploy-form__field"
          >
            <Form.Field
              type="select"
              name="deploymentPolicy"
              label={
                <Trans
                  t={t}
                  i18nKey="viewPipeline.form.deployment.field.deploymentPolicy.label"
                >
                  <PdsLink
                    size="tiny"
                    as="a"
                    data-testid="deployment-policy-help"
                    href="https://puppet.com/docs/continuous-delivery/latest/deployment_policies.html"
                    target="_blank"
                  />
                </Trans>
              }
              placeholder={
                deploymentPolicies.isFetching
                  ? t(
                      'viewPipeline.form.deployment.field.deploymentPolicy.loading',
                    )
                  : t(
                      'viewPipeline.form.deployment.field.deploymentPolicy.placeholder',
                    )
              }
              options={deploymentPolicyOptions}
              required
              disabled={
                values.branch === '' ||
                deploymentPolicies.isLoading ||
                deploymentPolicies.isFetching ||
                deploymentPolicies.isError
              }
            />
            {values.deploymentPolicy ===
              DEPLOYMENT_POLICY_TYPES.FEATURE_BRANCH_POLICY && (
              <div data-testid="feature-branch-policy-explanation">
                <Text size="small">
                  <Icon type="rocket" className="deploy-form__rocket-icon" />
                  {t('viewPipeline.dialog.deployment.featureBranch.heading')}
                </Text>
                <Text size="small">
                  {t('viewPipeline.dialog.deployment.featureBranch')}
                </Text>
              </div>
            )}
          </div>
        )}
        {projectType === 'MODULE' &&
          selectedDeploymentPolicy.name ===
            DEPLOYMENT_POLICY_TYPES.EVENTUAL_CONSISTENCY_POLICY && (
            <div className="deploy-form__field">
              <div className="deploy-form__policy-title">
                <Icon type="rocket" className="deploy-form__policy-icon" />
                {selectedDeploymentPolicy.displayName}
              </div>
              <Trans
                t={t}
                i18nKey="viewPipeline.form.deployment.policy.eventualConsistencyExplanation"
                components={{
                  1: <br />,
                  2: <div className="deploy-form__text--yellow" />,
                  3: (
                    <PdsLink
                      type={1}
                      as="a"
                      href="https://www.puppet.com/docs/continuous-delivery/5.x/deploy_module.html"
                      target="_blank"
                    />
                  ),
                }}
              />
            </div>
          )}
        {projectType === 'MODULE' &&
          isRegexPipeline &&
          values.deploymentPolicy && (
            <div
              className="deploy-form__field"
              data-testid="select-control-repo"
            >
              <Form.Field
                type="select"
                name="controlRepo"
                label={t(
                  'viewPipeline.form.deployment.field.controlRepo.label',
                )}
                placeholder={
                  controlRepos.isFetching
                    ? t(
                        'viewPipeline.form.deployment.field.controlRepo.loading',
                      )
                    : t(
                        'viewPipeline.form.deployment.field.controlRepo.placeholder',
                      )
                }
                options={controlRepoOptions}
                required
                disabled={
                  values.puppetEnterpriseServer === '' ||
                  controlRepos.isLoading ||
                  controlRepos.isFetching ||
                  controlRepos.error
                }
              />
            </div>
          )}
        {projectType === 'MODULE' && isRegexPipeline && values.controlRepo && (
          <div
            data-testid="select-control-repo-branch"
            className="deploy-form__field"
          >
            <Form.Field
              type="select"
              name="controlRepoBranch"
              label={t(
                'viewPipeline.form.deployment.field.controlRepoBranch.label',
              )}
              placeholder={
                branches.isLoading ||
                (controlRepoBranches.isFetching && !controlRepoBranches.error)
                  ? t(
                      'viewPipeline.form.deployment.field.controlRepoBranch.loading',
                    )
                  : t(
                      'viewPipeline.form.deployment.field.controlRepoBranch.placeholder',
                    )
              }
              options={controlRepoBranchOptions}
              required
              disabled={
                controlRepoBranches.isLoading ||
                controlRepoBranches.isFetching ||
                controlRepoBranches.error
              }
            />
          </div>
        )}
        {parameterComponents?.length && parameterComponents}
        {values.deploymentPolicy && (type === 'stage' || type === 'edit') && (
          <Form.Field
            type="number"
            name="timeout"
            label={t('viewPipeline.form.deployment.field.timeout.label')}
            placeholder={t(
              'viewPipeline.form.deployment.field.timeout.placeholder',
            )}
            min={0}
            required
          />
        )}
        {peServer && prefixOptions.length > 0 && (
          <div className="deploy-form__field">
            <Form.Field
              type="select"
              name="prefix"
              label={t('viewPipeline.form.deployment.field.prefix.label')}
              placeholder={
                prefixes.isFetching
                  ? t('viewPipeline.form.deployment.field.prefix.loading')
                  : t('viewPipeline.form.deployment.field.prefix.placeholder')
              }
              options={prefixOptions}
              disabled={
                prefixOptions.length === 0 ||
                prefixes.isFetching ||
                prefixes.isError
              }
            />
          </div>
        )}
        {type === 'manual' && (
          <Form.Field
            type="text"
            name="description"
            label={t('viewPipeline.form.deployment.field.description.label')}
            placeholder={t(
              'viewPipeline.form.deployment.field.description.placeholder',
            )}
            required
          />
        )}
      </Form>

      <div className="deploy-form__api-errors">
        {infiniteDeploymentError && (
          <Alert type="danger">
            {t('viewPipeline.form.deployment.error.infiniteDeployment')}
          </Alert>
        )}
        <Cd4peError error={branches.error} />
        <Cd4peError error={commits.error} />
        <Cd4peError error={peIntegrations.error} />
        <Cd4peError error={nodeGroups.error} />
        <Cd4peError error={deploymentPolicies.error} />
      </div>
    </>
  );
};

export default DeploymentForm;

DeploymentForm.defaultProps = defaultProps;
